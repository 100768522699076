import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import { bus } from '../app';
import { getField, updateField } from 'vuex-map-fields';

Vue.use(Vuex);


export const store = new Vuex.Store({
    strict: false,
    state: {
        defaultCity: '',
        defaultCityState: '',
        currentLatitude:'',
        currentLongitude:'',
        scrollPositionY: 0,
        lookupData: '',
        hashThemes: [],
        toggleView:"gride",
        moreThemeOptions: "",
        moreCuisinesOptions: "",
        moreAmenitiesOptions: "",
        headerClass:'',
        banner_display_type: false,
        isNearMe:false,
        autoSuggestion:{
            keyword:'',
            results:[],
        },
        search_offset:0,
        search_limit:12,
        search_total_results:0,
        search_term:'',
        search_top_hash: '',
        search_category: '',
        search_sub_category: '',
        search_main_category: 'Trending',
        search_business_type: '',
        search_business_sub_type: '',
        search_date_range: '',
        search_event_genre: '',
        search_event_theme: [],
        search_event_cuisines: [],
        search_event_amenities:[],
        search_hash_listing: [],
        tickets: [],

        hash_themes:[],
        search_user_filters: {
            amenity:[],
            sub_category: [],
            business_category :[],
            category:[],
            genre:[],
            theme:[]
        },
        isSearching:false,
        requestingClaimData:true,
        enabledSeverityLevel:false,
        hashDetail: '',
        top_featured: false,
        mark_featured_date: '',
        hash_start_date: '',
        hash_end_date: '',
        stateCitiesData: [],
        trendingEventList: [],
        trendingHashList: [],
        performerLineupData: [],
        claimRequestData: [],
        claimRequestedData: [],
        businessSeverityOptions:[],
        eventSubCategories:[],
        businessAidDurationOptions:[],
        performersSelectOptions: [],
        performersSelectedOptions: [],
        businessSubTypeData: [],
        defaultPageData: '',
        allBusinessSubTypeData: [],
        filteredTopCities: [],
        milesArrayData: [
            { id: 10, text: '10 M' },
            { id: 20, text: '20 M' },
            { id: 30, text: '30 M' },
            { id: 40, text: '40 M' },
            { id: 50, text: '50 M' },
            { id: 60, text: '60 M' },
            { id: 70, text: '70 M' },
            { id: 80, text: '80 M' },
            { id: 90, text: '90 M' },
            { id: 100, text: '100 M' },
        ],
        eventDisplayStatus: "",
        eventStatus: [
            { id: 1, text: 'Activated' },
            { id: 2, text: 'Canceled' },
            { id: 3, text: 'Delayed' },
            { id: 4, text: 'postponed' },
            { id: 8,  text: "Capacity 25%" },
            { id: 7,  text: "Capacity 50%" },
            { id: 6,  text: "Capacity 75%" },
            { id: 5,  text: "Capacity 100%" },
            { id: 9,  text: "Tickets On Sale"},
            { id: 10,  text: "Tickets Available"},
            { id: 11, text: "Tickets Sold Out" },
            { id: 12, text: "Tables on Sale"},
            { id: 13, text: "Tables Sold Out"},
            { id: 14, text: "Help Wanted"}
        ],
        bannerType: [
            { id: 'all-time', text: 'All Time' },
            { id: 'duration', text: 'Duration' }
        ],
        businessTypeOption: [
            { id: 'all', text: 'All' },
            { id: 'global', text: 'Global' }
        ],
        alphabetArrayData: [
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
        ]

    },
    getters: {
        // Add the `getField` getter to the
        getField,
        tickets: state => state.tickets,

        milesArray: (state) => {
            return state.milesArrayData.map(el => { return el; })
        },
        alphabetArray: (state) => {
            return state.alphabetArrayData.map(el => { return el; })
        },
        getViewType: (state) => {
            return state.toggleView;
        },
        performersSelectOptions: (state) => {
            return state.performersSelectOptions;
        },
        bannerType: (state) => {
            return state.bannerType;
        },
        performersSelectedOptions: (state) => {
            return state.performersSelectedOptions;
        },
        PerformerLineupData: (state) => {
            return state.performerLineupData;
        },
        getTopCitiesData: (state) => {
            return state.defaultPageData?state.defaultPageData.top_cities:[];
        },
        getFilteredTopCitie: (state) => {
            return state.filteredTopCities;
        },
        businessTypesTotalCount(state) {

            var count = 0;
            if(typeof state.search_user_filters.business_category == 'undefined'){
                return count;
            }

            var items = state.search_user_filters.business_category;
            items.map(function(obj,index){
                count = obj.count + count;
            });
            return count;
        },
        hashThemesTotalCount(state) {
            var count = 0;
            if(typeof state.search_user_filters.theme == 'undefined'){
                return count;
            }
            var items = state.search_user_filters.theme;
            items.map(function(obj,index){
                if(obj.count == 0){
                    console.log('theme 0 count object',obj);
                }
                count = obj.count + count;
            });
            return count;
        },
        amenitiesTotalCount(state) {
            var count = 0;
            if(typeof state.search_user_filters.amenity == 'undefined'){
                return count;
            }
            var items = state.search_user_filters.amenity;
            items.map(function(obj,index){
                if(obj.subgroup_n != 'None' && obj.subgroup_n != 'Food'){
                    count = obj.count + count;
                }

            });
            return count;
        },
        mainCategoriesTotalCount(state) {

            var count = 0;
            if(typeof state.search_user_filters.category == 'undefined'){
                return count;
            }

            var items = state.search_user_filters.category;
            var arrData = ['Trending','All','National', 'Festival'];

            items.map(function(obj,index){
                if(arrData.includes(obj.type_n)){
                    count = obj.count + count;
                }else{
                    count = obj.city_count + count;
                }
            });
            return count;
        },
        cuisinesTotalCount(state) {

            var count = 0;
            if(typeof state.search_user_filters.amenity == 'undefined'){
                return count;
            }
            var items = state.search_user_filters.amenity;
            items.map(function(obj,index){
                if(obj.type_n == 'Food'){
                    count = obj.count + count;
                }
            });
            return count;
        },
        businessTypes: (state) => {
            var types = [];
            if (typeof state.lookupData == 'object' && state.lookupData != '') {
                types = state.lookupData['BusinessType'].children.map(el => {
                    return { 'id': el.code, 'text': el.name, 'position': el.position }
                });
            }
            bus.$emit('updateBusinesStore');
            return types;
        },
        businessGenre: (state) => {
            var genres = [];
            console.log(state.lookupData['Genre'].lookups)
            if (typeof state.lookupData == 'object' && state.lookupData != '') {
                genres = state.lookupData['Genre'].lookups.map(el => {
                    return { 'id': el.id, 'text': el.name, 'position': el.position }
                });
            }
            // bus.$emit('updateBusinessGenre');
            return genres;
        },
        getFilteredAmenities: (state) => {
            var data = [];
            if ( typeof state.search_user_filters.amenity !='undefined' && state.search_user_filters.amenity.length > 0) {

                // Accepts the array and key
                const groupBy = (array, key) => {
                    // Return the end result
                    return array.reduce((result, currentValue) => {
                        // If an array already present for key, push it to the array. Else create an array and push the object
                        (result[currentValue[key]] = result[currentValue[key]] || []).push(
                            currentValue
                        );
                        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                        return result;
                    }, {}); // empty object is the initial value for result object
                };

                var grouped = groupBy(state.search_user_filters.amenity,'subgroup_n');
                let objectArray = Object.keys(grouped);
                let objectArrayValues = Object.values(grouped);

                $.each(objectArray,function(index,obj){
                    if(obj != 'Food' && obj != 'None'){
                        data.push({amenity: obj,values: objectArrayValues[index]});
                    }
                });

                var getPreferencesGroupData = []
                $.each(data,function(index,obj){
                    if(obj.amenity == 'Preference'){
                        var sub_grouped = groupBy(obj.values,'type_n');
                        let sub_objectArray = Object.keys(sub_grouped);
                        let sub_objectArrayValues = Object.values(sub_grouped);
                        $.each(sub_objectArray,function(index2,obj2){
                            getPreferencesGroupData.push({amenity: obj2,values: sub_objectArrayValues[index2]});
                        });

                        data[index]['values'] = getPreferencesGroupData;
                    }
                });
            }
            return data;
        },
        getAdminFilteredAmenities: (state) => {
            var data = [];
            if ( state.lookupData.amenities.Others.length > 0) {

                // Accepts the array and key
                const groupBy = (array, key) => {
                    // Return the end result
                    return array.reduce((result, currentValue) => {
                        // If an array already present for key, push it to the array. Else create an array and push the object
                        (result[currentValue[key]] = result[currentValue[key]] || []).push(
                            currentValue
                        );
                        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                        return result;
                    }, {}); // empty object is the initial value for result object
                };

                var grouped = groupBy(state.lookupData.amenities.Others,'amenity_subgroup');
                let objectArray = Object.keys(grouped);
                let objectArrayValues = Object.values(grouped);

                $.each(objectArray,function(index,obj){
                    if(obj != 'Food' && obj != 'None'){
                        data.push({amenity: obj,values: objectArrayValues[index]});
                    }
                });


                var getPreferencesGroupData = []
                $.each(data,function(index,obj){
                    if(obj.amenity == 'Preference'){
                        var sub_grouped = groupBy(obj.values,'type_n');
                        let sub_objectArray = Object.keys(sub_grouped);
                        let sub_objectArrayValues = Object.values(sub_grouped);
                        $.each(sub_objectArray,function(index2,obj2){
                            getPreferencesGroupData.push({amenity: obj2,values: sub_objectArrayValues[index2]});
                        });

                        data[index]['values'] = getPreferencesGroupData;
                    }
                });
                // console.log('Preference Group',getPreferencesGroupData);
            }
            return data;
        },

        getFilteredCuisines: (state) => {
            var data = [];
            if ( typeof state.search_user_filters.amenity !='undefined' && state.search_user_filters.amenity.length > 0) {

                // Accepts the array and key
                const groupBy = (array, key) => {
                    // Return the end result
                    return array.reduce((result, currentValue) => {
                        // If an array already present for key, push it to the array. Else create an array and push the object
                        (result[currentValue[key]] = result[currentValue[key]] || []).push(
                            currentValue
                        );
                        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                        return result;
                    }, {}); // empty object is the initial value for result object
                };

                var grouped = groupBy(state.search_user_filters.amenity,'type_n');
                let objectArray = Object.keys(grouped);
                let objectArrayValues = Object.values(grouped);

                $.each(objectArray,function(index,obj){
                    if(obj == 'Food'){
                        data.push({amenity: obj,values: objectArrayValues[index]});
                    }
                });
            }
            return data;
        },

        getFilteredCategories: (state) => {

            let filteredCategories = [];
            if ( typeof state.search_user_filters.category !='undefined' && state.search_user_filters.category.length > 0) {

                var arrData = ['Trending','All','National', 'Festival'];
                filteredCategories = state.search_user_filters.category.filter(function(object){

                    if(object.name == 'MC/SC/CC'){
                        object.name = 'College'
                    }
                    if(object.name == 'Media'){
                        object.name = 'Attractions'
                    }
                    if(object.name == 'Night Life'){
                        object.name = 'Dine & Drinks'
                    }

                    if(object.count > 0 && arrData.includes(object.type_n)){
                        return true;
                    }

                    if(object.city_count > 0 && !arrData.includes(object.type_n)){
                        return true;
                    }
                });

            }

            var sortingArr= [
                'ALL',
                'Entertainment',
                'MC/SC/CC',
                'NightLife',
                'Media',
                'Sports',
                'National',
                'Festival',
                'Trending'
            ];

            filteredCategories = filteredCategories.sort(function(a, b){  
                return sortingArr.indexOf(a.type_n) - sortingArr.indexOf(b.type_n);
              });

            return filteredCategories;
        },
        getFilteredThemes: (state) => {

            var data = [];
            if ( typeof state.search_user_filters.theme !='undefined' && state.search_user_filters.theme.length > 0) {

                var arrData = ['None', 'Internal'];
                let filteredThemes = state.search_user_filters.theme.filter(function(object){
                    return !arrData.includes(object.type_n) && object.type_n != '' ?true:false;
                });

                // Accepts the array and key
                const groupBy = (array, key) => {
                    // Return the end result
                    return array.reduce((result, currentValue) => {
                        // If an array already present for key, push it to the array. Else create an array and push the object
                        (result[currentValue[key]] = result[currentValue[key]] || []).push(
                            currentValue
                        );
                        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                        return result;
                    }, {}); // empty object is the initial value for result object
                };
                var grouped = groupBy(filteredThemes,'type_n');
                let objectArray = Object.keys(grouped);
                let objectArrayValues = Object.values(grouped);

                $.each(objectArray,function(index,obj){
                    data.push({theme: obj,values: objectArrayValues[index]});
                });

                console.log('theme grouping',data);
            }
            return data;
        },
        businessIdsOptions: (state) => {
            var types = [];
            if (typeof state.lookupData == 'object' && state.lookupData != '') {
                types = state.lookupData['BusinessType'].children.map(el => {
                    return { 'id': el.id, 'text': el.name, 'position': el.position }
                });
            }
            // bus.$emit('updateBusinesStore');
            return types;
        },
        statesData: (state) => {
            var states = [];
            if (typeof state.defaultPageData == 'object' && state.defaultPageData.lookup_cities != '') {
                states = state.defaultPageData.lookup_cities.map(el => {
                    return { 'id': el.id, 'text': el.name, 'code': el.geoname_code }
                });
                bus.$emit('stateStoreUpdated');
            }
            return states;
        },
        addressesStatesData: (state) => {
            var states = [];

            if (typeof state.defaultPageData == 'object' && state.defaultPageData != '') {

                console.log("states data",state.defaultPageData.lookup_cities); // Log lookup_cities

                states = state.defaultPageData.lookup_cities.map(el => {

                    // el.geoname_code = el.name == 'Meta'? "META":el.geoname_code 
                    return { 'id': el.geoname_code, 'text': el.name, 'code': el.geoname_code }
                });
                bus.$emit('stateStoreUpdated');
            }

            return states;
        },
        getstateCities: (state) => {
            return state.stateCitiesData;
        },
        getBusinessSeverityOptions: (state) => {
            return state.businessSeverityOptions;
        },
        getHashTagTheme: (state) => {
            var states = [];
            if (typeof state.Hashtag.lookups.length > 0) {
                states = state.Hashtag.lookups.map(el => {
                    return { 'id': el.code, 'text': el.name}
                });
            }
            return states;
        },
        getEventSubCategories: (state) => {
            return state.eventSubCategories;
        },
        getBusinessAidOptions: (state) => {
            return state.businessAidDurationOptions;
        },
        businessSubTypes: (state) => {
            return state.businessSubTypeData;
        },
        allBusinessSubTypes: (state) => {
            return state.allBusinessSubTypeData;
        },
        raceOptions: (state) => {
            var race = [];
            if (typeof state.lookupData == 'object' && state.lookupData != '') {
                race = state.lookupData.Race.lookups.map(el => {
                    return { id: el.id, text: el.name };
                });
            }
            return race;
        },
        genderOptions: (state) => {
            var race = [];
            if (typeof state.lookupData == 'object' && state.lookupData != '') {
                race = state.lookupData.Gender.lookups.map(el => {
                    return { id: el.id, text: el.name };
                });
            }
            return race;
        },
        ageOptions: (state) => {
            var race = [];
            if (typeof state.lookupData == 'object' && state.lookupData != '') {
                race = state.lookupData.UserAge.lookups.map(el => {
                    return { id: el.id, text: el.name };
                });
            }
            return race;
        },
        amenitiesFilterOptions: (state) => {

            var amenities = [];
            var options = [];
            if (state.lookupData !='' && state.lookupData.amenities.Others.length > 0) {


                // Accepts the array and key
                const groupBy = (array, key) => {
                    // Return the end result
                    return array.reduce((result, currentValue) => {
                        // If an array already present for key, push it to the array. Else create an array and push the object
                        (result[currentValue[key]] = result[currentValue[key]] || []).push(
                            currentValue
                        );
                        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                        return result;
                    }, {}); // empty object is the initial value for result object
                };

                let data = state.lookupData.amenities.Others;
                var grouped = groupBy(data,'amenity_subgroup');
                let objectArray = Object.keys(grouped);
                let objectArrayValues = Object.values(grouped);


                $.each(objectArray,function(index,obj){
                    amenities.push({theme: obj,values: objectArrayValues[index]});
                });

                amenities.push({theme:'Age',values: state.lookupData.amenities.Age});
                amenities.push({theme:'Attire',values: state.lookupData.amenities.Attire});
                amenities.push({theme:'Gender',values: state.lookupData.amenities.Gender});
                console.log('Amenities filtered data',amenities);



                options = amenities.map(item => {
                    return {
                        'id': item.theme, 'text': item.theme, 'children': item.values.map(sub_item => {
                            return { 'id': sub_item.code, 'text': sub_item.title }
                        })
                    }
                });

            }
            return options;
        },
        profileAmenitiesFilterOptions: (state) => {

            var amenities = [];
            var options = [];
            if (state.lookupData !='' && state.lookupData.amenities.Others.length > 0) {


                // Accepts the array and key
                const groupBy = (array, key) => {
                    // Return the end result
                    return array.reduce((result, currentValue) => {
                        // If an array already present for key, push it to the array. Else create an array and push the object
                        (result[currentValue[key]] = result[currentValue[key]] || []).push(
                            currentValue
                        );
                        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                        return result;
                    }, {}); // empty object is the initial value for result object
                };

                let data = state.lookupData.amenities.Others;
                var grouped = groupBy(data,'amenity_subgroup');
                let objectArray = Object.keys(grouped);
                let objectArrayValues = Object.values(grouped);


                $.each(objectArray,function(index,obj){
                    amenities.push({theme: obj,values: objectArrayValues[index]});
                });

                amenities.push({theme:'Age',values: state.lookupData.amenities.Age});
                amenities.push({theme:'Attire',values: state.lookupData.amenities.Attire});
                amenities.push({theme:'Gender',values: state.lookupData.amenities.Gender});
                console.log('Amenities filtered data',amenities);



                options = amenities.map(item => {
                    return {
                        'id': item.theme, 'text': item.theme, 'children': item.values.map(sub_item => {
                            return { 'id': sub_item.id, 'text': sub_item.title }
                        })
                    }
                });

            }
            return options;
        },
        FoodData: (state) => {
            var foods = [];
            if (typeof state.lookupData == 'object' && state.lookupData != '') {


                var americanFoodTypes = [
                    'barbecue',
                    'breakfast-brunch',
                    'buffet',
                    'burgers',
                    'casual',
                    'chicken-shop',
                    'fast-food',
                    'gluten-free',
                    'hot-dogs',
                    'modern-american',
                    'new-american',
                    'pizzeria',
                    'quick-bites',
                    'seafood',
                    'soul-food',
                    'southern',
                    'salad',
                    'sandwich',
                    'steak-house',
                    'tex-mex',
                    'tacos',
                    'traditional-american',
                    'upscale',
                    'vegetarian',
                    'vegan',

                ];

                // Categories with American food types
                var filteredAmericanFoodTypes = state.lookupData.amenities.Food.filter(function(obj){
                    return (americanFoodTypes.includes(obj.code))?true:false
                });
                filteredAmericanFoodTypes = filteredAmericanFoodTypes.map(el => {
                    return { id: el.code, text: el.title };
                });

                // Categories other than American food types
                var filteredOtherFoodTypes = state.lookupData.amenities.Food.filter(function(obj){
                    return (!americanFoodTypes.includes(obj.code))?true:false
                });

                foods = filteredOtherFoodTypes.map(el => {
                    if(el.code == 'american'){
                        return { id: el.code, text: el.title,children:filteredAmericanFoodTypes };
                    }else {
                        return { id: el.code, text: el.title };
                    }
                });

            }
            return foods;
        },
        FoodIdsOptions: (state) => {
            var foods = [];
            if (typeof state.lookupData == 'object' && state.lookupData != '') {

                foods = state.lookupData.amenities.Food.map(el => {
                    return { id: el.id, text: el.title };
                });
            }
            return foods;
        },
        getAgePreferencesAmenities: (state) => {
            if (typeof state.lookupData == 'object' && state.lookupData.amenities.Age.length > 0) {
                return state.lookupData.amenities.Age
            }
        },
        getGenderPreferencesAmenities: (state) => {
            if (typeof state.lookupData == 'object' && state.lookupData.amenities.Gender.length > 0) {
                return state.lookupData.amenities.Gender
            }
        },
        getAttirePreferencesAmenities: (state) => {
            if (typeof state.lookupData == 'object' && state.lookupData.amenities.Attire.length > 0) {
                return state.lookupData.amenities.Attire
            }
        },
        FoodCuisinesData: (state) => {
            var foods = [];
            if (typeof state.lookupData == 'object' && state.lookupData != '') {
                foods = state.lookupData.amenities.Food.map(el => {
                    return { id: el.code,code: el.code, text: el.title };
                });
            }
            foods.push({ id: '', text: 'No options' });
            return foods;
        },
        parkingTypes: (state) => {
            var types = [];
            if (typeof state.lookupData == 'object' && state.lookupData != '') {
                types = state.lookupData.Parking.lookups.map(el => {
                    return { id: el.code, text: el.name };
                });
            }
            return types;
        },
        eventType: (state) => {
            var type = [];
            if (typeof state.lookupData == 'object' && state.lookupData != '') {
                type = state.lookupData['EventType'].lookups.map(el => {
                    return { id: el.code, text: el.name,position: el.position };
                });
            }
            type.sort(function (a, b) {
                if (a.position > b.position) {
                    return 1
                }
                if (a.position < b.position) {
                    return -1
                }
                return 0;
            });
            return type;
        },
        eventCategory: (state) => {

            var type = [];
            state.eventSubCategories = [];
            if (typeof state.lookupData == 'object' && state.lookupData != '') {
                type = state.lookupData['EventCategory'].lookups.map(el => {
                    return { id: el.code, text: el.name,position: el.position };
                });
            }
            type.sort(function (a, b) {
                if (a.position > b.position) {
                    return 1
                }
                if (a.position < b.position) {
                    return -1
                }
                return 0;
            });
            return type;
        },
        eventThemes: (state) => {
            var themes = [];
            if (typeof state.lookupData == 'object' && state.lookupData != '') {
                themes = state.lookupData['EventTheme'].lookups.map(el => {
                    return { id: el.code, text: el.name,position: el.position };
                });
            }

            themes.sort(function (a, b) {
                if (a.position > b.position) {
                    return 1
                }
                if (a.position < b.position) {
                    return -1
                }
                return 0;
            });
            return themes;
        },
        tierEventThemes: (state) => {

            var themes = [];
            if (typeof state.hashThemes != 'undefined' && state.hashThemes.length > 0) {

                var themes = state.hashThemes.filter(function(obj){
                    return  obj.hashtags.length > 0 ? true:false
                });
                themes = themes.map(item => {
                    return {
                        'id': item.code, 'text': item.name, 'position': item.position, 'children': item.hashtags.map(tier_item => {
                            return { 'id': tier_item.title, 'text': tier_item.title }
                        })
                    }
                });
            }

            themes.sort(function (a, b) {
                if (a.position > b.position) {
                    return 1
                }
                if (a.position < b.position) {
                    return -1
                }
                return 0;
            });
            return themes;
        },
        profileHashtagsOptions: (state) => {

            var themes = [];
            if (typeof state.hashThemes != 'undefined' && state.hashThemes.length > 0) {

                var themes = state.hashThemes.filter(function(obj){
                    return  obj.hashtags.length > 0 ? true:false
                });
                themes = themes.map(item => {
                    return {
                        'id': item.code, 'text': item.name, 'position': item.position, 'children': item.hashtags.map(tier_item => {
                            return { 'id': tier_item.id, 'text': tier_item.title }
                        })
                    }
                });
            }

            themes.sort(function (a, b) {
                if (a.position > b.position) {
                    return 1
                }
                if (a.position < b.position) {
                    return -1
                }
                return 0;
            });
            return themes;
        },
        adminTierEventThemes: (state) => {

            var themes = [];
            if (typeof state.hashThemes != 'undefined' && state.hashThemes.length > 0) {

                var themes = state.hashThemes.filter(function(obj){
                    return  obj.hashtags.length > 0 ? true:false
                });
                themes = themes.map(item => {
                    return {
                        'id': item.code, 'text': item.name, 'children': item.hashtags.map(tier_item => {
                            return { 'id': tier_item.title, 'text': tier_item.title_tier_price }
                        })
                    }
                });
            }
            return themes;
        },
        ticketTypes: (state) => {
            var types = [];
            if (typeof state.lookupData == 'object' && state.lookupData != '') {
                types = state.lookupData['Tickets'].lookups.map(el => {
                    return { id: el.code, text: el.name };
                });
            }
            return types;
        },
        ticketLeftTypes: (state) => {
            var types = [];
            if (typeof state.lookupData == 'object' && state.lookupData != '') {
                types = state.lookupData['MobileStatusesLeft'].lookups.map(el => {
                    return { id: el.code, text: el.name };
                });
            }
            return types;
        },
        otherticketTypesRight: (state) => {
            var types = [];
            if (typeof state.lookupData == 'object' && state.lookupData != '') {
                types = state.lookupData['MobileStatusesRight'].lookups.map(el => {
                    return { id: el.code, text: el.name };
                });
            }
            return types;
        },
        businessActiveStatus: (state) => {
            var types = [];
            if (typeof state.lookupData == 'object' && state.lookupData != '') {
                types = state.lookupData['business_closing_reasons'].map(el => {
                    return { id: el.id, text: el.title };
                });
            }
            return types;
        },
        getBusinessSeverities: (state) => {
            return  state.lookupData['business_closing_reasons']
        },
        getEventDisplayStatus:(state) => {
            return  state.eventDisplayStatus
        },
        eventGenre: (state) => {

            var genre = [];
            if (typeof state.lookupData == 'object' && state.lookupData != '') {
                genre = state.lookupData['Genre'].lookups.map(el => {
                    return { id: el.code, text: el.name, position: el.position };
                });
            }

            genre.sort(function (a, b) {
                if (a.position > b.position) {
                    return 1
                }
                if (a.position < b.position) {
                    return -1
                }
                return 0;
            });

            return genre;
        },
        genreIdsOptions: (state) => {
            var genre = [];
            if (typeof state.lookupData == 'object' && state.lookupData != '') {
                genre = state.lookupData['Genre'].lookups.map(el => {
                    return { id: el.id, text: el.name,position: el.position };
                });
            }
            genre.sort(function (a, b) {
                if (a.position > b.position) {
                    return 1
                }
                if (a.position < b.position) {
                    return -1
                }
                return 0;
            });
            return genre;
        },
        eventStatus: (state) => {
            return state.eventStatus;
        },
        businessTypeOption: (state) => {
            return state.businessTypeOption;
        }
        

    },
    mutations: {
        // Add the `updateField` mutation to the
        // `mutations` of your Vuex store instance.
        updateField,
        getBusinessReasonMutation: (state,business_id) => {

            if(typeof state.lookupData != 'undefined' && state.lookupData != ''){
                // debugger
                var data = jQuery.grep(state.lookupData.business_closing_reasons, function (obj) {
                    return (obj.id == business_id);
                });

                if (typeof data != 'undefined' && data.length > 0) {

                    state.businessSeverityOptions = data[0].severity.map(el => {
                        var str =  el.description;
                        return { id: el.id, text: str,icon_url: el.icon_url,level: el.level };
                    });
                    state.businessAidDurationOptions = data[0].aid_duration.map(el => {
                        return { id: el.id, text: el.time_duration };
                    });
                }
            }
        },
        setTickets(state, tickets) {
            state.tickets = tickets;
          },
        getStateCitiesMutation: (state, state_id) => {
            var stateData = jQuery.grep(state.defaultPageData.lookup_cities, function (obj) {
                return (obj.id == state_id);
            });

            if (stateData.length > 0) {
                state.stateCitiesData = [];
                state.stateCitiesData = stateData[0].city_set.map(el => {
                    return { id: el.name, text: el.name };
                });
            }
        },
        searchTopCityMutation: (state, city) => {
            if(city){
                let filteredCities = state.defaultPageData.top_cities.filter(item => {
                    return item.city.includes(city); 
                });
                state.filteredTopCities = filteredCities;
            }else {
                state.filteredTopCities = [];
            }
                        
        },
        toggleViewMutation: (state, view) => {
            state.toggleView = view            
        },
        getEventDisplayStatusMutation: (state, status_id) => {            

             var displayStatus = state.eventStatus.filter(item => {
                return item.id == status_id
            });
            if(displayStatus.length > 0){
                state.eventDisplayStatus = displayStatus[0].text;  
            }
        },
        getBusinessSubTypesMutation: (state, business_id) => {
            
            var data = [];
            state.businessSubTypeData = [];
            if (typeof state.lookupData != 'undefined' && typeof state.lookupData['BusinessType'].children != 'undefined') {
                data = jQuery.grep(state.lookupData['BusinessType'].children, function (obj) {
                    return (obj.code === business_id);
                });
            } else {
                return [];
            }

            // vm.subBusinessTypes = [];    
            var dataOptions = data? data[0]:[]
            
            if(typeof dataOptions == 'undefined'){
                return [];
            }
            
            console.log('dataOptions',dataOptions,data,'business_id:'+business_id,'BusinessType',state.lookupData['BusinessType']);
            if ( typeof dataOptions.children != 'undefined' && dataOptions.children.length > 0) {
                dataOptions = dataOptions.children.map(item => {
                    if (typeof item.lookups != 'undefined' && item.lookups.length > 0) {
                        return {
                            'id': item.code, 'text': item.name, 'position': item.position, 'children': item.lookups.map(item2 => {
                                return { 'id': item2.code, 'text': item2.name, 'position': item2.position }
                            })
                        }
                    } else {
                        return { 'id': item.code, 'text': item.name, 'position': item.position }
                    }
                });
            } else {
                dataOptions = dataOptions.lookups.map(item => {
                    return { 'id': item.code, 'text': item.name, 'position': item.position }
                });
            }
            state.businessSubTypeData = dataOptions.sort(function (a, b) {
                if (a.position > b.position) {
                    return 1
                }
                if (a.position < b.position) {
                    return -1
                }
                return 0;
            });
        },
        getSubCategoryMutation: (state, cat_id) => {

            var data = [];
            state.eventSubCategories = [];
            if (typeof state.lookupData != 'undefined' && state.lookupData != '') {

                if(state.lookupData['EventCategory'])
                    data = jQuery.grep(state.lookupData['EventCategory'].lookups, function (obj) {
                        return (obj.code === cat_id);
                    });

                if(data.length == 0 ){
                    state.eventSubCategories = [];
                    return false;
                }

            } else {
                state.eventSubCategories = [];
                return false;
            }
        },
        getAllBusinessSubTypesMutation: (state, businesses) => {

            var data = [];
            if (typeof state.lookupData != 'undefined' && state.lookupData != '') {

                var data = state.lookupData['BusinessType'].children.filter(function (obj) {
                    if(businesses){
                        var searchId = obj.id;
                        var strArr = businesses.map(Number);
                        var result = jQuery.inArray(searchId, strArr);
                        return result > -1;
                    }
                });
            }

            var mergedData = [];
            if(data.length > 0 ){
                $.each(data,function(index,obj){

                    var dataOptions = obj;
                    if (typeof dataOptions.children != 'undefined' && dataOptions.children.length > 0) {
                        dataOptions = dataOptions.children.map(item => {
                            if (typeof item.lookups != 'undefined' && item.lookups.length > 0) {

                                mergedData.push({ 'id': item.id, 'text': item.name, 'position': item.position });
                                item.lookups.map(item2 => {
                                    mergedData.push({ 'id': item2.id, 'text': item2.name, 'position': item2.position });
                                });

                            } else {
                                mergedData.push({ 'id': item.id, 'text': item.name, 'position': item.position });
                            }
                        });
                    }else {
                        dataOptions = dataOptions.lookups.map(item => {
                            mergedData.push({ 'id': item.id, 'text': item.name, 'position': item.position });
                        });
                    }

                });
            }

            console.log('merged data',mergedData);
            state.allBusinessSubTypeData = mergedData.sort(function (a, b) {
                if (a.position > b.position) {
                    return 1
                }
                if (a.position < b.position) {
                    return -1
                }
                return 0;
            });
        }

    },
    actions: {
        getBusinessReasonAction: (context, business_id) => {
            context.commit('getBusinessReasonMutation', business_id);
        },
        getEventSubCategoryAction: (context, event_cat) => {
            context.commit('getSubCategoryMutation', event_cat);
        },
        getBusinessSubTypesAction: (context, business_id) => {
            context.commit('getBusinessSubTypesMutation', business_id);
        },
        getAllBusinessSubTypesAction: (context, business_id) => {
            context.commit('getAllBusinessSubTypesMutation', business_id);
        },
        getEventDisplayStatusAction: (context, status_id) => {
            context.commit('getEventDisplayStatusMutation', status_id);
        },
        getStateCitiesAction: (context, state_id) => {
            context.commit('getStateCitiesMutation', state_id);
        },
        searchTopCityAction: (context, term) => {
            context.commit('searchTopCityMutation', term);
        },
        toggleViewAction: (context, view) => {
            context.commit('toggleViewMutation', view);
        },
        updateTickets({ commit }, tickets) {
            debugger
            commit('setTickets', tickets);
        },
    }
});