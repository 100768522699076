<template>
  <div>
    <date-picker
      v-model="hash_start_date"
      value-type="YYYY-MM-DD"
      format="MM-DD-YYYY"
      :disabled-date="backendNotBeforeToday"
      input-class="floating-label-field floating-label-field--s3 form-control"
      placeholder="MM-DD-YYYY"
      @change="updateValues"
      :input-attr="{ name: '', required: 'required', id: 'start' }"
    >
    </date-picker>
    <input
      type="hidden"
      id="start_date"
      name="start_date"
      v-model="hash_start_date"
    />
  </div>
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";

export default {
  props: ["start_date"],
  watch: {},
  mixins: [helpers],
  created() {
    this.hash_start_date = this.start_date;
  },
  computed: {},
  data: function () {
    return {
      hash_start_date: "",
    };
  },
  methods: {
    updateValues: function (data) {
      this.hash_start_date = data;
      bus.$emit("start_date_event", this.hash_start_date);
    },
  },
};
</script>
