<template>
    <section class="vgps-events-tickets vgps-event-detail-section-wrapper" id="tickets">
            <div class="containers">
                <div class="row">
                    <div class="col-md-12 vgps-event-detail-section-title">
                        <h2>Tickets/Passes</h2>
                    </div>
                </div>
            </div>
            <div class="containers">
                <div class="row">
                    <div class="vgps-tickets-grid-wrapper">
                        <!-- @foreach($eventDetail->tickets as $ticket) -->
                            <div v-for="ticket in event.tickets" v-if="ticket.provider !== 'Parking' && ticket.provider !== 'Betting' && ticket.provider !== 'VIP'" class="vgps-tickets-grid">
                                <div class="vgps-tickets-grid-inner">
                                    <div class="ticket-desc-wrapper">
                                        <h4>{{ ticket.provider }}</h4>
                                        <p>Tickets are now available!</p>
                                    </div>
                                    <div class="ticket-btn-wrapper">
                                        <a :href="ticket.url" target="_blank" class="btn btn-purple solid">Buy Ticket</a>
                                    </div>
                                </div>
                            </div>
                        <!-- @endforeach -->
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";


export default {
  data() {
    return {
      
    };
  },
  props: ["event"],
  mixins: [helpers],
  created() {

  },
  components: {

  },
  methods: {
    
  },
  filters: {
    
  }
};
</script>
