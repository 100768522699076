<template>
    <section class="vgps-event-performers-wrapper vgps-event-detail-section-wrapper" id="organizer-list">
            <div class="containers">
                <div class="row">
                    <div class="col-md-12 vgps-event-detail-section-title">
                        <h2>{{ title }}</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="vgps-event-performer-grid-wrapper">
                        <div v-for="(item,index) in organizers" class="col-lg-6 col-md-6 col-sm-6 col-xs-12" :key="index">
                            <div class="vgps-event-performer-grid">
                                <div class="thumb">
                                    <router-link :to="{ name: 'venue', params: { id: item.people.id }}">
                                        <img :src="item.people.thumbnail_urls.thumb_296">
                                    </router-link>    
                                </div>
                                <div class="desc">
                                    <h3><router-link :to="{ name: 'venue', params: { id: item.people.id }}">{{ item.people.title }}</router-link></h3>
                                    <!-- <span class="followers-count">{{ item.people.favourites_count }} followers</span> -->
                                    <p>Category: {{item.people.type_name }}</p>
                                    <span class="followers-count">
                                        Events: {{ item.people.events_count }}
                                    </span>
                                    <!-- <p>Genre:</p> -->
                                    <!-- <ul class="performers-genres">
                                        <li v-for="genre in item.people.genre.slice(0,1)" :key="genre.id">Music Genre: {{genre.name}}</li>
                                    </ul> -->

                                    <ul class="performers-genres">                                                                                           
                                        <li v-if="item.people.genre.length === 1">
                                            Music Genre: {{ item.people.genre[0].name }}
                                        </li>
                                        <li v-else>
                                            Music Genres:
                                            <button
                                                style="color: #337ab7;background-color: unset;border: none;"
                                                @click="togglePopup(item.people.id)"
                                            >
                                                {{ item.people.genre.length }}
                                            </button>
                                        </li>
                                    </ul>

                                            <!-- Popup Modal -->
                                    <div v-if="activePerformer === item.people.id" class="popup-overlay" @click="handleOverlayClick">
                                        <div class="popup-content" @click.stop>
                                            <h3>Genres</h3>
                                            <ul>
                                                <li v-for="genre in item.people.genre" :key="genre.id">{{ genre.name }}</li>
                                            </ul>
                                            <button @click="closePopup">Close</button>
                                        </div>
                                    </div>

                                </div>
                                <div v-if="item.people.social_contacts.length > 0" class="socials-btns-wrapper">
                                    <ul>
                                        <li v-for="(social,index) in getSocialApps(item.people.social_contacts)" :id="social.provider" :key="index">
                                            <a :href="social.value" target="_blank">
                                                <img :src="social.icon_url">
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="share-follow-btn-wrapper">
                                    <ul>
                                        <li class="follow-btn">
                                            <venue_follow_button 
                                                v-bind:id="item.people.id"
                                                v-bind:title="item.people.title"                  
                                                v-bind:follow="item.people.follow">
                                            </venue_follow_button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";

import VueSilentbox from 'vue-silentbox';//https://vuejsexamples.com/a-lightbox-inspired-vue-js-component/
import EventDetailFollowButton from '../../components/global/event-detail-follow-button.vue';
import EventDetailLikeButton from '../../components/global/detail-page-like.vue';
import VenueDetailFollowButton from '../../components/global/detail-follow-button.vue';



import {
  ContentLoader,
  FacebookLoader,
  CodeLoader,
  BulletListLoader,
  InstagramLoader,
  ListLoader
} from "vue-content-loader";

export default {
  data() {
    return {
      loadingContent: false,
      directionURL: '',
      showPopup: false,
      activePerformer: null, // Keeps track of the active performer ID for the popup
    };
  },
  props: ['title',"organizers"],
  mixins: [helpers],
  created() {
    var vm = this;
    console.log('header section data',vm.event);
  },
  mounted: function() {



  },
  computed: {
    iframeUrl: function () {
        return `https://maps.google.com/maps?q=${this.event.address_details.latitude},${this.event.address_details.longitude}&hl=es;z=14&output=embed` 
    },
    getDirectionUrl: function () {

      var vm = this;
      // debugger
      var eventLat = this.event.address_details.latitude;
      var eventLong = this.event.address_details.longitude;


      //Get GeoLocation location
      if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
              function (position) {
                  
                  // debugger

                  var currentLat = position.coords.latitude
                  var currentLong = position.coords.longitude
                  vm.directionURL = `https://www.google.com/maps/dir/?api=1&origin=${currentLat},${currentLong}&destination=${eventLat},${eventLong}&travelmode=`;
                  // $('#map-direction-btn').attr('href', directionURL);
                  console.log('Event map',vm.directionURL);
                  // return directionURL;
              },
              function (error) {
                  // debugger
                  console.log(error.message);
                  // return directionURL
              }, {
                  enableHighAccuracy: true
                  , timeout: 10000
              }
          );
      } else {
          alert("Geolocation is not supported by this browser.");
      }

      console.log('Get Direction URL',vm.directionURL);
      return vm.directionURL
        // return `https://maps.google.com/maps?q=${this.event.address_details.latitude},${this.event.address_details.longitude}&hl=es;z=14&amp;output=embed`
    }
  },
  components: {
    ContentLoader,
    FacebookLoader,
    VueSilentbox,
    'event_detail_follow_button': EventDetailFollowButton,
    'event_detail_like_button': EventDetailLikeButton,
    'venue_follow_button': VenueDetailFollowButton,
    // follow_venue: FollowVenue
  },
  methods: {
    togglePopup(performerId) {
            this.activePerformer = this.activePerformer === performerId ? null : performerId;
    },
    closePopup() {
        this.activePerformer = null;
    },
    handleOverlayClick() {
        this.closePopup();
    },
  },
  filters: {
    assetPath: function(img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
    url: function(path) {
      var path = window.axios.defaults.baseURL + "/" + path;
      return path;
    }
  }
};
</script>
<style scoped>
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
}

.popup-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.popup-content button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.popup-content button:hover {
    background-color: #0056b3;
}

</style>