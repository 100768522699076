<template>
  <!-- Footer Start -->
<footer class="page-footer">
    <section class="top-footer">
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-sm-6 col-xs-6">
                    <h6>Information</h6>
                    <ul>
                        <li><router-link :to="{ name: 'claim-business' }">Claim Business</router-link></li>
                        <li><router-link :to="{ name: 'contact-us' }">Contact Us</router-link></li>
                        <li><router-link :to="{ name: 'faqs' }">FAQ's</router-link></li>
                        <li><router-link :to="{ name: 'privacy-policy' }">Privacy Policy</router-link></li>
                        <li><router-link :to="{ name: 'terms-of-use' }">Terms of Use</router-link></li>
                        <!-- <li><router-link :to="{ name: 'coming-soon' }">FAQ's</router-link></li> -->
                        <!-- <li><router-link :to="{ name: 'coming-soon' }">Privacy Policy</router-link></li> -->
                        <!-- <li><router-link :to="{ name: 'coming-soon' }">Terms of Use</router-link></li> -->
                    </ul>
                </div>
                <div class="col-md-3 col-sm-6 col-xs-6">
                    <h6>Advertise with Us</h6>
                    <ul>
                        <!-- <li><a href="#">Why Us?</a></li> -->
                        <li><router-link :to="{ name: 'advertising-guidelines' }">Advertising Guidelines</router-link></li>
                        <li><router-link :to="{ name: 'image-size-matrix' }">Image Size Matrix</router-link></li>
                        <li><router-link :to="{ name: 'our-logo' }">Our Logo</router-link></li>
                        <li><router-link :to="{ name: 'pricing' }">Pricing </router-link></li>
                    </ul>
                </div>
                <div class="col-md-2 col-sm-6 col-xs-6 m-t-m clear-left">
                    <h6>Media</h6>
                    <ul>
                        <li><router-link :to="{ name: 'press-kit' }">Press Kit</router-link></li>
                        <li><router-link :to="{ name: 'tutorial' }">Tutorial </router-link></li>
                        <!-- <li><router-link :to="{ name: 'covid-19' }">Covid-19</router-link></li> -->
                    </ul>
                </div>
                <div class="col-md-2 col-sm-6 col-xs-6 m-t-m clear-left">
                    <h6>Venue Support</h6>
                    <ul>
                        <li><router-link :to="{ name: 'site-map' }">SiteMap </router-link></li>
                        <li><router-link :to="{ name: 'venue-live' }">Venue Live </router-link></li>
                        <li><router-link :to="{ name: 'venue-aid' }">Venue Aid </router-link></li>
                        <li><router-link :to="{ name: 'venue-store' }">Venue Store</router-link></li>
                        <li><router-link :to="{ name: 'venue-market' }">Venue Market</router-link></li>
                        <!-- <li><router-link :to="{ name: 'covid-19' }">Covid-19</router-link></li> -->
                    </ul>
                </div>
                <div class="col-md-2 col-sm-6 col-xs-6 m-t-m">
                    <h6>Company</h6>
                    <ul>
                        <li><router-link :to="{ name: 'ceoMessage' }">CEO Message</router-link></li>
                        <li><router-link :to="{ name: 'retired-digital' }">Retired Digital </router-link></li>
                        <li><router-link :to="{ name: 'careers' }">Careers </router-link></li>
                        <li><router-link :to="{ name: 'about' }">About Us</router-link></li>
                        <li><router-link :to="{ name: 'team' }">Team</router-link></li>
                        <li><router-link :to="{ name: 'vision' }">Vision</router-link></li>
                        <li><router-link :to="{ name: 'sneak-preview' }">Sneak Preview</router-link></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="bottom-footer">
        <div class="container">
            <div class="row border-top">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <p>&copy; {{ year}} VenueGPS</p>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <ul class="social-icons">
                        <li><a href="https://www.facebook.com/VENUEGPS/" target="_blank"><img :src="'/fb.svg'|  assetPath" /></a></li>
                        <li><a href="https://twitter.com/venuegps" target="_blank"><img :src="'/twt.svg'|  assetPath" /></a></li>
                        <li><a href="https://www.instagram.com/venuegps/" target="_blank"><img :src="'/insta.svg'|  assetPath"  /></a></li>
                        <li><a href="https://www.youtube.com/channel/UCaQ-SvgfP9_-b86k60BmUcQ/videos" target="_blank"><img :src="'/youtube.svg'|  assetPath"  /></a></li>
                        <li><a href="https://www.linkedin.com/in/venuegps-llc-0061a31a8/" target="_blank"><img :src="'icons/social/linkedin.png'|  assetPath"  alt="LinkedIn" /></a></li>
                        <!-- <li><a href="https://snapchat.com/add/venuegps" target="_blank"><img :src="'/snapchat.svg'|  assetPath"  /></a></li> -->
                        <!-- <li><a href="#"><img src="'images/blog.svg'|  assetPath" target="_blank" /></a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <input id="calendarStart_date" value="" type="hidden">
    <input id="calendarEnd_date" value="" type="hidden">
    <input id="selectedStart_date" value="" type="hidden">
    <input id="selectedEnd_date" value="" type="hidden">
</footer>
<!-- Footer End -->

</template>

<script>

  import { bus } from './../../app'
  import helpers from './../../../mixin/helpers';

  export default {
    data() {
      return {
          year: ''
      }
    },
    created (){
        var d = new Date();
        this.year = d.getFullYear();
    },
    mixins: [helpers],
  }
</script>