<template>
  <div>
    <header_section></header_section>
    <!-- Main Start -->
    <main class="top-inner-pages faqs-page">
      <!-- <section class="headerSectionWrapper">
        <div class="headerBGImage">
          <img :src="'banners/vgps-min.png' | assetPath" alt="header Image" />
        </div>
      </section> -->
      <!-- Dashboard Start -->
      <section class="dashboard vgps_contact_support_wrapper">
        <div class="container">
          <div class="row">
            <div class="col-md-12 vgps_page_header_wrapper">
              <h2>FAQ's</h2>
            </div>
            <div class="col-md-3 col-sm-4">
                <information_sidebar></information_sidebar>
            </div>
            <div class="col-md-9 col-sm-8">
              <div class="right-box">
                <div class="vgps_contact_form_header">
                  <h5>FAQ's</h5>
                  <p>Your question may have already been answered. See if it's in our FAQ's.</p>
                </div>
                <div class="vgps_kb_accordions_wrapper">
                  <div class="panel-group" id="accordion">

                    
                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h4 class="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#q1"
                          >1. Which department should businesses contact for questions and concerns while registering VenueGPS business accounts?</a>
                        </h4>
                      </div>
                      <div id="q1" class="panel-collapse collapse">
                        <div class="panel-body">
                          <p>Our VenueGPS Staff will provide 24-hour customer service to ensue your questions or concerns are answered to your satisfaction.  
                            VenueGPS' highest priority is the functionality of our business accounts. </p>
                        </div>
                      </div>
                    </div>


                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h4 class="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#q2"
                            aria-expanded="false"
                          >2. Is VenueGPS promotional marketplace limited to nightclubs and bars?</a>
                        </h4>
                      </div>
                      <div id="q2" class="panel-collapse collapse">
                        <div class="panel-body">
                          <p>No, VenueGPS supports sporting events, national holidays, and any entertainment venue 
                            that can be supported on our paperless platform.  
                            We also promote internet influencers and community projects that align with our values and mission statement and work within the framework of our objective. </p>
                        </div>
                      </div>
                    </div>

                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h4 class="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#q3"
                            aria-expanded="false"
                          >3. In the event that I experience account verification “technical difficulties:”, 
                          what will VenueGPS consider valid credentials to open my business account?</a>
                        </h4>
                      </div>
                      <div id="q3" class="panel-collapse collapse">
                        <div class="panel-body">
                          <p>To prove your authenticity, please DM VenueGPS via Instagram or Facebook services from your 
                            external business accounts for business validation.</p>
                        </div>
                      </div>
                    </div>

                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h4 class="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#q4"
                            aria-expanded="false"
                          >4. If my business account has not been created by the default VenueGPS account page team, what are my options when I don’t see my page?</a>
                        </h4>
                      </div>
                      <div id="q4" class="panel-collapse collapse">
                        <div class="panel-body">
                          <p>You will be instructed to sign on to create your business account. </p>
                        </div>
                      </div>
                    </div>

                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h4 class="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#q5"
                            aria-expanded="false"
                          >5. What happens if my email is already listed when I try to create an account?</a>
                        </h4>
                      </div>
                      <div id="q5" class="panel-collapse collapse">
                        <div class="panel-body">
                          <p>In the event your email is listed when attempting to create an account, you will need to reset your password. Type in your email and click ‘forgot password’.
                             This will automatically prompt VenueGPS to initiate a temporary password to your email. Sign in, reset your password.  </p>
                        </div>
                      </div>
                    </div>

                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h4 class="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#q6"
                            aria-expanded="false"
                          >6. Who does VenueGPS permit to claim an established business account?</a>
                        </h4>
                      </div>
                      <div id="q6" class="panel-collapse collapse">
                        <div class="panel-body">
                          <p>The original registrar of the account will be responsible for initial claimant of the account.  Following that, 
                            the site’s maintenance and updating can be delegated to the manager or appointed editor.</p>
                        </div>
                      </div>
                    </div>

                     <div class="panel panel-default">
                      <div class="panel-heading">
                        <h4 class="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#q7"
                            aria-expanded="false"
                          >7. Does VenueGPS permit appointed managers to control business operations, events and social media?</a>
                        </h4>
                      </div>
                      <div id="q7" class="panel-collapse collapse">
                        <div class="panel-body">
                          <p>The VenueGPS staff will grant delegated appointees full operational account access. Newly appointed account managers will be 
                            carefully vetted by Administrative Department to identify fraudulent activity and/or previously submitted account access request. </p>
                        </div>
                      </div>
                    </div>

                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h4 class="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#q8"
                            aria-expanded="false"
                          >8. What’s the VenueGPS timetable for the review and approval for a business account application?</a>
                        </h4>
                      </div>
                      <div id="q8" class="panel-collapse collapse">
                        <div class="panel-body">
                          <p>Business and venue owners should expect 48 to 96 hours for processing your request, background check, and approval. </p>
                        </div>
                      </div>
                    </div>


                     <div class="panel panel-default">
                      <div class="panel-heading">
                        <h4 class="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#q9"
                            aria-expanded="false"
                          >9. What is VenueGPS’ main source of communications with business account holders?</a>
                        </h4>
                      </div>
                      <div id="q9" class="panel-collapse collapse">
                        <div class="panel-body">
                          <p>VenueGPS will utilize email as the primary source of communications with account managers for all official business transactions. </p>
                        </div>
                      </div>
                    </div>

                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h4 class="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#q10"
                            aria-expanded="false"
                          >10. What control measures does VenueGPS have in place for identity theft prevention?</a>
                        </h4>
                      </div>
                      <div id="q10" class="panel-collapse collapse">
                        <div class="panel-body">
                          <p>VenueGPS has acquired top quality security hardware to protect you and your business from hackers.  An anti-cyber security team is on duty 24-hour to 
                            ensue preventive measures are maintained and ready to launch an investigation if an account is compromised. </p>
                        </div>
                      </div>
                    </div>


                     <div class="panel panel-default">
                      <div class="panel-heading">
                        <h4 class="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#q11"
                            aria-expanded="false"
                          >11. Following the VenueGPS business account approval, how do business account managers sign-in to take control of the mini-domain assigned by VenueGPS?</a>
                        </h4>
                      </div>
                      <div id="q11" class="panel-collapse collapse">
                        <div class="panel-body">
                          <p>VenueGPS will send the original account holder or all persons listed on the original request an email and link to activate your account.  
                            The email will navigate the owners or account manager back to their business domain. </p>
                        </div>
                      </div>
                    </div>


                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h4 class="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#q12"
                            aria-expanded="false"
                          >12. What advantages do businesses gain by advertising their organization with VenueGPS?</a>
                        </h4>
                      </div>
                      <div id="q12" class="panel-collapse collapse">
                        <div class="panel-body">
                          <p>VenueGPS was uniquely designed to benefit the following</p> 
                          <ul class="un-order-list">
                            <li>Track Users, website view transactions, gender, race, age and location</li> 
                            <li>List Contact Information & Highlight Social Media</li>
                            <li>We have over 14,000 zip codes with over 85,000 business accounts.</li> 
                            <li>Business hours</li>
                            <li>COVID-19, pandemic, epidemic, severe weather warning, and public safety notifications can be generated from VenueGPS.com</li>
                            <li>Advertise sales, business website, downloads from apps or music</li>
                          </ul>
                        </div>
                      </div>
                    </div>


                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h4 class="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#q13"
                            aria-expanded="false"
                          >13. What is VenueGPS’ policy when two businesses share the same or very similar names?</a>
                        </h4>
                      </div>
                      <div id="q13" class="panel-collapse collapse">
                        <div class="panel-body">
                          <p>VenueGPS will negotiate with one if the business owner to slightly alter the pronunciation, 
                            be creative with spelling, or just add an “s” to the end of possible. </p>
                        </div>
                      </div>
                    </div>

                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h4 class="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#q14"
                            aria-expanded="false"
                          >14. How does VenueGPS plan to handle national franchise or multi-business accounts?</a>
                        </h4>
                      </div>
                      <div id="q14" class="panel-collapse collapse">
                        <div class="panel-body">
                          <p>National or regional leads will verify accountability for the franchise in question.  
                            Once the representative is validated, he/she will provide VenueGPS a list of local account managers per region or city.</p>
                        </div>
                      </div>
                    </div>

                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h4 class="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#q15"
                            aria-expanded="false"
                          >15. Once a business takes control of their account, what service does VenueGPS provide the venue manager?</a>
                        </h4>
                      </div>
                      <div id="q15" class="panel-collapse collapse">
                        <div class="panel-body">
                          <p>VenueGPS will authorize managers three account management tools to optimize their business in autonomy.</p>
                          <ul class="un-order-list">
                              <li> Manager: Create, post, delete and manage business account </li>
                              <li> Editor: Create, post and delete events </li>
                              <li> Viewer: Coordinate with like businesses or local eateries to improve customer traffic and patronage  </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h4 class="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#q16"
                            aria-expanded="false"
                          >16. How does VenueGPS accommodate for business who desire to add musicians, comedians, poets, etc.,
                           on the actual day of an event?</a>
                        </h4>
                      </div>
                      <div id="q16" class="panel-collapse collapse">
                        <div class="panel-body">
                          <p>Musicians, acts, etc. can be added.  The account or appointed event manager will have full authority to add, alter, 
                            or remove performers or shows to an existing promotional VenueGPS approved event.</p>
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Dashboard End -->
    </main>
    <!-- Main End -->
    <footer_section></footer_section>
  </div>
</template>

<script>
import { bus } from "./app";
import HeaderSection from "./../views/partials/header";
import FooterSection from "./../views/partials/footer";
import Information_Sidebar from './../views/partials/information-sidebar'
import helpers from "./../mixin/helpers";

export default {
  data() {
    return {
      loadingContent: false,
    };
  },
  mixins: [helpers],
  components: {
    footer_section: FooterSection,
    header_section: HeaderSection,
    information_sidebar: Information_Sidebar
  },
};
</script>