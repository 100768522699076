<template>
  <div>
    <!-- Main Start -->
    <header_section></header_section>
    <main class="top-inner-pages">
      <!-- <section class="headerSectionWrapper">
        <div class="headerBGImage">
          <img :src="'banners/vgps-min.png' | assetPath" alt="header Image" />
        </div>
      </section> -->
      <!-- Dashboard Start -->
      <section class="dashboard vgps_contact_support_wrapper innerPageContentSec">
        <div class="container">
          <div class="row">
            <div class="col-md-12 vgps_page_header_wrapper text-center">
              <h2>Thank You!</h2>
            </div>
            <div class="col-md-12 vgps_privacy_content_wrapper privacyPolicyPage text-center">
              <div class="headerBGImage text-center">
                <img width="300px" :src="'icons/payment-okay.png' | assetPath" alt="header Image" />
              </div>
              <p class="text-center">
                you have successfully upgrade your subscribed to our Plan Pakage
              </p>

              <a class="payment-sucessp-btn" href="/">Back To Home</a>
            </div>
          </div>
        </div>
      </section>
      <!-- Dashboard End -->
    </main>
    <!-- Main End -->
    <footer_section></footer_section>
  </div>
</template>

<script>
import HeaderSection from "./../views/partials/header";
import FooterSection from "./../views/partials/footer";
import helpers from "./../mixin/helpers";

export default {
  data() {
    return {
      loadingContent: false,
    };
  },
  mixins: [helpers],
  components: {
    header_section: HeaderSection,
    footer_section: FooterSection,
  },
};
</script>