<template>
    <div>
        <date-picker v-model="display_start_date"
            value-type="YYYY-MM-DD"
            format="MM-DD-YYYY"
            :disabled-date="displayBackendNotBeforeToday"
            input-class="floating-label-field floating-label-field--s3 form-control" 
            placeholder="MM-DD-YYYY"
            @change="updateValues"
            :input-attr="{name:'',required:'required', id: 'start'}">
        </date-picker>
        <input type="text" id="display_start_date" required name="display_start_date" v-model="display_start_date">
    </div>
</template>
<script>

    import { bus } from '../../app'
    import helpers from '../../mixin/helpers';
    
    export default {
        props:['display_start_date'],
        watch: {},
        mixins: [helpers],
        created () {
            this.display_start_date = this.display_start_date;
        },
        computed: {
            // dateranges: function () {
            //     debugger
            // }
        },
        data: function () {
            return {

            } 
        },
        methods: {
            updateValues:function(data){
                this.display_start_date = data;
                bus.$emit('display_start_date_event', this.display_start_date);
            },
        },
    }

</script>
