<template>
  <section class="event-location venue-location-section vgps-event-detail-section-wrapper" id="mapdetail">
    <div class="containers">
      <div class="col-md-12 col-sm-12 col-xs-1vgps-event-detail-section-title">
        <h2>Location</h2>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="location-card">
          <div class="dec">
            <!-- <div class="vgps-location-thumb">
              <img :src="venue.thumbnail_urls.thumb_296" alt="" />
            </div> -->
            <div class="vgps-location-desc">
              <!-- <h6>
                <router-link :to="{ name: 'venue', params: { id: venue.id } }">
                  {{ venue.title }}
                </router-link>
              </h6> -->
              
              <div class="vgps-event-location-btn-wrapper">
                <p class="full-venue-address" v-html="fullAddress()"></p>
                <!-- <div class="follow-btns-wrapper">
                  <venue_follow_button
                    v-bind:id="venue.id"
                    v-bind:title="venue.title"
                    v-bind:follow="venue.follow"
                  >
                  </venue_follow_button>
                </div> -->

                <!-- <div
                  v-if="social_apps.length > 0"
                  class="socials social-share-btns-wrapper venueDetailSSIcons"
                >
                  <ul>
                    <li v-for="link in social_apps" :key="link.provider">
                      <a :href="link.value" :class="link.provider"
                        ><img :src="link.icon_url"
                      /></a>
                    </li>
                  </ul>
                </div> -->

                <a
                  v-if="getDirectionUrl"
                  id="map-direction-btn2"
                  :href="getDirectionUrl"
                  target="_blank"
                  class="vgps-event-get-directions"
                  >Directions</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 col-sm-12 col-xs-12">
        <iframe
          :src="iframeUrl"
          style="width: 100%; border: 0"
          height="350"
          frameborder="0"
          allowfullscreen=""
        ></iframe>
      </div>
    </div>
  </section>
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";

import VueSilentbox from "vue-silentbox"; //https://vuejsexamples.com/a-lightbox-inspired-vue-js-component/
import EventDetailFollowButton from "../../components/global/event-detail-follow-button.vue";
import EventDetailLikeButton from "../../components/global/detail-page-like.vue";
import VenueDetailFollowButton from "../../components/global/detail-follow-button.vue";

import {
  ContentLoader,
  FacebookLoader,
  CodeLoader,
  BulletListLoader,
  InstagramLoader,
  ListLoader,
} from "vue-content-loader";

export default {
  data() {
    return {
      loadingContent: false,
      directionURL: "",
    };
  },
  props: ["venue"],
  mixins: [helpers],
  created() {
    var vm = this;
    console.log("header section data", vm.event);
    console.log("Verify iframe url", vm.iframeUrl);
  },
  mounted: function () {},
  computed: {
    iframeUrl: function () {
      return `https://maps.google.com/maps?q=${this.venue.address_details.latitude},${this.venue.address_details.longitude}&hl=es;z=14&output=embed`;
    },
    social_apps: function () {
      return this.getSocialApps(this.venue.social_contacts);
    },
    getDirectionUrl: function () {
      var vm = this;
      // debugger
      var eventLat = this.venue.address_details.latitude;
      var eventLong = this.venue.address_details.longitude;

      //Get GeoLocation location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            // debugger

            var currentLat = position.coords.latitude;
            var currentLong = position.coords.longitude;
            vm.directionURL = `https://www.google.com/maps/dir/?api=1&origin=${currentLat},${currentLong}&destination=${eventLat},${eventLong}&travelmode=`;
            // $('#map-direction-btn').attr('href', directionURL);
            console.log("Event map", vm.directionURL);
            // return directionURL;
          },
          function (error) {
            // debugger
            console.log(error.message);
            // return directionURL
          },
          {
            enableHighAccuracy: true,
            timeout: 10000,
          }
        );
      } else {
        alert("Geolocation is not supported by this browser.");
      }

      console.log("Get Direction URL", vm.directionURL);
      return vm.directionURL;
      // return `https://maps.google.com/maps?q=${this.venue.address_details.latitude},${this.venue.address_details.longitude}&hl=es;z=14&amp;output=embed`
    },
  },
  components: {
    ContentLoader,
    FacebookLoader,
    VueSilentbox,
    event_detail_follow_button: EventDetailFollowButton,
    event_detail_like_button: EventDetailLikeButton,
    venue_follow_button: VenueDetailFollowButton,
    // follow_venue: FollowVenue
  },
  methods: {
    fullAddress: function () {
        let event = this.venue.address_details
        let address = ''
        address += (event.street) ? (event.street)+', ' : ''
        address += (event.street2) ? (event.street2) : ''
        address += (event.city) ? '<br> ' + (event.city) : ''
        address += (event.state) ? ', ' + (event.state) : ''
        address += (event.zip_code && event.zip_code != 0) ? ', ' + (event.zip_code) : ''
        address += (event.country) ? ', ' + (event.country) : ''
        return address;
    },
  },
  filters: {
    assetPath: function (img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
    
    url: function (path) {
      var path = window.axios.defaults.baseURL + "/" + path;
      return path;
    },
  },
};
</script>
