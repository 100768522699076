<template >
    <div class="result-not-found">
          <a><i class="fa fa-search"></i></a>
          <p>Result Not Found! </p>
        </div>

</template>

<script>

  import { bus } from './../../app'
  import helpers from './../../../mixin/helpers';

  export default {
    data() {
      return {}
    },
    mixins: [helpers],
  }
</script>