<template>
    <div class="my-event-vue-wrapper">
        <header_section></header_section>
        <main class="top-inner-pages filters">
            <section class="admin-dashboard vgps-notifications-page-wrapper">
                <div class="container-fluid">
                    <div class="row">
                        <div class="hidden-md hidden-sm hidden-xs col-lg-3 col-md-4 col-sm-12 col-xs-12">
                            <!-- @include('user.includes._partials.user-info-card') -->
                        </div>
                        <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                            <div class="right-box">
                                <div class="admin-dash-section-header">
                                    <h2>Notifications Settings</h2>
                                    <p>Set your notification settings  </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div id="sidebar" class="hidden-md hidden-sm hidden-xs col-lg-3 col-md-4 col-sm-12 col-xs-12">
                            <sidebar_section></sidebar_section>
                        </div>
                        <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                        <div class="right-box">
                            <div class="user-profile-options-wrapper">
                                <template v-if="loadingContent">
                                    <ContentLoader
                                        height="200"
                                        width="400"
                                        speed="1"
                                        viewBox="0 0 400 200"
                                        backgroundColor="#d9d9d9"
                                        foregroundColor="#ecebeb"
                                        >
                                        <rect x="15" y="15" rx="4" ry="4" width="130" height="10" />
                                        <rect x="155" y="15" rx="3" ry="3" width="130" height="10" />
                                        <rect x="295" y="15" rx="3" ry="3" width="90" height="10" />
                                        <rect x="15" y="50" rx="3" ry="3" width="90" height="10" />
                                        <rect x="115" y="50" rx="3" ry="3" width="60" height="10" />
                                        <rect x="185" y="50" rx="3" ry="3" width="200" height="10" />
                                        <rect x="15" y="90" rx="3" ry="3" width="130" height="10" />
                                        <rect x="160" y="90" rx="3" ry="3" width="120" height="10" />
                                        <rect x="290" y="90" rx="3" ry="3" width="95" height="10" />
                                        <rect x="15" y="130" rx="3" ry="3" width="130" height="10" />
                                        <rect x="160" y="130" rx="3" ry="3" width="225" height="10" />
                                    </ContentLoader>
                                </template>
                                <form class="form" id="user-profile"
                                    method="POST" enctype="multipart/form-data">
                                    
                                    <div class="admin-user-notifications-wrapper">
                                        <ul>
                                            <!-- @foreach($settings as $setting) -->
                                            <li v-for="(setting,index) in notifications" :key="index" 
                                                class="admin-user-notification">
                                                <div class="admin-user-notification-desc">
                                                    <h3>{{ setting.name }}</h3>
                                                    <p>{{ setting.explanation }}</p>
                                                </div>
                                                <div class="custom-checkbox-toggles">
                                                    <input type="checkbox" v-model="setting_ids" :value="setting.id"
                                                        :id="setting.code" class="customIosCheckbox ios-toggle"/>
                                                    <label :for="setting.code" class="checkbox-label" data-off="off"
                                                        data-on="on"></label>
                                                </div>
                                            </li>
                                            <!-- @endforeach -->
                                        </ul>
                                        <div class="row">
                                            <div class="form-group notifications-btn-wrapper">
                                                <div class="col-md-12 text-right">
                                                    <a @click="save" href="javascript:void(0)" value="save" class="btn save-btn pull-right">
                                                        <img v-if="!processing" :src="'add.svg'| assetPath" />
                                                        <span class="login-show-spinner" :class="{active : processing == true }">
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                        </span> Save
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <footer_section></footer_section>
    </div>
</template>

<script>

// Imports
import { bus } from '../../app'
import helpers from '../../mixin/helpers';
import selectConfig from "./../../mixin/select2configs";
import Select2 from "v-select2-component";

// import BannerSection from './../views/venue/header'
import My_Following_Events from './../../components/MyEvents.vue';
import FooterSection from './../../views/user/partials/footer'  
import HeaderSection from './../../views/user/partials/header'   
import Sidebar_Section from './../../views/user/partials/sidebar'   

import {
  ContentLoader,
  FacebookLoader,
  CodeLoader,
  BulletListLoader,
  InstagramLoader,
  ListLoader
} from "vue-content-loader";

export default {
    name:'MyProfileComponent',
    components: {
      ContentLoader,
      FacebookLoader,
      Select2,
      header_section: HeaderSection,
      my_following_events : My_Following_Events,
      footer_section: FooterSection,
      sidebar_section: Sidebar_Section
    },
    created: function(){
        this.getDetail();
    },
    watch: {
        // $route: "fetchData",
        state: function(newVal, oldVal) {
            this.getStateCities();
        },
        business_type: function(newVal, oldVal) {
            var vm = this;
            // vm.business_type = [];
            vm.$store.dispatch("getBusinessSubTypesAction", newVal);
        }
    },
    activated () {
        // this.$destroyKey()
    },
    props:[],
    mixins: [helpers],
    computed: {
        businessIdsOptions() {
            return this.$store.getters.businessIdsOptions;
        },
        businessSubTypes() {
            return this.$store.getters.businessSubTypes;
        },
        raceOptions() {
            return this.$store.getters.raceOptions;
        },
        genderOptions() {
            return this.$store.getters.genderOptions;
        },
        ageOptions() {
            return this.$store.getters.ageOptions;
        },
        FoodIdsOptions() {
            return this.$store.getters.FoodIdsOptions;
        },
        genreIdsOptions() {
            return this.$store.getters.genreIdsOptions;
        },
        statesData() {
      return this.$store.getters.addressesStatesData;
        },
        citiesData() {
        return this.$store.getters.getstateCities;
        }
    },
    data () {
        return {
          title: 'Venue Detail Component',
          loadingContent:false,
          processing:false,
          notifications: [],
          setting_ids: [],
          
        }
    },
    methods: {
        async getDetail() {
        
            var vm = this ;
            vm.loadingContent = true;
            axios.get("get/notifications/data")
            .then( (response) => {
                
                if (response.data.success == false) {

                    if(response.data.status_code == 403){
                        this.clearVueSession(response.data.template);
                    }else{
                        sweetalert2.fire("", response.data.description, "warning");
                    }
                    vm.$router.back()
                } else {
                    
                    // debugger
                    var data = response.data.data;
                    vm.notifications = response.data.data;
                    vm.setting_ids = response.data.ids
                    
                }
                vm.loadingContent = false;
            })
            .catch(function (errors) {
                console.log('user profile get exception',errors);
                sweetalert2.fire("", errors.data, "error");
                vm.loadingContent = false;
            });
        },
        save: function() {

            var vm = this;
            var data = new FormData();

            data.append("notification_ids", JSON.stringify(this.setting_ids));
            // data.append("setting_ids", this.setting_ids);
        

            // var validate = $("#user-profile").validate();
            // validate.form();

            // if (validate.errorList.length > 0) {
            //     sweetalert2.fire("", "please fill the required fields.", "fail");
            //     return false;
            // }

            if (vm.processing === true) {
                return false;
            }

            vm.processing = true;

            axios
                .post('update/notifications/data', data)
                .then(function(response) {
                    
                    if (response.data.success == true) {
                        sweetalert2.fire("", 'Notification updated successfully', "success");
                    } else {
                        vm.exceptionAlert(response)
                    }
                    vm.processing = false;
                })
                .catch(function(errors) {
                    console.log("Notification update exception", errors);
                    vm.exceptionAlert(errors)
                    vm.processing = false;
                });
            },
        getStateCities: function() {
            var vm = this;
            var stateData = jQuery.grep(this.$store.state.lookupData.states, function(
                obj
            ) {
                return obj.geoname_code == vm.state;
            });
            if (typeof stateData != "undefined" && stateData.length > 0) {
                this.$store.state.stateCitiesData = [];
                this.$store.state.stateCitiesData = stateData[0].city_set.map(el => {
                return { id: el.name, text: el.name };
                });
            }
        }
    }
}
</script>