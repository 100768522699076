<template>
    <section v-if="GeneralInfo.length > 0 || bookingInfo.length > 0 || bookingManagerInfo.length > 0" class="vgps-events-contact-info-wrapper vgps-venue-detail-section-wrapper" id="contact-info">
        <div class="containers">
            <div class="row">
                <div class="col-md-12 vgps-event-detail-section-title">
                    <h2>Contact Information</h2>
                </div>
            </div>
        </div>
        <div class="containers">
            <div class="row">
                <div class="vgps-events-contact-info-grid-wrapper">
                        <div v-if="GeneralInfo.length > 0"  class="vgps-events-contact-info-grid">
                            <div class="vgps-events-contact-info-grid-inner">
                                <h4>General</h4>
                                <ul>
                                    <template v-for="contact in GeneralInfo">
                                        <li v-if="contact.value && contact.provider == 'website'">
                                            <a :href="contact.value" class="contact-info-btn">Visit Business Website</a>
                                        </li>
                                        <li v-if="contact.value && contact.provider == 'press-email'">
                                            <a :href="'mailto:'+contact.value">{{ contact.value }}</a>
                                        </li>
                                        <li v-if="contact.value && contact.provider == 'phone'">
                                            <a :href="'tel:'+contact.value">{{ contact.value }}</a>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                        <div v-if="bookingInfo.length > 0" class="vgps-events-contact-info-grid">
                            <div class="vgps-events-contact-info-grid-inner">
                                <h4>Press/Booking</h4>
                                <ul>
                                    <template v-for="val in bookingInfo">
                                        <li v-if="val.provider && val.provider == 'booking-email'">
                                            <a :href="'mailto:'+val.value">{{ val.value }}</a>
                                        </li>
                                        <li v-if="val.provider && val.provider == 'phone'">
                                            <a :href="'tel:'+val.value">{{ val.value }}</a>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                        <div v-if="bookingManagerInfo.length > 0" class="vgps-events-contact-info-grid">
                        <div class="vgps-events-contact-info-grid-inner">
                            <h4>Press/Booking Manager</h4>
                            <ul>
                                    <template v-for="val in bookingManagerInfo">
                                    <li v-if="val.provider && val.provider == 'manager-email'">
                                        <a :href="'mailto:'+val.value">{{ val.value }}</a>
                                    </li>
                                    <li v-if="val.provider && val.provider == 'manager-number'">
                                        <a :href="'tel:'+val.value">{{ val.value }}</a>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";


export default {
  data() {
    return {
      //loadingContent: false,
    };
  },
  props: ["venue"],
  mixins: [helpers],
  mounted() {
    
  },
  created() {
    
    

  },
  computed: {
      bookingInfo: function(){
        var data = this.venue.contacts.booking.filter(function(obj){
            return obj.provider == 'booking-email' || obj.provider == 'phone'? true:false;  
        });
        return data;
      },
      bookingManagerInfo: function(){
        var data = this.venue.contacts.booking.filter(function(obj){
            return obj.provider == 'manager-email' || obj.provider == 'manager-number'? true:false;  
        });
        return data;
      },
      GeneralInfo: function(){
        var data = this.venue.contacts['info-contact'].filter(function(obj){
            return obj.provider == 'website' || obj.provider == 'phone' || obj.provider == 'press-email'? true:false;  
        });
        return data;
      }
  },
  components: {
    
  },
  methods: {
    
  },
  filters: {
    
  }
};
</script>
