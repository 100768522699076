<template>
  <div class="business-filter">
    <ul class="business-sub-category list-group list-group-flush">
      <li
        v-for="item in getFilteredCuisines"
        :key="item.amenity"
        class="list-group-item"
      >
        <!-- <div class="form-group vgps-custom-checkbox-wrapper">
              <label class="fancyRadioWrapper business-main-cat" :for="item.amenity">
                {{ item.amenity }} 
              </label>
          </div> -->
        <ul
          v-if="item.values.length > 0"
          class="business-sub-category list-group list-group-flush"
        >
          <li
            v-for="subitems in item.values.slice(0, 5)"
            :key="subitems.name"
            class="list-group-item"
          >
            <div class="form-group vgps-custom-checkbox-wrapper">
              <input
                type="checkbox"
                @change="advanceFilter"
                v-model="search_event_cuisines"
                name="search_event_cuisines"
                :value="subitems.name"
                :id="subitems.name"
              />
              <label :for="subitems.name">{{ subitems.name }}</label>
              <span class="item-count badge">{{ subitems.count }}</span>
            </div>
          </li>
          <li v-if="item.values.length > 5" class="list-group-item">
            <a
              href="javascript:void(0)"
              @click="cuisinesMoreOptions(item.amenity)"
              class="vgp-primary-link cusiniesLoadMoreBtn"
              data-toggle="modal"
              data-target="#food-more-option-model"
            >
              See all
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";
import { mapFields } from "vuex-map-fields";
import Select2 from "v-select2-component";

export default {
  components: {
    Select2,
  },
  data() {
    return {
      //
      event_cuisines: [],
    };
  },
  computed: {
    FoodCuisinesData() {
      return this.$store.getters.FoodCuisinesData;
    },
    getFilteredCuisines() {
      return this.$store.getters.getFilteredCuisines;
    },
    ...mapFields([
      "search_event_cuisines",
      "search_main_category",
      "moreCuisinesOptions",
    ]),
  },
  created: function () {
    var vm = this;
    // vm.search_event_cuisines = [];
  },
  mixins: [helpers],
  methods: {
    cuisinesMoreOptions: function (amenity) {
      this.moreCuisinesOptions = [];
      var data = this.getFilteredCuisines.filter(function (obj) {
        if (obj.amenity == amenity) {
          return true;
        }
      });
      this.moreCuisinesOptions = data.length > 0 ? data[0] : [];
    },
    advanceFilter: function () {
      var vm = this;
      // vm.search_main_category = vm.search_main_category == 'Trending' ? 'All': vm.search_main_category;
      bus.$emit("search_filter_emit");
    },
  },
  filters: {
    assetPath: function (img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
  },
};
</script>
