<template>
    <div class="venue-content filter-content">
        <div class="">
            <advance_filters :filters="filters"></advance_filters>
            <venue_listing :filters="filters"></venue_listing>
        </div>
    </div>
</template>

<script>

// Imports
import { bus } from '../app'
import advanceFilters from '../components/myvenues/advance-filter.vue';
import VenueListing from '../components/myvenues/venue-listing.vue';
import helpers from '../mixin/helpers';

const offset = 0;
const limit = 8;


export default {
    components: {
        'advance_filters': advanceFilters,
        'venue_listing': VenueListing,
    },
    created: function(){
        bus.$on('updateSessionFilters',(filters) => {
            var vm = this;
        });
    },
    props:[],
    mixins: [helpers],
    data () {
        return {
          title: 'MyVenue Component',
          filters: {
              location:'',
              event_genre:'',
              offset:offset,
              limit:limit,
          },
        }
    },
    methods: {

    }
}
</script>