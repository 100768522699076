<template>
    <div class="floating-label-wrap event-search-component">
        <vue-google-autocomplete
                :ref="address"
                id="map2"
                v-model="address"
                name="city"
                :country="['us', 'mx', 'ca', 'gb', 'ae']"

                classname="form-control floating-label-field floating-label-field--s3 "
                placeholder="Location"
                v-on:placechanged="getAddressData"
                v-on:no-results-found="noResultFound">
        </vue-google-autocomplete>
        <label for="map2" class="floating-label">Finding Address</label>
        <img :src="'search.svg' | assetPath" alt="search">
    </div>
</template>
<script>
    // Ref Types: address, cities,
    import { bus } from '../../app'
    import VueGoogleAutocomplete from 'vue-google-autocomplete'
    import helpers from '../../mixin/helpers';
    export default {
        components: {VueGoogleAutocomplete},
        mixins: [helpers],
        props:['event'],
        data: function () {
            return {
                address: '',
                location_city: '',
                latitude: '',
                longitude: '',
                isLocationEnable: false,
                showLocation: false,
                mapPreviewUrl:'',
                
            }
        },
        created (){

            bus.$on('businessAddressMap', (businessData) => {
            
                console.log('Update business address  data',businessData);
                this.address = businessData.address_details.street ? businessData.address_details.street+', ':'';
                this.address += businessData.address_details.full_address;
                this.address += ' '+businessData.address_details.zip_code;
                this.latitude = businessData.address_details.latitude;
                this.longitude = businessData.address_details.longitude;
                this.initMap('initMap');
            });

        },
        updated() {

          
                
        },
        mounted() {


            if(typeof  this.event != 'undefined' && this.event != ''){
                this.address = this.event.address_details.street ? this.event.address_details.street+', ':'';
                this.address += this.event.address_details.full_address;
                this.latitude = this.event.address_details.latitude;
                this.longitude = this.event.address_details.longitude;

            }else{
                this.address = '';//this.$session.get('default_location');
                this.latitude = '';//this.$session.get('default_location_lat');
                this.longitude = '';//.this.$session.get('default_location_long');
                this.event.address_details.latitude = '';
                this.event.address_details.longitude = '';
            }
            this.initMap('initMap');
        
        },
        methods: {
            updateVal: function () {
             
            },
            initMap: function (type,eventData) {

                this.mapPreviewUrl = 'https://maps.google.com/maps?q='+this.latitude+','+this.longitude+'&hl=es;z=14&amp;output=embed'
                $('#event-map-widget .map-ifram').html('<iframe src="'+this.mapPreviewUrl+'" height="220" width="100%" frameborder="0" allowfullscreen=""></iframe>')
                $('#event-map-widget').show()

            },
                /**
             * When the location not found */
            noResultFound: function (addressData, placeResultData, id) {
                console.log(addressData);
                $('#event-map-widget').hide();
            },
            /**
             * When the location found
             * @param {Object} addressData Data of the found location
             * @param {Object} placeResultData PlaceResult object
             * @param {String} id Input container ID
             */
            //  getAddressData44: function (addressData, placeResultData, id) {
            //         console.log('Get event location', addressData, placeResultData);
            //         var vm = this;
            //         this.noLocation = false;
            //         this.mapPreviewUrl = 'https://maps.google.com/maps?q=' + addressData.latitude + ',' + addressData.longitude + '&hl=es;z=14&amp;output=embed';
            //         $('#event-map-widget .map-ifram').html('<iframe src="' + this.mapPreviewUrl + '" height="220" width="100%" frameborder="0" allowfullscreen=""></iframe>');
            //         $('#event-map-widget').show();

            //         // Initialize variables for extracting state/region
            //         let state = '';
            //         let country = '';

            //         // Extract address components from placeResultData
            //         placeResultData.address_components.forEach(component => {
            //             if (component.types.includes('administrative_area_level_1')) {
            //                 // This is the state/region
            //                 state = component.long_name || component.short_name;
            //             }
            //             if (component.types.includes('country')) {
            //                 // This is the country
            //                 country = component.long_name;
            //             }
            //         });

            //         // Log the extracted state and country
            //         console.log('Extracted State:', state, 'Country:', country);

            //         // You can now use the state and country for further logic, e.g., auto-selecting the state dropdown

            //         var formatedData = $('#map2').val(); // placeResultData.formatted_address;
            //         formatedData = formatedData.split(',').reverse();

            //         var formattedAddress = placeResultData.formatted_address;

            //         if (formattedAddress.includes('-')) {
            //             formatedData = formattedAddress.replace(/-/g, ',').trim();
            //             console.log('Get event location with hyphen', formatedData);
            //         }

            //         console.log('Formated Data', formatedData);

            //         vm.latitude = addressData.latitude;
            //         vm.longitude = addressData.longitude;
            //         bus.$emit('updateLatLong', addressData, placeResultData, formatedData);

            //         return false;
            //     },

            
            getAddressData: function (addressData, placeResultData, id) {

                
                console.log('Get event location',addressData,placeResultData)    
                var vm = this
                this.noLocation = false;
                this.mapPreviewUrl = 'https://maps.google.com/maps?q='+addressData.latitude+','+addressData.longitude+'&hl=es;z=14&amp;output=embed'
                $('#event-map-widget .map-ifram').html('<iframe src="'+this.mapPreviewUrl+'" height="220" width="100%" frameborder="0" allowfullscreen=""></iframe>')
                $('#event-map-widget').show()

                // debugger
                var formatedData = $('#map2').val();//placeResultData.formatted_address;

                var formattedAddress = placeResultData.formatted_address;


                if (formattedAddress.includes('-')) {
                    formatedData = formattedAddress.replace(/-/g, ',').trim();
                    placeResultData.formatted_address = formatedData

                    // console.log('Get event location222', formatedData);    
                }

                formatedData = formatedData.split(',').reverse();

                // console.log('Formated Data', formatedData)
                    
                // $('#zipcode').val((typeof addressData.postal_code != 'undefined')?addressData.postal_code: '');
                vm.latitude = addressData.latitude;
                vm.longitude = addressData.longitude;

                            // Add condition to handle country-specific logic
                if (placeResultData.address_components) {
                    placeResultData.address_components.forEach(component => {
                        if (component.types.includes('administrative_area_level_1')) {
                            // Handle state or region
                            vm.state = component.short_name || component.long_name;  // Use either short name (GA) or long name (Dubai)
                        } else if (component.types.includes('country')) {
                            vm.country = component.short_name; // Capture country for state validation if needed
                        }
                    });
                }

                    // Now, search the state in lookup_cities from the store
                    var selectedState = this.$store.state.defaultPageData.lookup_cities.find(function (obj) {
                    // Match either by geoname_code (for states like GA) or by name (for states like Dubai)
                    return obj.geoname_code === vm.state || obj.name === vm.state;
                });

                // If a matching state is found, set it in the Vuex store or use it as needed
                if (selectedState) {
                    // console.log('Selected State:', selectedState);
                    // console.log('Selected State GeoCode:', selectedState.geoname_code);

                    vm.state = selectedState.geoname_code; // or any other relevant field from `selectedState`
                    
                    // this.$store.commit('SET_SELECTED_STATE', selectedState); // Optional: commit to Vuex store if needed

                    if (formatedData.length > 1) {
                        // console.log('formtedss', formatedData[1]  ),
                        // formatedData[1] = vm.state
                        addressData.administrative_area_level_1 = vm.state
                    }

                    } else {
                        console.warn('State not found in lookup_cities');
                }

                console.log('Address Data >',addressData)
                console.log('PlaceResult >',placeResultData)
                console.log('Formated Data >',formatedData)


                bus.$emit('updateLatLong',addressData,placeResultData,formatedData)
                return false;

            },

            // getAddressData2: function (addressData, placeResultData, id) {
            //     console.log('Get event location addressData', addressData);
            //     console.log('Get event location placeResultData', placeResultData);
                
            //     var vm = this;
            //     this.noLocation = false;
            //     this.mapPreviewUrl = 'https://maps.google.com/maps?q=' + addressData.latitude + ',' + addressData.longitude + '&hl=es;z=14&amp;output=embed';
                
            //     // Embed the map in the UI
            //     $('#event-map-widget .map-ifram').html('<iframe src="' + this.mapPreviewUrl + '" height="220" width="100%" frameborder="0" allowfullscreen=""></iframe>');
            //     $('#event-map-widget').show();

            //     // Extract components from the placeResultData object
            //     var addressComponents = placeResultData.address_components;
            //     var formattedAddress = placeResultData.formatted_address;
                
            //     var city = '';
            //     var state = '';
            //     var country = '';
                
            //     // Loop through the address components to extract the city, state, and country
            //     addressComponents.forEach(component => {
            //         if (component.types.includes('locality')) {
            //             city = component.long_name; // city or locality
            //         }
            //         if (component.types.includes('administrative_area_level_1')) {
            //             state = component.long_name; // state
            //         }
            //         if (component.types.includes('country')) {
            //             country = component.long_name; // country
            //         }
            //     });
                
            //     // Update the formatted address (convert hyphens to commas and clean up spacing)
            //     if (formattedAddress.includes('-')) {
            //         formattedAddress = formattedAddress.replace(/-/g, ',').trim();
            //     }
                
            //     // Auto-select latitude, longitude, state, and city
            //     vm.latitude = addressData.latitude;
            //     vm.longitude = addressData.longitude;

            //     // Set the city, state, and country in your form fields
            //     $('#city').val(city);
            //     $('#state').val(state);
            //     $('#country').val(country);

            //     // Emit the updated data for latitude and longitude
            //     bus.$emit('updateLatLong', addressData, placeResultData, formattedAddress);
                
            //     return false;
            // },
        }
    }

</script>