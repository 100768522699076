const API_URL = document.head.querySelector('meta[name="api_url"]').content;
import { bus } from '../app'

export default {
    data: function() {
        return {
            select2:{
                tags: {
                    tags: true,
                    multiple:true,
                    allowClear: true,
                    tokenSeparators: [",", "#",''],
                    placeholder: "Search & select multiple #Tags",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    closeOnSelect: true,
                    minimumInputLength: 1,
                    createTag: function (params) {
                        return undefined;
                        // var term = $.trim(params.term);
                        // if (term === '') {
                        //   return null;
                        // }
                        // return {
                        //   id: term,
                        //   text: '#'+term,
                        //   newTag: true // add additional parameters
                        // }
                    },
                    ajax: {
                        url: API_URL+'hashtags/',
                        dataType: 'json',
                        type: "GET",
                        quietMillis: 50,
                        data: function (params) {
                            var query = {
                                q: params.term,
                                offset:0,
                                limit:10
                            }
                            // Query parameters will be ?search=[term]&type=public
                            return query;
                        },
                        processResults: function (data) {

                            return {
                                results: $.map(data.payload.hashtags, function (item) {
                                    return {
                                        text: item.title_tier_price,
                                        slug: item.title,
                                        id: item.title
                                    }
                                })
                            };
                        }
                    }
                },
                faqs: {
                    tags: true,
                    allowClear: true,
                    multiple:true,
                    tokenSeparators: [",", "#",''],
                    placeholder: "Search & select multiple FAQ's",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    closeOnSelect: true,
                    minimumInputLength: 1,
                    createTag: function (params) {
                        return undefined;
                    },
                    ajax: {
                        url: API_URL+'faqs/',
                        dataType: 'json',
                        type: "GET",
                        quietMillis: 50,
                        data: function (params) {
                            var query = {
                                q: params.term,
                                // type: 'people',
                                offset:0,
                                limit:10
                            }
                            // Query parameters will be ?search=[term]&type=public
                            return query;
                        },
                        processResults: function (data) {

                            return {
                                results: $.map(data.payload.faqs , function (item) {
                                    return {
                                        text: item.question,
                                        slug: item.id,
                                        id: item.id
                                    }
                                })
                            };
                        }
                    }
                },
                performersConfig2: {
                    tags: true,
                    allowClear: true,
                    multiple:true,
                    tokenSeparators: [",", "#",''],
                    placeholder: "Performers/Guests",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: true,
                    closeOnSelect: true,
                    minimumInputLength: 1,
                    maximumSelectionLength:52,
                    createTag: function (params) {
                        return undefined;
                    },
                    ajax: {
                        url: API_URL+'venue/business-list/',
                        dataType: 'json',
                        type: "GET",
                        quietMillis: 50,
                        data: function (params) {
                            var query = {
                                q: params.term,
                                type: 'performer',
                                offset:0,
                                limit:100
                            }
                            // Query parameters will be ?search=[term]&type=public
                            return query;
                        },
                        processResults: function (data) {
                            return {
                                results: $.map(data.payload.business, function (item) {
                                    return {
                                        text: item.title_type,
                                        slug: item.title,
                                        id: item.id
                                    }
                                })
                            };
                        }
                    }
                },
                performer: {
                    tags: false,
                    allowClear: true,
                    multiple:true,
                    tokenSeparators: [",", "#",''],
                    placeholder: "Search & select multiple performers",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    closeOnSelect: true,
                    minimumInputLength: 1,
                    maximumSelectionLength:52,
                    createTag: function (params) {
                        return undefined;
                    },
                    ajax: {
                        url: API_URL+'venue/business-list/',
                        dataType: 'json',
                        type: "GET",
                        quietMillis: 50,
                        data: function (params) {
                            var query = {
                                q: params.term,
                                type: 'performer',
                                offset:0,
                                limit:100
                            }
                            // Query parameters will be ?search=[term]&type=public
                            return query;
                        },
                        processResults: function (data) {
                            return {
                                results: $.map(data.payload.business, function (item) {
                                    return {
                                        text: item.title_type,
                                        slug: item.title,
                                        id: item.id
                                    }
                                })
                            };
                        }
                    }
                },
                performer_flyer: {
                    tags: false,
                    allowClear: true,
                    multiple:false,
                    tokenSeparators: [",", "#",''],
                    placeholder: "Search Performer Flyer Image",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    closeOnSelect: true,
                    minimumInputLength: 1,
                    maximumSelectionLength:52,
                    createTag: function (params) {
                        return undefined;
                    },
                    ajax: {
                        url: API_URL+'venue/business-list/',
                        dataType: 'json',
                        type: "GET",
                        quietMillis: 50,
                        data: function (params) {
                            var query = {
                                q: params.term,
                                type: 'performer',
                                offset:0,
                                limit:100
                            }
                            // Query parameters will be ?search=[term]&type=public
                            return query;
                        },
                        processResults: function (data) {
                            return {
                                results: $.map(data.payload.business, function (item) {
                                    return {
                                        text: item.title_type,
                                        slug: item.title,
                                        id: item.id
                                    }
                                })
                            };
                        }
                    }
                },
                FoodTypesConfig: {
                    tags: false,
                    allowClear: true,
                    multiple:true,
                    tokenSeparators: [",", "#",''],
                    placeholder:  "select maximum 6 types",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    closeOnSelect: true,
                    minimumInputLength: 1,
                    maximumSelectionLength: 6,
                    createTag: function (params) {
                        return null;
                     },
                },
                activationStatusConfig: {
                    tags: false,
                    allowClear: true,
                    multiple:false,
                    tokenSeparators: [",", "#",''],
                    placeholder: "Select One",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    closeOnSelect: true,
                    // minimumInputLength: 1,
                    // maximumSelectionLength: 5,
                    createTag: function (params) {
                        return null;
                    },
                },
                severitySelect2Config: {
                    tags: false,
                    allowClear: true,
                    multiple:false,
                    placeholder: "Select One",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    closeOnSelect: true,
                    // templateResult: function (data, container) {
                    //     if (!data.id) {
                    //         return data.text;
                    //     }

                    //     return data.text
                    //     // var str = '<span class="severity-select2-option"><img class="image-responsive" src="'+data.icon_url+'"> Level '+data.level+'</span>';
                    //     // return str;
                    // },
                    createTag: function (params) {
                        return null;
                    },
                },
                powered_by: {
                    tags: false,
                    allowClear: true,
                    multiple:true,
                    tokenSeparators: [",", "#",''],
                    placeholder: "Search & select maximum 5",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    closeOnSelect: true,
                    minimumInputLength: 1,
                    maximumSelectionLength: 5,
                    createTag: function (params) {
                        return null;
                    },
                    ajax: {
                        url: API_URL+'venue/business-list/',
                        dataType: 'json',
                        type: "GET",
                        quietMillis: 50,
                        data: function (params) {
                            var query = {
                                q: params.term,
                                type: 'powered',
                                offset:0,
                                limit:100
                            }
                            // Query parameters will be ?search=[term]&type=public
                            return query;
                        },
                        processResults: function (data) {

                            return {
                                results: $.map(data.payload.business, function (item) {
                                    return {
                                        text: item.title_type,
                                        slug: item.title,
                                        id: item.id
                                    }
                                })
                            };
                        }
                    }
                },
                promoter: {
                    tags: true,
                    allowClear: true,
                    multiple:true,
                    tokenSeparators: [",", "#",''],
                    placeholder: "Search & select maximum 5",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    closeOnSelect: true,
                    minimumInputLength: 1,
                    maximumSelectionLength: 5,
                    createTag: function (params) {
                        return null;
                    },
                    ajax: {
                        url: API_URL+'venue/business-list/',
                        dataType: 'json',
                        type: "GET",
                        quietMillis: 50,
                        data: function (params) {
                            var query = {
                                q: params.term,
                                type: 'promoter',
                                offset:0,
                                limit:100
                            }
                            // Query parameters will be ?search=[term]&type=public
                            return query;
                        },
                        processResults: function (data) {

                            return {
                                results: $.map(data.payload.business, function (item) {
                                    return {
                                        text: item.title_type,
                                        slug: item.title,
                                        id: item.id
                                    }
                                })
                            };
                        }
                    }
                },
                promoter_flyer: {
                    tags: true,
                    allowClear: true,
                    multiple:false,
                    tokenSeparators: [",", "#",''],
                    placeholder: "Search Promoter for Flyer Image",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    closeOnSelect: true,
                    minimumInputLength: 1,
                    maximumSelectionLength: 5,
                    createTag: function (params) {
                        return null;
                    },
                    ajax: {
                        url: API_URL+'venue/business-list/',
                        dataType: 'json',
                        type: "GET",
                        quietMillis: 50,
                        data: function (params) {
                            var query = {
                                q: params.term,
                                type: 'promoter',
                                offset:0,
                                limit:100
                            }
                            // Query parameters will be ?search=[term]&type=public
                            return query;
                        },
                        processResults: function (data) {

                            return {
                                results: $.map(data.payload.business, function (item) {
                                    return {
                                        text: item.title_type,
                                        slug: item.title,
                                        id: item.id
                                    }
                                })
                            };
                        }
                    }
                },
                music_by: {
                    tags: true,
                    allowClear: true,
                    multiple:true,
                    tokenSeparators: [",", "#",''],
                    placeholder: "Search & select maximum 5",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    closeOnSelect: true,
                    minimumInputLength: 1,
                    maximumSelectionLength: 5,
                    createTag: function (params) {
                        return null;
                    },
                    ajax: {
                        url: API_URL+'venue/business-list/',
                        dataType: 'json',
                        type: "GET",
                        quietMillis: 50,
                        data: function (params) {
                            var query = {
                                q: params.term,
                                type: 'music',
                                offset:0,
                                limit:100
                            }
                            // Query parameters will be ?search=[term]&type=public
                            return query;
                        },
                        processResults: function (data) {

                            return {
                                results: $.map(data.payload.business, function (item) {
                                    return {
                                        text: item.title_type,
                                        slug: item.title,
                                        id: item.id
                                    }
                                })
                            };
                        }
                    }
                },
                hosted_by: {
                    tags: true,
                    allowClear: true,
                    multiple:true,
                    tokenSeparators: [",", "#",''],
                    placeholder: "Search & select maximum 5",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    closeOnSelect: true,
                    minimumInputLength: 1,
                    maximumSelectionLength: 5,
                    createTag: function (params) {
                        return null;
                    },
                    ajax: {
                        url: API_URL+'venue/business-list/',
                        dataType: 'json',
                        type: "GET",
                        quietMillis: 50,
                        data: function (params) {
                            var query = {
                                q: params.term,
                                type: 'hosted',
                                offset:0,
                                limit:100
                            }
                            // Query parameters will be ?search=[term]&type=public
                            return query;
                        },
                        processResults: function (data) {

                            return {
                                results: $.map(data.payload.business, function (item) {
                                    return {
                                        text: item.title_type,
                                        slug: item.title,
                                        id: item.id
                                    }
                                })
                            };
                        }
                    }
                },
                media: {
                    tags: true,
                    allowClear: true,
                    multiple:true,
                    tokenSeparators: [",", "#",''],
                    placeholder: "Search & select maximum 5",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    closeOnSelect: true,
                    minimumInputLength: 1,
                    maximumSelectionLength: 5,
                    createTag: function (params) {
                        return null;
                    },
                    ajax: {
                        url: API_URL+'venue/business-list/',
                        dataType: 'json',
                        type: "GET",
                        quietMillis: 50,
                        data: function (params) {
                            var query = {
                                q: params.term,
                                type: 'media',
                                offset:0,
                                limit:100
                            }
                            // Query parameters will be ?search=[term]&type=public
                            return query;
                        },
                        processResults: function (data) {

                            return {
                                results: $.map(data.payload.business, function (item) {
                                    return {
                                        text: item.title_type,
                                        slug: item.title,
                                        id: item.id
                                    }
                                })
                            };
                        }
                    }
                },
                business: {
                    tags: true,
                    allowClear: true,
                    multiple:false,
                    tokenSeparators: [",", "#",''],
                    placeholder: "Select One",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    closeOnSelect: true,
                    minimumInputLength: 1,
                    createTag: function (params) {
                       return null;
                    },
                    ajax: {
                        url: API_URL+'venue/business-list/',
                        dataType: 'json',
                        type: "GET",
                        quietMillis: 50,
                        data: function (params) {
                            var query = {
                                q: params.term,
                                type:'',
                                offset:0,
                                limit:100
                            }
                            // Query parameters will be ?search=[term]&type=public
                            return query;
                        },
                        processResults: function (data) {

                            return {
                                results: $.map(data.payload.business, function (item) {
                                    return {
                                        text: item.title_type,
                                        slug: item.title,
                                        id: item.id
                                    }
                                })
                            };
                        }
                    }
                },
                claim_business: {
                    tags: true,
                    allowClear: true,
                    multiple:false,
                    tokenSeparators: [",", "#",''],
                    placeholder: "Select One",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    selectOnBlur: true,
                    closeOnSelect: true,
                    minimumInputLength: 1,
                    createTag: function (params) {
                       return null;
                    },
                    ajax: {
                        url: API_URL+'venue/unclaimed-business',
                        dataType: 'json',
                        type: "GET",
                        quietMillis: 50,
                        data: function (params) {
                            var query = {
                                q: params.term,
                                offset:0,
                                limit:50
                            }
                            // Query parameters will be ?search=[term]&type=public
                            return query;
                        },
                        processResults: function (data) {

                            if(data.payload.content.length == 0) {
                                bus.$emit('claimBusinessNotFound');
                                
                                return {
                                    results : {
                                        text: 'Results not Found!',
                                        id: '0'
                                    }
                                }

                            }else {

                                return {
                                    results: $.map(data.payload.content, function (item) {
                                        return {
                                            text: item.title_type,
                                            slug: item.title,
                                            id: item.id
                                        }
                                    })
                                };
                            }
                        }
                    }
                },
                editEventBusiness: {
                    tags: true,
                    allowClear: true,
                    multiple:false,
                    disabled:true,
                    tokenSeparators: [",", "#",''],
                    placeholder: "Select One",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    closeOnSelect: true,
                    minimumInputLength: 1,
                    createTag: function (params) {
                       return null;
                    },
                    ajax: {
                        url: API_URL+'venue/business-list/',
                        dataType: 'json',
                        type: "GET",
                        quietMillis: 50,
                        data: function (params) {
                            var query = {
                                q: params.term,
                                type:'',
                                offset:0,
                                limit:100
                            }
                            // Query parameters will be ?search=[term]&type=public
                            return query;
                        },
                        processResults: function (data) {

                            return {
                                results: $.map(data.payload.business, function (item) {
                                    return {
                                        text: item.title_type,
                                        slug: item.title,
                                        id: item.id
                                    }
                                })
                            };
                        }
                    }
                },
                city: {
                    tags: false,
//                    allowClear: true,
                    multiple:false,
                    placeholder: "City",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    closeOnSelect: true,
                    minimumInputLength: 1,
                    maximumSelectionLength: 5,
                    createTag: function (params) {
                        return null;
                    },
                    ajax: {
                        url: API_URL+'main/cities/',
                        dataType: 'json',
                        type: "GET",
                        quietMillis: 50,
                        data: function (params) {
                            var query = {
                                q: params.term,
                                offset:0,
                                limit:100
                            }
                            // Query parameters will be ?search=[term]&type=public
                            return query;
                        },
                        processResults: function (data) {

                            console.log(data);
                            return {
                                results: $.map(data.payload.content, function (item) {
                                    return {
                                        text: item.name,
                                        slug: item.name,
                                        id: item.id
                                    }
                                })
                            };
                        }
                    },
                 
                },
                event_category: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                },
                theme_hash: {
                    tags: false,
                    multiple:true,
                    allowClear: true,
                    placeholder: "Select Multiple Theme #Tags",
                    selectOnClose: false,
                    closeOnSelect: true,
                    dropdownCssClass:'hashthemes-select2'
                },
                business_genre: {
                    tags: false,
                    multiple:true,
                    allowClear: true,
                    placeholder: "Select Multiple Geners",
                    selectOnClose: false,
                    closeOnSelect: true,
                    dropdownCssClass:'hashthemes-select2'
                },
                event_type: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                    
                },
                single_select2_default_config: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                    
                },
                theme_hash: {
                    tags: false,
                    multiple:true,
                    allowClear: true,
                    placeholder: "Select Multiple Theme #Tags",
                    selectOnClose: false,
                    closeOnSelect: true,
                    dropdownCssClass:'hashthemes-select2'
                },
                edit_event_type: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                    disabled:true
                },
                ticket_type: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                },
                otherticket_type: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                },
                rightticket_type: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                },
                event_theme: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                },
                event_genre: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                },
                category: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                },
                sub_category: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                },
                parking_type: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                },
                business_type_select: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                },

            }
        };
    },
    created: function () {
        console.log('Printing from the Mixin');
    },
    methods: {
        displayMessage: function () {
            console.log('Now printing from a mixin function');
        },
        limitStr: function (str,limit) {
            
            var limitStr = str;
            if(str.length > limit ){
                limitStr = str.substring(0,limit)+".."
            }
            return limitStr;
        },
        isActiveFilter:function(value){
            return value != ''? true :false;
        },
        isActiveDateFilter:function(date){
            return date.start != '' & date.end != ''? true :false;
        },
        url: function (path) {
            var path = window.axios.defaults.baseURL+'/'+path;
            return path;
        }
        
    },
    filters: {

        assetPath: function (img) {
            var path = window.axios.defaults.baseURL+'/images/'+img;
            return path;
        },
        fullAddress: function (event) {
            var address = event.city
            address += (event.state != null)?  ', '+ (event.state) : ''
            address += (event.zip_code != null) ? ', '+ (event.zip_code) : ''
            return address;
        },
        checkObj: function (obj) {
            return (obj == '')?false:true
        },
        url: function (path) {
            var path = window.axios.defaults.baseURL+'/'+path;
            return path;
        }
    }
}