<template>
    <section class="event-about vgps-event-detail-section-wrapper" id="about-business">
        <div class="containers">
            <div class="row">
                <div class="col-md-12 vgps-event-detail-section-title">
                    <h2>About Business</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 vgps-about-event-content-wrapper" :class="[ venue.venue_bio.length < 1000 ?'show-content':'',readMore?'show-content':'' ]">
                    <p v-html="venue.venue_bio"></p>
                </div>
                <!-- @if(strlen($eventDetail->about) > 1000) -->
                    <div v-if="venue.venue_bio.length > 1000" class="col-md-12 vgps-about-event-toggle-btn-wrapper">
                         <button  @click="readMore = !readMore" class="vgps-event-content-toggle-btn">View More</button>
                    </div>
                <!-- @endif -->
            </div>
        </div>
    </section>
    <!-- <section id="about-business" class="event-about vgps-venue-detail-section-wrapper">
            <div class="containers">
                <?php $showmoreContent = (strlen($venueDetail->venue_bio) < 500) ? 'show-content' : '' ?>
                <div class="row">
                    <div class="col-md-12 vgps-about-event-content-wrapper {{ $showmoreContent }}">
                        <p>{!! $venueDetail->venue_bio !!}</p>
                    </div>
                    @if(strlen($venueDetail->venue_bio) > 500)
                        <div class="col-md-12 vgps-about-event-toggle-btn-wrapper">
                            <a href="#" class="vgps-event-content-toggle-btn">View More</a>
                        </div>
                    @endif
                </div>
            </div>
        </section> -->
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";


export default {
  data() {
    return {
      //loadingContent: false,
      readMore:false
    };
  },
  props: ["venue"],
  mixins: [helpers],
  created() {
    
  },
  components: {
    
  },
  methods: {
    
  },
  filters: {
    
  }
};
</script>
