<template>
  <div>
    <header_section></header_section>
    <main
      id="claim-business-form"
      class="top-inner-pages vgps_contact_support_wrapper claimBusinessWrapper"
    >
      <!-- <section class="headerSectionWrapper">
        <div class="headerBGImage">
          <img :src="'banners/vgps-min.png' | assetPath" alt="header Image" />
        </div>
      </section> -->
      <section class="vgps_contact_support_wrapper dashboard">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <h2>Find Your Business</h2>
              <p>You can search venue, people and community business accounts.</p>
            </div>  
            <div class="col-lg-3 col-md-4 col-sm-5 col-xs-12">
              <information_sidebar></information_sidebar>  
              <!-- <div class="menu-box">
                <ul>
                  <li><router-link class="active" :to="{ name: 'claim-business' }">Claim Your Business</router-link></li>
                  <li><router-link  :to="{ name: 'faqs' }">Knowledge Base</router-link></li>
                  <li><router-link  :to="{ name: 'contact-support' }">Contact Support</router-link></li>
                </ul>
              </div> -->
            </div>  
            <div class="col-lg-7 col-md-8 col-sm-7 col-xs-12 vgps_page_header_wrapper">
              <h5>Search Your Business</h5>
              <div class="row">
                <div class="form-group">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="floating-label-wrap">
                      <Select2
                        v-model="venue_id"
                        name="venue_id"
                        id="venue_id"
                        placeholder="Business"
                        @change="getBusinessDetail()"
                        :options="businessSelectOptions"
                        :settings="select2.claim_business"
                      />
                      <label for="business" class="floating-label">Business Name</label>
                    </div>
                  </div>
                </div>
              </div>

              <form v-if="venue_id" action="#" id="claim-business-request-page" class="form" @keyup.enter.prevent="save">
                  <!-- <input type="hidden" id="venue_id"/> -->
                  <!-- <input type="hidden" id="view_type"/> -->
                  <h4>Enter Your Information</h4>
                  <!-- <p class="p-m-t">Enter your information.</p> -->
                  <!-- @include('frontend.messages.alert') -->
                  <!-- <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="form-group  floating-label-wrap">
                            <input
                                type="text"
                                id="business_name"
                                name="business_name"
                                class="floating-label-field floating-label-field--s3 form-control"
                                v-model="business_name"
                                maxlength="65"
                                placeholder="Business Name"
                                required="required"/>
                            <label for="business_name" class="floating-label">Business Name</label>    
                        </div>
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="form-group  floating-label-wrap">
                            <input
                                type="text"
                                id="first_name"
                                name="first_name"
                                class="floating-label-field floating-label-field--s3 form-control"
                                v-model="first_name"
                                maxlength="65"
                                placeholder="Enter Your Name"
                                required="required"/>
                            <label for="first_name" class="floating-label">Name</label>    
                        </div>
                    </div>
                    <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group  floating-label-wrap">
                        <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            class="floating-label-field floating-label-field--s3 form-control"
                            v-model="last_name"
                            maxlength="65"
                            placeholder="Last Name"
                            required="required"/>
                            <label for="last_name" class="floating-label">Last Name</label>
                        </div>
                    </div> -->
                  </div>
                  <div class="form-group floating-label-wrap">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      required="required"
                      class="floating-label-field floating-label-field--s3 form-control"
                      v-model="email"
                      placeholder="Email"
                    />
                    <label for="email" class="floating-label">Email</label>
                  </div>
                  <div class="form-group floating-label-wrap">
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      class="floating-label-field floating-label-field--s3 form-control"
                      v-model="phone"
                      maxlength="17"
                      placeholder="Phone Number"
                      required="required"
                    />
                    <label for="phone" class="floating-label">Phone Number</label>
                  </div>

                  <!-- <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <claim_business_location_picker></claim_business_location_picker>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                            <div class="row">
                                                <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                                                    <div class="form-group">
                                                    <div class="floating-label-wrap">
                                                        <input type="text" class="form-control floating-label-field floating-label-field--s3"
                                                            v-model="latitude"
                                                            value=""
                                                            name="latitude"
                                                            placeholder="Latitude"
                                                            id="latitude">
                                                        <label for="latitude" class="floating-label">Latitude</label>
                                                    </div>
                                                </div>
                                            </div>
                                                <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                                                    <div class="form-group">
                                                    <div class="floating-label-wrap">
                                                        <input type="text"
                                                            value=""
                                                            name="longitude"
                                                            placeholder="Longitude"
                                                            v-model="longitude"
                                                            class="form-control floating-label-field form-control floating-label-field--s3"
                                                            id="longitude">
                                                        <label for="longitude" class="floating-label">Longitude</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                    <div class="floating-label-wrap">
                                                    <Select2  name="city_id" id="city_id"
                                                        v-model="city_id" placeholder="Select City"
                                                        :options="citiesData"
                                                        required
                                                        :settings="{placeholder:'Select One',allowClear: true}"/>
                                                    <label for="city_id" class="floating-label">City</label>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                <div class="floating-label-wrap">
                                                    <Select2  name="state" id="state"
                                                        v-model="state" placeholder="Select State"
                                                        required
                                                        :options="statesData"
                                                        @change="getStateCities()"
                                                        :settings="{placeholder:'Select One',allowClear: true}"/>
                                                    <label for="zipcode" class="floating-label">State</label>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="floating-label-wrap">
                                                <input type="number"
                                                    value=""
                                                    maxlength="5"
                                                    v-model="zip_code"
                                                    class="form-control floating-label-field form-control floating-label-field--s3"
                                                    name="zip_code"
                                                    id="zipcode" placeholder="Zipcode">
                                                <label for="zipcode" class="floating-label">Zipcode</label>
                                            </div>
                                        </div>
                                    </div>
                  </div>-->
                  <div class="form-group">
                    <a
                      @click="save"
                      href="javascript:void(0)"
                      value="save"
                      class="btn save-btn col-md-4 col-lg-2 col-sm-6 col-xs-12">
                      <!-- <img v-if="!processing" :src="'add.svg'| assetPath" /> -->
                      <span class="login-show-spinner" :class="{active : processing == true }">
                        <i class="fa fa-spinner fa-spin"></i>
                      </span> Claim Now
                    </a>
                  </div>
                </form>
                <div v-if="venue_id == ''" class="result-not-found">
                    <a>
                    <i class="fa fa-search"></i>
                    </a>
                    <!-- <h3>Search your Business!</h3> -->
                    <p>Signup and register your business with VenueGPS.</p>
                    <a
                    onclick="$('#loginModal').modal('hide')"
                    class="btn btn-default"
                    href="javascript:void(null);"
                    id="signUp"
                    data-toggle="modal"
                    data-target="#signUpModal"
                    >Signup</a>
                </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer_section></footer_section>
  </div>
</template>
<script>
import { bus } from "../app";
import Select2 from "v-select2-component";
import ClaimBusinessLocationPicker from "../components/global/claim-business-location-picker.vue";
import selectConfig from "../mixin/select2configs";

import Information_Sidebar from './../views/partials/information-sidebar' 
 

import HeaderSection from "./../views/partials/header";
import FooterSection from "./../views/partials/footer";
import helpers from "./../mixin/helpers";

export default {
  data() {
    return {
      loadingContent: false,
      processing: false,
      isSearch: false,
      first_name: "",
      last_name: "",
      business_name: "",
      city_id: "",
      state: "",
      businessSelectOptions: [],
      street: "",
      latitude: "",
      longitude: "",
      venue_id: "",
      logitude: "",
      zip_code: "",
      email: "",
      phone: "",
    };
  },
  mixins: [helpers, selectConfig],
  components: {
    Select2,
    header_section: HeaderSection,
    footer_section: FooterSection,
    claim_business_location_picker: ClaimBusinessLocationPicker,
    information_sidebar: Information_Sidebar
  },
  created() {
    var vm = this;
    bus.$on("get_claim_request_data", (data) => {
      vm.loadingClaimData = true;
      vm.claimData = "";
    });
    bus.$on("claim_request_data", (data) => {
      vm.claimData = data;
      vm.loadingClaimData = false;
    });

    bus.$on("claimBusinessNotFound", (data) => {
      vm.isSearch = true;
      vm.venue_id = "";
      vm.business_name = '';
    });

    bus.$on(
      "updateClaimLocationLatLong",
      (addressData, placeData, formatedData) => {
        var vm = this;
        vm.city_id = formatedData[2].trim();
        vm.state = addressData.administrative_area_level_1;
        vm.latitude = addressData.latitude;
        vm.longitude = addressData.longitude;

        if (formatedData.length > 4) {
          vm.street = formatedData[4].trim();
          vm.street += ", " + formatedData[3].trim();
        } else {
          vm.street = formatedData[3].trim();
        }

        if (
          typeof addressData.postal_code != "undefined" &&
          addressData.postal_code != ""
        ) {
          vm.zip_code = addressData.postal_code;
        }
      }
    );
  },
  watch: {
    state: function (newVal, oldVal) {
      this.getStateCities();
    },
    venue_id: function (newVal, oldVal) {
      if (newVal == null) {
        this.isSearch = true;
      } else {
        this.isSearch = false;
      }
    },
  },
  computed: {
    statesData() {
      return this.$store.getters.addressesStatesData;
    },
    citiesData() {
      return this.$store.getters.getstateCities;
    },
  },
  methods: {
    save: function () {
      var vm = this;
      var data = new FormData();
      data.append("first_name", vm.first_name);
      // data.append("last_name", vm.last_name);
      data.append("email", vm.email);
      data.append("venue_id", $("#venue_id").val());

      var viewType = $("#view_type").val();

      // Take Business Address
      // Stringify address information
      var address = JSON.stringify({
        street: vm.street,
        zip_code: vm.zip_code,
        city: vm.city_id,
        state: vm.state,
        latitude: vm.latitude,
        longitude: vm.longitude,
      });
      // data.append("address", address);

      // Stringify Contact information
      var contacts = JSON.stringify([
        { type: "info-contact", provider: "phone", value: vm.phone },
        // { "type": "booking", "provider": "phone", "value": vm.mobile },
      ]);
      data.append("contacts", contacts);

      var validate = $("#claim-business-request-page").validate({
         wrapper: "span",
         errorElement: "span",
      });
      validate.form();
      if (validate.errorList.length > 0) {
        console.log("Fill form required fields");
      } else {
        if (vm.processing === true) {
          return false;
        }
        vm.processing = true;

        axios
          .post("/send/claim-request", data)
          .then(function (response) {

            if (response.data.success !== false) {

              vm.first_name = "";
              vm.last_name = "";
              vm.street = "";
              vm.venue_id = "";
              vm.email = "";
              vm.city_id = "";
              vm.state = "";
              vm.latitude = "";
              vm.longitude = "";
              vm.zip_code = "";
              vm.phone = "";

              sweetalert2.fire("", response.data.description, "success");

            } else {
              sweetalert2.fire("", response.data.description, "error");
            }
            vm.processing = false;
          })
          .catch(function (errors) {
            vm.processing = false;
            sweetalert2.fire("", "Something went wrong", "error");
          });
      }
    },
    getStateCities: function () {
      var vm = this;
      if (vm.$store.state.lookupData) {
        var stateData = jQuery.grep(
          vm.$store.state.lookupData.states,
          function (obj) {
            return obj.geoname_code == vm.state;
          }
        );

        if (typeof stateData != "undefined" && stateData.length > 0) {
          this.$store.state.stateCitiesData = [];
          this.$store.state.stateCitiesData = stateData[0].city_set.map(
            (el) => {
              return { id: el.name, text: el.name };
            }
          );
        }
      }
    },
    getBusinessDetail: function () {
      var vm = this;
      axios
        .get("data/venue-detail/" + this.venue_id)
        .then(function (response) {
          var data = response.data.data.payload;
          if (response.data.data.success) {
            if (data) {
              vm.isSearch = false;
              vm.business_name = data.title_type 
            } else {
              vm.isSearch = true;
            }
          } else {
            vm.isSearch = true;
          }
        })
        .catch(function (errors) {
          vm.isSearch = true;
        });
    },
  },
};
</script>