<template>
  <div class="filter-top-bar">
    <div class="container">
      <div class="row">
        <div class="col-md-12 vgps-mobile-horizontal-scroll-wrapper">
          <span class="vgps-scroll-left"></span>
          <span class="vgps-scroll-right"></span>
          <div
            id="myBtnContainer"
            class="vgps-horizontal-scroll-wrapper homePageFilters"
          >
            <button
              @click="setActive('ceo')"
              :class="{ active: isActive('ceo') }"
              class="btn ceo menuForDesktop"
            >
              Trending
            </button>

            <button
              @click="setActive('ceo')"
              :class="{ active: isActive('ceo') }"
              class="btn ceo menuForMobile"
              v-scroll-to="'#rendingEventLink'"
              id="rendingEventLink"
            >
              Trending
            </button>
            <button
              @click="setActive('All')"
              :class="{ active: isActive('All') }"
              class="btn all menuForDesktop"
            >
              All
            </button>
            <button
              @click="setActive('All')"
              :class="{ active: isActive('All') }"
              class="btn all menuForMobile"
              v-scroll-to="'#allEventsLink'"
              id="allEventsLink"
            >
              All
            </button>
            <button
              @click="setActive('week')"
              :class="{ active: isActive('week') }"
              class="btn week menuForDesktop"
            >
              This week
            </button>
            <button
              @click="setActive('week')"
              :class="{ active: isActive('week') }"
              class="btn week menuForMobile"
              v-scroll-to="'#thisWeekEventsLink'"
              id="thisWeekEventsLink"
            >
              This week
            </button>

            <button
              @click="setActive('Media')"
              :class="{ active: isActive('Media') }"
              class="btn international menuForDesktop"
            >
            Attractions
            </button>
            <button
              @click="setActive('Media')"
              :class="{ active: isActive('Media') }"
              class="btn international menuForMobile"
              v-scroll-to="'#mediaEventsLink'"
              id="mediaEventsLink"
            >
            Attractions
            </button>
            
            <button
              @click="setActive('NightLife')"
              :class="{ active: isActive('NightLife') }"
              class="btn night-life menuForDesktop"
            >
            Dine & Drinks 
            </button>
            <button
              @click="setActive('NightLife')"
              :class="{ active: isActive('NightLife') }"
              class="btn night-life menuForMobile"
              v-scroll-to="'#nightLifeEventsLink'"
              id="nightLifeEventsLink"
            >
            Dine & Drinks
            </button>
            <button
              @click="setActive('Entertainment')"
              :class="{ active: isActive('Entertainment') }"
              class="btn entertainment menuForDesktop"
            >
              Entertainment
            </button>
            <button
              @click="setActive('Entertainment')"
              :class="{ active: isActive('Entertainment') }"
              class="btn entertainment menuForMobile"
              v-scroll-to="'#entertainmentEventsLink'"
              id="entertainmentEventsLink"
            >
              Entertainment
            </button>
            <button
              @click="setActive('Sports')"
              :class="{ active: isActive('Sports') }"
              class="btn sports menuForDesktop"
            >
              Sports
            </button>
            <button
              @click="setActive('Sports')"
              :class="{ active: isActive('Sports') }"
              class="btn sports menuForMobile"
              v-scroll-to="'#sportsEventsLink'"
              id="sportsEventsLink"
            >
              Sports
            </button>
            <button
              @click="setActive('National')"
              :class="{ active: isActive('National') }"
              class="btn national menuForDesktop"
            >
              National
            </button>
            <button
              @click="setActive('National')"
              :class="{ active: isActive('National') }"
              class="btn national menuForMobile"
              v-scroll-to="'#nationalEventsLinks'"
              id="nationalEventsLinks"
            >
              National
            </button>
            <button
              @click="setActive('Festival')"
              :class="{ active: isActive('Festival') }"
              class="btn festival menuForDesktop"
            >
              Festivals
            </button>
            <button
              @click="setActive('Festival')"
              :class="{ active: isActive('Festival') }"
              class="btn festival menuForMobile"
              v-scroll-to="'#festivalsEventsLink'"
              id="festivalsEventsLink"
            >
              Festivals
            </button>
            <!-- <button
              @click="setActive('Tours')" :class="{ active: isActive('Tours') }"
              class="btn tour"
            >Tours</button> -->

            <button
              @click="setActive('Metaverse')"
              :class="{ active: isActive('Metaverse') }"
              class="btn international menuForDesktop"
            >
              Metaverse
            </button>
            <button
              @click="setActive('Metaverse')"
              :class="{ active: isActive('Metaverse') }"
              class="btn international menuForMobile"
              v-scroll-to="'#mediaEventsLink'"
              id="mediaEventsLink"
            >
              Metaverse
            </button>
            
            <button
              @click="setActive('Cannibas')"
              :class="{ active: isActive('Cannibas') }"
              class="btn mc menuForDesktop"
            >
            Cannabis
            </button>
            <button
              @click="setActive('Cannibas')"
              :class="{ active: isActive('Cannibas') }"
              class="btn mc menuForMobile"
              v-scroll-to="'#mcScCCEventssLink'"
              id="mcScCCEventssLink"
            >
            Cannabis
            </button>

            


          

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../../app";
import { mapFields } from "vuex-map-fields";

export default {
  computed: {
    ...mapFields(["search_main_category"]),
  },
  mounted() {
    console.log("Component mounted.");

    var vm = this;
    // var filtersData = this.$session.get("global_filters");
    // vm.filters.category = typeof filtersData != "undefined" && filtersData.params.category? filtersData.params.category: "ceo";
    vm.filters.category = "ceo";

    jQuery(document).ready(function ($) {
      $("span.vgps-scroll-left").click(function (e) {
        // e.preventDefault();
        $(this).parent().find(".vgps-horizontal-scroll-wrapper").animate(
          {
            scrollLeft: "-=10px",
          },
          "fast"
        );
      });
      $("span.vgps-scroll-right").click(function (e) {
        $(this).parent().find(".vgps-horizontal-scroll-wrapper").animate(
          {
            scrollLeft: "+=10px",
          },
          "fast"
        );
      });
    });
  },
  props: ["filters"],
  created: function () {
    var vm = this;

    bus.$on("clearAdvanceFilter", () => {
      vm.filters.category = "ceo";
      vm.activeItem = vm.filters.category;
    });

    bus.$on("hashRedirect", (query) => {
      vm.activeItem = "all";
    });

    bus.$on("hashtag", (category, hashtag, type) => {
      if (type != "theme") {
        vm.filters.category = category;
      }
    });
  },
  data() {
    return {
      loadingContent: false,
      activeItem: "ceo",
      categories: [],
    };
  },
  methods: {
    isActive: function (menuItem) {
      return this.activeItem === menuItem;
    },
    setActive: function (menuItem) {
      this.activeItem = menuItem; // no need for Vue.set()
      this.filters.category = menuItem;
      this.search_main_category = menuItem;
      bus.$emit("filterSelection");
    },
    filterSelection: function (cat, elem) {
      $("#myBtnContainer").find("button").removeClass("active");
      this.filters.category = cat;
      bus.$emit("filterSelection");
    },
  },
};
</script>
