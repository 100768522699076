<template>
    <div id="map_view" width="100" height="auto" style="overflow: hidden;">
        Loading Map .....
    </div>
</template>

<script>
    import { bus } from '../../app'
    import { API_URL } from '../../app'
    import helpers from "../../mixin/helpers";
    import { mapFields } from 'vuex-map-fields';
    export default {
        props: {
            'latitude': {
                type: Number,
                default: function(){
                    return this.$session.get('default_location_lat')
                }
            },
            'longitude': {
                type: Number,
                default: function(){
                    return this.$session.get('default_location_long')
                }
            },
            'zoom': {
                type: Number,
                default: function(){
                    return 11
                }
            },
            'radius': {
                default: function(){
                    return 1
                }
            },
            'mapControll': {
                type: Boolean,
                default: function(){
                    return false
                }
            }
        },
        mixins: [helpers],
        data(){
            return {
                markers: [],
                listenerData:'',
                allPointers:[],
                activeInfoWindow:''
        }
        },
        created (){
    
            var vm = this
            bus.$on('showMarkerDetail', (data) => {
                vm.ShowMarkerDetail(data)
            });
            bus.$on('removeMarkerDetail', (data) => {
                vm.ShowMarkerDetail(data)
            });
            bus.$on('updateCityMap', (data) => {
                vm.latitude = data.lat
                vm.longitude = data.long
                vm.initMap();
                this.getAllPointers();
            });
            bus.$on('updateMapMarker',() => {
                this.vm.buildMarkers(data)
            });
            bus.$on('getLiveLocation', () => {
                this.getLiveLocation()
                this.initMap();
            })
        },
        computed: {
            /*
             Gets the events
             */
            events(){
//                return this.$store.getters.getCafes;
            },
            ...mapFields([
                'search_term',
                'search_offset',
                'search_limit',
                'search_category',
                'search_top_hash',
                'search_date_range',
                'search_business_type',
                'search_business_sub_type',
                'search_event_theme',
                'search_event_genre',
                'search_event_cuisines',
                'search_total_results',
                'enabledSeverityLevel'
            ]),
        },
        watch: {
            /*
             Watches the events. When they are updated, clear the markers
             and re build them.
             */
            radius(){
                this.initMap();
                this.getAllPointers();    
            },
            events(){
                this.clearMarkers();
                this.buildMarkers();
            },
            enabledSeverityLevel: {
                handler: function (newVal, oldVal) {
                    console.log('Old Severity Level: ', newVal,oldVal)
                    this.enableSeverityLevelsCircles(); 
                },
                deep: true 
            },


        },
        mounted(){
            this.initMap();
            this.getAllPointers();

            
            
        },
        methods: {
            async getAllPointers(action, latLongDetail, vm) {

                // let latitude = this.$session.get("default_location_lat");
                // let longitude = this.$session.get("default_location_long");

                var vm = this;
                var mapSearchParams = {
                    params: {
                        latitude: this.latitude,
                        longitude: this.longitude,
                        radius: this.radius
                    }
                };
                try {
                    // fetch data from a url endpoint
                    const response = await axios.get("data/get/all-pointers", mapSearchParams);
                    console.log('>>>>>>>>>>>>>>all pointers data >>>>>>>>>>>>>',response.data.data.events)
                    vm.allPointers = response.data.data.events
                    vm.buildMarkers(response.data.data.events)
                    
                } catch(error) {
                    console.log("error", error);
                    vm.loadingContent = false;
                    vm.loadingMoreContent = false;
                    // appropriately handle the error
                }
                
                
            },
            /*
             Clears the markers from the map.
             */
            initMap(){
                var isLiveLocation = this.$session.get('liveLocation');
                if(isLiveLocation){
                    this.latitude = this.$session.get('live_location_lat');
                    this.longitude = this.$session.get('live_location_long');
                }else {
                    this.latitude = this.$session.get('default_location_lat');
                    this.longitude = this.$session.get('default_location_long'); 
                }
                
                var circleRadius = this.radiusInMeter(this.radius); 
                console.log('Map Lat',this.latitude)
                console.log('Map Long',this.longitude)
                var iconURL = {
                    url:  window.axios.defaults.baseURL+'/images/current_location.png', // url
                    scaledSize: new google.maps.Size(30, 50), // scaled size
                    origin: new google.maps.Point(0,0), // origin
                    anchor: new google.maps.Point(0, 0) // anchor
                };
                /*
                 We don't want the map to be reactive, so we initialize it locally,
                 but don't store it in our data array.
                 */
                this.map = new google.maps.Map(document.getElementById('map_view'), {
                    center: {lat: parseFloat(this.latitude), lng: parseFloat(this.longitude)},
                    zoom: this.zoom,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    mapTypeControl: this.mapControll,
                    radius: circleRadius
                });
                var current_position = {
                    lat: 33.5838,
                    lng: -84.0678
                };
                // let marker = new google.maps.Marker({
                //     position: new google.maps.LatLng(this.latitude, this.longitude),
                //     map: this.map,
                //     icon: iconURL
                // });
                var center = new google.maps.LatLng(this.latitude, this.longitude);
                // var circle = new google.maps.Circle({
                //     map: this.map,
                //     radius: circleRadius,    // 10 miles in metres
                //     center: center,
                //     strokeColor: '#31a9ed',
                //     strokeOpacity: 0.8,
                //     strokeWeight: 2,
                //     fillColor: '#31a9ed',
                //     fillOpacity: 0.45,
                    
                // });
                // Recenter selected marker
                google.maps.event.trigger(this.map, "resize");
//                this.map.setCenter(center);
                /*
                 Clear and re-build the markers
                 */
                this.clearMarkers();
                this.buildMarkers();
            },
            /*
             Clears the markers from the map.
             */
            clearMarkers(){
                /*
                 Iterate over all of the markers and set the map
                 to null so they disappear.
                 */
                for( var i = 0; i < this.markers.length; i++ ){
                    this.markers[i].setMap( null );
                }
            },
            /*
             Builds all of the markers for the events
             */
            ShowMarkerDetail(obj){
                
                console.log('ShowMarkerDetail',obj);
            
                if(!obj && typeof obj =='undefined' && obj != ''){
                    return false;
                }
                var vm = this;
                var defaultPin = window.axios.defaults.baseURL+'/images/map_pin_resturant.svg';
                var restaurantPin = ((!jQuery.isEmptyObject(obj.venue.closing_reasons)))?obj.venue.closing_reasons.severity.icon_url:defaultPin;
                var iconURL = {
                    url: restaurantPin, // url
                    scaledSize: new google.maps.Size(30, 30), // scaled size
                    origin: new google.maps.Point(0,0), // origin
                    anchor: new google.maps.Point(0, 0) // anchor
                };
                if(obj && obj.venue.type == 'bar-grill') {
                    iconURL.url = restaurantPin;
                }
                var markerZoom = this.zoom;
                var marker = new google.maps.Marker({
                    position: { lat: parseFloat( obj.address.latitude ), lng: parseFloat( obj.address.longitude ) },
                    map: vm.map,
                    zoom: parseInt(markerZoom) + 2,
                    icon: iconURL
                });
                // Recenter selected marker
                var center = new google.maps.LatLng(parseFloat(obj.address.latitude), parseFloat(obj.address.longitude));
                // google.maps.event.trigger(this.map, "resize");
                // this.map.setCenter(center);
                this.map.panTo(center);


                /*
                 Add Conent Detail.
                 */

                let startDate = typeof obj.start_date != 'undefined'? obj.start_date : ''
                let severityStr = obj.venue.closing_reasons.title !='Normal'? '<p style="font-size: 14px;margin: 0;">'+obj.venue.closing_reasons.severity.description+' | Duration: '+obj.venue.closing_reasons.duration.time_duration+'</p>':'';


                let contentDetails = '<div style="display: flex;">'
                        + '<div><img style="width: 65px;height: 65px;" src="'+obj.thumbnail_urls.thumb_296+'"></div> '
                        + '<div style="margin-left: 8px;"><p style="color: #000;margin: 0; font-size: 14px;line-height: 22px;font-weight: 600;"><a href="javascript:void(0)" class="map-card-event" data-id="'+obj.id+'">'+obj.title+'</a></p><p style="font-size: 14px;margin: 0;">'+startDate+'</p>'+severityStr+'<a href="https://www.google.com/maps/dir/?api=1&origin='+vm.latitude+','+vm.longitude+'&destination='+obj.address.latitude+','+obj.address.longitude+'&travelmode=driving" style="color: #3e3e3e;margin: 0; font-size: 14px;line-height: 18px;" target="_blank">Show Directions</a> </div> </div>';
                var infowindow = new google.maps.InfoWindow({
                    content: contentDetails,
                    maxWidth: 320,
                });

                google.maps.event.addListener(marker, 'click', ( () =>  {
                    return function() {
                        if (vm.activeInfoWindow)
                        {
                            vm.activeInfoWindow.close();
                        }
                        // infowindow.setContent(contentDetails);
                        infowindow.open(vm.map, marker);
                        vm.activeInfoWindow = infowindow;

                        $('.vgps-map-listing-event-card').removeClass('active');
                        $("#event-card-"+obj.id).addClass('active');

                        // Animating scrolling effect
                        var container = $('.map-event-listing-wrapper');          
                        let scrollTo = $("#event-card-"+obj.id).offset().top  
                        container.animate({ 
                            scrollTop: scrollTo 
                        }); 

                    }
                })(marker, obj.id));
                if (vm.activeInfoWindow || obj == null)
                {
                    vm.activeInfoWindow.close();
                }
                infowindow.setContent(contentDetails);
                infowindow.open(vm.map, marker);
                vm.activeInfoWindow = infowindow;


                // Draw Circle for marker pointes
                google.maps.event.addListener(infowindow, 'domready', function() {
                    $(".map-card-event").click(function(e) {

                        if(!vm.isAuth()){
                            $("#loginModal").modal("show");
                            return false;
                        }
                        
                        var eventID = $(this).data('id')
                        console.log('Redirect map event on ID',eventID);
                        vm.$router.push({
                            name: "event",
                            params: { id: eventID },
                        });
                    });
                });
            },
            /*
             Builds all of the markers for the events
             */
            buildMarkers(data){
    
                var vm = this;
                if(typeof data != 'undefined' && data.length == 0 || typeof data == 'undefined'){
                    this.clearMarkers();
                    return false;
                }
                var restaurantPin = window.axios.defaults.baseURL+'/images/map_pin_resturant.svg';
                // var restaurantPin = obj.venue.closing_reasons.severity.icon_url;
                var iconURL = {
                    url: restaurantPin, // url
                    scaledSize: new google.maps.Size(30, 30), // scaled size
                    // origin: new google.maps.Point(0,0), // origin
                    // anchor: new google.maps.Point(0, 0) // anchor
                };
                /*
                 Initialize the markers to an empty array.
                 */
                this.markers = [];
                /*
                 Iterate over all of the events
                 */
                $.each(data,function(key,obj){
                    // debugger
                    if(obj.venue.type == 'bar-grill') {
                        iconURL.url = restaurantPin;
                    }
                    // console.log('obj.venue.closing_reasons',obj.venue.closing_reasons);
                    iconURL.url = (!jQuery.isEmptyObject(obj.venue.closing_reasons))?obj.venue.closing_reasons.severity.icon_url:restaurantPin;
                    /*
                     Create the marker for each of the events and set the
                     latitude and longitude to the latitude and longitude
                     of the cafe. Also set the map to be the local map.
                     */
                    var marker = new google.maps.Marker({
                        position: { lat: parseFloat( obj.address.latitude ), lng: parseFloat( obj.address.longitude ) },
                        map: vm.map,
                        icon: iconURL,
                        customData:obj,
                        customDataIndex:key
                    });
                    /*
                     Push the new marker on to the array.
                     */
                    vm.markers.push(marker);
                    /*
                     Add Conent Detail.
                     */
 

                    let startDate = typeof obj.start_date != 'undefined'? obj.start_date : ''
                    let severityStr = obj.venue.closing_reasons.title !='Normal'? '<p style="font-size: 14px;margin: 0;">'+obj.venue.closing_reasons.severity.description+' | Duration: '+obj.venue.closing_reasons.duration.time_duration+'</p>':'';

                    let contentDetails = '<div style="display: flex;">'
                            + '<div class="map-marker-icon"><img style="width: 65px;height: 65px;" src="'+obj.image+'"></div> '
                            + '<div style="margin-left: 8px;"><p style="color: #000;margin: 0; font-size: 14px;line-height: 22px;font-weight: 600;"><a href="javascript:void(0)" class="map-card-event" data-id="'+obj.id+'">'+obj.title+'</a></p><p style="font-size: 14px;margin: 0;">'+startDate+'</p>'+severityStr+'<a href="https://www.google.com/maps/dir/?api=1&origin='+vm.latitude+','+vm.longitude+'&destination='+obj.address.latitude+','+obj.address.longitude+'&travelmode=driving" style="color: #3e3e3e;margin: 0; font-size: 14px;line-height: 18px;" target="_blank">Show Directions</a> </div> </div>';
                    var infowindow = new google.maps.InfoWindow({
                        content: contentDetails,
                        maxWidth: 320,
                    });
                    google.maps.event.addListener(marker, 'click', (function(marker, key) {
                        return function() {
                            
                            // infowindow.setContent(contentDetails);
                            infowindow.open(vm.map, marker);
                            
                            
                            $('.vgps-map-listing-event-card').removeClass('active');
                            $("#event-card-"+obj.id).addClass('active');

                            // Animating scrolling effect 
                            var container = $('.map-event-listing-wrapper');          
                            let scrollTo = $("#event-card-"+obj.id).offset().top 
                            container.animate({ 
                                scrollTop: scrollTo 
                            }); 

                        }
                    })(marker, obj.id));
                    
                    // Draw Circle for marker pointes
                    google.maps.event.addListener(infowindow, 'domready', function() {
                        $(".map-card-event").click(function(e) {

                            if(!vm.isAuth()){
                                $("#loginModal").modal("show");
                                return false;
                            }

                            var eventID = $(this).data('id')
                            console.log('Redirect map event on ID',eventID);
                            vm.$router.push({
                                name: "event",
                                params: { id: eventID },
                            });
                            // setCommufnitySelected(e.currentTarget.dataset.communitynumber);
                        });
                    });

                    
                });
            },
            enableSeverityLevelsCircles: function(){
                
                var vm = this;
                $.each(vm.markers,function(key,obj){
                    
                    let business = obj.customData.venue;
                    if(business.closing_reasons.title != 'Normal' ){
                        let circleRadius = vm.radiusInMeter(vm.radius);
                        if(vm.enabledSeverityLevel == false){
                            if(obj._myCircle){
                                obj._myCircle.setMap(null);
                            }
                        }else{
                            
                            if(vm.mapExcludeReasons(business.closing_reasons.closing_reason)){

                                var circle = new google.maps.Circle({
                                    map: vm.map,
                                    radius: circleRadius,    // 10 miles in metres
                                    // center: center,
                                    strokeColor: business.closing_reasons.color,
                                    strokeOpacity: 0.2,
                                    strokeWeight: 0.4,
                                    fillColor: business.closing_reasons.color,
                                    fillOpacity: 0.2,
                                }); 
                                circle.bindTo('center', obj, 'position');
                                obj._myCircle = circle;
                            }
                            
                        } 
                    } 
                });
                
                
            },
            // enableSeverityLevelsCircles: function(){
                
            //     var vm = this;
            //     $.each(vm.markers,function(key,obj){
                    
            //         let business = obj.customData.venue;
            //         if(business.closing_reasons.title != 'Normal' ){
            //             let circleRadius = vm.radiusInMeter(vm.radius);
            //             if(vm.enabledSeverityLevel == false){
            //                 obj._myCircle.setMap(null);
                            
            //             }else{
                            
 
            //                 var circle = new google.maps.Circle({
            //                     map: vm.map,
            //                     radius: circleRadius,    // 10 miles in metres
            //                     // center: center,
            //                     strokeColor: business.closing_reasons.color,
            //                     strokeOpacity: 0.2,
            //                     strokeWeight: 0.4,
            //                     fillColor: business.closing_reasons.color,
            //                     fillOpacity: 0.2,
            //                 }); 
            //                 circle.bindTo('center', obj, 'position');
            //                 obj._myCircle = circle;
                            
                            
            //             } 
            //         } 
            //     });
                
                
            // },
            getLiveLocation: function(){
 
                var vm = this;
                //Get GeoLocation location
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            vm.$session.set('live_location_lat', parseFloat(position.coords.latitude));
                            vm.$session.set('live_location_long', parseFloat(position.coords.longitude));
                        },
                        function (error) {
                            // alert(error.message);
                        }, {
                            enableHighAccuracy: true
                            , timeout: 5000
                        }
                        );
                } else {
                    alert("Geolocation is not supported by this browser.");
                }
            },
            radiusInMeter:function(radius){
                // var miles = (parseInt(radius) * 1609.344);
                var miles = 5000;
                console.log('miles',miles); 
                return miles;
            }
        },
        
    }
</script>
