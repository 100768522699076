<template>
  <section
    :id="id"
    class="vgps-venue-theme-wrapper vgps-venue-detail-section-wrapper my-venue-theme filters venueDetailPage"
  >
    <div class="containers">
        <div class="col-md-12 col-sm-12 col-xs-12 vgps-event-detail-section-title">
          <h2>{{ title }}</h2>
      </div>
    </div>
    <div class="venue-content-theme filter-content">
      <div class="containers">
          <event_theme :venue="venue" :events="events"></event_theme>
        </div>
    </div>
  </section>
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";
import EventCard from "../../components/global/event-card.vue";

export default {
  data() {
    return {
      //loadingContent: false,
    };
  },
  props: ["venue", "events", "title", "id"],
  mixins: [helpers],
  created() {},
  components: {
    event_theme: EventCard,
  },
  methods: {},
  filters: {},
};
</script>
