<template>
    <!-- <span v-on:click="likeEvent()" class="heart"><img :src="likeImage()" /></span> -->
    <button :class="[like ? 'pink like-btn' : 'like-btn']" v-on:click="likeEvent()" class="" href="javascript:void(null);">
        <img v-if="!processing" :src="likeImage()">
        <i v-if="processing" class="fa fa-circle-o-notch fa-spin"></i>
        Like {{ count }}
    </button>
</template>

<script>

    import { bus } from '../../app'
    import helpers from "../../mixin/helpers";
    import {
            ContentLoader,
            FacebookLoader,
            CodeLoader,
            BulletListLoader,
            InstagramLoader,
            ListLoader
    } from 'vue-content-loader'

    export default {
        props:['id','count','like'],
        mixins: [helpers],
        watch: {

        },
        components: {

        },
        computed: {

        },
        data: function () {
            return {
                // like: false,
                processing:false,
            }
        },
        methods: {
            likeEvent: function(event) {

                var vm = this;
                if(typeof vm.$session.get('access_token') == 'undefined'){
                    $('#loginModal').modal('show');
                    return false;
                }

                if(!vm.hasSubscribedPlan()){
                    sweetalert2.fire({
                        icon: 'info',
                        title: 'Upgrade Subscription Plan',
                        text: 'Please upgrade your subscription plan to like and follow event.'
                    })
                    return false;
                }

                var like = this.like == true ? false: true;
                var data = new FormData();
                data.append("content_id", this.id);
                data.append("content_type", 'event');
                data.append("like", like);

                if (vm.processing === true) {
                    return false;
                }

                vm.processing = true;
                axios.post("data/markLike", data)
                    .then(function (response) {
                        
                        vm.processing = false;
                        vm.like = like;
                        if(vm.like){
                            vm.count = vm.count + 1
                        }else {
                            vm.count = vm.count - 1
                        }
                    })
                    .catch(function (errors) {
                        vm.processing = false;
                    });
            },
            likeImage: function () {


                var vm = this;
                var img = 'heart-grey.svg';
                if(typeof vm.$session.get('access_token') == 'undefined'){
                    img = 'heart-grey.svg';
                }else{
                    if(this.like) {
                        img = 'heart.svg'
                    }
                }
                return vm.$options.filters.assetPath(img);

            },
        },

        filters: {
            assetPath: function (img) {
                var path = window.axios.defaults.baseURL+'/images/'+img;
                return path;
            },
        }
    }

</script>
