<template>
    <div id="vue-contriner-id" class="vue-contriner">
        <transition name="fade" mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter">
            <keep-alive exclude="EventDetailComponent,VenueDetailComponent">
                <router-view :key="$router.fullPath"></router-view>
            </keep-alive>
        </transition>
    </div>
</template>
<script>
  // Transition Linkk https://markus.oberlehner.net/blog/vue-router-page-transitions/

  import { bus } from './app'
  import helpers from './../mixin/helpers';
  import { mapFields } from "vuex-map-fields";

    export default {
        data(){
            return {
                loadingContent: true,
                prevHeight: 0,
            }
        },
        mixins: [helpers],
        beforeCreate(){

        },
        beforeMount(){
            this.loadingContent = true;
        },
        computed:{
            ...mapFields(["defaultPageData"]),
        },
        created (){
        //    var lookupsData = window.lookups? JSON.parse(window.lookups):{}; 
           this.defaultPageData = window.custom_lookup; 
        },
        methods: {
            beforeLeave(element) {
                this.prevHeight = getComputedStyle(element).height;
                // this.$store.state.scrollPositionY = document.documentElement.scrollTop
            },
            enter(element) {
                const { height } = getComputedStyle(element);
                element.style.height = this.prevHeight;

                setTimeout(() => {
                    element.style.height = height;
                });
            },
            afterEnter(element) {
                element.style.height = 'auto';
            },
        }
    }
</script>