<template>
  <div class="business-filter">
    <ul class="business-sub-category list-group list-group-flush">
      <li
        v-for="(item, index) in getFilteredAmenities"
        :key="item.amenity"
        class="list-group-item sPSubFilterWrap"
      >
        <div class="form-group vgps-custom-checkbox-wrapper">
          <label
            class="fancyRadioWrapper business-main-cat searchCheckBoxItem sPSubFilterHeader collapsed"
            :for="item.amenity"
            data-toggle="collapse"
            :data-target="'#amennity-menu-div-' + index"
            aria-expanded="false"
            :aria-controls="'#amennity-menu-div-' + item.amenity"
          >
            {{ item | ItemTotalCount }}
            <span class="sPFilterCount">{{ item | ItemTotalCountValue }} </span>
            <i class="fas fa-angle-down subFilterArrow"></i>
          </label>
        </div>

        <template v-if="item.amenity == 'Preference'">
          <ul
            :id="'amennity-menu-div-' + index"
            v-if="item.values.length > 0"
            class="business-sub-category  list-group collapse list-group-flush"
          >
            <li
              v-for="(item2, index3) in item.values.slice(0, 5)"
              :key="item2.name"
              class="list-group-item"
            >
              <div class="form-group vgps-custom-checkbox-wrapper">
                <label
                  class="fancyRadioWrapper business-main-cat searchCheckBoxItem sPSubFilterHeader collapsed"
                  :for="item2.amenity"
                  data-toggle="collapse"
                  :data-target="'#amennity-menu2-div-' + index3"
                  aria-expanded="false"
                  :aria-controls="'#amennity-menu2-div-' + item2.amenity"
                >
                  {{ item2 | ItemTotalCountSub }}
                  <span class="sPFilterCount">
                    {{ item2 | ItemTotalCountSubValue }}
                  </span>
                  <i class="fas fa-angle-down subFilterArrow"></i>
                </label>
              </div>
              <ul
                :id="'amennity-menu2-div-' + index3"
                v-if="item2.values.length > 0"
                class="business-sub-category preference-folder-list list-group collapse list-group-flush"
              >
                <li
                  v-for="subitems in item2.values"
                  :key="subitems.name"
                  class="list-group-item"
                >
                  <div class="form-group vgps-custom-checkbox-wrapper">
                    <input
                      type="checkbox"
                      @change="advanceFilter"
                      v-model="search_event_amenities"
                      name="search_event_amenities"
                      :value="subitems.name"
                      :id="subitems.name"
                    />
                    <label :for="subitems.name">{{ subitems.name }}</label>
                    <span class="item-count badge sPFilterCount">{{
                      subitems.count
                    }}</span>
                  </div>
                </li>
                <li v-if="item.values.length > 5" class="list-group-item">
                  <a
                    href="javascript:void(0)"
                    @click="amenitiesMoreOptions(item.amenity)"
                    class="vgp-primary-link"
                    data-toggle="modal"
                    data-target="#cusinies-more-option-model"
                    >See all
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </template>
        <template v-else>
          <ul
            :id="'amennity-menu-div-' + index"
            v-if="item.values.length > 0"
            class="business-sub-category list-group collapse list-group-flush"
          >
            <li
              v-for="subitems in item.values.slice(0, 5)"
              :key="subitems.name"
              class="list-group-item"
            >
              <div class="form-group vgps-custom-checkbox-wrapper">
                <input
                  type="checkbox"
                  @change="advanceFilter"
                  v-model="search_event_amenities"
                  name="search_event_amenities"
                  :value="subitems.name"
                  :id="subitems.name"
                />
                <label :for="subitems.name">{{ subitems.name }}</label>
                <span class="item-count badge">{{ subitems.count }}</span>
              </div>
            </li>
            <li v-if="item.values.length > 5" class="list-group-item">
              <a
                href="javascript:void(0)"
                @click="amenitiesMoreOptions(item.amenity)"
                class="vgp-primary-link amenitiesLoadMoreBtn"
                data-toggle="modal"
                data-target="#cusinies-more-option-model"
                >See all
              </a>
            </li>
          </ul>
        </template>
      </li>
    </ul>
  </div>
</template>
<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";
import { mapFields } from "vuex-map-fields";
import Select2 from "v-select2-component";

export default {
  components: {
    Select2,
  },
  data() {
    return {
      //
      event_cuisines: [],
    };
  },
  computed: {
    FoodCuisinesData() {
      return this.$store.getters.FoodCuisinesData;
    },
    getFilteredAmenities() {
      return this.$store.getters.getFilteredAmenities;
    },
    ...mapFields([
      "search_event_cuisines",
      "search_event_amenities",
      "search_main_category",
      "moreAmenitiesOptions",
    ]),
  },
  watch: {},
  created: function () {
    var vm = this;
    // vm.search_event_cuisines = [];
  },
  mixins: [helpers],
  methods: {
    amenitiesMoreOptions: function (amenity) {
      this.moreAmenitiesOptions = [];
      var data = this.getFilteredAmenities.filter(function (obj) {
        if (obj.amenity == amenity) {
          return true;
        }
      });
      this.moreAmenitiesOptions = data.length > 0 ? data[0] : [];
    },
    advanceFilter: function () {
      var vm = this;
      // vm.search_main_category =
      // vm.search_main_category == "Trending" ? "All" : vm.search_main_category;
      bus.$emit("search_filter_emit");
    },
  },
  filters: {
    assetPath: function (img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
    ItemTotalCountSub: function (item) {
      return item.amenity;
    },
    ItemTotalCountSubValue: function (item) {
      var count = 0;
      if (item.amenity == "Preference") {
        item.values.map(function (obj, index) {
          obj.values.map(function (child, index) {
            count = child.count + count;
          });
        });
      } else {
        item.values.map(function (obj, index) {
          count = obj.count + count;
        });
      }

      if (count > 0) {
        return "(" + count + ")";
      }
      // return item.amenity;
    },

    ItemTotalCount: function (item) {
      return item.amenity;
    },

    ItemTotalCountValue: function (item) {
      var count = 0;
      if (item.amenity == "Preference") {
        item.values.map(function (obj, index) {
          obj.values.map(function (child, index) {
            count = child.count + count;
          });
        });
      } else {
        item.values.map(function (obj, index) {
          count = obj.count + count;
        });
      }

      if (count > 0) {
        return "(" + count + ")";
      }
      // return item.amenity;
    },
  },
};
</script>
