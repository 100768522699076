<template>
  <main id="detail-slider-component">
    <section class="top-banner containers vDetailAdSliderWrp">
      <div
        v-if="typeof loadingContent != 'undefined' && loadingContent"
        class="container slider-default-loader"
      >
        <content-loader
          viewBox="0 0 400 160"
          height="160"
          width="900"
          speed="2"
          backgroundColor="transparent"
        >
          <circle cx="150" cy="86" r="8" />
          <circle cx="194" cy="86" r="8" />
          <circle cx="238" cy="86" r="8" />
        </content-loader>
      </div>
      <template v-else>
        <slick
          ref="slick"
          :options="slickOptions"
          v-animate-css="animate.fadeIn"
          @reInit="reInit"
        >
          <template v-if="banners.length > 0">
            <div
              v-for="(banner, index) in banners"
              :key="index"
              class="silk-item"
            >
              <a v-if="banner.link" :href="banner.link" target="_blank"
                ><img :src="banner.image" :alt="banner.title"
              /></a>
              <a v-else href="javascript:vloid(0)l"
                ><img :src="banner.image" :alt="banner.title"
              /></a>
            </div>
          </template>
          <template v-else>
            <div class="silk-item">
              <img :src="'/banners/new-banner-2.jpg' | assetPath" alt="" />
            </div>
          </template>
        </slick>
      </template>
    </section>
  </main>
</template>

<script>
import { bus } from "../../app";
import helpers from "./../../mixin/helpers";
import { ContentLoader } from "vue-content-loader";

import carousel from "vue-owl-carousel2";
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default {
  data() {
    return {
      loadingContent: true,
      banners: [],
      leaderSpeed: 1,
      slickOptions: {
        autoplay: true,
        slidesToShow: 1,
        arrows: true,
        dots: false,
        slide: "div",
        // centerPadding:50,
        lazyLoad: true,
        // Any other options that can be got from plugin documentation
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              dots: false,
              arrows: true,
            },
          },
          {
            breakpoint: 300,
            settings: "unslick", // destroys slick
          },
        ],
      },
    };
  },
  mixins: [helpers],
  components: {
    Slick,
    carousel,
    ContentLoader,
  },
  beforeCreate() {
    // this.loadingContent = true;
  },
  created() {
    this.getBanners();
  },
  updated() {
    this.reInit();
    // this.loadingContent = false;
  },
  mounted() {
    // var vm = this;
    // setTimeout(function(){
    //     vm.loadingContent = false;
    // },2000)
  },
  methods: {
    getBanners: function () {
      var vm = this;
      axios
        .get("data/banners", {
          params: {
            slot: "event",
          },
        })
        .then((response) => {
          vm.banners = response.data.data;
          vm.loadingContent = false;
        })
        .catch(function (errors) {
          vm.loadingContent = false;
        });
    },
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
  },
  filters: {
    checkNullLink: function (link) {
      var checklink = link ? link : "javascript:void(0);";
      return checklink;
    },
  },
};
</script>


<style>
.owl-nav disabled {
  margin-bottom: 369px;
}
</style>