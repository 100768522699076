<template>
  <div class="v-home-component home-page">
    <header_section></header_section>
    <div class="home-vue-content">
        <home_slider></home_slider>
        <home_content></home_content>
        <footer_section></footer_section>
    </div>
  </div>
</template>

<script>

  import { bus } from './app'
  import Home from './../components/home' 
  import NProgress from 'nprogress';
  import FooterSection from './../views/partials/footer' 
  import HeaderSection from './../views/partials/header' 
  import helpers from './../mixin/helpers';
  import HomeSlider from './../components/home/home-slider.vue';

  export default {
    data() {
      return {
          loadingContent: false,
      }
    },
    beforeCreate() {
      this.loadingContent = true;
    },
    ready(){
      
    },
    mounted(){
      setTimeout(function(){
        NProgress.done();
      },2000);
    },
    created(){
      this.loadingContent = false;
      
    },
    mixins: [helpers],
    components:{
      home_content: Home,
      'home_slider': HomeSlider,
      'footer_section': FooterSection,
      'header_section': HeaderSection,
    }
  }
</script>