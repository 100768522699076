<template>
  <section class="search-page filters searchPageWrapper" id="filter-id">
    <div class="v-home-component home-page">
      <header_section></header_section>
      <div class="home-vue-content">
        <section
          id="search-content-area"
          class="container-fluid filter-top-section searPCotentWrp"
        >
          <aside
            class="col-lg-3 col-md-3 col-sm-3 col-xs-12"
            :class="{ active_searching: isSearching }"
          >
            <sidebar_section></sidebar_section>
          </aside>
          <main class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
            <vue-sticky-sidebar
              class="selected-filter-wrapper"
              containerSelector=".filter-top-section"
              :topSpacing="topSpacing"
              stickyClass="is-fixed-selected-filter"
              innerWrapperSelector=".selected-filter"
            >
              <div class="selected-filter">
                <span v-if="search_total_results > 0"
                  >Total:
                  <strong>{{ search_total_results }}</strong> Events</span
                >

                <a
                  v-if="search_term"
                  @click="clearFillter('search_term')"
                  type="button"
                  class="btn btn-default sPNavTag"
                  >Search: "{{ search_term }}" <i class="fa fa-times"></i
                ></a>

                <a
                  v-if="search_main_category"
                  type="button"
                  class="btn btn-default sPNavTag trendingTag"
                >
                {{ search_main_category === 'NightLife' ? 'Dine & Drink' : (search_main_category === 'Media' ? 'Attractions' : search_main_category) }}

              </a>
                <a
                  v-if="search_sub_category"
                  @click="clearFillter('search_sub_category')"
                  type="button"
                  class="btn btn-default sPNavTag"
                  :data-item-name="search_sub_category"
                >
                  {{ search_sub_category }}
                  <i class="fa fa-times"></i>
                  ></a
                >

                <template
                  v-if="
                    isArray(search_date_range) && search_date_range != 'custom'
                  "
                >
                  <a
                    v-if="search_date_range.length > 0"
                    @click="clearFillter('search_date_range')"
                    type="button"
                    class="btn btn-default with-dates sPNavTag"
                  >
                    <span
                      v-for="(item, index) in search_date_range"
                      :key="index"
                      class="selected-dates colorWgite"
                    >
                      {{ item | moment("MM-DD-YYYY") }}
                    </span>
                    <i class="fa fa-times"></i>
                  </a>
                </template>

                <template
                  v-if="
                    !isArray(search_date_range) && search_date_range != 'custom'
                  "
                >
                  <a
                    v-if="search_date_range.length > 0"
                    @click="clearFillter('search_date_range')"
                    type="button"
                    class="btn btn-default without-dates sPNavTag"
                  >
                    <span class="selected-dates colorWgite">
                      {{ search_date_range }}
                    </span>
                    <i class="fa fa-times"></i>
                  </a>
                </template>
                <a
                  v-if="search_business_sub_type"
                  @click="clearFillter('search_business_sub_type')"
                  type="button"
                  class="btn btn-default sPNavTag"
                  :data-item-name="search_business_sub_type"
                >
                  {{ search_business_sub_type }} <i class="fa fa-times"></i
                ></a>

                <template v-if="search_event_theme.length > 0">
                  <a
                    v-for="(item, index) in search_event_theme"
                    :key="index"
                    @click="clearThemeFillter(index)"
                    type="button"
                    class="btn btn-default"
                    :data-item-name="item"
                    >{{ item }} <i class="fa fa-times"></i>
                  </a>
                </template>

                <a
                  v-if="search_event_genre"
                  @click="clearFillter('search_event_genre')"
                  type="button"
                  class="btn btn-default sPNavTag"
                  :data-item-name="search_event_genre"
                >
                  {{ search_event_genre }} <i class="fa fa-times"></i
                ></a>

                <template v-if="search_event_amenities.length > 0">
                  <a
                    v-for="(item, index) in search_event_amenities"
                    :key="index"
                    @click="clearAmentiesFillter(index)"
                    type="button"
                    class="btn btn-default sPNavTag"
                    :data-item-name="item"
                    >{{ item }} <i class="fa fa-times"></i
                  ></a>
                </template>
                <template v-if="search_event_cuisines.length > 0">
                  <a
                    v-for="(item, index) in search_event_cuisines"
                    :key="index"
                    @click="clearCusiniesFillter(index)"
                    type="button"
                    class="btn btn-default sPNavTag"
                    :data-item-name="item"
                    >{{ item }} <i class="fa fa-times"></i
                  ></a>
                </template>

                <a
                  @click="clearFillter('all')"
                  type="button"
                  class="btn btn-default sPNavTag sPclearAllBtn"
                  >Clear All
                </a>
              </div>
            </vue-sticky-sidebar>
            <div class="search-results">
              <event_listing></event_listing>
            </div>
          </main>

          <!-- Business Category Modal -->
          <div
            class="modal right fade"
            id="business-category-more-option-model"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Venues</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <ul
                    v-if="
                      typeof search_user_filters.business_category !=
                        'undefined' &&
                      search_user_filters.business_category.length > 5
                    "
                    class="business-sub-category list-group list-group-flush popupContentWrapper searchPopUpContentArea"
                  >
                    <li
                      v-for="subCategory in search_user_filters.business_category.slice(
                        5
                      )"
                      :key="subCategory.type_n"
                      class="list-group-item col-md-4 col-sm-12"
                    >
                      <div class="form-group vgps-custom-checkbox-wrapper">
                        <label
                          class="fancyRadioWrapper"
                          :for="subCategory.name"
                        >
                          {{ subCategory.name }}
                          <input
                            type="radio"
                            v-model="venue_type_category"
                            :value="subCategory.type_n"
                            name="search_business_sub_type"
                            :id="subCategory.name"
                          />
                          <span class="checkmark"></span>
                        </label>
                        <span class="item-count badge">{{
                          subCategory.count
                        }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary venueModelClose"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    @click="applyBusinessFilter"
                    class="btn btn-primary"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Genre Options  Modal -->
          <!-- <div
            class="modal right fade"
            id="genre-category-more-option-model"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Genre</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <ul
                    v-if="search_user_filters.genre.length > 0"
                    class="business-sub-category list-group list-group-flush popupContentWrapper searchPopUpContentArea"
                  >
                    <li
                      v-for="genreItem in search_user_filters.genre.slice(5)"
                      :key="genreItem.type_n"
                      class="list-group-item col-md-4 col-sm-12"
                    >
                      <div class="form-group vgps-custom-checkbox-wrapper">
                        <label class="fancyRadioWrapper" :for="genreItem.name">
                          {{ genreItem.name }}
                          <input
                            type="radio"
                            v-model="search_event_genre"
                            :value="genreItem.type_n"
                            name="search_business_sub_type"
                            :id="genreItem.name"
                          />
                          <span class="checkmark"></span>
                        </label>
                        <span class="item-count badge">{{
                          genreItem.count
                        }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    @click="applyGenreFilter"
                    class="btn btn-primary"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div> -->

          <!--Amenities Modal --->
          <div
            class="modal right fade moreFilterModelWrp"
            id="cusinies-more-option-model"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ moreAmenitiesOptions.amenity }}
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <ul
                    v-if="
                      moreAmenitiesOptions &&
                      moreAmenitiesOptions.values.length > 5
                    "
                    class="business-sub-category list-group-flush col-md-12 searchPopUpContentArea"
                  >
                    <li
                      v-for="subitems in moreAmenitiesOptions.values.slice(5)"
                      :key="subitems.name"
                      class="list-group-item col-md-6 col-sm-12"
                    >
                      <div class="form-group vgps-custom-checkbox-wrapper">
                        <input
                          type="checkbox"
                          v-model="amenity_values"
                          name="search_event_amenities"
                          :value="subitems.name"
                          :id="subitems.name"
                        />
                        <label :for="subitems.name">{{ subitems.name }}</label>
                        <span class="item-count badge">{{
                          subitems.count
                        }}</span>
                      </div>
                    </li>
                  </ul>
                  <!-- </li>
                  </ul> -->
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary closeModelBtn"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    @click="applyCuisinesFilter"
                    class="btn btn-primary"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!--Food Cusiness Modal --->
          <div
            class="modal right fade cusinessModelWrapp moreFilterModelWrp"
            id="food-more-option-model"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog cusinessModelWrapp" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ moreCuisinesOptions.amenity }}
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <ul
                    v-if="
                      typeof moreCuisinesOptions.values != 'undefined' &&
                      moreCuisinesOptions.values.length > 5
                    "
                    class="business-sub-category list-group-flush col-md-12 searchPopUpContentArea"
                  >
                    <li
                      v-for="subitems in moreCuisinesOptions.values.slice(5)"
                      :key="subitems.name"
                      class="list-group-item col-md-6 col-sm-12"
                    >
                      <div class="form-group vgps-custom-checkbox-wrapper">
                        <input
                          type="checkbox"
                          v-model="cuisiness_values"
                          name="search_event_amenities"
                          :value="subitems.name"
                          :id="subitems.name"
                        />
                        <label :for="subitems.name">{{ subitems.name }}</label>
                        <span class="item-count badge">{{
                          subitems.count
                        }}</span>
                      </div>
                    </li>
                  </ul>
                  <!-- </li>
                  </ul> -->
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary closeModelBtn"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    @click="applyFoodFilter"
                    class="btn btn-primary"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!--Theme Modal -->
          <div
            class="modal right fade moreFilterModelWrp"
            id="theme-more-option-model"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ moreThemeOptions.theme }} Hashtags
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <ul
                    class="business-sub-category list-group 
                    list-group-flush searcThemFiltOption searchPopUpContentArea"
                  >
                    
                    <ul
                      v-if="
                        moreThemeOptions && moreThemeOptions.values.length > 5
                      "
                      class="business-sub-category list-group list-group-flush"
                    >
                      <li
                        v-for="(subitems,index) in moreThemeOptions.values.slice(5)"
                        :key="subitems.name"
                        class="list-group-item col-md-4 col-sm-12"
                      >
                        <div class="form-group vgps-custom-checkbox-wrapper">
                          <input
                            type="checkbox"
                            v-model="hash_themes"
                            name="hash_themes[]"
                            :value="subitems.name"
                            :id="'theme-'+index"
                          />
                          <label :for="'theme-'+index" class="pLeft30">{{
                            subitems.name
                          }}</label>
                          <span class="item-count badge">{{
                            subitems.count
                          }}</span>
                        </div>
                      </li>
                    </ul>
                  </ul>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    
                    class="btn btn-secondary closeModelBtn"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    @click="applyThemeFilter"
                    class="btn btn-primary"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer_section></footer_section>
      </div>
    </div>
  </section>
</template>

<script>
import { bus } from "./../app";

import EventListing from "./../components/search/event-listing.vue";
import BannerSection from "./../components/search/banner-section.vue";
import SidebarSection from "./../components/search/sidebar-section.vue";

import NProgress from "nprogress";
import FooterSection from "./../views/partials/footer";
import HeaderSection from "./../views/partials/header";
import helpers from "./../mixin/helpers";
import { mapFields } from "vuex-map-fields";
import vueStickySidebar from "vue-sticky-sidebar"; //https://blixhavn.github.io/sticky-sidebar-v2/

const offset = 0;
const limit = 12;

export default {
  name: "SearchComponent",
  data() {
    return {
      loadingContent: false,
      topSpacing: 65,
      venue_type_category: "",
      amenity_values: [],
      cuisiness_values: [],
      // hash_themes: [],
      filters: {
        location: "",
        state_code: "",
        search: "",
        category: "",
        dates: {
          start: "",
          end: "",
        },
        business_type: "",
        genre: "",
        venue_type: "",
        venue_sub_type: "",
        event_filter: "",
        event_theme: "",
        event_genre: "",
        amenity_id: "",

        offset: offset,
        limit: limit,
      },
    };
  },
  computed: {
    ...mapFields([
      "search_term",
      "search_main_category",
      "search_sub_category",
      "search_top_hash",
      "search_date_range",
      "search_business_type",
      "search_business_sub_type",
      "search_event_theme",
      "search_event_genre",
      "search_event_cuisines",
      "search_event_amenities",
      "search_total_results",
      "search_user_filters",
      "moreThemeOptions",
      "moreAmenitiesOptions",
      "moreCuisinesOptions",
      "isSearching",
      'hash_themes'
    ]),
    getFilteredAmenities() {
      return this.$store.getters.getFilteredAmenities;
    },
    getFilteredThemes() {
      return this.$store.getters.getFilteredThemes;
    },
  },
  updated() {



  },
  beforeCreate() {
    this.loadingContent = true;
  },
  watch:{
    
  },
  mounted() {
    setTimeout(function () {
      NProgress.done();
    }, 2000);
  },
  created() {
    this.loadingContent = false;

    var vm = this;
    bus.$on("update_hash_theme_option", (data) => {
      console.log(vm.moreThemeOptions,vm.hash_themes)
      vm.hash_themes = [];
      vm.hash_themes = vm.search_event_theme;
      // vm.hash_themes; 
      vm.$forceUpdate();
    });

    var vm = this;
  },
  mixins: [helpers],
  components: {
    footer_section: FooterSection,
    header_section: HeaderSection,
    banner_section: BannerSection,
    sidebar_section: SidebarSection,
    event_listing: EventListing,
    "vue-sticky-sidebar": vueStickySidebar,
  },
  methods: {

    applyBusinessFilter: function () {
      var vm = this;
      // vm.search_main_category =
      vm.search_business_sub_type = vm.venue_type_category;
      // vm.search_main_category == "Trending" ? "All" : vm.search_main_category;
      $("#business-category-more-option-model").modal("hide");
      bus.$emit("search_filter_emit");
    },
    applyGenreFilter: function () {
      var vm = this;
      // vm.search_event_genre =
      // vm.search_event_genre == "Trending" ? "All" : vm.search_event_genre;
      $("#genre-category-more-option-model").modal("hide");
      bus.$emit("search_filter_emit");
    },
    clearThemeFillter: function (index) {
      this.search_event_theme.splice(index, 1);
      bus.$emit("clear_search_page_filter_emit");
    },
    applyCuisinesFilter: function () {
      var vm = this;
      vm.search_event_amenities = vm.search_event_amenities.concat(
        vm.amenity_values
      );
      vm.search_event_amenities = vm.search_event_amenities.filter(
        (item, index) => {
          return vm.search_event_amenities.indexOf(item) == index;
        }
      );
      // vm.search_main_category =
      // vm.search_main_category == "Trending" ? "All" : vm.search_main_category;
      $("#cusinies-more-option-model").modal("hide");
      bus.$emit("search_filter_emit");
    },
    applyFoodFilter: function () {
      var vm = this;
      vm.search_event_cuisines = vm.search_event_cuisines.concat(
        vm.cuisiness_values
      );
      vm.search_event_cuisines = vm.search_event_cuisines.filter(
        (item, index) => {
          return vm.search_event_cuisines.indexOf(item) == index;
        }
      );
      // vm.search_main_category =
      // vm.search_main_category == "Trending" ? "All" : vm.search_main_category;
      $("#food-more-option-model").modal("hide");
      bus.$emit("search_filter_emit");
    },
    applyThemeFilter: function () {
      var vm = this;

      vm.search_event_theme = vm.hash_themes;
      // vm.search_event_theme = vm.search_event_theme.concat(vm.hash_themes);
      // vm.search_event_theme = vm.search_event_theme.filter((item, index) => {
      //   return vm.hash_themes.includes(item);
      // });

      $("#theme-more-option-model").modal("hide");
      bus.$emit("search_filter_emit");
    },
    clearAmentiesFillter: function (index) {
      this.search_event_amenities.splice(index, 1);
      bus.$emit("clear_search_page_filter_emit");
    },
    clearCusiniesFillter: function (index) {
      this.search_event_cuisines.splice(index, 1);
      bus.$emit("clear_search_page_filter_emit");
    },
    clearFillter: function (key) {
      var vm = this;
      switch (key) {
        case "search_term":
          vm.search_term = "";
          break;
        case "search_main_category":
          vm.search_main_category = "";
          break;
        case "search_sub_category":
          vm.search_sub_category = "";
          vm.venue_type_category = "";
          break;
        case "search_top_hash":
          vm.search_top_hash = "";
          break;
        case "search_date_range":
          vm.search_date_range = "";
          break;
        case "search_business_type":
          vm.search_business_type = "";
          break;
        case "search_business_sub_type":
          vm.search_business_sub_type = "";
          break;
        case "search_event_theme":
          vm.search_event_theme = [];
          vm.hash_themes = [];
          break;
        case "search_event_genre":
          vm.search_event_genre = "";
          break;
        case "search_event_amenities":
          vm.search_event_amenities = [];
          vm.amenity_values = [];
          break;
        case "search_event_cuisines":
          vm.search_event_cuisines = [];
          vm.cuisiness_values = [];
          break;
        default:
          vm.search_term = "";
          vm.search_main_category = "All";
          vm.search_top_hash = "";
          vm.search_date_range = "";
          vm.search_business_type = "";
          vm.search_business_sub_type = "";
          vm.search_event_theme = [];
          vm.search_event_genre = "";
          vm.search_event_amenities = [];
          vm.search_event_cuisines = [];
          vm.amenity_values = [];
          vm.cuisiness_values = [];
          vm.hash_themes = [];
          vm.selected_hash_themes = [];
          vm.venue_type_category = "";
          break;
      }
      bus.$emit("clear_search_page_filter_emit");
    },
  },
  filters: {
    categoryFillter: function (category) {
      return category == "ceo" ? "Top Trending" : category;
    },
  },
};
</script>