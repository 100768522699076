<template>
    <div class="my-event-vue-wrapper">
        <header_section></header_section>
        <main class="top-inner-pages filters">
            <section class="dashboard admin-dashboard admin-add-ceo-ads-wrapper user-profile-options-page-wrapper">
                <div class="container-fluid">
                    <div class="row">
                        <div class="hidden-md hidden-sm hidden-xs col-lg-3 col-md-4 col-sm-12 col-xs-12">
                            <!-- @include('user.includes._partials.user-info-card') -->
                        </div>
                        <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                            <div class="right-box">
                                <div class="admin-dash-section-header">
                                    <h2>My Profile</h2>
                                    <p>Add your personal details </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div id="sidebar" class="hidden-md hidden-sm hidden-xs col-lg-3 col-md-4 col-sm-12 col-xs-12">
                            <sidebar_section></sidebar_section>
                        </div>
                        <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                        <div class="right-box">
                            <div class="user-profile-options-wrapper">

                                <template v-if="loadingContent  && isFetchingCities">
                                    <ContentLoader
                                        height="200"
                                        width="400"
                                        speed="1"
                                        viewBox="0 0 400 200"
                                        backgroundColor="#d9d9d9"
                                        foregroundColor="#ecebeb"
                                        >
                                        <rect x="15" y="15" rx="4" ry="4" width="130" height="10" />
                                        <rect x="155" y="15" rx="3" ry="3" width="130" height="10" />
                                        <rect x="295" y="15" rx="3" ry="3" width="90" height="10" />
                                        <rect x="15" y="50" rx="3" ry="3" width="90" height="10" />
                                        <rect x="115" y="50" rx="3" ry="3" width="60" height="10" />
                                        <rect x="185" y="50" rx="3" ry="3" width="200" height="10" />
                                        <rect x="15" y="90" rx="3" ry="3" width="130" height="10" />
                                        <rect x="160" y="90" rx="3" ry="3" width="120" height="10" />
                                        <rect x="290" y="90" rx="3" ry="3" width="95" height="10" />
                                        <rect x="15" y="130" rx="3" ry="3" width="130" height="10" />
                                        <rect x="160" y="130" rx="3" ry="3" width="225" height="10" />
                                    </ContentLoader>
                                </template>
                                <form v-else class="form" id="user-profile" 
                                      method="POST" enctype="multipart/form-data">
                                    <div class="row">
                                        <div class="">
                                            <h6 style="padding-left: 15px;">Account Information</h6>
                                            <div class="form-group-vue col-lg-6 col-md-6 col-sm-6 col-xs-12 bottom-m">
                                            <div class="floating-label-wrap">
                                                <input type="text" class="form-control" readonly
                                                       id="role" v-model="role" placeholder="Role">
                                                       <label for="role" class="floating-label">Role</label>
                                            </div>
                                            </div>
                                            <div class="form-group-vue col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <div class="floating-label-wrap">
                                                <input type="email" 
                                                class="floating-label-field floating-label-field--s3  form-control" 
                                                readonly
                                                       id="email"
                                                       v-model="email" placeholder="Email">
                                                       <label for="email" class="floating-label">Email</label>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                            <div class="form-group-vue-vue col-lg-6 col-md-6 col-sm-6 col-xs-12 bottom-m ">
                                                <div class="floating-label-wrap">
                                                    <input id="first_name"
                                                           class="floating-label-field floating-label-field--s3  form-control"
                                                           type="text" maxlength="65" name="first_name"
                                                           v-model="first_name" required="required"
                                                           placeholder="First Name">
                                                    <label for="first_name" class="floating-label">First Name</label>
                                                </div>
                                            </div>
                                            <div class="form-group-vue col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div class="floating-label-wrap">
                                                    <input id="last_name"
                                                           class="floating-label-field floating-label-field--s3  form-control"
                                                           type="text" maxlength="65" name="last_name"
                                                           v-model="last_name"
                                                           placeholder="Last Name">
                                                    <label for="last_name" class="floating-label">Last Name</label>
                                                </div>

                                            </div>
                                        </div>    
                                         <div class="row">
                                            <div class="form-group-vue col-lg-4 col-md-4 col-sm-6 col-xs-12 bottom-m">
                                                <div class="floating-label-wrap">
                                                    <Select2
                                                        v-model="age"
                                                        name="age"
                                                        id="age"
                                                        required
                                                        placeholder="Select One"
                                                        :options="ageOptions"
                                                        :settings="{tags: false,allowClear: true,placeholder: 'Select One',selectOnClose: false,closeOnSelect: true}"
                                                        />
                                                    <label for="age" class="floating-label">Age</label>
                                                </div>
                                            </div>
                                            <div class="form-group-vue col-lg-4 col-md-4 col-sm-6 col-xs-12 bottom-m">
                                                <div class="floating-label-wrap">
                                                    <Select2
                                                        v-model="race"
                                                        name="race"
                                                        id="race"
                                                        required
                                                        placeholder="Select One"
                                                        :options="raceOptions"
                                                        :settings="{tags: false,allowClear: true,placeholder: 'Select One',selectOnClose: false,closeOnSelect: true}"
                                                        />
                                                   <label for="race" class="floating-label">Race</label>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group-vue col-md-4 col-lg-4 col-sm-6 col-xs-12">
                                                <div class="floating-label-wrap">
                                                <Select2
                                                    name="city"
                                                    v-model="city"
                                                    placeholder="Select City"
                                                    :options="citiesData"
                                                    required
                                                    :settings="{placeholder:'Select One',allowClear: true}"
                                                />
                                                <label for="city_id" class="floating-label">City</label>
                                                </div>
                                            </div>
                                            <div class="form-group-vue col-md-4 col-lg-4 col-sm-6 col-xs-12">
                                                <div class="floating-label-wrap">
                                                    <Select2
                                                        name="state"
                                                        v-model="state"
                                                        placeholder="Select State"
                                                        required
                                                        :options="statesData"
                                                        @change="getStateCities()"
                                                        :settings="{placeholder:'Select One',allowClear: true}"
                                                    />
                                                    <label for="sate" class="floating-label">State</label>
                                                </div>
                                            </div>
                                            <div class="form-group-vue col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-6">
                                                <div class="floating-label-wrap">
                                                    <input type="text"
                                                           class="form-control floating-label-field floating-label-field--s3"
                                                           maxlength="5"
                                                           name="zip_code"
                                                           id="zip_code"
                                                           v-model="zip_code" placeholder="Zipcode">
                                                    <label for="zip_code" class="floating-label">Zipcode</label>

                                                </div>
                                            </div>
                                    </div>
                                    <div class="row">
                                        <div class="profile-gender form-group-vue col-lg-12 col-md-12 col-sm-12 col-xs-12 bottom-m">
                                            <h6>Gender</h6>
                                            <div class="floating-label-wrap">
                                                <template v-for="item in genderOptions">
                                                    <div class="form-group vgps-custom-checkbox-wrapper" :key="item.id">
                                                        <label class="fancyRadioWrapper">
                                                        <img width="40px" :src="'icons/'+item.text+'.svg'| assetPath" :alt="item.text"/>
                                                        <span>{{ item.text }}</span>
                                                        <input
                                                            type="radio"
                                                            v-model="gender"
                                                            checked="checked"
                                                            :value="item.id"
                                                            :id="item.id"
                                                        />
                                                        <span class="checkmark"></span> 
                                                        </label>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group-vue">
                                            <div class="col-md-12 text-right">
                                                 <a @click="save" href="javascript:void(0)" value="save" class="btn save-btn pull-right">
                                                    <img v-if="!processing" :src="'add.svg'| assetPath" />
                                                    <span class="login-show-spinner" :class="{active : processing == true }">
                                                    <i class="fa fa-spinner fa-spin"></i>
                                                    </span> Save
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <footer_section></footer_section>
    </div>
</template>

<script>

// Imports
import { bus } from '../../app'
import helpers from '../../mixin/helpers';
import Select2 from "v-select2-component";

import My_Following_Events from './../../components/MyEvents.vue';
import FooterSection from './../../views/user/partials/footer'  
import HeaderSection from './../../views/user/partials/header'   
import Sidebar_Section from './../../views/user/partials/sidebar'   

import {
  ContentLoader,
  FacebookLoader
} from "vue-content-loader";

export default {
    name:'MyProfileComponent',
    components: {
      ContentLoader,
      FacebookLoader,
      Select2,
      header_section: HeaderSection,
      my_following_events : My_Following_Events,
      footer_section: FooterSection,
      sidebar_section: Sidebar_Section
    },
    created: function(){
        var vm = this;

        bus.$on("stateStoreUpdated", () => {
        vm.getStateCities();

        });

        vm.getDetail();

        bus.$on('stateStoreUpdated', () => {
            vm.$store.dispatch("getAllBusinessSubTypesAction", vm.business_type);
            vm.getStateCities();

        });

        // const {user_subscription} = vm.$session.get("user_data")
        // vm.isSubscribed = user_subscription

        // const user_data = vm.$session.get("user_data")
        // console.log('ssssssssssssssss',user_data.profile.address_details.city)

        // console.log('address222Name',vm.first_name)

        // vm.state = user_data.profile.address_details.state


        // vm.city = user_data.profile.address_details.city


        console.log('address222City', vm.city,'address222State', vm.state)

        console.log('statesData on creation:', this.statesData);
        console.log('citiesData on creation:', this.citiesData);

    },
    watch: {
        state: function(newVal, oldVal) {
            this.getStateCities();
        },
        business_type: function(newVal, oldVal) {
            var vm = this;
            vm.$store.dispatch("getAllBusinessSubTypesAction", newVal);
        }
    },
    activated () {
    },
    props:[],
    mixins: [helpers],
    computed: {
        businessIdsOptions() {
            return this.$store.getters.businessIdsOptions;
        },
        allBusinessSubTypes() {
            return this.$store.getters.allBusinessSubTypes;
        },
        raceOptions() {
            return this.$store.getters.raceOptions;
        },
        genderOptions() {
            return this.$store.getters.genderOptions;
        },
        ageOptions() {
            return this.$store.getters.ageOptions;
        },
        FoodIdsOptions() {
            return this.$store.getters.FoodIdsOptions;
        },
        genreIdsOptions() {
            return this.$store.getters.genreIdsOptions;
        },
        amenitiesFilterOptions() {
            return this.$store.getters.profileAmenitiesFilterOptions;
        },
        tierEventThemes() {
            return this.$store.getters.profileHashtagsOptions;
        },
        statesData() {
            return this.$store.getters.addressesStatesData;
        },
        citiesData() {
            return this.$store.getters.getstateCities;
        }
    },
    data () {
        return {
          title: 'Venue Detail Component',
          loadingContent:false,
          processing:false,
          venue: '',
          role: '',
          first_name:'',
          last_name:'',
          email:'',
          gender:'',
          age:'',
          race:'',
          city:'',
          state:'',
          zip_code:'',
          business_type:[],
          venues:[],
          foods:[],
          genres:[],
          amenities:[],
          hashtags_type:[],
          isSubscribed: true,
          isFetchingCities: false, // Loader state for cities and states
        }
    },
    
    methods: {
        async getDetail() {
        
            var vm = this ;
            vm.loadingContent = true;
            axios.get("get/profile/data")
            .then( (response) => {
                
                if (response.data.success == false) {

                    if(response.data.status_code == 403){
                        this.clearVueSession(response.data.template);
                    }else{
                        sweetalert2.fire("", response.data.description, "warning");
                    }
                    vm.$router.back()
                } else {
                    
                    var data = response.data.data;
                    console.log('user profile Data',data);

                    vm.role = data.role;
                    vm.first_name = data.first_name;
                    vm.last_name = data.last_name;
                    vm.email = data.email;
                    vm.gender = data.gender;
                    vm.age = data.age;
                    vm.race = data.race;                    


                    vm.zip_code = data.address_details.zip_code && data.address_details.zip_code !=0 ?data.address_details.zip_code:'';
                    vm.city = data.address_details.city;
                    vm.state = data.address_details.state_code;

                    console.log('address', vm.city, vm.state)
 
                }
                vm.loadingContent = false;
            })
            .catch(function (errors) {
                console.log('user profile get exception',errors);
                sweetalert2.fire("", errors.data, "error");
                vm.loadingContent = false;
            });
        },
        save: function() {

            var vm = this;
            var data = new FormData();

            data.append("first_name", this.first_name);
            data.append("last_name", this.last_name);
            data.append("email", this.email);
            data.append("age", this.age);
            data.append("race", this.race);
            data.append("gender", this.gender);

            // Stringify address information
            let address = JSON.stringify({
                zip_code : vm.zip_code,
                city: vm.city,
                state: vm.state,
            })
            data.append("address", address);
         

            let validate = $("#user-profile").validate();
            validate.form();

            if (validate.errorList.length > 0) {
                sweetalert2.fire("", "please fill the required fields.", "fail");
                return false;
            }

            if (vm.processing === true) {
                return false;
            }

            vm.processing = true;

            axios
                .post('update/profile/data', data)
                .then(function(response) {
                    
                    if (response.data.success == true) {
                        sweetalert2.fire("", 'Profile updated successfully', "success");
                    } else {
                        vm.exceptionAlert(response)
                    }
                    vm.processing = false;
                })
                .catch(function(errors) {
                    console.log("Profile upate exception", errors);
                    vm.exceptionAlert(errors)
                    vm.processing = false;
                });
            },

            getStateCities: function () {
                console.log('inside profile get state cities')
                this.isFetchingCities = true; // Set loader to true
            try {
                let vm = this;
                if (vm.$store.state.defaultPageData) {
                    let stateData = jQuery.grep(
                        vm.$store.state.defaultPageData.lookup_cities,
                        (obj) => obj.geoname_code == vm.state
                    );

                    if (stateData && stateData.length > 0) {
                        this.$store.state.stateCitiesData = stateData[0].city_set.map((el) => ({
                            id: el.name,
                            text: el.name,
                        }));
                    }
                }
            } catch (error) {
                console.error("Error fetching cities:", error);
            } finally {
                this.isFetchingCities = false; // Set loader to false
            }}}
    
}
</script>