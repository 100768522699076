<template>        
    <ul class="panel-group" id="sidebarNavigation">
      <li style="height: 48px;"><router-link :to="{ name: '' }"></router-link></li>
      <li><router-link :to="{ name: 'home' }">Home</router-link></li>
      <li><router-link :to="{ name: 'directory' }">Contacts</router-link></li>
      <li><router-link :to="{ name: 'mapview' }">Map</router-link></li>
      <li><a :href="url('admin/business/client/account')">My Account</a></li>
      <li><a href="javascript:void(0);" @click="logoutAccount()">Logout</a></li>
    </ul>
</template>

<script>

  import { bus } from './../../app'
  import helpers from './../../../mixin/helpers';

  export default {
    created(){
      var vm = this
    },
    data() {
      return {}
    },
    components:{      

    },
    mixins: [helpers],
    props:[],
    methods: {
      
    }
  }
</script>