<template>
    <div class="containers">
        <section class="vgps-event-amenities-wrapper vgps-event-detail-section-wrapper" id="status-reason">
            <div class="alert alert-info" role="alert">
                <h4 class="alert-heading">Event {{ event.activation_status_display }}! </h4>
                <hr>
                <p>{{ event.status_reason }}</p>
            </div>
        </section>
    </div>
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";


export default {
  data() {
    return {
      //loadingContent: false,
    };
  },
  props: ["event"],
  mixins: [helpers],
  created() {
    
  },
  components: {
    
  },
  methods: {
    
  },
  filters: {
    
  }
};
</script>
