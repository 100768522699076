import { render, staticRenderFns } from "./business-sub-category-dropdown.vue?vue&type=template&id=19f0588e&"
import script from "./business-sub-category-dropdown.vue?vue&type=script&lang=js&"
export * from "./business-sub-category-dropdown.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports