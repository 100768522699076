<template>
  <section class="filters" id="filter-id">
    <template>
      <category_filter v-bind:filters="filters"></category_filter>
      <advance_filters v-bind:filters="filters"></advance_filters>
      <event_listing v-bind:filters="filters" v-bind:hashtag="hashtag"></event_listing>
      <!-- <top_cities></top_cities> -->
    </template>
  </section>
</template>
<script>
// Imports
import { bus } from "../app";
import categoryFilters from "./home/category-filter.vue";
import advanceFilters from "./home/advance-filter.vue";
import EventListing from "./home/event-listing.vue";
import helpers from "../mixin/helpers";
// import TopCities from "./home/top-cities.vue";


// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

const offset = 0;
const limit = 12;

export default {
  mixins: [helpers],
  components: {
    category_filter: categoryFilters,
    advance_filters: advanceFilters,
    event_listing: EventListing,
    // top_cities: TopCities,
    Loading
  },
  beforeCreate() {
    this.loading = true;
    console.log("before create hook");
  },
  beforeUpdate() {},
  watch: {},
  updated() {},
  mounted: function() {},
  created: function() {
    let filtersData = this.$session.get("global_filters");
    let vm = this;
    bus.$on("updateSessionFilters", filters => {
      vm.location = filters.city;
    });
    vm.loading = false;
    vm.filters.offset = offset;
    vm.filters.limit = limit;

  },
  props: ["location", "hashtag"],
  data() {
    return {
      loading: true,
      isLoading: false,
      fullPage: true,
      title: "Home Component",
      filters: {
        location: "",
        state_code: "",
        search: "",
        category: "",
        dates: {
          start: "",
          end: ""
        },
        business_type: "",
        venue_type: "",
        genre: "",
        venue_sub_type: "",
        event_filter: "",
        event_theme: "",
        event_genre: "",
        amenity_id: "",
        offset: offset,
        limit: limit
      },
      isApiCompleted: false,
      userData: [],
      optionsData: []
    };
  },
  methods: {
    lookupData: function() {
      axios
        .get("data/lookup")
        .then(response => {
          this.optionsData = response.data.lookup_data;
          this.userData = response.data.user_data;
          this.isApiCompleted = true;
          this.filterLookUps();
          bus.$emit("loadCities");
        })
        .catch(function(errors) {});
    },
    filterLookUps: function(data) {
      this.optionsData["EventType"].lookups.sort(function(a, b) {
        if (a.position > b.position) {
          return 1;
        }
        if (a.position < b.position) {
          return -1;
        }
        return 0;
      });

      this.optionsData["EventCategory"].lookups.sort(function(a, b) {
        if (a.position > b.position) {
          return 1;
        }
        if (a.position < b.position) {
          return -1;
        }
        return 0;
      });

      this.optionsData["EventTheme"].lookups.sort(function(a, b) {
        if (a.position > b.position) {
          return 1;
        }
        if (a.position < b.position) {
          return -1;
        }
        return 0;
      });
      this.optionsData["EventGenre"].lookups.sort(function(a, b) {
        if (a.position > b.position) {
          return 1;
        }
        if (a.position < b.position) {
          return -1;
        }
        return 0;
      });
    }
  }
};
</script>