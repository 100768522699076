<template>
    <div class="filter-top-bar">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div id="myBtnContainer">
                        <button :class="{active: this.filters.category == 'all' }" class="btn all" v-on:click="filterSelection('all', this)">All</button>
                        <button :class="{active: this.filters.category == 'ceo' }" class="btn ceo" v-on:click="filterSelection('ceo')">Trending</button>
                        <button :class="{active: this.filters.category == 'week' }" class="btn week" v-on:click="filterSelection('week')">This week</button>
                        <button :class="{active: this.filters.category == 'night-life' }" class="btn night-life" v-on:click="filterSelection('night-life')">Night Life</button>
                        <button :class="{active: this.filters.category == 'entertainment' }" class="btn entertainment" v-on:click="filterSelection('entertainment')">Entertainment</button>
                        <button :class="{active: this.filters.category == 'national' }" class="btn national" v-on:click="filterSelection('national')">National</button>
                        <button :class="{active: this.filters.category == 'festival' }" class="btn festival" v-on:click="filterSelection('festival')">Festivals</button>
                        <button :class="{active: this.filters.category == 'tour' }" class="btn tour" v-on:click="filterSelection('tour')">Tours</button>
                        <button :class="{active: this.filters.category == 'MC/SC/CC' }" class="btn mc" v-on:click="filterSelection('mc')">MC/SC/CC</button>
                        <button :class="{active: this.filters.category == 'sports' }" class="btn sports" v-on:click="filterSelection('sports')">Sports</button>
                        <button :class="{active: this.filters.category == 'international' }" class="btn international" v-on:click="filterSelection('international')">International</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { bus } from '../../app'
    export default {
        mounted() {
            console.log('Component mounted.')
        },
        props:['filters'],
        data() {
            return {
                loadingContent:false,
                categories: []
            }
        },
        methods: {
            filterSelection:function(cat){
                this.filters.category = cat
                bus.$emit('filterSelection')
            }
        }
    }
</script>
