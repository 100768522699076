<template>


    <div :class="{ active_side_menu: showMobileMenu }" class="sidenav hide-md hide-lg vissible-xs vissible-sm">
      <div id="mobile-sidebar" class="menu-box">
          <a href="javascript:void(0)" title="Close menu" @click="showMobileMenu = !showMobileMenu" class="closebtn">×</a>
            
          <template v-if="isAuth()">
              <public_user v-if="isPublicUserAuth()"></public_user>
              <business_user v-if="isClientBusinessAuth()"></business_user>
              <admin_user v-if="isAdminsAccounts()"></admin_user>
          </template>
          <template v-else>
            <ul class="panel-group" id="sidebarNavigation">
              <li style="height: 48px;"><router-link :to="{ name: '' }"></router-link></li>
              <li><router-link :to="{ name: 'home' }">Home</router-link></li>
              <li><router-link :to="{ name: 'mapview' }">Map</router-link></li>
              <li><router-link :to="{ name: 'directory' }">Contacts</router-link></li>
              <li><a href="javascript:void(null);" id="login-btn" @click="showMobileMenu = !showMobileMenu" data-toggle="modal" data-target="#loginModal">Sign In</a></li>
              <li><a href="javascript:void(null);" id="signUp" @click="showMobileMenu = !showMobileMenu" data-toggle="modal" data-target="#signUpModal">Sign Up</a></li>
            </ul>
          </template>
      </div>
  </div>
</template>

<script>

  import { bus } from './../app'
  import helpers from './../../mixin/helpers';

    // Get User Mobile menues
  import Public_User_Menu_Items from './menues/user.vue';
  import Business_User_Menu_Items from './menues/business.vue';
  import Admin_User_Menu_Items from './menues/admin.vue';

  export default {
    created(){
      var vm = this
    },
    data() {
      return {}
    },
    components:{      
      public_user: Public_User_Menu_Items,
      business_user: Business_User_Menu_Items,
      admin_user: Admin_User_Menu_Items
    },
    mixins: [helpers],
    props:['showMobileMenu'],
    methods: {
      
    }
  }
</script>