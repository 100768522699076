<template>
  <!-- tabs Start -->
  <section class="event-top-bar">
    <div class="container">
      <div class="row">
        <div
          class="col-md-12 vgps-mobile-horizontal-scroll-wrapper vgps-event-section-nav"
        >
          <span class="vgps-scroll-left"></span>
          <span class="vgps-scroll-right"></span>
          <ul class="vgps-jump-to-links-wrapper vgps-horizontal-scroll-wrapper">
            <li
              v-if="
                GeneralInfo.length > 0 ||
                bookingInfo.length > 0 ||
                bookingManagerInfo.length > 0
              "
            >
              <router-link
                to=""
                v-on:click.native="setActive('info')"
                :class="{ active: isActive('info') }"
                v-scroll-to="{ el: '#contact-info', offset: -180 }"
                >Info Contacts</router-link
              >
            </li>
            <li v-if="venue.events.today_events.length > 0">
              <router-link
                to=""
                v-on:click.native="setActive('today-theme')"
                :class="{ active: isActive('today-theme') }"
                v-scroll-to="{ el: '#today-themes', offset: -100 }"
                >Today's Themes</router-link
              >
            </li>
            <li v-if="venue.events.recurring_events.length > 0">
              <router-link
                to=""
                v-on:click.native="setActive('recurring')"
                :class="{ active: isActive('recurring') }"
                v-scroll-to="{ el: '#recurring-event', offset: -100 }"
                >Recurring Events</router-link
              >
            </li>
            <li v-if="venue.events.month_events.length > 0">
              <router-link
                to=""
                v-on:click.native="setActive('upcoming')"
                :class="{ active: isActive('upcoming') }"
                v-scroll-to="{ el: '#month-event', offset: -100 }"
                >Upcoming Events</router-link
              >
            </li>
            <li v-if="amenities.length > 0">
              <router-link
                to=""
                v-on:click.native="setActive('amenities')"
                :class="{ active: isActive('amenities') }"
                v-scroll-to="{ el: '#amenities', offset: -100 }"
                >Amenities</router-link
              >
            </li>
            <li v-if="venue.venue_bio && venue.venue_bio != ''">
              <router-link
                to=""
                v-on:click.native="setActive('about-business')"
                :class="{ active: isActive('about-business') }"
                v-scroll-to="{ el: '#about-business', offset: -100 }"
                >About Business</router-link
              >
            </li>
            <li v-if="venue.panaromic_images.length > 0">
              <router-link
                to=""
                v-on:click.native="setActive('gallery')"
                :class="{ active: isActive('gallery') }"
                v-scroll-to="{ el: '#gallery', offset: -100 }"
                >Gallery</router-link
              >
            </li>
            <li v-if="venue.promo_videos.length > 0">
              <router-link
                to=""
                v-on:click.native="setActive('promo')"
                :class="{ active: isActive('promo') }"
                v-scroll-to="{ el: '#promo-videos', offset: -120 }"
                >Promo Videos</router-link
              >
            </li>
            <li v-if="venue.address_details">
              <router-link
                to=""
                v-on:click.native="setActive('mapdetail')"
                :class="{ active: isActive('mapdetail') }"
                v-scroll-to="{ el: '#mapdetail', offset: -100 }"
                >Location</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- Tabs End -->
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";

import VueSilentbox from "vue-silentbox"; //https://vuejsexamples.com/a-lightbox-inspired-vue-js-component/
import EventDetailFollowButton from "../../components/global/event-detail-follow-button.vue";
import EventDetailLikeButton from "../../components/global/detail-page-like.vue";

import {
  ContentLoader,
  FacebookLoader,
  CodeLoader,
  BulletListLoader,
  InstagramLoader,
  ListLoader,
} from "vue-content-loader";

export default {
  data() {
    return {
      loadingContent: false,
      activeItem: "info",
    };
  },
  computed: {
    bookingInfo: function () {
      var data = this.venue.contacts.booking.filter(function (obj) {
        return obj.provider == "booking-email" || obj.provider == "phone"
          ? true
          : false;
      });
      return data;
    },
    bookingManagerInfo: function () {
      var data = this.venue.contacts.booking.filter(function (obj) {
        return obj.provider == "manager-email" ||
          obj.provider == "manager-number"
          ? true
          : false;
      });
      return data;
    },
    GeneralInfo: function () {
      var data = this.venue.contacts["info-contact"].filter(function (obj) {
        return obj.provider == "website" ||
          obj.provider == "phone-number" ||
          obj.provider == "press-email"
          ? true
          : false;
      });
      return data;
    },
  },
  props: ["venue", "amenities"],
  mixins: [helpers],
  created() {
    var vm = this;
  },
  components: {
    ContentLoader,
    FacebookLoader,
    VueSilentbox,
    event_detail_follow_button: EventDetailFollowButton,
    event_detail_like_button: EventDetailLikeButton,
    // follow_venue: FollowVenue
  },
  methods: {
    isActive: function (menuItem) {
      return this.activeItem === menuItem;
    },
    setActive: function (menuItem) {
      this.activeItem = menuItem; // no need for Vue.set()
    },
  },
  filters: {},
};
</script>
