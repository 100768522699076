<template>
  <div>
    <!-- Main Start -->
    <header_section></header_section>
    <main class="top-inner-pages pressKitWrapper">
      <!-- <section class="headerSectionWrapper">
        <div class="headerBGImage">
          <img :src="'banners/vgps-min.png' | assetPath" alt="header Image" />
        </div>
      </section> -->
      <!-- Dashboard Start -->
      <section class="dashboard vgps_contact_support_wrapper">
        <div class="container">
          <div class="col-md-12 vgps_page_header_wrapper">
            <h3>Image Size Matrix</h3>
          </div>
          <!-- Portfolio -->
          <div id="portfolio" class="text-left pt">
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-12">
                <div class="bg-white-container">
                  <table class="table table-responsive">
                    <thead>
                      <tr>
                        <th>Sr.#</th>
                        <th>Section</th>
                        <th colspan="1">Dimension</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Ads Flyer</td>
                        <td colspan="1">296x296</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Home Page Banner</td>
                        <td colspan="1">1440x480</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Event Flyer Image</td>
                        <td colspan="1">296x296</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Event Gallery Images</td>
                        <td colspan="1">844x476</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Venue Flyer Image</td>
                        <td colspan="1">296x296</td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>Venue Gallery Images</td>
                        <td colspan="1">844x476</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-1"></div>
            </div>
          </div>
          <!-- end Portfolio -->
        </div>
      </section>
      <!-- Dashboard End -->
    </main>
    <!-- Main End -->
    <footer_section></footer_section>
  </div>
</template>

<script>
import { bus } from "./app";
import FooterSection from "./../views/partials/footer";
import HeaderSection from "./../views/partials/header";
import helpers from "./../mixin/helpers";

export default {
  data() {
    return {
      loadingContent: false,
    };
  },
  mixins: [helpers],
  components: {
    footer_section: FooterSection,
    header_section: HeaderSection,
  },
};
</script>