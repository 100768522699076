<template>
    <div class="event-vue-wrapper">
        <header_section></header_section>
        <main class="top-inner-pages filters">    
            <section class="my-venue admin-dashboard ">
                <div class="container-fluid">
                <div class="row">
                    <div class="hidden-md hidden-sm hidden-xs col-lg-3 col-md-4 col-sm-12 col-xs-12">
                    </div>
                    <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                        <div class="right-box">
                            <div class="admin-dash-section-header">
                                <h2>My Accounts</h2>
                                <p>My Following accounts (venues, people and communities).</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                        <div id="sidebar" class="hidden-md hidden-sm hidden-xs col-lg-3 col-md-4 col-sm-12 col-xs-12">
                            <sidebar_section></sidebar_section>
                        </div>
                        <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                            <div class="right-box">
                                <div class="dicrectory-page-conten2">
                                    <my_following_venues></my_following_venues>
                                </div>   
                            </div>
                        </div>
                    </div>    
                </div>
            </section>
        </main>
        <footer_section></footer_section>
    </div>
</template>

<script>

// Imports
import { bus } from '../../app'
import helpers from '../../mixin/helpers';

// import BannerSection from './../views/venue/header'
import MyVenues from './../../components/MyVenues.vue';
import FooterSection from './../../views/user/partials/footer'  
import HeaderSection from './../../views/user/partials/header'   
import Sidebar_Section from './../../views/user/partials/sidebar'   

import {
  ContentLoader,
  FacebookLoader,
  CodeLoader,
  BulletListLoader,
  InstagramLoader,
  ListLoader
} from "vue-content-loader";

export default {
    name:'MyVenuesComponent',
    components: {
      ContentLoader,
      FacebookLoader,
      header_section: HeaderSection,
      my_following_venues : MyVenues,
      footer_section: FooterSection,
      sidebar_section: Sidebar_Section
    },
    created: function(){
        // this.getDetail();
    },
    watch: {
        $route: "fetchData"
    },
    activated () {
        // this.$destroyKey()
    },
    props:[],
    mixins: [helpers],
    computed: {
        isAmenitiesLength: function(){
            var obj = this.venue.amenities;
            var arr = Object.keys(obj).map(function (key) { return obj[key]; });
            var data = arr.filter(function(obj,val){
                return obj.length > 0 ? true : false;  
            });
            return data;
        },
    },
    data () {
        return {
          title: 'Venue Detail Component',
          loadingContent:false,
          venue: ''
        }
    },
    methods: {
        async getDetail(id) {
        
            var vm = this ;
            // console.log('api path',window.api_url);
            vm.loadingContent = true;
            axios.get("get/venue/"+this.$route.params.id)
            .then( (response) => {
                if (response.data.success == false) {
                    sweetalert2.fire("", response.data.description, "warning");
                    vm.$router.back()
                } else {
                    vm.venue = response.data.data 
                }
                vm.loadingContent = false;
            })
            .catch(function (errors) {
                sweetalert2.fire("", errors.data, "error");
                vm.loadingContent = false;
            });
        }
    }
}
</script>