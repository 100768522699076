<template>
    <section v-if="isGroupedPerformers.length > 0 || event.eventpeoplerelations.performer.length > 0" class="vgps-event-performers-wrapper vgps-event-detail-section-wrapper" id="performers">
            <div class="containers">
                <div class="row">
                    <div class="col-md-12 vgps-event-detail-section-title">
                        <h2>Performers/Guests</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="vgps-event-performer-grid-wrapper">
                    <template v-if="isAnnualEvents(event.type,event.category) && isGroupedPerformers.length > 0">
                        <template v-for="(item,key,count) in event.eventpeoplerelations.grouped_performer">
                           <div v-if="item.length > 0" class="panel-group col-md-12 col-sm-12 col-xs-12 event-performer-widget" id="accordion">
                                <div  class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4 class="panel-title copanel-headingllapsed" data-toggle="collapse" :data-target="key | removeSpace">
                                            {{ key }} ({{item.length }} {{ "performers" }})
                                            <span class="pull-right"><i class="fa fa-chevron-down"></i></span>
                                            <span class="pull-right"><i class="fa fa-chevron-up"></i></span>
                                        </h4>
                                    </div>
                                    <div :id="key | removeSpaceDiv" class="panel-collapse collapse" :class="[count == 0? 'in':'']">
                                        <div class="panel-body">
                                            <div v-for="(performer,index) in item" :key="index" class="items col-lg-6 col-md-6 col-sm-12  col-xs-12 ">
                                                <div class="vgps-event-performer-grid">
                                                    <div class="thumb">
                                                        <router-link :to="{ name: 'venue', params: { id: performer.people.id }}">
                                                            <img :src="performer.people.thumbnail_urls.thumb_296">
                                                        </router-link>    
                                                    </div>
                                                    <div class="desc">
        <h3>
            <router-link :to="{ name: 'venue', params: { id: performer.people.id }}">
                {{ performer.people.title }}
            </router-link>
        </h3>
        <p>Category: {{ performer.people.type_name }}</p>

        <span v-if="performer.people.favourites_count" class="followers-count">
            Events: {{ performer.people.events_count }}
        </span>
        <ul class="performers-genres">
            <li v-if="performer.people.genre.length === 1">
                Music Genre: {{ performer.people.genre[0].name }}
            </li>
            <li v-else>
                Music Genres:
                <button
                    style="color: #337ab7;background-color: unset;border: none;"
                    @click="togglePopup(performer.people.id)"
                >
                    {{ performer.people.genre.length }}
                </button>
            </li>
        </ul>

        <!-- Popup Modal -->
        <div v-if="activePerformer === performer.people.id" class="popup-overlay" @click="handleOverlayClick">
            <div class="popup-content" @click.stop>
                <h3>Genres</h3>
                <ul>
                    <li v-for="genre in performer.people.genre" :key="genre.id">{{ genre.name }}</li>
                </ul>
                <button @click="closePopup">Close</button>
            </div>
        </div>
    </div>
                                                    <div class="socials-btns-wrapper">
                                                        <ul>
                                                            <template v-for="(social,index2) in performer.people.social_contacts">
                                                                <li v-if="getSocialAppsLinks(social.provider) && social.value !=''"  :key="index2" :id="social.provider">
                                                                    <a :href="social.value" target="_blank"><img :src="social.icon_url"></a>
                                                                </li>    
                                                            </template>
                                                        </ul>
                                                    </div>
                                                    <div class="share-follow-btn-wrapper">
                                                        <ul>
                                                            <li class="follow-btn">
                                                                <venue_follow_button 
                                                                    v-bind:id="performer.people.id"
                                                                    v-bind:title="performer.people.title"                  
                                                                    v-bind:follow="performer.people.follow">
                                                                </venue_follow_button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template v-else>    
                            <div v-if="event.eventpeoplerelations.performer" class="performer-non-aunnual-events">
                                <div v-for="(performer,index3) in  event.eventpeoplerelations.performer" :key="index3" class="items col-lg-6 col-md-6 col-sm-6  col-xs-12 ">
                                    <div class="vgps-event-performer-grid">
                                        <div class="thumb">
                                            <router-link :to="{ name: 'venue', params: { id: performer.people.id }}">
                                                <img :src="performer.people.thumbnail_urls.thumb_296">
                                            </router-link>    
                                        </div>
                                        <div class="desc">
                                            <h3>
                                                <router-link :to="{ name: 'venue', params: { id: performer.people.id }}">{{ performer.people.title }}</router-link>
                                            </h3>
                                            <!-- <span v-if="performer.people.favourites_count" class="followers-count">{{ performer.people.favourites_count }} followers</span> -->
                                            <p>Category: {{ performer.people.type_name }}</p>
                                            <span v-if="performer.people.favourites_count" class="followers-count">
            Events: {{ performer.people.events_count }}
        </span>
                                            <!-- <ul class="performers-genres">
                                                <li v-for="genre in performer.people.genre.slice(0,1)" :key="genre.id">Music Genre: {{genre.name}}</li>
                                            </ul> -->
                                            <ul class="performers-genres">
                                                                                            

                                                <li v-if="performer.people.genre.length === 1">
                                                    Music Genre: {{ performer.people.genre[0].name }}
                                                </li>
                                                <li v-else>
                                                    Music Genres:
                                                    <button
                                                        style="color: #337ab7;background-color: unset;border: none;"
                                                        @click="togglePopup(performer.people.id)"
                                                    >
                                                        {{ performer.people.genre.length }}
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="socials-btns-wrapper">
                                            <ul>
                                                <template v-for="(social,index2) in performer.people.social_contacts">
                                                    <li v-if="getSocialAppsLinks(social.provider) && social.value !=''"  :key="index2" :id="social.provider">
                                                        <a :href="social.value" target="_blank"><img :src="social.icon_url"></a>
                                                    </li>    
                                                </template>
                                            </ul>
                                        </div>
                                        <div class="share-follow-btn-wrapper">
                                                <ul>
                                                    <li class="follow-btn">
                                                        <venue_follow_button 
                                                            v-bind:id="performer.people.id"
                                                            v-bind:title="performer.people.title"                  
                                                            v-bind:follow="performer.people.follow">
                                                        </venue_follow_button>
                                                    </li>
                                                </ul>
                                            </div>
                                    </div>
                                </div>
                            </div>
                   
                    </template>
                        
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";

import VueSilentbox from 'vue-silentbox';//https://vuejsexamples.com/a-lightbox-inspired-vue-js-component/
import EventDetailFollowButton from '../../components/global/event-detail-follow-button.vue';
import EventDetailLikeButton from '../../components/global/detail-page-like.vue';
import VenueDetailFollowButton from '../../components/global/detail-follow-button.vue';



import {
  ContentLoader,
  FacebookLoader,
  CodeLoader,
  BulletListLoader,
  InstagramLoader,
  ListLoader
} from "vue-content-loader";

export default {
  data() {
    return {
      loadingContent: false,
      directionURL: '',
      counter: 0,
      showPopup: false,
      activePerformer: null, // Keeps track of the active performer ID for the popup


    };
  },
  props: ["event","performer"],
  mixins: [helpers],
  created() {
    var vm = this;
    console.log('header section data',vm.event);
  },
  mounted: function() {



  },
  computed: {
    isGroupedPerformers: function(){
        
        // return filtered = Object.filter(this.event.eventpeoplerelations.grouped_performer, function(key,val){
        //     debugger
        //     return val.length > 0 ? true : false; 

        // }); 
        // debugger
        var obj = this.event.eventpeoplerelations.grouped_performer;
        var arr = Object.keys(obj).map(function (key) { return obj[key]; });
        // console.log('convert object to array',arr);
        // console.log('sdsads',arr[2].length)
        var data = arr.filter(function(obj,val){
            return obj.length > 0 ? true : false;  
        });

        return data;
    }
  },
  components: {
    ContentLoader,
    FacebookLoader,
    VueSilentbox,
    'event_detail_follow_button': EventDetailFollowButton,
    'event_detail_like_button': EventDetailLikeButton,
    'venue_follow_button': VenueDetailFollowButton,
    // follow_venue: FollowVenue
  },
  methods: {
       getSocialAppsLinks: function (link) {
        var arrData = ['facebook', 'twitter', 'instagram','youtube','snapchat'];
        return (arrData.includes(link)) ? true : false
    }, 
    togglePopup(performerId) {
            this.activePerformer = this.activePerformer === performerId ? null : performerId;
        },
        closePopup() {
            this.activePerformer = null;
        },
        handleOverlayClick() {
            this.closePopup();
        },
    
  },
  filters: {
    removeSpace:function(str){
    //    this.counter = this.counter + 1  
    //    str = '#index-'+str;
       str = '#index-'+str.replace( /^\D+/g, '');
       str = str.replace(/\//g, '-');
       return str
    },
     
    assetPath: function(img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
    url: function(path) {
      var path = window.axios.defaults.baseURL + "/" + path;
      return path;
    },
    
    removeSpaceDiv:function(str){
    //    str = 'index-'+str;
       str = 'index-'+str.replace( /^\D+/g, '');
       str = str.replace(/\//g, '-');
       return str
    }
  }
};
</script>
<style scoped>
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
}

.popup-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.popup-content button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.popup-content button:hover {
    background-color: #0056b3;
}

</style>