<template>
  <div class="filter-select">
    <div class="container">
      <div class="row">
        <div
          class="home-advance-filters col-md-12"
        >
          <span class="vgps-scroll-left"></span>
          <span class="vgps-scroll-right"></span>
          <ul class="hPDateAndFilterWrap">
            <li class="pull-right">
              <div class="form-group">
                <router-link
                  class="btn more-filters btn-default btn-grey"
                  :to="{ name: 'home'}"
                >Home</router-link>
              </div>
            </li>
            <li
              :class="{ active: dateRange.length > 0 }"
              class="home-date-range"
            >
              <div class="form-group">
                <date-picker
                  v-model="dateRange"
                  value-type="YYYY-MM-DD"
                  format="MMM DD"
                  input-class="form-control"
                  :disabled-date="notBeforeToday"
                  range
                  @change="updateValues"
                  placeholder="Dates"
                  :input-attr="{
                    name: 'daterange',
                    id: 'event-search-dateranges',
                  }"
                ></date-picker>
              </div>
            </li>
            <div class="hPDateFilterWrp">
              <span
                v-if="isFilterSelected"
                class="butstyle"
                @click="clearFilter"
              >
                <i class="fa fa-times"></i>
              </span>
            </div>
            <li class="pull-right">
              <div class="form-group">
                <router-link
                  class="btn more-filters btn-default btn-grey"
                  :to="{
                    name: 'search',
                    query: {
                      search_main_category: search_main_category,
                      search_date_range: search_date_range,
                    },
                  }"
                >Filters</router-link
                >
              </div>
            </li>
            
            <!-- <li v-if="isNearMeLocationOption(search_main_category)" class="pull-right">
                <div class="form-group">
                  <button class="global-venue nearme-location-btn" @click="nearMeEvents" :class="[ isNearMe? 'active':'']" title="Search Event near to you."><i class="fas fa-map-marker-alt"></i> Near Me</button>
                </div>
            </li> -->
          </ul>
          <ul class="view-type-wrapper">
            <li class="btn-group form-group" role="group">
              <button type="button" @click="toggleView('gride')" :class="[getViewType == 'gride'?'active-view':'']" class="btn btn-secondary view-toggle-btn hidden-xs"><i class="fas fa-th"></i></button>
              <button type="button" @click="toggleView('list')" :class="[getViewType == 'list'?'active-view':'']" class="btn btn-secondary view-toggle-btn hidden-xs"><i class="fas fa-list"></i></button>
        
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { bus } from "../../app";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Select2 from "v-select2-component";
import helpers from "../../mixin/helpers";
import { mapFields } from "vuex-map-fields";

export default {
  components: {
    DateRangePicker,
    Select2,
  },
  data() {
    return {
      value: "",
      dateRange: "",
      venue_type: [],
      isDateFormat: false,
      isFilterSelected: false,
      business_type: "",
      dropDownOptions: [],
      subBusinessTypes: [],
    };
  },
  computed: {
    tierEventThemes() {
      return this.$store.getters.tierEventThemes;
    },
    getViewType() {
      return this.$store.getters.getViewType;
    },
    eventGenre() {
      return this.$store.getters.eventGenre;
    },
    businessTypes() {
      return this.$store.getters.businessTypes;
    },
    businessSubTypes() {
      return this.$store.getters.businessSubTypes;
    },
    FoodCuisinesData() {
      return this.$store.getters.FoodCuisinesData;
    },
    ...mapFields(["search_main_category", "search_date_range","isNearMe","currentLongitude","currentLatitude"]),
  },
  watch: {
    "filters.business_type": function (newVal, oldVal) {},
    dateRange: function (newVal, oldVal) {
      var vm = this;
      vm.search_date_range = newVal;
    },
  },
  created: function () {
    var vm = this;

    bus.$on("filterSelection", () => {
      vm.hasEmptyFilter();
    });

    bus.$on("hashtag", (category, hashtag, type) => {
      if (type == "category") {
        vm.filters.category = category;
      } else if (type == "venue_type") {
        vm.business_type = category;
        vm.filters.business_type = category;
        vm.filters.category = category;
        vm.filters.venue_type = hashtag;
      } else {
        vm.filters.event_theme = category;
      }
      vm.hasEmptyFilter();
    });

    vm.hasEmptyFilter();
  },
  mixins: [helpers],
  props: ["filters"],
  methods: {
    toggleView: function(view){
      this.$store.dispatch("toggleViewAction", view);
    },
    nearMeEvents: function(){
      
      this.getCurrentUserPosition();
      console.log('Current Latitude',this.currentLatitude)
      if(this.currentLatitude && this.currentLongitude){
        this.isNearMe = !this.isNearMe;
      }
      bus.$emit("advanceFilter");
    },
    mySelectEvent({id}) {
      this.filters.venue_type = id;
      this.isFilterSelected = true;
      this.business_type = id;
      bus.$emit("advanceFilter");
      this.hasEmptyFilter();
    },
    updateValues: function (data) {
      this.isFilterSelected = true;
      this.filters.dates.start = data[0];
      this.filters.dates.end = data[1];
      bus.$emit("updateDateValues");
      this.hasEmptyFilter();
    },
    advanceFilter: function () {
      this.isFilterSelected = true;
      this.business_type = this.filters.business_type;
      bus.$emit("advanceFilter");
      this.hasEmptyFilter();
      this.isActiveFilter();
    },
    clearFilter: function () {
      var vm = this;

      this.isFilterSelected = false;
      $(".js-select2").val("");

      $(".reportrange-text").find("span").html("Date");
      vm.dateRange = "";
      vm.filters.dates.start = "";
      vm.filters.dates.end = "";
      $("#event-search-dateranges").val("");

      vm.filters.venue_type = "";
      vm.filters.category = "ceo";
      vm.filters.venue_sub_type = "";
      vm.subBusinessTypes = [];

      vm.filters.business_type = "";
      vm.business_type = "";
      vm.filters.event_theme = "";
      vm.filters.event_genre = "";
      vm.filters.amenity_id = "";
      vm.filters.search = "";

      if (
        vm.dateRange != "" &&
        vm.filters.dates.start == "" &&
        vm.filters.dates.end == ""
      ) {
        vm.dateRange.startDate = "";
        vm.dateRange.endDate = "";
      }

      bus.$emit("clearAdvanceFilter");
    },
    applySelectedClass: function (value) {
      return value != "" ? "js-select2 dropDownSelected" : "js-select2";
    },
    hasEmptyFilter: function () {
      var vm = this;
      if (this.filters.dates.end != "" && this.filters.dates.start != "") {
        vm.isFilterSelected = true;
      } else if (vm.filters.business_type != "") {
        vm.isFilterSelected = true;
      } else if (vm.filters.event_genre != "") {
        vm.isFilterSelected = true;
      } else if (vm.filters.amenity_id != "") {
        vm.isFilterSelected = true;
      } else if (vm.filters.category != "" && vm.filters.category != "ceo") {
        vm.isFilterSelected = true;
      } else if (
        vm.filters.category != "" &&
        vm.filters.category != "ceo" &&
        vm.filters.q != ""
      ) {
        vm.isFilterSelected = true;
      } else if (vm.filters.event_theme != "") {
        vm.isFilterSelected = true;
      } else if (vm.filters.venue_type != "") {
        vm.isFilterSelected = true;
      } else {
        vm.isFilterSelected = false;
      }
    },
  },
  filters: {
    dateFilterFormatStart: function (date) {
      var myDate = "";
      var currentDay = 0;
      if (date != null) {
        var isoStr = Date.parse(date);
        var current_datetime = new Date(isoStr);
        currentDay =
          current_datetime.getDate() <= 9
            ? "0" + current_datetime.getDate()
            : current_datetime.getDate();
        let formatted_date =
          current_datetime.getFullYear() +
          "-" +
          (current_datetime.getMonth() + 1) +
          "-" +
          currentDay;
        myDate = formatted_date;
      } else {
        myDate = "";
      }
      $("#calendarStart_date").val(
        date.toLocaleString("default", { month: "short" }) + " " + currentDay
      );
      $(".reportrange-text")
        .find("span")
        .text(
          $("#calendarStart_date").val() + " - " + $("#calendarEnd_date").val()
        );
      $("#selectedStart_date").val(myDate);
      return myDate;
    },
    dateFilterFormatEnd: function (date) {
      var myDate = "";
      var currentDay = 0;
      if (date != null) {
        var isoStr = Date.parse(date);
        var current_datetime = new Date(isoStr);
        currentDay =
          current_datetime.getDate() <= 9
            ? "0" + current_datetime.getDate()
            : current_datetime.getDate();
        let formatted_date =
          current_datetime.getFullYear() +
          "-" +
          (current_datetime.getMonth() + 1) +
          "-" +
          currentDay;
        myDate = formatted_date;
      } else {
        myDate = "";
      }
      $("#calendarEnd_date").val(
        date.toLocaleString("default", { month: "short" }) + " " + currentDay
      );
      $(".reportrange-text")
        .find("span")
        .text(
          $("#calendarStart_date").val() + " - " + $("#calendarEnd_date").val()
        );
      $("#selectedEnd_date").val(myDate);
      return myDate;
    },
    assetPath: function (img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
  },
};

function showDiv() {
  div = document.getElementById("ClearFilters");
  div.style.display = "block";
}
window.onscroll = function () {
    var element = document.querySelector('.home-advance-filters');
    if (window.innerWidth <= 768) { // Mobile view
      var sticky = element.offsetTop;
      if (window.pageYOffset > sticky) {
        element.classList.add('fixed-position'); // Add the class with !important
      } else {
        element.classList.remove('fixed-position'); // Remove the class
      }
    }
  };
</script>
<style>
  .fixed-position {
    position: fixed;
    top: 2;
    z-index: 10;
    margin-top: -50px !important;
  }

    /* Apply media query for mobile view */
    @media screen and (max-width: 767px) {
    .home-vue-content .home-advance-filters {
      width: 100% !important;
      margin: 0 auto !important;
      display: flex;
    }

    /* When the element is fixed, unset the margin auto */
    .home-vue-content .home-advance-filters.fixed-position {
      margin-top: -50px !important;
      width: 100% !important;
      display: flex !important;
    }
  }
</style>
