<template>
    <div>
    <!-- Main Start -->
    <header_section>
    

    </header_section>
    <main class="top-inner-pages">
        <!-- <section class="headerSectionWrapper">
            <div class="headerBGImage">
          <img :src="'banners/vgps-min.png' | assetPath" alt="header Image" />
            </div>
        </section> -->
        <!-- Dashboard Start -->
        <section id="ceo-message-wrapper" class="dashboard vgps_contact_support_wrapper innerPageContentSec">
            <div class="container ceoPageWrapper paddingLR20">
                <div class="row">
                    <div class="col-md-12  mb-5">
                        <h2>CEO Message</h2>
                    </div>
                    <div class="col-md-3 col-ls-3 col-sm-4 col-xs-12 ceo-image">
                      <img class="img-responsive" src="https://d2cv1d7fub3ij0.cloudfront.net/ceo-publicity-media/team/david.jpg" alt="David Conn Morey" />
                    </div>
                    <div class="col-md-9 vgps_privacy_content_wrapper">
                      <p> Hello,<br> I'm Conn Morey, Founder/CEO of VenueGPS (Venue Global Promotional Service). It’s my pleasure to welcome you and explain our fascinating new product which is guaranteed to bridge entertainment and technology for your ultimate personalized convenience.</p>
                      <p>
                        <strong>VenueGPS</strong> is the newest mobile application and website that will completely revolutionize and overhaul the way we connect to our local communities and cities abroad. The entertainment and lifestyle industry had been in need for some time now a
product which gives ordinary people electronic access to short and long-term events. All generations desire to be “in-the-know” of their entertainment and lifestyle interests. I firmly believe our product allows consumers to finally be able to create their own travel experience from start to finish in one centralized application.
                      </p>

                      <p>
                        The wasteful practice of print and flyer distribution employed by
advertisement agencies, venue owners and event promoters are due for an
electronic transformation that will restore the balance of power of choice to
hard working consumers. Here at <strong>VenueGPS</strong>, our team of bold researchers work
diligently to ensure we are enhancing entertainment awareness by maintaining a
user-friendly digital platform while steadily eliminating the need for word-of-mouth and physical marketing using cardboard flyers.
                      </p>
                      <p>
                        Advertising marketeers currently provide post-reviews and lack the access to the 32 other website amenities offered to our customers such as VenueAID (Venue Advisory Immediate Display) and our prestigious VenueGPS alert system just to name a few. After 48 months of brainstorming, analyzing entertainment spending trends, and concept development, we are certain that our dynamic product will undeniably alter the manner in which entertainment is resourced and enjoyed forever.
                      </p>
                      <p>
                        <strong>VenueGPS</strong>, Live. Travel. Explore!
                      </p> 
                      <p>V/r<br>
                        Founder/CEO<br>
                        Conn Morey
                      </p>

                      <iframe width="560" height="315" src="https://www.youtube.com/embed/eOibT8CEHn8?si=FBwTYfW5-XHoQ7NO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                    </div>
                </div>
            </div>
        </section>
        <!-- Dashboard End -->

    </main>
    <!-- Main End -->
    <footer_section></footer_section>
    </div>
</template>

<script>

  import { bus } from './app'
  import FooterSection from './../views/partials/footer' 
  import HeaderSection from './../views/partials/header' 
  import helpers from './../mixin/helpers';
  

  export default {
    data() {
      return {
          loadingContent: false,
      }
    },
    mixins: [helpers],
    components:{
      'footer_section': FooterSection,
      'header_section': HeaderSection,
    }
  }
</script>