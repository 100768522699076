<template>
  <div>
    <Select2
      name="category"
      id="event_category"
      v-model="event_category"
      placeholder="Select One"
      :options="eventCategory"
      :settings="{placeholder:'Select One',class:'floating-label-field form-control floating-label-field--s3 form-control',selectOnClose: false,allowClear: true,closeOnSelect: true}"
    />
  </div>
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";
import Select2 from "v-select2-component";

export default {
  props: ["category"],
  mixins: [helpers],
  watch: {
      event_category: function (newVal, oldVal) {
          var vm = this;
          vm.$store.dispatch('getEventSubCategoryAction',newVal);
      }
  },
  updated() {
    if (this.event_category) {
      // this.getStateCities();
      this.$store.dispatch('getEventSubCategoryAction',this.category);
      
    }
  },
  created() {
    this.event_category = this.category;

  },
  computed: {
    eventCategory() {
      return this.$store.getters.eventCategory;
    },
    
  },
  components: {
    Select2
  },
  data: function() {
    return {
      event_category: ""
    };
  },
  methods: {
    
  }
};
</script>
