<template>
  <header>
    <nav class="navbar navbar-default navbar-fixed-top" :class="headerClass">
      <div class="container-fluid">
        <div class="navbar-header">
          <router-link :to="{ name: 'home' }" class="navbar-brand logo-tab">
            <img
              src="https://d2cv1d7fub3ij0.cloudfront.net/system-images/logo.png"
              alt="VenueGPS"
            />
          </router-link>
          <mobile_location_picker></mobile_location_picker>

          <!-- Mobile View for Top Logos -->
          <!-- <a @click="isMobileSearch = true"  href="https://play.google.com/store/apps/details?id=com.thunders.venuegps" target="_blank">  
          <img class="hidden-md hidden-sm hidden-lg search-mobile-icon mobile-google-style" style="" :src="'/google-play-badge-logo-svgrepo-com.svg' | assetPath" alt="" />
          </a>

          <a @click="isMobileSearch = true"  href="https://apps.apple.com/us/app/venuegps/id1613059136  " target="_blank">  
          <img class="hidden-md hidden-sm hidden-lg search-mobile-icon mobile-apple-style" style="" :src="'/download-on-the-app-store-apple-logo-svgrepo-com.svg' | assetPath" alt="" />
          </a> -->
          <!-- end of mobile view for top logos -->

          <!-- <a @click.prevent="openMobileSearch" href="#">
            <img
              class="d-md-none search-mobile-icon"
              :src="'/mobile-search.svg' | assetPath"
              alt="Search"
            />
          </a>
          <div
            class="d-md-none search-dropdown-menu"
            :class="{ 'mobile-search-enable': isMobileSearch }"
          >
            <mobile_auto_complete v-model="selection"></mobile_auto_complete>
          </div> -->


          <a @click="openMobileSearch" href="#">
            <img
              class="hidden-md hidden-sm hidden-lg search-mobile-icon"
              :src="'/mobile-search.svg' | assetPath"
              alt="Search"
            />
          </a>
          <div
            class="hidden-md mobile-top-search hidden-lg search-dropdown-menu"
            :class="{ 'mobile-search-enable': isMobileSearch }"
          >
            <mobile_auto_complete v-model="selection"></mobile_auto_complete>
          </div>


          <!-- <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
          </button>-->

          <button
            type="button"
            id="menu-toggle"
            @click="showMobileMenu = !showMobileMenu"
            class="navbar-toggle visible-xs visible-sm"
          >
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
        </div>
        <div class="collapse navbar-collapse" id="myNavbar">
          <div class="navbar-left hide-tab">
            <ul class="nav navbar-nav left-menu">
              <location_picker></location_picker>
              <li class="hidden-xs search-wrapper-md">
                <autocomplete v-model="selection"></autocomplete>
              </li>
            </ul>
          </div>
          <!-- <a target="_blank">
            <img class="google-image" style=" margin-left: -55px; margin-top: -26px; height: 120px; width: 344px;" :src="'/google-play-badge-logo-svgrepo-com.svg' | assetPath" alt="" />
          </a>

          <a target="_blank">
            <img class="apple-image" style="height: 120px; width: 344px; margin-top: -28px; margin-left: -208px;" :src="'/download-on-the-app-store-apple-logo-svgrepo-com.svg' | assetPath" alt="" />
            </a>
             -->
           <!-- <img class="google-image" style=" margin-left: 15px; margin-top: -1px; height: 60px; width: 174px;" :src="'/google_play.png' | assetPath" alt="" /> -->


            <!-- <div class="apple-element">
                <img class="apple-image" :src="'/apple.png' | assetPath" alt="" />
            </div> -->


          <ul
            class="nav navbar-nav navbar-right hidden-sm hidden-md hidden-xs user-menu"
          >
            <li>
              <router-link :to="{ name: 'home' }">Home</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'directory' }">Contacts</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'mapview' }">Map View</router-link>
            </li>
            <li v-if="!isAuth()" class="navSiginWrap">
              <!-- <a href="#" @click="loginModal()" id="login-btn">Sign In</a> -->
              <span @click="loginModal()" id="login-btn" class="navSiginLink">
                Sign In
              </span>
            </li>

            <template v-if="isAuth() && isAdminsAccounts()">
              <!-- <li><a href="{{ route('admin.event.create') }}">Create Event</a></li> -->
              <li class="desktop-hide">
                <a :href="url('admin/dashboard')">Dashboard</a>
              </li>
              <li class="">
                <a
                  href="#"
                  class="dropdown-toggle user"
                  data-toggle="dropdown"
                  aria-expanded="true"
                >
                  <img
                    class="user-image"
                    :src="'/user/admin2.svg' | assetPath"
                    alt="User"
                  />
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a :href="url('admin/dashboard')">Dashboard</a>
                  </li>
                  <li @click="logoutAccount()">
                    <a href="javascript:void(0);">Logout</a>
                  </li>
                </ul>
              </li>
            </template>

            <template v-if="isAuth() && isClientBusinessAuth()">
              <li>
                <a :href="url('admin/business/client/event/create')"
                  >Create Event</a
                >
              </li>
              <li class="desktop-hide">
                <a :href="url('admin/business/client/account')">My Account</a>
              </li>
              <li class="">
                <a
                  href="#"
                  class="dropdown-toggle user"
                  data-toggle="dropdown"
                  aria-expanded="true"
                >
                  <img
                    class="user-image"
                    :src="'user/admin2.svg' | assetPath"
                    alt="User"
                  />
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a :href="url('admin/business/client/account')"
                      >Business Account</a
                    >
                  </li>
                  <li @click="logoutAccount()">
                    <a href="javascript:void(0);">Logout</a>
                  </li>
                </ul>
              </li>
            </template>
            <template v-if="isAuth() && isPublicUserAuth()">
              <li class="">
                <a
                  href="#"
                  class="dropdown-toggle user"
                  data-toggle="dropdown"
                  aria-expanded="true"
                >
                  <img
                    class="user-image"
                    :src="profileImageUrl()"
                    alt="Profile"
                  />
                </a>
                <ul class="dropdown-menu newClass userDBOptionWrapper">
                  <li class="user-info">
                    <div class="user-img">
                      <img :src="profileImageUrl()" alt="Profile" />
                    </div>
                    <div class="user-name">
                      <p>Welcome</p>
                      <p>
                        <span>{{ limitStr(userEmail(), 20) }}</span>
                      </p>
                    </div>
                  </li>
                  <li class="divider"></li>
                  <li>
                    <router-link :to="{ name: 'my_events' }"
                      ><i class="fa fa-star"></i> My Events</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'my_venues' }"
                      ><i class="fa fa-star"></i> My Accounts</router-link
                    >
                  </li>
                  <li>
                        <router-link :to="{ name: 'user_notification_settings' }"><i class="fa fa-bell"></i> Notifications</router-link>
                      </li>
                  <!-- <li class="dropdown setting-menu"> -->
                    <!-- <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-cog"></i> Settings <span class="caret"></span>
                    </a> -->
                    <!-- <ul class="dropdown-menu"> -->
                      <li>
                        <router-link :to="{ name: 'user_prefrences' }"><i class="fa fa-bookmark"></i> Prefrences</router-link>
                      </li>

                      <li> <router-link :to="{ name: 'all_cards' }"><i class="fa fa-list"></i> Subscription</router-link></li>

                      <!-- <li> <router-link :to="{ name: 'user_profile' }"><i class="fa fa-user"></i> My Profile</router-link></li> -->


                      <!-- <li><a href="#"><i class="fa fa-comment"></i> Submit Feedback</a></li> -->
                      <li>
                        <router-link :to="{ name: 'user_settings' }"><i class="fa fa-cog"></i> Settings </router-link></li>


                    <!-- </ul> -->
                  <!-- </li> -->
                  <li class="divider"></li>
                  <li>
                    <a href="javascript:void(0);" @click="logoutAccount()"
                      ><i class="fa fa-sign-out"></i> Logout</a
                    >
                  </li>
                </ul>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </nav>

    <user_mobile_menu :showMobileMenu="showMobileMenu"></user_mobile_menu>
  </header>
</template>

<script>
import { bus } from "./../../app";
import helpers from "./../../mixin/helpers";
import Autocomplete from "./../../components/global/all-search.vue";
import MobileAutocomplete from "./../../components/global/mobile-auto-complete";
import LocationPicker from "./../../components/global/location-picker.vue";
import MobileLocationPicker from "./../../components/global/mobile-location-picker.vue";

// Get User Mobile menues
import User_Mobile_Menu from "./user-mobile-menu";

export default {    
  created() {
    var vm = this;
    vm.headerClass = vm.$store.state.headerClass
      ? vm.$store.state.headerClass
      : vm.$session.get("headerClass");
    bus.$on("closeTopSearch", function () {
      vm.isMobileSearch = false;
      vm.value = "";
    });
  },
  data() {
    return {
      isMobileSearch: false,
      showMobileMenu: false,
      headerClass: "",
      suggestions: [],
      value: "",
      selection: "",
    };
  },
  components: {
    autocomplete: Autocomplete,
    mobile_auto_complete: MobileAutocomplete,
    location_picker: LocationPicker,
    mobile_location_picker: MobileLocationPicker,
    user_mobile_menu: User_Mobile_Menu,
  },
  mixins: [helpers],
  watch: {
  isMobileSearch(newVal) {
    console.log("isMobileSearch changed:", newVal);
  }
},

  methods: {
    openMobileSearch() {
      this.isMobileSearch = true;
      // this.selection = true
      console.log("Mobile Search Click", this.isMobileSearch)

      console.log(this.selection)
    },
    updateAutoComplete() {
      this.selection;
      console.log("Auto-complete Selection:", this.selection);

    },
  },
};
</script>

<style scoped>
@media (min-width: 1450px) and (max-width: 1600px) {

    .google-image {
        margin-left: 15px !important;
        margin-top: -29px !important;
        height: 122px !important;
        width: 102px !important;
    }

    .apple-image {
      height: 122px !important;
      width: 102px !important;
      margin-top: -29px !important;
      margin-left: 9px !important;
    }
}


@media (min-width: 1323px) and (max-width: 1450px) {
    .apple-image {
      /* height: 102px !important; */
      width: 70px !important;
      margin-top: -29px !important;
      margin-left: 2px !important;
    }

    .google-image {
        margin-left: 15px !important;
        margin-top: -29px !important;
        /* height: 102px !important; */
        width: 70px !important;
    }
}

@media (min-width: 1200px) and (max-width: 1323px) {
    .apple-image {
      /* height: 102px !important; */
      width: 45px !important;
        margin-top: -29px !important;
        margin-left: 0px !important;
    
    }

    .google-image {
        margin-left: 0px !important;
        margin-top: -29px !important;
        /* height: 102px !important; */
        width: 42px !important;
    }
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .apple-image {
      height: 64px !important;
        width: 56px !important;
        margin-top: -4px !important;
        margin-left: 8px !important;
    
    }

    .google-image {
      margin-left: 161px !important;
        margin-top: -4px !important;
        height: 78px !important;
        width: 59px !important;
    }
}


/* Media query for mobile devices */
@media (max-width: 767px) {
  .apple-image {
      /* height: 31px !important; */
    width: 40px !important;
    margin-top: 6px !important;
    }

    .google-image{
      margin-left: 15px !important;
    margin-top: 7px !important;
    /* height: 48px !important; */
    width: 40px !important;
    
    }

}

.mobile-google-style{
  margin-right: 35px;
    margin-top: -20px;
    /* height: 37px; */
    width: 36px;
  }
.mobile-apple-style{
  margin-right: 35px;
    margin-top: -1px;
    width: 36px;
  
  }

</style>