<template>
    <section class="event-gallery vgps-event-detail-section-wrapper" id="gallery">
        <div class="containers">
            <div class="row">
                <div class="col-md-12 vgps-event-detail-section-title">
                    <h2>Venue Gallery</h2>
                </div>
            </div>
        </div>
        <div class="containers">
            <div class="owl-carousel ev vgps-event-gallery-carousel">
                <div v-for="item in event.venue[0].panaromic_images" class="gallery-images">
                    <img :src="item.image" alt="Gallery">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";


export default {
  data() {
    return {
      //loadingContent: false,
    };
  },
  props: ["event"],
  mixins: [helpers],
  mounted() {
      
      $(".owl-carousel.ev").owlCarousel({
        stagePadding: 200,
        loop: true,
        margin: 32,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: true,
                stagePadding: 50,
                margin: 15
            },
            600: {
                items: 1,
                nav: true,
                stagePadding: 50,
                margin: 15
            },
            1000: {
                items: 1,
                nav: true
            }
        }
    });

  },
  created() {
    
    

  },
  components: {
    
  },
  methods: {
    
  },
  filters: {
    
  }
};
</script>
