<template>
    <section class="event-faq vgps-event-detail-section-wrapper" id="faq">
            <div class="containers">
                <div class="row">
                    <div class="col-md-12 vgps-event-detail-section-title">
                        <h2>FAQ's</h2>
                    </div>
                </div>
                <div class="vgps-event-faqs-wrapper">
                    <div class="panel-group" id="accordion-events-faq" role="tablist" aria-multiselectable="true">
                        <!-- @foreach($eventDetail->faqs as $faq) -->
                            <div v-for="faq in event.faqs" class="panel panel-default">
                                <div class="panel-heading" role="tab" :id="faq.id | eleIDPrefix">
                                    <h4 class="panel-title">
                                        <a role="button" data-toggle="collapse"
                                           data-parent="#accordion-events-faq" :href="faq.id | eleIDPrefix"
                                           aria-expanded="false" aria-controls="collapseOne">
                                            {{ faq.question }}
                                        </a>
                                    </h4>
                                </div>
                                <div :id="faq.id | devIDPrefix " class="panel-collapse collapse" role="tabpanel"
                                     aria-labelledby="headingOne">
                                    <div class="panel-body">
                                        <p>{{ faq.answer }}</p>
                                    </div>
                                </div>
                            </div>
                        <!-- @endforeach -->
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";


export default {
  data() {
    return {
      
    };
  },
  props: ["event"],
  mixins: [helpers],
  created() {

  },
  components: {

  },
  computed:{
      
  },
  methods: {
    
  },
  filters: {
    eleIDPrefix : function (id){
        return  '#faq-div-'+id
    },
    devIDPrefix : function (id){
        return  'faq-div-'+id
    }
  }
};
</script>
