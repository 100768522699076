<template>
  <div>
    <!-- Main Start -->
    <header_section></header_section>
    <main class="top-inner-pages covid19PageWrapper">
      <!-- <section class="headerSectionWrapper">
        <div class="headerBGImage">
          <img :src="'banners/vgps-min.png' | assetPath" alt="header Image" />
        </div>
      </section> -->
      <!-- Dashboard Start -->
      <section class="dashboard vgps_contact_support_wrapper">
        <div class="container">
          <div class="row">
            <!-- <div class="col-md-12 vgps_page_header_wrapper">
              
            </div>-->
            <div class="col-md-12 vgps_privacy_content_wrapper">
              <h3>1. How is the VenueGPS App contributing to COVID-19 response?</h3>
              <p>
                COVID-19 is a global respiratory infection which exposed the fragility of mankind’s mental and
                emotional stability. World leaders failed to combine scientific forces to quell fears resulting in a
                wide-ranging scale of trepidation not seen since the Flu Pandemic of 1968. Entertainment has
                unprecedented crossover appeal creating the segue for the creation of the evolution of
                VenueGPS. Our App developers merged over 50 Apps, then tastefully embedded the cultures
                of over 100 nationalities, to pioneer what is sure to be the #1 Application ever
                produced! VenueGPS is literally in a world of its own! VenueGPS’ target audience is all 6.9
                billion people on this planet. For the last 3 years, VenueGPS researched and developed a
                timeline of entertainment events in every zip code in the country. Furthermore, with the 2022
                goal of worldwide establishment, we included international favorites such as London and Dubai
                as we place our stamp around the globe. VenueGPS will be shifting sovereignty of the night
                from the party promotors and club owners to the those who truly deserve to have the power of
                choice, The People. VenueGPS coded GPS triangulation commands, calculated social spending
                habits, and created a seductive marketing nightclub campaign which will maximize their
                participation and our entertainment footprint. There are 65,000 nightclubs and bars, 20,000
                celebrity/influencers, and 5,000 annual events in one website. Patrons can review, follow,
                purchase tickets, receive GPS navigation with commands, and receive e-reminder alerts on the
                mobile devices by subscribing to one App.
              </p>
              <p>
                The VenueGPS Application is a 24-hour digital circulating jumbotron use to provide
                entertainment news, public service announcements, major sporting events, or any information,
                in this case COVID-19, of high importance or public interest. The e-read only format giving
                viewers the autonomy to interpret the news independently. VenueGPS’ primary mission is
                promoting the 25,000 dancefloors, 5,000 Amphitheaters/stages, and 600 casinos throughout
                America. Our secondary mission is customers amenities of genres, security, parking options
                and food & kitchen options of call in reservations, take out for night life partiers. COVID-19
                triggered a cataclysmic effect that instantly shut down 95 percent of entertainment industry
                affecting 50 million households. VenueGPS has the unique flexibility to reprioritize from party
                promotors to a health and nutrition support system. VenueGPS will assist any existing or startup food delivery service such as Uber Eats and Doordash. We will also advertise menu items
                and prices for local corner markets, mom & pop stores, and curb-side grills to show our support
                during and after a natural disaster or event causing social disorder. Furthermore, the
                exclusivity of our digital public service announcement capabilities, our domain is literally an
                unrestricted mobile command post. The noteworthy ability to simultaneously send mass text messages and mobile alerts from a recognizable source provides our customers a sense of
                normalcy and gives VenueGPS tremendous staying power. VenueGPS can be considered to be
                the world’s first intercontinental disaster headquarters due to our flexibility and ability to
                immediately communicate to all cultures worldwide.
              </p>
              <h3>2. How will the VenueGPS App respond to the COVID-19 crisis?</h3>
              <p>
                VenueGPS was envisioned, created, developed, and engineered by CEO and Retired Navy Chief
                Petty Officer David “Conn” Morey. COVID-19 created a 13 percent growth in business
                compared to the same period in recent years. VenueGPS will advertise our marketing
                capabilities to all businesses for the interests of public safety during COVID-19 and future times
                of potential cultural unrest. VenueGPS goals are to educate and provide a means of advertising
                vendor services to offset financial losses incurred due to the government’s response to COVID19. In times of crisis, VenueGPS will provide business management teams a digitized peer-topeer text messaging system to educate their clientele resulting in a sense of confidence and
                trust. Fifty-nine percent of Americans have expressed their desire to return to PRE-COVID-19
                entertainment and leisure activity norms. VenueGPS’ trifecta of text messaging, the Trending
                Jumbotron, and (GPAN- S) Global Promotion Alert Notification-System transmission capabilities
                gives our website unique communication advantages over all other entertainment and social
                media outlets. As Plan-A slowly supersedes Plan-B from food to entertainment, VenueGPS will
                construct a rendezvous point for the exchange of information for vendors and customers.
                VenueGPS will be the rescheduling headquarters for the 50,000 plus events that were
                postponed or canceled because of COVID-19. Industry experts predict a progressive reopening
                of bars and nightclubs with 10 percent of them remaining closed.
              </p>
              <p>
                Thanks to leading edge technology, VenueGPS will serve as a virtual lighthouse as the 58,000
                nightclubs and bars slowly reopen. Music Festivals, Comic Cons, Carnivals and State Fairs will
                have greater advertising power to update local and regional dates, operating hours, security
                concerns, and admissions costs. VenueGPS will be the banner for any national sanitation
                statutes for the 6,500 Arenas, Stadiums, Coliseums and Fairgrounds as performance dates are
                reassigned. The onset of COVID-19 and bizarre human responses provided comedians a
                lifetime of material and our website will be the recasting epicenter of dates and stage times.
                On March 13th the U.S. Department of Human Services advised the discontinuation of public
                gathering causing immediate postponements or cancellations for the thousands of musicals,
                celebrity appearances, and entertainment activities. The VenueGPS scheduling management team is currently tracking all postponed events since March 13th to maximize the distribution of
                entertainment updates to the 330 million Americans and the 6.9 billion people worldwide.
              </p>
              <h3>3. What impact do you envision VenueGPS APP will have? Who is it for?</h3>
              <p>
                VenueGPS is a mobile event management powerhouse that supersedes any entertainment
                resource ever produced in the history of mankind. Out with the 1987 practice of hand
                delivered COVID-19 sweat gland pathogen polluted hand flyers; in with a 100 percent
                <span
                  class="textColorChange"
                >
                  ‘GO
                  GREEN’
                </span> buffet of celebrating life. We are the Disneyland of amusement parks;
                or better stated, we are the VenueGPS of Entertainment.
              </p>
              <p>
                <b>VenueGPS was created for the everyone! That simple.</b> Venue managers, nightclub owners,
                and concert coordinators will have direct access to their target audience via the uploading of
                electronically generated flyers, or e-flyers. E-flyers provides the researcher all the knowledge
                factors necessary to make an informed decision yet saves the venue 95 percent of their annual
                advertising budget. VenueGPS charges and average of $7.99 to allow capitalism to flourish by
                ensuring competition resulting in superb customer service and spurring creativity. Nightclubs
                can furnish clients with amenities such as bottle service/VIP availability, average are of patrons,
                advertise for special celebrations, and security concerns/parking on one flyer uploaded to one
                website. VenueGPS’ staff meticulously ensures the daily exchange of over 100k e-flyers.
              </p>
              <p>
                VenueGPS empowers normal citizens with an ever-expanding cyber-warehouse of
                entertainment options through their mobile device. VenueGPS subscribers can follow their
                favorite performer, athlete, singer, actor, comedian, or social media influencer. Clients can
                search for their genre in seven different drop-down box or hashtag keyword searches.
                Additionally, patrons can search by city, zip code, date of event, business name, sup-type, event
                name such as “Essence Fest”, or music genre. A floating acquisition of entertainment
                suggestions based on a preferred GPS coordinates (example; everything within 20 miles of
                current location) and the customization of genres gives travelers unprecedented independence
                and autonomy. Patrons will receive audible and text like alerts when their mobile device enters
                the GPS range of one or several of the 72 genres offered by VenueGPS, such as Live Banjo Music
                or Battle Rap venues.
              </p>
              <p>
                VenueGPS’ is a 24-hour virtual news-entertainment jumbotron which can function as a PSA
                notification source. We deliver long short- and long-range events to customers we review the
                date, to create algorithms and statistics for future predictions. VenueGPS proudly supports LGBTQ social clubs and events and although we are black owned, we target every culture, color,
                creed, ethnicity known to man. Welcome to VenueGPS!
              </p>
              <h3>4. Welcome to Retired Digital</h3>
              <p>
                Conn is the CEO of Retired Digital, an e-marketing phenomenon best known for providing
                online businesses with a www-dot digital face-lift dot overhaul to any website, application,
                business plan or custom research needed, regardless of industry. We specialize in content,
                graphics, logo, and creativity to successfully improve brand appeal and namesake. Five out of
                the six masterminds who make Retired Digital a success, are retired United States Navy Chief
                Petty Officers, hence the name Retired Digital.
              </p>
            </div>
          </div>
        </div>
      </section>
      <!-- Dashboard End -->
    </main>
    <!-- Main End -->
    <footer_section></footer_section>
  </div>
</template>

<script>
import { bus } from "./app";
import FooterSection from "./../views/partials/footer";
import HeaderSection from "./../views/partials/header";
import helpers from "./../mixin/helpers";

export default {
  data() {
    return {
      loadingContent: false,
    };
  },
  mixins: [helpers],
  components: {
    footer_section: FooterSection,
    header_section: HeaderSection,
  },
};
</script>