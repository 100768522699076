<template>
  <div class="business-filter">
    <div class="business-filter">
      <ul
        class="business-sub-category list-group list-group-flush sPSubFilterWrap"
      >
        <li
          v-for="(item,theme_index) in getFilteredThemes"
          :key="item.theme"
          class="list-group-item"
        >
          <div class="form-group vgps-custom-checkbox-wrapper">
            <label
              class="fancyRadioWrapper business-main-cat sPSubFilterHeader collapsed"
              data-toggle="collapse"
              :data-target="'#menu-div-' + theme_index"
              aria-expanded="false"
              :aria-controls="'#menu-div-' + theme_index"
              :for="item.theme"
            >
              {{ item | ItemTotalCount }}
              <span class="sPFilterCount">
                {{ item | ItemTotalCountValue }}
              </span>
              <span v-if="SubItemFilteredCounts(item) > 0" class="SubFolderSelectCount">
                <i class="fas fa-filter"></i>
                <span class="countIndex">
                  {{ SubItemFilteredCounts(item) }}
                </span>
              </span>

              <i class="fas fa-angle-down subFilterArrow"></i>
            </label>
          </div>
          <ul
            v-if="item.values.length > 0"
            :id="'menu-div-' + theme_index"
            class="business-sub-category collapse list-group list-group-flush"
          >
            <li
              v-for="subitems in item.values.slice(0, 5)"
              :key="subitems.name"
              class="list-group-item"
            >
              <div class="form-group vgps-custom-checkbox-wrapper">
                <input
                  type="checkbox"
                  @change="advanceFilter"
                  v-model="search_event_theme"
                  name="search_event_theme"
                  :value="subitems.name"
                  :id="subitems.name"
                />
                <label :for="subitems.name">{{ subitems.name }}</label>
                <span class="item-count badge">{{ subitems.count }}</span>
              </div>
            </li>
            <li
              v-if="item.values.length != 0 && item.values.length > 5"
              class="list-group-item"
            >
              <a
                class="vgp-primary-link"
                href="javascript:void(0)"
                @click="themeMoreOptions(item.theme)"
                data-toggle="modal"
                data-target="#theme-more-option-model"
              >
                See all
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { bus } from "../../app";
import Select2 from "v-select2-component";
import helpers from "../../mixin/helpers";



// import the plugin
import { mapFields } from "vuex-map-fields";

export default {
  components: {
    Select2,
  },
  data() {
    return {
      value: "",
      dateRange: "",
      venue_type: [],
      sub_categories: [],
      isDateFormat: false,
      isFilterSelected: false,
      business_type: "",
      dropDownOptions: [],
      subBusinessTypes: [],
    };
  },
  computed: {
    tierEventThemes() {
      return this.$store.getters.tierEventThemes;
    },
    
    getFilteredThemes() {
      return this.$store.getters.getFilteredThemes;
    },
    ...mapFields(["search_event_theme", "search_category", "moreThemeOptions"]),
  },
  created: function () {
    var vm = this;
  },
  mixins: [helpers],
  methods: {
    SubItemFilteredCounts: function (items) {
      var vm = this;
      var count = 0;
      items.values.map(function (obj, index) {
        if(vm.search_event_theme.includes(obj.name)){
          count = count + 1;
        } 
      });
      return count;
    },
    themeMoreOptions: function (theme) {
      this.moreThemeOptions = [];
      var data = this.getFilteredThemes.filter(function (obj) {
        if (obj.theme == theme) {
          return true;
        }
      });
      this.moreThemeOptions = data.length > 0 ? data[0] : [];
      this.$forceUpdate();
      bus.$emit("update_hash_theme_option",this.moreThemeOptions);
    },
    advanceFilter: function () {
      var vm = this;
      // vm.search_main_category =
      bus.$emit("search_filter_emit");
    },
  },
  filters: {
    removeSpecialCharacter: function (str) {
      return str.replace(/[^a-zA-Z ]/g, "")
    },
    assetPath: function (img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
    ItemTotalCount: function (item) {
      // var count = 0;
      // item.values.map(function (obj, index) {
      //   count = obj.count + count;
      // });

      // if (count > 0) {
      //   return item.theme + " " + "  (" + count + ")";
      // }
      return item.theme;
    },

    ItemTotalCountValue: function (item) {
      var count = 0;
      item.values.map(function (obj, index) {
        count = obj.count + count;
      });

      if (count > 0) {
        return "(" + count + ")";
      }
      // return item.theme;
    },




  },
};
</script>
