<template>
  <div class="row my-venue-following-list">
    <div class="col-md-12">
      <div v-if="loadingContent" class="content-loader2">
        <!-- <img :src="'icons/vgps-loader.gif' | assetPath" /> -->
        <content-loader
          width="1490"
          height="475"
          :speed="1"
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="0" y="20" rx="8" ry="8" width="350" height="350" />
          <rect x="0" y="390" rx="0" ry="0" width="200" height="18" />
          <rect x="0" y="420" rx="0" ry="0" width="120" height="20" />

          <rect x="380" y="20" rx="8" ry="8" width="350" height="350" />
          <rect x="380" y="390" rx="0" ry="0" width="200" height="18" />
          <rect x="380" y="420" rx="0" ry="0" width="120" height="20" />

          <rect x="760" y="20" rx="8" ry="8" width="350" height="350" />
          <rect x="760" y="390" rx="0" ry="0" width="200" height="18" />
          <rect x="760" y="420" rx="0" ry="0" width="120" height="20" />

          <rect x="1140" y="20" rx="8" ry="8" width="350" height="350" />
          <rect x="1140" y="390" rx="0" ry="0" width="200" height="18" />
          <rect x="1140" y="420" rx="0" ry="0" width="120" height="20" />
        </content-loader>
      </div>

      <div v-else class="loaded-content">
        <div v-if="venues" class="row">
          <div
            v-for="(venue, key) in venues"
            class="col-lg-3 col-md-3 col-sm-4 col-xs-6 ceo"
            :key="key"
          >
            <div class="content">
              <router-link
                class="venue-image"
                :class="getBusinessStatus(venue)"
                :to="{ name: 'venue', params: { id: venue.id } }"
              >
                <div
                  class="pic event-thumb-img"
                  v-lazy:background-image="venue.thumbnail_urls.thumb_296"
                >
                  <div
                    v-if="venue.venue_closing_reason.title != 'Normal'"
                    class="reason-info"
                  >
                    <span class="info-imgae" :title="getVenueAidTitle(venue)">
                      
                      {{ venue.venue_closing_reason.severity.level | checkNullSeverity }}
                      <img :src="'info.svg' | assetPath" />
                    </span>
                  </div>
                </div>
                <div
                  v-if="
                    venue.claim_color == 'red' || venue.claim_color == 'blue'
                  "
                  class="claim-business-flag"
                >
                  <a
                    href="javascript:void(null);"
                    data-toggle="modal"
                    @click="
                      assignVenueID(venue.id, venue.title, 'directory', key)
                    "
                    data-target="#claimBusinessModal"
                    >Claim Business Now</a
                  >
                </div>
                <div
                  v-if="venue.claim_color == 'white'"
                  class="claim-business-flag requested"
                >
                  <a href="javascript:void(null);">Requested for Claim</a>
                </div>
              </router-link>
              <div class="bottom-decription">
                <h6>
                  <router-link
                    :to="{ name: 'venue', params: { id: venue.id } }"
                    >{{ limitStr(venue.title, 28) }}</router-link
                  >
                </h6>
                <p>{{ venue.address_details.full_address }}</p>
                <div class="btn-block mobile-view-margin" >
                  <span class="hashtags-div">
                    <a
                      @click="searchHashTag('#' + venue.type)"
                      href="javascript:void();"
                      class="event-hashtags"
                      >#{{ venue.type }}</a
                    ></span
                  >
                </div>

                <!--Following Event Component -->
                <follow_venue
                  v-bind:venues="venues"
                  v-bind:event="venue"
                  v-bind:viewType="viewType"
                  v-bind:venueid="venue.id"
                  v-bind:index="key"
                ></follow_venue>


                
                <div class="dropup">
                  <button
                    class="btn btn-default vue-social-share"
                    id="social-network-share"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-share-alt"></i>
                    <span>Share</span>
                  </button>
                  <div
                    class="dropdown-menu vue-social-share-items"
                    aria-labelledby="dropdownMenu2"
                  >
                    <li class="dropdown-item">
                      <ShareNetwork
                        network="facebook"
                        :url="socialShareLink('#/event/' + venue.id)"
                        :title="venue.title"
                        :description="venue.title"
                        :quote="venue.title"
                        hashtags="venue,gps"
                      >
                        <i class="fab fah fa-lg fa-facebook"></i>
                        <span>Share on Facebook</span>
                      </ShareNetwork>
                    </li>
                    <li class="dropdown-item">
                      <ShareNetwork
                        network="twitter"
                        :url="socialShareLink('#/event/' + venue.id)"
                        :title="venue.title"
                        :description="venue.title"
                        :quote="venue.title"
                        hashtags="venue,gps"
                      >
                        <i class="fab fah fa-lg fa-twitter"></i>
                        <span>Share on Twitter</span>
                      </ShareNetwork>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="loadingMoreContent" class="content-loader">
            <img :src="'icons/vgps-loader.gif' | assetPath" />
          </div>

          <div
            v-if="showLoadMore"
            class="col-md-12 col-smd-12 col-xs-12 clear-row"
          >
            <a @click="loadMore" class="see-more" href="javascript:void(0)"
              >Load More</a
            >
          </div>
        </div>
        <result_not_found v-if="venues.length == 0"></result_not_found>
      </div>
    </div>
  </div>
</template>
<script>
import { bus } from "../../app";
import FollowVenue from "../global/follow-button.vue";
import ResultNotFound from "./../../views/user/partials/result-not-found";
import helpers from "../../mixin/helpers";

import {
  ContentLoader,
  FacebookLoader
} from "vue-content-loader";

export default {
  data() {
    return {
      viewType: "venueType",
      loadingContent: false,
      loadingMoreContent: false,
      showLoadMore: true,
      venues: [],
      loaderSpeed: 1,
      selectedCategory: "night-life",
    };
  },
  mixins: [helpers],
  created() {
    bus.$on("advanceFilterEvent", (searchValue) => {
      this.getVenues("advanceFilter", searchValue);
    });
  },
  beforeUpdate() {},
  components: {
    ContentLoader,
    FacebookLoader,
    follow_venue: FollowVenue,
    result_not_found: ResultNotFound,
  },
  props: ["filters"],
  mounted() {
    this.getVenues();
    console.log("Component mounted.");
  },
  methods: {
    getVenueAidTitle: function(venue){
        var title = '';
        if(venue.venue_closing_reason.severity.description && venue.venue_closing_reason.title != 'Normal'){
            title = venue.venue_closing_reason.severity.description
            title += venue.venue_closing_reason.duration.time_duration?' | Duration:'+venue.venue_closing_reason.duration.time_duration:'';
        }else {
            title = venue.venue_closing_reason.title;
        }

        return title;
    },
    loadMore: function () {
      this.filters.offset = this.filters.offset + this.filters.limit;
      this.getVenues("loadmore");
    },
    getBusinessStatus: function (venue) {
      var str = "";
      if (typeof venue.venue_closing_reason != 'undefined') {
        str = venue.venue_closing_reason.color;
      }
      return str;
    },
    getVenueAidTitle: function (venue) {
      var title = "";
      if (
        venue.venue_closing_reason.severity.description &&
        venue.venue_closing_reason.title != "Normal"
      ) {
        title = venue.venue_closing_reason.severity.description;
        title += venue.venue_closing_reason.duration.time_duration
          ? " | Duration:" + venue.venue_closing_reason.duration.time_duration
          : "";
      } else {
        title = venue.venue_closing_reason.title;
      }

      return title;
    },
    getVenues: function(action, searchingValue) {
      var vm = this;
      if (action == "loadmore") {
        vm.loadingMoreContent = true;
      } else if (action == "advanceFilter") {
        vm.selectedCategory = searchingValue;
        vm.loadingContent = true;
      } else {
        vm.loadingContent = true;
      }
      axios
        .get("data/venue-new/", {
          params: {
            offset: this.filters.offset,
            limit: this.filters.limit,
            keyword: this.filters.search !=''?this.filters.search:'',
            business_type: this.filters.business_type!=''?this.filters.business_type:'',
            type: this.filters.venue_type!=''?this.filters.venue_type:'',
            favourite: "my_venue",
            category: "venue",
          },
          
        })
        .then(function (response) {
          // console.log("Venue Res", response.data.data.content.length)

          if (action == "loadmore") {
            $.each(response.data.data.content, function (index, obj) {
              vm.venues.push(obj);
            });
            vm.showLoadMore =
              response.data.data.content.length < vm.filters.limit
                ? false
                : true;
            vm.loadingMoreContent = false;
          } else {
            if (action == "updateLocation" || action == "search_event_title") {
              document.getElementById("filter-id").scrollIntoView({
                block: "end",
                behavior: "smooth",
              });
            }
            vm.venues = response.data.data.content;
            vm.showLoadMore =
              response.data.data.content.length < vm.filters.limit
                ? false
                : true;
            vm.loadingContent = false;
          }
          shareButtonEnable(response.data.data.content, "venue-detail");
        })
        .catch(function (errors) {
          vm.loadingContent = false;
          vm.loadingMoreContent = false;
        });
    },
  },
  filters: {
    checkNullSeverity: function(severity) {
      var str = "V-AID";
      if (severity != "" && severity != null) {
        str += ": "+severity;
      }
      return str;
    },
    assetPath: function(img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
    url: function (path) {
      var path = window.axios.defaults.baseURL + "/" + path;
      return path;
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 767px){
  .mobile-view-margin{
  margin-top: -22px;
  }

}

</style>