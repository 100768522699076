<template>
  <div>
    <!-- Main Start -->
    <header_section></header_section>
    <main class="top-inner-pages sneakPreviewWrapper">
      <!-- <section class="headerSectionWrapper">
        <div class="headerBGImage">
          <img :src="'banners/vgps-min.png' | assetPath" alt="header Image" />
        </div>
      </section> -->
      <!-- Dashboard Start -->
      <section class="dashboard vgps_contact_support_wrapper">
        <div class="container">
          <!-- App Page -->
          <div id="venuegps" class="text-left pt">
            <div class="app-intro-header">
              <div class="row verticle-align-middle">
                <div class="col-sm-5 col-sm-offset-1">
                  <div class="app-intro">
                    <h2>
                      We Go
                      <br />Where You are
                    </h2>
                    <p>An inclusive, social website and app connecting consumer to the endless options of entertainment, with the goal of granting people the definitive answer on "where to be" in any given city</p>
                    <!--                                            <a href="#" class="blue-btn">Join us Today</a>-->
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="app-intro-image">
                    <img src="https://d2cv1d7fub3ij0.cloudfront.net/catalog/laptop.png" />
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white-container">
              <div class="headline wow fadeInLeft">
                <h1>VenueGPS</h1>
                <p>is a multifunctional tool that promises a simplified experience. By combining social media, trend mapping and current event information, consumers can search from the hottest nightlife and exclusive events to the biggest celebrities in one place</p>
              </div>
              <div class="header-icons">
                <ul>
                  <li>
                    <a href="#">
                      <img src="https://d2cv1d7fub3ij0.cloudfront.net/catalog/filter.svg" />
                      <p>Specific filtering</p>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="https://d2cv1d7fub3ij0.cloudfront.net/catalog/pin.svg" />
                      <p>Trend mapping</p>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="https://d2cv1d7fub3ij0.cloudfront.net/catalog/tv.svg" />
                      <p>Live Broadcast</p>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="https://d2cv1d7fub3ij0.cloudfront.net/catalog/fav.svg" />
                      <p>All in one</p>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="app-mobile-image">
                <img src="https://d2cv1d7fub3ij0.cloudfront.net/catalog/app.png" />
              </div>
              <div class="app-footer">
                <h2>Discover</h2>
                <p>Get VenueGPS on Android and iOS (coming soon {{ getYear() }})</p>
                <div class="app-download-links">
                  <a href="#" class="ios">
                    <img src="https://d2cv1d7fub3ij0.cloudfront.net/catalog/ios.png" />
                  </a>
                  <a href="#" class="android">
                    <img src="https://d2cv1d7fub3ij0.cloudfront.net/catalog/play.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- end APP Page -->
        </div>
      </section>
      <!-- Dashboard End -->
    </main>
    <!-- Main End -->
    <footer_section></footer_section>
  </div>
</template>
<script>
import { bus } from "./app";
import FooterSection from "./../views/partials/footer";
import HeaderSection from "./../views/partials/header";
import helpers from "./../mixin/helpers";

export default {
  data() {
    return {
      loadingContent: false,
    };
  },
  mixins: [helpers],
  components: {
    footer_section: FooterSection,
    header_section: HeaderSection,
  },
};
</script>