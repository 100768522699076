<template>
  <div>
    <input
      type="hidden"
      id="start_date"
      name="start_date"
      v-model="start_date"
    />
    <input type="hidden" id="end_date" name="end_date" v-model="end_date" />
    <date-picker
      v-model="dateranges"
      value-type="YYYY-MM-DD"
      format="MM-DD-YYYY"
      input-class="form-control"
      range
      @change="updateValues"
      placeholder="MM-DD-YYYY - MM-DD-YYYY"
      :input-attr="{ name: 'dateranges', id: 'event-search-dateranges' }"
    >
    </date-picker>
  </div>
</template>

<script>
import { bus } from "../../app";

export default {
  props: ["daterangesval", "start_date", "end_date"],
  watch: {},
  created() {
    this.start_date = this.start_date;
    this.end_date = this.end_date;
    this.dateranges = [this.start_date, this.end_date];
    // this.dateranges = this.dateranges.map(item => item.trim());
    console.log("Date range selected value", this.dateranges);
  },
  computed: {
    // dateranges: function () {
    //     debugger
    // }
  },
  data: function () {
    return {
      dateranges: ["2020-02-01", "2020-02-05"],
      // start_date: '',
      // end_date: '',
    };
  },
  methods: {
    updateValues: function (data) {
      this.start_date = data[0];
      this.end_date = data[1];
    },
    selectDataRange: function (event) {
      var vm = this;
    },
  },
};
</script>
