<template>
  <div class="filter-content">
    <div v-if="loadingContent" class="content-loader">
      <content-loader
        width="1490"
        height="475"
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="20" rx="8" ry="8" width="350" height="350" />
        <rect x="0" y="390" rx="0" ry="0" width="200" height="18" />
        <rect x="0" y="420" rx="0" ry="0" width="120" height="20" />

        <rect x="380" y="20" rx="8" ry="8" width="350" height="350" />
        <rect x="380" y="390" rx="0" ry="0" width="200" height="18" />
        <rect x="380" y="420" rx="0" ry="0" width="120" height="20" />

        <rect x="760" y="20" rx="8" ry="8" width="350" height="350" />
        <rect x="760" y="390" rx="0" ry="0" width="200" height="18" />
        <rect x="760" y="420" rx="0" ry="0" width="120" height="20" />

        <rect x="1140" y="20" rx="8" ry="8" width="350" height="350" />
        <rect x="1140" y="390" rx="0" ry="0" width="200" height="18" />
        <rect x="1140" y="420" rx="0" ry="0" width="120" height="20" />
      </content-loader>
    </div>

    <div class="loaded-content" v-animate-css="animate.fadeIn">
      <div v-if="events.length > 0" class="homeListingWraper">
        <template v-for="(event, key) in events">
          <!---Ads Card ------------------------------------------>
          <div v-if="event && event.object_type == 'ads'" :key="key" :class="cardType(key, event.object_typem, 'ads')" class="event-card-wraper">
            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12 ceo adver-card">
              <div class="content">
                <a
                  :href="event.link ? event.link : 'javascript:void(0)'"
                  rel="noopener"
                  target="_blank"
                >
                  <div class="pic" v-lazy:background-image="event.image"></div>
                </a>
                <a
                  v-if="isAdministratorAccount()"
                  :href="adminEditOptionURL('ads', event.id)"
                  target="_blank"
                  class="admin-edit-option"
                >
                  <i class="fa fa-edit"></i>
                </a>

                <div class="bottom-decription">
                  <h6>
                    <a v-if="event.link" target="_blank" :href="event.link">{{
                      event.title
                    }}</a>
                    <a v-else href="javascript:void(0)">{{ event.title }}</a>
                  </h6>

                  <p v-if="event.release_date" class="dates">
                    {{ event.release_date }}
                  </p>
                  <br />
                  <div v-if="event.link" class="hashtag-wrapper">
                    <a :href="event.link" target="_blank" class="visiteSite"
                      ><i class="fa fa-external-link-alt"></i>
                      <span>Visit Site</span></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!---Event Card ------------------------------------------>
          <!-- <div v-if="event && event.object_type == 'event' && event.status_value === 1" :key="key" :class="cardType(key, event.object_type, 'event')" class="event-card-wraper"> -->

            <div :key="key" v-if="event && event.object_type == 'event'" :class="cardType(key, event.object_type, 'event')" class="event-card-wraper">
              <div  class="col-lg-3 col-md-3 col-sm-3 col-xs-6 ceo event-card event-card-mobile-resp-trending">
                <div class="content" :class="event.activation_status_display">
                  <div
                  class="pic event-thumb-img"
                    v-lazy:background-image="event.thumbnail_urls.thumb_296"
                    style="height: 300px;"
                  >

                  <div style="float: right;top:52px; position: relative;right: 4px;width: 38px;">
                    <event_follow
                      v-bind:events="events"
                      v-bind:event="event"
                      v-bind:index="key"
                    ></event_follow>
                  </div>
                 
                    <!-- <router-link
                      v-if="isAuth()"
                      class="flyer-venue-name"
                      :to="{ path: 'event/' + event.event_id }"
                    ></router-link>
                    <a
                      v-else
                      @click="loginModal(event.event_id, '#/event/')"
                      class="flyer-venue-name"
                      :href="url('event-detail/' + event.event_id)"
                      @contextmenu="blockRightClick($event)"
                    ></a> -->





                    <!--Social Share  plugin -->
                    <div class="dropup homeShareButton"       style="position: absolute; bottom: -244px; left: 7%; transform: translateX(-50%);"
                    >
                        <button
                        class="btn btn-default vue-social-share"
                        id="social-network-share"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style="background: #673ab7;border-radius: 12px"
                      >

                      <img style="filter: invert(1);" width="30px" :src="'share.png' | assetPath" :alt="event.title" /><br>

                      </button>

 

                      <div
                        class="dropdown-menu vue-social-share-items"
                        aria-labelledby="dropdownMenu2"
                        style="width: 180px;"
                      >
                        <li class="dropdown-item">
                          <ShareNetwork
                            network="facebook"
                            :url="
                              socialShareLink('event-detail/' + event.event_id)
                            "
                            :title="event.event_title | socialImgPath"
                            description="{event.formatted_start_date+'-'+event.formatted_start_date}"
                          >
                            <i class="fab fah fa-lg fa-facebook"></i>
                            <span>Share on Facebook</span>
                          </ShareNetwork>
                        </li>
                        <li class="dropdown-item">
                          <ShareNetwork
                            network="twitter"
                            :url="
                              socialShareLink('event-detail/' + event.event_id)
                            "
                            :title="event.event_title"
                            description="{event.formatted_start_date+'-'+event.formatted_start_date}"
                          >
                            <i class="fab fah fa-lg fa-twitter"></i>
                            <span>Share on Twitter</span>
                          </ShareNetwork>
                        </li>
                      </div>
                    </div>




                    <div class="seen-title" style="width: 20px;    margin-left: auto;
                    height: 0px;
    margin-right: 48px;
    top: 196px;
    position: relative;">
 
                      <span class="eye"                   >
                        <img :src="'seen.svg' | assetPath" :alt="event.title" /><br>
                        {{ event.total_views }}
                      </span>
                      <span
                        title="Private Location"
                        v-if="event.type && event.type == 'PrivateLocation'"
                        class="time private-label"
                        >Private</span
                      >
                    </div>

                    <!--Venue AID -->
                    <div class="venue-aid">
                      <span class="" data-toggle="tooltip" :title="getVenueAidTitle(event.venue)" v-if="event.venue.closing_reasons.title !='Normal'" 
                      :class="event.venue.closing_reasons.color">{{ event.venue.closing_reasons.severity.level | checkNullSeverity }} <img width="15" :src="'info.svg' | assetPath"></span>
                    </div>


                    <!--like Event Component -->
                      <like_event
                      v-bind:events="events"
                      v-bind:event="event"
                      v-bind:index="key"
                    ></like_event>


                    
                  </div>

                  <div
                    v-if="event.activation_status_display"
                    class="event-status-flag activation-status-flag"
                    :class="[ event.activation_status? 'status-bg-'+event.activation_status:'' ]"
                  >
                    <a  href="#">
                      {{ event.activation_status_display }}
                      <img :src="'info.svg' | assetPath" alt />
                    </a>
                  </div>

                  <a
                    v-if="isAuth() && isAdministratorAccount()"
                    :href="adminEditOptionURL('event', event.event_id)"
                    target="_blank"
                    class="admin-edit-option"
                  >
                    <i class="fa fa-edit"></i>
                  </a>

                  <a
                   v-if="isAuth() && isAdministratorAccount()"
    @click="updateEvent(event.event_id, 'delete', -1, 'deleted')"
   class="admin-edit-option"
    :style="{ 
      opacity: 1, 
      position: 'absolute', 
      top: '10px', 
      left: '54px', 
      backgroundColor: '#eaeaea', 
      padding: '5px', 
      borderRadius: '4px', 
      transition: 'opacity 0.3s ease' 
    }"
  >
    <img 
      :src="'delete-icon.svg' | assetPath" 
      alt="Delete" 
      style="width: 20px; height: 20px;" 
    />
  </a>




                  <div class="bottom-decription">
                    <h6>
                      <router-link
                        v-if="isAuth()"
                        class="logged-in"
                        :to="{ path: 'event/' + event.event_id }"
                        >{{ limitStr(event.event_title, 26) }}</router-link
                      >
                      <a
                        v-else
                        class="login"
                        @click="loginModal(event.event_id, '#/event/')"
                        :href="url('event-detail/' + event.event_id)"
                        @contextmenu="blockRightClick($event)"
                        >{{ limitStr(event.event_title, 26) }}</a
                      >
                    </h6>
                    <p title="Start & End Dates" class="dates">
                      
                      <template>
                        <div>
                          <template v-if="event.type === 'Recurring'">
                            {{ event.start_date }}
                          </template>
                          <template v-else-if="event.start_date === event.end_date">
                            {{ event.start_date }}
                          </template>
                          <template v-else>
                            {{ event.start_date }} - {{ event.end_date }}
                          </template>
                        </div>
                      </template>
                      
                    </p>
                    <p
                      v-if="event.start_time"
                      class="event-time"
                      title="Start Time"
                    >
                      Start Time: {{ event.start_time }}
                    </p>
                    <p>
                      <router-link
                        v-if="isAuth()"
                        class="venue-link"
                        :to="{ name: 'venue', params: { id: event.venue_id } }"
                      >
                        {{ limitStr(event.venue_title, 26) }}
                      </router-link>
                      <a
                        v-else
                        @click="loginModal(event.venue_id, '#/venue/')"
                        class="flyer-venue-name"
                        :title="event.venue_title"
                        href="#"
                        >{{ limitStr(event.venue_title, 26) }}</a
                      >
                    </p>
                    <p :title="event.full_address">
                      {{ limitStr(event.full_address, 26) }}
                    </p>
                    

                    <!-- Tickets -->

                    <div v-if="event && event.object_type !== 'ads' && event.tickets && event.tickets.length > 0">
                    <div class="parallelogram-container">           
                      <!-- Sort and render tickets directly in the v-for loop -->
                    <div v-for="(ticket, index) in event.tickets.slice().sort((a, b) => {
                          const priority = ['Tickets','OrderOnline','FoodMenu', 'Reservation','VIP', 'Suites','Parking','FreeRSVP','Tailgate','Metaverse','Watch','PlaceBet'];
                          return priority.indexOf(a.provider) - priority.indexOf(b.provider);
                        })"
                        :key="index"
                      >
                          <div v-if="ticket.provider_left != null">

                            <div class="parallelogram near_you_right" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                              <p class="ticket_status_text near-you-ticket-text-resp-left">
                                <a :href="ticket.url" target="_blank">  {{ ticket.provider.replace(/([A-Z])/g, ' $1').trim() }}
                                </a>
                              </p>
                            </div>
                          </div>

                          <div v-if="event.tickets.length != 1 && ticket.provider_left != null">

                          <div v-if="ticket.provider_right != null">

                            <div class="parallelogram2 near_you_left" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                              <p class="ticket_status_text2">
                                <a :href="ticket.url" target="_blank">  {{ ticket.provider.replace(/([A-Z])/g, ' $1').trim() }}
                                </a>
                              </p>
                            </div>
                          </div>
                          </div>

                          <div v-else-if="event.tickets.length === 1 && ticket.provider_left == null">  

                            <div v-if="ticket.provider_right != null">
                              <div class="parallelogram3" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                                <p class="ticket_status_text2">
                                  <a :href="ticket.url" target="_blank">  {{ ticket.provider.replace(/([A-Z])/g, ' $1').trim() }}
                                  </a>
                                </p>
                              </div>
                            </div>

                          </div>
                          <div v-else-if="event.tickets.length === 2 && event.tickets.some(t => ['EventBrite', 'LiveNation', 'CompanyWebsite', 'Stubhub', 'Ticketmaster'].includes(t.provider))  && ticket.provider_left == null">

                          <div v-if="ticket.provider_right != null">
                            <div class="parallelogram3" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                              <p class="ticket_status_text2">   
                                <a :href="ticket.url" target="_blank">  {{ ticket.provider.replace(/([A-Z])/g, ' $1').trim() }}
                                </a>
                              </p>
                            </div>        
                          </div>

                          </div>
                          <div v-else>

                          <div  v-if="ticket.provider_right != null">

                        <div class="parallelogram2 near_you_left" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                          <p class="ticket_status_text2 near-you-ticket-text-resp-right">
                            <a :href="ticket.url" target="_blank">  {{ ticket.provider.replace(/([A-Z])/g, ' $1').trim() }}
                            </a>
                          </p>
                        </div>
                        </div>

                          </div>


                        </div>
                        </div>
                        </div>

                      <div v-if="event && event.object_type !== 'ads' && event.tickets && event.tickets.length === 1">
                        <div v-for="(ticket, index) in event.tickets.slice().sort((a, b) => {
                          const priority = ['Tickets','OrderOnline','FoodMenu', 'Reservation','VIP', 'Suites','Parking','FreeRSVP','Tailgate','Metaverse','Watch','PlaceBet'];
                          return priority.indexOf(a.provider) - priority.indexOf(b.provider);
                        })"
                        :key="index"
                      >
                          <div v-if="ticket.provider_right == null && ticket.provider_left ==null">
                            <div class="centered-container2 more_info_near_you">
                            <router-link  :to="{ path: 'event/' + event.event_id }">More Info</router-link>
                          </div>
                          </div>
                        
                        </div>
                      </div>

                        <div v-if="event && event.object_type !== 'ads' && event.tickets && event.tickets.length === 0">

                            <div class="centered-container more_info_near_you" style="margin-top: 14px;">
                            <router-link  :to="{ path: 'event/' + event.event_id }">More Info</router-link>
                          </div>
                        
                        </div>


                    <!--Private Location  plugin -->
                    <div
                      v-if="
                        event.type &&
                        event.type == 'PrivateLocation' &&
                        isPrivateContacts(event.contacts)
                      "
                      class="dropup private-info"
                    >
                      <button
                        class="btn btn-default notfollow"
                        id="private-contact-info"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        <img
                          width="16"
                          :src="'icons/call.svg' | assetPath"
                          alt="Call"
                        />
                        Contact
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="private-contact-info"
                      >
                        <li>
                          <a href="#"
                            ><strong>Private Event Information</strong></a
                          >
                        </li>
                        <li role="separator" class="divider"></li>
                        <template v-for="(contact, index) in event.contacts">
                          <li
                            v-if="contact.provider == 'private-number'"
                            :key="index"
                          >
                            <a
                              :href="'tel:' + contact.value"
                              class="dropdown-item"
                            >
                              <img
                                width="20"
                                :src="'icons/call.svg' | assetPath"
                                alt="Call"
                              />
                              {{ contact.value }}
                            </a>
                          </li>
                          <li
                            v-if="contact.provider == 'private-email'"
                            :key="index"
                          >
                            <a
                              :href="'mailto:' + contact.value"
                              class="dropdown-item"
                            >
                              <img
                                width="20"
                                :src="'icons/email.svg' | assetPath"
                                alt="Email"
                              />
                              {{ contact.value }}
                            </a>
                          </li>
                          <li
                            v-if="contact.provider == 'private-link'"
                            :key="index"
                          >
                            <a :href="contact.value" class="dropdown-item">
                              <img
                                width="20"
                                :src="'icons/link.svg' | assetPath"
                                alt="URL"
                              />
                              URL
                            </a>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          
        </template>

        <!--Load more results --->
        <div v-if="loadingMoreContent" class="content-loader">
          <img :src="'icons/vgps-loader.gif' | assetPath" alt="Loading Content" />
        </div>
      </div>

      <!--Show more event --->
      <div v-if="showLoadMore && !processing && !loadingContent" class="row">
        <div class="col-md-12">
          <infinite-loading
            spinner="waveDots"
            @infinite="infiniteHandler"
          ></infinite-loading>
        </div>
      </div>

      <!--Result not found -->
      <div v-if="events.length == 0 && !loadingContent" class="result-not-found">
        <a><i class="fa fa-search"></i></a>
        <p class="text-center">
          Sorry, we do not have any events for this search for now.<br />
          Maybe give one of these a try?<br />
          <strong>Festivals</strong> or <strong>National</strong>
        </p>
      </div>
    </div>
    <div v-if="events.length == 0 && !loadingContent" class="default-trending-wraper">
      <trending_events ></trending_events>
    </div>
    
  </div>
</template>

<script>
import { bus } from "../../app";
import { API_URL } from "../../app";
import LikeEvent from "../global/like .vue";
import Event_Follow from "../global/event-follow-button.vue";
import TrendingEvents from "./trending-events.vue";
import ResultNotFound from "./../../views/user/partials/result-not-found";
import helpers from "../../mixin/helpers";
import { mapFields } from "vuex-map-fields";
import Loadmore from "vue-loadmore";
import InfiniteLoading from "vue-infinite-loading";

import {
  ContentLoader,
  FacebookLoader,
} from "vue-content-loader";

export default {
  data() {
    return {
      disable: false,
      busy: false,
      page: 1,
      pageSize: 8,
      viewType: "eventType",
      loadingContent: false,
      loadingMoreContent: false,
      Searching: false,
      processing: false,
      showLoadMore: true,
      events: [],
      heartImage: "heart.svg",
      customAdCounter: 0,
      private_location: {
        email: "",
        phone: "",
        link: "",
      },
      providerColors: {
        Tickets: 'black',
        Reservation: '#33aaec',
        Parking: 'black',
        OrderOnline: '#ef1718',
        FoodMenu: '#ef1718',
        VIP: 'purple',
        Suites: 'green',
        Metaverse: '#28a9ed',
        FreeRSVP: '#28a9ed',
        Tailgate: '#28a9ed',
        Watch: 'black',
        PlaceBet : 'black',
        DrinkMenu:'red',
        ShopNow:'green',

      },
      adStatusOptions: {
        '' : 'Select One',
        '1' : 'Learn Now', 
        '2' : 'Download',
        '3' : 'Register',
        '4' : 'Buy Now',
        '5' : 'On Sale',
        '6': 'Holiday',
        '7' : 'Vote Now',
        '8' : 'Ending Soon',
        '9' : 'Tickets',
        '10' : 'Ads Wanted',
        '11' : 'Visit Site',
        '12' : 'Movie',
        '13' : 'Sneakers',
        '14' : 'Watch',
        '15' : 'Live Stream',
        '16' : 'Coming Soon',
        '16' : 'TV Show',
      },
    };
  },
  mixins: [helpers],
  computed: {
    ...mapFields([
      "search_term",
      "search_offset",
      "search_limit",
      "search_main_category",
      "search_sub_category",
      "search_top_hash",
      "search_date_range",
      "search_business_type",
      "search_business_sub_type",
      "search_event_theme",
      "search_event_genre",
      "search_event_cuisines",
      "search_event_amenities",
      "search_total_results",
      "search_hash_listing",
      "search_user_filters",
      "isSearching",
      "trendingEventList",
      "trendingHashList"
    ]),
  },
  created() {
    var vm = this;
    var filtersData = this.$session.get("global_filters");

    bus.$on("updateLocation", (city) => {
      vm.search_offset = 0;
      vm.search_limit = 12;
      this.getEvents("updateLocation", false);
    });

    bus.$on("getEventsByCity", (city) => {
      vm.search_offset = 0;
      vm.search_limit = 12;
      this.getEvents("updateLocation", false);
    });
    bus.$on("search_trending_hash", () => {
      vm.search_offset = 0;
      vm.search_limit = 12;
      this.getEvents();
    });

    bus.$on("search_page_term_emit", (query) => {
      vm.search_offset = 0;
      vm.search_limit = 12;
      vm.getEvents();
    });
    bus.$on("search_filter_emit", (query) => {
      vm.search_offset = 0;
      vm.search_limit = 12;
      vm.getEvents();
    });
    bus.$on("clear_search_page_filter_emit", (query) => {
      vm.search_offset = 0;
      vm.search_limit = 12;
      vm.getEvents();
    });

    bus.$on("search_event_title", (data) => {
      vm.search_term = data.search_term;
      vm.search_main_category = data.search_main_category;
      vm.search_offset = 0;
      vm.search_limit = 12;
      vm.getEvents();
    });

    bus.$on("get_search_term_events", () => {
      vm.search_offset = 0;
      vm.search_limit = 12;
      vm.getEvents();
    });

    bus.$on("search_params", (data) => {
      var params = this.$route.query;
      vm.search_term = typeof data.search_term != "undefined" ? data.search_term : "";
      vm.search_main_category =
        typeof data.search_main_category != "undefined"
          ? data.search_main_category
          : "";
      vm.search_offset = 0;
      vm.search_limit = 12;
      vm.getEvents();
    });
  },
  components: {
    ContentLoader,
    FacebookLoader,
    like_event: LikeEvent,
    event_follow: Event_Follow,
    result_not_found: ResultNotFound,
    trending_events : TrendingEvents,
    loadmore: Loadmore,
    InfiniteLoading,
  },
  props: [],
  mounted() {
    this.getEvents();
  },
  methods: {
    infiniteHandler($state) {
      var vm = this;
      vm.showLoadMore = true;
      vm.isSearching = true;
      vm.loadingMoreContent = true;
      
      if(!vm.loadingContent){
        setTimeout(() => {
          vm.search_offset = this.search_offset + 12;
          vm.getEvents("loadmore");
          $state.complete();
          vm.showLoadMore = false;
        }, 200);
      }
    },
    loadMore: function () {
      this.busy = true;

      setTimeout(() => {
        for (var i = 0, j = 10; i < j; i++) {
          this.data.push({ name: count++ });
        }
        this.busy = false;
      }, 1000);
      // this.search_offset = this.search_offset + 12;
      // this.getEvents("loadmore");
    },
    hashSearch: function (hashTag) {
      this.search_term = "#" + hashTag;
      this.getEvents();
    },
    getEvents: function (action, isScrollable) {
      var vm = this;

      
      var actionType = "";
      var start_date = "";
      var end_date = "";
      vm.customAdCounter = 0;
      if (action == "loadmore") {
        vm.loadingMoreContent = true;
        actionType = "loadmore";
      } else {
        vm.isSearching = true;
        vm.loadingContent = true;
        vm.offset = 0;
        actionType = "other";
      }

      if (typeof isScrollable != "undefined" && isScrollable === false) {
        this.search_offset = 0;
        $("#selectedStart_date").val("");
        $("#selectedEnd_date").val("");
      }

      var dataParams = {
        params: {
          q: vm.search_term,
          city: vm.$session.get("default_location"),
          state_code: vm.$session.get("default_state"),
          dates: vm.search_date_range,
          category: vm.search_main_category,
          sub_category: vm.search_sub_category,
          venue_type: vm.search_business_sub_type,
          genre: vm.search_event_genre,

          action: actionType,
          offset: vm.search_offset,
          limit: vm.search_limit,
        },
      };

      if (vm.search_event_theme.length > 0) {
        dataParams.params.event_theme = JSON.stringify(vm.search_event_theme);
      }

      var mergedAmenities = vm.search_event_amenities.concat(
        vm.search_event_cuisines
      );

      if (mergedAmenities.length > 0) {
        dataParams.params.amenity_id = JSON.stringify(mergedAmenities);
      }

      // if(vm.search_event_amenities.length > 0){
      //    dataParams.params.amenity_id = JSON.stringify(vm.search_event_amenities);
      // }
      // this.testapi(),

      // axios.get(API_URL+"search/advance-search/", dataParams)
      axios
        .get("data/search/advance/", dataParams)
        .then(function (response) {
          // let resData = response.data.payload;
          let resData = response.data.data;

          // console.log('event listing', resData)

          // add custom ads in event array if it exists
          let latestEventsList = [];
          if (vm.search_main_category == "Trending") {
            let countEvent = 0;
            let eventDetails = resData.events.length > 0 ? resData.events : [];
            eventDetails.map((el, key) => {
              latestEventsList.push(el);
            });

            vm.trendingEventList = latestEventsList;
            vm.trendingHashList = resData.hashtags;
          } else {
            latestEventsList = resData.events.length > 0 ? resData.events : [];
          }

          if (action == "loadmore") {
            $.each(latestEventsList, function (index, obj) {
              vm.events.push(obj);
              // console.log('loading---------------------->',obj)
            });
            vm.showLoadMore =
              resData.events.length < vm.search_limit ? false : true;
            vm.loadingMoreContent = false;
          } else {
            if (
              isScrollable != false &&
              (action == "updateLocation" || action == "search_event_title")
            ) {
              document
                .getElementById("filter-id")
                .scrollIntoView({ block: "end", behavior: "smooth" });
            }
            vm.events = latestEventsList;
            vm.showLoadMore =
              resData.events.length < vm.search_limit ? false : true;
            vm.loadingContent = false;
          }
          let eventList = resData.events;

        // Assuming vm.events is your list of arrays
        // let filteredEvents = vm.events.filter(event => event.some(item => item.status_value === 1));
        // console.log('loading-zzzzzzzzzzzzzzzzzz2222--------------------->',filteredEvents)

          vm.search_total_results = resData.total;
          vm.search_hash_listing = resData.hashtags;
          vm.search_user_filters = resData.user_filters;
          vm.isSearching = false;
        })
        .catch(function (errors) {
          vm.loadingContent = false;
          vm.loadingMoreContent = false;
          vm.isSearching = false;
        });
    },

    testapi(){

      const vm = this;

const dataParams2 = {
    params: {
        q: vm.search_term,
        city: vm.$session.get("default_location"),
        state_code: vm.$session.get("default_state"),
        dates: vm.search_date_range,
        category: vm.search_main_category,
        sub_category: vm.search_sub_category,
        venue_type: vm.search_business_sub_type,
        genre: vm.search_event_genre,
        action: actionType,
        offset: vm.search_offset,
        limit: 500,
    },
};

axios
    .get("data/search/advance/", dataParams2)
    .then(function(response) {
        let resData = response.data.data;
        let filterevents = resData.events.filter(event => event.some(item => item.status_value === 1));

        console.log('event listing111', filterevents);

        vm.search_total_results = resData.total;
        vm.search_hash_listing = resData.hashtags;
        vm.search_user_filters = resData.user_filters;
        vm.isSearching = false;
    })
    .catch(function(errors) {
        vm.loadingContent = false;
        vm.loadingMoreContent = false;
        vm.isSearching = false;
    });


    },

    followVenue: function (id, venue_id) {
      this.customAdCounter = 0;
      this.events = this.events.map((el) => {
        el.event_id === id ? (el.follow = true) : "";
        return el;
      });
      var vm = this;
      //this.heartImage = 'red-heart.svg';
      var data = new FormData();
      data.append("content_id", venue_id);
      data.append("content_type", "venue");
      data.append("favourite", true);

      if (vm.processing === true) {
        return false;
      }

      vm.processing = true;
      axios
        .post("data/markFavourite", data)
        .then(function (response) {
          vm.processing = false;
        })
        .catch(function (errors) {
          vm.processing = false;
        });
    },
    getNumberReminder: function (item, divider) {
      item = item + 1;
      var reminder = item % divider;
      if (reminder == 0 && item != 0) {
        console.log("Reminder", reminder);
        return true;
      } else {
        return false;
      }
    },
    cardType: function (index, eventType, type) {
      var str = "main-card main-card-" + index;
      if (eventType != "event" && type == "ads") {
        str += " ads-card-item";
      }
      if (eventType == "event" && type == "event") {
        str += " event-card-item";
      }

      if (this.getNumberReminder(index, 13) && type == "google-card") {
        str += " google-ads-card-item";
      }
      if (this.getNumberReminder(index, 15) && type == "google-full-width") {
        str += " google-full-width-card-item";
      }

      return str;
    },
    blockRightClick: function (e) {
      e.preventDefault();
    },
  },
  filters: {
    checkNullHash: function (hash) {
      var hashTag = "";
      if (hash != "" && hash != null && hash != "null") {
        hashTag = hash.indexOf("#") > -1 ? hash : "#" + hash;
      }
      return hashTag;
    },
    NATime: function (time) {
      if (time == "" || time == null || time == "00:00:00") {
        time = "- N/A";
      } else {
        time = "- " + time;
      }
      return time;
    },
    NADate: function (date) {
      if (date == "" || date == null) {
        date = "- N/A";
      } else {
        date = "- " + date;
      }
      return date;
    },
    assetPath: function (img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
    fullAddress: function (event) {
      var address = event.city;
      address += event.state != null ? ", " + event.state : "";
      address += event.zip_code != null ? ", " + event.zip_code : "";
      return address;
    },
    checkObj: function (obj) {
      return obj == "" ? false : true;
    },
    url: function (path) {
      var path = window.axios.defaults.baseURL + "/" + path;
      return path;
    },
  },
};
</script>
<style scoped>

.scroll-wrapper {
  display: flex;
  align-items: center;
  height: 500px;
}

.scroll-container {
  /* height: 250px; */
  display: flex;
  /* overflow-x: hidden; */
  overflow-y: hidden;
  white-space: nowrap;
  padding: 10px;
  gap: 10px;
  scroll-behavior: smooth; /* Smooth scroll effect */
  width: 100%; /* Set the container width */
  height: 100%;

}

.scroll-item {
  flex: 0 0 auto;
  /* width: 150px; */
  height: 100%;
  /* background-color: #e67e22; */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.scroll-button {
  border: none;
  color: white;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  height: 120px;
  background-color: unset;
}

.scroll-button:hover {
  background-color: #28a9ed;
}
/*  */

/*  */
.parallelogram-container {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between;  */
  margin-top: 8px;
}
/* Ticket  Right*/
.parallelogram {
  width: 140px;
  height: 26px;
  background-color: #3498db;
  color: white;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
}
.parallelogram2 {
  width: 140px;
  height: 26px;
  color: white;
  background-color: black;
  /* clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%);  */
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
}
.parallelogram3 {
  width: 140px;
  height: 26px;
  color: white;
  background-color: black;
  /* clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%); */
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
  margin-left: 130px;
}
/* Ad Ticket */
.parallelogramadleft {
  width: 80px;
  height: 26px;
  background-color:#57BB5B;
  color: white;
  clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
}
.parallelogramright{
  width: 200px;
  height: 26px;
  color: white;
  background-color:  #3498db;
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
}
.container23 {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Adjust based on your layout needs */
  margin-top: 10px;
}
.ticket_status_text{
  font-size: medium;
  color: white;
  margin-left: 15px;
  margin-top: 8px;
}
.ticket_status_text2{
  font-size: medium;
  color: white;
  margin-left: 22px;
  margin-top: 8px;
}
/* More Info Left */
.centered-container {
  font-size: medium;
  background-color: silver;
  width: 275px;
  height: 26px;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center;    /* Centers vertically */
  color: black;
}
/* More Info Right */
.centered-container2{
  font-size: medium;
  background-color: silver;
  width: 275px;
  height: 26px;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center;    /* Centers vertically */
  color: black;
  margin-top: 7px;
}

a{
  color: unset;
}
.near_you_right2{
  width: 110px !important;
}

.near_you_right{
  width: 132px !important;
}
.near_you_left{
  width: 123px !important;
}
.near_you_left2{
  width: 103px !important;
}
.more_info_near_you{
  width: 246px !important;
}
.more_info_near_you2{
  width: 206px !important;
}

.title-text-resp{
  font-size: 14px;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 197px;
}
.near-you-ticket-text-resp-left2{
  font-size: 12px;
}

.near-you-ticket-text-resp-right2{
  font-size: 12px;
  /* margin-left: 10px; */
}

/* .delete-event-btn {
    position: absolute;
    top: 6px;
    left: 38px;
    padding: 5px;
    border-radius: 4px;
    text-decoration: none;
    display: inline-block;
    overflow: hidden;
}

.delete-icon {
    opacity: 0; 
    transition: opacity 0.3s ease;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #eaeaea;

}

.delete-event-btn:hover .delete-icon {
    opacity: 1;
} */


/* Media query for mobile devices */
@media only screen and (min-width: 350px) and (max-width: 1008px){

.parallelogram {
width: 160px;
}
.parallelogram2 {
  width: 190px;
  clip-path: polygon(8% 0, 100% 0, 100% 100%, 0 100%);

  
}
.parallelogramadleft {
width: 90px !important;

}
.parallelogramright{
width: 280px !important;
clip-path: polygon(8% 0, 100% 0, 100% 100%, 0 100%);
}

.ticket_status_text{
  margin-left: 15px;
  /* margin-top: 15px !important; */
}
.ticket_status_text2{

  margin-left: 50px;
  /* margin-top: 15px!important */
}

.parallelogram3 {
  /* width: 140px;
  height: 50px;
  color: white;
  background-color: black;
  clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%); */

  margin-left: 206px;

}

.centered-container {
  width: 380px;

}
.centered-container2{
  width: 380px;

}

.container23 {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;  */
  padding: 20px;

}

.near_you_right{
  width: 80px !important;
}

.near_you_right2{
  width: 80px !important;
}
.near_you_left{
  width: 70px !important;
}
.near_you_left2{
  width: 70px !important;
}
.more_info_near_you{
  width: 156px !important;
}
.more_info_near_you2{
  width: 156px !important;
}
.event-card-resp{
  height: 200px !important;
  width: 150px !important;
  padding-bottom: unset
}
.dates{
  font-size: 11px;
}
.near-you-ticket-text-resp-left{
  font-size: 11px;
}
.near-you-ticket-text-resp-right{
  font-size: 11px;
  margin-left: 10px;
}
.near-you-ticket-text-resp-left2{
  font-size: 11px;
}
.near-you-ticket-text-resp-right2{
  font-size: 11px;
  margin-left: 10px;
}
.more-info-resp{

}
.title-text-resp{
  font-size: 14px;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}
.scroll-container{
  gap: 0px;
}
.scroll-wrapper{
  height: unset;
}
.event-card-mobile-resp-trending{
  height: 400px;
}
}

</style>