<template>
    <a  :class="[follow == true ?  'follow' : 'notfollow']"
        class="btns follow-btn-component"
        href="javascript:void(null)"
        v-on:click="followVenue">
        <img v-if="!processing" :src="getImageUrl(follow)"/> 
        <span v-if="processing" class="" :class="{active : processing == true }">
            <i class="fa fa-circle-o-notch fa-spin"></i>
        </span>
        <span class="xs-follow-text-hide">Follow</span>
    </a>
</template>

<script>

    import { bus } from '../../app'
    import helpers from "../../mixin/helpers";
    import {
            ContentLoader,
            FacebookLoader,
            CodeLoader,
            BulletListLoader,
            InstagramLoader,
            ListLoader
    } from 'vue-content-loader'


    export default {
        props:['follow','id','title'],
        mixins: [helpers],
        watch: {

        },
        components: {

        },
        computed: {

        },
        mounted: function () {
            var vm = this;
            vm.DetailshareButtonEnable();

        },
        data: function () {
            return {
//                follow: false,
                shareTitle: 'venue',
                shareType: 'venue',
                shareUrl: 'venue-detail',
                processing:false,
            }
        },
        methods: {
            followVenue: function() {

                var vm = this;
                
                if(typeof vm.$session.get('access_token') == 'undefined'){
                    $('#loginModal').modal('show');
                    return false;
                }

                if(!vm.hasSubscribedPlan()){
                    sweetalert2.fire({
                        icon: 'info',
                        title: 'Upgrade Subscription Plan',
                        text: 'Please upgrade your subscription plan to like and follow event.'
                    })
                    return false;
                }
                
                var follow = vm.follow == true ? false: true;

                var data = new FormData();
                data.append("content_id", vm.id);
                data.append("content_type", 'event');
                data.append("favourite", follow);

                if (vm.processing === true) {
                    return false;
                }

                vm.processing = true;
                axios.post("data/markFavourite", data)
                    .then(function (response) {
                        vm.processing = false;
                        vm.follow = follow;

                    })
                    .catch(function (errors) {
                        vm.processing = false;
                    });

            },
            getImageUrl(isLike) {
                var vm = this;
                var path = window.axios.defaults.baseURL+'/images/';
                return vm.follow === true ?  path+'fav-w.svg':path + 'fav.svg' ;
            },
            DetailshareButtonEnable: function () {

                var vm = this;
                var base_url = window.axios.defaults.baseURL;
                setTimeout(function () {

                    var options = {
                        twitter: {
                            text: 'VenueGPS Event'
                        },
                        facebook: true,
                    };

                    $('.social-share').shareButtons(base_url, options,vm.shareType);
                    // $.each(eventList, function (index, obj) {

                    var twitterLink = 'https://twitter.com/intent/tweet?url='+base_url +'/'+ vm.shareUrl + '/' + vm.id + '&text=' + vm.title;
                    var facebookLink = 'https://facebook.com/sharer.php?u='+ base_url +'/'+ vm.shareUrl + '/' + vm.id;
                    $('.socialShare_' + vm.id).find('.fa-twitter').attr('href', twitterLink);
                    $('.socialShare_' + vm.id).find('.fa-facebook').attr('href', facebookLink);
                    var share_type = 'venue';
                    if(vm.shareType == 'event') {
                        share_type = 'event';
                    }
                    $('.socialShare_' + vm.id).find('.fa-twitter').attr('onclick', 'shareCountIncreases('+vm.id+', "'+vm.shareType+'")');
                    $('.socialShare_' + vm.id).find('.fa-facebook').attr('onclick', 'shareCountIncreases('+vm.id+', "'+vm.shareType+'")');
                    // });
                }, 100);
            }
        },

        filters: {
            assetPath: function (img) {
                var path = window.axios.defaults.baseURL+'/images/'+img;
                return path;
            },
        }
    }

</script>
