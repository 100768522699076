<template>

    <div class="selector">
                  <ul>
                    <li>        
                      <div class="form-group">
                        <label>Date</label>
                        <date-range-picker
                                ref="picker"
                                :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }"
                                :startDate="2019-10-1"
                                :endDate="2019-10-17"
                                :autoApply="true"
                                :showDropdowns="false"
                                :showWeekNumbers="true"
                                v-model="dateRange"
                                @update="updateValues">
                            <div slot="input" slot-scope="picker" style="min-width: 116px;">
                                <span v-if="picker.startDate">
                                    {{ picker.startDate | dateFilterFormatStart }} - {{ picker.endDate | dateFilterFormatEnd }}
                                </span>
                                <span v-else-if="start_date && end_date && !picker.startDate">
                                    {{ start_date+ ' - '+ end_date }}
                                </span>
                                <span v-else>
                                    Date
                                </span>
                            </div>
                        </date-range-picker>
                      </div>
                    </li>
                    <li>        
                      <div class="form-group">
                        <label>Venue Type</label>
                        <select class="js-select2" v-model="venue_type" v-on:change="advanceFilter()">
                          <option value="">Venue Type</option>
                          <option class="option-select" v-for="type in qoute['arena'].lookups" :value="type.code">{{type.name}}</option>
                        </select>
                      </div>
                    </li>
                    <li>        
                      <div class="form-group">
                        <label>Event Type</label>
                        <select class="js-select2" v-model="event_theme" v-on:change="advanceFilter()">
                          <option value="">Event Theme</option>
                          <option v-for="type in qoute['EventTheme'].lookups" :value="type.code">{{type.name}}</option>
                        </select>
                      </div>
                    </li>
                    <li>        
                      <div class="form-group">
                        <label>Genres</label>
                        <select class="js-select2" v-model="event_genre" v-on:change="advanceFilter()">
                          <option value="">Genres</option>
                         <option v-for="type in qoute['Genre'].lookups" :value="type.code">{{type.name}}</option>
                        </select>
                      </div>
                    </li>
                    <li>
                      <div class="form-group">
                        <a @click=clearSearch(); class="clear" href="javascript:void(0)"><img :src="'invalid-name.svg' | assetPath"> Clear</a>
                      </div>
                    </li>
                  </ul>
               </div>
</template>
<script>

    import { bus } from '../../app'
     import DateRangePicker from 'vue2-daterange-picker'
    import helpers from '../../mixin/helpers';
    //you need to import the CSS manually (in case you want to override it)
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

    export default {
        components: {
            DateRangePicker
        },
        mixins: [helpers],
        data() {
            return {
                dateRange:'',
                venue_type:'',
                isDateFormat: false,
                isFilterSelected: false,
                start_date: '',
                end_date: '',
                event_theme: '',
                event_genre: ''
            }
        },
        mounted() {
            var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
            this.start_date = this.qoute2.start_date;
            this.end_date = this.qoute2.end_date;
            this.venue_type = this.qoute2.venue_type ? this.qoute2.venue_type : '';
            this.event_theme = this.qoute2.event_theme ? this.qoute2.event_theme : '';
            this.event_genre = this.qoute2.genre ? this.qoute2.genre : '';

            if(this.start_date && this.end_date) {
                this.start_date = monthShortNames[parseInt(this.qoute2.start_date.split("-")[1]) - 1] +' '+ (this.qoute2.start_date.split("-")[2]);
                this.end_date = monthShortNames[parseInt(this.qoute2.end_date.split("-")[1]) - 1] +' '+ (this.qoute2.end_date.split("-")[2]);
            }
        },
        created: function(){

        },

        props:['qoute2','lookupData', 'qoute'],
        methods:{
            clearFilter:function(){
                var vm = this;
                this.isFilterSelected = false;
                $('.js-select2').val('');
            },
            applySelectedClass:function(value) {
                return value != '' ? 'js-select2 dropDownSelected' : 'js-select2';
            },
            updateValues:function(data){
                 var isoStr = Date.parse(data.startDate);
                    var current_datetime = new Date(isoStr);
                    var currentDay = current_datetime.getDate() <= 9 ? '0'+current_datetime.getDate() : current_datetime.getDate();
                    let start_formatted_date = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1)
                            + "-" + currentDay;
                    

                    isoStr = Date.parse(data.endDate);
                    current_datetime = new Date(isoStr);
                    currentDay = current_datetime.getDate() <= 9 ? '0'+current_datetime.getDate() : current_datetime.getDate();
                    let end_formatted_date = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1)
                            + "-" + currentDay;
                   
                   //console.log(start_formatted_date, end_formatted_date);
                   this.start_date = start_formatted_date;
                   this.end_date = end_formatted_date;
                    this.goToLink();
            },
            goToLink(type) {

                if(type != 'clear') {
                    if(this.start_date == undefined || this.end_date == undefined) {
                        this.start_date = '';
                        this.end_date = '';
                    }
                    else if(this.start_date.indexOf(" ") >= 0) {
                        this.start_date = this.qoute2.start_date;
                        this.end_date = this.qoute2.end_date;
                    }
                    window.location.href = '?limit=10&offset=30&start_date='+this.start_date+'&end_date='+this.end_date+'&venue_type='+this.venue_type+'&event_theme='+this.event_theme+'&genre='+this.event_genre;
                }
                else {
                    window.location.href = this.url('admin/event/listing');
                }
            },
            clearSearch() {

                this.start_date = '';
                this.end_date = '';
                this.venue_type = '';
                this.event_theme = '';
                this.event_genre = '';
                this.goToLink('clear');
            },
            advanceFilter() {
                this.goToLink();
            }
        },
        filters: {
            dateFilterFormatStart: function (date) {
                var myDate = '';
                var currentDay = 0;
                if(date != null ){

                    var isoStr = Date.parse(date);
                    var current_datetime = new Date(isoStr);
                    currentDay = current_datetime.getDate() <= 9 ? '0'+current_datetime.getDate() : current_datetime.getDate();
                    let formatted_date = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1)
                            + "-" + currentDay;
                    myDate = formatted_date;
                }else {
                    myDate = '';
                    
                }
                $('#calendarStart_date').val(date.toLocaleString('default', { month: 'short' })+' '+currentDay);
                $('.reportrange-text').find('span').text($('#calendarStart_date').val() + ' - '+ $('#calendarEnd_date').val());
                $('#selectedStart_date').val(myDate);
                return myDate;
            },
            dateFilterFormatEnd: function (date) {
                var myDate = '';
                var currentDay = 0;
                if(date != null ){
                    var isoStr = Date.parse(date);
                    var current_datetime = new Date(isoStr);
                    currentDay = current_datetime.getDate() <= 9 ? '0'+current_datetime.getDate() : current_datetime.getDate();
                   let formatted_date = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1)
                            + "-" + currentDay;
                    myDate = formatted_date;
                }else {
                    myDate = '';
                    
                }
                $('#calendarEnd_date').val(date.toLocaleString('default', { month: 'short' })+' '+currentDay);
                $('.reportrange-text').find('span').text($('#calendarStart_date').val() + ' - '+ $('#calendarEnd_date').val());
                $('#selectedEnd_date').val(myDate);
                return myDate;
            },
            assetPath: function (img) {
                var path = window.axios.defaults.baseURL+'/images/'+img;
                return path;
            },
        }
    }

    function showDiv() {
        div = document.getElementById('ClearFilters');
        div.style.display = "block";
    }

</script>
