<template>

    <div class="menu-box info-page-menu">
        <ul>
            <li><router-link  :to="{ name: 'claim-business' }">Claim Your Business</router-link></li>
            <!-- <li><router-link  :to="{ name: 'faqs' }">FAQ's</router-link></li> -->
            <li><router-link  :to="{ name: 'contact-us' }">Contact Support</router-link></li>
        </ul>
    </div>

</template>

<script>

  import { bus } from './../app'
  import helpers from './../../mixin/helpers';

  export default {
    data() {
      return {

      }
    },
    created (){

    },
    mixins: [helpers],
  }
</script>