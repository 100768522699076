<template>
  <div>
    <header_section></header_section>
    <main id="team-page-content" class="top-inner-pages">
      <!-- <section class="headerSectionWrapper">
        <div class="headerBGImage">
          <img :src="'banners/vgps-min.png' | assetPath" alt="header Image" />
        </div>
      </section> -->

      <div class="container">
        <div
          class="headline wow fadeInLeft teamPageHeading"
          style="visibility: visible; animation-name: fadeInLeft;"
        >
          <h2>Meet Our Team</h2>
        </div>
        <hr class="xs" />
        <div class="bg-white-container teamPageContentCont">
          <div class="row verticle-align-middle">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
              <div class="team-main-image">
                <img src="https://d2cv1d7fub3ij0.cloudfront.net/ceo-publicity-media/team/david.jpg" />
              </div>
            </div>
            <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12">
              <div class="team-main-description">
                <h3>DAVID "CONN" MOREY</h3>
                <h4>FOUNDER, CEO &amp; CO-OWNER</h4>
                <p>David "Conn" Morey, CEO, Co-Owner, and retired Navy Chief Petty Officer as well as Chief Engineer of VenueGPS. Following Naval service, he spent the next four years hypothesizing the digitization of the entertainment industry. He studied spending habits and social norms to answer to the age-old question; "What going down tonight?" He slept many nights on top of his computer keyboard determined to answer That question once and for all. Ladies and Gentlemen, VenueGPS,Your Global Phenomenon!</p>
              </div>
            </div>
          </div>
          <div class="row mt-5 verticle-align-middle">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
              <div class="team-main-image">
                <img :src="'team/andrae.JPG'| assetPath" />
              </div>
            </div>
            <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12">
              <div class="team-main-description">
                <h3>ANDRAE "DRE" SUTHERLAND</h3>
                <h4>CFO &amp; CO-OWNER</h4>
                <p>Co-Owner, Andrae "Dre" Sutherland is the CFO and face of VenueGPS. Dre created deep ripple effect market awareness by establishing wide-ranging public relation alliances. He engaged entertainment icons, forged relationships with industry experts, and even shook hands and kissed babies free of COVID-19. Dre's diplomacy skills resulted in 133 stakeholders which stimulated "BOSS LEVEL" enthusiasm for VenueGPS' earthshattering creation of digitized entertainment. VenueGPS! A World of its Own!</p>
              </div>
            </div>
          </div>
          <!-- end col-md-3 -->
          <!-- <div class="row mt-5">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/tiara.png'| assetPath" alt />
              <h3>Tiara Elvin</h3>
              <p class="subhead fregular">COO</p>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/jaymes.png'| assetPath" alt />
              <h3>Jaymes Dwight</h3>
              <p class="subhead fregular">Creative Director</p>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/renae.png'| assetPath" alt />
              <h3>Renae Green-Hart</h3>
              <p class="subhead fregular">Human Resource</p>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/anthony.png'| assetPath" alt />
              <h3>Anthony Latimore</h3>
              <p class="subhead fregular">Content Manager</p>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/IMG_4955-min.jpg'| assetPath" alt />
              <h3>Michael Tank Torian</h3>
              <p class="subhead fregular">Account Lead</p>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/ray.jpeg'| assetPath" alt />
              <h3>Ray DoneGan Jr.</h3>
              <p class="subhead fregular">Casino Lead</p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/marketing_1_240x320.jpg'| assetPath" alt />
              <h3>Muhammed Imran</h3>
              <p class="subhead fregular">Digital Marketing Lead</p>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/placeholder.png'| assetPath" alt />
              <h3>Antoine Lew</h3>
              <p class="subhead fregular">Digital Marketing Manager</p>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/placeholder.png'| assetPath" alt />
              <h3>Mandi Bender</h3>
              <p class="subhead fregular">Public Relations Lead</p>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/placeholder.png'| assetPath" alt />
              <h3>Whitney Shelley</h3>
              <p class="subhead fregular">Public Relations</p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/placeholder.png'| assetPath" alt />
              <h3>Katy Smith</h3>
              <p class="subhead fregular">Public Relations</p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/J-Black-img.jpg'| assetPath" alt />
              <h3>J. Blak</h3>
              <p class="subhead fregular">Digital Flyer Lead</p>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/IMG_1719-min.jpg'| assetPath" alt />
              <h3>Rob Ruger</h3>
              <p class="subhead fregular">Visionary Lead</p>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/IMG-0335-min.jpg'| assetPath" alt />
              <h3>Keddan Savage</h3>
              <p class="subhead fregular">Graphic Designer</p>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/Anathony.jpg'| assetPath" alt />
              <h3>Anthony Pastrana</h3>
              <p class="subhead fregular">Research Analyst</p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/FullSizeRender-min.jpeg'| assetPath" alt />
              <h3>Mr. Chase</h3>
              <p class="subhead fregular">Research Analyst</p>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/rsz_team-page_240x320.jpg'| assetPath" alt />
              <h3>Roosevelt Teddy' Blackmon</h3>
              <p class="subhead fregular">Research Analyst</p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/IMG_5370-min.jpg'| assetPath" alt />
              <h3>Mrs. Chase</h3>
              <p class="subhead fregular">Research Analyst</p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/brian.JPG'| assetPath" alt />
              <h3>Brian Johnson</h3>
              <p class="subhead fregular">Account Analyst</p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/Diva.jpg'| assetPath" alt />
              <h3>Diva Gilbert</h3>
              <p class="subhead fregular">MC/SC/CC/Coord</p>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/Nazaire.jpeg'| assetPath" alt />
              <h3>Nazaire Vereen</h3>
              <p class="subhead fregular">Schedule Lead</p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 box_one">
              <img :src="'team/IMG_1730-min.jpeg'| assetPath" alt />
              <h3>DJ. Snap</h3>
              <p class="subhead fregular">House DJ</p>
            </div>
          </div> -->

        </div>
      </div>
    </main>
    <footer_section></footer_section>
  </div>
</template>
<script>
import { bus } from "./app";
import HeaderSection from "./../views/partials/header";
import FooterSection from "./../views/partials/footer";
import helpers from "./../mixin/helpers";

export default {
  data() {
    return {
      loadingContent: false,
      processing: false,
      title: "",
      contact: "",
      company: "",
      email: "",
      location: "",
      subject: "",
      message: "",
    };
  },
  mixins: [helpers],
  components: {
    header_section: HeaderSection,
    footer_section: FooterSection,
  },
  methods: {
    save: function () {
      var vm = this;
      var data = new FormData();

      data.append("title", this.title);
      data.append("email", this.email);
      data.append("contact", this.contact);
      data.append("company", this.company);
      data.append("location", this.location);
      data.append("subject", this.subject);
      data.append("message", this.message);

      var validate = $("#contact-form").validate();
      validate.form();
      if (validate.errorList.length > 0) {
        sweetalert2.fire("", "please fill the required fields.", "fail");
        return false;
      }

      if (vm.processing === true) {
        return false;
      }
      vm.processing = true;

      axios
        .post("contact-support", data)
        .then(function (response) {
          if (response.data.success == true) {
            sweetalert2.fire("", response.data.description, "success");

            this.title = "";
            this.email = "";
            this.contact = "";
            this.company = "";
            this.location = "";
            this.subject = "";
            this.message = "";
          } else {
            var messages = "";
            if (typeof response.data.exceptions.errors != "undefined") {
              messages += '<ul class="error-list">';
              $.each(response.data.exceptions.errors, function (index, obj) {
                messages += "<li>" + obj.message + "</li>";
              });
              messages += "</ul>";
            } else {
              messages = response.data.description;
            }

            sweetalert2.fire({
              icon: "info",
              title: "Something went wrong",
              html: messages,
            });
          }

          vm.processing = false;
        })
        .catch(function (errors) {
          console.log("Contact exception", errors);
          sweetalert2.fire("", "Something went wrong!", "fail");
          vm.processing = false;
        });
    },
  },
  filters: {
    assetPath: function (img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
  },
};
</script>