<template>
  <div>
    <ul class="map-tabs-layout">
      <li class="radius-select2">
        <Select2
          v-model="filters.event_radius"
          placeholder="Radius"
          :options="milesArray"
          :settings="{ placeholder: 'Radius' }"
          @select="advanceFilter()"
        />
      </li>
      <li
        class="map-dates"
        :class="{ 'active-selected-item': filters.dateRange != '' }"
      >
        <date-picker
          v-model="dateRange"
          value-type="YYYY-MM-DD"
          format="MMM DD"
          input-class="form-control"
          :disabled-date="notBeforeToday"
          range
          @change="updateValues"
          placeholder="Select Date"
          :input-attr="{ name: 'daterange', id: 'event-search-dateranges' }"
        ></date-picker>
      </li>
      <li
        class="map-select-field hidden-xs"
        :class="{ 'active-selected-item': filters.business_type }"
      >
        <Select2
          v-model="filters.business_type"
          placeholder="Business Type"
          :options="businessTypes"
          :settings="{ placeholder: 'Business Type', allowClear: true }"
          @select="advanceFilter()"
        />
      </li>
      <li
        :class="{ 'active-selected-item ': filters.venue_type }"
        class="map-select-field hidden-xs select2-venue-type filter-element"
      >
        <Select2
          v-model="filters.venue_type"
          :options="businessSubTypes"
          :settings="{
            placeholder: 'Business Sub-Type',
            allowClear: true,
            language: {
              noResults: function () {
                return 'Select Business Type';
              },
            },
          }"
          @select="advanceFilter()"
        />
      </li>
      <li
        class="map-select-field hidden-xs"
        :class="{ 'active-selected-item': filters.event_theme }"
      >
        <Select2
          v-model="filters.event_theme"
          :options="tierEventThemes"
          @select="advanceFilter()"
          :settings="{ placeholder: 'Event Theme', allowClear: true }"
        />
      </li>
      <li
        class="map-select-field hidden-xs"
        :class="{ 'active-selected-item': filters.event_genre }"
      >
        <Select2
          v-model="filters.event_genre"
          :options="eventGenre"
          @select="advanceFilter()"
          :settings="{ placeholder: 'Event Genre', allowClear: true }"
        />
      </li>
      <li
        class="map-select-field hidden-xs"
        :class="{ 'active-selected-item': filters.amenity_id }"
      >
        <Select2
          v-model="filters.amenity_id"
          :options="FoodCuisinesData"
          @select="advanceFilter()"
          :settings="{ placeholder: 'Food Cuisines', allowClear: true }"
        />
      </li>
      <li class="hidden-lg hidden-md hidden-sm">
        <div
          class="map-filter-card-open"
          data-toggle="modal"
          data-target="#myModal"
        >
          <img :src="'filter.svg' | assetPath" />
        </div>
      </li>
      <li class="map-clear" v-show="isFilterSelected">
        <button
          id="ClearFilters"
          @click="clearFilter"
          title="Click to reset any filters set."
          class="butstyle"
        >
          <img :src="'close-blue.svg' | assetPath" />
        </button>
      </li>
      <li class="pull-right live-locatioin">
        <label class="switch" for="severity-level">
          <input
            v-model="enabledSeverityLevel"
            value="1"
            id="severity-level"
            type="checkbox"
            checked
          />
          <span class="slider round"></span>
        </label>
        <!-- Single button -->
        <div v-if="getBusinessSeverities.length > 0" class="btn-group">
          <a
            href="#"
            class="btn btn-small btn-default dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Venue AID <span class="caret"></span>
          </a>
          <ul class="dropdown-menu severity-info-list dropdown-menu-right">
            <template
              v-for="level in getBusinessSeverities"
              v-if="excludeReasons(level.id)"
            >
              <li :class="level.color" :key="'level-' + level.id">
                <a class="severity-heading" href="#">{{ level.title }}</a>
              </li>
              <template v-if="level.severity.length > 0">
                <li v-for="item in level.severity" :key="item.id">
                  <a href="#"
                    ><img width="20" :src="item.icon_url" :alt="item.level" />
                    {{ item.description }}</a
                  >
                </li>
                <span :key="'divider-' + level.id" class="divider"></span>
              </template>
            </template>
          </ul>
        </div>
      </li>

      <!-- <li class="pull-right live-locatioin">
        <div class="form-group vgps-custom-checkbox-wrapper">
          <input type="checkbox" v-model="liveLocation" value="1" id="live-location" />
          <label for="live-location">Live Location</label>
        </div>
      </li> -->
    </ul>

    <!-- Modal -->
    <div class="modal fade left" id="myModal" role="dialog">
      <div class="modal-dialog">
        <ul class="popup-tabs-layout">
          <li :class="{ 'active-selected-item': filters.business_type }">
            <Select2
              v-model="filters.business_type"
              placeholder="Business Type"
              :options="businessTypes"
              :settings="{ placeholder: 'Business Type', allowClear: true }"
            />
          </li>
          <li
            :class="{ 'active-selected-item': filters.venue_type }"
            class="select2-venue-type filter-element"
          >
            <Select2
              v-model="filters.venue_type"
              :options="businessSubTypes"
              :settings="{
                placeholder: 'Business Sub-Type',
                allowClear: true,
                language: {
                  noResults: function () {
                    return 'Select Business Type';
                  },
                },
              }"
            />
          </li>
          <li :class="{ 'active-selected-item': filters.event_theme }">
            <Select2
              v-model="filters.event_theme"
              :options="tierEventThemes"
              :settings="{ placeholder: 'Event Theme', allowClear: true }"
            />
          </li>
          <li :class="{ 'active-selected-item': filters.event_genre }">
            <Select2
              v-model="filters.event_genre"
              :options="eventGenre"
              :settings="{ placeholder: 'Event Genre', allowClear: true }"
            />
          </li>
          <li :class="{ 'active-selected-item': filters.amenity_id }">
            <Select2
              v-model="filters.amenity_id"
              :options="FoodCuisinesData"
              :settings="{ placeholder: 'Food Cuisines', allowClear: true }"
            />
          </li>
        </ul>
        <button type="button" class="btn btn-cancel-popup" data-dismiss="modal">
          <img src="images/pop-cancel.svg" />CANCEL
        </button>
        <button
          type="button"
          v-on:click="advanceFilter()"
          data-dismiss="modal"
          class="btn btn-apply-popup"
        >
          <img src="images/pop-apply.svg" /> APPLY
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { bus } from "../../app";
import DateRangePicker from "vue2-daterange-picker";
import Select2 from "v-select2-component";
import helpers from "../../mixin/helpers";
//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import { mapFields } from "vuex-map-fields";

export default {
  components: {
    DateRangePicker,
    Select2,
  },
  data() {
    return {
      dateRange: "",
      venue_type: "",
      business_type: "",
      liveLocation: false,
      isDateFormat: false,
      isFilterSelected: false,
      subBusinessTypes: [],
    };
  },
  mixins: [helpers],
  computed: {
    milesArray() {
      return this.$store.getters.milesArray;
    },
    tierEventThemes() {
      return this.$store.getters.tierEventThemes;
    },
    eventGenre() {
      return this.$store.getters.eventGenre;
    },
    businessTypes() {
      return this.$store.getters.businessTypes;
    },
    businessSubTypes() {
      return this.$store.getters.businessSubTypes;
    },
    getBusinessSeverities() {
      return this.$store.getters.getBusinessSeverities;
    },
    FoodCuisinesData() {
      return this.$store.getters.FoodCuisinesData;
    },
    ...mapFields([
      "search_term",
      "search_offset",
      "search_limit",
      "search_category",
      "search_top_hash",
      "search_date_range",
      "search_business_type",
      "search_business_sub_type",
      "search_event_theme",
      "search_event_genre",
      "search_event_cuisines",
      "search_total_results",
      "enabledSeverityLevel",
    ]),
  },
  mounted() {},
  watch: {
    liveLocation: function () {
      if (this.liveLocation) {
        this.$session.set("liveLocation", true);
      } else {
        this.$session.set("liveLocation", false);
      }
      bus.$emit("getLiveLocation");
    },
    business_type: function (newVal) {
      var vm = this;
      vm.$store.dispatch("getBusinessSubTypesAction", newVal);
    },
  },
  updated: function () {},
  created: function () {
    this.liveLocation = this.$session.get("liveLocation");
  },
  props: ["filters"],
  methods: {
    updateValues: function (data) {
      this.isFilterSelected = true;
      this.filters.dates.start = data[0];
      this.filters.dates.end = data[1];
      bus.$emit("updateMapDateValues");
    },
    advanceFilter: function () {
      this.business_type = this.filters.business_type;
      this.isFilterSelected = true;
      bus.$emit("advanceFilter");
    },
    clearFilter: function () {
      var vm = this;
      this.isFilterSelected = false;
      $(".js-select2").val("");
      // $('#selectedStart_date').val('');
      // $('#selectedEnd_date').val('');
      // $('.reportrange-text').find('span').html('Date');

      vm.dateRange = "";
      vm.filters.dates.start = "";
      vm.filters.dates.end = "";

      if (typeof dateRange != "undefined" && dateRange != "") {
        vm.dateRange.start = "";
        vm.dateRange.end = "";
      }

      vm.filters.dates.start = "";
      vm.filters.dates.end = "";
      vm.filters.venue_type = "";
      vm.filters.business_type = "";
      vm.business_type = "";
      vm.filters.event_theme = "";
      vm.filters.event_genre = "";
      vm.filters.amenity_id = "";
      vm.filters.event_radius = "10";
      vm.filters.search = "";
      vm.filters.category = "";
      bus.$emit("clearAdvanceFilter");
    },
    applySelectedClass: function (value) {
      return value != "" ? "active-selected-item" : "";
    },
  },
  filters: {
    dateFilterFormatStart: function (date) {
      var myDate = "";
      var currentDay = 0;
      if (date != null) {
        var isoStr = Date.parse(date);
        var current_datetime = new Date(isoStr);
        currentDay =
          current_datetime.getDate() <= 9
            ? "0" + current_datetime.getDate()
            : current_datetime.getDate();
        let formatted_date =
          current_datetime.getFullYear() +
          "-" +
          (current_datetime.getMonth() + 1) +
          "-" +
          currentDay;
        myDate = formatted_date;
      } else {
        myDate = "";
      }
      // $('#calendarStart_date').val(date.toLocaleString('default', { month: 'short' })+' '+currentDay);
      // $('.reportrange-text').find('span').text($('#calendarStart_date').val() + ' - '+ $('#calendarEnd_date').val());
      // $('#selectedStart_date').val(myDate);
      return myDate;
    },
    dateFilterFormatEnd: function (date) {
      var myDate = "";
      var currentDay = 0;
      if (date != null) {
        var isoStr = Date.parse(date);
        var current_datetime = new Date(isoStr);
        currentDay =
          current_datetime.getDate() <= 9
            ? "0" + current_datetime.getDate()
            : current_datetime.getDate();
        let formatted_date =
          current_datetime.getFullYear() +
          "-" +
          (current_datetime.getMonth() + 1) +
          "-" +
          currentDay;
        myDate = formatted_date;
      } else {
        myDate = "";
      }
      $("#calendarEnd_date").val(
        date.toLocaleString("default", { month: "short" }) + " " + currentDay
      );
      $(".reportrange-text")
        .find("span")
        .text(
          $("#calendarStart_date").val() + " - " + $("#calendarEnd_date").val()
        );
      $("#selectedEnd_date").val(myDate);
      return myDate;
    },
    assetPath: function (img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
  },
};

function showDiv() {
  div = document.getElementById("ClearFilters");
  div.style.display = "block";
}
</script>
