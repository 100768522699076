<template>
  <div>
    <Select2
      name="theme"
      id="theme"
      v-model="theme_id"
      placeholder="Select State"
      :options="adminTierEventThemes"
      :settings="{placeholder:'Select One',selectOnClose: false,allowClear: true,closeOnSelect: true}"
    />
  </div>
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";
import Select2 from "v-select2-component";

export default {
  props: ["theme"],
  mixins: [helpers],
  watch: {},
  updated() {
    
  },
  created() {
    this.theme_id = this.theme;
  },
  computed: {
    adminTierEventThemes() {
      return this.$store.getters.adminTierEventThemes;
    }
  },
  components: {
    Select2
  },
  data: function() {
    return {
      theme_id: ""
    };
  },
  methods: {
    getStateCities: function() {
      var vm = this;
      var stateData = jQuery.grep(this.$store.state.lookupData.states, function(
        obj
      ) {
        return obj.id == vm.state_id;
      });
      if (typeof stateData != "indefined" && stateData.length > 0) {
        this.$store.state.stateCitiesData = [];
        this.$store.state.stateCitiesData = stateData[0].city_set.map(el => {
          return { id: el.name, text: el.name };
        });
      }
    }
  }
};
</script>
