<template>
    <section class="event-about vgps-event-detail-section-wrapper" id="about">
        <div class="containers">
            <div class="row">
                <div class="col-md-12 vgps-event-detail-section-title">
                    <h2>About Event</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 vgps-about-event-content-wrapper" :class="[ event.about.length < 1000 ?'show-content':'', readMore?'show-content':'' ]" >
                    <p v-html="event.about"></p>
                </div>
                <!-- @if(strlen($eventDetail->about) > 1000) -->
                    <div v-if="event.about.length > 1000" class="col-md-12 vgps-about-event-toggle-btn-wrapper">
                        <button  @click="readMore = !readMore" class="vgps-event-content-toggle-btn">View More</button>
                    </div>
                <!-- @endif -->
            </div>
        </div>
    </section>
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";


export default {
  data() {
    return {
      readMore:false,
    };
  },
  props: ["event"],
  mixins: [helpers],
  created() {
    
  },
  components: {
    
  },
  methods: {
    
  },
  filters: {
    
  }
};
</script>
