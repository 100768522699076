<template>
  <div class="filter-content">
    <div class="container">
      <div v-if="near_you_events.length > 0 && category_name == 'ceo' || category_name == 'National' || category_name == 'Festival'">


      <div><h4>Near You: {{near_you_city }}</h4></div><br>


      <template>
        <div class="scroll-wrapper">
          <button class="scroll-button left" @click="scrollLeft" @mouseover="startScroll('left')" @mouseout="stopScroll"><img width="50" height="50" :src="'icons8-back-50.png' | assetPath" alt="back--v1"/></button>
          <!-- <button class="scroll-button left" @click="scrollLeft" @mouseover="startScroll('left')" @mouseout="stopScroll"><img width="50" height="50" :src="'long-arrow-pointing-to-left.png' | assetPath" alt="back--v1"/></button> -->

          
          <div class="scroll-container" ref="scrollContainer">
            <div class="scroll-item" v-for="(event, key) in near_you_events">

           <!---Near You Event Card ------------------------------------------>
            <div :key="key" v-if="event" :class="cardType(key, event.object_type, 'event')" style="width: 100%">
              <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 ceo event-card">
                <div class="content" :class="event.activation_status_display">
                  <div
                    class="pic event-thumb-img event-card-resp"
                    v-lazy:background-image="event.thumbnail_urls.thumb_296"
                    style="height: 278px;width: 200px;padding-bottom: unset"
                  >

                  <div style="float: right;top:52px; position: relative;right: 4px;width: 38px;">
                    <event_follow
                      v-bind:events="near_you_events"
                      v-bind:event="event"
                      v-bind:index="key"
                    ></event_follow>
                  </div>
                 
                   
                    <!--Social Share  plugin -->
                    <div class="dropup homeShareButton"       style="position: absolute; bottom: -210px; left: 7%; transform: translateX(-50%);"
                    >
                        <button
                        class="btn btn-default vue-social-share"
                        id="social-network-share"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style="background: #673ab7;border-radius: 12px"
                      >

                      <img style="filter: invert(1);" width="30px" :src="'share.png' | assetPath" :alt="event.title" /><br>

                      </button>

 

                      <div
                        class="dropdown-menu vue-social-share-items"
                        aria-labelledby="dropdownMenu2"
                        style="width: 180px;"
                      >
                        <li class="dropdown-item">
                          <ShareNetwork
                            network="facebook"
                            :url="
                              socialShareLink('event-detail/' + event.event_id)
                            "
                            :title="event.event_title | socialImgPath"
                            description="{event.formatted_start_date+'-'+event.formatted_start_date}"
                          >
                            <i class="fab fah fa-lg fa-facebook"></i>
                            <span>Share on Facebook</span>
                          </ShareNetwork>
                        </li>
                        <li class="dropdown-item">
                          <ShareNetwork
                            network="twitter"
                            :url="
                              socialShareLink('event-detail/' + event.event_id)
                            "
                            :title="event.event_title"
                            description="{event.formatted_start_date+'-'+event.formatted_start_date}"
                          >
                            <i class="fab fah fa-lg fa-twitter"></i>
                            <span>Share on Twitter</span>
                          </ShareNetwork>
                        </li>
                      </div>
                    </div>




                    <div class="seen-title" style="width: 20px;    margin-left: auto;
                        height: 0px;
                        margin-right: 48px;
                        top: 160px;
                        position: relative;">
                
                      <span class="eye">
                        <img :src="'seen.svg' | assetPath" :alt="event.title" /><br>
                        {{ event.total_views }}
                      </span>
                      <span
                        title="Private Location"
                        v-if="event.type && event.type == 'PrivateLocation'"
                        class="time private-label"
                        >Private</span
                      >
                    </div>

                    <!--Venue AID -->
                    <div class="venue-aid">
                      <span class="" data-toggle="tooltip" :title="getVenueAidTitle(event.venue)" v-if="event.venue.closing_reasons.title !='Normal'" 
                      :class="event.venue.closing_reasons.color">{{ event.venue.closing_reasons.severity.level | checkNullSeverity }} <img width="15" :src="'info.svg' | assetPath"></span>
                    </div>


                    <!--like Event Component -->
                      <like_event
                      v-bind:events="near_you_events"
                      v-bind:event="event"
                      v-bind:index="key"
                    ></like_event>


                    
                  </div>

                  <div
                    v-if="event.activation_status_display"
                    class="event-status-flag activation-status-flag"
                    :class="[ event.activation_status? 'status-bg-'+event.activation_status:'' ]"
                  >
                    <a  href="#">
                      {{ event.activation_status_display }}
                      <img :src="'info.svg' | assetPath" alt />
                    </a>
                  </div>

                  <a
                    v-if="isAuth() && isAdministratorAccount()"
                    :href="adminEditOptionURL('event', event.id)"
                    target="_blank"
                    class="admin-edit-option"
                    style="position: absolute;top: 5px;left: 18px;color: #747474;background-color: #eaeaea;padding: 2px;"
                  >
                    <i class="fa fa-edit"></i>
                  </a>

                  <a
                   v-if="isAuth() && isAdministratorAccount()"
                    @click="updateEvent(event.event_id, 'delete', -1, 'deleted')"
                  class="admin-edit-option"
                    :style="{ 
                      opacity: 1, 
                      position: 'absolute', 
                      top: '1px', 
                      left: '54px', 
                      backgroundColor: '#eaeaea', 
                      padding: '5px', 
                      borderRadius: '4px', 
                      transition: 'opacity 0.3s ease' 
                    }"
                  >
                    <img 
                      :src="'delete-icon.svg' | assetPath" 
                      alt="Delete" 
                      style="width: 20px; height: 20px;" 
                    />
                  </a>

                  <div class="bottom-decription">
                    <h6 >
                      <router-link
                        v-if="isAuth()"
                        class="logged-in title-text-resp"
                        :to="{ path: 'event/' + event.id }"
                        >{{ limitStr(event.title, 26) }}</router-link
                      >
                      <a
                        v-else
                        class="login title-text-resp"
                        @click="loginModal(event.id, '#/event/')"
                        :href="url('event-detail/' + event.id)"
                        @contextmenu="blockRightClick($event)"
                        >{{ limitStr(event.title, 26) }}</a
                      >
                    </h6>
                    <p title="Start & End Dates" class="dates" style="margin-top: -8px;">
                      
                      <template>
                        <div>
                          <template v-if="event.type === 'Recurring'">
                            {{ event.start_date }}
                          </template>
                          <template v-else-if="event.start_date === event.end_date">
                            {{ event.start_date }}
                          </template>
                          <template v-else>
                            {{ event.start_date }} - {{ event.end_date }}
                          </template>
                        </div>
                      </template>

                    </p>
                    <p
                      v-if="event.start_time"
                      class="event-time"
                      title="Start Time"
                    >
                      Start Time: {{ event.start_time }}
                    </p>
                    <p>
                      <router-link
                        v-if="isAuth()"
                        class="flyer-venue-name"
                        :to="{ name: 'venue', params: { id: event.venue.id } }"
                      >
                        {{ limitStr(event.venue.title, 26) }}
                      </router-link>
                      <a
                        v-else
                        @click="loginModal(event.venue_id, '#/venue/')"
                        class="flyer-venue-name"
                        :title="event.venue.title"
                        href="#"
                        >{{ limitStr(event.venue.title, 26) }}</a>
                      
                    </p>
                    <p :title="event.address.full_address">
                      {{ limitStr(event.address.full_address, 26) }}
                    </p>

                    <!-- Tickets -->

                    <div v-if="event && event.object_type !== 'ads' && event.tickets && event.tickets.length > 0">
                    <div class="parallelogram-container">           
                      <!-- Sort and render tickets directly in the v-for loop -->
                    <div v-for="(ticket, index) in event.tickets.slice().sort((a, b) => {
                          const priority = ['Tickets','OrderOnline','FoodMenu', 'Reservation','VIP', 'Suites','Parking','FreeRSVP','Tailgate','Metaverse','Watch','PlaceBet'];
                          return priority.indexOf(a.provider) - priority.indexOf(b.provider);
                        })"
                        :key="index"
                      >
                          <div v-if="ticket.provider_left != null">

                            <div class="parallelogram near_you_right2" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                              <p class="ticket_status_text near-you-ticket-text-resp-left2">
                                <a :href="ticket.url" target="_blank">  {{ ticket.provider.replace(/([A-Z])/g, ' $1').trim() }}
                                </a>
                              </p>
                            </div>
                          </div>

                          <div v-if="event.tickets.length != 1 && ticket.provider_left != null">

                          <div v-if="ticket.provider_right != null">

                            <div class="parallelogram2 near_you_left2" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                              <p class="ticket_status_text2 near-you-ticket-text-resp-right2">
                                <a :href="ticket.url" target="_blank">  {{ ticket.provider.replace(/([A-Z])/g, ' $1').trim() }}
                                </a>
                              </p>
                            </div>
                          </div>
                          </div>

                          <div v-else-if="event.tickets.length === 1 && ticket.provider_left == null">  

                            <div v-if="ticket.provider_right != null">
                              <div class="parallelogram3" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                                <p class="ticket_status_text2 near-you-ticket-text-resp-right2">
                                  <a :href="ticket.url" target="_blank">  {{ ticket.provider.replace(/([A-Z])/g, ' $1').trim() }}
                                  </a>
                                </p>
                              </div>
                            </div>

                          </div>
                          <div v-else-if="event.tickets.length === 2 && event.tickets.some(t => ['EventBrite', 'LiveNation', 'CompanyWebsite', 'Stubhub', 'Ticketmaster'].includes(t.provider))  && ticket.provider_left == null">

                          <div v-if="ticket.provider_right != null">
                            <div class="parallelogram3" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                              <p class="ticket_status_text2 near-you-ticket-text-resp-right2">   
                                <a :href="ticket.url" target="_blank">  {{ ticket.provider.replace(/([A-Z])/g, ' $1').trim() }}
                                </a>
                              </p>
                            </div>        
                          </div>

                          </div>
                          <div v-else>

                          <div  v-if="ticket.provider_right != null">

                        <div class="parallelogram2 near_you_left2" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                          <p class="ticket_status_text2 near-you-ticket-text-resp-right2">
                            <a :href="ticket.url" target="_blank">  {{ ticket.provider.replace(/([A-Z])/g, ' $1').trim() }}
                            </a>
                          </p>
                        </div>
                        </div>

                          </div>


                        </div>
                        </div>
                        </div>

                      <div v-if="event && event.object_type !== 'ads' && event.tickets && event.tickets.length === 1">
                        <div v-for="(ticket, index) in event.tickets.slice().sort((a, b) => {
                          const priority = ['Tickets','OrderOnline','FoodMenu', 'Reservation','VIP', 'Suites','Parking','FreeRSVP','Tailgate','Metaverse','Watch','PlaceBet'];
                          return priority.indexOf(a.provider) - priority.indexOf(b.provider);
                        })"
                        :key="index"
                      >
                          <div v-if="ticket.provider_right == null && ticket.provider_left ==null">
                            <div class="centered-container2 more_info_near_you2">
                            <router-link  :to="{ path: 'event/' + event.event_id }">More Info</router-link>
                          </div>
                          </div>
                        
                        </div>
                      </div>

                        <div v-if="event && event.object_type !== 'ads' && event.tickets && event.tickets.length === 0">

                            <div class="centered-container more_info_near_you2" style="margin-top: 14px;">
                            <router-link  :to="{ path: 'event/' + event.event_id }">More Info</router-link>
                          </div>
                        
                        </div>


                    <!--Private Location  plugin -->
                    <div
                      v-if="
                        event.type &&
                        event.type == 'PrivateLocation' &&
                        isPrivateContacts(event.contacts)
                      "
                      class="dropup private-info"
                    >
                      <button
                        class="btn btn-default notfollow"
                        id="private-contact-info"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        <img
                          width="16"
                          :src="'icons/call.svg' | assetPath"
                          alt="Call"
                        />
                        Contact
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="private-contact-info"
                      >
                        <li>
                          <a href="#"
                            ><strong>Private Event Information</strong></a
                          >
                        </li>
                        <li role="separator" class="divider"></li>
                        <template v-for="(contact, index) in event.contacts">
                          <li
                            v-if="contact.provider == 'private-number'"
                            :key="index"
                          >
                            <a
                              :href="'tel:' + contact.value"
                              class="dropdown-item"
                            >
                              <img
                                width="20"
                                :src="'icons/call.svg' | assetPath"
                                alt="Call"
                              />
                              {{ contact.value }}
                            </a>
                          </li>
                          <li
                            v-if="contact.provider == 'private-email'"
                            :key="index"
                          >
                            <a
                              :href="'mailto:' + contact.value"
                              class="dropdown-item"
                            >
                              <img
                                width="20"
                                :src="'icons/email.svg' | assetPath"
                                alt="Email"
                              />
                              {{ contact.value }}
                            </a>
                          </li>
                          <li
                            v-if="contact.provider == 'private-link'"
                            :key="index"
                          >
                            <a :href="contact.value" class="dropdown-item">
                              <img
                                width="20"
                                :src="'icons/link.svg' | assetPath"
                                alt="URL"
                              />
                              URL
                            </a>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>            
          </div>
          </div>
          <button style="margin-left: 10px;" class="scroll-button right" @click="scrollRight"   @mouseover="startScroll('right')" @mouseout="stopScroll"><img width="50" height="50" :src="'icons8-arrow-50.png' | assetPath" alt="back--v1"/></button>
          <!-- <button style="margin-left: 10px;" class="scroll-button right" @click="scrollRight"   @mouseover="startScroll('right')" @mouseout="stopScroll"><img width="50" height="50" :src="'long-arrow-pointing-to-the-right.png' | assetPath" alt="back--v1"/></button> -->

        </div>
      </template>
      
      </div>
      <br><br>

      <div v-if="category_name == 'ceo' || category_name == 'National' || category_name == 'Festival'"><h4>{{ category_name === 'ceo' ? 'Trending' : category_name }}: World</h4></div><br>


      <div v-if="loadingContent" class="content-loader">
        <content-loader
          width="1490"
          height="475"
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="0" y="20" rx="8" ry="8" width="350" height="350" />
          <rect x="0" y="390" rx="0" ry="0" width="200" height="18" />
          <rect x="0" y="420" rx="0" ry="0" width="120" height="20" />

          <rect x="380" y="20" rx="8" ry="8" width="350" height="350" />
          <rect x="380" y="390" rx="0" ry="0" width="200" height="18" />
          <rect x="380" y="420" rx="0" ry="0" width="120" height="20" />

          <rect x="760" y="20" rx="8" ry="8" width="350" height="350" />
          <rect x="760" y="390" rx="0" ry="0" width="200" height="18" />
          <rect x="760" y="420" rx="0" ry="0" width="120" height="20" />

          <rect x="1140" y="20" rx="8" ry="8" width="350" height="350" />
          <rect x="1140" y="390" rx="0" ry="0" width="200" height="18" />
          <rect x="1140" y="420" rx="0" ry="0" width="120" height="20" />
        </content-loader>
      </div>
      <div v-else class="loaded-content" v-animate-css="animate.fadeIn">



        <div v-if="events.length > 0" >


          <template v-for="(event, key) in events">

          <template v-if="getViewType == 'gride'" >
            <!---Google Ads Card ------------------------------------------>
            <!-- <div v-if="getNumberReminder(key,13)" :class="cardType(key,event.object_type,'google-card')">
            <div  class="col-lg-3 col-md-4 col-sm-6 col-xs-12 ceo google-ads-card adver-card">
              <div class="content">
                <h1>Google Ads</h1>
              </div>
            </div>
            </div>-->

            <!---Google Ads Card ------------------------------------------>
            <!-- <div v-if="getNumberReminder(key,15)" :class="cardType(key,event.object_type,'google-full-width')">
            <div class="col-md-12 col-sm-12 col-xs-12 ceo google-container">
                <h1>Google Ads</h1>
            </div>
            </div>-->

            <!---Ads Card ------------------------------------------>
            <div
              :key="key"
              v-if="event && event.object_type == 'ads'"
              :class="cardType(key, event.object_typem, 'ads')"
              class="event-card-wraper">
              <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 ceo adver-card">
                <div class="content">
                  <a
                    :href="event.link ? event.link : 'javascript:void(0)'"
                    :target="[event.link ? '_blank' : '']"
                  >
                    <div
                      class="pic"
                      v-lazy:background-image="event.image"
                    ></div>
                  </a>

                  <a
                    v-if="isAdministratorAccount()"
                    :href="adminEditOptionURL('ads', event.id)"
                    target="_blank"
                    class="admin-edit-option"
                  >
                    <i class="fa fa-edit"></i>
                  </a>

                  <div class="bottom-decription">
                    <h6>
                      <a v-if="event.link" target="_blank" :href="event.link">{{
                        event.title
                      }}</a>
                      <a v-else href="javascript:void(0)">{{ event.title }}</a>
                    </h6>

                    <p v-if="event.release_date" class="dates">
                      {{ event.release_date }}
                    </p>
                    <br />
                    <!-- <div v-if="event.link" class="hashtag-wrapper">
                      <a :href="event.link" target="_blank" class="visiteSite"
                        ><i class="fa fa-external-link-alt"></i>
                        <span> Visit Site </span>
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="container23">
                    <div class="parallelogramadleft">
                      <p class="ticket_status_text near-you-ticket-text-resp-left" style="margin-top: 1px;">
                        <a>Ad</a>
                      </p>
                    </div>
                    <div class="parallelogramright" >
                      <p class="ticket_status_text2 near-you-ticket-text-resp-right2" style="margin-top: 1px;font-size: medium;">
                        <a :href="event.ad_url"  target="_blank" >{{ getAdStatusText(event.ad_status_option) }}</a>
                      </p>
                    </div>
                  </div>
              </div>

            </div>




            <!---Event Card ------------------------------------------>
            <div :key="key" v-if="event && event.object_type == 'event'" :class="cardType(key, event.object_type, 'event')" class="event-card-wraper">
              <div  class="col-lg-3 col-md-3 col-sm-3 col-xs-6 ceo event-card event-card-mobile-resp-trending">
                <div class="content" :class="event.activation_status_display">
                  <div
                  class="pic event-thumb-img"
                    v-lazy:background-image="event.thumbnail_urls.thumb_296"
                  >

                  <div style="float: right;top:52px; position: relative;right: 4px;width: 38px;">
                    <event_follow
                      v-bind:events="events"
                      v-bind:event="event"
                      v-bind:index="key"
                    ></event_follow>
                  </div>
                 
                    <!-- <router-link
                      v-if="isAuth()"
                      class="flyer-venue-name"
                      :to="{ path: 'event/' + event.event_id }"
                    ></router-link>
                    <a
                      v-else
                      @click="loginModal(event.event_id, '#/event/')"
                      class="flyer-venue-name"
                      :href="url('event-detail/' + event.event_id)"
                      @contextmenu="blockRightClick($event)"
                    ></a> -->





                    <!--Social Share  plugin -->
                    <div class="dropup homeShareButton"       style="position: absolute; bottom: -244px; left: 7%; transform: translateX(-50%);"
                    >
                        <button
                        class="btn btn-default vue-social-share"
                        id="social-network-share"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style="background: #673ab7;border-radius: 12px"
                      >

                      <img style="filter: invert(1);" width="30px" :src="'share.png' | assetPath" :alt="event.title" /><br>

                      </button>

 

                      <div
                        class="dropdown-menu vue-social-share-items"
                        aria-labelledby="dropdownMenu2"
                        style="width: 180px;"
                      >
                        <li class="dropdown-item">
                          <ShareNetwork
                            network="facebook"
                            :url="
                              socialShareLink('event-detail/' + event.event_id)
                            "
                            :title="event.event_title | socialImgPath"
                            description="{event.formatted_start_date+'-'+event.formatted_start_date}"
                          >
                            <i class="fab fah fa-lg fa-facebook"></i>
                            <span>Share on Facebook</span>
                          </ShareNetwork>
                        </li>
                        <li class="dropdown-item">
                          <ShareNetwork
                            network="twitter"
                            :url="
                              socialShareLink('event-detail/' + event.event_id)
                            "
                            :title="event.event_title"
                            description="{event.formatted_start_date+'-'+event.formatted_start_date}"
                          >
                            <i class="fab fah fa-lg fa-twitter"></i>
                            <span>Share on Twitter</span>
                          </ShareNetwork>
                        </li>
                      </div>
                    </div>




                    <div class="seen-title" style="width: 20px;    margin-left: auto;
                    height: 0px;
    margin-right: 48px;
    top: 196px;
    position: relative;">
 
                      <span class="eye"                   >
                        <img :src="'seen.svg' | assetPath" :alt="event.title" /><br>
                        {{ event.total_views }}
                      </span>
                      <span
                        title="Private Location"
                        v-if="event.type && event.type == 'PrivateLocation'"
                        class="time private-label"
                        >Private</span
                      >
                    </div>

                    <!--Venue AID -->
                    <div class="venue-aid">
                      <span class="" data-toggle="tooltip" :title="getVenueAidTitle(event.venue)" v-if="event.venue.closing_reasons.title !='Normal'" 
                      :class="event.venue.closing_reasons.color">{{ event.venue.closing_reasons.severity.level | checkNullSeverity }} <img width="15" :src="'info.svg' | assetPath"></span>
                    </div>


                    <!--like Event Component -->
                      <like_event
                      v-bind:events="events"
                      v-bind:event="event"
                      v-bind:index="key"
                    ></like_event>


                    
                  </div>

                  <div
                    v-if="event.activation_status_display"
                    class="event-status-flag activation-status-flag"
                    :class="[ event.activation_status? 'status-bg-'+event.activation_status:'' ]"
                  >
                    <a  href="#">
                      {{ event.activation_status_display }}
                      <img :src="'info.svg' | assetPath" alt />
                    </a>
                  </div>

                  <a
                    v-if="isAuth() && isAdministratorAccount()"
                    :href="adminEditOptionURL('event', event.event_id)"
                    target="_blank"
                    class="admin-edit-option"
                  >
                    <i class="fa fa-edit"></i>
                  </a>

                  <a
                   v-if="isAuth() && isAdministratorAccount()"
    @click="updateEvent(event.event_id, 'delete', -1, 'deleted')"
   class="admin-edit-option"
    :style="{ 
      opacity: 1, 
      position: 'absolute', 
      top: '10px', 
      left: '54px', 
      backgroundColor: '#eaeaea', 
      padding: '5px', 
      borderRadius: '4px', 
      transition: 'opacity 0.3s ease' 
    }"
  >
    <img 
      :src="'delete-icon.svg' | assetPath" 
      alt="Delete" 
      style="width: 20px; height: 20px;" 
    />
  </a>




                  <div class="bottom-decription">
                    <h6>
                      <router-link
                        v-if="isAuth()"
                        class="logged-in"
                        :to="{ path: 'event/' + event.event_id }"
                        >{{ limitStr(event.event_title, 26) }}</router-link
                      >
                      <a
                        v-else
                        class="login"
                        @click="loginModal(event.event_id, '#/event/')"
                        :href="url('event-detail/' + event.event_id)"
                        @contextmenu="blockRightClick($event)"
                        >{{ limitStr(event.event_title, 26) }}</a
                      >
                    </h6>
                    <p title="Start & End Dates" class="dates">
                      
                      <template>
                        <div>
                          <template v-if="event.type === 'Recurring'">
                            {{ event.start_date }}
                          </template>
                          <template v-else-if="event.start_date === event.end_date">
                            {{ event.start_date }}
                          </template>
                          <template v-else>
                            {{ event.start_date }} - {{ event.end_date }}
                          </template>
                        </div>
                      </template>
                      
                    </p>
                    <p
                      v-if="event.start_time"
                      class="event-time"
                      title="Start Time"
                    >
                      Start Time: {{ event.start_time }}
                    </p>
                    <p>
                      <router-link
                        v-if="isAuth()"
                        class="venue-link"
                        :to="{ name: 'venue', params: { id: event.venue_id } }"
                      >
                        {{ limitStr(event.venue_title, 26) }}
                      </router-link>
                      <a
                        v-else
                        @click="loginModal(event.venue_id, '#/venue/')"
                        class="flyer-venue-name"
                        :title="event.venue_title"
                        href="#"
                        >{{ limitStr(event.venue_title, 26) }}</a
                      >
                    </p>
                    <p :title="event.full_address">
                      {{ limitStr(event.full_address, 26) }}
                    </p>
                    

                    <!-- Tickets -->

                    <div v-if="event && event.object_type !== 'ads' && event.tickets && event.tickets.length > 0">
                    <div class="parallelogram-container">           
                      <!-- Sort and render tickets directly in the v-for loop -->
                    <div v-for="(ticket, index) in event.tickets.slice().sort((a, b) => {
                          const priority = ['Tickets','OrderOnline','FoodMenu', 'Reservation','VIP', 'Suites','Parking','FreeRSVP','Tailgate','Metaverse','Watch','PlaceBet'];
                          return priority.indexOf(a.provider) - priority.indexOf(b.provider);
                        })"
                        :key="index"
                      >
                          <div v-if="ticket.provider_left != null">

                            <div class="parallelogram near_you_right" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                              <p class="ticket_status_text near-you-ticket-text-resp-left">
                                <a :href="ticket.url" target="_blank">  {{ ticket.provider.replace(/([A-Z])/g, ' $1').trim() }}
                                </a>
                              </p>
                            </div>
                          </div>

                          <div v-if="event.tickets.length != 1 && ticket.provider_left != null">

                          <div v-if="ticket.provider_right != null">

                            <div class="parallelogram2 near_you_left" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                              <p class="ticket_status_text2">
                                <a :href="ticket.url" target="_blank">  {{ ticket.provider.replace(/([A-Z])/g, ' $1').trim() }}
                                </a>
                              </p>
                            </div>
                          </div>
                          </div>

                          <div v-else-if="event.tickets.length === 1 && ticket.provider_left == null">  

                            <div v-if="ticket.provider_right != null">
                              <div class="parallelogram3" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                                <p class="ticket_status_text2">
                                  <a :href="ticket.url" target="_blank">  {{ ticket.provider.replace(/([A-Z])/g, ' $1').trim() }}
                                  </a>
                                </p>
                              </div>
                            </div>

                          </div>
                          <div v-else-if="event.tickets.length === 2 && event.tickets.some(t => ['EventBrite', 'LiveNation', 'CompanyWebsite', 'Stubhub', 'Ticketmaster'].includes(t.provider))  && ticket.provider_left == null">

                          <div v-if="ticket.provider_right != null">
                            <div class="parallelogram3" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                              <p class="ticket_status_text2">   
                                <a :href="ticket.url" target="_blank">  {{ ticket.provider.replace(/([A-Z])/g, ' $1').trim() }}
                                </a>
                              </p>
                            </div>        
                          </div>

                          </div>
                          <div v-else>

                          <div  v-if="ticket.provider_right != null">

                        <div class="parallelogram2 near_you_left" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                          <p class="ticket_status_text2 near-you-ticket-text-resp-right">
                            <a :href="ticket.url" target="_blank">  {{ ticket.provider.replace(/([A-Z])/g, ' $1').trim() }}
                            </a>
                          </p>
                        </div>
                        </div>

                          </div>


                        </div>
                        </div>
                        </div>

                      <div v-if="event && event.object_type !== 'ads' && event.tickets && event.tickets.length === 1">
                        <div v-for="(ticket, index) in event.tickets.slice().sort((a, b) => {
                          const priority = ['Tickets','OrderOnline','FoodMenu', 'Reservation','VIP', 'Suites','Parking','FreeRSVP','Tailgate','Metaverse','Watch','PlaceBet'];
                          return priority.indexOf(a.provider) - priority.indexOf(b.provider);
                        })"
                        :key="index"
                      >
                          <div v-if="ticket.provider_right == null && ticket.provider_left ==null">
                            <div class="centered-container2 more_info_near_you">
                            <router-link  :to="{ path: 'event/' + event.event_id }">More Info</router-link>
                          </div>
                          </div>
                        
                        </div>
                      </div>

                        <div v-if="event && event.object_type !== 'ads' && event.tickets && event.tickets.length === 0">

                            <div class="centered-container more_info_near_you" style="margin-top: 14px;">
                            <router-link  :to="{ path: 'event/' + event.event_id }">More Info</router-link>
                          </div>
                        
                        </div>


                    <!--Private Location  plugin -->
                    <div
                      v-if="
                        event.type &&
                        event.type == 'PrivateLocation' &&
                        isPrivateContacts(event.contacts)
                      "
                      class="dropup private-info"
                    >
                      <button
                        class="btn btn-default notfollow"
                        id="private-contact-info"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        <img
                          width="16"
                          :src="'icons/call.svg' | assetPath"
                          alt="Call"
                        />
                        Contact
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="private-contact-info"
                      >
                        <li>
                          <a href="#"
                            ><strong>Private Event Information</strong></a
                          >
                        </li>
                        <li role="separator" class="divider"></li>
                        <template v-for="(contact, index) in event.contacts">
                          <li
                            v-if="contact.provider == 'private-number'"
                            :key="index"
                          >
                            <a
                              :href="'tel:' + contact.value"
                              class="dropdown-item"
                            >
                              <img
                                width="20"
                                :src="'icons/call.svg' | assetPath"
                                alt="Call"
                              />
                              {{ contact.value }}
                            </a>
                          </li>
                          <li
                            v-if="contact.provider == 'private-email'"
                            :key="index"
                          >
                            <a
                              :href="'mailto:' + contact.value"
                              class="dropdown-item"
                            >
                              <img
                                width="20"
                                :src="'icons/email.svg' | assetPath"
                                alt="Email"
                              />
                              {{ contact.value }}
                            </a>
                          </li>
                          <li
                            v-if="contact.provider == 'private-link'"
                            :key="index"
                          >
                            <a :href="contact.value" class="dropdown-item">
                              <img
                                width="20"
                                :src="'icons/link.svg' | assetPath"
                                alt="URL"
                              />
                              URL
                            </a>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </template>

            <template v-else>
              <template v-if="event && event.object_type == 'event'">
                <!---Event List Card ------------------------------------------>
                <div class="event-card-list-wraper mt-5"  :class="event.activation_status_display">
                  <div class="row">
                      <div class="col-lg-3 col-md-3 banner-image p-0">
                          <img :src="event.image" class="card-img-top h-100 img-responsive" alt="...">
                            <!--Venue AID -->
                          <div class="venue-aid">
                            <span class="" data-toggle="tooltip" :title="getVenueAidTitle(event.venue)" v-if="event.venue.closing_reasons.title !='Normal'" 
                            :class="event.venue.closing_reasons.color">{{ event.venue.closing_reasons.severity.level | checkNullSeverity }} <img width="15" :src="'info.svg' | assetPath"></span>
                          </div>

                          <div
                            v-if="event.activation_status_display"
                            class="event-status-flag activation-status-flag"
                            :class="[ event.activation_status? 'status-bg-'+event.activation_status:'' ]"
                          >
                            <a  href="#">
                              {{ event.activation_status_display }}
                              <img :src="'info.svg' | assetPath" alt />
                            </a>
                          </div>

                          <a v-if="isAdministratorAccount()" :href="adminEditOptionURL('event', event.event_id)"
                            target="_blank" class="admin-edit-option"><i class="fa fa-edit"></i>
                          </a>
                          
                      </div>
                      
                      <div class="col-lg-5 col-md-5">
                        <div class="card-body" style="padding: 20px;">
                            <h6><span class="event-category text-purple">{{event.category}}</span></h6>

                        <div class="event-category">
                          <span
                            title="Private Location"
                            v-if="event.type && event.type == 'PrivateLocation'"
                            class="time private-label"
                            >Private Event</span>
                        </div>
                        <p title="Start & End Dates" class="dates">
                          
                          <template v-if="event.type === 'Recurring'">
                            {{ formatDate(event.start_date) }}
                          </template>
                          <template v-else>
                            {{ formatDateRange(event.start_date, event.end_date) }}
                          </template>
                          
                        </p>
                        <p
                          v-if="event.start_time"
                          class="event-time"
                          title="Start Time"
                        >
                          Start Time: {{ event.start_time }}
                        </p>

                        <h6>
                              <router-link v-if="isAuth()" class="logged-in" :to="{ path: 'event/' + event.event_id }">
                                <span class="event-category" style="line-height: unset; color: black;">{{ event.event_title }} </span>
                              </router-link>
                              <a v-else class="login" @click="loginModal(event.event_id, '#/event/')" :href="url('event-detail/' + event.event_id)"
                                @contextmenu="blockRightClick($event)"><span class="event-category" style="line-height: unset; color: black;">{{ event.event_title }}</span></a>
                            </h6>
                            <p>
                            <router-link
                              v-if="isAuth()" 
                              class="venue-link text-blue"
                              :to="{ name: 'venue', params: { id: event.venue_id } }"
                            >
                              {{ limitStr(event.venue_title, 26) }}
                            </router-link>
                            <a
                              v-else
                              @click="loginModal(event.venue_id, '#/venue/')"
                              class="flyer-venue-name text-blue"
                              :title="event.venue_title"
                              href="#"
                              >{{ limitStr(event.venue_title, 26) }}</a
                            >
                          </p>

                              <p :title="event.full_address">
                                {{ event.full_address }}
                              </p>

                              <div >
                                
                                <!-- Tickets -->
            
                                <div v-if="event && event.object_type !== 'ads' && event.tickets && event.tickets.length > 0">
                                <div class="parallelogram-container">           
                                  <!-- Sort and render tickets directly in the v-for loop -->
                                <div v-for="(ticket, index) in event.tickets.slice().sort((a, b) => {
                                      const priority = ['Tickets','OrderOnline','FoodMenu', 'Reservation','VIP', 'Suites','Parking','FreeRSVP','Tailgate','Metaverse','Watch','PlaceBet'];
                                      return priority.indexOf(a.provider) - priority.indexOf(b.provider);
                                    })"
                                    :key="index"
                                  >
                                      <div v-if="ticket.provider_left != null">
            
                                        <div class="parallelogram" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                                          <p class="ticket_status_text">
                                            <a :href="ticket.url" target="_blank">  {{ ticket.provider.replace(/([A-Z])/g, ' $1').trim() }}
                                            </a>
                                          </p>
                                        </div>
                                      </div>
            
                                      <div v-if="event.tickets.length != 1 && ticket.provider_left != null">
            
                                      <div v-if="ticket.provider_right != null">
            
                                        <div class="parallelogram2" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                                          <p class="ticket_status_text2">
                                            <a :href="ticket.url" target="_blank">  {{ ticket.provider.replace(/([A-Z])/g, ' $1').trim() }}
                                            </a>
                                          </p>
                                        </div>
                                      </div>
                                      </div>
            
                                      <div v-else-if="event.tickets.length === 1 && ticket.provider_left == null">  
            
                                        <div v-if="ticket.provider_right != null">
                                          <div class="parallelogram3" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                                            <p class="ticket_status_text2">
                                              <a :href="ticket.url" target="_blank">  {{ ticket.provider.replace(/([A-Z])/g, ' $1').trim() }}
                                              </a>
                                            </p>
                                          </div>
                                        </div>
            
                                      </div>
                                      <div v-else-if="event.tickets.length === 2 && event.tickets.some(t => ['EventBrite', 'LiveNation', 'CompanyWebsite', 'Stubhub', 'Ticketmaster'].includes(t.provider))  && ticket.provider_left == null">
            
                                      <div v-if="ticket.provider_right != null">
                                        <div class="parallelogram3" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                                          <p class="ticket_status_text2">   
                                            <a :href="ticket.url" target="_blank">  {{ ticket.provider.replace(/([A-Z])/g, ' $1').trim() }}
                                            </a>
                                          </p>
                                        </div>        
                                      </div>
            
                                      </div>
                                      <div v-else>
            
                                      <div  v-if="ticket.provider_right != null">
            
                                    <div class="parallelogram2" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                                      <p class="ticket_status_text2">
                                        <a :href="ticket.url" target="_blank">  {{ ticket.provider.replace(/([A-Z])/g, ' $1').trim() }}
                                        </a>
                                      </p>
                                    </div>
                                    </div>
            
                                      </div>
            
            
                                    </div>
                                    </div>
                                    </div>
            
                                  <div v-if="event && event.object_type !== 'ads' && event.tickets && event.tickets.length === 1">
                                    <div v-for="(ticket, index) in event.tickets.slice().sort((a, b) => {
                                      const priority = ['Tickets','OrderOnline','FoodMenu', 'Reservation','VIP', 'Suites','Parking','FreeRSVP','Tailgate','Metaverse','Watch','PlaceBet'];
                                      return priority.indexOf(a.provider) - priority.indexOf(b.provider);
                                    })"
                                    :key="index"
                                  >
                                      <div v-if="ticket.provider_right == null && ticket.provider_left ==null">
                                        <div class="centered-container2">
                                        <router-link  :to="{ path: 'event/' + event.event_id }">More Info</router-link>
                                      </div>
                                      </div>
                                    
                                    </div>
                                  </div>
            
                                    <div v-if="event && event.object_type !== 'ads' && event.tickets && event.tickets.length === 0">
            
                                        <div class="centered-container" style="margin-top: 14px;">
                                        <router-link  :to="{ path: 'event/' + event.event_id }">More Info</router-link>
                                      </div>
                                    
                                    </div>


                                          </div>
      
                              </div>
                      </div>

                      <div class="col-lg-4 col-md-3">
                        <div class="card-button" style="width: 140px;">

                                <event_follow
                                  v-bind:events="events"
                                  v-bind:event="event"
                                  v-bind:index="key"
                                ></event_follow>



                                <!--like Event Component -->
                                <div style="margin-top: 15px;">
                                  <like_event
                                  style="margin-top: 45px; width: 132px;"
                                  v-bind:events="events"
                                  v-bind:event="event"
                                  v-bind:index="key"
                                ></like_event>

                                </div>





                            </div> <br>   

                      </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="event-card-list-wraper mt-5"  :class="event.activation_status_display">
                  <div class="row">
                      <div class="col-lg-2 col-md-2 banner-image p-0">
                          <a :href="event.link ? event.link : 'javascript:void(0)'" :target="[event.link ? '_blank' : '']">
                            <img :src="event.image" class="card-img-top h-100 img-responsive" alt="...">
                          </a>
                      </div>
                      <div class="col-lg-6 col-md-6">
                          <div class="card-body">
                            <div class="bottom-decription">
                              <h6>
                                <a v-if="event.link" target="_blank" :href="event.link">{{
                                  event.title
                                }}</a>
                                <a v-else href="javascript:void(0)">{{ event.title }}</a>
                              </h6>

                              <p v-if="event.release_date" class="dates">
                                {{ event.release_date }}
                              </p>
                              <br />
                              <div v-if="event.link" class="hashtag-wrapper">
                                <a :href="event.link" target="_blank" class="visiteSite"
                                  ><i class="fa fa-external-link-alt"></i>
                                  <span> Visit Site </span>
                                </a>
                              </div>

                              <div class="container23" style="justify-content: normal;">
                                <div class="parallelogramadleft">
                                  <p class="ticket_status_text" style="margin-top: 1px;">
                                    <a>Ad</a>
                                  </p>
                                </div>
                                <div class="parallelogramright" >
                                  <p class="ticket_status_text2" style="margin-top: 1px">
                                    <a :href="event.ad_url"  target="_blank" >{{ getAdStatusText(event.ad_status_option) }}</a>
                                  </p>
                                </div>
                              </div>


                            </div>
                              <a v-if="isAdministratorAccount()" :href="adminEditOptionURL('ads', event.id)"
                                target="_blank" class="admin-edit-option"><i class="fa fa-edit"></i>
                              </a>   
                          </div>
                      </div>
                      <div class="col-lg-3 col-md-3" style="margin-top: 80px;">


                      </div>
                  </div>
                </div>
              </template>
            </template>
          </template>
          <div v-if="loadingMoreContent" class="content-loader">
            <img :src="'icons/vgps-loader.gif' | assetPath" alt="Loading Content" />
          </div>
        </div>


        

        <!--Show more event --->
        <!-- <div v-if="showLoadMore && !processing " class="row">
          <div class="col-md-12">
            <infinite-loading spinner="waveDots" @infinite="infiniteHandler"></infinite-loading>
          </div>
        </div> -->

        <div v-if="showLoadMore && processing == false" class="row text-center paginationWrapper">
          <div class="col-md-12">
            <button @click="loadMore" class="see-more"
              >See more events</button>
          </div>
          <!-- <paginate
            v-model="page"
            :page-count="total_page"
            :page-range="page_limit"
            :margin-pages="2"
            :click-handler="getPaginatedData"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'pagination'"
            :page-class="'page-item'"
          >
          </paginate> -->
        </div>

        <div
          v-if="events.length == 0 && !loadingContent"
          class="result-not-found"
        >
          <a><i class="fa fa-search"></i></a>
          <p class="text-center">
            Sorry, we do not have any events for this search for now.<br />
            Maybe give one of these a try?<br />
            <strong>Festivals</strong> or <strong>National</strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../../app";
import LikeEvent from "../global/like .vue";
import Event_Follow from "../global/event-follow-button.vue";
import EventDetailLikeButton from "../../components/global/detail-page-like.vue";
import ResultNotFound from "./../../views/user/partials/result-not-found";
import helpers from "../../mixin/helpers";
import { mapFields } from "vuex-map-fields";
import InfiniteLoading from "vue-infinite-loading";

import { ContentLoader, FacebookLoader } from "vue-content-loader";
import { event } from "jquery";

export default {
  data() {
    return {
      // hovering: {},

      items: [
        { id: 1, name: 'Item 1' },
        { id: 2, name: 'Item 2' },
        { id: 3, name: 'Item 3' },
        { id: 4, name: 'Item 4' },
        { id: 5, name: 'Item 5' },
        { id: 6, name: 'Item 6' },
        { id: 7, name: 'Item 7' },
      ],
      dropdownVisible: false,
      scrollInterval: null,
      near_you_city:null,
      category_name:null,

      page: 1,
      processing:false,
      total_page: 0,
      page_limit: 3,
      viewType: "eventType",
      loadingContent: false,
      loadingMoreContent: false,
      Searching: false,
      showLoadMore: true,
      events: [],
      near_you_events:[],
      shouldShowTestingText: true,
      heartImage: "heart.svg",
      customAdCounter: 0,
      private_location: {
        email: "",
        phone: "",
        link: "",
      },
      providerColors: {
        Tickets: 'black',
        Reservation: '#33aaec',
        Parking: 'black',
        OrderOnline: '#ef1718',
        FoodMenu: '#ef1718',
        VIP: 'purple',
        Suites: 'green',
        Metaverse: '#28a9ed',
        FreeRSVP: '#28a9ed',
        Tailgate: '#28a9ed',
        Watch: 'black',
        PlaceBet : 'black',
        DrinkMenu:'red',
        ShopNow:'green',

      },
      adStatusOptions: {
        '' : 'Select One',
        '1' : 'Learn Now', 
        '2' : 'Download',
        '3' : 'Register',
        '4' : 'Buy Now',
        '5' : 'On Sale',
        '6': 'Holiday',
        '7' : 'Vote Now',
        '8' : 'Ending Soon',
        '9' : 'Tickets',
        '10' : 'Ads Wanted',
        '11' : 'Visit Site',
        '12' : 'Movie',
        '13' : 'Sneakers',
        '14' : 'Watch',
        '15' : 'Live Stream',
        '16' : 'Coming Soon',
        '16' : 'TV Show',
      },
    };
    
  },
  mixins: [helpers],
  computed: {
    eyeStyle() {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 775 && screenWidth <= 999) {
        return { marginLeft: '258px' };
      }
      else if (screenWidth >= 1000 && screenWidth <= 1199) {
        return { marginLeft: '206px' };
      }
      else if (screenWidth >= 1200 && screenWidth <= 1299) {
        return { marginLeft: '190px' };
      }
      else if (screenWidth >= 1300){
        return { marginLeft: '206px' };
      }


    },
    getAdStatusText() {
      return (option) => this.adStatusOptions[option] || 'Select One';
    },
    getViewType() {
      return this.$store.getters.getViewType;
    },
    ...mapFields(["trendingEventList"]),
  },
  created() {
    var vm = this;
    var filtersData = this.$session.get("global_filters");

    bus.$on("citySearchFilter", () => {
      vm.filters.offset = 0;
      vm.filters.limit = 12;
      vm.getEvents("advanceFilter", false);
    });

    bus.$on("advanceFilter", () => {
      vm.filters.offset = 0;
      vm.filters.limit = 12;
      vm.getEvents("advanceFilter", false);
    });

    bus.$on("clearAdvanceFilter", () => {
      vm.filters.offset = 0;
      vm.filters.limit = 12;
      this.filters.search = "";
      this.getEvents("advanceFilter", false);
    });

    bus.$on("search_event_title", (keyword) => {
      vm.filters.offset = 0;
      vm.filters.limit = 12;
      this.filters.search = keyword;
      this.scrollToDiv("filter-id");
      this.getEvents("", false);
    });

    bus.$on("filterSelection", () => {
// debugger
      vm.filters.offset = 0;
      vm.filters.limit = 12;
      vm.getEvents("filterSelection", false);

      vm.categoryName(vm.filters.category)

      console.log('categorey',vm.filters.category)
      var categ = vm.filters.category


      vm.getNearYouData();


      // if(categ === 'National' ){
      //   console.log('categorey2',vm.filters.category)

      //   var isLiveLocation = this.$session.get("liveLocation");
      // var latitude = "";
      // var longitude = "";
      // if (isLiveLocation) {
      //   latitude = this.$session.get("live_location_lat");
      //   longitude = this.$session.get("live_location_long");
      // } else {
      //   latitude = this.$session.get("default_location_lat");
      //   longitude = this.$session.get("default_location_long");
      // }
      // var pos = {
      //   lat: latitude,
      //   lng: longitude,
      // };

      // var action = 'null'
      // vm.getEventsAPI(action, pos, vm)


      // }
      // else if(categ === 'ceo'){
      //   console.log('categorey2',vm.filters.category)

      //   var isLiveLocation = this.$session.get("liveLocation");
      // var latitude = "";
      // var longitude = "";
      // if (isLiveLocation) {
      //   latitude = this.$session.get("live_location_lat");
      //   longitude = this.$session.get("live_location_long");
      // } else {
      //   latitude = this.$session.get("default_location_lat");
      //   longitude = this.$session.get("default_location_long");
      // }
      // var pos = {
      //   lat: latitude,
      //   lng: longitude,
      // };

      // var action = 'null'
      // vm.getEventsAPI(action, pos, vm)


      // }
      // else if(categ === 'Festival'){
      //   console.log('categorey2',vm.filters.category)

      //   var isLiveLocation = this.$session.get("liveLocation");
      // var latitude = "";
      // var longitude = "";
      // if (isLiveLocation) {
      //   latitude = this.$session.get("live_location_lat");
      //   longitude = this.$session.get("live_location_long");
      // } else {
      //   latitude = this.$session.get("default_location_lat");
      //   longitude = this.$session.get("default_location_long");
      // }
      // var pos = {
      //   lat: latitude,
      //   lng: longitude,
      // };

      // var action = 'null'
      // vm.getEventsAPI(action, pos, vm)


      // }
      // else{
      //   // console.log('categorey2',vm.filters.category)

      // vm.filters.category = null;
      // var isLiveLocation = this.$session.get("liveLocation");
      // var latitude = "";
      // var longitude = "";
      // if (isLiveLocation) {
      //   latitude = this.$session.get("live_location_lat");
      //   longitude = this.$session.get("live_location_long");
      // } else {
      //   latitude = this.$session.get("default_location_lat");
      //   longitude = this.$session.get("default_location_long");
      // }
      // var pos = {
      //   lat: '99999999999999999990',
      //   lng: '9999999999999999999990',
      // };

      // var action = 'null'
      // vm.getEventsAPI(action, pos, vm)


      // }
      
      
    });

    bus.$on("updateDateValues", () => {
      vm.filters.offset = 0;
      vm.filters.limit = 12;
      this.getEvents("updateDateValues");
    });

    bus.$on("updateLocation", (city) => {
      debugger
      vm.filters.offset = 0;
      vm.filters.limit = 12;
      this.scrollToDiv("filter-id");
      this.getEvents("updateLocation", false);
      var isLiveLocation = this.$session.get("liveLocation");
      var latitude = "";
      var longitude = "";
      if (isLiveLocation) {
        latitude = this.$session.get("live_location_lat");
        longitude = this.$session.get("live_location_long");
      } else {
        latitude = this.$session.get("default_location_lat");
        longitude = this.$session.get("default_location_long");
      }
      var pos = {
        lat: latitude,
        lng: longitude,
      };

      var action = 'null'
      vm.getEventsAPI(action, pos, vm)
      
    });

    bus.$on("citySearchFilter", (city) => {
      vm.filters.offset = 0;
      vm.filters.limit = 12;
      this.getEvents("updateLocation", false);
      this.getEventsAPI();

    });

    bus.$on("updateSessionFilters", (city) => {
      this.getEvents("updateLocation", false);
      this.getEventsAPI();
    });

    bus.$on("getEventsByCity", (city) => {
      vm.filters.offset = 0;
      vm.filters.limit = 12;
      this.getEvents("updateLocation", false);
      this.getEventsAPI();

    });

    bus.$on("hashRedirect", (query) => {
      this.filters.search = query.q;
      this.filters.category = "all";
      this.getEvents("hash_search");
      
    });

    bus.$on("hashtag", (category, hashTag, type) => {
      this.filters.category = category;
      this.filters.event_theme = "";
      this.scrollToDiv("filter-id");
      // this.getEvents("");
    });

    bus.$on("topSearchhashtag", (hashtag) => {
      vm.filters.search = hashtag;
      vm.filters.offset = 0;
      vm.filters.limit = 12;
      this.scrollToDiv("filter-id");
      this.getEvents("");
    });

    this.filters.search = this.hashtag ? this.hashtag : "";
  },
  beforeUpdate() {},
  components: {
    ContentLoader,
    FacebookLoader,
    like_event: LikeEvent,
    event_follow: Event_Follow,
    result_not_found: ResultNotFound,
    event_detail_like_button: EventDetailLikeButton,
    InfiniteLoading,
  },
  props: ["filters", "hashtag", "event"], // Ensure "event" prop is properly defined
  mounted() {
    this.getEvents("default");

    this.getNearYouData()

    var vm = this;

   

    // this.getEventsAPI();

  },
//   watch: {
//   hovering: {
//     handler(newVal) {
//       console.log('Hovering state changed:', newVal);
//     },
//     deep: true,
//   },
// },
  methods: {
    					
    handleMouseover(eventId) {
    if (eventId) {
      console.log('Mouseover:', eventId);
      this.$set(this.hovering, eventId, true); // Ensure reactivity
    } else {
      console.warn('Mouseover eventId is undefined');
    }
  },
  handleMouseleave(eventId) {
    if (eventId) {
      console.log('Mouseleave:', eventId);
      this.$set(this.hovering, eventId, false); // Ensure reactivity
    } else {
      console.warn('Mouseleave eventId is undefined');
    }
  },
    updateEvent: function(id,status,status_value,message) {

      console.log('aaaaaaaaaasdas')

          var vm = this;
          var data = new FormData();
          data.append("event_id", id);
          data.append("status", status_value);


          if (vm.processing === true) {
              return false;
          }

          sweetalert2.fire({
              title: 'Are you sure?',
              text: 'You want to '+status+' this event?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes'
          }).then((result) => {
              if (result.value) {

              vm.processing = true;
              
              axios.post("admin/event/update", data)
                      .then(function (response) {
                          vm.status = message;
                          vm.status_value = status_value;
                          vm.processing = false;
                          // sweetalert2.fire(
                          //         '',
                          //         'Event '+message+' successfully',
                          //         'success'
                          // )

                          var element =  $('#event-detail-row-'+id);
                          element.addClass('animated bounceOutRight faster')
                          vm.processing = false;
                          setTimeout(function(){
                              element.remove();
                          },1000);

                          //window.location = window.location.href;

                      })
                      .catch(function (errors) {
                          sweetalert2.fire(
                                  '',
                                  'Something went wrong.',
                                  'fail'
                          )
                          vm.processing = false;
                      });

               this.getEvents("default");


          }
          })


      },
      deleteEvent: function(id) {

      var vm = this;
      var data = new FormData();
      data.append("event_id", id);
      data.append("status", -1);
      data.append("content_type", 'event');


      if (vm.processing === true) {
          return false;
      }

      sweetalert2.fire({
          title: 'Are you sure?',
          text: 'You want to permanently delete this event?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Delete it!'
      }).then((result) => {
          if (result.value) {

          vm.processing = true;
          axios.post("admin/event/delete", data)
                  .then(function (response) {

                      var element =  $('#event-detail-row-'+id);
                      element.addClass('animated bounceOutRight faster')
                      vm.processing = false;
                      setTimeout(function(){
                          element.remove();
                      },1000);
                  })
                  .catch(function (errors) {
                      sweetalert2.fire(
                              '',
                              'Something went wrong.',
                              'fail'
                      )
                      vm.processing = false;
                  });

      }
      })
      },
    categoryName(name){
      this.category_name = name;
    },
    scrollLeft() {
      this.$refs.scrollContainer.scrollBy({ left: -150, behavior: 'smooth' });
    },
    scrollRight() {
      this.$refs.scrollContainer.scrollBy({ left: 150, behavior: 'smooth' });
    },

  // Continuous scroll on hover
  startScroll(direction) {
    if (this.scrolling) return; // Prevent multiple intervals from being set
    this.scrolling = true; // Set the scrolling flag to true
    const scrollAmount = direction === 'left' ? -150 : 150; // Increase speed, small distance for smoother scroll
    this.stopScroll(); // Ensure no other intervals are running
    this.scrollInterval = setInterval(() => {
      this.$refs.scrollContainer.scrollBy({ left: scrollAmount, behavior: 'auto' }); // Continuous scroll
    }, 80); // Reduce the delay for faster, smoother scroll
  },
  
  stopScroll() {
    clearInterval(this.scrollInterval); // Stop the interval
    this.scrolling = false; // Reset the scrolling flag
  },


    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      }).format(date);
    },

    formatDateRange(startDateString, endDateString) {
      if (!startDateString || !endDateString) return '';
      
      const startDate = new Date(startDateString);
      const endDate = new Date(endDateString);

      // Check if the start and end dates are in the same month and year
      if (
        startDate.getFullYear() === endDate.getFullYear() &&
        startDate.getMonth() === endDate.getMonth()
      ) {
        return `${startDate.getDate()}-${endDate.getDate()} ${endDate.toLocaleString('en-GB', {
          month: 'short',
          year: 'numeric',
        })}`;
      } else {
        // Default formatting for different months or years
        return `${this.formatDate(startDateString)} - ${this.formatDate(endDateString)}`;
      }
    },
    getPaginatedData() {
      console.log("Get paginated data");
      this.getEvents("");
    },
    infiniteHandler($state) {
      var vm = this;
      vm.showLoadMore = true;
      setTimeout(() => {
        this.filters.offset = this.filters.offset + 12;
        vm.getEvents("loadmore");
        $state.complete();
        vm.showLoadMore = false;
      }, 1000);
    },
    loadMore: function () {
      this.filters.offset = this.filters.offset + 12;
      this.getEvents("loadmore");
    },
    getHeartImageUrl(isLike) {
      var path = window.axios.defaults.baseURL + "/images/";
      return isLike === true ? path + "red-heart.svg" : path + "heart.svg";
    },
    getImageUrl(isLike) {
      var path = window.axios.defaults.baseURL + "/images/";
      return isLike === true ? path + "fav-w.svg" : path + "fav.svg";
    },
    getFollowClass(isFollow) {
      return isFollow === true ? "follow mobile-hide" : "notfollow mobile-hide";
    },
    getFollowClassMobile(isFollow) {
      return isFollow === true
        ? "follow desktop-hide inline"
        : "notfollow desktop-hide inline";
    },
    getVenueAidTitle: function (venue) {
      var title = "";
      if (
        venue.closing_reasons.severity.description &&
        venue.closing_reasons.title != "Normal"
      ) {
        title = venue.closing_reasons.severity.description;
        title += venue.closing_reasons.duration.time_duration
          ? " | Duration:" + venue.closing_reasons.duration.time_duration
          : "";
      } else {
        title = venue.closing_reasons.title;
      }

      return title;
    },
    hashTagsSearch(category, hashTag, type) {
      debugger
      if (type == "category") {
        bus.$emit("hashtag", category, hashTag, type);
        this.getEvents("advanceFilter");
      } else if (type == "venue_type") {
        bus.$emit("hashtag", category, hashTag, type);
        this.getEvents("advanceFilter");
      } else if (type == "theme") {
        bus.$emit("hashtag", category, hashTag, type);
        this.getEvents("advanceFilter");
      }
    },
    getVenueAidTitle: function (venue) {
      var title = "";
      if (
        venue.closing_reasons.severity.description &&
        venue.closing_reasons.title != "Normal"
      ) {
        title = venue.closing_reasons.severity.description;
        title += venue.closing_reasons.duration.time_duration
          ? " | Duration:" + venue.closing_reasons.duration.time_duration
          : "";
      } else {
        title = venue.closing_reasons.title;
      }

      return title;
    },

    getNearYouData:function(){

      var vm = this;

      this.near_you_city = this.$session.get("default_location");

      this.categoryName(vm.filters.category)

      var isLiveLocation = this.$session.get("liveLocation");
        var latitude = "";
        var longitude = "";
        if (isLiveLocation) {
          latitude = this.$session.get("live_location_lat");
          longitude = this.$session.get("live_location_long");
        } else {
          latitude = this.$session.get("default_location_lat");
          longitude = this.$session.get("default_location_long");
        }
        var pos = {
          lat: latitude,
          lng: longitude,
        };

        var action = 'null'
        vm.getEventsAPI(action, pos, vm);


    },
    getEvents: function (action, isScrollable) {
      var vm = this;
      var actionType = "";
      var start_date = "";
      var end_date = "";

      var offset = vm.filters.offset;
      var limit = vm.filters.limit;

      vm.customAdCounter = 0;

      //setTimeout(() => {
      if (action == "loadmore") {
        vm.loadingMoreContent = true;
        actionType = "loadmore";
      } else {
        vm.loadingContent = true;
        vm.offset = 0;
        actionType = "other";
      }

      if (typeof isScrollable != "undefined" && isScrollable === false) {
        this.filters.offset = 0;
        $("#selectedStart_date").val("");
        $("#selectedEnd_date").val("");
      }

      // Pagination Limit Set ----------------------------
      // var offset = 0;
      // var limit = vm.page > 1 ? vm.page * 12 : 12;
      // offset = vm.page > 1 ? offset + limit - 12 : 0;

      var dataParams = {
        params: {
          q: this.filters.search,
          city: this.$session.get("default_location"),
          state_code: this.$session.get("default_state"),
          start_date: this.filters.dates.start,
          end_date: this.filters.dates.end,
          category: this.filters.category,
          business_type: this.filters.business_type,
          venue_type: this.filters.venue_type,
          event_theme: this.filters.event_theme,
          genre: this.filters.event_genre,
          amenity_id: this.filters.amenity_id,
          action: actionType,
          offset: offset,
          limit: limit,
          // page: vm.page,
        },
      };

      this.$session.set("global_filters", dataParams);
      console.log("Vue session", this.$session.get("global_filters"));

      axios
        .get("data/event/", dataParams)
        .then(function (response) {
          // add custom ads in event array if it exists
          let latestEventsList = [];
          if (vm.filters.category == "ceo") {
            let countEvent = 0;
            let eventDetails = response.data.data.events;

            eventDetails.map((el, key) => {
              latestEventsList.push(el);
            });
            vm.trendingEventList = latestEventsList;
            vm.trendingHashList = response.data.hashtags
            // vm.trendingEventList = eventDetails;
          } else {
            latestEventsList = response.data.data.events;
          }

          if (action == "loadmore") {
            $.each(latestEventsList, function (index, obj) {
              vm.events.push(obj);
            });
            vm.showLoadMore =
              response.data.data.events.length < vm.filters.limit
                ? false
                : true;
            vm.loadingMoreContent = false;
          } else {
            if (
              isScrollable != false &&
              (action == "updateLocation" || action == "search_event_title")
            ) {
              document
                .getElementById("filter-id")
                .scrollIntoView({ block: "end", behavior: "smooth" });
            }
            vm.events = latestEventsList;
            vm.showLoadMore =
              response.data.data.events.length < vm.filters.limit
                ? false
                : true;
            vm.loadingContent = false;
          }
          let eventList = response.data.data.events;

          var totalPages = response.data.data.total / vm.filters.limit;
          vm.total_page = parseInt(totalPages);
          // eventList = eventList.map(el => {
          //   (el.id = el.event_id), (el.title = el.event_title);
          //   return el;
          // });
          // shareButtonEnable(vm.events, "event-detail");      
        })
        .catch(function (errors) {
          vm.loadingContent = false;
          vm.loadingMoreContent = false;
        });
      //}, 100);
    },

    async getEventsAPI(action, latLongDetail, vm) {
      var mapSearchParams = {
        params: {
          q: this.filters.search,
          city: this.$session.get("default_location"),
          state_code: this.$session.get("default_state"),
          start_date: this.filters.dates.start,
          end_date: this.filters.dates.end,
          category: this.filters.category,
          business_type: this.filters.business_type,
          venue_type: this.filters.venue_type,
          event_theme: this.filters.event_theme,
          genre: this.filters.event_genre,
          amenity_id: this.filters.amenity_id,
          radius: 10,
          // action: actionType,
          offset: 0,
          limit: 12,
          // latitude: 42.33143,
          // longitude: -83.04575,

          latitude: latLongDetail.lat,
          longitude: latLongDetail.lng,
          // page: vm.page,
        },

        // params: {
        //   q: this.filters.search,
        //   start_date: this.filters.dates.start,
        //   end_date: this.filters.dates.end,
        //   category: this.filters.category,
        //   business_type: this.filters.business_type,
        //   venue_type: this.filters.venue_type,
        //   event_theme: this.filters.event_theme,
        //   genre: this.filters.event_genre,
        //   amenity_id: this.filters.amenity_id,
        //   radius: this.filters.event_radius,
        //   offset: this.filters.offset,
        //   limit: this.filters.limit,
        //   latitude: latLongDetail.lat,
        //   longitude: latLongDetail.lng,
        // },
      };

      try {
        // fetch data from a url endpoint
        const response = await axios.get("data/search/map", mapSearchParams);
        // console.log('near you Data',response)
        let eventMapsList = [];
        if (response.data.success != false) {
          var isLoadMore = false;
          if (action == "loadmore") {
            isLoadMore = true;
            if (response.data.data.events.length > 0) {
              $.each(response.data.data.events, function (index, obj) {
                vm.events.push(obj);
              });
              vm.showLoadMore =
                response.data.data.events.length < vm.filters.limit
                  ? false
                  : true;
              vm.loadingMoreContent = false;
            } else {
              vm.showLoadMore = false;
            }
            vm.loadingContent = false;
          } else {
            vm.near_you_events = response.data.data.events;

            // vm.showLoadMore =
            //   response.data.data.events.length < vm.filters.limit
            //     ? false
            //     : true;
            // vm.loadingContent = false;
          }

          // shareButtonEnable(response.data.data.events, "event-detail");
          // eventMapsList["isLoadMore"] = isLoadMore;
          // eventMapsList["latitude"] = latLongDetail.lat;
          // eventMapsList["longitude"] = latLongDetail.lng;
          // eventMapsList["response"] = response.data.data.events;

          // bus.$emit("addMarkers", eventMapsList);
          // vm.showLoadMore = false;


          console.log('near you Data',vm.near_you_events)
          console.log('near you Data2',vm.events)


        } else {
          vm.near_you_events = []; // Clear near_you_events if no data is returned

          vm.showLoadMore = false;
          vm.loadingMoreContent = false;
          loadingContent = false;
          vm.loadingContent = false;
        }
      } catch (error) {
        console.log("error", error);
        vm.near_you_events = []; // Clear near_you_events if no data is returned

        vm.loadingContent = false;
        vm.loadingMoreContent = false;
        vm.showLoadMore = false;
        // appropriately handle the error
      }
    },
    followVenue: function (id, venue_id) {
      this.customAdCounter = 0;
      this.events = this.events.map((el) => {
        el.event_id === id ? (el.follow = true) : "";
        return el;
      });
      var vm = this;
      //this.heartImage = 'red-heart.svg';
      var data = new FormData();
      data.append("content_id", venue_id);
      data.append("content_type", "venue");
      data.append("favourite", true);

      if (vm.processing === true) {
        return false;
      }

      vm.processing = true;
      axios
        .post("data/markFavourite", data)
        .then(function (response) {
          vm.processing = false;
        })
        .catch(function (errors) {
          vm.processing = false;
        });
    },
    scrollToDiv: function (divID) {
      // $("html, body").animate({scrollTop: $("#" + divID).offset().top,},1000);
    },
    getBaseUrl() {
      var path = window.axios.defaults.baseURL;
      return path;
    },
    getNumberReminder: function (item, divider) {
      item = item + 1;
      var reminder = item % divider;
      if (reminder == 0 && item != 0) {
        console.log("Reminder", reminder);
        return true;
      } else {
        return false;
      }
    },
    cardType: function (index, eventType, type) {
      var str = "main-card main-card-" + index;
      if (eventType != "event" && type == "ads") {
        str += " ads-card-item";
      }
      if (eventType == "event" && type == "event") {
        str += " event-card-item";
      }

      if (this.getNumberReminder(index, 13) && type == "google-card") {
        str += " google-ads-card-item";
      }
      if (this.getNumberReminder(index, 15) && type == "google-full-width") {
        str += " google-full-width-card-item";
      }

      return str;
    },
    saveEventLink() {
      let currentEventId = event.target.getAttribute("data-eventlink");
      // eventPath = path + currentEventId;
      localStorage.setItem("selectedEvent", currentEventId);
    },
    blockRightClick: function (e) {
      e.preventDefault();
    },
  },
  filters: {
    checkNullSeverity: function(severity) {
      var str = "V-AID";
      if (severity != "" && severity != null) {
        str += ": "+severity;
      }
      return str;
    },
    checkNullHash: function (hash) {
      var hashTag = "";
      if (hash != "" && hash != null && hash != "null") {
        hashTag = hash.indexOf("#") > -1 ? hash : "#" + hash;
      }
      return hashTag;
    },
    NATime: function (time) {
      if (time == "" || time == null || time == "00:00:00") {
        time = "- N/A";
      } else {
        time = "- " + time;
      }
      return time;
    },
    NADate: function (date) {
      if (date == "" || date == null) {
        date = "- N/A";
      } else {
        date = "- " + date;
      }
      return date;
    },
    assetPath: function (img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
    socialImgPath: function (title) {
      return '<img src="https://d2cv1d7fub3ij0.cloudfront.net/system-images/logo.png" alt="VenueGPS"> '+title;
    },
    fullAddress: function (event) {
      var address = event.city;
      address += event.state != null ? ", " + event.state : "";
      address += event.zip_code != null ? ", " + event.zip_code : "";
      return address;
    },
    checkObj: function (obj) {
      return obj == "" ? false : true;
    },
    url: function (path) {
      var path = window.axios.defaults.baseURL + "/" + path;
      return path;
    },
  },
};
</script>
<style scoped>

.scroll-wrapper {
  display: flex;
  align-items: center;
  height: 500px;
}

.scroll-container {
  /* height: 250px; */
  display: flex;
  /* overflow-x: hidden; */
  overflow-y: hidden;
  white-space: nowrap;
  padding: 10px;
  gap: 10px;
  scroll-behavior: smooth; /* Smooth scroll effect */
  width: 100%; /* Set the container width */
  height: 100%;

}

.scroll-item {
  flex: 0 0 auto;
  /* width: 150px; */
  height: 100%;
  /* background-color: #e67e22; */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.scroll-button {
  border: none;
  color: white;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  height: 120px;
  background-color: unset;
}

.scroll-button:hover {
  background-color: #28a9ed;
}
/*  */

/*  */
.parallelogram-container {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between;  */
  margin-top: 8px;
}
/* Ticket  Right*/
.parallelogram {
  width: 140px;
  height: 26px;
  background-color: #3498db;
  color: white;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
}
.parallelogram2 {
  width: 140px;
  height: 26px;
  color: white;
  background-color: black;
  /* clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%);  */
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
}
.parallelogram3 {
  width: 140px;
  height: 26px;
  color: white;
  background-color: black;
  /* clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%); */
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
  margin-left: 130px;
}
/* Ad Ticket */
.parallelogramadleft {
  width: 80px;
  height: 26px;
  background-color:#57BB5B;
  color: white;
  clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
}
.parallelogramright{
  width: 200px;
  height: 26px;
  color: white;
  background-color:  #3498db;
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
}
.container23 {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Adjust based on your layout needs */
  margin-top: 10px;
}
.ticket_status_text{
  font-size: medium;
  color: white;
  margin-left: 15px;
  margin-top: 8px;
}
.ticket_status_text2{
  font-size: medium;
  color: white;
  margin-left: 22px;
  margin-top: 8px;
}
/* More Info Left */
.centered-container {
  font-size: medium;
  background-color: silver;
  width: 275px;
  height: 26px;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center;    /* Centers vertically */
  color: black;
}
/* More Info Right */
.centered-container2{
  font-size: medium;
  background-color: silver;
  width: 275px;
  height: 26px;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center;    /* Centers vertically */
  color: black;
  margin-top: 7px;
}

a{
  color: unset;
}
.near_you_right2{
  width: 110px !important;
}

.near_you_right{
  width: 132px !important;
}
.near_you_left{
  width: 123px !important;
}
.near_you_left2{
  width: 103px !important;
}
.more_info_near_you{
  width: 246px !important;
}
.more_info_near_you2{
  width: 206px !important;
}

.title-text-resp{
  font-size: 14px;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 197px;
}
.near-you-ticket-text-resp-left2{
  font-size: 12px;
}

.near-you-ticket-text-resp-right2{
  font-size: 12px;
  /* margin-left: 10px; */
}

/* .delete-event-btn {
    position: absolute;
    top: 6px;
    left: 38px;
    padding: 5px;
    border-radius: 4px;
    text-decoration: none;
    display: inline-block;
    overflow: hidden;
}

.delete-icon {
    opacity: 0; 
    transition: opacity 0.3s ease;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #eaeaea;

}

.delete-event-btn:hover .delete-icon {
    opacity: 1;
} */


/* Media query for mobile devices */
@media only screen and (min-width: 350px) and (max-width: 1008px){

.parallelogram {
width: 160px;
}
.parallelogram2 {
  width: 190px;
  clip-path: polygon(8% 0, 100% 0, 100% 100%, 0 100%);

  
}
.parallelogramadleft {
width: 90px !important;

}
.parallelogramright{
width: 280px !important;
clip-path: polygon(8% 0, 100% 0, 100% 100%, 0 100%);
}

.ticket_status_text{
  margin-left: 15px;
  /* margin-top: 15px !important; */
}
.ticket_status_text2{

  margin-left: 50px;
  /* margin-top: 15px!important */
}

.parallelogram3 {
  /* width: 140px;
  height: 50px;
  color: white;
  background-color: black;
  clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%); */

  margin-left: 206px;

}

.centered-container {
  width: 380px;

}
.centered-container2{
  width: 380px;

}

.container23 {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;  */
  padding: 20px;

}

.near_you_right{
  width: 80px !important;
}

.near_you_right2{
  width: 80px !important;
}
.near_you_left{
  width: 70px !important;
}
.near_you_left2{
  width: 70px !important;
}
.more_info_near_you{
  width: 156px !important;
}
.more_info_near_you2{
  width: 156px !important;
}
.event-card-resp{
  height: 200px !important;
  width: 150px !important;
  padding-bottom: unset
}
.dates{
  font-size: 11px;
}
.near-you-ticket-text-resp-left{
  font-size: 11px;
}
.near-you-ticket-text-resp-right{
  font-size: 11px;
  margin-left: 10px;
}
.near-you-ticket-text-resp-left2{
  font-size: 11px;
}
.near-you-ticket-text-resp-right2{
  font-size: 11px;
  margin-left: 10px;
}
.more-info-resp{

}
.title-text-resp{
  font-size: 14px;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}
.scroll-container{
  gap: 0px;
}
.scroll-wrapper{
  height: unset;
}
.event-card-mobile-resp-trending{
  height: 400px;
}
}

</style>