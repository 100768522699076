<template  >
  <div v-if="isPlanSelected" :key="componentKey"> 
  <!-- Card -->
  <div class="card">
        <div class="card-header bg-white py-3">
            <h5 style="margin-left: 120px;margin-bottom: 15px !important" class="mb-0">{{ selectedPlan.title }}</h5>
        </div>
        <div class="card-body">
            <ul class="packages-type">
                <li class="item-type "  :class="{'selected-plan': selected_plan === 'free_vip_trial'}" >
                  <div class="form-group vgps-custom-checkbox-wrapper">
                      <label class="fancyRadioWrapper">
                      <input type="radio" v-model="selected_plan" checked="checked" value="free_vip_trial" id="free_vip_trial" />
                      <span class="checkmark"></span>
                      </label>
                  </div>
                  <h3>USD</h3>
                  <h2>$ {{ selectedPackage.plan_details.price }}</h2>
                  <p>{{ selectedPackage.name }}</p>
                                </li>
            </ul>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <strong>Contacts</strong>
                <p>Phonebook</p>
                <span class="subscript-icons"><img class="img-responsive" :src="'icons/tick-icon.svg' | assetPath" alt=""/></span>
              </li>
              <li class="list-group-item">
                <strong>Hashtags</strong>
                <p>Customize Events</p>
                <span class="subscript-icons"><img class="img-responsive" :src="'icons/tick-icon.svg' | assetPath" alt=""/></span>
              </li>
              <li class="list-group-item">
                <strong>MAP</strong>
                <p>Directories</p>
                <span class="subscript-icons"><img class="img-responsive" :src="'icons/tick-icon.svg' | assetPath" alt=""/></span>
              </li>
              <li class="list-group-item">
                <strong>Accounts</strong>
                <p>{{ checkPlanLimit() }}</p>
                <span class="subscript-icons"><img class="img-responsive" :src="'icons/tick-icon.svg' | assetPath" alt=""/></span>
              </li>
              <li class="list-group-item">
                <strong>Message</strong>
                <p>Notifications</p>
                <template v-if="checkPromoteAndMessage()">
                  <span class="subscript-icons"><img class="img-responsive" :src="'icons/tick-icon.svg' | assetPath" alt=""/></span>
                </template>
                <template v-else>
                  <span class="subscript-icons"><img class="img-responsive" :src="'icons/close-icon.svg' | assetPath" alt=""/></span>
                </template>
              </li>
              <li class="list-group-item">
                <strong>Auto Promote</strong>
                <p>Prefrences</p>
                <template v-if="checkPromoteAndMessage()">
                  <span class="subscript-icons"><img class="img-responsive" :src="'icons/tick-icon.svg' | assetPath" alt=""/></span>
                </template>
                <template v-else>
                  <span class="subscript-icons"><img class="img-responsive" :src="'icons/close-icon.svg' | assetPath" alt=""/></span>
                </template>
              </li>
            </ul>
        </div>
        <div class="card-footer bg-white py-3">
            <button type="button" @click="submit()" class="btn btn-success btn-lg subscription-btn">
                <span class="login-show-spinner" :class="{ active: processing == true }">
                    <i class="fa fa-spinner fa-spin"></i>
                </span>
                <router-link :to="{ name: 'subscription' }" style="text-decoration: none;color: white;">
                    Upgrade
                </router-link>
            </button>
        </div>
        <!-- <div v-if="showPlans">
            <AllCardView/>
        </div> -->
        
    </div>
    <!-- Card -->
  </div>

  <div v-else>
    <div >
    <div class="card-header bg-white py-3">
      <h5 style="padding: 5px;margin-top: 50px; margin-bottom: 100px !important" class="mb-0">
        {{"You do not have a subscription" }}
      </h5>
    </div>
    <div class="card-footer bg-white py-3">
            <button type="button" @click="submit()" class="btn btn-success btn-lg subscription-btn">
                <span class="login-show-spinner" :class="{ active: processing == true }">
                    <i class="fa fa-spinner fa-spin"></i>
                </span>
                <router-link :to="{ name: 'subscription' }" style="text-decoration: none;color: white;">
                    Upgrade
                </router-link>
            </button>
        </div>
  </div>
  </div>
  
  </template>
  
  <script>
  
  import { StripeCheckout } from '@vue-stripe/vue-stripe';
  import helpers from "../../../mixin/helpers";
  import CardView from "./../../../views/partials/subscription/card-view";
  import TrialCardView from "./../../../views/partials/subscription/trial-card-view";
//   import AllCardView from "./../../../views/partials/subscription/all-card-view";

  export default {
    mixins: [helpers],
    props: ["packages"],

    data() {
      return {
        loadingContent: false,
        loading:false,
        processing:false,
        selected_plan: null,
        isSelectedPlan:false,
      selectedPackage: null,
      selectedPlan: {}, // Initialize as null
        customerEmail:'',
        customerStripeID:'',
        showPlans: false, // New variable to manage plan visibility
        componentKey: 0, // Add a key to control component reload

        customerId:'',
        // selected_plan:"price_1LwuhaDcPFLTCaUzuVzkdvOX",
        publishableKey:"pk_test_DdH7PjiE2RWO3zuKEmXODUGq00Dp2zdIQg",
        successURL: window.axios.defaults.baseURL+"/#/subscription/done",
        cancelURL:window.axios.defaults.baseURL+"/#/subscription",
        lineItems: [
          {
            price: "price_1LwuhaDcPFLTCaUzuVzkdvOX", // The id of the recurring price you created in your Stripe dashboard
            quantity: 1,
          }
        ],
        subscriptionsList: [],
        elementsOptions: {
          appearance: {}, // appearance options
          clientSecret:""
        },
        confirmParams: {
          return_url: window.axios.defaults.baseURL+'/#/subscription/done', // success url
        },
      };
    },
    computed: {
      isPlanSelected() {
        return this.isSelectedPlan;
      }
    },
    watch: {
    // Watch the $route object to detect route changes
    '$route'(to, from) {
      if (to.path === '/user-subscription') {
        this.getDetail(); // Call the API when the user navigates to /user-subscription
      }
    }
      // selected_plan: function (newVal, oldVal) {
      //     console.log(' selected_plan ',newVal)
      //     this.$set(this.lineItems[0], 'price', newVal);    
      // },    
      // selectedPlan(newVal, oldVal) {
      //   console.log('Selected plan changed:', newVal);
      //   this.isSelectedPlan = newVal !== null;
      // },
    },
    created: function() {
  let vm = this;
  vm.loadingContent = true;

  // Fetch user profile data
  this.getDetail();
},



    components: {
      StripeCheckout,
      CardView,
    TrialCardView,
    // AllCardView,
    },
    methods: {
      async getDetail() {
        // alert("Api call")
      console.log('API is called');
      this.componentKey += 1; // Update componentKey to trigger re-render
      var vm = this;
      return axios
        .get("get/profile/data")
        .then((response) => {
          if (response.data.success) {
            vm.profile = response.data.data; // Assume profile data includes user_subscription and other details

            const userSubscription = vm.profile.user_subscription_plan; // Assuming this is set in getDetail

    const availablePackages = [
      {
        "title": "Trial",
        "packages": [
          {
            "id": "stripe_plan_2023",
            "name": "14 Days Trial",
            "plan_details": {
              "id": "trial",
              "price": "0"
            },
            "total_plan_counts": 50
          },
          {
            "id": "free_vip_trial",
            "name": "7 Days Trial",
            "plan_details": {
              "id": "trial",
              "price": "0"
            },
            "total_plan_counts": 250000
          }
        ]
      },
      {
        "title": "Standard",
        "packages": [
          {
            "id": "prod_OBuoQpsL53xt2R_updated",
            "name": "VGPS Standard Yearly",
            "plan_details": {
              "id": "price_1NPWyeDcPFLTCaUzFX5koDOH",
              "price": "10",
              "plan_id": "prod_OBuoQpsL53xt2R"
            },
            "total_plan_counts": 250000
          },
          {
            "id": "prod_OBugDACwuh0kR3_updated",
            "name": "VGPS Standard Monthly",
            "plan_details": {
              "id": "price_1NPWqlDcPFLTCaUzsvk4Bnk0",
              "price": "0.99",
              "plan_id": "prod_OBugDACwuh0kR3"
            },
            "total_plan_counts": 250000
          }
        ]
      },
      {
        "title": "Premium",
        "packages": [
          {
            "id": "prod_OBurPRj5z2fJA5_updated",
            "name": "Premium Yearly",
            "plan_details": {
              "id": "price_1NPX1WDcPFLTCaUzeR4StsDZ",
              "price": "50",
              "plan_id": "prod_OBurPRj5z2fJA5"
            },
            "total_plan_counts": 250000
          },
          {
            "id": "prod_OBupsEcCHMdICV_updated",
            "name": "Premium Monthly",
            "plan_details": {
              "id": "price_1NPX0ADcPFLTCaUzHplcn5Pq",
              "price": "4.99",
              "plan_id": "prod_OBupsEcCHMdICV"
            },
            "total_plan_counts": 250000
          }
        ]
      }
    ];

    // Find the current plan from the user subscription data
    const currentPlan = availablePackages.flatMap(p => p.packages).find(p => p.id === userSubscription.stripe_plan);

    if (currentPlan) {
      vm.selectedPlan = availablePackages.find(p => p.packages.some(pkg => pkg.id === currentPlan.id));
      vm.selectedPackage = currentPlan;
      vm.selected_plan = currentPlan.id;
      vm.isSelectedPlan = true;
    } else {
      vm.selectedPlan = null;
    }
            console.log('Profile data:', vm.profile);
          } else {
            // Handle errors, such as invalid session or authorization issues
            if (response.data.status_code === 403) {
              vm.clearVueSession(response.data.template);
            } else {
              sweetalert2.fire("", response.data.description, "warning");
            }
          }
        })
        .catch((errors) => {
          console.log("User profile get exception", errors);
          // sweetalert2.fire("", "Error fetching profile data", "error");
        });
    },

      submit : function () {
        this.showPlans = true; // Show the plans when the button is clicked
        // this.$router.push({ name: 'AllCardView' });
        // var vm = this

        // if(this.selected_plan ==''){
        //       sweetalert2.fire(
        //       "Plan Option is Required!",
        //       'Please select the plan Month/Year',
        //       "info"
        //     );
        //     return false;
        // }

        // const data = new FormData();
        // data.append("stripe_user_subscription_id", "is_trial");
        // data.append("stripe_plan", this.selected_plan);
        // data.append("stripe_customer_id", vm.customerId);

        // vm.processing = true;

        // axios.post('data/free-subscription', data)
        //   .then(function (response) {
            
        //     const {data} = response
        //     if (data.data.success == true) {
        //       setTimeout(function () {  
        //         window.location =  window.axios.defaults.baseURL+'/#/subscription/done';
        //       }, 500);
        //     } else {

        //       sweetalert2.fire({
        //         icon: "info",
        //         title: "Something went wrong",
        //         html: data.data.description,
        //       });
        //     }
        //     vm.processing = false;
        //   })
        //   .catch(function (errors) {
        //     console.log("Subscription create exception", errors);
        //     sweetalert2.fire("", "Something went wrong!", "error");
        //     vm.processing = false;
        //   });
      },
      checkPlanLimit: function () {
        const includePlan = [
          'Trial'
        ];
        return (this.selected_plan == 'stripe_plan_2023')?'50':"Unlimited"
      },
      checkPromoteAndMessage: function () {
        const includePlanSelection = [
          'free_vip_trial',
          "price_1MeDL7DcPFLTCaUzozDb2GGu",
          "price_1MeDKYDcPFLTCaUz7HFDDKGc"
        ];
        return (includePlanSelection.includes(this.selected_plan))?true:false
      }
    },
  //   beforeRouteEnter(to, from, next) {
  //   next(vm => vm.getDetail());
  // },

  // beforeRouteUpdate(to, from, next) {
  //   this.getDetail();
  //   next();
  // },
  

    filters: {
      formatData: function (str) {
        return str.replace("Yearly",'Save/Yearly')
      }
  }
  };
  </script>

<style scoped>
/* Subscription */
.pricinng-plan .tab-pane .plan-card .packages-type li {
  position: relative;
  background: #FAFAFB;
  border: 2px solid #EDEDEE;
  border-radius: 20px;
  width: 50%;
  padding: 36px 10px 10px 10px;
  text-align: center;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  margin-left: 70px;
}

.pricinng-plan .tab-pane .plan-card .packages-type li.selected-plan {
  background: #FAFAFB; /* Inner background */
  border: none; /* Remove default border */
  position: relative;
}

.pricinng-plan .tab-pane .plan-card .packages-type li.selected-plan::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px; /* Same border-radius as the main element */
  padding: 2px; /* The thickness of the border */
  background: linear-gradient(80.46deg, #00AEF0 4%, #6740B4 90.99%);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}
</style>