<template>
  <div class="venue-content filter-content">
    <div class="container">
      <advance_filters v-bind:filters="filters"></advance_filters>
      <venue_listing v-bind:filters="filters"></venue_listing>
    </div>
    <div v-if="loadingContent" class="content-loader">
      <img :src="'icons/vgps-loader.gif' | assetPath" />
    </div>
  </div>
</template>

<script>
// Imports
import { bus } from "../app";
import advanceFilters from "./browse/advance-filter.vue";
import VenueListing from "./browse/venue-listing.vue";
import helpers from "../mixin/helpers";

const offset = 0;
const limit = 12;

export default {
  components: {
    advance_filters: advanceFilters,
    venue_listing: VenueListing
  },
  beforeMount: function() {
    //        this.loadingContent = true;
  },
  beforeCreate: function() {
    //        this.loadingContent = true;
  },
  created: function() {
    var vm = this;

    bus.$on("updateSessionFilters", filters => {
      var vm = this;
      vm.location = filters.city;
    });

    bus.$on("stateChangeEvent", filters => {
      vm.filters.city = '';
    });


    

    this.loadingContent = false;
    this.pageLoader = false;

    // var filtersData = this.$session.get("global_filters");
    // if (typeof filtersData != "undefined") {
    //   vm.filters.offset = filtersData.params.offset;
    //   vm.filters.limit = filtersData.params.limit;
    // } else {
    vm.filters.offset = offset;
    vm.filters.limit = limit;
    // }


  },
  mounted() {
    bus.$emit("advanceFilterEvent");
  },
  mixins: [helpers],
  props: ["location", "pageLoader"],
  data() {
    return {
      title: "Browse Component",
      filters: {
        start_with: "",
        business_type: "",
        venue_type: "",
        genre: "",
        is_global: "all",
        state_id: "",
        city_id: "",
        night_life: "",
        amenities: [],
        venue: "",
        people: "",
        community: "",
        location: "",
        search: "",
        offset: 0,
        limit: limit,
        category: "",
        type: "",
        festival: ""
      },
      isApiCompleted: false,
      userData: [],
      loadingContent: true
    };
  },
  methods: {
    // lookupData: function() {
    //     axios.get("data/lookup")
    //         .then((response)  => {
    //             this.lookupData = response.data.lookup_data;
    //             this.userData = response.data.user_data;
    //             this.isApiCompleted = true;
    //         })
    //         .catch(function (errors) {
    //         });
    // }
  }
};
</script>