<template>
  <div class="map-view-event-listing">
    <div
      v-if="loadingContent"
      class="content-loader"
      v-animate-css="animate.fadeOut"
    >
      <img :src="'icons/vgps-loader.gif' | assetPath" />
    </div>
    <div
      v-animate-css="animate.fadeIn"
      v-if="!loadingContent && events.length > 0"
      v-for="(event, key) in events"
      v-on:mouseenter="eventMouseOver(event, key)"
      v-on:mouseleave="eventMouseLeave(event, key)"
      :key="key"
      class="col-md-12 col-sm-12 col-xs-12 column ceo no-padding-l-r show vgps-map-listing-event-card"
      :class="{ active: key == activeIndex }"
      :id="'event-card-' + event.id"
    >
      <!-- <div class="map-content" @click.self="eventDetail(event.id)"> -->
      <div class="map-content">
        <div class="map-left">
          <div class="vgps-event-thumb-wrapper">
            <router-link
              v-if="isAuth()"
              :to="{ name: 'event', params: { id: event.id } }"
            >
              <!-- <a v-if="isAuth()" :href="url('event-detail/'+event.id)" target="_blank"> -->
              <img v-lazy="event.thumbnail_urls.thumb_296" :alt="event.title" />
            </router-link>
            <a
              v-else
              @click="loginModal(event.id, '#/event/')"
              :href="url('event-detail/' + event.id)"
            >
              <img v-lazy="event.thumbnail_urls.thumb_296" :alt="event.title" />
            </a>
          </div>
          <a
            v-if="isAdministratorAccount()"
            :href="adminEditOptionURL('event', event.id)"
            target="_blank"
            class="admin-edit-option"
          >
            <i class="fa fa-edit"></i> Edit
          </a>
        </div>
        <div class="map-center" :class="event.activation_status_display">
          <h6 class="event-title">
            <router-link
              v-if="isAuth()"
              :to="{ name: 'event', params: { id: event.id } }"
            >
              {{ limitStr(event.title, 28) }}
            </router-link>
            <a
              v-else
              @click="loginModal(event.id, '#/event/')"
              href="javascript:void(0);"
              @contextmenu="blockRightClick($event)"
              >{{ limitStr(event.title, 28) }}</a
            >
          </h6>
          <div class="vgps-event-date-wrapper">
            <span>{{ event.start_date }} - {{ event.end_date }}</span>
          </div>
          <div class="vgps-event-venue-wrapper">
            <router-link
              v-if="isAuth()"
              :to="{ name: 'venue', params: { id: event.venue.id } }"
            >
              {{ limitStr(event.venue.title, 28) }}
            </router-link>

            <a
              v-else
              @click="loginModal(event.venue.id, '#/venue/')"
              href="#"
              >{{ limitStr(event.venue.title, 28) }}</a
            >
          </div>
          <div class="vgps-event-hashtags-wrapper">
            <ul>
              <li
                class="hash-item-search"
                :data-category="event.category"
                data-type="category"
              >
                <router-link
                  class="event-hashtags"
                  :title="event.category"
                  :to="{
                    name: 'search',
                    query: {
                      search_term: '#' + event.category,
                      search_category: 'all',
                    },
                  }"
                  >{{ event.category | checkNullHash }}</router-link
                >
              </li>
              <li
                class="hash-item-search"
                :data-category="event.category"
                data-type="venue_type"
                :data-venue_type="event.venue_type"
              >
                <router-link
                  class="event-hashtags"
                  :title="event.venue.type"
                  :to="{
                    name: 'search',
                    query: {
                      search_term: '#' + event.venue.type,
                      search_category: 'all',
                    },
                  }"
                  >{{ event.venue.type | checkNullHash }}</router-link
                >
              </li>
              <li
                class="hash-item-search"
                data-type="theme"
                :data-theme="event.theme"
              >
                <router-link
                  class="event-hashtags"
                  :title="event.theme"
                  :to="{
                    name: 'search',
                    query: {
                      search_term: '#' + event.theme,
                      search_category: 'all',
                    },
                  }"
                  >{{ event.theme | checkNullHash }}</router-link
                >
              </li>
            </ul>
          </div>
          <div class="vgps-event-cat-views-wrapper">
            <span v-if="event.start_time" title="Start Time" class="time">
              <i class="fa fa-clock"></i>
              : {{ event.start_time }}
            </span>
            <span v-if="event.total_views > 0" class="eye">
              <img :src="'seen.svg' | assetPath" />
              {{ event.total_views }}
            </span>
            <span
              v-if="event.activation_status_display"
              class="eye event-status"
              >{{ event.activation_status_display }}</span
            >

            <!--Venue AID -->
            <div
              v-if="event.venue.closing_reasons.title != 'Normal'"
              class="venue-aid"
            >
              <span
                class=""
                :title="getVenueAidTitle(event.venue)"
                :class="event.venue.closing_reasons.color"
              >
                {{ event.venue.closing_reasons.severity.level | checkNullSeverity }}
                <img width="15" :src="'info.svg' | assetPath"
              /></span>
            </div>
          </div>
        </div>
        <div class="map-right">
          <!--like Event Component -->
          <like_event
            v-bind:events="events"
            v-bind:event="event"
            v-bind:index="key"
          ></like_event>

          <!--Following Event Component -->
          <follow_venue
            v-bind:events="events"
            v-bind:event="event"
            v-bind:venueid="event.venue.id"
            v-bind:viewType="viewType"
            v-bind:index="key"
          ></follow_venue>

          <!-- <span :class="'pleaseShare share socialShare_'+event.id"></span> -->
          <div class="dropright">
            <button
              class="btn btn-default vue-social-share"
              id="social-network-share"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-share-alt"></i>
            </button>
            <div
              class="dropdown-menu vue-social-share-items"
              aria-labelledby="dropdownMenu2"
            >
              <li class="dropdown-item">
                <ShareNetwork
                  network="facebook"
                  :url="socialShareLink('event-detail/' + event.id)"
                  :title="event.title"
                  description="event.title"
                >
                  <i class="fab fah fa-lg fa-facebook"></i>
                  <!-- <span>Share on Facebook</span> -->
                </ShareNetwork>
              </li>
              <li class="dropdown-item">
                <ShareNetwork
                  network="twitter"
                  :url="socialShareLink('event-detail/' + event.id)"
                  :title="event.title"
                  description="event.title"
                >
                  <i class="fab fah fa-lg fa-twitter"></i>
                  <!-- <span>Share on Twitter</span> -->
                </ShareNetwork>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!loadingContent && events.length == 0"
      class="text-center result-not-found"
    >
      <a><i class="fa fa-search"></i></a>
      <p>Sorry, we do not have any events for this search for now.<br /></p>
    </div>

    <!-- Load More Button End -->
    <div v-show="!loadingContent" class="map-view-load-btn-wrapper clear-row">
      <button
        
        @click="loadMore"
        class="map-view-load-more-btn"
      >
        <i
          class="login-show-spinner"
          :class="{ active: loadingMoreContent == true }"
        >
          <i class="fa fa-spinner fa-spin"></i>
        </i>
        load More
      </button>


      <!--Load more results --->
      <div v-if="showLoadMore && !loadingContent" class="infinite-loader">
        <img :src="'icons/vgps-loader.gif' | assetPath" alt="Loading Content" />
      </div>

      <!--Show more event --->
      <!-- <div v-if="loadingContent == false" class="row">
        <div class="col-md-12">
          <infinite-loading
            spinner="waveDots"
            @infinite="infiniteHandler"
          ></infinite-loading>
        </div>
      </div> -->

      <div v-if="!showLoadMore && !loadingContent" class="no-more-map-result">
        <!--<p>No more results available!</p>-->
      </div>
    </div>

    <!-- Load More Button End -->
  </div>
</template>

<script>
import { bus } from "../../app";
import LikeEvent from "../global/like .vue";
import FollowVenue from "../global/follow-button.vue";
import helpers from "../../mixin/helpers";
import InfiniteLoading from "vue-infinite-loading";

import {
  ContentLoader,
  FacebookLoader,
  CodeLoader,
  BulletListLoader,
  InstagramLoader,
  ListLoader,
} from "vue-content-loader";

export default {
  data() {
    return {
      processing: false,
      viewType: "eventType",
      loadingContent: false,
      loadingMoreContent: false,
      Searching: false,
      showLoadMore: true,
      events: [],
      heartImage: "heart.svg",
      activeIndex: 0,
    };
  },
  mixins: [helpers],
  created() {
    bus.$on("citySearchFilter", () => {
      this.getEvents("advanceFilter");
    });
    bus.$on("advanceFilter", () => {
      this.getEvents("advanceFilter");
    });
    bus.$on("search_event_title", (keyword) => {
      this.filters.search = keyword;
      this.scrollToDiv("filter-id");
      this.getEvents("");
    });
    bus.$on("clearAdvanceFilter", () => {
      this.getEvents("advanceFilter");
    });
    bus.$on("filterSelection", () => {
      this.getEvents("filterSelection");
    });
    bus.$on("updateMapDateValues", () => {
      this.getEvents("updateDateValues");
    });
    bus.$on("updateLocation", () => {
      this.getEvents("updateLocation");
    });
    bus.$on("hashtag", (category, hashTag, type) => {
      this.filters.search = category;
      this.filters.event_theme = "";
      this.scrollToDiv("filter-id");
      this.getEvents("");
    });
    this.getEvents();

    bus.$on("getLiveLocation", () => {
      this.getEvents();
    });
  },
  components: {
    ContentLoader,
    FacebookLoader,
    like_event: LikeEvent,
    follow_venue: FollowVenue,
    InfiniteLoading,
  },
  props: ["filters"],
  mounted() {
    var vm = this;
  },
  methods: {
    infiniteHandler($state) {
      var vm = this;
      if (vm.processing === true) {
        return false;
      }
      console.log('**************[ infiniteHandler ]*****************');
      vm.showLoadMore = true;
      setTimeout(() => {
        vm.filters.offset = vm.filters.offset + 12;
        vm.getEvents("loadmore");
        $state.complete();
        vm.showLoadMore = false;
      }, 500);
    },
    loadMore: function () {
      this.filters.offset = this.filters.offset + this.filters.limit;
      this.getEvents("loadmore");
    },
    getVenueAidTitle: function (venue) {
      var title = "";
      if (
        venue.closing_reasons.severity.description &&
        venue.closing_reasons.title != "Normal"
      ) {
        title = venue.closing_reasons.severity.description;
        title += venue.closing_reasons.duration.time_duration
          ? " | Duration:" + venue.closing_reasons.duration.time_duration
          : "";
      } else {
        title = venue.closing_reasons.title;
      }

      return title;
    },
    getHeartImageUrl(isLike) {
      var path = window.axios.defaults.baseURL + "/images/";
      return isLike === true ? path + "red-heart.svg" : path + "heart.svg";
    },
    getImageUrl(isLike) {
      var path = window.axios.defaults.baseURL + "/images/";
      return isLike === true ? path + "red-heart.svg" : path + "heart.svg";
    },
    getEvents: function (action) {
      var vm = this;
      if (action == "loadmore") {
        vm.loadingMoreContent = true;
      } else {
        this.filters.offset = 0;
        vm.loadingContent = true;
      }

      var vm = this;
      var isLiveLocation = this.$session.get("liveLocation");
      var latitude = "";
      var longitude = "";
      if (isLiveLocation) {
        latitude = this.$session.get("live_location_lat");
        longitude = this.$session.get("live_location_long");
      } else {
        latitude = this.$session.get("default_location_lat");
        longitude = this.$session.get("default_location_long");
      }
      var pos = {
        lat: latitude,
        lng: longitude,
      };
      vm.getEventsAPI(action, pos, vm);
    },
    hashTagsSearch(hashTag, type) {
      console.log("a");
      this.filters.venue_type = "";
      this.filters.event_theme = "";

      if (type == "category") {
        this.filters.category = hashTag;
        this.getEvents("advanceFilter");
      } else if (type == "venue_type") {
        this.filters.venue_type = hashTag.toLowerCase();
        this.getEvents("advanceFilter");
      } else if (type == "theme") {
        this.filters.event_theme = hashTag;
        this.getEvents("advanceFilter");
      }
    },
    eventMouseLeave(event, index) {
      bus.$emit("removeMarkerDetail");
    },
    eventMouseOver(event, index) {
      this.activeIndex = index;
      bus.$emit("showMarkerDetail", event);
    },
    async getEventsAPI(action, latLongDetail, vm) {
      var vm = this;
      var mapSearchParams = {
        params: {
          q: this.filters.search,
          start_date: this.filters.dates.start,
          end_date: this.filters.dates.end,
          category: this.filters.category,
          business_type: this.filters.business_type,
          venue_type: this.filters.venue_type,
          event_theme: this.filters.event_theme,
          genre: this.filters.event_genre,
          amenity_id: this.filters.amenity_id,
          radius: this.filters.event_radius,
          offset: this.filters.offset,
          limit: this.filters.limit,
          latitude: latLongDetail.lat,
          longitude: latLongDetail.lng,
        },
      };

      try {
        // fetch data from a url endpoint
        const response = await axios.get("data/search/map", mapSearchParams);
        let eventMapsList = [];
        if (response.data.success != false) {
          var isLoadMore = false;
          if (action == "loadmore") {
            isLoadMore = true;
            if (response.data.data.events.length > 0) {
              $.each(response.data.data.events, function (index, obj) {
                vm.events.push(obj);
              });
              vm.showLoadMore =
                response.data.data.events.length < vm.filters.limit
                  ? false
                  : true;
              vm.loadingMoreContent = false;
            } else {
              vm.showLoadMore = false;
            }
            vm.loadingContent = false;
          } else {
            vm.events = response.data.data.events;
            vm.showLoadMore =
              response.data.data.events.length < vm.filters.limit
                ? false
                : true;
            vm.loadingContent = false;
          }

          shareButtonEnable(response.data.data.events, "event-detail");
          eventMapsList["isLoadMore"] = isLoadMore;
          eventMapsList["latitude"] = latLongDetail.lat;
          eventMapsList["longitude"] = latLongDetail.lng;
          eventMapsList["response"] = response.data.data.events;

          bus.$emit("addMarkers", eventMapsList);
          vm.showLoadMore = false;
        } else {
          vm.events = [];
          vm.showLoadMore = false;
          vm.loadingMoreContent = false;
          loadingContent = false;
          vm.loadingContent = false;
        }
      } catch (error) {
        console.log("error", error);
        vm.loadingContent = false;
        vm.loadingMoreContent = false;
        vm.showLoadMore = false;
        // appropriately handle the error
      }

      

      // axios
      //   .get("data/search/map", mapSearchParams)
      //   .then(response => {

      //     let eventMapsList = [];
      //     if (response.data.success != false) {
      //       var isLoadMore = false;
      //       if (action == "loadmore") {
      //         isLoadMore = true;
      //         if (response.data.data.events.length > 0) {
      //           $.each(response.data.data.events, function(index, obj) {
      //             vm.events.push(obj);
      //           });
      //           vm.showLoadMore =
      //             response.data.data.events.length < vm.filters.limit
      //               ? false
      //               : true;
      //           vm.loadingMoreContent = false;
      //         } else {
      //           vm.showLoadMore = false;
      //         }
      //       } else {
      //         vm.events = response.data.data.events;
      //         vm.showLoadMore =
      //           response.data.data.events.length < vm.filters.limit
      //             ? false
      //             : true;
      //         vm.loadingContent = false;
      //       }

      //       shareButtonEnable(response.data.data.events, "event-detail");
      //       eventMapsList["isLoadMore"] = isLoadMore;
      //       eventMapsList["latitude"] = latLongDetail.lat;
      //       eventMapsList["longitude"] = latLongDetail.lng;
      //       eventMapsList["response"] = response.data.data.events;

      //       bus.$emit("addMarkers", eventMapsList);
      //     } else {
      //       vm.events = [];
      //       vm.showLoadMore = false;
      //       vm.loadingMoreContent = false;
      //       loadingContent = false;
      //     }
      //   })
      //   .catch(function(errors) {
      //     vm.loadingContent = false;
      //     vm.loadingMoreContent = false;
      //   });
    },
    getImageUrl(isLike) {
      var path = window.axios.defaults.baseURL + "/images/";
      return isLike === true ? path + "fav-w.svg" : path + "fav.svg";
    },
    scrollToMap() {
      document
        .getElementById("mapviewId")
        .scrollIntoView({ block: "end", behavior: "smooth" });
    },
    scrollToDiv: function (divID) {
      $("html, body").animate(
        {
          scrollTop: $("#" + divID).offset().top,
        },
        1000
      );
    },
    eventDetail: function (id) {
      if (window.screen.width < 768) {
        this.scrollToMap();
      }
      this.$emit("clickOnEvents", id);
    },
    blockRightClick: function (e) {
      e.preventDefault();
    },
  },
  filters: {
    checkNullHash: function (hash) {
      var hashTag = "";
      if (hash != "" && hash != null && hash != "null") {
        hashTag = "#" + hash;
      }
      return hashTag;
    },
    checkNullSeverity: function(severity) {
      var str = "V-AID";
      if (severity != "" && severity != null) {
        str += ": "+severity;
      }
      return str;
    },
    assetPath: function(img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
    fullAddress: function (event) {
      var address = event.venue.address.city;
      address +=
        event.venue.address.state != null
          ? ", " + event.venue.address.state
          : "";
      address +=
        event.venue.address.zip_code != null
          ? ", " + event.venue.address.zip_code
          : "";
      return address;
    },
    checkObj: function (obj) {
      return obj == "" ? false : true;
    },
  },
};
</script>
