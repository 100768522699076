$(document).ready(function () {

    jQuery.validator.setDefaults({
        debug: true,
        success: "valid"
    });

    $("#loginFormId").validate({
        rules: {
            field: {
                required: true,
                email: true
            }
        },
        submitHandler: function (form, event) {
            event.preventDefault();
        }
    });

    // $("#business-setup-form").validate({
    //     rules: {},
    //     submitHandler: function(form, event) {
    //         // debugger
    //         return true;
    //         console.log('Business form',form);
    //     }
    // });

    $("#signupFormId").validate({
        submitHandler: function (form, event) {
            event.preventDefault();
        }
    });

    $("#claim-business-from").validate({
        // wrapper: "span",
        errorElement: "span",
        submitHandler: function (form, event) {
            event.preventDefault();
        }
    });

    $("#claim-business-request-page").validate({
        // wrapper: "span",
        errorElement: "span",
        submitHandler: function (form, event) {
            event.preventDefault();
        }
    });

    $("#create-tier").validate({
        submitHandler: function (form, event) {
            event.preventDefault();
        }
    });


    $("#create-tier").validate({
        submitHandler: function (form) {
            alert('sdfsdfs');
            // do other things for a valid form
            form.submit();
        }
    });

    $("#tier-form").validate({
        debug: false,
        focusInvalid: false,
        onfocusout: false,
        onkeyup: false,
        onclick: false,
        rules: {
            name:{
                required:true,
                blankSpace:true
            }
        },
        messages: {
            name:{
                blankSpace: 'white spaces not allowed!'
            }
        },
        success: function (label) {
            console.log('success', label);
        },
        submitHandler: function (form) {
            return true;
        },
        invalidHandler: function (form, validator) {
            console.log('invalidHandler', form, validator);
        },
    });

    $("#event-vue-create-form").validate({
        rules: {
            title: {
                required: true,
            },
            contact_website: {
                required: false,
                url: true
            },
            private_link: {
                required: false,
                url: true
            }
        },
        messages: {
            private_link: {
                url: 'Please enter a valid URL'  
            },
            contact_website: {
                url: 'Please enter a valid URL'  
            }
        },
        submitHandler: function (form, event) {
            event.preventDefault();
        }
    });
    $("#user-profile").validate({
        debug: false,
        focusInvalid: false,
        onfocusout: false,
        onkeyup: false,
        onclick: false,
        // rules: {},
        messages: {

        },
        success: function (label) {
            console.log('success', label);
        },
        submitHandler: function (form) {
            return true;
            console.log('submitHandler', form);
        },
        invalidHandler: function (form, validator) {

            console.log('invalidHandler', form, validator);
        },
    });

    $("#user-create-ad").validate({
        debug: false,
        focusInvalid: false,
        onfocusout: false,
        onkeyup: false,
        onclick: false,
        rules: {
            link: {
                required: false,
                url: true,
            },
            '#start': {
                required: {
                    depends: function (element) {
                        if ($('#date_display_option').val() == 1) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            },
            '#end': {
                required: {
                    depends: function (element) {
                        if ($('#date_display_option').val() == 1) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            }

        },
        // messages: {},
        success: function (label) {
            // alert('succes:' + label);
            // console.log('success', label);
        },
        submitHandler: function (form) {
            return true;
            console.log('submitHandler', form);
        },
        invalidHandler: function (form, validator) {
            console.log('invalidHandler', form, validator);
        },
    });

    $("#user-create-tutorial").validate({
        debug: false,
        focusInvalid: false,
        onfocusout: false,
        onkeyup: false,
        onclick: false,
        rules: {
            name: {
                required: true,
            },
            url: {
                required: true,
                url: true,
            }
        },
        submitHandler: function (form) {
            return true;
        },
        invalidHandler: function (form, validator) {
            console.log('invalidHandler', form, validator);
        },
    });

    $("#admin-create-banner").validate({
        debug: false,
        focusInvalid: false,
        onfocusout: false,
        onkeyup: false,
        onclick: false,
        rules: {
            link: {
                required: false,
                url: true,
            },
            '#start': {
                required: {
                    depends: function (element) {
                        if ($('#duration').val() == 1) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            },
            '#end': {
                required: {
                    depends: function (element) {
                        if ($('#duration').val() == 1) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            }

        },
        // messages: {},
        success: function (label) {
            // alert('succes:' + label);
            console.log('success', label);
        },
        submitHandler: function (form) {
            console.log('banner submitHandler', form);
            return true;

        },
        invalidHandler: function (form, validator) {
            console.log('banner invalidHandler', form, validator);
        },
    });


    $("#create-staff-form").validate({
        debug: false,
        focusInvalid: false,
        onfocusout: false,
        onkeyup: false,
        onclick: false,
        rules: {
        },
        // messages: {},
        success: function (label) {
            // alert('succes:' + label);
            console.log('success', label);
        },
        submitHandler: function (form) {
            return true;
            console.log('submitHandler', form);
        },
        invalidHandler: function (form, validator) {
            console.log('invalidHandler', form, validator);
        },
    });

    $("#edit-staff-form").validate({
        debug: false,
        focusInvalid: false,
        onfocusout: false,
        onkeyup: false,
        onclick: false,
        rules: {
            password: {
                required: false
            },
            confirm_password: {
                required: false,
                equalTo: '#password'
            }
        },
        // messages: {},
        success: function (label) {
            // alert('succes:' + label);
            console.log('success', label);
        },
        submitHandler: function (form) {
            return true;
            console.log('submitHandler', form);
        },
        invalidHandler: function (form, validator) {
            console.log('invalidHandler', form, validator);
        },
    });


    jQuery.validator.addMethod("blankSpace", function(value) { 
        return value.indexOf(" ") < 0 && value != ""; 
    });

    jQuery.validator.addMethod("startDate", function (value, element, params) {
        var end_date = $(params).val();
        var result = new Date(value) <= new Date(end_date) ? true : false;
        console.log('start date', result, new Date(value) + '<=' + new Date(end_date));
        return result;
    }, 'Start date must be less than or equal to end date.');
    jQuery.validator.addMethod("endDate", function (value, element, params) {

        var start_date = $(params).val();
        var result = new Date(value) >= new Date(start_date) ? true : false;
        console.log('end date', result, new Date(value) + '>=' + new Date(start_date));
        return result;
    }, 'End date must be greater than or equal to start date.');

    jQuery.validator.addMethod("doorOpenTime", function (value, element, params) {
        var result = value <= $(params).val() ? true : false;
        console.log('door open time', result, value + '<' + $(params).val());
        return result;
    }, 'Door open time must be less than start time.');

    jQuery.validator.addMethod('zipcode', function(value, element) {
        return this.optional(element) || !!value.trim().match(/^\d{5}(?:[-\s]\d{4})?$/);
    }, 'Invalid zip code');

    jQuery.validator.addMethod('BannerImageDimension', function (value, element) {
        if ($(element).data('width')) {
            return $(element).data('width') >= 800;
        }

        return true;
    }, 'Image needs to be wider than 800px');


    // jQuery.validator.addMethod("StartTime", function (value, element, params) {


    //     var start_date = new Date($('#start_date').val()).toLocaleString();
    //     var end_date = new Date($('#end_date').val()).toLocaleString();

    //     if(start_date == end_date){
    //         var result =  (value < $(params).val())?true:false;
    //     }else {
    //         return true;
    //     }
    //     console.log('start time',result,value+'<'+ $(params).val());


    // jQuery.validator.addClassRules("valid-url", {
    //     required: false,
    //     url: true,
    // });

    


    // },'Start time must be less than end time.');
    jQuery.validator.addMethod("EndTime", function (value, element, params) {


        var start_date = new Date($('#start_date').val()).toLocaleString();
        var end_date = new Date($('#end_date').val()).toLocaleString();

        if (start_date == end_date) {
            var result = (value > $(params).val()) ? true : false;
            return result;
        } else {
            return true;
        }
        console.log('end time', result, value + '>' + $(params).val());


    }, 'End time Must be greater than start time.');


    //     console.log('validation');
    //     const menu = jQuery.('#claimBusinessModal').find('#claim-business-from .row');

    //     $('body').click(function(event){
    //    $dropdowns.not($dropdowns.has(event.target)).hide();
    // });
    // $(document).click(function () {
    //     $(".pac-container.pac-logo").css('display', 'none');
    // });


});// end of Document ready function

