import { bus } from '../app'
import { mapFields } from 'vuex-map-fields';



export default {
    data: function () {
        return {
            animate: {
                slideInRight: {
                    classes: 'slideInRight',
                    duration: 1000,
                    delay: 500,
                },
                slideInUp: {
                    classes: 'slideInUp',
                    duration: 1000,
                    delay: 500,
                },
                slideOutUp: {
                    classes: 'slideOutUp',
                    duration: 1000,
                    delay: 500,
                },
                slideInLeft: {
                    classes: 'slideInLeft',
                    duration: 1000,
                    delay: 500,
                },
                fadeInUp: {
                    classes: 'fadeInUp',
                    duration: 500,
                    delay: 500,
                },
                fadeIn: {
                    classes: 'fadeIn',
                    duration: 500,
                    delay: 500,
                },
                fadeOut: {
                    classes: 'fadeOut',
                    duration: 1000,
                    delay: 500,
                },
            },
            dateranges: '',
            isAddTheme: false,
            theme_name: '',
            theme_description: '',
            imgObj: {
                src: 'http://localhost/vgps-frontend/public/images/icons/vgps-loader.gif',
                error: 'http://localhost/vgps-frontend/public/images/icons/vgps-loader.gif',
                loading: 'http://localhost/vgps-frontend/public/images/icons/vgps-loader.gif'
            },
            imgUrl: 'http://localhost/vgps-frontend/public/images/icons/vgps-loader.gif' // String
        };
    },
    mounted: function () {

        var isFeatured = $('#top-featured-hash').val();
        if (isFeatured == 1) {
            this.top_featured = true
        }
    },
    updated: function () {
    },
    created() {

    },
    computed: {
        ...mapFields([
            'top_featured',
            'currentLatitude',
            'currentLongitude',
        ]),
    },
    methods: {
        showNewsletter() {
            $('#newsletterModal').modal('show');
        },
        loginModal: function (id, type) {

            var vm = this;
            if (id == 'undefined' || id == null) {
                var currentUrlPage = window.location.href;
                vm.$session.set('intended_url', currentUrlPage);
            } else {
                var redirectURL = vm.getPath(type + id)
                vm.$session.set('intended_url', redirectURL);
            }
            if (typeof vm.$session.get('access_token') == 'undefined') {
                $('#loginModal').modal('show');
                return false;
            }
            preventDefault();
        },
        logoutAccount: function () {

            var vm = this;
            if (vm.processing === true) {
                return false;
            }

            var getToken = vm.$session.get('access_token');
            if (typeof getToken == 'undefined') {
                sweetalert2.fire(
                    {
                        title: "",
                        icon: "info",
                        text: "You already logged out.",
                        confirmButtonText: 'Go to Home!',
                        showCloseButton: true,
                        showCancelButton: true,

                    }
                ).then((result) => {
                    if (result.value) {
                        // vm.$router.push('home');
                        window.location = window.axios.defaults.baseURL
                    }
                })
                return false;
            }

            // console.log(window.axios.defaults.baseURL+'/images/icons/vgps-loader.gif');

            sweetalert2.fire(
                {
                    title: "",
                    text: "Please wait ...",
                    imageUrl: window.axios.defaults.baseURL + '/images/icons/vgps-loader.gif',
                    showConfirmButton: false,
                    allowOutsideClick: false
                }
            )

            // return false;
            vm.processing = true;

            axios.get("data/logout")
                .then(function (response) {


                    if (response.data.success == true) {

                        // sweetalert2.fire(
                        //     '',
                        //     response.data.description,
                        //     'success'
                        // )
                        // vm.$session.destroy()
                        // vm.$session.destroy('user_data')
                        // vm.$session.destroy('access_token')
                        vm.clearVueSession(response.data.description);
                        // console.log('Logout response ',response);

                        setTimeout(function () {
                            window.location.href = response.data.callback
                            // vm.$router.push('home');
                        }, 500)
                    } else {
                        // sweetalert2.fire('',response.data.description,'fail')
                        vm.clearVueSession(response.data.description);
                        console.log('Logout Exception', response);
                    }
                    vm.processing = false;
                }).catch(function (errors) {
                    // sweetalert2.fire('',response.data.description,'fail')
                    console.log('Logout Exception', errors);
                    vm.processing = false;
                });
        },
        addNewTheme: function () {

            var vm = this;
            var data = new FormData();

            data.append("name", this.theme_name);
            data.append("description", this.theme_description);



            if (vm.theme_name == "") {
                sweetalert2.fire("", "Theme name field is required.", "fail");
                return false;
            }

            if (vm.processing === true) {
                return false;
            }
            vm.processing = true;


            axios
                .post('data/add-theme', data)
                .then(function (response) {
                    if (response.data.success == true) {
                        sweetalert2.fire("", response.data.message, "success");
                        vm.theme_name = '';
                        vm.theme_description = '';
                        vm.isAddTheme = false
                    } else {

                        console.log('theme API Error', response)
                        sweetalert2.fire({
                            icon: "info",
                            title: "Something went wrong",
                            html: response.data.message
                        });
                    }

                    vm.processing = false;

                })
                .catch(function (errors) {

                    sweetalert2.fire("", errors.data.message, "fail");
                    vm.processing = false;
                });
        },
        getPrivateContacts: function (contacts) {
            var arrData = ['private-number', 'private-email', 'private-link'];
            var data = contacts.filter(function (obj) {
                return (arrData.includes(obj.provider) && obj.value != '') ? true : false
            });
            return data;
        },
        notCity: function (obj) {
            var arrData = ['Trending', 'All', 'National', 'Festival'];
            return (arrData.includes(obj.type_n)) ? true : false
        },
        getYear: function (type) {
            var d = new Date();
            var year = d.getFullYear();
            return year;
        },
        isNotNormal: function (type) {
            var arrData = ['Normal'];
            return !(arrData.includes(type)) ? true : false
        },
        getCurrentUserPosition: function (){
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  (position) => {
                    console.log('====== GeoLocation Coordinates ========',position);
                    this.$session.set('live_location_lat',position.coords.altitude);
                    this.$session.set('live_location_long',position.coords.longitude);
                    this.currentLatitude = position.coords.altitude;
                    this.currentLongitude = position.coords.longitude;
                  },
                  (positionData) => {
                    console.log('====== Geolocation ========',positionData);
                  }
                );
              } else {
                // Browser doesn't support Geolocation
                console.log('====== Browser doesnot support Geolocation ========');
              }
        },
        isPrivateContacts: function (contacts) {
            var arrData = ['private-number', 'private-email', 'private-link'];
            var data = contacts.filter(function (obj) {
                return (arrData.includes(obj.provider) && obj.value != '') ? true : false
            });
            return data.length > 0 ? true : false;
        },
        
        isTemporaryClosed: function (val) {
            var arrData = [6];
            val = parseInt(val);
            return !arrData.includes(val) ? true :false;
        },
        excludeReasons: function(type){
            var arrData = [4,6,13,14, 15, 16, 17, 18];
            type = parseInt(type);
            return !arrData.includes(type) ? true :false;
        },
        mapExcludeReasons: function(type){
            var arrData = [4,13,14, 15, 16, 17, 18];
            type = parseInt(type);
            return !arrData.includes(type) ? true :false;
        },
        excludeEventStatus: function(type){
            var arrData = [2,3,4];
            type = parseInt(type);
            return arrData.includes(type) ? true :false;
        },
        isPrivateEvent: function (eventType) {
            var arrData = ['PrivateLocation'];
            return arrData.includes(eventType)?true:false;
        },
        isNearMeLocationOption: function (category) {
            var arrData = ['Trending','ceo','NightLife','National','Festival'];
            return arrData.includes(category)?true:false;
        },
        assignVenueID: function (id, business_name, viewType, index) {

            $('#venue_id').val(id);
            $('#view_type').val(viewType);
            $('#business_name').val(business_name);
            bus.$emit('getvenueIndex', index);
        },
        isAuth: function () {
            var vm = this;
            return (typeof vm.$session.get('access_token') == 'undefined')? false:true;
        },
        notBeforeToday(date) {
            var today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
        },
        profileImageUrl: function () {

            // var userData = this.$session.get('user_data');
            var $image = this.url('images/user/profile-icon.svg');

            // if (userData.profile.gender_code == 'female') {
            //     $image = this.url('images/user/female.svg');
            // } else if (userData.profile.gender_code == 'male') {
            //     $image = this.url('/user/male.svg');
            // } else if (userData.profile.gender_code == 'pride') {
            //     $image = this.url('/user/pride.jpg')
            // } else {
            //     $image = this.url('images/user.svg');
            // }
            return $image;
        },
        userEmail: function () {
            var userData = this.$session.get('user_data');
            return userData ? userData?.profile?.first_name+' '+userData?.profile?.last_name : '';
        },
        isAdminsAccounts: function () {
            var userData = this.$session.get('user_data');
            return userData.role.code == 'super-admin' || userData.role.code == 'business-operator' ? true : false;
        },
        isPublicUserAuth: function () {
            var userData = this.$session.get('user_data');
            return (typeof userData != 'undefined' && userData.role.code == 'public' || typeof userData != 'undefined' && jQuery.isEmptyObject(userData.role)) ? true : false;
        },
        hasSubscribedPlan: function () {
            var userData = this.$session.get('user_data');
            return (typeof userData != 'undefined' && userData.profile.current_user_subscription ) ? true : false;
        },
        backendNotBeforeToday(date) {

            var selectedDate = $('#start_date').val();
            var today = new Date();
            today.setHours(0, 0, 0, 0);
            if (selectedDate) {
                selectedDate = new Date(selectedDate);
                selectedDate.setHours(0, 0, 0, 0);
                return date < selectedDate || date < today;
            } else {
                return date < today;
            }

        },
        backendNotBeforeStartDate(date) {


            var selectedDate = $('#start_date').val();
            var today = new Date();
            today.setHours(0, 0, 0, 0);
            if (selectedDate) {
                selectedDate = new Date(selectedDate);
                selectedDate.setHours(0, 0, 0, 0);
                return date < selectedDate;
            }
            // else {
            //     return date < today;
            // }

        },
        displayBackendNotBeforeToday(date) {

            var selectedDate = $('#display_start_date').val();
            var today = new Date();
            today.setHours(0, 0, 0, 0);
            if (selectedDate) {
                selectedDate = new Date(selectedDate);
                selectedDate.setHours(0, 0, 0, 0);
                return date < selectedDate || date < today;
            } else {
                return date < today;
            }

        },
        isBusinessAccount: function () {
            var userData = this.$session.get('user_data');
            return typeof userData != 'undefined' && userData.role.code == 'super-admin' || userData.role.code == 'business-operator' ? true : false;
        },
        isAdministratorAccount: function () {
            var userData = this.$session.get('user_data');
            var result = (typeof userData != 'undefined' && userData.role.code == 'super-admin' || typeof userData != 'undefined' && userData.role.code == 'business-operator') ? true : false;
            // console.log('isAdministratorAccount', userData.role.code, result);
            return result;
        },
        haveAccess: function ($access) {

            var userData = this.$session.get('user_data');
            if (userData == null) {
                return false;
            }
            var findElement = [];
            // var result = $.inArray($access, userData.permissions);
            findElement = jQuery.grep(userData.permissions, function (matchstr) {
                return $access == matchstr ? true : false;
            });
            // console.log('have access', $access, userData.permissions, findElement);
            return findElement.length > 0 ? true : false;
        },
        beforeEnter() {
            this.$root.$emit('scrollBeforeEnter');
        },
        isClientBusinessAuth: function () {

            var userData = this.$session.get('user_data');
            return typeof userData != 'undefined' && !jQuery.isEmptyObject(userData.role) && userData.role.code != 'super-admin'
                && userData.role.code != 'business-operator' && userData.role.code != 'public' ? true : false;
        },
        clearVueSession: function (message) {
            this.$session.remove('user_data')
            this.$session.remove('access_token')
            // $(".alerts-div", "#loginModal").html(message);
            // $("#loginModal").modal("show");

        },
        exceptionAlert: function (response) {
            if (response.data.status_code == 500) {
                sweetalert2.fire("Error!", response.data.exception, "error");
            } else {
                sweetalert2.fire("Alert!", response.data.description, "warning");
            }


        },
        isNonVenue: function (type) {
            switch (type) {
                case 'People':
                    return true
                case 'Community':
                    return true
                case 'Annual':
                    return true
                default:
                    return false
            }
        },
        isAnnualEvents: function (type, category) {
            var arrData = ['Annual', 'Festival', 'National'];
            if (arrData.includes(type) || arrData.includes(category)) {
                return true
            } else {
                return false
            }
        },
        isRecurringCategories: function (type, category) {
            var arrData = ['Media', 'NightLife', 'Cannibas', 'Recurring',"Sports","Metaverse", "Entertainment"];
            if (arrData.includes(type) && arrData.includes(category)) {
                return true
            } else {
                return false
            }
        },
        nonAnnualEvents: function (type, category) {
            var arrData = ['Annual', 'Festival', 'National'];
            if (arrData.includes(type) || arrData.includes(category)) {
                return false
            } else {
                return true
            }
        },
        requiredVenueLocation: function (businessType, businessCategory) {
            var arrData = ['People', 'Community', 'PrivateLocation', 'AnnualEvent', 'VenueBig', 'VenueSmall'];
            if (arrData.includes(businessType) || arrData.includes(businessCategory)) {
                return false
            } else {
                return true
            }
        },
        getPrivateContacts: function (contacts) {
            var arrData = ['private-number', 'private-email', 'private-link'];
            var data = contacts.filter(function (obj) {
                return (arrData.includes(obj.provider) && obj.value != '') ? true : false
            });
            return data;
        },
        getSocialApps: function (apps) {
            
            let arrData = [
                'facebook', 
                'twitter', 
                'instagram',
                'youtube',
                'snapchat',
            ];
            let data = apps.filter(function (obj) {
                return (arrData.includes(obj.provider) && obj.value != '') ? true : false
            });
            return data;
        },
        getOtherSocialApps: function (apps) {
            
            let arrData = [
                'tiktok',
                'onlyfans',
                'clubhouse'
            ];
            let data = apps.filter(function (obj) {
                return (arrData.includes(obj.provider) && obj.value != '') ? true : false
            });
            return data;
        },
        hasReasonStatus: function (status) {
            const arrData = [2,3,4]
            return arrData.includes(parseInt(status)) ? true : false
        },
        getLiveLocation: function(){
 
            var vm = this;
            //Get GeoLocation location
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    function (position) {
                        vm.$session.set('live_location_lat', parseFloat(position.coords.latitude));
                        vm.$session.set('live_location_long', parseFloat(position.coords.longitude));
                    },
                    function (error) {
                        // alert(error.message);
                    }, {
                        enableHighAccuracy: true
                        , timeout: 1000
                    }
                    );
            } else {
                alert("Geolocation is not supported by this browser.");
            }
        },
        getMusicApps: function (apps) {
            var arrData = [
                'soundcloud',
                'spotify', 
                'applemusic',
                'itunesstore',
                'linemusic',
                'tidal',
                'youtubemusic',
                'amazonmusic',
                'pandora',
                'audiomack',
                'napster',
                'deezer',
            ];
            var data = apps.filter(function (obj) {
                return (arrData.includes(obj.provider) && obj.value != '') ? true : false;
            });

            const filteredArr = data.reduce((acc, current) => {
                const x = acc.find(item => item.provider === current.provider);
                if (!x) {
                  return acc.concat([current]);
                } else {
                  return acc;
                }
              }, []);

            return filteredArr;
        },
        getDeliveryApps: function (apps) {
            var arrData = [
                'doordash',
                'ubereats',
                'grubhub',
                'caviar',
                'explorestock',
                'postmates',
                'instacart',
                'slice',
                'chownow',
                'gopuff',
            ];
            var data = apps.filter(function (obj) {
                return (arrData.includes(obj.provider) && obj.value != '') ? true : false
            });
            return data;
        },
        getFoodMenuApps: function (apps) {
            var arrData = [
                'seamless',
            ];
            var data = apps.filter(function (obj) {
                return (arrData.includes(obj.provider) && obj.value != '') ? true : false
            });
            return data;
        },
        getOnlineTaxiApps: function (apps) {
            var arrData = [
                'uber',
                'lyft',
            ];
            var data = apps.filter(function (obj) {
                return (arrData.includes(obj.provider) && obj.value != '') ? true : false
            });
            return data;
        },
        getTableReservationApps: function (apps) {
            var arrData = [
                'opentable',
                'yelp',
            ];
            var data = apps.filter(function (obj) {
                return (arrData.includes(obj.provider) && obj.value != '') ? true : false
            });
            return data;
        },
        getLuxryServicessApps: function (apps) {
            var arrData = [
                'vistajet',
                'yachtlife',
            ];
            var data = apps.filter(function (obj) {
                return (arrData.includes(obj.provider) && obj.value != '') ? true : false
            });
            return data;
        },
        isPrivateContacts: function (contacts) {
            var arrData = ['private-number', 'private-email', 'private-link'];
            var data = contacts.filter(function (obj) {
                return (arrData.includes(obj.provider) && obj.value != '') ? true : false
            });
            return data.length > 0 ? true : false;
        },
        isPrivateEvent: function (eventType) {
            var arrData = ['PrivateLocation'];
            if (arrData.includes(eventType)) {
                return true
            } else {
                return false
            }
        },
        nonNightLifeEvent: function (eventType, eventCategory) {
            var arrData = ['Recurring', 'PrivateLocation', 'NightLife'];
            if (arrData.includes(eventType) && arrData.includes(eventCategory)) {
                return true
            } else {
                return false
            }
        },
        nonPrivateRecurringEvent: function (eventType, eventCategory) {
            var arrData = ['Recurring', 'PrivateLocation'];
            if (!arrData.includes(eventType) && !arrData.includes(eventCategory)) {
                return true
            } else {
                return false
            }
        },
        searchHashTag(val) {
            this.$router.push({ name: 'home', query: { q: val } });
        },
        // checkNetworkConnection: function () {
        //     this.connectionStatus = (navigator.onLine)? true :false;
        // },
        filterEventByDateRange: function (date) {
            console.log('filterEventByDateRange', date);
            setTimeout(function () {
                $('#event-search-form').submit()
            }, 1000)
        },
        displayMessage: function () {
            console.log('Now printing from a mixin function');
        },
        inArray: function (arr, val) {
            return arr.indexOf(val) > -1 ? true : false;
        },
        checkHash: function (val, string) {
            return string.indexOf(val) > -1 ? true : false;
        },
        showBusinessSubTypes: function () {
            console.log('Now printing from a mixin function');
        },
        limitStr: function (str, limit) {

            var limitStr = str;
            if (str && str.length > limit) {
                limitStr = str.substring(0, limit) + ".."
            }
            return limitStr;
        },
        isActiveFilter: function (value) {
            return value != '' ? true : false;
        },
        isArray: function (value) {
            return Array.isArray(value) ? true : false
        },
        searchTimeOut(value, keyBtn) {

            var vm = this;
            if (keyBtn === 13 || keyBtn === 37 || keyBtn === 38 || keyBtn === 39 || keyBtn === 40) {
                return false;
            }
            if (this.timer) {
                clearTimeout(this.timer);
                vm.timer = null;
            }
            this.timer = setTimeout(() => {
                bus.$emit('search_page_term_emit', value);

            }, 500);
        },
        isActiveDateFilter: function (date) {
            return date.start != '' & date.end != '' ? true : false;
        },
        url: function (path) {

            var vm = this;
            var path = window.axios.defaults.baseURL + '/' + path;
            if (typeof vm.$session.get('access_token') == 'undefined') {
                path = 'javascript:void(0)';
            }
            return path;
        },
        getPath: function (path) {

            var vm = this;
            var path = window.axios.defaults.baseURL + '/' + path;

            return path;
        },
        socialShareLink: function (path) {

            var vm = this;
            var path = window.axios.defaults.baseURL + '/' + path;
            return path;
        },
        adminEditOptionURL: function (type, id) {
            var vm = this;
            var path = '';
            var base_URL = window.axios.defaults.baseURL
            if (type == 'ads') {
                path = base_URL + '/admin/ads/edit/' + id;
            } else if (type == 'event') {
                path = base_URL + '/admin/event/edit/' + id;
            } else if (type == 'business') {
                path = base_URL + '/admin/business/venue/edit/' + id;
            } else {
                path = 'javascript:void(0)'
            }
            return path;
        },
        venueURL: function (venue_type, id) {

            var path = window.axios.defaults.baseURL;
            var vm = this;
            if (typeof vm.$session.get('access_token') == 'undefined') {
                return path = 'javascript:void(0)';
            }

            if (venue_type == 'People') {
                path += '/#/people/' + id;
            } else if (venue_type == 'Community') {
                path += '/#/community/' + id;
            } else {
                // path += '/venue-detail/' + id;
                path += '/#/venue/' + id;
            }
            return path;
        },
        openNav: function () {
            document.getElementById("mySidenav").style.width = "100%";
        },
        closeNav: function () {
            document.getElementById("mySidenav").style.width = "0";
        }

    },
    filters: {

        assetPath: function (img) {
            var path = window.axios.defaults.baseURL + '/images/' + img;
            return path;
        },
        fullAddress: function (event) {
            var address = event.city
            address += (event.state != null) ? ', ' + (event.state) : ''
            address += (event.zip_code != null) ? ', ' + (event.zip_code) : ''
            return address;
        },
        checkObj: function (obj) {
            return (obj == '') ? false : true
        },
        url: function (path) {
            var path = window.axios.defaults.baseURL + '/' + path;
            return path;
        }
    }
}