<template>
  <div class="subscription-desktop-view hidden-xs">
    <div class="tab-content pricinng-plan" id="nav-tabContent">
      
      <div class="tab-pane fade show active in" style="margin-left: 100px;" id="free-plan" role="tabpanel" aria-labelledby="free-plan-tab">
<!-- 
        <div  class="col-lg-3 col-md-3 col-sm-3 col-xs-12 mb-4 plan-card trial-card-ele" :class="[loadingContent !=true ? 'active':'']">
          <DefaultCardView/>
        </div> -->
        
        <div  class="col-lg-3 col-md-3 col-sm-3 col-xs-12 mb-4 plan-card trial-card-ele" :class="[loadingContent !=true ? 'active':'']">
          <TrialCardView/>
        </div>

        <template v-for="(plan,index) in packages">
          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 mb-4 plan-card">
            <CardView :plan="plan" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import CardView from "./../../../views/partials/subscription/card-view";
import TrialCardView from "./../../../views/partials/subscription/trial-card-view";
import DefaultCardView from "./../../../views/partials/subscription/default-card-view";

import helpers from "./../../../mixin/helpers";

export default {

  data() {
    return {
      loadingContent: true,
      subscriptionsList: [],
      elementsOptions: {
        appearance: {}, // appearance options
        clientSecret:""
      },
    };
  },
  props: ["packages"],
  mixins: [helpers],
  components: {
    CardView,
    TrialCardView,
    DefaultCardView
  },
  created: function(){
      let vm = this;
      setTimeout(() => {
        vm.loadingContent = false 
      }, 100);
  },
};
</script>