<template>
  <div>
    <header_section></header_section>
    <!-- Main Start -->
    <main class="top-inner-pages faqs-page">
      <section class="subscription-page vgps_contact_support_wrapper">
        <div class="container">
          <div class="row">
              <div class="col-md-12">
                <h2 class="text-center">Subscription Details</h2>

                <div v-if="loadingContent" class="content-loader hidden-lg hidden-md">
                  <content-loader
                    width="350"
                    height="600"
                    viewBox="0 0 350 600"
                    backgroundColor="#f5f5f5"
                    foregroundColor="#dbdbdb"
                  >
                    <rect x="4" y="8" rx="3" ry="3" width="8" height="570" />
                    <rect x="5" y="573" rx="3" ry="3" width="331" height="7" />
                    <rect x="329" y="9" rx="3" ry="3" width="8" height="570" />
                    <rect x="102" y="69" rx="3" ry="3" width="102" height="7" />
                    <rect x="92" y="47" rx="3" ry="3" width="178" height="6" />
                    <circle cx="48" cy="63" r="18" />
                    <rect x="95" y="95" rx="3" ry="3" width="178" height="6" />
                    <rect x="105" y="169" rx="3" ry="3" width="102" height="7" />
                    <rect x="95" y="147" rx="3" ry="3" width="178" height="6" />
                    <circle cx="51" cy="163" r="18" />
                    <rect x="98" y="195" rx="3" ry="3" width="178" height="6" />
                    <rect x="107" y="265" rx="3" ry="3" width="102" height="7" />
                    <rect x="97" y="243" rx="3" ry="3" width="178" height="6" />
                    <circle cx="53" cy="259" r="18" />
                    <rect x="100" y="291" rx="3" ry="3" width="178" height="6" />
                    <rect x="108" y="365" rx="3" ry="3" width="102" height="7" />
                    <rect x="98" y="343" rx="3" ry="3" width="178" height="6" />
                    <circle cx="54" cy="359" r="18" />
                    <rect x="101" y="391" rx="3" ry="3" width="178" height="6" />
                    <rect x="110" y="458" rx="3" ry="3" width="102" height="7" />
                    <rect x="100" y="436" rx="3" ry="3" width="178" height="6" />
                    <circle cx="56" cy="452" r="18" />
                    <rect x="103" y="484" rx="3" ry="3" width="178" height="6" />
                    <rect x="114" y="507" rx="3" ry="3" width="102" height="7" />
                    <rect x="103" y="534" rx="3" ry="3" width="178" height="6" />
                    <rect x="5" y="8" rx="3" ry="3" width="331" height="7" />
                  </content-loader>
                </div>
                
                <div v-if="loadingContent" class="content-loader hidden-xs">
                  <content-loader
                    width="1490"
                    height="475"
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                  >
                    <rect x="0" y="20" rx="8" ry="8" width="350" height="350" />
                    <rect x="0" y="390" rx="0" ry="0" width="200" height="18" />
                    <rect x="0" y="420" rx="0" ry="0" width="120" height="20" />

                    <rect x="380" y="20" rx="8" ry="8" width="350" height="350" />
                    <rect x="380" y="390" rx="0" ry="0" width="200" height="18" />
                    <rect x="380" y="420" rx="0" ry="0" width="120" height="20" />

                    <rect x="760" y="20" rx="8" ry="8" width="350" height="350" />
                    <rect x="760" y="390" rx="0" ry="0" width="200" height="18" />
                    <rect x="760" y="420" rx="0" ry="0" width="120" height="20" />

                    <rect x="1140" y="20" rx="8" ry="8" width="350" height="350" />
                    <rect x="1140" y="390" rx="0" ry="0" width="200" height="18" />
                    <rect x="1140" y="420" rx="0" ry="0" width="120" height="20" />
                  </content-loader>
                </div>
                
                <DesktopView :packages="filteredSubscriptions"/>  
                <MobileView :packages="filteredSubscriptions"/>
              </div>
          </div>
        </div>
      </section>
      <!-- Dashboard End -->
    </main>
    <!-- Main End -->
    <footer_section></footer_section>
  </div>
</template>


<script>
import HeaderSection from "./../views/partials/header";
import FooterSection from "./../views/partials/footer";
import DesktopView from "./../views/partials/subscription/desktop-view";
import MobileView from "./../views/partials/subscription/mobile-view";
import Information_Sidebar from './../views/partials/information-sidebar'
import helpers from "./../mixin/helpers";
import { ContentLoader } from "vue-content-loader";

export default {
  data() {
    return {
      loadingContent: false,
      subscriptionsList: [],
    };
  },
  created: function(){
      let vm = this;
      vm.getDetail();

  },
  computed: {
    filteredSubscriptions() {

      let filteredSubscription = []
      filteredSubscription = this.subscriptionsList.filter((item) => {
        return item.title != 'Trial'
      })
      return filteredSubscription
    }
  },
  mixins: [helpers],
  components: {
    footer_section: FooterSection,
    header_section: HeaderSection,
    information_sidebar: Information_Sidebar,
    DesktopView,
    MobileView,
    ContentLoader
  },
  methods: {
    async getDetail() {
      let vm = this;
      vm.loadingContent = true;
      axios.get("data/subscription").then( (response) => {
          
          if (response.data.success == false) {

              if(response.data.status_code == 403){
                  this.clearVueSession(response.data.template);
              }else{
                  sweetalert2.fire("", response.data.description, "warning");
              }
              vm.$router.back()
          } else {
              vm.subscriptionsList = response.data.data;
          }
          vm.loadingContent = false;
      })
      .catch(function (errors) {
          console.log('user profile get exception',errors);
          sweetalert2.fire("", errors.data, "error");
          vm.loadingContent = false;
      });
    },
  },
};
</script>