<template>
  <div>
    <Select2
      name="category"
      id="sub_category"
      v-model="sub_cat"
      placeholder="Select One"
      :options="businessSubTypes"
      :settings="{placeholder:'Select One',class:'floating-label-field form-control floating-label-field--s3 form-control',selectOnClose: false,allowClear: true,closeOnSelect: true}"
    />
  </div>
</template>
<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";
import Select2 from "v-select2-component";

export default {
  props: ['category',"sub_category"],
  mixins: [helpers],
  watch: {},
  updated() {

  },
  created() {
    this.sub_cat = this.sub_category;
  },
  computed: {
    businessSubTypes(){
        return this.$store.getters.businessSubTypes;
    },
  },
  components: {
    Select2
  },
  data: function() {
    return {
      sub_cat: ""
    };
  },
  methods: {
    // updateValues:function(data){
    //     this.end_date  = data;
    // },
    // selectDataRange: function(event) {
    //     var vm = this;
    // },
  }
};
</script>
