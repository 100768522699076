import VueRouter from 'vue-router';

// vue-routing-anchor-parser
// https://github.com/BKWLD/vue-routing-anchor-parser


// Frontend Routes ---------------------------
import HomePage from '../views/home';
import SearchPage from '../views/search';
import MapViewPage from '../views/mapview';
import DirectoryPage from '../views/directory';
import FAQPage from '../views/faqs';
import TutorialPage from '../views/tutorials';
import Team_Page from '../views/team';
import PrivacyPage from '../views/privacy-policy';
import AboutPage from '../views/about';
import ClaimBusinessPage from '../views/claim-business';
import CEOMessagePage from '../views/ceo-message';
import PressKintPage from '../views/press-kit';
import OurLogoPage from '../views/our-logo';
import Covid19Page from '../views/covid-19';
import ImageSizeMatrixPage from '../views/image-size-matrix';
import SneakPreviewtPage from '../views/sneak-preview';
import VisionPage from '../views/vision';
import ContactUsPage from '../views/contact-us';
import EventDetail from '../views/event-detail';
import VenueDetail from '../views/venue-detail';
import TermsOfUsePage from '../views/terms-of-use';
import NotFoundPage from '../views/4o4-page';
import subscriptionPage from '../views/subscription';
import AllCardView from '../views/partials/subscription/all-card-view';

import successPaymentPage from '../views/payment-success';
import ComingSoonPage from '../views/coming-soon-page';
import AdvertisingPage from '../views/advertising-page';

// const My_Venues = () => import('../views/user/following-venues')

// User Components ------------------------------
import My_Venues from '../views/user/following-venues';
import My_Events from '../views/user/following-events';
import User_Profile from '../views/user/profile';
import User_Settings from '../views/user/settings';

import User_Prefrences from '../views/user/prefrences';
import User_Notifications from '../views/user/notifications';

export default new VueRouter({
    mode: 'hash', // "hash" | "history" | "abstract"
    base : 'vgps-frontend/public/',
    // relative: false,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition
        } else {
          return { x: 0, y: 0 }
        }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomePage,
            meta: {
                title: 'Home',
                breadcrumb: 'Welcome to VenueGPS',
                bodyClass: 'home-page' 
            }
        },
        {
            path: '/search',
            name: 'search',
            component: SearchPage,
            meta: {
                title: 'Search',
                breadcrumb: 'Search Results',
                bodyClass: 'search-page' 
            }
        },
        {
            path: '/map-view',

            name: 'mapview',
            component: MapViewPage,
            meta: {
                title: 'Map View',
                breadcrumb: 'Home / Map View',
                bodyClass: 'mapview'
            }
        },
        {
            path: '/directory',
            name: 'directory',
            component: DirectoryPage,
            meta: {
                title: 'Venue Directories',
                breadcrumb: 'Home / Venue Directories',
                bodyClass: 'directory'
            }
        },
        {
            path: '/faqs',
            name: 'faqs',
            // component: FAQPage,
            component: ComingSoonPage,
            meta: {
                title: "FAQ's",
                breadcrumb: "Home / FAQ's",
                bodyClass: 'faqs'
            }
        },
        {
            path: '/tutorial',
            name: 'tutorial',
            component: TutorialPage,
            meta: {
                title: "Tutorial",
                breadcrumb: "Home / Tutorial",
                bodyClass: 'tutorial'
            }
        },
        {
            path: '/venue-live',
            name: 'venue-live',
            // component: FAQPage,
            component: ComingSoonPage,
            meta: {
                title: "Venue Live",
                breadcrumb: "Home / Venue Live",
                bodyClass: 'venue-live'
            }
        },
        {
            path: '/venue-aid',
            name: 'venue-aid',
            // component: FAQPage,
            component: ComingSoonPage,
            meta: {
                title: "Venue Aid",
                breadcrumb: "Home / Venue Aid",
                bodyClass: 'venue-aid'
            }
        },
        {
            path: '/venue-store',
            name: 'venue-store',
            // component: FAQPage,
            component: ComingSoonPage,
            meta: {
                title: "Venue Store",
                breadcrumb: "Home / Venue Store",
                bodyClass: 'venue-store'
            }
        },
        {
            path: '/venue-market',
            name: 'venue-market',
            // component: FAQPage,
            component: ComingSoonPage,
            meta: {
                title: "Venue Market",
                breadcrumb: "Home / Venue Market",
                bodyClass: 'venue-market'
            }
        },
        {
            path: '/site-map',
            name: 'site-map',
            // component: FAQPage,
            component: ComingSoonPage,
            meta: {
                title: "SiteMap",
                breadcrumb: "Home / SiteMap",
                bodyClass: 'site-map'
            }
        },
        {
            path: '/retired-digital',
            name: 'retired-digital',
            // component: FAQPage,
            component: ComingSoonPage,
            meta: {
                title: "Retired Digital",
                breadcrumb: "Home / Retired Digital",
                bodyClass: 'retired-digital'
            }
        },
        {
            path: '/careers',
            name: 'careers',
            // component: FAQPage,
            component: ComingSoonPage,
            meta: {
                title: "Careers",
                breadcrumb: "Home / Careers",
                bodyClass: 'retired-digital'
            }
        },
        {
            path: '/careers',
            name: 'careers',
            // component: FAQPage,
            component: ComingSoonPage,
            meta: {
                title: "Careers",
                breadcrumb: "Home / Careers",
                bodyClass: 'retired-digital'
            }
        },
        {
            path: '/user-subscription',
            name: 'all_cards',
            component: AllCardView,
        },
        {
            path: '/subscription',
            name: 'subscription',
            component: subscriptionPage,
            meta: {
                title: "Subscription",
                breadcrumb: "Home / Subscription",
                bodyClass: 'subscription-page'
            }
        },
        {
            path: '/subscription/done',
            name: 'payment_done',
            component: successPaymentPage,
            meta: {
                title: "Successfull Payment",
                breadcrumb: "Home / Successfull Payment",
                bodyClass: 'successfull-payment'
            }
        },
        {
            path: '/team',
            name: 'team',
            // component: Team_Page,
            component: ComingSoonPage,
            meta: {
                title: "Meet Our Team",
                breadcrumb: "Home / Team",
                bodyClass: 'team'
            }
        },
        {
            path: '/contact-support',
            name: 'contact-support',
            component: ContactUsPage,
            meta: {
                title: "Contact Support",
                breadcrumb: "Home / Contact Support",
                bodyClass: 'contact-support'
            }
        },
        {
            path: '/claim-business',
            name: 'claim-business',
            component: ClaimBusinessPage,
            meta: {
                title: "Claim Business",
                breadcrumb: "Home / Claim Business",
                bodyClass: 'claim-business'
            }
        },
        {
            path: '/privacy-policy',
            name: 'privacy-policy',
            component: PrivacyPage,
            // component: ComingSoonPage,
            meta: {
                title: "Privacy Policy",
                breadcrumb: "Home / Privacy Policy",
                bodyClass: 'Privacy Policy'
            }
        },
        {
            path: '/ceo-message',
            name: 'ceoMessage',
            component: CEOMessagePage,
            meta: {
                title: "CEO Message",
                breadcrumb: "Home / CEO Message",
                bodyClass: 'CEO Message'
            }
        },
        {
            path: '/vision',
            name: 'vision',
            // component: VisionPage,
            component: ComingSoonPage,
            meta: {
                title: "Vision",
                breadcrumb: "Home / Vision",
                bodyClass: 'Vision'
            }
        },
        {
            path: '/about-us',
            name: 'about',
            // component: AboutPage,
            component: ComingSoonPage,
            meta: {
                title: "About",
                breadcrumb: "Home / About",
                bodyClass: 'About'
            }
        },
        {
            path: '/tutorial',
            name: 'tutorial',
            // component: AboutPage,
            component: ComingSoonPage,
            meta: {
                title: "Tutorial",
                breadcrumb: "Home / Tutorial",
                bodyClass: 'tutorial'
            }
        },
        {
            path: '/press-kit',
            name: 'press-kit',
            component: PressKintPage,
            meta: {
                title: "Press Kit",
                breadcrumb: "Home / Press Kit",
                bodyClass: 'press-kit'
            }
        },
        {
            path: '/our-logo',
            name: 'our-logo',
            component: OurLogoPage,
            meta: {
                title: "Our Logo",
                breadcrumb: "Home / Our Logo",
                bodyClass: 'our-logo'
            }
        },
        {
            path: '/covid-19',
            name: 'covid-19',
            component: Covid19Page,
            meta: {
                title: "Covid-19",
                breadcrumb: "Home / Covid-19",
                bodyClass: 'covid-19'
            }
        },
        {
            path: '/advertising-guidelines',
            name: 'advertising-guidelines',
            // component: ImageSizeMatrixPage,
            component: AdvertisingPage,
            meta: {
                title: "Advertising Guidelines",
                breadcrumb: "Home / Advertising Guidelines",
                bodyClass: 'advertising-guidelines'
            }
        },
        {
            path: '/pricing',
            name: 'pricing',
            component: ComingSoonPage,
            // component: ImageSizeMatrixPage,
            meta: {
                title: "Pricing",
                breadcrumb: "Home / Pricing",
                bodyClass: 'pricing'
            }
        },
        {
            path: '/image-size-matrix',
            name: 'image-size-matrix',
            component: ImageSizeMatrixPage,
            meta: {
                title: "Image Size Matrix",
                breadcrumb: "Home / Image Size Matrix",
                bodyClass: 'image-size-matrix'
            }
        },
        {
            path: '/sneak-preview',
            name: 'sneak-preview',
            component: SneakPreviewtPage,
            meta: {
                title: "Sneak Preview",
                breadcrumb: "Home / Sneak Preview",
                bodyClass: 'sneak-preview'
            }
        },
        {
            path: '/terms-of-use',
            name: 'terms-of-use',
            component: TermsOfUsePage,
            // component: ComingSoonPage,
            meta: {
                title: "Terms of Use ",
                breadcrumb: "Home / Terms of Use",
                bodyClass: 'terms-of-use'
            }
        },
        {
            path: '/contact-us',
            name: 'contact-us',
            component: ContactUsPage,
            meta: {
                title: "Contact Us ",
                breadcrumb: "Home / Contact Us",
                bodyClass: 'contact-us'
            }
        },
        {
            path: '/event/:id',
            name: 'event',
            component: EventDetail,
            meta: {
                title: "Event Detail",
                breadcrumb: "Home / Event Detail",
                bodyClass: 'event-detail',
                reload: true,
            }
        },
        {
            path: '/venue/:id',
            // path: '/venue/:title',
            name: 'venue',
            component: VenueDetail,
            meta: {
                title: "Venue Detail",
                breadcrumb: "Home / Venue Detail",
                bodyClass: 'venue-detail',
                reload: true,
            }
        },
        {
            path: '/people/:id',
            name: 'venue',
            component: VenueDetail,
            meta: {
                title: "Venue Detail",
                breadcrumb: "Home / Venue Detail",
                bodyClass: 'venue-detail',
                reload: true,
            }
        },
        {
            path: '/community/:id',
            name: 'venue',
            component: VenueDetail,
            meta: {
                title: "Venue Detail",
                breadcrumb: "Home / Venue Detail",
                bodyClass: 'venue-detail',
                reload: true,
            }
        },
        // User Routes -------------------------------
        {
            path: '/user/profile',
            name: 'user_profile',
            component: User_Profile,
            meta: {
                title: 'My Profile',
                breadcrumb: 'User / My Profile',
                bodyClass: 'user-profile' 
            }
        },
        {
            path: '/user/settings',
            name: 'user_settings',
            component: User_Settings,
            meta: {
                title: 'My Settings',
                breadcrumb: 'User / My Settings',
                bodyClass: 'user-settings' 
            }
        },
        {
            path: '/user/prefrences',
            name: 'user_prefrences',
            component: User_Prefrences,
            meta: {
                title: 'My Prefrences',
                breadcrumb: 'User / My Prefrences',
                bodyClass: 'user-prefrences' 
            }
        },
        {
            path: '/notification/setting',
            name: 'user_notification_settings',
            component: User_Notifications,
            meta: {
                title: 'Notification Setting',
                breadcrumb: 'User / Notification Settings',
                bodyClass: 'user-notifications' 
            }
        },
        {
            path: '/following-venues',
            name: 'my_venues',
            component: My_Venues,
            meta: {
                title: 'My Accounts',
                breadcrumb: 'User / My Accounts',
                bodyClass: 'my-following-venue' 
            }
        },
        {
            path: '/following-events',
            name: 'my_events',
            component: My_Events,
            meta: {
                title: 'My Following Event',
                breadcrumb: 'User / My Events',
                bodyClass: 'my-following-events' 
            }
        },
        {
            path: '/coming-soon',
            name: 'coming-soon',
            component: ComingSoonPage,
            meta: {
                title: 'Coming Soon',
                breadcrumb: 'Home / Coming Soon',
                bodyClass: 'coming-soon' 
            }
        },

        { path: '*', redirect: '/' }, // catch all use case
    ],
});
