<template>
     <section class="my-venue">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h2>Venues</h2>
              <select class="js-select2">
                <option>NightLife</option>
                <option>Select A</option>
                <option>Select B</option>
                <option>Select C</option>
              </select>
            </div>
          </div>
        </div>
  
      <div class="venue-content">
        <div class="container">
          <div class="row">
            <div class="col-md-3 col-sm-6 col-xs-6">
              <div class="content">
                <div class="pic">
                  <!-- <img src="./images/01.png" alt="Image" /> -->
                  main image
                </div>  
                <span>24 Events</span>
                <h6>Madlife Stage & Studios</h6>
                <p>800 Battery Ave SE</p>
                <p>Atlanta, GA 30339</p>
                <span>#chrisbrown  #xtour</span>
                <!-- <a class="follow mobile-hide" href="#"><img src="./images/fav-w.svg" /> Following</a>
                <a class="share mobile-hide" href="#"><img src="./images/share.svg" /> Share</a>
                <a class="follow desktop-hide inline" href="#"><img src="./images/fav-w.svg" /></a>
                <a class="share desktop-hide inline" href="#"><img src="./images/share.svg" /></a> -->
              </div>
            </div>
        </div>
      </div> 
      </div>    
    </section>
</template>

<script>
    export default {
        mounted() {
            // axios.get("data/cities")
            //     .then(response => {
            //         this.citiesListing = response.data.data;
            //     })
            //     .catch(function (errors) {
            // });
        },
        data() {
            return {
                loadingContent:false,
                venueListing: []
            }
        },
        methods: {
        }
    }
</script>