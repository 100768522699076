<template>
    <div v-if="isLocationEnable" class="vgps-top-location-search-wrapper vgps-mobile-top-location-search-wrapper hidden-md hidden-lg">
        <a href="#" class="top-location-city mobile-location-button">{{location_city}}</a>
         <!-- <a href="javascript:void(null);" class="top-location-city" 
        data-toggle="modal" data-target="#select-city-modal" 
        :class="{ active: location }">{{ location_city }}</a> -->
        <ul class="vgps-location-search-wrapper mobile-google-search">
            <li>
                <div class="form-inline location-search ">
                    <vue-bootstrap-typeahead 
                        :data="addresses"
                        v-model="addressSearch"
                        size="lg"
                        id="location-field"
                        inputClass="city-location-field"
                        :serializer="s => s.name"
                        ref="mobileLocationFieldSearchRef"
                        placeholder="Where are you going?"
                        @hit="setAddressData($event)"
                    />
                    <!-- <vue-google-autocomplete
                            ref="mobileAddress"
                            id="mobileAddress-field"
                            classname="form-inline location-search"
                            placeholder="Where are you going?"
                            v-on:placechanged="getAddressData"
                            types="(cities)"
                            :country="['us']">
                    </vue-google-autocomplete> -->
                    <span v-show="!processing" :click="close()" class="clear-location-search"><img :src="'close.svg' | assetPath" alt="clear"/></span>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>

    import {bus} from '../../app'
    // import VueGoogleAutocomplete from 'vue-google-autocomplete'
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';

    export default {
        components: {
            VueBootstrapTypeahead,
            // VueGoogleAutocomplete
        },
        props: [],
        data: function () {
            return {
                address: '',
                query:"",
                addresses: [],
                addressSearch: '',
                selectedAddress: {},
                location: '',
                location_city: '',
                processing:false,
                state: '',
                showLocation: false,
                isLocationEnable: false
            }
        },
        watch: {
             addressSearch: _.debounce(function(addr) { this.getAddresses(addr) }, 500)
        },
        created() {

            var vm = this; 
            bus.$on('citySearchFilter', (city) => {
                this.location_city = city;
            });

            bus.$on('setTopCityStateCode', (state) => {
                vm.state = state;
            });
            // bus.$on('updateLocation', () => {
            //     console.log('updateLocation',this.$session.get('default_location')+', '+this.$session.get('default_state'));
            //     vm.location_city = this.$session.get('default_location');
            //     $('#select-city-modal').modal('hide')
            // });
            bus.$on('updateDefaultIPLocation', () => {
                this.location_city = this.$session.get('default_location');
                this.state = this.$session.get('default_state');
            });
            bus.$on('updateLocation', () => {
                vm.location_city = this.$session.get('default_location')+', '+this.$session.get('default_state')
                $('#select-city-modal').modal('hide')
            });


        },
        mounted() {
            
            var vm = this;
            if ($(window).width() < 600) {
                this.isLocationEnable = true;
            }

            // To demonstrate functionality of exposed component functions
            // Here we make focus on the user input
            vm.location_city = this.$session.get('default_location')+', '+this.$session.get('default_state')
            vm.state = this.$session.get('default_state');
            
            vm.showLocation = true;

            jQuery(document).ready(function ($) {

                $('.mobile-location-button').click(function (f) {
                    setTimeout(function(){
                        $('.mobile-google-search').addClass('search-visible');
                        vm.$refs.mobileAddress.$el.focus();
                    },1000);
                });

                $('.vgps-top-location-search-wrapper .vgps-location-search-wrapper .location-search input').on('keypress', function () {
                    $(this).parent().addClass('search-active');
                }).on('keydown', function (e) {
                    if (e.keyCode == 8){
                        if($(this).val().length == 1){
                            $(this).parent().removeClass('search-active');
                        }
                    }
                });

                $('.vgps-top-location-search-wrapper .vgps-location-search-wrapper .location-search .clear-location-search').click(function () {
                    $('.vgps-top-location-search-wrapper .vgps-location-search-wrapper .location-search input').val('');
                    $(this).parent().removeClass('search-active');
                });

            });

        },
        methods: {

            getAddresses:function (query) {
                let vm = this;
                vm.processing = true;
                vm.addresses = [];

                axios.get("data/get-city", {params: {
                    name: query,
                },
                }).then(function (response) {
                    console.log('City search result',response)
                    vm.addresses = response.data
                    vm.processing = false;
                })
                .catch(function (errors) {
                    vm.processing = false;
                    console.error(errors)
                });
                
            },

            /**
             * When the location found
             * @param {Object} address Data of the found location
             */
            setAddressData: function (data) {
                console.log('=============== Select City Address ===================',data)
                this.selectedAddress = data;
                this.addressSearch = '';
                this.$session.set('default_location', data.city);
                
                this.$session.set('default_location_lat', data.latitude);
                this.$session.set('default_location_long', data.longitude);
                this.$session.set('default_state', data.state_code);

                // let fullLocation = this.$session.get('default_location')+', '+this.$session.get('default_state')
                // $('#locationTextId').text(fullLocation);
                this.location = this.$session.get('default_location');
                bus.$emit('updateLocation', this.$session.get('default_location'))
                bus.$emit('updateCityMap', {lat: data.latitude, long: data.longitude})
                bus.$emit('updateLatLong',data)

                jQuery(document).ready(function ($) {
                    $('.vgps-top-location-search-wrapper .vgps-location-search-wrapper').removeClass('search-visible');
                    $(".city-location-field").val('');    
                });

            },

            /**
             * When the location found
             * @param {Object} addressData Data of the found location
             * @param {Object} placeResultData PlaceResult object
             * @param {String} id Input container ID
             */
            getAddressData: function (addressData, placeResultData, id) {

                var formatedData = placeResultData.formatted_address;
                formatedData = formatedData.split(',').reverse();
                console.log('City Stte', formatedData)

                console.log('Location Data', addressData)
                this.address = addressData;
                if (addressData.locality == undefined) {
                    this.location = (formatedData.length > 3)? formatedData[3]:formatedData[2];
                } else {
                    this.location = (formatedData.length > 3)? formatedData[3]: addressData.locality;
                    this.state = addressData.administrative_area_level_1;
                }

                this.$session.set('default_location', this.location);
                this.$session.set('default_location_lat', addressData.latitude);
                this.$session.set('default_location_long', addressData.longitude);
                this.$session.set('default_state', addressData.administrative_area_level_1);
//
                console.log('Vue session get default location', this.$session.get('default_location'));

                this.location_city = this.location;
                // $('#locationTextId').text(this.$session.get('default_location'));
//                this.location = this.location;
                bus.$emit('updateLocation', this.$session.get('default_location'))
                bus.$emit('updateCityMap', {lat: addressData.latitude, long: addressData.longitude})
                $('.location-search').val('');
                $('.vgps-top-location-search-wrapper .vgps-location-search-wrapper').removeClass('search-visible');

            },
            close: function(){                
                console.log('Remove class search visible');
                // $('ul.vgps-location-search-wrapper').removeClass('search-visible');
            },
            selectCityOption: function() {
                let vm = this;
                // $('.vgps-location-search-wrapper').toggleClass('search-visible');
                vm.$refs.mobileLocationFieldSearchRef.inputValue = '';    
                vm.$refs.mobileLocationFieldSearchRef.isFocused = true;    
            }
        },
        filters: {
            assetPath: function (img) {
                var path = window.axios.defaults.baseURL+'/images/'+img;
                return path;
            } 
        }
    }

</script>
