<template>
    <li v-if="isLocationEnable" class="vgps-top-location-search-wrapper hidden-xs" >
        <!-- <a href="javascript:void(null);" class="top-location-city" 
        data-toggle="modal" data-target="#select-city-modal" 
        :class="{ active: location }">{{ location_city }}</a> -->

        <a href="javascript:void(null);" class="top-location-city"  v-click-outside="close"
        :class="{ active: location }" v-on:click="selectCityOption" id="locationTextId-2" >{{ location_city }}</a>
        <ul class="vgps-location-search-wrapper" v-click-outside="close">
            <li>
                <div class="form-inline location-search">
                    <vue-bootstrap-typeahead 
                        :data="addresses"
                        v-model="addressSearch"
                        size="lg"
                        id="location-field"
                        inputClass="city-location-field"
                        backgroundVariant="red"
                        :serializer="s => s.name"
                        inputValue="addressSearch"
                        placeholder="Where are you going?"
                        ref="locationFieldSearchRef"
                        @hit="setAddressData($event)"
                        />
                    <!-- <vue-google-autocomplete
                            ref="topCity"
                            id="map"
                            :country="['us']"
                            classname="form-inline location-search"
                            placeholder="Where are you going?"
                            v-on:placechanged="getAddressData"
                            autofocus="true"
                            :types="types">
                    </vue-google-autocomplete> -->
                    <img v-bind:class="{ active: processing }" class="search-loader" :src="'ajax-loader.gif' | assetPath" alt="processing"/>
                    <span :class="[ !processing && addressSearch? 'active' : '']" :click="close()" class="clear-location-search"><img :src="'close.svg' | assetPath" alt="clear"/></span>
                </div>
            </li>
        </ul>
    </li>

</template>

<script>

    import {bus} from '../../app';
    import  _  from 'lodash';

    // import VueGoogleAutocomplete from 'vue-google-autocomplete'
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'; // https://vuejsexamples.com/an-autocomplete-typeahead-component-for-vue-2-and-bootstrap-4/

    export default {
        components: {
            // VueGoogleAutocomplete,
            VueBootstrapTypeahead
        },
        props: [],
        data: function () {
            return {
                address: '',
                query:"",
                addresses: [],
                addressSearch: '',
                selectedAddress: null,
                location:'',
                location_city: '',
                state: '',
                isLocationEnable: false,
                showLocation: false,
                processing: false,
                isSharedLocation:false,
                types: '(cities)',
            }
        },   
        watch: {
             addressSearch: _.debounce(function(addr) { this.getAddresses(addr) }, 500)
        },  
        created() {

            var vm = this;
            bus.$on('citySearchFilter', (city) => {
                this.location_city = city;
            });
            bus.$on('setTopCityStateCode', (state) => {
                vm.state = state;
            });
            bus.$on('updateLocation', () => {
                console.log('updateLocationsss',this.$session.get('default_location')+', '+this.$session.get('default_state'));
                vm.location_city = this.$session.get('default_location')+', '+this.$session.get('default_state')
                $('#select-city-modal').modal('hide')
            });
            bus.$on('updateDefaultIPLocation', () => {
                this.location_city = this.$session.get('default_location');
                this.state = this.$session.get('default_state');
            });

        },
        mounted() {

            var vm = this;
            if ($(window).width() > 600) {
                this.isLocationEnable = true;
            }

            // To demonstrate functionality of exposed component functions
            // Here we make focus on the user input
            vm.location_city = this.$session.get('default_location')+', '+this.$session.get('default_state')
            this.state = this.$session.get('default_state');
            
            vm.showLocation = true;

            jQuery(document).ready(function ($) {

                // $('#locationTextId').click(function (f) {
                //     console.log('Set focus for location-search')
                //     f.stopImmediatePropagation();
                //     $(".city-location-field").val('');
                //     $('.vgps-location-search-wrapper').toggleClass('search-visible');
                //     // if(typeof this.$vm !== 'undefined'){
                        
                //         console.log('Set focus for location-search',vm)
                //         setTimeout(() => {
                //             // this.$vm.inputValue = ""    
                //             // $(this.$vm.$refs.input,'.location-search').val('')
                            
                //         }, 500);
                        
                //         // $(this.$vm.$refs.input).focus().val('');
                //     // }
                // }); 

                if($(window).width() < 767){

                    $(document).on("click", function(e) {
                        if ($(e.target).is("#locationTextId, .vgps-location-search-wrapper input, .vgps-location-search-wrapper .clear-location-search") === false) {
                            $('.vgps-location-search-wrapper').removeClass('search-visible');
                        }
                    });
                }

                $('.vgps-top-location-search-wrapper .vgps-location-search-wrapper .location-search input').on('keypress', function () {
                    $(this).parent().addClass('search-active');
                }).on('keydown', function (e) {
                    if (e.keyCode == 8){
                        if($(this).val().length == 1){
                            $(this).parent().removeClass('search-active');
                        }
                    }
                });

                $('.vgps-top-location-search-wrapper .vgps-location-search-wrapper .location-search .clear-location-search').click(function () {
                    $('.vgps-top-location-search-wrapper .vgps-location-search-wrapper .location-search input').val('');
                    $(this).parent().removeClass('search-active');
                });

            });

        },
        methods: {
            
            getAddresses:function (query) {
                let vm = this;
                vm.processing = true;
                vm.addresses = [];

                axios.get("data/get-city", {params: {
                    name: query,
                },
                }).then(function (response) {
                    console.log('City search result',response)
                    vm.addresses = response.data
                    vm.processing = false;
                })
                .catch(function (errors) {
                    vm.processing = false;
                    console.error(errors)
                });
                
            },

            /**
             * When the location found
             * @param {Object} address Data of the found location
             */
            setAddressData: function (data) {
                console.log('=============== Select City Address ===================',data)
                this.selectedAddress = data;
                this.addressSearch = '';
                this.$session.set('default_location', data.city);
                
                this.$session.set('default_location_lat', data.latitude);
                this.$session.set('default_location_long', data.longitude);
                this.$session.set('default_state', data.state_code);

                let fullLocation = this.$session.get('default_location')+', '+this.$session.get('default_state')
                $('#locationTextId').text(fullLocation);
                this.location = this.$session.get('default_location');
                bus.$emit('updateLocation', this.$session.get('default_location'))
                bus.$emit('updateCityMap', {lat: data.latitude, long: data.longitude})
                bus.$emit('updateLatLong',data)

                jQuery(document).ready(function ($) {
                    $('.vgps-top-location-search-wrapper .vgps-location-search-wrapper').removeClass('search-visible');
                    $(".city-location-field").val('');    
                });
                
                $('.city-location-field','.location-search').val('');

            },

            /**
             * When the location found
             * @param {Object} addressData Data of the found location
             * @param {Object} placeResultData PlaceResult object
             * @param {String} id Input container ID
             */
            getAddressData: function (addressData, placeResultData, id) {
                
                var formatedData = placeResultData.formatted_address;
                formatedData = formatedData.split(',').reverse();
                console.log('City Stte', formatedData)
                
                console.log('Location Data', addressData,'place Data',placeResultData)
                this.address = addressData;
                if (addressData.locality == undefined) {
                    this.location = (formatedData.length > 3)? formatedData[3]:formatedData[2];
                } else {
                    this.location = (formatedData.length > 3)? formatedData[3]: addressData.locality;
                    this.state = addressData.administrative_area_level_1;
                }

                this.$session.set('default_location', this.location);
                this.$session.set('default_location_lat', addressData.latitude);
                this.$session.set('default_location_long', addressData.longitude);
                this.$session.set('default_state', addressData.administrative_area_level_1);

                console.log('Vue session get default location', this.$session.get('default_location'));
                var fullLocation = this.$session.get('default_location')+', '+this.$session.get('default_state')
                $('#locationTextId').text(fullLocation);
//                this.location = this.location;
                bus.$emit('updateLocation', this.$session.get('default_location'))
                bus.$emit('updateCityMap', {lat: addressData.latitude, long: addressData.longitude})
                bus.$emit('updateLatLong',addressData,placeResultData)


                $('.location-search input').val('');
                $('.vgps-top-location-search-wrapper .vgps-location-search-wrapper').removeClass('search-visible');
                $('.location-search input').val('');
            },
            getBrowserAddressData: function () {

                var vm = this;
                    
                // //Get GeoLocation location
                if (navigator.geolocation) {
                    
                
                    navigator.geolocation.getCurrentPosition((position) => {
                        
                        vm.isSharedLocation = true;
                        var currentLat =  position.coords.latitude
                        var currentLong = position.coords.longitude


                        vm.$session.set('default_location_lat', currentLat);
                        vm.$session.set('default_location_long', currentLong);

                        
                        
                        var location = 'https://maps.googleapis.com/maps/api/geocode/json?latlng='+currentLat+','+currentLong+'&sensor=true&key=AIzaSyA9dVUJYajI6mWBrxrAe6s6ylQrd14UUyQ';
                        console.log('Get Address from LAt and Long',location);
                        axios.get(location)
                        .then(function (response) {
                            
                            if(response){
                                
                                var address =  response.data.results[0].formatted_address
                                address = address.split(',');
                                address = address.reverse();
                                vm.location_city = address[2];
                                vm.$session.set('default_location', vm.location_city);
                                console.log('get live location city',city);
                            }
                           
                        })
                        .catch(function (errors) {

                        });

                        navigator.geolocation.watchPosition(function(success){
                            
                        },function(error){
                            
                        });

                    },
                    function (error) {
                        // debugger
                        console.log('Location shared block',error.message);
                    }, {
                        enableHighAccuracy: true,
                        timeout: 5000
                    });
                } else {
                    alert("Geolocation is not supported by this browser.");
                }
            },
            close: function(event, el){                
                const exclude = ['top-location-city','top-location-city active','form-control city-location-field'];
                if(event && !exclude.includes(event.target.className))
                $('ul.vgps-location-search-wrapper').removeClass('search-visible');
                $('.clear-location-search').removeClass('active');
                
            },
            selectCityOption: function() {
                let vm = this;
                $('.vgps-location-search-wrapper').toggleClass('search-visible');
                vm.$refs.locationFieldSearchRef.inputValue = '';    
                vm.$refs.locationFieldSearchRef.isFocused = true;    
            }
        },
        filters: {
            assetPath: function (img) {
                var path = window.axios.defaults.baseURL+'/images/'+img;
                return path;
            } 
        } 
    }

</script>