<template>
    <div class="filter-select">
        <div class="row">
            <div class="col-md-12">
                <div class="my-events-filters">
                    <button @click="setActive('All')" :class="{ active: isActive('All') }" class="btn ceo menuForDesktop">
                        All
                    </button>
                    <button @click="setActive('SingleDay')" :class="{ active: isActive('SingleDay') }" class="btn ceo menuForDesktop">
                        Upcoming
                    </button>
                    <button @click="setActive('Recurring')" :class="{ active: isActive('Recurring') }" class="btn ceo menuForDesktop">
                        Recurring
                    </button>
                    <button @click="setActive('Annual')" :class="{ active: isActive('Annual') }" class="btn ceo menuForDesktop">
                        Annual
                    </button>
                    <button @click="setActive('Metaverse')" :class="{ active: isActive('Metaverse') }" class="btn ceo menuForDesktop">
                        Metaverse
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import { bus } from '../../app'
    export default {
        data() {
            return {
                dateRange:'',
                activeItem:"All",
            }
        },
        props:['filters','lookupData'],
        methods:{
            setActive: function (menuItem) {
                this.activeItem = menuItem; // no need for Vue.set()
                this.filters.type = menuItem;
                bus.$emit('advanceFilter')
            },
            isActive: function (menuItem) {
                return this.activeItem === menuItem;
            },
            advanceFilter:function(){
                bus.$emit('advanceFilter')
            },
        }
    }
</script>
