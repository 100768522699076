/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import $ from "jquery";
import Vue from "vue";
import Vuex from 'vuex'
import axios from "axios";
// import Vuetify from 'vuetify';
import NProgress from 'nprogress';
import validator from "jquery-validation";
// import Sortable from 'vue-sortable' // https://sagalbot.github.io/vue-sortable/
import VueCarousel from '@chenfengyuan/vue-carousel';//https://fengyuanchen.github.io/vue-carousel/#
import VueSweetalert2 from 'vue-sweetalert2';
import Select2 from 'v-select2-component';
// import timepicker from 'timepicker';
import daterangepicker from 'daterangepicker'; //https://www.npmjs.com/package/daterangepicker
import 'vue-toastr-2/dist/vue-toastr-2.min.css'
import './../../node_modules/nprogress/nprogress.css'
// TODO: Social Sharing
//https://vuelibs.org/UI%20Components/Social%20Sharing

// require('./bootstrap');
window.$ = window.jQuery = $;
window.Vue = Vue;
window.validator = validator;
window.VueSweetalert2 = VueSweetalert2;
window.daterangepicker = daterangepicker;
window.Select2 = Select2;
// window.vSortable = Sortable
// window.timepicker = timepicker;
window.validation = require("./validation");
window.customjs = require("./custom");
window.select2 = require("./plugins/select2");
window.slick = require("./plugins/slick");
window.sweetalert2 = require("./plugins/sweetalert2");




const baseURL = document.head.querySelector('meta[name="base_url"]');
// const RouteName = document.head.querySelector('meta[name="uri"]').content;
// const APP_ENV = document.head.querySelector('meta[name="app_env"]').content;
export const API_URL = document.head.querySelector('meta[name="api_url"]').content;

const defaultLocation = 'Conyers';
const defaultState = 'GA';
const defaultLocationLat = 33.6676103;
const defaultLocationLong = -84.01769039999999;
export const bus = new Vue();

window.axios = axios;
window.axios.defaults.baseURL = baseURL.content;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
window.api_url = API_URL;


/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

const token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
    window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
} else {
    console.error(
        "CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token"
    );
}

// Plugins ----------------------------------------
import HomeContent from './components/home.vue';
import BrowseContent from './components/browse.vue';
import VenueListing from './components/venue/venueListing.vue';
import MyEvents from './components/MyEvents.vue';
import MyVenues from './components/MyVenues.vue';
import MapView from './components/MapView.vue';
import UserProfileView from './components/user/update-user-profile.vue';
import TopCityAutocomplete from "./components/global/top-city-search";
// import UserBusinessSetup from './components/admin/business/setup-account.vue';
import VueScrollTo from 'vue-scrollto'
import VueSession from 'vue-session' // https://www.npmjs.com/package/vue-session
import VueGeolocation from 'vue-browser-geolocation';
import VueLazyload from 'vue-lazyload'  // Ref Link: https://www.npmjs.com/package/vue-lazyload
import VAnimateCss from 'v-animate-css'; // https://jofftiquez.github.io/v-animate-css/

import vSelect2 from 'v-select2-component';
import DatePicker from 'vue2-datepicker'; //https://mengxiong10.github.io/vue2-datepicker/index.html
import 'vue2-datepicker/index.css';
import VueSilentbox from 'vue-silentbox';//https://vuejsexamples.com/a-lightbox-inspired-vue-js-component/
import VueSocialSharing from 'vue-social-sharing'; // https://www.npmjs.com/package/vue-social-sharing
// import { sync } from 'vuex-router-sync';// https://www.npmjs.com/package/vuex-router-sync
import ScrollLoader from 'vue-scroll-loader' // https://www.npmjs.com/package/vue-scroll-loader
import infiniteScroll from 'vue-infinite-scroll'
import Paginate from 'vuejs-paginate'; //https://www.npmjs.com/package/vuejs-paginate

// Global Components
import LocationPicker from './components/global/location-picker.vue';
import ClaimBusinessLocationPicker from './components/global/claim-business-location-picker.vue';
import EventLocationPicker from './components/global/event-location-picker.vue';
import MobileLocationPicker from './components/global/mobile-location-picker.vue';
import Autocomplete from './components/global/all-search.vue';

import DateRangesComponent from './components/global/date-ranges.vue';
import StartDateComponent from './components/global/start-date.vue';
import HashFeaturedDateComponent from './components/global/hash-featured-date.vue';
import EndDateComponent from './components/global/end-date.vue';
import DisplayStartDateComponent from './components/global/display-start-date.vue';
import DisplayEndDateComponent from './components/global/display-end-date.vue';
import tireEventThemeDropdownComponent from './components/global/tier-event-theme.vue';
import EventCategoryDropdownComponent from './components/global/event-category-dropdown.vue';
import EventSubCategoryDropdownComponent from './components/global/event-sub-category-dropdown.vue';
import BusinessCategoryDropdownComponent from './components/global/business-category-dropdown.vue';
import BusinessSubCategoryDropdownComponent from './components/global/business-sub-category-dropdown.vue';
import stateDropdownComponent from './components/global/state-dropdown.vue';
import BannerTypeDropdownComponent from './components/global/select-banner-type-dropdown.vue';
import stateCityDropdownComponent from './components/global/state-city-dropdown.vue';
import EventCard from './components/global/event-card.vue';
import AdvanceSearchBar from './components/admin/advance-filter.vue';
import DetailFollowButton from './components/global/detail-follow-button.vue';
import helpers from './mixin/helpers';
import select2configs from './mixin/select2configs';
import { store } from './store/store';
import vClickOutside from 'v-click-outside'
import { mapFields } from 'vuex-map-fields';
import VueGtag from "vue-gtag";

Vue.use(vClickOutside)
Vue.use(VueSession, { persist: 'local' });
Vue.use(VueGeolocation);
Vue.use(VueLazyload);
Vue.use(VAnimateCss);
Vue.use(VueSilentbox)
Vue.use(DatePicker)
Vue.use(Vuex)
Vue.use(VueCarousel);
Vue.use(NProgress);
Vue.use(VueScrollTo)
Vue.use(VueSocialSharing);
Vue.use(ScrollLoader)
Vue.use(require('vue-moment')); // https://www.npmjs.com/package/vue-moment
Vue.use(infiniteScroll);// https://github.com/ElemeFE/vue-infinite-scroll
Vue.component('paginate', Paginate)


// Routing Pages ---------------------------
import AppView from './views/app'
import VueRouter from 'vue-router'; // importing Vue router library
import router from './routes/frontend';
Vue.use(VueRouter);


const moment = require('moment')
Vue.use(require('vue-moment'), {
    moment
})

// Google GTag
// Vue.use(VueGtag, {config: { id: "G-W4Y5PF13LP" }});
// Vue.use(VueGtag, {
//     config: { 
//       id: "G-W4Y5PF13LP",
//     },
// }, router);


Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his childrens
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});

const app = window.App = new Vue({
    el: "#app",
    store: store,
    mixins: [helpers, select2configs],
    components: {
        'app_view': AppView,
        'home_content': HomeContent,
        'browse_content': BrowseContent,
        'location_picker': LocationPicker,
        'mobile_location_picker': MobileLocationPicker,
        'event_location_picker': EventLocationPicker,
        'claim_business_location_picker': ClaimBusinessLocationPicker,
        'autocomplete': Autocomplete,
        'date_range_picker_vue': DateRangesComponent,
        'display_end_date_picker_vue': DisplayEndDateComponent,
        'display_start_date_picker_vue': DisplayStartDateComponent,
        'end_date_picker_vue': EndDateComponent,
        'start_date_picker_vue': StartDateComponent,
        'hash_featured_date_vue': HashFeaturedDateComponent,
        'tier_event_theme_dropdown': tireEventThemeDropdownComponent,
        'event_category_dropdown': EventCategoryDropdownComponent,
        'event_sub_category_dropdown': EventSubCategoryDropdownComponent,
        'business_category_dropdown': BusinessCategoryDropdownComponent,
        'business_sub_category_dropdown': BusinessSubCategoryDropdownComponent,
        'state_dropdown': stateDropdownComponent,
        'state_city_dropdown': stateCityDropdownComponent,
        'venue_listing': VenueListing,
        'my_events': MyEvents,
        'my_venues': MyVenues,
        'map_view': MapView,
        'user_profile_view': UserProfileView,
        'venue_card': EventCard,
        'advance_searchbar': AdvanceSearchBar,
        'detail_follow_button': DetailFollowButton,
        'select2_select_input': vSelect2,
        'select2_banner_type': BannerTypeDropdownComponent,
        Select2,
        'top_city_autocomplete':TopCityAutocomplete
    },
    router,
    data: {
        loadingContent: true,
        contentLoading: true,
        connectionStatus: false,
        location: defaultLocation,
        showMobileMenu: false,
        showTopCities: false,
        pageLoader: true,
        hidePageLoading: false,
        always_on: 'false',
        processing: false,
        moreContent: false,
        business: {
            type: '',
            business_typeData: [],
            business_type: '',
            typeData: '',
            sub_type2: '',
            sub_type2Options: [],
            sub_type3: '',
            sub_type3Options: [],
            dropdowndata: []
        },
        contact: {
            name: "",
            email: "",
            phone: "",
            message: "",
            user_type: "buyer",
            subscribe: ""
        },
        login: {
            email: "",
            password: ""
        },
        signup: {
            role: "",
            email: "",
            password: ""
        },
        claim: {
            first_name: "",
            last_name: "",
            business_name: "",
            city_id: "",
            state: "",
            street: "",
            latitude: "",
            venue_id: "",
            logitude: "",
            zip_code: "",
            email: "",
            phone: ""
        },
        forgot: {
            email: ""
        },
        newsletter: {
            email: ""
        },
        update: {
            newPassword: "",
            confirmPassword: ""
        },
        userData: '',
        lookupData: []
        , selection: '',
        suggestions: [],
        loadingClaimData: true,
        claimData: '',
        venueId: '',
    },
    beforeMount() {
        var vm = this;
        setTimeout(function () {
            vm.loadingContent = false
        }, 1000);
    },
    mounted: function () {

        var vm = this;
        vm.processing = false;
        this.venueId = window.location.pathname.split("venue-detail/")[1]

        setTimeout(function () {
            vm.showTopCities = true;
        }, 2000);

        document.onreadystatechange = () => {
            if (document.readyState == "complete") {
                // alert('Page completed with image and filesc!');
                console.log('Page completed with image and files!')
                vm.hidePageLoading = true;
                // fetch to next page or some code
            }
        }

        router.beforeResolve((to, from, next) => {
            var headerClass = (to.name != 'home') ? 'default-page-header-bg' : '';
            vm.$session.set('headerClass', headerClass);
            vm.$store.state.headerClass = vm.$session.get('headerClass');

            // If this isn't an initial page load.
            if (to.name) {
                // Start the route progress bar.
                NProgress.start()
            }
            next()
        });
        router.afterEach((to, from) => {
            if (to.name) {

                if (!jQuery.isEmptyObject(to.query) && to.name == 'home') {
                    bus.$emit('hashRedirect', to.query);
                }

                if (!jQuery.isEmptyObject(to.params) && to.name == 'event') {
                    // bus.$emit('loadEventDetailData', to.query);
                }

                if (!jQuery.isEmptyObject(to.params) && to.name == 'venue') {
                    bus.$emit('loadVenueDetailData', to.query);
                }

                if (!jQuery.isEmptyObject(to.query) && to.name == 'search') {

                    vm.search_term = typeof to.query.search_term != 'undefined' ? to.query.search_term : '';
                    
                    vm.search_main_category = '';
                    if(typeof to.query.search_main_category != 'undefined' && to.query.search_main_category == 'Trending'){
                        vm.search_main_category = 'All';
                    }else {
                        vm.search_main_category = to.query.search_main_category;
                    }
                    bus.$emit('get_search_term_events');
                }

                jQuery('.sidenav.hide-md.hide-lg.vissible-xs.vissible-sm').removeClass('active_side_menu');

            }
            // Complete the animation of the route progress bar.
            NProgress.done()
        })
        this.loadLookupData();
    },
    computed: {
        statesData() {
            return this.$store.getters.addressesStatesData;
        },
        citiesData() {
            return this.$store.getters.getstateCities;
        },
        bannerType() {
            return this.$store.state.banner_display_type
        },
        ...mapFields([
            'search_term',
            'search_offset',
            'search_limit',
            'search_main_category',
            'search_top_hash',
            'search_date_range',
            'search_business_type',
            'search_business_sub_type',
            'search_event_theme',
            'search_event_genre',
            'search_event_cuisines',
            'search_total_results',
            'defaultPageData',
            'claimRequestedData',
            'requestingClaimData'
        ]),
    },
    watch: {
        'claim.state': function (newVal, oldVal) {
            this.getStateCities();
        },
        '$route':function(to, from) {
            const toDepth = to.path.split('/').length
            const fromDepth = from.path.split('/').length
            this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
        }
    },
    created: function () {

        var vm = this;
       
        this.setAuthSession()
        this.ipLookUp()
        bus.$on('get_claim_request_data', (data) => {
            // vm.loadingClaimData = true
            // vm.claimData = data;
        });
        bus.$on('claim_request_data', (data) => {
            // vm.loadingClaimData = false
            // vm.claimData = data;
        });

        bus.$on('updateClaimLocationLatLong', (addressData, placeData, formatedData) => {

            var vm = this;
            vm.claim.city_id = formatedData[2].trim();
            vm.claim.state = addressData.administrative_area_level_1;
            vm.claim.latitude = addressData.latitude;
            vm.claim.longitude = addressData.longitude;

            if (formatedData.length > 4) {
                vm.claim.street = formatedData[4].trim();
                vm.claim.street += ', ' + formatedData[3].trim();
            } else {
                vm.claim.street = formatedData[3].trim();
            }

            if (typeof addressData.postal_code != 'undefined' && addressData.postal_code != '') {
                vm.claim.zip_code = addressData.postal_code;
            }
        });

        var tagsStatus = $('#always_on_value').val();
        this.always_on = tagsStatus ? 'true' : 'false'

    },
    methods: {
        updateFeaturedHash(e) {
            this.$store.commit('updateFeaturedHash', e.target.checked)
        },
        showBusinessSelectData: function (level) {

            var vm = this;
            if (level == 1 && vm.business.type != '') {
                vm.business.business_typeData = vm.$options.filters.filterLookUps2(vm.business.DropdownData['BusinessType'].children);
                var category = jQuery.grep(
                    this.business.DropdownData['BusinessType'].children,
                    function (obj) {
                        return (obj.code === vm.business.type);
                    }
                );
                this.business.sub_type3Options = [];
                var sortingData = (category[0].lookups.length > 0) ? category[0].lookups : category[0].children;
                vm.business.sub_type2Options = vm.$options.filters.filterLookUps(sortingData);
            } else if (level == 2) {

                console.log('level 2 search ')
                var sub_category = jQuery.grep(
                    this.business.sub_type2Options,
                    function (obj) {

                        return (obj.id === vm.business.sub_type2);
                    }
                )

                console.log(sub_category);
                if (typeof sub_category[0].lookups != 'undefined') {

                    this.business.sub_type3Options = [];
                    
                    var sortingData = (sub_category[0].lookups.length > 0) ? sub_category[0].lookups : sub_category[0].children;
                    vm.business.sub_type3Options = vm.$options.filters.filterLookUps(sortingData);

                } else {
                    this.business.sub_type3Options = [];
                }
            } else {

                this.business.sub_type2Options = [];
                this.business.sub_type3Options = [];
            }
        },
        logoutUser: function () {

            var vm = this;
            if (vm.processing === true) {
                return false;
            }

            var getToken = vm.$session.get('access_token');
            if (typeof getToken == 'undefined') {
                sweetalert2.fire(
                    {
                        title: "",
                        icon: "info",
                        text: "You already logged out.",
                        confirmButtonText: 'Go to Home!',
                        showCloseButton: true,
                        showCancelButton: true,

                    }
                ).then((result) => {
                    if (result.value) {
                        window.location = window.axios.defaults.baseURL
                    }
                })
                return false;
            }

          

            sweetalert2.fire(
                {
                    title: "",
                    text: "Please wait",
                    imageUrl: window.axios.defaults.baseURL + '/images/icons/vgps-loader.gif',
                    showConfirmButton: false,
                    allowOutsideClick: false
                }
            )

            vm.processing = true;
            axios.get("data/logout")
                .then(function (response) {


                    if (response.data.success == true) {
                        sweetalert2.fire('',response.data.description,'success')
                        
                        vm.clearVueSession(response.data.description);
                        setTimeout(function () {
                            window.location.href = response.data.callback
                        }, 500)
                    } else {
                        sweetalert2.fire('', response.data.description, 'fail')
                        vm.clearVueSession(response.data.description);
                        console.log('Logout Exception', response);
                    }
                    vm.processing = false;
                }).catch(function (errors) {
                    sweetalert2.fire('', response.data.description, 'fail')
                    console.log('Logout Exception', errors);
                    vm.processing = false;
                });
        },
        setAuthSession: function () {

            var vm = this;
            axios.get("/set-auth-session")
                .then(function (response) {
              
                    
                    if (response.data.success) {
                        vm.$session.set('access_token', response.data.user_data.access_token);
                        vm.$session.set('user_data', response.data.user_data);
                    } else {
                        vm.$session.remove('user_data')
                        vm.$session.remove('access_token')
                    }
                }).catch(function (errors) {
                    console.log('Exception', errors);
                });
        },
        loginModel: function (isAdmin) {


            var vm = this;
            isAdmin = typeof isAdmin != 'undefined' ? true : false;

            var data = new FormData();
            data.append("email", vm.login.email);
            data.append("password", vm.login.password);
            data.append("admin", isAdmin);
            var validate = $("#loginFormId").validate({
                rules: {
                    field: {
                        required: true,
                        email: true,
                    }
                }
            });
            validate.form();

            if (validate.errorList.length > 0) {
                //
            } else {

                if (vm.processing === true) {
                    return false;
                }

                vm.processing = true;
                axios
                    .post("/login", data)
                    .then(function (response) {

                        if (response.data.status_code == 200) {

                            let token = response.data.access_token;
                            // setCookie("token", token, 60 * 60 * 24 * 30);
                            // Set user data and access token in VUE Session ------------------------------------------
                            vm.$session.set('access_token', token);
                            vm.$session.set('user_data', response.data.user_data);
                            // console.log('access token',vm.$session.get('access_token'));
                            // console.log('user data',vm.$session.get('user_data'));
                            $("#loginModal").modal("hide");
                            let intendedURL = vm.$session.get('intended_url');
                            window.location.href = intendedURL;//response.data.callback;
                            window.location.reload();

                            // window.location.href = response.data.callback;
                        } else {
                            $(".alerts-div", "#loginFormId").html(response.data.template);
                        }
                        vm.processing = false;
                    })
                    .catch(function (errors) {

                        vm.processing = false;

                        if (typeof errors.response.data.errors !== "undefined") {

                            $(".error").remove();
                            $.each(errors.response.data.errors, function (key, val) {
                                $("#" + key, "#loginFormId").after('<label id="' + key + '-error" class="error" for="' + key + '">' + val[0] + "</label>");
                            });
                        } else {
                            $("#alerts-div", "#loginFormId").html(response.data.template);
                        }

                    });
            }
        },

        forgotPassword: function () {
            var vm = this;

            var data = new FormData();
            data.append("email", vm.forgot.email);

            var validate = $("#forgotPasswordModal form").validate();
            validate.form();
            if (validate.errorList.length > 0) {
                console.log("Fill form required fields");
            } else {

                if (vm.processing === true) {
                    return false;
                }
                vm.processing = true;

                axios
                    .post("/forgot-password", data)
                    .then(function (response) {


                        vm.processing = false;
                        if (typeof response.data.status_code != 'undefined' && response.data.status_code == 200) {
                            vm.forgot.email = '';
                            $(".alerts-div", "#loginModal").html(response.data.template);
                            $("#loginModal").modal("show");
                            vm.login.email = '';
                            vm.login.password = '';
                            $("#forgotPasswordModal").modal("hide");
                        } else {
                            $(".alerts-div", "#forgotFormId").html(response.data.template);
                        }

                    })
                    .catch(function (error) {
                        vm.processing = false;
                    });
            }
        },
        newsLetter: function () {

            var vm = this;

            var data = new FormData();
            data.append("email", vm.newsletter.email);

            var validate = $("#newsletter-form").validate();
            validate.form();
            if (validate.errorList.length > 0) {
                console.log("Fill form required fields");
            } else {

                if (vm.processing === true) {
                    return false;
                }
                vm.processing = true;

                axios
                    .post("/news-letter/subscription", data)
                    .then(function (response) {
                        vm.processing = false;
                        $(".alerts-div", "#newsletter-form").html(response.data.template);
                        vm.newsletter.email = '';
                    })
                    .catch(function (error) {
                        vm.processing = false;
                    });
            }
        },
        claimNowBusiness: function (index) {

            var vm = this;
            var data = new FormData();
            data.append("first_name", vm.claim.first_name);
            data.append("last_name", vm.claim.last_name);
            data.append("email", vm.claim.email);
            data.append("venue_id", $('#venue_id').val());

            var viewType = $('#view_type').val();

            // Take Business Address 
            // Stringify address information
            var address = JSON.stringify({
                street: vm.claim.street,
                zip_code: vm.claim.zip_code,
                city: vm.claim.city_id,
                state: vm.claim.state,
                latitude: vm.claim.latitude,
                longitude: vm.claim.longitude
            })
           

            // Stringify Contact information
            var contacts = JSON.stringify(
                [
                    { "type": "info-contact", "provider": "phone", "value": vm.claim.phone },
                    
                ]
            );
            data.append("contacts", contacts);


            var validate = $("#claim-business-from").validate();
            validate.form();
            if (validate.errorList.length > 0) {
                console.log("Fill form required fields");
            } else {

                if (vm.processing === true) {
                    return false;
                }
                vm.processing = true;
                axios.post("/send/claim-request", data)
                    .then(function (response) {

                        if (response.data.success !== false) {

                            bus.$emit('claimBusinessRequested', response.data);
                            

                            sweetalert2.fire("", response.data.description, "success");


                            vm.claim.first_name = '';
                            vm.claim.last_name = '';
                            vm.claim.street = '';
                            vm.claim.city_id = '';
                            vm.claim.email = '';
                            vm.claim.state = '';
                            vm.claim.latitude = '';
                            vm.claim.longitude = '';
                            vm.claim.zip_code = '';
                            vm.claim.phone = '';

                        } else {
                            sweetalert2.fire("", response.data.description, "error");
                        }

                        $('#claimBusinessModal').modal('hide');
                        vm.processing = false;

                    })
                    .catch(function (errors) {
                        vm.processing = false;
                        if (typeof errors.response.data.errors !== "undefined") {
                            $("label.error").remove();
                            $.each(errors.response.data.errors, function (key, val) {
                                $("#" + key, "#user-signup-from").after('<label id="' + key + '-error" class="error" for="' + key + '">' + val[0] + "</label>");
                            });
                        } else {
                            $("label.error").remove();
                            $(".alerts-div", "#claim-business-from").html(error.data.template);
                        }
                    });
            }
        },

        updatePassword: function () {
            var vm = this;
            var data = new FormData();
            $("#verifySignUpModal").modal("hide");
            var validate = $("#updatePassword form").validate();

            validate.form();
            data.append("newPassword", vm.update.newPassword);
            data.append("confirmPassword", vm.update.confirmPassword);

            data.append("uid", vm.verify.uidb64);
            if (validate.errorList.length > 0) {
                //vm.client.lead.validate = true;
                console.log("Fill form required fields");
            } else {
                if (vm.processing === true) {
                    return false;
                }
                vm.processing = true;

                axios
                    .post("/update-password", data)
                    .then(function (response) {
                        vm.processing = false;
                        if (response.data.success !== false) {
                            $("#loginModal").modal("hide");
                            $("#verifySignUpModal").modal("hide");
                            $("#forgotPasswordModal").modal("hide");
                            // $(".alerts-div", "#updatePassword").html(
                            //     response.data.template
                            // );
                            $(".alerts-div", "#loginModal").html(
                                response.data.template
                            );
                            $("#updatePassword").modal("hide");
                            $("#loginModal").modal("show");
                        } else {
                            $(".alerts-div", "#updatePassword").html(
                                response.data.template
                            );
                        }
                        // swal("Congrats !", "Your password has been updated!", "success");
                    })
                    .catch(function (error) {
                        vm.processing = false;
                        //  swal("Sorry!", "Make sure password is same!", "error");
                    });
            }
        },
        userSignup: function () {
            var vm = this;
            var data = new FormData();

            data.append("email", vm.signup.email);
            data.append("password", vm.signup.password);
            data.append("role", vm.signup.role);

            var validate = $("#user-signup-from").validate();
            validate.form();

            if (validate.errorList.length > 0) {
                //vm.client.lead.validate = true;
                console.log("Fill form required fields");
            } else {
                if (vm.processing === true) {
                    return false;
                }
                vm.processing = true;

                axios
                    .post("/signup", data)
                    .then(function (response) {

                        if (response.data.success != true) {

                            $("#alerts-div", "#signUpModal").html(response.data.template);
                            if (typeof response.data.errors !== "undefined") {
                                $("label.error").remove();
                                $.each(response.data.errors, function (key, val) {
                                    $("#" + val['field'], "#user-signup-from").after('<label id="' + key + '-error" class="error" for="' + val['field'] + '">' + val['message'] + "</label>");
                                });
                            }
                            vm.processing = false;
                        } else {

                            vm.processing = false;
                            $('#role').val('');
                            $('#email').val('');
                            $('#password').val('');
                            $(".alerts-div", "#signUpModal").html(response.data.template);

                        
                        }


                    })
                    .catch(function (errors) {

                        vm.processing = false;

                        if (typeof errors.response.data.errors !== "undefined") {
                            $("label.error").remove();
                            $.each(errors.response.data.errors, function (key, val) {
                                $("#" + key, "#user-signup-from").after('<label id="' + key + '-error" class="error" for="' + key + '">' + val[0] + "</label>");
                            });
                        } else {
                            $("label.error").remove();
                            $("#alerts-div", "#signUpModal").html(response.data.template);
                        }

                    });
            }
        },
        loadMoreEvents: function () {
            var vm = this;
            if (vm.processing === true) {
                return false;
            }
            vm.processing = true;
            var nextDataurl = $("#next-page-link-input").val();
            if (nextDataurl != "") {
                axios
                    .get(nextDataurl)
                    .then(function (response) {
                        $("#property-listing-div .property-item:last").after(
                            response.data.template
                        );
                        vm.getPriceInWord();
                        vm.processing = false;
                        if (response.data != "") {
                            vm.moreContent = true;
                        }
                    })
                    .catch(function (errors) {
                        vm.processing = false;
                        if (response.data != "") {
                            vm.moreContent = true;
                        }
                    });
            } else {
                vm.processing = false;
                vm.moreContent = false;
            }
        },
        searchEvents: function () {

            var vm = this;
            if (vm.processing === true) {
                return false;
            }

            vm.pageLoader = true;
            vm.processing = true;
            vm.scrollToDiv("property-listing-section");
            $("#property-listing-div").html("");

            var data = new FormData();
            data.append(
                "search_type",
                vm.property.search_type != "" ? vm.property.search_type : "sale"
            );
            data.append("city", vm.property.city);
            data.append("address", vm.property.address);
            data.append("type", vm.property.type);
            data.append(
                "sub_type",
                vm.getPropertySubTypeName(vm.property.sub_type)
            );
            data.append("price_range", vm.property.price_range);
            data.append("area_unit", vm.getLandUnitName(vm.property.area_unit));
            data.append("area_range", vm.property.area_range);


            axios
                .post("property/ajax/data", data)
                .then(function (response) {
                    vm.processing = false;
                    $("#property-listing-div").html(response.data.template);
                    vm.getPriceInWord();
                    vm.processing = false;
                    if (
                        response.data.success === false ||
                        response.data.total < 13
                    ) {
                        vm.moreContent = false;
                    } else {
                        vm.moreContent = true;
                    }
                    vm.pageLoader = false;
                    vm.pageLoader = false;
                })
                .catch(function (errors) {
                    vm.pageLoader = false;
                    vm.processing = false;
                    vm.moreContent = false;
                    $("#property-listing-div").html(response.data.template);
                    vm.pageLoader = false;
                });

            vm.buildAnalytics()
        },
        loadHomeContent: function () {
            var vm = this;
            vm.pageLoader = true;
            var vm = this;
            axios
                .get("data/feature/properties")
                .then(function (response) {

                    $("#feature-properties-div").html(response.data.featrued_properties);
                    $("#latest-properties-div").html(response.data.latest_properties);
                    $(".top-dealers-div").html(response.data.top_dealers);
                    vm.getPriceInWord();
                    vm.sliderInit();
                    vm.pageLoader = false;
                })
                .catch(function (errors) {
                    vm.pageLoader = false;
                });
        },
        onEnter: function () {
            window.location = window.location.href;
        },
        getCookie: function (name) {
            var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
            return v ? v[2] : null;
        },
        loadLookupData: function (type) {

            axios.get("data/lookup")
                .then((response) => {
                    this.business.DropdownData = response.data.lookup_data;
                    this.$store.state.lookupData = response.data.lookup_data
                    this.$store.state.hashThemes = response.data.hashThemes

                    bus.$emit('getLookupData', city);


                }).catch(function (errors) { });


        },
        locationSearch: function (city, lat, long) {


            this.$session.remove('default_location')
            this.$session.remove('default_location_lat')
            this.$session.remove('default_location_long')

            this.$session.set('default_location', city);
            this.$session.set('default_location_lat', lat);
            this.$session.set('default_location_long', long);



            bus.$emit('citySearchFilter', city);
            $(window).scrollTop(300, "slow");

        },
        setDefaultSession: function () {

            if (this.$session.exists() && this.$session.has('default_location')) {
                console.log(this.$session.get('default_location'));
            } else {
                this.$session.set('default_location', defaultLocation);
                this.$session.set('default_state', defaultState);
                this.$session.set('default_location_lat', defaultLocationLat);
                this.$session.set('default_location_long', defaultLocationLong);
            }

            this.$store.state.defaultCity = this.$session.get('default_location')
            this.$store.state.defaultCityState = this.$session.get('default_state')

        },
        ipLookUp: function () {

            var vm = this;

            axios.get("data/lookup-ip")
                .then((response) => {

                    var data = response.data.data;

                    if (vm.$session.exists() && vm.$session.has('default_location')) {
                        console.log(this.$session.get('default_location'));
                    } else {

                        if (response.data.success == true && data.query != '::1') {

                            vm.$session.set('default_location', data.city);
                            vm.$session.set('default_state', data.region);
                            vm.$session.set('default_location_lat', data.lat);
                            vm.$session.set('default_location_long', data.long);
                            vm.$store.state.defaultCity = vm.$session.get('default_location')
                            vm.$store.state.defaultCityState = vm.$session.get('default_state')

                        } else {

                            vm.$session.set('default_location', defaultLocation);
                            vm.$session.set('default_state', defaultState);
                            vm.$session.set('default_location_lat', defaultLocationLat);
                            vm.$session.set('default_location_long', defaultLocationLong);

                            vm.$store.state.defaultCity = vm.$session.get('default_location')
                            vm.$store.state.defaultCityState = vm.$session.get('default_state')
                        }
                    }

                    bus.$emit('updateDefaultIPLocation');

                }).catch(function (errors) { });

        },
        emitLookupData: function () {

            axios.get("data/lookup")
                .then((response) => {
                    bus.$emit('lookupData', response.data)

                })
                .catch(function (errors) {

                });
        },
        getStateCities: function () {
            var vm = this;
            if (vm.$store.state.lookupData) {

                var stateData = jQuery.grep(vm.$store.state.lookupData.states, function (obj) {
                    return (obj.geoname_code == vm.claim.state);
                });

                if (typeof stateData != 'undefined' && stateData.length > 0) {
                    this.$store.state.stateCitiesData = [];
                    this.$store.state.stateCitiesData = stateData[0].city_set.map(el => {
                        return { id: el.name, text: el.name };
                    });
                }
            }
        },
    },
    filters: {

        filterLookUps: function (data) {

            data.sort(function (a, b) {
                if (a.position > b.position) { return 1 }
                if (a.position < b.position) { return -1 }
                return 0;
            });

            return data.map(el => {
                return {
                    'id': el.code,
                    'text': el.name,
                    'name': el.name,
                    'position': el.position,
                    'lookups': el.lookups,
                    'children': el.children
                };
            })

        },
        filterLookUps2: function (data) {

            data.sort(function (a, b) {
                if (a.position > b.position) { return 1 }
                if (a.position < b.position) { return -1 }
                return 0;
            });


            return data.map(el => {
                return {
                    'id': el.code,
                    'text': el.name,
                    'name': el.name,
                    'position': el.position,
                };
            })

        },
    }
});
