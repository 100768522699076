<template>
  <div
    style="position: relative"
    v-bind:class="{ open: openSuggestion, 'not-found': not_found }"
    v-click-outside="outsideClose"
  >

    <div class="form-group has-search">
        <span class="fa fa-search form-control-feedback"></span>
        <input type="text" class="form-control" 
        v-model="value"
        @keydown.enter="enter"
        onfocus="this.value=''"
        @keydown.up="up"
        @keydown.down="down"
        placeholder="Search Top Cities">
    </div> 
    
    

    <ul class="list-group top-cities-list">
      <li  v-for="(item, index) in filteredTopCities.slice(0,5)" :key="index" class="list-item">
        <a href="javascript:void(0)" @click="setTopCity(item)" class="list-group-item">{{ item.city+', '+item.state_code}} 
        <span class="badge badge-default">{{ item.event_count}}</span></a>
      </li>
    </ul>


    <h6 class="mt-5">Top Cities</h6>
    <ul class="list-group top-cities-list">
        <li  v-for="(item, index) in topCities.slice(0,5)" :key="index" class="list-item">
          <a href="javascript:void(0)" @click="setTopCity(item)" class="list-group-item">{{ item.city+', '+item.state_code}} 
          <span class="badge badge-default">{{ item.event_count}}</span></a>
        </li>
    </ul>
    
  </div>
</template>

<script>
import { bus } from "../../app";
import { API_URL } from "../../app";
import helpers from "../../mixin/helpers";
import {
  ContentLoader,
  FacebookLoader,
  CodeLoader,
  BulletListLoader,
  InstagramLoader,
  ListLoader,
} from "vue-content-loader";
import { mapFields } from "vuex-map-fields";

export default {
  mixins: [helpers],
  watch: {
    open: function (newVal, oldVal) {
      console.log("newVal");
    },
    value: function (newVal, oldVal) {
      console.log("search term",newVal);
      this.$store.dispatch("searchTopCityAction", newVal);
    },
  },
  components: {
    ContentLoader,
    FacebookLoader,
    ...mapFields([
      "search_term",
      "search_main_category"
      ]),
  },
  created() {
    var vm = this;
    bus.$on("clearAdvanceFilter", () => {
      this.value = "";
    });
    bus.$on("hashRedirect", (query) => {
      vm.value = query.q;
    });
  },
  mounted() {},
  computed: {
    // Filtering the suggestion based on the input
    matches() {},
    openSuggestion() {
      // return this.open === true;
      return this.selection !== "" && this.open === true;
    },
    topCities() {
      return this.$store.getters.getTopCitiesData;
    },
    filteredTopCities() {
      return this.$store.getters.getFilteredTopCitie;
    },
    
  },
  methods: {
    preventLeadingSpace(e) {
      // only prevent the keypress if the value is blank
      if (!e.target.value) e.preventDefault();
      // otherwise, if the leading character is a space, remove all leading white-space
      else if (e.target.value[0] == " ")
        e.target.value = e.target.value.replace(/^\s*/, "");
    },
    // Triggered the input event to cascade the updates to
    setTopCity: function (data) {
                
        console.log('set Top City',data);        
        this.$session.set('default_location',data.city);
        this.$session.set('default_location_lat', data.latitude);
        this.$session.set('default_location_long', data.longitude);
        this.$session.set('default_state', data.state_code);
        //  $('#locationTextId').text(data.city+', '+data.state_code);

        bus.$emit('updateLocation', this.$session.get('default_location'))
        this.value = '';
        // bus.$emit('updateCityMap', {lat: data.latitude, long: data.longitude})
        // bus.$emit('updateLatLong',addressData,placeResultData)

    },
    updateValue(value) {
      if (this.open === false) {
        this.open = true;
        this.current = 0;
        this.not_found = false;
      }
      this.$emit("input", value);
    },

    searchTimeOut(value, keyBtn) {
      if (
        keyBtn === 13 ||
        keyBtn === 37 ||
        keyBtn === 38 ||
        keyBtn === 39 ||
        keyBtn === 40
      ) {
        return false;
      }

      this.suggestions = [];
      this.hashtag_suggestions = [];

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (this.open === false) {
          this.open = true;
          this.current = 0;
        }
        if (value) {
          this.$store.dispatch("searchTopCityAction", value);
        }
        // your code
      },100);
    },
    // When enter key pressed on the input
    enter() {
      if (this.value) {

        bus.$emit("search_event_title", {
          search_term: this.value,
          search_main_category: "All",
        });

        this.search_main_category = 'All';
        this.$router.push({
          name: "search",
          query: { search_term: this.value, search_main_category: "All" },
        });
        

        // this.$emit('input', this.value);
        // bus.$emit('search_event_title',this.value);
        // this.open = false;
        // this.hashtag_suggestions = [];
        // this.suggestions = [];
      }
    },
    outsideClose(obj) {
      this.not_found = false;
      this.open = false;
    },
    close(type) {
      if (type && this.value != "") {
        // this.value = '';
        bus.$emit("clearAdvanceFilter");
      }
      // this.value = '';
      this.not_found = false;
      this.open = false;
      this.hashtag_suggestions = [];
      this.suggestions = [];
      // this.outSideHideInputEvent();
      bus.$emit("closeTopSearch");
    },
    up() {
      if (this.current > 0) {
        this.current--;
      }
    },
    // When down arrow pressed while suggestions are open
    down() {
      if (this.current < this.suggestions.length - 1) {
        this.current++;
      }
    },
    // For highlighting element
    isActive(index) {
      return index === this.current;
    },
    isNotfound() {
      if (this.processing || this.searching) {
        return false;
      }
      return this.not_found;
    },
    isFound() {
      if (this.processing || this.searching) {
        return false;
      }
      return this.not_found;
    },
    // When one of the suggestion is clicked
    suggestionClick(index) {
      this.$emit("input", this.suggestions[index].event_title);
      this.open = false;
    },
    
    activateSuggestion(hastags, events) {
      var strClass = "";
      if (hastags.length > 0) {
        return (strClass = "active");
      }
      if (events.length > 0) {
        return (strClass = "active");
      }
      if (this.not_found) {
        return (strClass = "active");
      }
      return strClass;
    },
    blockRightClick: function (e) {
      e.preventDefault();
    },
  },
  data: function () {
    return {
      open: false,
      current: 0,
      value:'',
      processing: false,
      searching: false,
      selection: "",
      suggestions: [],
      not_found: false,
      hashtag_suggestions: [],
    };
  },
  filters: {
    assetPath: function (img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
  },
};
</script>
