<template>
    <div class="my-event-vue-wrapper">
        <header_section></header_section>
        <main class="top-inner-pages filters">
            <section class="dashboard admin-dashboard admin-add-ceo-ads-wrapper user-profile-options-page-wrapper">
                <div class="container-fluid">
                    <div class="row">
                        <div class="hidden-md hidden-sm hidden-xs col-lg-3 col-md-4 col-sm-12 col-xs-12">
                            <!-- @include('user.includes._partials.user-info-card') -->
                        </div>
                        <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                            <div class="right-box">
                                <div class="admin-dash-section-header">
                                    <h2>My Prefrences</h2>
                                    <p>Add your prefrences details </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div id="sidebar" class="hidden-md hidden-sm hidden-xs col-lg-3 col-md-4 col-sm-12 col-xs-12">
                            <sidebar_section></sidebar_section>
                        </div>
                        <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                        <div class="right-box">
                            <div class="user-profile-options-wrapper">

                                <template v-if="loadingContent">
                                    <ContentLoader
                                        height="200"
                                        width="400"
                                        speed="1"
                                        viewBox="0 0 400 200"
                                        backgroundColor="#d9d9d9"
                                        foregroundColor="#ecebeb"
                                        >
                                        <rect x="15" y="15" rx="4" ry="4" width="130" height="10" />
                                        <rect x="155" y="15" rx="3" ry="3" width="130" height="10" />
                                        <rect x="295" y="15" rx="3" ry="3" width="90" height="10" />
                                        <rect x="15" y="50" rx="3" ry="3" width="90" height="10" />
                                        <rect x="115" y="50" rx="3" ry="3" width="60" height="10" />
                                        <rect x="185" y="50" rx="3" ry="3" width="200" height="10" />
                                        <rect x="15" y="90" rx="3" ry="3" width="130" height="10" />
                                        <rect x="160" y="90" rx="3" ry="3" width="120" height="10" />
                                        <rect x="290" y="90" rx="3" ry="3" width="95" height="10" />
                                        <rect x="15" y="130" rx="3" ry="3" width="130" height="10" />
                                        <rect x="160" y="130" rx="3" ry="3" width="225" height="10" />
                                    </ContentLoader>
                                </template>
                                <form v-else class="form" id="user-profile" method="POST" enctype="multipart/form-data">
                                    <div class="row" v-if="!isSubscribed">
                                            <h6 style="padding-left: 15px;">Account Prefrences</h6>
                                            <div class="form-group-vue col-lg-12 col-md-12 col-sm-12 col-xs-12 bottom-m">
                                                <div class="floating-label-wrap">
                                                    <Select2
                                                        v-model="business_type"
                                                        
                                                        name="business_type"
                                                        id="business_type"
                                                        multiple
                                                        :options="businessIdsOptions"
                                                        :settings="{multiple:true,tags: false,allowClear: true,placeholder: 'Select Multiple',selectOnClose: false,closeOnSelect: true}"
                                                        />
                                                    <label for="business_type" class="floating-label">Business Category</label>
                                                </div>
                                            </div>
                                            <div class="form-group-vue col-lg-12 col-md-12 col-sm-12 col-xs-12 bottom-m">
                                                <div class="floating-label-wrap">
                                                    <Select2
                                                        v-model="venues"
                                                        
                                                        name="venues"
                                                        id="venues"
                                                        multiple
                                                        :options="allBusinessSubTypes"
                                                        :settings="{multiple:true,tags: false,allowClear: true,placeholder: 'Select Business Category',selectOnClose: false,closeOnSelect: true}"
                                                        />
                                                    <label for="venues" class="floating-label">Venue Types</label>
                                                </div>
                                            </div>
                                            <div class="form-group-vue col-lg-12 col-md-12 col-sm-12 col-xs-12 bottom-m">
                                                <div class="floating-label-wrap">
                                                    <Select2
                                                        v-model="foods"
                                                        
                                                        name="foods"
                                                        id="foods"
                                                        multiple
                                                        placeholder="Select One"
                                                        :options="FoodIdsOptions"
                                                        :settings="{multiple:true,tags: false,allowClear: true,placeholder: 'Select Multiple',selectOnClose: false,closeOnSelect: true}"
                                                        />
                                                    <label for="foods" class="floating-label">Food</label>
                                                </div>
                                            </div>
                                            <div class="form-group-vue col-lg-12 col-md-12 col-sm-12 col-xs-12 bottom-m">
                                                <div class="floating-label-wrap">
                                                    <Select2
                                                        v-model="genres"
                                                        
                                                        name="genres"
                                                        id="genres"
                                                        multiple
                                                        :options="genreIdsOptions"
                                                        :settings="{multiple:true,tags: false,allowClear: true,placeholder: 'Select Multiple',selectOnClose: false,closeOnSelect: true}"
                                                        />
                                                   <label for="genres" class="floating-label">Genres</label>
                                                </div>
                                            </div>
                                            <div class="form-group-vue col-lg-12 col-md-12 col-sm-12 col-xs-12 bottom-m">
                                                <div class="floating-label-wrap">
                                                    <Select2
                                                        v-model="hashtags_type"
                                                        
                                                        name="hashtags_type"
                                                        id="hashtags_type"
                                                        multiple
                                                        :options="tierEventThemes"
                                                        :settings="{multiple:true,tags: false,allowClear: true,placeholder: 'Select Multiple',selectOnClose: false,closeOnSelect: true}"
                                                        />
                                                   <label for="genres" class="floating-label">#Tags</label>
                                                </div>
                                            </div>
                                            <div class="form-group-vue col-lg-12 col-md-12 col-sm-12 col-xs-12 bottom-m">
                                                <div class="floating-label-wrap">
                                                    <Select2
                                                    v-model="amenities"
                                                    
                                                        name="amenities"
                                                        id="amenities"
                                                        multiple
                                                        :options="amenitiesFilterOptions"
                                                        :settings="{multiple:true,tags: false,allowClear: true,placeholder: 'Select Multiple',selectOnClose: false,closeOnSelect: true}"
                                                        />
                                                   <label for="genres" class="floating-label">Amenities</label>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group-vue">
                                            <div class="col-md-12 text-right">
                                                 <a @click="save" href="javascript:void(0)" value="save" class="btn save-btn pull-right">
                                                    <img v-if="!processing" :src="'add.svg'| assetPath" />
                                                    <span class="login-show-spinner" :class="{active : processing == true }">
                                                    <i class="fa fa-spinner fa-spin"></i>
                                                    </span> Save
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <footer_section></footer_section>
    </div>
</template>

<script>

// Imports
import { bus } from '../../app'
import helpers from '../../mixin/helpers';
import Select2 from "v-select2-component";

import My_Following_Events from '../../components/MyEvents.vue';
import FooterSection from './../../views/user/partials/footer'  
import HeaderSection from './../../views/user/partials/header'   
import Sidebar_Section from './../../views/user/partials/sidebar'   

import {
  ContentLoader,
  FacebookLoader
} from "vue-content-loader";

export default {
    name:'MyProfileComponent',
    components: {
      ContentLoader,
      FacebookLoader,
      Select2,
      header_section: HeaderSection,
      my_following_events : My_Following_Events,
      footer_section: FooterSection,
      sidebar_section: Sidebar_Section
    },
    created: function(){
        var vm = this;
        vm.getDetail();

        bus.$on('stateStoreUpdated', () => {
            vm.$store.dispatch("getAllBusinessSubTypesAction", vm.business_type);
        });

        const {user_subscription} = vm.$session.get("user_data")
        vm.isSubscribed = user_subscription
        console.log('ssssssssssssssss',vm.isSubscribed,vm.$session.get("user_data"))
    },
    watch: {
        state: function(newVal, oldVal) {
            this.getStateCities();
        },
        business_type: function(newVal, oldVal) {
            var vm = this;
            vm.$store.dispatch("getAllBusinessSubTypesAction", newVal);
        }
    },
    activated () {

    },
    props:[],
    mixins: [helpers],
    computed: {
        businessIdsOptions() {
            return this.$store.getters.businessIdsOptions;
        },
        allBusinessSubTypes() {
            return this.$store.getters.allBusinessSubTypes;
        },
        raceOptions() {
            return this.$store.getters.raceOptions;
        },
        genderOptions() {
            return this.$store.getters.genderOptions;
        },
        ageOptions() {
            return this.$store.getters.ageOptions;
        },
        FoodIdsOptions() {
            return this.$store.getters.FoodIdsOptions;
        },
        genreIdsOptions() {
            return this.$store.getters.genreIdsOptions;
        },
        amenitiesFilterOptions() {
            return this.$store.getters.profileAmenitiesFilterOptions;
        },
        tierEventThemes() {
            return this.$store.getters.profileHashtagsOptions;
        },
        statesData() {
            return this.$store.getters.addressesStatesData;
        },
        citiesData() {
            return this.$store.getters.getstateCities;
        }
    },
    data () {
        return {
          title: 'Venue Detail Component',
          loadingContent:false,
          processing:false,
          venue: '',
          role: '',
          first_name:'',
          last_name:'',
          email:'',
          gender:'',
          age:'',
          race:'',
          city:'',
          state:'',
          zip_code:'',
          business_type:[],
          venues:[],
          foods:[],
          genres:[],
          amenities:[],
          hashtags_type:[],
          isSubscribed: true
        }
    },
    methods: {
        async getDetail() {
        
            var vm = this ;
            vm.loadingContent = true;
            axios.get("get/profile/data")
            .then( (response) => {
                
                if (response.data.success == false) {

                    if(response.data.status_code == 403){
                        this.clearVueSession(response.data.template);
                    }else{
                        sweetalert2.fire("", response.data.description, "warning");
                    }
                    vm.$router.back()
                } else {
                    
                    var data = response.data.data;
                    console.log('user profile Data',data);

                    vm.role = data.role;
                    vm.first_name = data.first_name;
                    vm.last_name = data.last_name;
                    vm.email = data.email;
                    vm.gender = data.gender;
                    vm.age = data.age;
                    vm.race = data.race;                    
                    vm.business_type = data.business_type;
                    vm.venues = data.venue_type;
                    vm.foods = data.food_type;
                    vm.genres = data.genre_type;
                    vm.amenities = data.amenity_type;
                    vm.hashtags_type = data.hashtag_type;

                    vm.zip_code = data.address_details.zip_code && data.address_details.zip_code !=0 ?data.address_details.zip_code:'';
                    vm.city = data.address_details.city;
                    vm.state = data.address_details.state_code;
 
                }
                vm.loadingContent = false;
            })
            .catch(function (errors) {
                console.log('user profile get exception',errors);
                sweetalert2.fire("", errors.data, "error");
                vm.loadingContent = false;
            });
        },
        save: function() {

            var vm = this;
            var data = new FormData();

            // data.append("first_name", this.first_name);
            // data.append("last_name", this.last_name);
            // data.append("email", this.email);
            // data.append("age", this.age);
            // data.append("race", this.race);
            // data.append("gender", this.gender);

            // Stringify address information
            // let address = JSON.stringify({
            //     zip_code : vm.zip_code,
            //     city: vm.city,
            //     state: vm.state,
            // })
            // data.append("address", address);


            this.amenities= this.amenities.map(Number) || [];
            this.venues= this.venues.map(Number) || [];
            this.foods= this.foods.map(Number) || [];
            this.genres= this.genres.map(Number) || [];
            this.amenities= this.amenities.map(Number) || [];
            this.hashtags_type= this.hashtags_type.map(Number)|| [];


            data.append("business_type", JSON.stringify(this.business_type));
            data.append("venue_type", JSON.stringify(this.venues));
            data.append("food_type", JSON.stringify(this.foods));
            data.append("genre_type", JSON.stringify(this.genres));
            data.append("amenity_type", JSON.stringify(this.amenities));
            data.append("hashtag_type", JSON.stringify(this.hashtags_type));
            

            let validate = $("#user-profile").validate();
            validate.form();

            if (validate.errorList.length > 0) {
                sweetalert2.fire("", "please fill the required fields.", "fail");
                return false;
            }

            if (vm.processing === true) {
                return false;
            }

            vm.processing = true;

            axios
                .post('update/profile/data', data)
                .then(function(response) {
                    
                    if (response.data.success == true) {
                        sweetalert2.fire("", 'Profile updated successfully', "success");
                    } else {
                        vm.exceptionAlert(response)
                    }
                    vm.processing = false;
                })
                .catch(function(errors) {
                    console.log("Profile upate exception", errors);
                    vm.exceptionAlert(errors)
                    vm.processing = false;
                });
            },
            // getStateCities: function() {
            //     var vm = this;
            //     var stateData = jQuery.grep(this.$store.state.lookupData.states, function(
            //         obj
            //     ) {
            //         return obj.geoname_code == vm.state;
            //     });
            //     if (typeof stateData != "undefined" && stateData.length > 0) {
            //         this.$store.state.stateCitiesData = [];
            //         this.$store.state.stateCitiesData = stateData[0].city_set.map(el => {
            //         return { id: el.name, text: el.name };
            //         });
            //     }
            // },
            getStateCities: function () {
                let vm = this;
                if (vm.$store.state.defaultPageData) {
                    let stateData = jQuery.grep(
                    vm.$store.state.defaultPageData.lookup_cities,
                    function (obj) {
                        return obj.geoname_code == vm.state;
                    }
                    );

                    if (typeof stateData != "undefined" && stateData.length > 0) {
                    this.$store.state.stateCitiesData = [];
                    this.$store.state.stateCitiesData = stateData[0].city_set.map(
                        (el) => {
                        return { id: el.name, text: el.name };
                        }
                    );
                    }
                }
            },
    }
}
</script>