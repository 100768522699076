<template>
    <!-- <section id="google-ad-slots" class="row google-ad-div vgps-event-detail-section-wrapper">
        <div class="containers">
        <div class="row">
            <div class="col-md-12 google-container">
                <div class="ads" :style="{ backgroundImage: backgroundImageURL }">
                    <div class="col-md-5"></div>
                    <div class="col-md-12 ad">
                        <p>Join the thousands of event goers who get curated content just for them</p>
                        <a href="javascript:void(null);" @click="showNewsletter()">Join Us Today</a>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </section> -->
    <section id="google-ad-slots" class="row google-ad-div vgps-event-detail-section-wrapper">
        <div class="containers">
        <div class="row">
            <div class="col-md-12 google-container">
                  <div class="col-md-5"></div>
                  <div class="detail-content-ads">
                    <img class="image-responsive" :src="backgroundImageURL" alt="Place your ads hear"/>
                      <!-- <p>Join the thousands of event goers who get curated content just for them</p> -->
                      <!-- <a href="javascript:void(null);" @click="showNewsletter()">Join Us Today</a> -->
                  </div>
              </div>
            </div>
        </div>
    </section>
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";


export default {
  data() {
    return {
      //loadingContent: false,
    };
  },
  props: ["event"],
  mixins: [helpers],
  created() {
    
  },
  computed:{
    backgroundImageURL: function () {
        // return 'url('+this.$options.filters.assetPath('venueads.png')+ ')';
        return this.$options.filters.assetPath('banners/detail-content-banner.gif');
        // return `https://maps.google.com/maps?q=${this.event.address_details.latitude},${this.event.address_details.longitude}&hl=es;z=14&output=embed` 
    },      
  },
  components: {
    
  },
  methods: {
    
  },
  filters: {
    assetPath: function(img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
  }
};
</script>
