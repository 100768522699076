<template>
    <div>
<!-- Main Start -->
    <header_section></header_section>
    <main class="top-inner-pages">

        <!-- Dashboard Start -->
        <section class="dashboard vgps_contact_support_wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 vgps_page_header_wrapper">
                        <h2>Privacy Policy</h2>
                    </div>
                    <div class="col-md-12 vgps_privacy_content_wrapper">
                        <p>This policy (together with our Terms Of Use policy) sets out the basis on which any personal
                            data we collect from you, or that you provide to us, will be processed by us.</p>
                        <p>In processing your data we will comply in compliance with the General Data Protection
                            Regulations (EU) 2016/679 (from 25 May 2018), as well as the Data Protection Act 1998 (and
                            any amendment of or replacement for that Act) and the Privacy and Electronic Communications
                            (EC Directive) Regulations 2003 (and any amendment of or replacement for those Regulations),
                            as well as any other data protection laws that apply from time to time (together the "Data
                            Protection Laws").</p>
                        <p>In this Privacy Policy, references to ‘VenueGPS, ‘we’, or ‘us’ or ‘our’ mean VenueGPS Ltd
                            trading as VenueGPS.com, company number 09876532 with its registered office at New York, USA
                            (“VenueGPS.com”). VenueGPS is the data controller of your data under the Data Protection
                            Laws in that we determine the purposes and means of processing the personal data that we
                            collect and are responsible for your data. We are registered with the Information
                            Commissioner’s Office.</p>
                        <p>We want this data policy to be as clear, simple and fully transparent as possible. Your
                            rights regarding your personal data are set out in the section “Your Rights” below.</p>
                        <p>Any questions concerning personal data should be addressed to the Privacy Manager by email to
                            privacy@venuegps.com or by post to New York, USA. Additionally, you can adjust your
                            preferences at any stage via your online VenueGPS account.</p>
                        <p>You have the right to make a complaint at any time to the Information Commissioner’s Office
                            (ICO), the USA supervisory authority for data protection issues (www.ico.org.us). We would,
                            however, appreciate the chance to deal with your concerns before you approach the ICO so
                            please contact us in the first instance.</p>
                        <p>We reserve the right to modify or amend this Policy at any time and will display the
                            effective date at the end of this Policy. Previous versions can be obtained by contacting
                            us.</p>
                        <h3>What personal data is collected from you?</h3>
                        <p>Under the Data Protection Laws, personal data means any information relating to an identified
                            or identifiable natural person. An identifiable natural person is one who can be identified,
                            directly or indirectly in particular by reference to an identifier such as a name, an
                            identification number, location data, an online identifier or to one or more factors
                            specific to the physical physiological, genetic, mental, economic, cultural or social
                            identity of that natural person.</p>
                        <p>When you browse our website, we automatically collect identifiers such as cookies and IP
                            addresses and other technical data, such as browser type and version and operating system
                            and platform, location data and login data, as well as usage data including information
                            about how you use our website, products and services.</p>
                        <p>When you make a booking with us, our third party payment provider will collect your
                            transaction data including the details of the purchases of festival packages or festival
                            tickets that you make on our site, including your payment details – bank account and payment
                            card.</p>
                        <p>We may also ask you for personal data when you enter a competition or promotion sponsored by
                            VenueGPS.com</p>
                    </div>
                </div>
            </div>
        </section>
        <!-- Dashboard End -->

    </main>
    <!-- Main End -->
    <footer_section></footer_section>
    </div>
</template>

<script>

  import { bus } from './app'
  import HeaderSection from './../views/partials/header' 
  import FooterSection from './../views/partials/footer' 
  import helpers from './../mixin/helpers';

  export default {
    data() {
      return {
          loadingContent: false,
      }
    },
    mixins: [helpers],
    components:{
      'header_section': HeaderSection,
      'footer_section': FooterSection,
    }
  }
</script>