<template>
  <ul class="panel-group" id="sidebarNavigation">
    <li style="height: 48px;"><router-link :to="{ name: '' }"></router-link></li>

    <li>
      <router-link :to="{ name: 'home' }">Home</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'directory' }">Contacts</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'mapview' }">Map</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'user_profile' }">Profile</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'home' }">Preferences</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'my_venues' }">My Accounts</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'my_events' }">My Events</router-link>
    </li>
    <li></li>
    <li>
      <router-link :to="{ name: 'contact-us' }">Contact Us</router-link>
    </li>
    <li>
      <!-- <router-link :to="{ name: 'subscription' }">{{ subscription.stripe_plan }}</router-link> -->
      <router-link :to="{ name: 'subscription' }">{{ planName === 'VIP' ? 'Premium' : planName }}</router-link>

    </li>
    <li>
      <!-- <router-link :to="{ name: 'user_settings' }"><i class="fa fa-cog"></i> Settings </router-link></li> -->

      <router-link :to="{ name: 'user_settings' }">Settings</router-link>
    </li>
    <li>
      <a href="javascript:void(0);" @click="logoutAccount()">Logout</a>
    </li>
  </ul>
</template>

<script>
import { bus } from "./../../app";
import helpers from "./../../../mixin/helpers";

export default {
  created() {
    var vm = this;
    const user = vm.$session.get("user_data");
    vm.subscription = user?.user_subscription;
  },
  computed:{
    planName() {
      if (!this.subscription?.stripe_plan) {
        return "You don't have any active subscription";
      }

      for (let category of this.payload) {
        for (let pkg of category.packages) {
          if (pkg.id === this.subscription.stripe_plan) {
            return pkg.name;
          }
        }
      }
      return "You don't have any active subscription";
    }
  },
  data() {
    return {
      subscription: [],
      payload: [
        {
          title: "Trial",
          packages: [
            {
              id: "stripe_plan_2023",
              name: "Year 2023",
              plan_details: {
                id: "trial",
                price: 0,
              },
              total_plan_counts: 50,
            },
            {
              id: "free_vip_trial",
              name: "15 Day Trial",
              plan_details: {
                id: "trial",
                price: 0,
              },
              total_plan_counts: 250000,
            },
          ],
        },
        {
          title: "Standard",
          packages: [
            {
              id: "prod_OBuoQpsL53xt2R_updated",
              name: "VGPS Standard Yearly",
              plan_details: {
                id: "price_1NPWyeDcPFLTCaUzFX5koDOH",
                price: "$ 10",
                plan_id: "prod_OBuoQpsL53xt2R",
              },
              total_plan_counts: 250000,
            },
            {
              id: "prod_OBugDACwuh0kR3_updated",
              name: "VGPS Standard Monthly",
              plan_details: {
                id: "price_1NPWqlDcPFLTCaUzsvk4Bnk0",
                price: "$ 0.99",
                plan_id: "prod_OBugDACwuh0kR3",
              },
              total_plan_counts: 250000,
            },
          ],
        },
        {
          title: "VIP",
          packages: [
            {
              id: "prod_OBurPRj5z2fJA5_updated",
              name: "VIP Yearly",
              plan_details: {
                id: "price_1NPX1WDcPFLTCaUzeR4StsDZ",
                price: "$ 50",
                plan_id: "prod_OBurPRj5z2fJA5",
              },
              total_plan_counts: 250000,
            },
            {
              id: "prod_OBupsEcCHMdICV_updated",
              name: "VIP Monthly",
              plan_details: {
                id: "price_1NPX0ADcPFLTCaUzHplcn5Pq",
                price: "$ 4.99",
                plan_id: "prod_OBupsEcCHMdICV",
              },
              total_plan_counts: 250000,
            },
          ],
        },
      ],
    };
  },
  components: {},
  mixins: [helpers],
  props: [],
  methods: {},
};
</script>