<template>
  <section class="filters map-header" id="filter-id">
    <div class="map-filter-select">
      <div class="row map-row">
        <div class="left-map">
          <div class="map-filter-row">
            <advance_filters v-bind:filters="filters"></advance_filters>
          </div>
          <div class="filter-content map-event-listing-wrapper">
            <event_listing
              @clickOnEvents="onClickEventChild"
              v-bind:filters="filters"
            >
            </event_listing>
            <!-- Footer Start -->
            <footer id="map-footer">
              <section class="top-footer">
                <!--<div class="container">-->
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-6">
                    <h6>Information</h6>
                    <ul>
                       <li><router-link :to="{ name: 'claim-business' }">Claim Business</router-link></li>
                        <li><router-link :to="{ name: 'contact-us' }">Contact Us</router-link></li>
                        <li><router-link :to="{ name: 'faqs' }">FAQ's</router-link></li>
                        <li><router-link :to="{ name: 'privacy-policy' }">Privacy Policy</router-link></li>
                        <li><router-link :to="{ name: 'terms-of-use' }">Terms of Use</router-link></li>
                    </ul>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-6 clear m-t-m">
                    <h6>Advertise with Us</h6>
                    <ul>
                       <li><router-link :to="{ name: 'advertising-guidelines' }">Advertising Guidelines</router-link></li>
                        <li><router-link :to="{ name: 'image-size-matrix' }">Image Size Matrix</router-link></li>
                        <li><router-link :to="{ name: 'our-logo' }">Our Logo</router-link></li>
                        <li><router-link :to="{ name: 'pricing' }">Pricing </router-link></li>
                    </ul>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-6 m-t-m">
                    <h6>Company</h6>
                    <ul>
                      <li><router-link :to="{ name: 'ceoMessage' }">CEO Message</router-link></li>
                        <li><router-link :to="{ name: 'retired-digital' }">Retired Digital </router-link></li>
                        <li><router-link :to="{ name: 'careers' }">Careers </router-link></li>
                        <li><router-link :to="{ name: 'about' }">About Us</router-link></li>
                        <li><router-link :to="{ name: 'team' }">Team</router-link></li>
                        <li><router-link :to="{ name: 'vision' }">Vision</router-link></li>
                        <!-- <li><a href="#">Partnerships</a></li> -->
                        <li><router-link :to="{ name: 'sneak-preview' }">Sneak Preview</router-link></li>
                    </ul>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-6 m-t-m clear-left">
                    <h6>Venue Support</h6>
                    <ul>
                       <li><router-link :to="{ name: 'site-map' }">SiteMap </router-link></li>
                        <li><router-link :to="{ name: 'venue-live' }">Venue Live </router-link></li>
                        <li><router-link :to="{ name: 'venue-aid' }">Venue Aid </router-link></li>
                        <li><router-link :to="{ name: 'venue-store' }">Venue Store</router-link></li>
                        <li><router-link :to="{ name: 'venue-market' }">Venue Market</router-link></li>
                      <!-- <li><router-link :to="{ name: 'covid-19' }">Covid-19</router-link></li> -->
                    </ul>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-6 m-t-m">
                    <h6>Media</h6>
                    <ul>
                        <li><router-link :to="{ name: 'press-kit' }">Press Kit</router-link></li>
                        <li><router-link :to="{ name: 'tutorial' }">Tutorial </router-link></li>
                      <!-- <li><router-link :to="{ name: 'covid-19' }">Covid-19</router-link></li> -->
                    </ul>
                  </div>
                </div>
                <!--</div>-->
              </section>
              <section class="bottom-footer">
                <!--<div class="container">-->
                <div class="row border-top">
                  <div class="col-md-12 col-sm-12 col-xs-12 text-center">
                    <p>&copy; {{ year }} VenueGPS</p>
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12 text-center">
                    <ul class="social-icons">
                      <li>
                        <a
                          href="https://www.facebook.com/VENUEGPS/"
                          target="_blank"
                          ><img :src="'/fb.svg' | assetPath"
                        /></a>
                      </li>
                      <li>
                        <a href="https://twitter.com/venuegps" target="_blank"
                          ><img :src="'/twt.svg' | assetPath"
                        /></a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/venuegps/"
                          target="_blank"
                          ><img :src="'/insta.svg' | assetPath"
                        /></a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCaQ-SvgfP9_-b86k60BmUcQ/videos"
                          target="_blank"
                          ><img :src="'/youtube.svg' | assetPath"
                        /></a>
                      </li>
                      <li>
                        <a
                          href="https://snapchat.com/add/venuegps"
                          target="_blank"
                          ><img :src="'/snapchat.svg' | assetPath"
                        /></a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/in/venuegps-llc-0061a31a8/"
                          target="_blank"
                          ><img
                            :src="'icons/social/linkedin.png' | assetPath"
                            alt="LinkedIn"
                        /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <!--</div>-->
              </section>
            </footer>
            <!-- Footer End -->
          </div>
        </div>
        <div class="right-map mPMapView">
          <event_map v-bind:radius="filters.event_radius"></event_map>
          <!--<div id="map_view" width="100" height="auto" style="overflow: hidden;">-->
          <!--Loading Map .....-->
          <!--</div>-->
          <a @click="showMarkerOnMap()" href="javascript:void(null);"></a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// Imports
import { bus } from "../app";

import categoryFilters from "./mapview/category-filter.vue";
import advanceFilters from "./mapview/advance-filter.vue";
import EventListing from "./mapview/event-listing.vue";
import helpers from "./../mixin/helpers";
import EventMap from "./mapview/event-map.vue";

const offset = 0;
const limit = 20;

export default {
  mixins: [helpers],
  components: {
    // 'category_filter': categoryFilters,
    advance_filters: advanceFilters,
    event_listing: EventListing,
    event_map: EventMap,
  },
  created: function () {
    var d = new Date();
    this.year = d.getFullYear();

    bus.$on("updateSessionFilters", (filters) => {
      var vm = this;
      vm.location = filters.city;
    });
  },
  mounted() {
    // var footerHtml = $('.page-footer').html()
    // $('#map-footer').html(footerHtml);
  },
  props: ["location"],
  data() {
    return {
      title: "Home Component",
      year: "",
      filters: {
        location: "",
        search: "",
        category: "all",
        dates: {
          start: "",
          end: "",
        },
        business_type: "",
        venue_type: "",
        genre: "",
        event_filter: "",
        event_theme: "",
        event_genre: "",
        amenity_id: "",
        event_radius: "10",
        offset: offset,
        limit: limit,
      },
      isApiCompleted: false,
      userData: [],
      mapData: "",
      eventList: [],
      markersList: [],
      isMapDraw: false,
    };
  },
  methods: {
    onClickChild(eventArray) {
      this.markersList = [];
      if (eventArray["isLoadMore"] === false) {
        this.eventList = [];
      }
      var map = "";
      //var latlng = new google.maps.LatLng(33.629307, -83.97789);
      var latlng = new google.maps.LatLng(
        eventArray["latitude"],
        eventArray["longitude"]
      );
      var myOptions = {
        zoom: 12,
        center: latlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false,
      };
      var iconURL = {
        url: window.axios.defaults.baseURL + "/images/current_location.png", // url
        scaledSize: new google.maps.Size(40, 40), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(0, 0), // anchor
      };
      map = new google.maps.Map(document.getElementById("map_view"), myOptions);
      var current_position = {
        lat: 33.5838,
        lng: -84.0678,
      };
      let marker = new google.maps.Marker({
        position: new google.maps.LatLng(
          current_position.lat,
          current_position.lng
        ),
        map: map,
        icon: iconURL,
      });

      if (eventArray["response"].length > 0) {
        eventArray["response"].map((el) => {
          this.eventList.push(el);
        });

        var restaurantPin =
          window.axios.defaults.baseURL + "/images/map_pin_resturant.svg";
        var sudoBarUrl =
          window.axios.defaults.baseURL + "/images/map_pin_party.svg";

        var iconURL = {
          url: restaurantPin, // url
          scaledSize: new google.maps.Size(30, 30), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(0, 0), // anchor
        };

        this.eventList.map((el, key) => {
          if (el.venue.type == "bar-grill") {
            iconURL.url = restaurantPin;
          }
          if (el.venue.address.latitude && el.venue.address.longitude) {
            let marker = new google.maps.Marker({
              position: new google.maps.LatLng(
                el.venue.address.latitude,
                el.venue.address.longitude
              ),
              map: map,
              icon: iconURL,
            });

            this.markersList[el.id] = marker;
            let contentDetails =
              '<div style="display: flex;">' +
              '<div><img style="width: 40px;height: 60px;" src="' +
              el.image +
              '"></div> ' +
              '<div style="margin-left: 8px;"><p style="color: #000;margin: 0; font-size: 14px;line-height: 22px;font-weight: 600;">' +
              el.title +
              '</p><p style="font-size: 14px;margin: 0;">' +
              el.start_date +
              '</p>  <a href="https://www.google.com/maps/dir/?api=1&origin=' +
              current_position.lat +
              "," +
              current_position.lng +
              "&destination=" +
              el.venue.address.latitude +
              "," +
              el.venue.address.longitude +
              '&travelmode=driving" style="color: #3e3e3e;margin: 0; font-size: 14px;line-height: 18px;" target="_blank">Show Directions</a> </div> </div>';

            var infowindow = new google.maps.InfoWindow();
            google.maps.event.addListener(
              marker,
              "click",
              (function (marker, key) {
                return function () {
                  infowindow.setContent(contentDetails);
                  infowindow.open(map, marker);
                };
              })(marker, el.id)
            );
          }
        });
      }
    },
    initMap(eventArray) {
      var latlng = new google.maps.LatLng(
        eventArray["latitude"],
        eventArray["longitude"]
      );
      var myOptions = {
        zoom: 7,
        center: latlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false,
      };
      return (map = new google.maps.Map(
        document.getElementById("map_view"),
        myOptions
      ));
    },
    onClickEventChild(id) {
      google.maps.event.trigger(this.markersList[id], "click");
    },
  },
};
</script>

<style>
#map_view {
  width: 57%;
  min-height: 609px;
  position: absolute !important;
  right: 0;
  top: 186px;
}
</style>