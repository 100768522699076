<template>
  <div>
    <header_section></header_section>
    <!-- Main Start -->
    <main class="top-inner-pages faqs-page">
      <section class="dashboard vgps_contact_support_wrapper">
        <div class="container">
          <div class="row">
            <div class="col-md-12 vgps_page_header_wrapper">
              <h2>User Guide</h2>
            </div>
            <div class="col-md-3 col-sm-4">
                <information_sidebar></information_sidebar>
            </div>
            <div class="col-md-9 col-sm-8">
              <div class="right-box">
                <div class="vgps_contact_form_header">
                  <h5>Tutorials</h5>
                  <p>Need Assistance? Our friendly staff is here to help you.</p>
                </div>
                <div class="vgps_kb_accordions_wrapper">
                  <div class="panel-group" id="accordion">

                    <div v-for="(video,index) in tutorials" class="panel panel-default" :key="index">
                      <div class="panel-heading">
                        <h4 class="panel-title">
                          <a
                            data-toggle="collapse"
                            :aria-expanded="[index == 0?true:false]"
                            data-parent="#accordion"
                            :href="'#q1'+video.id"
                          ><img :src="'/youtube.svg'|  assetPath"  alt="Youtube" /> {{ (index+1)+'. '+video.name }} </a>
                        </h4>
                      </div>
                      <div :id="'q1'+video.id" :class="[index == 0?'in':'' ]" class="panel-collapse collapse">
                        <div class="panel-body">
                          <iframe width="100%" height="315" 
                          :src="video.url | parseEmbdedURL" 
                          title="YouTube video player" 
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                          allowfullscreen></iframe>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Dashboard End -->
    </main>
    <!-- Main End -->
    <footer_section></footer_section>
  </div>
</template>

<script>
import HeaderSection from "./../views/partials/header";
import FooterSection from "./../views/partials/footer";
import Information_Sidebar from './../views/partials/information-sidebar'
import helpers from "./../mixin/helpers";

export default {
  data() {
    return {
      loadingContent: false,
      tutorials: []
    };
  },
  mounted() {
    this.getTutorials();
  },
  mixins: [helpers],
  components: {
    footer_section: FooterSection,
    header_section: HeaderSection,
    information_sidebar: Information_Sidebar
  },
  methods: {
        async getTutorials() {
        
            let vm = this;
            vm.loadingContent = true;
            axios.get("data/get/tutorials")
            .then( (response) => {
                console.log('====== Tutorials =======',response);
                vm.tutorials = response.data 
                vm.loadingContent = false;
            })
            .catch(function (errors) {
                sweetalert2.fire("", errors.data, "error");
                vm.loadingContent = false;
            });
        }
    },
    filters: {
      parseEmbdedURL: function (url) {
        const regx = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|-){11})(?:\S+)?$/;
        if(!url.match(regx)){
          url = url.replace('/watch?v=', '/embed/');
        }
        return url;
      },
    }
};
</script>