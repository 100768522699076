<template>
  <div
    class="form-inline event-search"
    style="position: relative"
    v-bind:class="{ open: openSuggestion, 'not-found': not_found }"
    v-click-outside="outsideClose"
  >
    <img @click="enter" class="search-img" :src="'search.svg' | assetPath" />
    <input
      class="form-control form-control-event"
      placeholder="Search Event by Name, Venue and People"
      type="text"
      :value="value"
      @input="updateValue($event.target.value)"
      @keydown.enter="enter"
      @blur="focusOutInput"
      @keydown.down="down"
      @focus="suggestionFocus()"
      @keydown.space="preventLeadingSpace"
      @keyup="searchTimeOut($event.target.value, $event.which)"
      @keydown.up="up"
    />
    <img
      v-if="
        (hashtag_suggestions.length > 0 && processing == false) ||
        (suggestions.length > 0 && processing == false) ||
        (value != '' && processing == false)
      "
      @click="close('clear-value')"
      class="close-img"
      :src="'close.svg' | assetPath"
    />
    <!-- <img v-if="processing == false" @click="close('clear-value')" class="close-img"  :src="'close.svg' | assetPath"> -->
    <img
      v-if="searching"
      v-bind:class="{ active: searching }"
      class="search-loader"
      :src="'ajax-loader.gif' | assetPath"
    />
    <ul
      v-else
      class="dropdown-menu global-auto-complete drop-thumb"
      :class="activateSuggestion(hashtag_suggestions, suggestions)"
      v-click-outside="outsideHideEvent"
    >
      <li v-if="processing" class="content-loader">
        <!-- <img :src="'icons/vgps-loader.gif' | assetPath">  -->
        <ContentLoader
          height="1200"
          width="1060"
          speed="1"
          primaryColor="#d9d9d9"
          secondaryColor="#ecebeb"
        >
          <rect x="103" y="12" rx="3" ry="3" width="123" height="7" />
          <rect x="102" y="152" rx="3" ry="3" width="171" height="6" />
          <circle cx="44" cy="42" r="38" />
          <circle cx="44" cy="147" r="38" />
          <circle cx="44" cy="251" r="38" />
          <rect x="105" y="117" rx="3" ry="3" width="123" height="7" />
          <rect x="104" y="222" rx="3" ry="3" width="123" height="7" />
          <rect x="105" y="48" rx="3" ry="3" width="171" height="6" />
          <rect x="104" y="257" rx="3" ry="3" width="171" height="6" />
        </ContentLoader>
      </li>

      <li
        v-if="processing == false && not_found == true"
        class="user-info auto-complete-no-results"
      >
        <div class="user-name header-search-event-card">
          <div class="text-center">
            <a><i class="fa fa-search"></i></a>
            <p><strong>Result Not Found!</strong></p>
            <p>Sorry, we do not have any results for this search for now.</p>
          </div>
        </div>
      </li>

      <!-- HashTag  Suggestions -->
      <template v-if="hashtag_suggestions.length > 0">
        <li class="hashtag-suggestions-header"><h6>Hashtags</h6></li>
        <li
          class="user-info hashtag-info"
          v-for="(hashtag_suggestion, index) in hashtag_suggestions"
          v-bind:class="{ active: isActive(index) }"
          :key="index"
        >
          <div class="user-name header-search-event-card">
            <router-link
              :to="{
                name: 'search',
                query: {
                  search_term: hashtag_suggestion.title,
                  search_main_category: 'All',
                },
              }"
            >
              <p>{{ limitStr(hashtag_suggestion.title, 35) }}</p>
            </router-link>
        
          </div>
        </li>
      </template>

      <!--Event Suggestions -->
      <template v-if="suggestions.length > 0">
        <li class="suggestions-header"><h6>Events</h6></li>
        <li
          class="user-info"
          v-for="(suggestion, index) in suggestions"
          :key="index"
          v-bind:class="{ active: isActive(index) }"
          @click="suggestionClick(index)"
        >
          <div class="user-img">
            <img :src="suggestion.thumbnail_urls.thumb_48" />
          </div>
          <div class="user-name header-search-event-card">
            <router-link
              v-if="isAuth()"
              :to="{ name: 'event', params: { id: suggestion.event_id } }"
            >
              <h5>{{ limitStr(suggestion.event_title, 35) }}</h5>
              <p>{{ suggestion.city + ", " + suggestion.state }}</p>
            </router-link>
            <a
              v-else
              @click="loginModal(suggestion.event_id, '#/event/')"
              href="#"
            >
              <a
                href="javascript:void(0)"
                @contextmenu="blockRightClick($event)"
                ><h5>{{ limitStr(suggestion.event_title, 35) }}</h5></a
              >
              <p>{{ suggestion.city + ", " + suggestion.state }}</p>
            </a>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { bus } from "../../app";
import { API_URL } from "../../app";
import helpers from "../../mixin/helpers";
import {
  ContentLoader,
  FacebookLoader,
  CodeLoader,
  BulletListLoader,
  InstagramLoader,
  ListLoader,
} from "vue-content-loader";
import { mapFields } from "vuex-map-fields";

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  mixins: [helpers],
  watch: {
    open: function (newVal, oldVal) {
      console.log("newVal");
    },
  },
  components: {
    ContentLoader,
    FacebookLoader,
    ...mapFields([
      "search_term",
      "search_main_category"
      ]),
  },
  created() {
    var vm = this;
    bus.$on("clearAdvanceFilter", () => {
      this.value = "";
    });
    bus.$on("hashRedirect", (query) => {
      vm.value = query.q;
    });
  },
  mounted() {},
  computed: {
    // Filtering the suggestion based on the input
    matches() {},
    openSuggestion() {
      // return this.open === true;
      return this.selection !== "" && this.open === true;
    },
  },
  methods: {
    outsideHideEvent(obj) {
      $(".dropdown-menu.global-auto-complete").removeClass("active");
      this.hashtag_suggestions = "";
      this.suggestions = "";
    },
    outSideHideInputEvent(obj) {
      var $navbar = $(".mobile-top-search");
      // var _opened = $navbar.hasClass("mobile-search-enable");
      // var target_className = obj.target.className;
      // if (_opened === true && target_className != 'hidden-md hidden-lg search-mobile-icon') {
      $navbar.removeClass("mobile-search-enable");
      // }
    },
    suggestionFocus(e) {
      // debugger
      this.getSuggestionEvents();
    },
    preventLeadingSpace(e) {
      // only prevent the keypress if the value is blank
      if (!e.target.value) e.preventDefault();
      // otherwise, if the leading character is a space, remove all leading white-space
      else if (e.target.value[0] == " ")
        e.target.value = e.target.value.replace(/^\s*/, "");
    },
    // Triggered the input event to cascade the updates to

    updateValue(value) {
      if (this.open === false) {
        this.open = true;
        this.current = 0;
        this.not_found = false;
      }
      this.$emit("input", value);
    },

    searchTimeOut(value, keyBtn) {
      if (
        keyBtn === 13 ||
        keyBtn === 37 ||
        keyBtn === 38 ||
        keyBtn === 39 ||
        keyBtn === 40
      ) {
        return false;
      }

      this.suggestions = [];
      this.hashtag_suggestions = [];

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (this.open === false) {
          this.open = true;
          this.current = 0;
        }
        if (value) {
          this.$emit("input", value);
          this.getSuggestionEvents(value);
        }
        // your code
      }, 500);
    },
    // When enter key pressed on the input
    enter() {
      if (this.value) {

        bus.$emit("search_event_title", {
          search_term: this.value,
          search_main_category: "All",
        });

        this.search_main_category = 'All';
        this.$router.push({
          name: "search",
          query: { search_term: this.value, search_main_category: "All" },
        });
        

        // this.$emit('input', this.value);
        // bus.$emit('search_event_title',this.value);
        // this.open = false;
        // this.hashtag_suggestions = [];
        // this.suggestions = [];
      }
    },
    outsideClose(obj) {
      this.not_found = false;
      this.open = false;
    },
    close(type) {
      if (type && this.value != "") {
        // this.value = '';
        bus.$emit("clearAdvanceFilter");
      }
      // this.value = '';
      this.not_found = false;
      this.open = false;
      this.hashtag_suggestions = [];
      this.suggestions = [];
      // this.outSideHideInputEvent();
      bus.$emit("closeTopSearch");
    },
    // When up arrow pressed while suggestions are open
    focusOutInput() {
      // debugger
    },
    up() {
      if (this.current > 0) {
        this.current--;
      }
    },
    // When down arrow pressed while suggestions are open
    down() {
      if (this.current < this.suggestions.length - 1) {
        this.current++;
      }
    },
    // For highlighting element
    isActive(index) {
      return index === this.current;
    },
    isNotfound() {
      if (this.processing || this.searching) {
        return false;
      }
      return this.not_found;
    },
    isFound() {
      if (this.processing || this.searching) {
        return false;
      }
      return this.not_found;
    },
    // When one of the suggestion is clicked
    suggestionClick(index) {
      this.$emit("input", this.suggestions[index].event_title);
      this.open = false;
    },
    getSuggestionEvents: function (search) {
      var vm = this;
      vm.searching = true;
      if ($(window).width() <= 720) {
        //$('.navbar-header').hide();
      }

      var location = this.$session.get("default_location");
      var state = this.$session.get("default_state");

      vm.processing = true;
      // axios.get(API_URL+"event/search-event", {
      axios
        .get("data/search/events", {
          params: {
            q: search,
            city: location,
            state_code: state,
          },
        })
        .then(function (response) {
          vm.suggestions = response.data.data.events;
          vm.hashtag_suggestions = response.data.data.hashtags;
          // vm.suggestions = response.data.payload.events
          // vm.hashtag_suggestions=response.data.payload.hashtags;

          // Show result not found in case both event and hastag result empty
          vm.not_found =
            vm.hashtag_suggestions.length === 0 && vm.suggestions.length === 0
              ? true
              : false;
          vm.processing = false;
          vm.searching = false;
        })
        .catch(function (errors) {
          vm.suggestions = [];
          vm.hashtag_suggestions = [];
          vm.processing = false;
          vm.searching = false;
          vm.not_found = true;
        });
    },
    hashTagsSearch(hashTag) {
      // bus.$emit('topSearchhashtag',hashTag);
      // this.close();
      // this.value=hashTag;

      bus.$emit("hash_search_redirection", {
        search_term: hashTag,
        search_category: "all",
      });
      // this.$router.push("/search?search_term=`${hashTag}`&search_category=all");
    },
    activateSuggestion(hastags, events) {
      var strClass = "";
      if (hastags.length > 0) {
        return (strClass = "active");
      }
      if (events.length > 0) {
        return (strClass = "active");
      }
      if (this.not_found) {
        return (strClass = "active");
      }
      return strClass;
    },
    blockRightClick: function (e) {
      e.preventDefault();
    },
  },
  data: function () {
    return {
      open: false,
      current: 0,
      // value:'',
      processing: false,
      searching: false,
      selection: "",
      suggestions: [],
      not_found: false,
      hashtag_suggestions: [],
    };
  },
  filters: {
    assetPath: function (img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
    detailPath: function (id) {
      var path = window.axios.defaults.baseURL + "/event-detail/" + id;
      return path;
    },
  },
};
</script>
