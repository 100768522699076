<template>
    <div class="">
        <advance_filters v-bind:filters="filters"></advance_filters>
        <event_listing v-bind:filters="filters"></event_listing>
    </div>
</template>

<script>

// Imports
import { bus } from '../app'
import advanceFilters from './myevents/advance-filter.vue';
import EventListing from './myevents/event-listing.vue';
import helpers from '../mixin/helpers';

const offset = 0;
const limit = 8;

export default {
    components: {
        'advance_filters': advanceFilters,
        'event_listing': EventListing
    },
    created: function(){
        bus.$on('updateSessionFilters',(filters) => {
            var vm = this;
            vm.location = filters.city;
        });
    },
    props:[],
    mixins: [helpers],
    data () {
        return {
          title: 'MyEvents Component',
          filters: {
              type:'All',
              offset:offset,
              limit:limit,
          },
          isApiCompleted: false,
        }
    },
    methods: {

    }
}
</script>