<template>
  <div class="resultNotFoundWrapp">
    <h3  v-if="trendingHashList.length > 0">Top Trending #Tags</h3>
    <ul
      v-if="trendingHashList.length > 0"
      class="trending-hash topTrendingTgList"
    >
      <li v-for="hash in trendingHashList" :key="hash.id">
        <button
          :title="hash.title"
          @click="hashSearch(hash.title)"
          class="event-hashtags"
        >
          {{ hash.title }}
        </button>
      </li>
    </ul>

    <h3 v-if="trendingEventList.length > 0" class="rNTrendEvent">Top Trending Events</h3>
    <div
      v-if="trendingEventList.length > 0"
      class="homeListingWraper default-trending-list"
    >
      
      <template v-for="(event, key) in trendingEventList.slice(0, 4)">
        <!---Ads Card ------------------------------------------>
        <div
          v-if="event && event.object_type == 'ads'"
          :key="key"
          :class="cardType(key, event.object_typem, 'ads')"
          class="event-card-wraper"
        >
          <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12 ceo adver-card">
            <div class="content">
              <a
                :href="event.link ? event.link : 'javascript:void(0)'"
                rel="noopener"
                target="_blank"
              >
                <div class="pic" v-lazy:background-image="event.image"></div>
              </a>
              <a
                v-if="isAdministratorAccount()"
                :href="adminEditOptionURL('ads', event.id)"
                target="_blank"
                class="admin-edit-option"
              >
                <i class="fa fa-edit"></i>
              </a>

              <div class="bottom-decription">
                <h6>
                  <a v-if="event.link" target="_blank" :href="event.link">{{
                    event.title
                  }}</a>
                  <a v-else href="javascript:void(0)">{{ event.title }}</a>
                </h6>

                <p v-if="event.release_date" class="dates">
                  {{ event.release_date }}
                </p>
                <br />
                <div v-if="event.link" class="hashtag-wrapper">
                  <a :href="event.link" target="_blank" class="visiteSite"
                    ><i class="fa fa-external-link-alt"></i>
                    <span>Visit Site</span></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!---Event Card ------------------------------------------>
        <div
          v-if="event && event.object_type == 'event'"
          :key="event.event_id"
          :class="cardType(key, event.object_type, 'event')"
          class="event-card-wraper"
        >
          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 ceo event-card">
            <div class="content" :class="event.activation_status_display">
              <div
                class="pic event-thumb-img"
                v-lazy:background-image="event.thumbnail_urls.thumb_296"
              >
                <router-link
                  v-if="isAuth()"
                  class="flyer-venue-name"
                  :to="{ path: 'event/' + event.event_id }"
                ></router-link>
                <a
                  v-else
                  @click="loginModal(event.event_id, '#/event/')"
                  class="flyer-venue-name"
                  :href="url('event-detail/' + event.event_id)"
                  @contextmenu="blockRightClick($event)"
                ></a>
                <div class="seen-title">
                  <span :title="event.category" class="time vCategoryName">{{
                    event.category
                  }}</span>
                  <span class="eye">
                    <img :src="'seen.svg' | assetPath" :alt="event.title" />
                    {{ event.total_views }}
                  </span>
                  <span
                    title="Private Location"
                    v-if="event.type && event.type == 'PrivateLocation'"
                    class="time private-label"
                    >Private</span
                  >
                </div>
                <!--like Event Component -->
                <like_event
                  v-bind:events="events"
                  v-bind:event="event"
                  v-bind:index="key"
                ></like_event>
              </div>

              <div
                v-if="event.activation_status_display"
                class="event-status-flag activation-status-flag"
              >
                <a href="#">
                  {{ event.activation_status_display }}
                  <img :src="'info.svg' | assetPath" alt />
                </a>
              </div>

              <a
                v-if="isAuth() && isAdministratorAccount()"
                :href="adminEditOptionURL('event', event.event_id)"
                target="_blank"
                class="admin-edit-option"
              >
                <i class="fa fa-edit"></i>
              </a>

              <div class="bottom-decription">
                <h6>
                  <router-link
                    v-if="isAuth()"
                    class="logged-in"
                    :to="{ path: 'event/' + event.event_id }"
                    >{{ limitStr(event.event_title, 26) }}</router-link
                  >
                  <a
                    v-else
                    class="login"
                    @click="loginModal(event.event_id, '#/event/')"
                    :href="url('event-detail/' + event.event_id)"
                    @contextmenu="blockRightClick($event)"
                    >{{ limitStr(event.event_title, 26) }}</a
                  >
                </h6>
                <p title="Start & End Dates" class="dates">
                  {{ event.start_date }} - {{ event.end_date }}
                </p>
                <p
                  v-if="event.start_time"
                  class="event-time"
                  title="Start Time"
                >
                  Start Time: {{ event.start_time }}
                </p>
                <p>
                  <router-link
                    v-if="isAuth()"
                    class="venue-link"
                    :to="{ name: 'venue', params: { id: event.venue_id } }"
                  >
                    {{ limitStr(event.venue_title, 26) }}
                  </router-link>
                  <a
                    v-else
                    @click="loginModal(event.venue_id, '#/venue/')"
                    class="flyer-venue-name"
                    :title="event.venue_title"
                    href="#"
                    >{{ limitStr(event.venue_title, 26) }}</a
                  >
                </p>
                <p :title="event.full_address">
                  {{ limitStr(event.full_address, 26) }}
                </p>
                <div class="hashtag-wrapper">
                  <button
                    :title="event.category"
                    href="javascript:void(null);"
                    v-if="event.category"
                    @click="hashSearch(event.category)"
                    class="event-hashtags"
                  >
                    {{ event.category | checkNullHash }}
                  </button>
                  <button
                    :title="event.venue_type"
                    href="javascript:void(null);"
                    v-if="event.venue_type"
                    @click="hashSearch(event.venue_type)"
                    class="event-hashtags"
                  >
                    {{ event.venue_type | checkNullHash }}
                  </button>
                  <button
                    :title="event.theme"
                    href="javascript:void(null);"
                    v-if="event.theme"
                    @click="hashSearch(event.theme)"
                    class="event-hashtags"
                  >
                    {{ event.theme | checkNullHash }}
                  </button>
                </div>
                <!--Following Event Component -->
                <event_follow
                  v-bind:events="events"
                  v-bind:event="event"
                  v-bind:index="key"
                ></event_follow>

                <!--Social Share  plugin -->
                <div class="dropup homeShareButton">
                  <button
                    class="btn btn-default vue-social-share"
                    id="social-network-share"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-share-alt"></i>
                    <span>Share</span>
                  </button>
                  <div
                    class="dropdown-menu vue-social-share-items"
                    aria-labelledby="dropdownMenu2"
                  >
                    <li class="dropdown-item">
                      <ShareNetwork
                        network="facebook"
                        :url="socialShareLink('event-detail/' + event.event_id)"
                        :title="event.event_title"
                        description="{event.formatted_start_date+'-'+event.formatted_start_date}"
                      >
                        <i class="fab fah fa-lg fa-facebook"></i>
                        <span>Share on Facebook</span>
                      </ShareNetwork>
                    </li>
                    <li class="dropdown-item">
                      <ShareNetwork
                        network="twitter"
                        :url="socialShareLink('event-detail/' + event.event_id)"
                        :title="event.event_title"
                        description="{event.formatted_start_date+'-'+event.formatted_start_date}"
                      >
                        <i class="fab fah fa-lg fa-twitter"></i>
                        <span>Share on Twitter</span>
                      </ShareNetwork>
                    </li>
                  </div>
                </div>

                <!--Private Location  plugin -->
                <div
                  v-if="
                    event.type &&
                    event.type == 'PrivateLocation' &&
                    isPrivateContacts(event.contacts)
                  "
                  class="dropup private-info"
                >
                  <button
                    class="btn btn-default notfollow"
                    id="private-contact-info"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    <!-- <i class="fas fa-mobile-alt"></i> -->
                    <img
                      width="16"
                      :src="'icons/call.svg' | assetPath"
                      alt="Call"
                    />
                    Contact
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="private-contact-info"
                  >
                    <li>
                      <a href="#"><strong>Private Event Information</strong></a>
                    </li>
                    <li role="separator" class="divider"></li>
                    <template v-for="contact in event.contacts">
                      <li v-if="contact.provider == 'private-number'">
                        <a :href="'tel:' + contact.value" class="dropdown-item">
                          <img
                            width="20"
                            :src="'icons/call.svg' | assetPath"
                            alt="Call"
                          />
                          {{ contact.value }}
                          <!-- <i class="fas fa-envelope"> {{ contact.value }}</i> -->
                        </a>
                      </li>
                      <li v-if="contact.provider == 'private-email'">
                        <a
                          :href="'mailto:' + contact.value"
                          class="dropdown-item"
                        >
                          <!-- <i class="fas fa-envelope"> {{ contact.value }}</i> -->
                          <img
                            width="20"
                            :src="'icons/email.svg' | assetPath"
                            alt="Email"
                          />
                          {{ contact.value }}
                        </a>
                      </li>
                      <li v-if="contact.provider == 'private-link'">
                        <a :href="contact.value" class="dropdown-item">
                          <img
                            width="20"
                            :src="'icons/link.svg' | assetPath"
                            alt="URL"
                          />
                          URL
                          <!-- <i class="fas fa-globe"> Website</i> -->
                        </a>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { bus } from "../../app";
import { API_URL } from "../../app";
import LikeEvent from "../global/like .vue";
import Event_Follow from "../global/event-follow-button.vue";
import ResultNotFound from "./../../views/user/partials/result-not-found";
import helpers from "../../mixin/helpers";
import { mapFields } from "vuex-map-fields";
import Loadmore from "vue-loadmore";
import InfiniteLoading from "vue-infinite-loading";

import { ContentLoader, FacebookLoader } from "vue-content-loader";

export default {
  data() {
    return {
      disable: false,
      busy: false,
      page: 1,
      pageSize: 8,
      viewType: "eventType",
      loadingContent: false,
      loadingMoreContent: false,
      Searching: false,
      processing: false,
      showLoadMore: true,
      events: [],
      heartImage: "heart.svg",
      customAdCounter: 0,
      private_location: {
        email: "",
        phone: "",
        link: "",
      },
    };
  },
  mixins: [helpers],
  computed: {
    ...mapFields([
      "search_term",
      "search_offset",
      "search_limit",
      "search_main_category",
      "search_sub_category",
      "search_top_hash",
      "search_date_range",
      "search_business_type",
      "search_business_sub_type",
      "search_event_theme",
      "search_event_genre",
      "search_event_cuisines",
      "search_event_amenities",
      "search_total_results",
      "search_hash_listing",
      "search_user_filters",
      "isSearching",
      "trendingEventList",
      "trendingHashList",
    ]),
  },
  created() {},
  components: {
    ContentLoader,
    FacebookLoader,
    like_event: LikeEvent,
    event_follow: Event_Follow,
    result_not_found: ResultNotFound,
    loadmore: Loadmore,
  },
  props: [],
  mounted() {},
  methods: {
    hashSearch: function (hash) {
      this.search_term = hash;
      bus.$emit("search_trending_hash");
    },
    followVenue: function (id, venue_id) {
      this.customAdCounter = 0;
      this.events = this.events.map((el) => {
        el.event_id === id ? (el.follow = true) : "";
        return el;
      });
      var vm = this;
      //this.heartImage = 'red-heart.svg';
      var data = new FormData();
      data.append("content_id", venue_id);
      data.append("content_type", "venue");
      data.append("favourite", true);

      if (vm.processing === true) {
        return false;
      }

      vm.processing = true;
      axios
        .post("data/markFavourite", data)
        .then(function (response) {
          vm.processing = false;
        })
        .catch(function (errors) {
          vm.processing = false;
        });
    },
    getNumberReminder: function (item, divider) {
      item = item + 1;
      var reminder = item % divider;
      if (reminder == 0 && item != 0) {
        console.log("Reminder", reminder);
        return true;
      } else {
        return false;
      }
    },
    cardType: function (index, eventType, type) {
      var str = "main-card main-card-" + index;
      if (eventType != "event" && type == "ads") {
        str += " ads-card-item";
      }
      if (eventType == "event" && type == "event") {
        str += " event-card-item";
      }

      if (this.getNumberReminder(index, 13) && type == "google-card") {
        str += " google-ads-card-item";
      }
      if (this.getNumberReminder(index, 15) && type == "google-full-width") {
        str += " google-full-width-card-item";
      }

      return str;
    },
    blockRightClick: function (e) {
      e.preventDefault();
    },
  },
  filters: {
    checkNullHash: function (hash) {
      var hashTag = "";
      if (hash != "" && hash != null && hash != "null") {
        hashTag = hash.indexOf("#") > -1 ? hash : "#" + hash;
      }
      return hashTag;
    },
    NATime: function (time) {
      if (time == "" || time == null || time == "00:00:00") {
        time = "- N/A";
      } else {
        time = "- " + time;
      }
      return time;
    },
    NADate: function (date) {
      if (date == "" || date == null) {
        date = "- N/A";
      } else {
        date = "- " + date;
      }
      return date;
    },
    assetPath: function (img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
    fullAddress: function (event) {
      var address = event.city;
      address += event.state != null ? ", " + event.state : "";
      address += event.zip_code != null ? ", " + event.zip_code : "";
      return address;
    },
    checkObj: function (obj) {
      return obj == "" ? false : true;
    },
    url: function (path) {
      var path = window.axios.defaults.baseURL + "/" + path;
      return path;
    },
  },
};
</script>
