<template>
  <div>
    <Select2
      name="sub_category"
      id="sub_category"
      v-model="sub_cat"
      placeholder="Select One"
      :options="getEventSubCategories"
      :settings="{placeholder:'Select One',class:'floating-label-field form-control floating-label-field--s3 form-control',selectOnClose: false,allowClear: true,closeOnSelect: true}"
    />
  </div>
</template>
<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";
import Select2 from "v-select2-component";

export default {
  props: ['category',"sub_category"],
  mixins: [helpers],
  watch: {},
  updated() {

  },
  created() {
    this.sub_cat = this.sub_category;
  },
  computed: {
    getEventSubCategories() {
      return this.$store.getters.getEventSubCategories;
    },
  },
  components: {
    Select2
  },
  data: function() {
    return {
      sub_cat: ""
    };
  },
  methods: {
    
  }
};
</script>
