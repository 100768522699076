<template>
    <span v-on:click="likeEvent(event)" class="heart"><img :src="likeImage(event)" /></span>
</template>

<script>

    import { bus } from '../../app'
    import helpers from "../../mixin/helpers";
    import {
            ContentLoader,
            FacebookLoader,
            CodeLoader,
            BulletListLoader,
            InstagramLoader,
            ListLoader
    } from 'vue-content-loader'

    export default {
        props:['events','event','index'],
        mixins: [helpers],
        watch: {

        },
        components: {

        },
        computed: {

        },
        data: function () {
            return {
                like: false,
                processing:false,
            }
        },
        methods: {
            likeEvent: function(event) {

                var vm = this;
                if(typeof vm.$session.get('access_token') == 'undefined'){
                    $('#loginModal').modal('show');
                    return false;
                }

                if(!vm.hasSubscribedPlan()){
                    sweetalert2.fire({
                        icon: 'info',
                        title: 'Upgrade Subscription Plan',
                        text: 'Please upgrade your subscription plan to like and follow event.'
                    })
                    return false;
                }

                var like = event.like == true ? false: true;
                var data = new FormData();
                data.append("content_id", event.id);
                data.append("content_type", 'event');
                data.append("like", like);

                vm.events[vm.index].like = like;
                if (vm.processing === true) {
                    return false;
                }

                vm.processing = true;
                axios.post("data/markLike", data)
                    .then(function (response) {
                        
                        vm.processing = false;
                        vm.$forceUpdate();

                    })
                    .catch(function (errors) {
                        vm.processing = false;
                    });


            },
            likeImage: function (event) {


                var vm = this;
                var img = 'heart-grey.svg';
                if(typeof vm.$session.get('access_token') == 'undefined'){
                    img = 'heart-grey.svg';
                }else{

                    if(event.like) {
                        img = 'red-heart.svg'
                    }
                }
                return vm.$options.filters.assetPath(img);

            },
        },

        filters: {
            assetPath: function (img) {
                var path = window.axios.defaults.baseURL+'/images/'+img;
                return path;
            },
        }
    }

</script>
