<template>
    <section id="promo-videos" class="promo-view vgps-event-detail-section-wrapper">
            <div class="containers">
                <div class="row">
                    <div class="col-md-12 vgps-event-detail-section-title">
                        <h2>Promo Videos</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="owl-carousel pro promo-vid-carousel">
                            <!-- @foreach($eventDetail->promo_videos as $promo) -->
                                <div v-for="(video,index) in venue.promo_videos" :key="index" class="vgps-event-promo-video-wrapper">
                                    <iframe width="auto" height="auto" :src="video.url" frameborder="0"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen>
                                    </iframe>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";


export default {
  data() {
    return {
      //loadingContent: false,
    };
  },
  props: ["venue"],
  mixins: [helpers],
  mounted() {
      
      $(".owl-carousel.promo-vid-carousel").owlCarousel({
        loop: false,
        responsiveClass: true,
        nav: true,
        responsive: {
            0: {
                items: 1,
                nav: true,
                margin: 10,
                // stagePadding: 30
            },
            600: {
                items: 1,
                nav: false,
                margin: 10,
                // stagePadding: 30
            },
            1000: {
                items: 1,
                nav: true,
                margin: 30,
                stagePadding: 100
            }
        }
    });

  },
  created() {
    
    

  },
  components: {
    
  },
  methods: {
    
  },
  filters: {
    
  }
};
</script>
