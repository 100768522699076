<template>
  <div class="filter-content my-follow-event-listing">
    <div class="row">
      <div class="col-md-12"></div>
    </div>
    <div v-if="loadingContent" class="content-loader2">
      <!-- <img :src="'icons/vgps-loader.gif' | assetPath" /> -->
      <content-loader
        width="1490"
        height="475"
        :speed="1"
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="20" rx="8" ry="8" width="350" height="350" />
        <rect x="0" y="390" rx="0" ry="0" width="200" height="18" />
        <rect x="0" y="420" rx="0" ry="0" width="120" height="20" />

        <rect x="380" y="20" rx="8" ry="8" width="350" height="350" />
        <rect x="380" y="390" rx="0" ry="0" width="200" height="18" />
        <rect x="380" y="420" rx="0" ry="0" width="120" height="20" />

        <rect x="760" y="20" rx="8" ry="8" width="350" height="350" />
        <rect x="760" y="390" rx="0" ry="0" width="200" height="18" />
        <rect x="760" y="420" rx="0" ry="0" width="120" height="20" />

        <rect x="1140" y="20" rx="8" ry="8" width="350" height="350" />
        <rect x="1140" y="390" rx="0" ry="0" width="200" height="18" />
        <rect x="1140" y="420" rx="0" ry="0" width="120" height="20" />
      </content-loader>
    </div>
    <div v-else class="loaded-content">
      <template v-if="gridView">
        <div v-if="events.length > 0" class="row">
          <div
            class="col-lg-3 col-md-4 col-sm-6 col-xs-12 ceo main-card"
            v-for="(event, key) in events"
            :key="key"
          >
            <div class="content" :class="event.activation_status_display">
              <div
                class="pic event-thumb-img"
                v-lazy:background-image="event.thumbnail_urls.thumb_296"
              >

              <!-- <div style="float: right;top:52px; position: relative;right: 4px">
                    <event_follow
                      v-bind:events="events"
                      v-bind:event="event"
                      v-bind:index="key"
                    ></event_follow>
                  </div> -->
                <router-link
                  class="flyer-venue-name"
                  :to="{ name: 'event', params: { id: event.id } }"
                ></router-link>
                <!-- <a class="flyer-venue-name" :href="'event-detail/'+event.id | url"></a> -->
                <div class="seen-title">
                  <span class="time">{{ event.category }}</span>
                  <span class="eye" style="margin-left: 164px;">
                    <img :src="'seen.svg' | assetPath" /><br>
                    {{ event.total_views }}
                  </span>
                  <span
                    title="Private Location"
                    v-if="event.type && event.type == 'PrivateLocation'"
                    class="time private-label"
                    >Private</span
                  >
                </div>
                <!--Venue AID -->
                <div
                  v-if="event.venue.closing_reasons.title != 'Normal'"
                  class="venue-aid"
                >
                  <span
                    class=""
                    :title="
                      event.venue.closing_reasons.severity.description +
                      ' | Duration: ' +
                      event.venue.closing_reasons.duration.time_duration
                    "
                    :class="event.venue.closing_reasons.color"
                  >
                    V-AID: {{ event.venue.closing_reasons.closing_reason }}
                    <img width="15" :src="'info.svg' | assetPath"
                  /></span>
                </div>
                 <!--Venue AID -->
                  <div v-if="event.venue.closing_reasons.title !='Normal'" class="venue-aid">
                    <span class=""  :title="getVenueAidTitle(event)" 
                    
                    :class="event.venue.closing_reasons.color">
                      <!-- V-AID: {{ event.venue.closing_reasons.closing_reason }}  -->
                      {{ event.venue.closing_reasons.severity.level | checkNullSeverity }}
                    <img width="15" :src="'info.svg' | assetPath"></span>
                  </div>
                <!--like Event Component -->
                <like_event
                  v-bind:events="events"
                  v-bind:event="event"
                  v-bind:index="key"
                ></like_event>
              </div>
              <div
                v-if="event.activation_status_display"
                class="event-status-flag activation-status-flag"
                :class="[
                  event.activation_status_id
                    ? 'status-bg-' + event.activation_status_id
                    : '',
                ]"
              >
                <a :class="[ event.activation_status? 'status-bg-'+event.activation_status:'' ]" href="#">
                  {{ event.activation_status_display }}
                  <img :src="'info.svg' | assetPath" />
                </a>
              </div>
              <div class="bottom-decription">
                <h6>
                  <router-link
                    :to="{ name: 'event', params: { id: event.id } }"
                    >{{ limitStr(event.title, 28) }}</router-link
                  >
                  <!-- <a :href="'event-detail/'+event.id | url">{{ limitStr(event.title,28) }}</a> -->
                </h6>
                <p title="Start & End Dates" class="dates">
                  {{ event.start_date }} - {{ event.end_date }}
                </p>
                <!-- <p title="Start Time">Start Time: {{ event.start_time }}</p> -->
                <p
                  v-if="event.start_time"
                  class="event-time"
                  title="Start Time"
                >
                  Start Time: {{ event.start_time }}
                </p>

                <p>
                  <router-link
                    class="venue-link"
                    :to="{ name: 'venue', params: { id: event.venue.id } }"
                    >{{ limitStr(event.venue.title, 28) }}</router-link
                  >
                  <!-- <a class="flyer-venue-name" :href="venueURL(event.venue.type,event.venue.id)">
                    {{ limitStr(event.venue.title,28) }}
                  </a> -->
                </p>
                <p>
                  {{
                    limitStr(
                      event.address.city + ", " + event.address.state_code,
                      26
                    )
                  }}
                </p>
                <!-- <div class="vgps-event-hashtags-wrapper">
                  <ul>
                    <li
                      class="hash-item-search"
                      :data-category="event.category"
                      data-type="category"
                    >
                      <a
                        :title="event.category"
                        @click="searchHashTag('#' + event.category)"
                        href="javascript:void();"
                        >{{ event.category | checkNullHash }}</a
                      >
                    </li>
                    <li
                      class="hash-item-search"
                      :data-category="event.category"
                      data-type="venue_type"
                      :data-venue_type="event.venue_type"
                    >
                      <a
                        :title="event.type"
                        @click="searchHashTag('#' + event.venue.type)"
                        href="javascript:void();"
                        >{{ event.venue.type | checkNullHash }}</a
                      >
                    </li>
                    <li
                      class="hash-item-search"
                      data-type="theme"
                      :data-theme="event.theme"
                    >
                      <a
                        :title="event.theme"
                        @click="searchHashTag('#' + event.theme)"
                        href="javascript:void();"
                        >{{ event.theme | checkNullHash }}</a
                      >
                    </li>
                  </ul>
                </div> -->

                <!--Following Event Component -->
                <follow_venue
                  v-bind:events="events"
                  v-bind:event="event"
                  v-bind:venueid="event.venue.id"
                  v-bind:viewType="'eventType'"
                  v-bind:index="key"
                ></follow_venue>

                <!--Social Share  plugin -->
                <div class="dropup">
                  <button
                    class="btn btn-default vue-social-share"
                    id="social-network-share"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-share-alt"></i>
                    <span>Share</span>
                  </button>
                  <div
                    class="dropdown-menu vue-social-share-items"
                    aria-labelledby="dropdownMenu2"
                  > 
                    <li class="dropdown-item">
                      <ShareNetwork
                        network="facebook"
                        :url="socialShareLink('event-detail/' + event.event_id)"
                        :title="event.event_title ? event.event_title : ''"
                        :description="
                          event.formatted_start_date +
                          '-' +
                          event.formatted_start_date
                        "
                      >
                        <i class="fab fah fa-lg fa-facebook"></i>
                        <span>Share on Facebook</span>
                      </ShareNetwork>
                    </li>
                    <li class="dropdown-item">
                      <ShareNetwork
                        network="twitter"
                        :url="socialShareLink('event-detail/' + event.event_id)"
                        :title="event.event_title? event.event_title :''"
                        :description="
                          event.formatted_start_date +
                          '-' +
                          event.formatted_start_date
                        "
                      >
                        <i class="fab fah fa-lg fa-twitter"></i>
                        <span>Share on Twitter</span>
                      </ShareNetwork>
                    </li>
                  </div>
                </div>

                <!--Private Location  plugin -->
                <div
                  v-if="
                    event.type &&
                    event.type == 'PrivateLocation' &&
                    isPrivateContacts(event.contacts)
                  "
                  class="dropup private-info"
                >
                  <button
                    class="btn btn-default notfollow"
                    id="private-contact-info"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    <!-- <i class="fas fa-mobile-alt"></i> -->
                    <img
                      width="15"
                      :src="'icons/call.svg' | assetPath"
                      alt="Call"
                    />
                    Contact
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="private-contact-info"
                  >
                    <li>
                      <a href="#"><strong>Private Event Information</strong></a>
                    </li>
                    <li role="separator" class="divider"></li>
                    <template v-for="contact in event.contacts">
                      <li v-if="contact.provider == 'private-number'">
                        <a :href="'tel:' + contact.value" class="dropdown-item">
                          <img
                            width="20"
                            :src="'icons/call.svg' | assetPath"
                            alt="Call"
                          />
                          {{ contact.value }}
                          <!-- <i class="fas fa-envelope"> {{ contact.value }}</i> -->
                        </a>
                      </li>
                      <li v-if="contact.provider == 'private-email'">
                        <a
                          :href="'mailto:' + contact.value"
                          class="dropdown-item"
                        >
                          <!-- <i class="fas fa-envelope"> {{ contact.value }}</i> -->
                          <img
                            width="20"
                            :src="'icons/email.svg' | assetPath"
                            alt="Email"
                          />
                          {{ contact.value }}
                        </a>
                      </li>
                      <li v-if="contact.provider == 'private-link'">
                        <a :href="contact.value" class="dropdown-item">
                          <img
                            width="20"
                            :src="'icons/link.svg' | assetPath"
                            alt="URL"
                          />
                          URL
                          <!-- <i class="fas fa-globe"> Website</i> -->
                        </a>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div v-if="loadingMoreContent" class="content-loader">
            <img :src="'icons/vgps-loader.gif' | assetPath" />
          </div>
          <div
            v-if="showLoadMore"
            class="col-lg-12 col-md-12 col-sm-12 col-xs-12 clear-row"
          >
            <a @click="loadMore" class="see-more" href="javascript:void(0)"
              >See more events</a
            >

          </div>
        </div>
        <result_not_found v-if="events.length == 0"></result_not_found>
      </template>
      <!-- <template v-else>
        <full_calendar :events="calendar_events" @event-selected="dayClick" :config="config"></full_calendar>
      </template> -->
    </div>
  </div>
</template>

<script>
import { bus } from "../../app";
import LikeEvent from "../global/like .vue";
import FollowVenue from "../global/follow-button.vue";
import ResultNotFound from "./../../views/user/partials/result-not-found";
import helpers from "../../mixin/helpers";

// import { FullCalendar } from "vue-full-calendar"; // https://www.npmjs.com/package/vue-full-calendar
// import "fullcalendar/dist/fullcalendar.css";

import {
  ContentLoader,
  FacebookLoader
} from "vue-content-loader";

export default {
  data() {
    return {
      viewType: "eventType",
      loadingContent: false,
      loadingMoreContent: false,
      Searching: false,
      showLoadMore: true,
      gridView: true,
      events: [],
      calendar_events: [
        {
          title: "event1",
          start: "2019-11-17 18:00:00",
          end: "2019-11-19",
        },
        {
          title: "event2",
          start: "2019-11-25T12:30:00",
          end: "2019-11-28T01:30:00",
          color: "yellow",
          textColor: "black",
        },
        {
          title: "event3",
          start: "2019-11-09T12:30:00",
          allDay: false,
        },
      ],
      config: {
        defaultView: "month",
        weekday: "long",
      },
      header: {
        // left: 'prev,next,today',
        // center: 'title',
        left: "title",
        center: "",
        right: "month,week,day",
      },
      heartImage: "heart.svg",
    };
  },
  mixins: [helpers],
  created() {
    bus.$on("advanceFilter", () => {
      this.getEvents("advanceFilter");
    });

    bus.$on("search_event_title", (keyword) => {
      this.filters.search = keyword;
      this.scrollToDiv("filter-id");
      this.getEvents("");
    });

    bus.$on("filterSelection", () => {
      this.getEvents("filterSelection");
    });

    bus.$on("updateDateValues", () => {
      this.getEvents("updateDateValues");
    });
    bus.$on("updateLocation", () => {
      this.getEvents("updateLocation");
    });
  },
  beforeUpdate() {},
  components: {
    ContentLoader,
    FacebookLoader,
    // full_calendar: FullCalendar,
    // FullCalendar,
    like_event: LikeEvent,
    follow_venue: FollowVenue,
    result_not_found: ResultNotFound,
  },
  props: ["filters"],
  mounted() {
    this.getEvents();
  },
  methods: {
    dayClick(date, jsEvent, view) {
      var url =
        window.axios.defaults.baseURL + "/event-detail/" + date.event_id;
      window.open(url, "_blank");
    },
    getVenueAidTitle: function (event) {
      var title = "";
      if (
        event.venue.closing_reasons.severity.description &&
        event.venue.closing_reasons.title != "Normal"
      ) {
        title = event.venue.closing_reasons.severity.description;
        title += event.venue.closing_reasons.duration.time_duration
          ? " | Duration:" + event.venue.closing_reasons.duration.time_duration
          : "";
      } else {
        title = event.venue.closing_reasons.title;
      }

      return title;
    },
    changeView(view) {
      var vm = this;
      vm.gridView = false;
      vm.calendar_events = [];
      $.each(vm.events, function (index, obj) {
        if (obj.performances.length > 0) {
          $.each(obj.performances, function (index2, obj2) {
            vm.calendar_events.push({
              title: obj.title,
              event_id: obj.id,
              start: obj2.calendar_start_dt,
              end: obj2.calendar_end_dt,
            });
          });
        } else {
          vm.calendar_events.push({
            title: obj.title,
            start: obj.calendar_start_dt,
            end: obj.calendar_end_dt,
            event_id: obj.id,
          });
        }
      });
    },
    showGridView() {
      this.gridView = true;
      shareButtonEnable(this.events, "event-detail");
    },
    loadMore: function () {
      this.filters.offset = this.filters.offset + this.filters.limit;
      this.getEvents("loadmore");
    },
    getImageUrl(isLike) {
      var path = window.axios.defaults.baseURL + "/images/";
      return isLike === true ? path + "red-heart.svg" : path + "heart.svg";
    },
    getEvents: function (action) {
      var vm = this;
      if (action == "loadmore") {
        vm.loadingMoreContent = true;
      } else {
        vm.loadingContent = true;
      }
      axios
        .get("data/myevent/", {
          params: {
            type: this.filters.type,
            offset: this.filters.offset,
            limit: this.filters.limit,
          },
        })
        .then(function (response) {

          if (action == "loadmore") {
            $.each(response.data.data.events, function (index, obj) {
              vm.events.push(obj);
            });
            vm.showLoadMore =
              response.data.data.events.length < vm.filters.limit
                ? false
                : true;
            vm.loadingMoreContent = false;
          } else {
            if (action == "updateLocation" || action == "search_event_title") {
              document
                .getElementById("filter-id")
                .scrollIntoView({ block: "end", behavior: "smooth" });
            }
            vm.events = response.data.data.events;
            vm.showLoadMore =
              response.data.data.events.length < vm.filters.limit
                ? false
                : true;
            vm.loadingContent = false;
          }
          shareButtonEnable(response.data.data.events, "event-detail");
        })
        .catch(function (errors) {
          vm.events = [];
          vm.loadingContent = false;
          vm.loadingMoreContent = false;
        });
    },
    getFollowClass(isFollow) {
      return isFollow === true ? "follow mobile-hide" : "notfollow mobile-hide";
    },
    getFollowClassMobile(isFollow) {
      return isFollow === true
        ? "follow desktop-hide inline"
        : "notfollow desktop-hide inline";
    },
    getImageUrlFollow(isLike) {
      var path = window.axios.defaults.baseURL + "/images/";
      return isLike === true ? path + "fav-w.svg" : path + "fav.svg";
    },
    scrollToDiv: function (divID) {
      $("html, body").animate(
        {
          scrollTop: $("#" + divID).offset().top,
        },
        1000
      );
    },
    followVenue: function (id, venue_id) {
      this.events = this.events.map((el) => {
        el.event_id === id ? (el.follow = true) : "";
        return el;
      });
      var vm = this;
      //this.heartImage = 'red-heart.svg';
      var data = new FormData();
      data.append("content_id", venue_id);
      data.append("content_type", "venue");
      data.append("favourite", true);

      if (vm.processing === true) {
        return false;
      }

      vm.processing = true;
      axios
        .post("data/markFavourite", data)
        .then(function (response) {
          vm.processing = false;
        })
        .catch(function (errors) {
          vm.processing = false;
        });
    },
    favoriteEvent: function (id) {
      this.events = this.events.map((el) => {
        el.id === id ? (el.like = true) : "";
        return el;
      });
      var vm = this;
      //this.heartImage = 'red-heart.svg';
      var data = new FormData();
      data.append("content_id", id);
      data.append("content_type", "event");
      data.append("favourite", true);

      if (vm.processing === true) {
        return false;
      }

      vm.processing = true;
      axios
        .post("data/markFavourite", data)
        .then(function (response) {
          vm.processing = false;
        })
        .catch(function (errors) {
          vm.processing = false;
        });
    },
  },
  filters: {
    checkNullHash: function (hash) {
      var hashTag = "";
      if (hash != "" && hash != null && hash != "null") {
        hashTag = "#" + hash;
      }
      return hashTag;
    },
    checkNullSeverity: function(severity) {
      var str = "V-AID";
      if (severity != "" && severity != null) {
        str += ": "+severity;
      }
      return str;
    },
    assetPath: function(img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
    checkObj: function (obj) {
      return obj == "" ? false : true;
    },
    url: function (path) {
      var path = window.axios.defaults.baseURL + "/" + path;
      return path;
    },
  },
};
</script>

