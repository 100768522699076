<template>
    
    <div class="subscription-mobile-view hidden-md hidden-lg">
        
        <nav>
            <ul class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">

              <a href="#div-free-plan" v-cloak role="tab"
                    id="free-plan-tab" 
                    class="nav-item"
                    :class="{ activetab : active_el == -1 }"
                    data-toggle="tab" aria-controls="free-plan" 
                    @click="activate(-1)"  
                    aria-expanded="true">Trial Plan
              </a>

                <template v-for="(plan,linkIndex) in packages">
                    <a :href="'#div-'+plan.title" role="tab"
                    :id="plan.title+'-tab'" 
                    class="nav-item"
                    :class="{ activetab : active_el == linkIndex }"
                    data-toggle="tab" :aria-controls="plan.title" 
                    @click="activate(linkIndex)"  
                    aria-expanded="true">{{plan.title === 'VIP' ? 'Premium' : plan.title}}</a>
                </template>
            </ul>
        </nav>

        <div class="tab-content pricinng-plan" id="nav-tabContent">

          <div class="tab-pane fade active in" id="div-free-plan" role="tabpanel" aria-labelledby="free-plan-tab" >
            <!--Trial Card-->
            <div v-cloak class="col-lg-3 col-md-3 col-sm-3 col-xs-12 mb-4 plan-card">
              <TrialCardView/>
            </div>
          </div>

            <template  v-for="(plan,index) in packages">
                <div     v-if="active_el === index"
                class="tab-pane fade" :class="[index == 0?'active in':'']" :id="'div-'+plan.title" role="tabpanel" :aria-labelledby="plan.title+'-tab'" >
                    

                    
                    <!--Grid column-->
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 mb-4 plan-card">                    
                      <!-- Card -->
                      <CardView :plan="plan" />
                      <!-- Card -->
                    </div>
                    <!--Grid column-->
                </div>
            </template>
        </div>
    </div>
</template>

<script>

import CardView from "./../../../views/partials/subscription/card-view";
import TrialCardView from "./../../../views/partials/subscription/trial-card-view";
import helpers from "./../../../mixin/helpers";

export default {

  data() {
    return {
      loadingContent: false,
      subscriptionsList: [],
      active_el:-1
    };
  },
  props: ["packages"],
  mixins: [helpers],
  components: {
    CardView,
    TrialCardView
  },
  methods:{
    activate:function(el){
        this.active_el = el;
    }
  }
};
</script>