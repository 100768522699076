<template>
  <section class="event-detail" :class="eventDisplayStatus">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <div class="event-detail-flyer-img-wrapper">
            <silent-box
              v-if="event.image"
              :image="{
                thumbnail: event.thumbnail_urls.thumb_296,
                src: event.image,
              }"
            ></silent-box>

            <div
              class="view-large-image"
              title="Click over the flyer image for full screen"
            >
              <i class="fas fa-expand" aria-hidden="true"></i>
            </div>
            <div
              v-if="event.activation_status && event.activation_status != 1"
              class="event-activation-status-flag"
            >
              <a
                :class="[
                  event.activation_status
                    ? 'status-bg-' + event.activation_status
                    : '',
                ]"
                href="#"
                >{{ eventDisplayStatus }}
                <img width="22px" :src="'info.svg' | assetPath"
              /></a>
            </div>
            <span
              v-if="event.type && event.type == 'PrivateLocation'"
              title="Private Location"
              class="time private-label"
              >Private</span
            >

            <!--Venue AID -->
            <div
              v-if="
                event.venue[0].venue_closing_reason &&
                event.venue[0].venue_closing_reason.title != 'Normal'
              "
              class="venue-aid"
            >
              <span
                class=""
                :title="getVenueAidTitle()"
                :class="event.venue[0].venue_closing_reason.color"
              >
                <!-- V-AID: {{ event.venue[0].venue_closing_reason.severity.level }}  -->
                {{
                  event.venue[0].venue_closing_reason.severity.level
                    | checkNullSeverity
                }}
                <img width="15" :src="'info.svg' | assetPath"
              /></span>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-8 col-xs-12">
          <h2>{{ event.title }}</h2>
          <a
            v-if="isAdministratorAccount()"
            :href="adminEditOptionURL('event', event.id)"
            target="_blank"
            class="admin-edit-option"
          >
            <i class="fa fa-edit"></i>
          </a>

          <div class="event-dates">
            <div class="left">
              <img :src="'info.svg' | assetPath" />
            </div>
            <div class="center">
              <div
                v-if="
                  typeof event.formatted_date_time.start_date != 'undefined'
                "
                class="vgps-event-details-row"
              >
                <div class="vgps-event-detail-label dates">Start Date</div>
                <div class="vgps-event-detail-desc dates">
                  <span>{{ event.formatted_date_time.start_date }}</span>
                </div>
              </div>
              <div
                v-if="typeof event.formatted_date_time.end_date != 'undefined' && event.type !== 'Recurring'"
                class="vgps-event-details-row"
              >
                <div class="vgps-event-detail-label dates">End Date</div>
                
                <div class="vgps-event-detail-desc dates">
                  <span>{{ event.formatted_date_time.end_date }}</span>
                </div>
              </div>
              <div
                v-if="event.formatted_date_time.door_open != ''"
                class="vgps-event-details-row"
              >
                <div class="vgps-event-detail-label">Doors Open at</div>
                <div class="vgps-event-detail-desc">
                  {{
                    event.formatted_date_time.door_open
                      ? event.formatted_date_time.door_open
                      : "N/A"
                  }}
                </div>
              </div>
              <div
                v-if="event.formatted_date_time.start_time != ''"
                class="vgps-event-details-row"
              >
                <div class="vgps-event-detail-label event-time">Starts at</div>
                <div class="vgps-event-detail-desc event-time">
                  {{ event.formatted_date_time.start_time }}
                </div>
              </div>
              <template
                v-if="
                  typeof event.admission_price != 'undefined' &&
                  event.admission_price.min_price != 0 &&
                  event.admission_price.max_price != 0
                "
              >
                <br />
                <div class="vgps-event-details-row">
                  <div class="vgps-event-detail-label">Admission Price</div>
                  <div class="vgps-event-detail-desc">
                    {{
                      "$" +
                      event.admission_price.min_price +
                      " - $" +
                      event.admission_price.max_price
                    }}
                  </div>
                </div>
              </template>
              <template
                v-if="
                  event.bottle_service &&
                  event.bottle_service.min_price != 0 &&
                  event.bottle_service.max_price != 0
                "
              >
                <div class="vgps-event-details-row">
                  <div class="vgps-event-detail-label">Bottle Service</div>
                  <div class="vgps-event-detail-desc">
                    {{
                      "$" +
                      event.bottle_service.min_price +
                      " - $" +
                      event.bottle_service.max_price
                    }}
                  </div>
                </div>
                <br />
              </template>
              <template v-if="event.days.length > 0">
                <div class="vgps-event-details-row">
                  <div class="vgps-event-detail-label">Recurring Days</div>
                </div>
                <ul class="recurring-days-list">
                  <li v-for="day in event.days">{{ day }}</li>
                </ul>
              </template>
            </div>
          </div>

          <div class="event-dates">
            <div class="left">
              <img :src="'tag.svg' | assetPath" />
            </div>
            <div class="center">
              <div class="vgps-event-details-row">
                <div
                  class="vgps-event-detail-desc vgps-event-detail-header-tags-wrapper"
                >
                  <ul class="vgps-event-detail-header-tags">
                    <template v-if="event.hashtags.length > 0">
                      <li v-for="(tag, index) in event.hashtags" :key="index" class="tag-item">
                        <router-link
                          class="event-hashtags"
                          :title="event.theme"
                          :to="{
                            name: 'search',
                            query: {
                              search_term: tag.title,
                              search_main_category: 'All',
                            },
                          }"
                        >
                          {{ tag.title }}
                        </router-link>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div title="Business Name" class="event-dates event-business-name">
            <div class="left">
              <span class="fa fa-building"></span>
            </div>
            <div class="center">
              <p>
  <router-link
    :class="{ 'followed': event.venue[0].follow }"
    style="color: #31a9ed;"
    :to="{ name: 'venue', params: { id: event.venue[0].id } }"
  >
    {{ event.venue[0].title }}
  </router-link>

  <!-- Followed SVG (Filled Star) -->
  <svg
  v-if="event.venue[0].follow"
  style="margin-top: -8px; width: 20px; height: 20px;"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
>
  <defs>
    <linearGradient
      id="gradient"
      gradientUnits="userSpaceOnUse"
      x1="15.701"
      x2="-1.807"
      y1="13.788"
      y2="10.474"
    >
      <stop offset="0" stop-color="#6146b7" />
      <stop offset="1" stop-color="#33aaeb" />
    </linearGradient>
  </defs>
  <path
    d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
    fill="url(#gradient)"
  />
</svg>


  <!-- Unfollowed SVG (Outlined Star) -->
  <svg
    v-else
    style="margin-top: -8px; width: 20px; height: 20px;"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
      fill="none"
      stroke="#33aaeb"
      stroke-width="2"
    />
  </svg>

  <br />
</p>

            </div>
          </div>
          <div class="event-dates">
            <div class="left">
              <img :src="'pin.svg' | assetPath" />
            </div>
            <div class="center">
              <p v-html="directoryfullAddress(event)"></p>
            </div>
          </div>

          <div class="event-btns vgps-events-btns">
            <template
              v-if="
                event.type &&
                event.type == 'PrivateLocation' &&
                private_contacts.length > 0
              "
            >
              <!--Social Share  plugin -->
              <div class="dropup private-info">
                <button
                  class="btn btn-default notfollow"
                  id="private-contact-info"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  <img
                    width="20"
                    :src="'icons/call.svg' | assetPath"
                    alt="Call"
                  />

                  <span>Contact</span>
                </button>
                <ul
                  class="dropdown-menu"
                  aria-labelledby="private-contact-info"
                >
                  <li>
                    <a href="#"><strong>Private Event Information</strong></a>
                  </li>
                  <li role="separator" class="divider"></li>
                  <li v-if="private_location.phone">
                    <a
                      :href="'tel:' + private_location.phone"
                      class="dropdown-item"
                    >
                      <img
                        width="20"
                        :src="'icons/call.svg' | assetPath"
                        alt="Call"
                      />
                      {{ private_location.phone }}
                    </a>
                  </li>
                  <li v-if="private_location.email">
                    <a
                      :href="'mailto:' + private_location.email"
                      class="dropdown-item"
                    >
                      <img
                        width="20"
                        :src="'icons/email.svg' | assetPath"
                        alt="Email"
                      />
                      {{ private_location.email }}
                    </a>
                  </li>
                  <li v-if="private_location.link">
                    <a
                      target="_blank"
                      :href="private_location.link"
                      class="dropdown-item"
                    >
                      <img
                        width="20"
                        :src="'icons/link.svg' | assetPath"
                        alt="URL"
                      />
                      URL
                    </a>
                  </li>
                </ul>
              </div>
            </template>

            <event_detail_follow_button
              v-bind:id="event.id"
              v-bind:title="event.title"
              v-bind:follow="event.follow"
            >
            </event_detail_follow_button>

            <event_detail_like_button
              v-bind:id="event.id"
              v-bind:like="event.like"
              v-bind:count="event.likes_count"
            ></event_detail_like_button>

            <button style="cursor: unset" href="javascript:void(null);">
              <img :src="'seen.svg' | assetPath" alt="" />
              {{ event.total_views }}
            </button>

            <div class="shareButtonWrapper d-inline">
              <button
                class="btn btn-default vue-social-share dropup"
                id="social-network-share"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-share-alt"></i> Share
              </button>
              <div
                class="dropdown-menu vue-social-share-items"
                aria-labelledby="dropdownMenu2"
              >
                <li class="dropdown-item">
                  <ShareNetwork
                    network="facebook"
                    :url="socialShareLink('event-detail/' + event.id)"
                    :title="event.title"
                    description=""
                  >
                    <i class="fab fah fa-lg fa-facebook"></i>
                    <span>Share on Facebook</span>
                  </ShareNetwork>
                </li>
                <li class="dropdown-item">
                  <ShareNetwork
                    network="twitter"
                    :url="socialShareLink('event-detail/' + event.id)"
                    :title="event.title"
                    description=""
                  >
                    <i class="fab fah fa-lg fa-twitter"></i>
                    <span>Share on Twitter</span>
                  </ShareNetwork>
                </li>
              </div>
            </div>
            <!-- end of share button wrapper -->
          </div>
        </div>

        <div class="vHeaderIconWrap col-lg-3 col-sm-4 col-md-3 col-xs-12">
          
          <div class="VHBtnWrap" v-if="delivery_apps.length > 0">
            <div class="dropdown">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                <span class="btnText"> Online Order </span>
                <!-- <span class="caret"></span> -->
              </button>
              <ul class="dropdown-menu">
                <template v-for="dItem in delivery_apps">
                  <li v-if="dItem.value" :key="dItem.id">
                    <a :href="dItem.value" target="_blank"
                      ><img :src="dItem.icon_url" :alt="dItem.provider" />
                      <span> {{ dItem.provider }} </span>
                    </a>
                  </li>
                </template>
              </ul>
            </div>
          </div>

          <div class="VHBtnWrap" v-if="music_apps.length > 0">
            <div class="dropdown">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                <span class="btnText"> Media Apps </span>
                <!-- <span class="caret"></span> -->
              </button>
              <ul class="dropdown-menu">
                <template v-for="music in music_apps">
                  <li v-if="music.value" :key="music.id">
                    <a :href="music.value" target="_blank"
                      ><img :src="music.icon_url" :alt="music.provider" />
                      <span> {{ music.provider }} </span>
                    </a>
                  </li>
                </template>
              </ul>
            </div>
          </div>

          <div class="VHBtnWrap" v-if="onlineTaxiApps.length > 0">
            <div class="dropdown">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                <span class="btnText"> Online Taxi </span>
                <!-- <span class="caret"></span> -->
              </button>
              <ul class="dropdown-menu">
                <template v-for="taxi in onlineTaxiApps">
                  <li v-if="taxi.value" :key="taxi.id">
                    <a :href="taxi.value" target="_blank"
                      ><img :src="taxi.icon_url" :alt="taxi.provider" />
                      <span> {{ taxi.provider }} </span>
                    </a>
                  </li>
                </template>
              </ul>
            </div>
          </div>

          <div class="VHBtnWrap" v-if="foodMenuApps.length > 0">
            <div class="dropdown">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                <span class="btnText"> Food Menu </span>
                <!-- <span class="caret"></span> -->
              </button>
              <ul class="dropdown-menu">
                <template v-for="Food in foodMenuApps">
                  <li v-if="Food.value" :key="Food.id">
                    <a :href="Food.value" target="_blank"
                      ><img :src="Food.icon_url" :alt="Food.provider" />
                      <span> {{ Food.provider }} </span>
                    </a>
                  </li>
                </template>
              </ul>
            </div>
          </div>

          <div class="VHBtnWrap" v-if="tableReservationApps.length > 0">
            <div class="dropdown">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                <span class="btnText"> Table Reservation </span>
                <!-- <span class="caret"></span> -->
              </button>
              <ul class="dropdown-menu">
                <template v-for="Reservation in tableReservationApps">
                  <li v-if="Reservation.value" :key="Reservation.id">
                    <a :href="Reservation.value" target="_blank"
                      ><img :src="Reservation.icon_url" :alt="Reservation.provider" />
                      <span> {{ Reservation.provider }} </span>
                    </a>
                  </li>
                </template>
              </ul>
            </div>
          </div>

          <div class="VHBtnWrap" v-if="luxry_servicess_apps.length > 0">
            <div class="dropdown">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                <span class="btnText"> Luxry Services </span>
                <!-- <span class="caret"></span> -->
              </button>
              <ul class="dropdown-menu">
                <template v-for="luxry in luxry_servicess_apps">
                  <li v-if="luxry.value" :key="luxry.id">
                    <a :href="luxry.value" target="_blank"
                      ><img :src="luxry.icon_url" :alt="luxry.provider" />
                      <span> {{ luxry.provider }} </span>
                    </a>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>

        <!-- end of vHeaher icon wrapper -->
      </div>
    </div>
  </section>
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";

import VueSilentbox from "vue-silentbox"; //https://vuejsexamples.com/a-lightbox-inspired-vue-js-component/
import EventDetailFollowButton from "../../components/global/event-detail-follow-button.vue";
import EventDetailLikeButton from "../../components/global/detail-page-like.vue";
import Event_Follow from "../../components/global/event-follow-button.vue";

import {
  ContentLoader,
  FacebookLoader,
  CodeLoader,
  BulletListLoader,
  InstagramLoader,
  ListLoader,
} from "vue-content-loader";

export default {
  data() {
    return {
      loadingContent: false,
      private_location: {
        email: "",
        phone: "",
        link: "",
      },
    };
  },
  props: ["event"],
  mixins: [helpers],
  computed: {
    private_contacts: function () {
      return this.getPrivateContacts(this.event.contacts["info-contact"]);
    },
    social_apps: function () {
      return this.getSocialApps(this.event.venue[0].social_contacts);
    },
    music_apps: function () {
      return this.getMusicApps(this.event.venue[0].social_contacts);
    },
    delivery_apps: function () {
      return this.getDeliveryApps(this.event.venue[0].social_contacts);
    },
    eventDisplayStatus:function() {
      return this.$store.getters.getEventDisplayStatus;
    },
    luxry_servicess_apps: function () {
      return this.getLuxryServicessApps(this.event.venue[0].social_contacts);
    },
    foodMenuApps: function () {
      return this.getFoodMenuApps(this.event.venue[0].social_contacts);
    },
    onlineTaxiApps: function () {
      return this.getOnlineTaxiApps(this.event.venue[0].social_contacts);
    },
    tableReservationApps: function () {
      return this.getTableReservationApps(this.event.venue[0].social_contacts);
    },
  },
  created() {
    var vm = this;
    console.log("header section data", vm.event);

    vm.$store.dispatch("getEventDisplayStatusAction", vm.event.activation_status);

    $.each(vm.event.contacts["info-contact"], function (index, obj) {
      if (obj.provider == "private-number") {
        vm.private_location.phone = obj.value;
      }
      if (obj.provider == "private-email") {
        vm.private_location.email = obj.value;
      }
      if (obj.provider == "private-link") {
        vm.private_location.link = obj.value;
      }
    });

   

  },
  components: {
    event_follow: Event_Follow,
    ContentLoader,
    FacebookLoader,
    VueSilentbox,
    event_detail_follow_button: EventDetailFollowButton,
    event_detail_like_button: EventDetailLikeButton,
    // follow_venue: FollowVenue
  },
  methods: {
    getVenueAidTitle: function () {
      var title = "";
      if (
        this.event.venue[0].venue_closing_reason.severity.description &&
        this.event.venue[0].venue_closing_reason.title != "Normal"
      ) {
        title = this.event.venue[0].venue_closing_reason.severity.description;
        title += this.event.venue[0].venue_closing_reason.duration.time_duration
          ? " | Duration:" +
            this.event.venue[0].venue_closing_reason.duration.time_duration
          : "";
      } else {
        title = this.event.venue[0].venue_closing_reason.title;
      }
      return title;
    },
    directoryfullAddress: function (event) {
      var address = "";
      address +=
        event.address_details.street &&
        event.address_details.street != "NULL" &&
        event.type != "PrivateLocation"
          ? event.address_details.street + ",<br>"
          : "";
      address +=
        event.address_details.full_address &&
        event.address_details.full_address != "NULL"
          ? event.address_details.full_address
          : "";
      return address;
    },
    getImageUrl(isLike) {
      var path = window.axios.defaults.baseURL + "/images/";
      return isLike === true ? path + "fav-w.svg" : path + "fav.svg";
    },
  },
  filters: {
    checkNullSeverity: function (severity) {
      var str = "V-AID";
      if (severity != "" && severity != null) {
        str += ": " + severity;
      }
      return str;
    },
    assetPath: function (img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
    url: function (path) {
      var path = window.axios.defaults.baseURL + "/" + path;
      return path;
    },
  },
};
</script>
<style scoped>
.venue-link {
  font-size: 16px;
  text-decoration: none;
}

.venue-link.followed {
  background: linear-gradient(to right, #6146b7, #33aaeb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>