<template>
  <div class="v-directory-page-component directory-page">
    <header_section></header_section>
    <main class="top-inner-pages filters">
      <section class="my-venue">
        <div class="directory-vue-content dicrectory-page-content2">
          <directory_content></directory_content>
        </div>
      </section>
    </main>
    <footer_section></footer_section>
  </div>
</template>
<script>

  import { bus } from './app'
  import Directory from './../components/browse'
  import FooterSection from './../views/partials/footer'  
  import HeaderSection from './../views/partials/header' 
  import helpers from './../mixin/helpers';

  export default {
    data() {
      return {
          loadingContent: false,
      }
    },
    beforeCreate() {
      this.loadingContent = true;
    },
    created(){
      this.loadingContent = false;
    },
    mixins: [helpers],
    components:{
      directory_content: Directory,
      header_section: HeaderSection,
      footer_section: FooterSection,
    }
  }
</script>