<template>
    <div class="vgps-directory-advance-filters">
      <span v-on:click="slidePrev" class="vgps-scroll-left"></span>
      <span v-on:click="slideNext" class="vgps-scroll-right"></span>
      <ul class="vgps-horizontal-scroll-wrapper">
        <li class="filter-element search_field_wrapper">
          <div class="form-group">
            <div class="search_field">
              <input
                  type="text"
                  @keydown.enter="advanceFilter()"
                  @keyup="searchTimeOut($event.target.value,$event.which)"
                  v-model="filters.search"
                  class="form-control"
                  placeholder="Search Business Name"
              />
            </div>
          </div>
        </li>
        
        <li class="filter-element" :class="{ active : filters.business_type  }">
          <div class="form-group" :class="applySelectedClass(filters.business_type)">
            <Select2
                v-model="filters.business_type"
                placeholder="Business Type"
                :options="businessTypes"
                :settings="{placeholder:'Business Type',allowClear: true,disabled:select2Dsiabled }"
                @select="advanceFilter()"
            />

            
          </div>
        </li>
        <li class="filter-element" :class="{ active : filters.venue_type  }">
          <div class="form-group" :class="applySelectedClass(filters.venue_type)">
            <Select2
                v-model="filters.venue_type"
                :options="businessSubTypes"
                :settings="{placeholder: 'Sub-Type',allowClear: true,language: {noResults: () => 'Select Business Type'}}"
                @select="advanceFilter()"
            />
          </div>
        </li>
      </ul>
    </div>
  </template>
  <script>
  import { bus } from "../../app";
  import Select2 from "v-select2-component";
  import helpers from "../../mixin/helpers";
  
  
  export default {
    components: {
      Select2
    },
    mixins: [helpers],
    data() {
      return {
        venue_type: [],
        myValue: "",
        event_genre: [],
        isDateFormat: false,
        select2Dsiabled:false,
        isFilterSelected: false,
        isActiveCity:false,
        business_type: "",
        cuisine: '',
        amenity: '',
        subBusinessTypes: []
      };
    },
    mounted() {
      this.setDefaultCityState();
      // setTimeout(() => {
        //  this.advanceFilter();
      // }, 500);
      
    },
    computed: {
      defaultCity() {
        return this.$store.getters.defaultCity;
      },
      defaultCityState() {
        return this.$store.getters.defaultCityState;
      },
      businessTypes() {
        return this.$store.getters.businessTypes;
      },
      businessTypeOption() {
        return this.$store.getters.businessTypeOption;
      },
      businessSubTypes() {
        return this.$store.getters.businessSubTypes;
      },
      eventGenre() {
        return this.$store.getters.eventGenre;
      },
      alphabetArray() {
        return this.$store.getters.alphabetArray;
      },
      statesData() {
        return this.$store.getters.statesData;
      },
      citiesData() {
        return this.$store.getters.getstateCities;
      },
      FoodCuisinesData() {
        return this.$store.getters.FoodData;
      },
      getAdminFilteredAmenities() {
        return this.$store.getters.amenitiesFilterOptions;
      }
    },
    watch: {
      "filters.state_id": function(newVal, oldVal) {
        if(newVal == null) {
          this.filters.city = '';
          this.$store.state.stateCitiesData = [];
        }
        this.isActiveCity = this.filters.city? true:false;
      },
      "filters.is_global": function(type) {
  
        var vm = this;
        if(type == 'global'){
          
          vm.filters.city = '';
          vm.filters.state_id = '';
          vm.filters.start_with = '';
          vm.filters.cuisine = '';
          vm.cuisine = '';
          vm.filters.event_genre = '';
          vm.filters.amenities = '';
          vm.amenity = '';
          vm.filters.business_type = 'People';
          vm.filters.venue_type = '';
          vm.$store.dispatch("getBusinessSubTypesAction", "People");
          vm.select2Dsiabled = true;
          
        }else {
  
          vm.filters.start_with = '';
          vm.filters.cuisine = '';
          vm.cuisine = '';
          vm.amenity = '';
          vm.filters.event_genre = '';
          vm.filters.amenities = '';
          vm.filters.business_type = '';
          vm.filters.venue_type = '';
  
          vm.setDefaultCityState();
          vm.isActiveCity = this.filters.city? true:false;
          vm.$store.dispatch("getBusinessSubTypesAction", "");
          vm.select2Dsiabled = false;
                
        }
  
        vm.advanceFilter();
  
      },
      "filters.business_type": function(newVal, oldVal) {
        var vm = this;
        vm.$store.dispatch("getBusinessSubTypesAction", newVal);
      }
    },
    created: function() {
      var vm = this;
      bus.$on("updateLocation", city => {
        this.setDefaultCityState();
      });
      bus.$on("stateStoreUpdated", () => {
        vm.getStateCities();
      });
      // setTimeout(function() {
      //   vm.setDefaultCityState();
      // }, 500);
    },
    props: ["filters"],
    methods: {
      setDefaultCityState() {
        var defaultState = this.$session.get("default_state");
        var stateData = jQuery.grep(this.$store.state.defaultPageData.lookup_cities, function(obj) {
          return obj.geoname_code == defaultState;
        });
        if (stateData.length > 0) {
          this.filters.state_id = stateData[0].id;
          this.filters.city = this.$session.get("default_location");
          // bus.$emit("advanceFilterEvent");
          
        }
      },
      searchTimeOut(value, keyBtn) {
        if (
            keyBtn === 13 ||
            keyBtn === 37 ||
            keyBtn === 38 ||
            keyBtn === 39 ||
            keyBtn === 40
        ) {
          return false;
        }
  
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        // this.timer = setTimeout(() => {
        this.advanceFilter();
        // }, 800);
      },
      mySelectEvent({ id, text }) {
        this.filters.venue_type = text;
        this.isFilterSelected = true;
        this.myValue = text;
        bus.$emit("advanceFilter");
      },
      advanceFilter: function(selectedCategory, selectedType) {
        
        console.log('dsdfsdfsd');
        this.business_type = this.filters.business_type;
        this.filters.amenities = [];
        if(this.amenity){
          this.filters.amenities.push(this.amenity)
        }
        if(this.cuisine){
          this.filters.amenities.push(this.cuisine)
        }
  
        this.filters.offset = 0;
        if (selectedCategory == "NightLife") {
          this.filters.venue = "";
          this.filters.people = "";
          this.filters.community = "";
        } else if (selectedCategory == "Venue") {
          this.filters.night_life = "";
          this.filters.people = "";
          this.filters.community = "";
        } else if (selectedCategory == "People") {
          this.filters.night_life = "";
          this.filters.venue = "";
          this.filters.community = "";
        } else if (selectedCategory == "Community") {
          this.filters.night_life = "";
          this.filters.people = "";
          this.filters.venue = "";
        }
  
        if (selectedType) {
          this.filters.type = selectedType;
        }
        if (selectedCategory) {
          this.filters.category = selectedCategory;
        }
  
        this.isActiveCity = this.filters.city? true:false;
        
        bus.$emit("advanceFilterEvent");
      },
      selectedGenre() {
        this.isFilterSelected = true;
        this.event_genre = this.filters.event_genre;
        bus.$emit("advanceFilterEvent");
      },
      clearFilter: function() {
        var vm = this;
        this.isFilterSelected = false;
        $(".js-select2").val("");
        this.filters.start_with = "";
        this.filters.night_life = "";
        this.filters.venue = "";
        this.filters.people = "";
        this.filters.community = "";
        this.filters.venue_type = "";
        this.filters.event_genre = "";
        this.filters.is_global = "all";
        this.filters.state_id = "";
        this.filters.city = "";
        this.filters.search = "";
        this.filters.business_type = "";
        this.filters.category = "";
        bus.$emit("clearAdvanceFilter");
        setTimeout(function() {
          $(".select2-dropdown-type")
              .find("select")
              .prepend('<option value="">Venue Type</option>');
        }, 200);
      },
      applySelectedClass: function(value) {
        return value != "" ? "active-selected-item" : "";
      },
      slidePrev: function() {
        jQuery(this)
            .parent()
            .animate(
                {
                  scrollLeft: "-=10px"
                },
                "fast"
            );
      },
      slideNext: function() {
        jQuery(this)
            .parent()
            .animate(
                {
                  scrollLeft: "+=10px"
                },
                "fast"
            );
      },
      getStateCities: function(type) {
        var vm = this;
    
        if(type == 'stateChange'){
          bus.$emit("stateChangeEvent");
        }
        var stateData = jQuery.grep(vm.$store.state.defaultPageData.lookup_cities, function(
            obj
        ) {
          return obj.id == vm.filters.state_id;
        });
        if (typeof stateData != "undefined" && stateData.length > 0) {
          // vm.filters.city = '';
          vm.$store.state.stateCitiesData = [];
          vm.$store.state.stateCitiesData = stateData[0].city_set.map(el => {
            return { id: el.name, text: el.name };
          });
        }
        // vm.advanceFilter();
      }
    },
    filters: {
      assetPath: function(img) {
        var path = window.axios.defaults.baseURL + "/images/" + img;
        return path;
      }
    }
  };
  
  function showDiv() {
    div = document.getElementById("ClearFilters");
    div.style.display = "block";
  }
  </script>
  