<template>
  <div>
    <Select2
      name="business_type"
      id="business_type"
      v-model="business_type"
      placeholder="Select State"
      :options="businessTypes"
      @change="getStateCities"
      :settings="{placeholder:'Select One',class:'select-form-submit floating-label-field form-control floating-label-field--s3 form-control',selectOnClose: false,allowClear: true,closeOnSelect: true}"
    />
  </div>
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";
import Select2 from "v-select2-component";

export default {
  props: ["category"],
  mixins: [helpers],
  watch: {
      business_type: function (newVal, oldVal) {
          var vm = this;
          vm.$store.dispatch('getBusinessSubTypesAction',newVal);
      }
  },
  updated() {
    if (this.business_type) {
      // this.getStateCities();
      this.$store.dispatch('getBusinessSubTypesAction',this.business_type);
      
    }
  },
  created() {
    this.business_type = this.category;

  },
  computed: {
    businessTypes(){
        return this.$store.getters.businessTypes;
    },
    
  },
  components: {
    Select2
  },
  data: function() {
    return {
      business_type: ""
    };
  },
  methods: {
    getStateCities: function() {
      var vm = this;
      var stateData = jQuery.grep(this.$store.state.lookupData.states, function(
        obj
      ) {
        return obj.id == vm.state_id;
      });
      if (typeof stateData != "indefined" && stateData.length > 0) {
        this.$store.state.stateCitiesData = [];
        this.$store.state.stateCitiesData = stateData[0].city_set.map(el => {
          return { id: el.name, text: el.name };
        });
      }
    }
  }
};
</script>
