<template>
    <!-- Card -->
    <div class="card">

        <template>
            <stripe-checkout
            ref="checkoutRef"
            mode="subscription"
            :pk="publishableKey"
            :clientReferenceId="customerId"
            :customerEmail="customerEmail"
            :line-items="lineItems"
            :success-url="successURL"
            :cancel-url="cancelURL"
            @loading="v => loading = v"
            />
        </template>

        <div class="card-header bg-white py-3">
            <h5 class="mb-0">{{ updatedPlanTitle }}</h5>
        </div>
        <div class="card-body">
            <ul class="packages-type">
            <template v-for="(item,index) in plan.packages">
                <li class="item-type " :class="{'selected-plan': selected_plan === item.plan_details.id}">
                <div class="form-group vgps-custom-checkbox-wrapper">
                    <label class="fancyRadioWrapper">
                    <input type="radio" v-model="selected_plan" checked="checked" :value="item.plan_details.id" :id="item.plan_details.id" />
                    <span class="checkmark"></span>
                    </label>
                </div>
                <h3>USD</h3>
                    <h2>{{item.plan_details.price}}</h2>
                    <div v-if="item.name == 'VGPS Standard Yearly'">
                      <p class="save-988">Save $1.88</p>
                      <p>{{ item.name.replace(plan.title,"") | formatData}}</p>
                    </div>
                    
                    <div v-else-if="item.name == 'VIP Yearly'">
                      <p class="save-988">Save $9.88</p>
                      <p>{{ item.name.replace(plan.title,"") | formatData2}}</p>
                    </div>

                    <div v-else>
                    <p>{{ item.name.replace(plan.title,"") }}</p>

                    </div>
                </li>
            </template>
            </ul>

            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <strong>Contacts</strong>
                <p>Phonebook</p>
                <span class="subscript-icons"><img class="img-responsive" :src="'icons/tick-icon.svg' | assetPath" alt=""/></span>
              </li>
              <li class="list-group-item">
                <strong>Hashtags</strong>
                <p>Customize Events</p>
                <span class="subscript-icons"><img class="img-responsive" :src="'icons/tick-icon.svg' | assetPath" alt=""/></span>
              </li>
              <li class="list-group-item">
                <strong>MAP</strong>
                <p>Directories</p>
                <span class="subscript-icons"><img class="img-responsive" :src="'icons/tick-icon.svg' | assetPath" alt=""/></span>
              </li>
              <li class="list-group-item">
                <strong>Accounts</strong>
                <p>{{ checkPlanLimit() }}</p>
                <span class="subscript-icons"><img class="img-responsive" :src="'icons/tick-icon.svg' | assetPath" alt=""/></span>
              </li>
              <li class="list-group-item">
                <strong>Message</strong>
                <p>Notifications</p>
                <template v-if="checkPromoteAndMessage()">
                  <span class="subscript-icons"><img class="img-responsive" :src="'icons/tick-icon.svg' | assetPath" alt=""/></span>
                </template>
                <template v-else>
                  <span class="subscript-icons"><img class="img-responsive" :src="'icons/close-icon.svg' | assetPath" alt=""/></span>
                </template>
              </li>
              <li class="list-group-item">
                <strong>Auto Promote</strong>
                <p>Prefrences</p>
                <template v-if="checkPromoteAndMessage()">
                  <span class="subscript-icons"><img class="img-responsive" :src="'icons/tick-icon.svg' | assetPath" alt=""/></span>
                </template>
                <template v-else>
                  <span class="subscript-icons"><img class="img-responsive" :src="'icons/close-icon.svg' | assetPath" alt=""/></span>
                </template>
              </li>
            </ul>

        </div>
        <div class="card-footer bg-white py-3">
            <button type="button" @click="submit()" class="btn btn-success btn-lg subscription-btn">
                <span class="login-show-spinner" :class="{ active: processing == true }">
                    <i class="fa fa-spinner fa-spin"></i>
                </span>
                Upgrade Subscription
            </button>
        </div>
    </div>
    <!-- Card -->
  </template>
  
  <script>
  
  import { StripeCheckout } from '@vue-stripe/vue-stripe';
  import helpers from "./../../../mixin/helpers";
  
  export default {
    props: ["plan"],
    mixins: [helpers],
    data() {
      return {
        loadingContent: false,
        loading:false,
        processing:false,
        selected_plan:"free_vip_trial",
        customerEmail:'',
        customerId:'',
        //selected_plan:"price_1LwuhaDcPFLTCaUzuVzkdvOX",
        // publishableKey:"pk_test_DdH7PjiE2RWO3zuKEmXODUGq00Dp2zdIQg",
        publishableKey:"pk_live_XG0wBmooO0BieFGwmTuLLXIg006Y5yh4g8",
        successURL: window.axios.defaults.baseURL+"/#/subscription/done",
        cancelURL:window.axios.defaults.baseURL+"/#/subscription",
        lineItems: [
          {
            price: "price_1NPWyeDcPFLTCaUzFX5koDOH", // The id of the recurring price you created in your Stripe dashboard
            // price_1NPWyeDcPFLTCaUzFX5koDOH          
            quantity: 1,
          }
        ],
        subscriptionsList: [],
        elementsOptions: {
          appearance: {}, // appearance options
          clientSecret:""
        },
        confirmParams: {
          return_url: window.axios.defaults.baseURL+'/#/subscription/done', // success url
        },
      };
    },
    watch: {
      selected_plan: function (newVal, oldVal) {
          console.log(' selected_plan ',newVal)
          this.$set(this.lineItems[0], 'price', newVal);
      }
    },
    computed: {
  updatedPlanTitle() {
    return this.plan.title === 'VIP' ? 'Premium' : this.plan.title;
  }
},
    created: function(){
      let vm = this;
      const user = this.$session.get("user_data");
      vm.customerId = user.profile.stripe_customer_id
      vm.customerEmail = user.profile.email


      console.log("Stripe customerId:", vm.customerId);


    },
    components: {
      StripeCheckout
    },
    methods: {
      pay () {
        this.$refs.paymentRef.submit();
      },
      async submit () {
        
        if(this.selected_plan ==''){
            sweetalert2.fire(
            "Plan Option is Required!",
            'Please select the plan Month/Year',
            "info"
          );
          return false;
        }

        if (this.processing === true) {
            return false;
        }
        this.processing = true;

      
        try {

          const availablePackages = [
{
  "title": "Trial",
  "packages": [
    {
      "id": "stripe_plan_2023",
      "name": "Year 2023",
      "plan_details": {
        "id": "trial",
        "price": "0"
      },
      "total_plan_counts": 50
    },
    {
      "id": "free_vip_trial",
      "name": "14 Day Trial",
      "plan_details": {
        "id": "trial",
        "price": "0"
      },
      "total_plan_counts": 250000
    }
  ]
},
{
  "title": "Standard",
  "packages": [
    {
      "id": "prod_OBuoQpsL53xt2R_updated",
      "name": "VGPS Standard Yearly",
      "plan_details": {
        "id": "price_1NPWyeDcPFLTCaUzFX5koDOH",
        "price": "10",
        "plan_id": "prod_OBuoQpsL53xt2R"
      },
      "total_plan_counts": 250000
    },
    {
      "id": "prod_OBugDACwuh0kR3_updated",
      "name": "VGPS Standard Monthly",
      "plan_details": {
        "id": "price_1NPWqlDcPFLTCaUzsvk4Bnk0",
        "price": "0.99",
        "plan_id": "prod_OBugDACwuh0kR3"
      },
      "total_plan_counts": 250000
    }
  ]
},
{
  "title": "VIP",
  "packages": [
    {
      "id": "prod_OBurPRj5z2fJA5_updated",
      "name": "VIP Yearly",
      "plan_details": {
        "id": "price_1NPX1WDcPFLTCaUzeR4StsDZ",
        "price": "50",
        "plan_id": "prod_OBurPRj5z2fJA5"
      },
      "total_plan_counts": 250000
    },
    {
      "id": "prod_OBupsEcCHMdICV_updated",
      "name": "VIP Monthly",
      "plan_details": {
        "id": "price_1NPX0ADcPFLTCaUzHplcn5Pq",
        "price": "4.99",
        "plan_id": "prod_OBupsEcCHMdICV"
      },
      "total_plan_counts": 250000
    }
  ]
}
];


function getSelectedPlanId(availablePackages, selectedPlanId) {
for (const packageCategory of availablePackages) {
for (const packageItem of packageCategory.packages) {
if (packageItem.plan_details.id === selectedPlanId) {
  return packageItem.id; // Return the matching package item's id
}
}
}
return null; // Return null if not found
}

const selectedPlanId = getSelectedPlanId(availablePackages, this.selected_plan);

console.log('idsss',selectedPlanId);

          const data = new FormData();
          data.append("stripe_user_subscription_id", "paid");
          data.append("stripe_plan", selectedPlanId);
          data.append("stripe_customer_id", this.customerId);


          const response = await axios.post('data/free-subscription', data);

          console.log("resp",response)

          await this.$refs.checkoutRef.redirectToCheckout();

          
          this.processing = false  
        } catch (error) {
          sweetalert2.fire(
            "Something went wrong!",
            error.message,
            "error"
          );
          console.log('======= Exception ========',error);
          this.processing = false
        }
      },
      checkPlanLimit: function () {
        const includePlan = [
          'Trial'
        ];
        return (includePlan.includes(this.plan.title) && this.selected_plan == 'stripe_plan_2023')?'50':"Unlimited"
      },
      checkPromoteAndMessage: function () {
        const includePlan = [
          'Standard',
          "VIP"
        ];
        const includePlanSelection = [
          "price_1NPWyeDcPFLTCaUzFX5koDOH", // VGPS Standard Yearly
          "price_1NPWqlDcPFLTCaUzsvk4Bnk0", // VGPS Standard Monthly
          "price_1NPX1WDcPFLTCaUzeR4StsDZ", // VIP Yearly
          "price_1NPX0ADcPFLTCaUzHplcn5Pq", // VIP Monthly
          "free_vip_trial", // Include original free trial if necessary
          "price_1MeDL7DcPFLTCaUzozDb2GGu", // Original price included before
          "price_1MeDKYDcPFLTCaUz7HFDDKGc"  // Original price included before
        ];

          // Return false if the plan is "Standard"
        if (this.plan.title === 'Standard') {
          return false;
        }
        
        //  return (includePlanSelection.includes(this.selected_plan))?true:false
        return (includePlan.includes(this.plan.title) && includePlanSelection.includes(this.selected_plan))?true:false
      }
    },
    filters: {
      formatData: function (str) {
        return str.replace("VGPS Yearly",'Yearly')
      },     
      formatData2: function (str) {
        return str.replace("VIP Yearly",'Yearly')
      }
    }
  };
  </script>
  <style scoped>
/* Subscription */
.pricinng-plan .tab-pane .plan-card .packages-type li {
  position: relative;
  background: #FAFAFB;
  border: 2px solid #EDEDEE;
  border-radius: 20px;
  width: 50%;
  padding: 36px 10px 10px 10px;
  text-align: center;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.pricinng-plan .tab-pane .plan-card .packages-type li.selected-plan {
  background: #FAFAFB; /* Inner background */
  border: none; /* Remove default border */
  position: relative;
}

.pricinng-plan .tab-pane .plan-card .packages-type li.selected-plan::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px; /* Same border-radius as the main element */
  padding: 2px; /* The thickness of the border */
  background: linear-gradient(80.46deg, #00AEF0 4%, #6740B4 90.99%);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.save-988 {
  background: linear-gradient(80.46deg, #00AEF0 4%, #6740B4 90.99%);
  -webkit-background-clip: text; /* For WebKit-based browsers */
  -webkit-text-fill-color: transparent; /* For WebKit-based browsers */
  padding: 5px; /* Optional: Add padding for better visual appearance */
  display: inline; /* Ensure the gradient is applied inline */
}

</style>