<template>
  <div>
    <Select2
      name="city"
      id="city"
      v-model="city_id"
      placeholder="Select City"
      :options="citiesData"
      :settings="{placeholder:'Select One',selectOnClose: false,allowClear: true,closeOnSelect: true}"
    />
  </div>
</template>
<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";
import Select2 from "v-select2-component";

export default {
  props: ["city"],
  mixins: [helpers],
  watch: {},
  created() {
    this.city_id = this.city ? this.city : "";
  },
  computed: {
    citiesData() {
      return this.$store.getters.getstateCities;
    }
  },
  components: {
    Select2
  },
  data: function() {
    return {
      city_id: ""
    };
  },
  methods: {
    
  }
};
</script>
