<template>
    <div>
<!-- Main Start -->
    <header_section></header_section>
    <main class="top-inner-pages vision-page">
        <section class="headerSectionWrapper">
            <div class="headerBGImage">
          <img :src="'banners/vgps-min.png' | assetPath" alt="header Image" />
            </div>
        </section>
        <!-- Dashboard Start -->
        <section class="dashboard vgps_contact_support_wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12 vgps_page_header_wrapper">
                <h2>Advertise With Us on VenueGPS</h2>
            </div>
            <div class="col-md-12 vgps_privacy_content_wrapper">
                <p>VenueGPS connects your business with millions of users actively searching for the best venues, events, dining, entertainment, and more. Whether you’re a small local business or a global brand, our platform offers flexible advertising solutions to help you engage your target audience and drive growth. Explore our advertising guidelines, format specifications, pricing, and performance tracking tools to create a successful ad campaign today.</p>

                <h3>1. Ad Guidelines & Content Standards</h3>
                <h4>Overview:</h4>
                <p>This section outlines the essential rules for creating advertisements on VenueGPS. To maintain a positive user experience, all ads must adhere to these guidelines and provide relevant, high-quality content.</p>
                <h4>Content:</h4>
                <ul>
                    <li><strong>Relevance:</strong> Ads should align with the industries currently supported on VenueGPS, such as dining, entertainment, wellness, events, and more. As we expand into additional industries (e.g., retail, wellness, hospitality), advertisers must ensure their ads are appropriate for the audience and platform.</li>
                    <li><strong>Quality:</strong> All ads should be visually appealing, professionally designed, and high-quality. Blurry, pixelated images or poorly written copy will not be accepted.</li>
                    <li><strong>Prohibited Content:</strong> Ads must not contain offensive, discriminatory, or misleading material. Ads promoting illegal products or activities are strictly prohibited.</li>
                    <li><strong>Call-to-Action (CTA):</strong> Ads should include a clear, action-oriented CTA (e.g., “Book Now,” “Shop Today,” “Discover More”) to prompt user engagement.</li>
                </ul>

                <h3>2. Ad Format & Size Specifications</h3>
                <h4>Overview:</h4>
                <p>To ensure your ads display perfectly across devices, use the following format and size specifications. This guarantees an optimized user experience for desktop, tablet, and mobile viewers.</p>
                <table>
                    <thead>
                        <tr>
                            <th>Section</th>
                            <th>Dimensions (Pixels)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Ads Tile Flyer</td>
                            <td>296 x 296</td>
                        </tr>
                        <tr>
                            <td>Ads Home Page Banner</td>
                            <td>1440 x 480</td>
                        </tr>
                        <tr>
                            <td>Event Flyer Image</td>
                            <td>512 x 512</td>
                        </tr>
                        <tr>
                            <td>Ads Event Page Banner</td>
                            <td>900 x 160</td>
                        </tr>
                    </tbody>
                </table>
                <p><strong>File Formats:</strong> JPEG, PNG, GIF.</p>
                <p><strong>Max File Size:</strong> 5MB to ensure fast loading times.</p>
                <p><strong>Mobile Optimization:</strong> Ads must be optimized for all screen sizes and devices.</p>
                <h4>Tips for Optimization:</h4>
                <ul>
                    <li>Compress images without sacrificing quality to ensure fast load times.</li>
                    <li>Design with mobile-first in mind to guarantee that your ads look great on every device.</li>
                </ul>

                <h3>3. Branding & Logo Use</h3>
                <h4>Overview:</h4>
                <p>When advertising on VenueGPS, you have the option to incorporate our logo into your marketing materials to enhance credibility and trust. Proper branding practices must be followed to maintain consistency and visual integrity.</p>
                <h4>Content:</h4>
                <ul>
                    <li><strong>Logo Usage:</strong> Ensure that the VenueGPS logo is always used in its original form. Do not distort, resize, or alter the logo.</li>
                    <li><strong>Clear Space:</strong> Maintain sufficient clear space around the logo for legibility.</li>
                    <li><strong>Color and Placement:</strong> Use the logo in its original colors, and avoid placing it over complex or cluttered backgrounds.</li>
                </ul>
                <p><strong>Download Our Logo:</strong> Available in PNG, JPEG, and SVG formats.</p>

                <h3>4. Ad Pricing & Packages</h3>
                <h4>Overview:</h4>
                <p>Explore the pricing options for advertising on VenueGPS. We offer flexible packages to fit your budget and goals, whether you’re looking for national exposure or targeting specific regions or cities.</p>
                <h4>Pricing Overview:</h4>
                <ul>
                    <li><strong>World (All Countries):</strong> To Be Announced</li>
                    <li><strong>National (Per Country):</strong> To Be Announced</li>
                    <li><strong>Regional (By Time Zone):</strong> To Be Announced</li>
                    <li><strong>City/Metropolitan:</strong> To Be Announced</li>
                    <li><strong>NYC, LA, ATL, UK, UAE:</strong> To Be Announced</li>
                    <li><strong>Town/Local:</strong> To Be Announced</li>
                </ul>
                <h4>Tile Ads:</h4>
                <ul>
                    <li><strong>Nationwide (All 50 States):</strong> To Be Announced</li>
                    <li><strong>Regional (By Time Zone):</strong> To Be Announced</li>
                    <li><strong>City/Metropolitan:</strong> To Be Announced</li>
                    <li><strong>Town/Local:</strong> To Be Announced</li>
                </ul>
                <h4>Duration Ad Options:</h4>
                <ul>
                    <li>Annually</li>
                    <li>Quarterly</li>
                    <li>Monthly</li>
                    <li>Daily</li>
                </ul>
                <p>We offer custom ad packages for businesses that need tailored solutions. Contact us for more details.</p>

                <h3>5. Kiosk Advertising Options</h3>
                <h4>Overview:</h4>
                <p>Advertise your brand in high-traffic areas through our Indoor and Outdoor Kiosk options. These kiosks provide prime real estate for promoting your business to event-goers, travelers, and locals.</p>
                <table>
                    <thead>
                        <tr>
                            <th>Kiosk Type</th>
                            <th>Buy</th>
                            <th>Lease</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>7ft Outdoor Kiosk (Single Sided)</td>
                            <td>$18,000</td>
                            <td>$2,500 - $5,000</td>
                        </tr>
                        <tr>
                            <td>7ft Outdoor Kiosk (Double Sided)</td>
                            <td>$22,000</td>
                            <td>$2,500 - $5,000</td>
                        </tr>
                        <tr>
                            <td>Indoor Kiosk (Single Sided)</td>
                            <td>$8,000</td>
                            <td>$2,500 - $5,000</td>
                        </tr>
                        <tr>
                            <td>Indoor Kiosk (Double Sided)</td>
                            <td>$10,000</td>
                            <td>$2,500 - $5,000</td>
                        </tr>
                        <tr>
                            <td>Indoor Kiosk (Podium Style)</td>
                            <td>$6,500</td>
                            <td>$2,500 - $5,000</td>
                        </tr>
                        <tr>
                            <td>Wall Kiosk</td>
                            <td>$4,500</td>
                            <td>N/A</td>
                        </tr>
                    </tbody>
                </table>
                <p><strong>Lease Options:</strong> All kiosks are available for purchase or lease. We provide full maintenance, installation, and 24/7 support for all kiosk placements.</p>

                <h3>6. Ad Performance & Analytics</h3>
                <h4>Overview:</h4>
                <p>Track the performance of your ads with VenueGPS’s real-time analytics. Monitor key metrics and adjust your campaigns based on real-time data to maximize your results.</p>
                <h4>Key Metrics:</h4>
                <ul>
                    <li><strong>Impressions:</strong> Track how many times your ad has been seen by users.</li>
                    <li><strong>Click-Through Rate (CTR):</strong> Measure the percentage of users who clicked on your ad.</li>
                    <li><strong>Engagement Metrics:</strong> Analyze how users are interacting with your ad (clicks, likes, shares).</li>
                </ul>
                <h4>Optimization Tips:</h4>
                <ul>
                    <li>Experiment with different ad creatives to determine what resonates most with your audience.</li>
                    <li>Refine your targeting options based on high-performing regions or demographics.</li>
                    <li>Adjust your ad budget based on real-time performance data to maximize ROI.</li>
                </ul>

                <h3>7. Targeting & Audience Segmentation</h3>

                <h4>Overview:</h4>
                <p>Target the right audience with VenueGPS’s advanced audience segmentation tools. Choose who sees your ads based on geography, demographics, and user preferences to ensure maximum relevance.</p>

                <h4>Targeting Options:</h4>
                <ul>
                    <li><strong>Geographic Targeting:</strong> Choose to display your ads nationally, regionally, or locally.</li>
                    <li><strong>Demographic Targeting:</strong> Segment your audience by age, gender, location, and more to reach the most relevant users.</li>
                    <li><strong>Behavioral Targeting:</strong> Target users based on their interests and behaviors (e.g., users frequently searching for dining, wellness, or event options).</li>
                </ul>

                <h4>Example:</h4>
                <p>If you’re a restaurant, target users who regularly search for dining options in New York City. If you’re promoting a wellness service, reach users who frequently attend fitness or wellness events in their area.</p>

                <h3>Get Started with Advertising on VenueGPS Today!</h3>
                <p>Ready to showcase your business to millions of users on VenueGPS? Contact us now to take advantage of our exclusive first-time advertiser discount or request a free consultation to find the perfect advertising package for your business.</p>
                <p>Email us at <a href="mailto:advertise@venuegps.com">advertise@venuegps.com</a> or call <strong>(757) 656-5799</strong> to get started today!</p>

                <h3>Final Rating: 10/10</h3>
                <h4>Improvements Incorporated:</h4>
                <ul>
                    <li><strong>Table Format for Kiosk Options:</strong> Simplifies the information and reduces repetition.</li>
                    <li><strong>Mockups and Ad Examples:</strong> While not visually included here, the suggestion to include mockups or visual examples of ad placements (e.g., homepage banners, tile ads) could greatly enhance the section.</li>
                    <li><strong>Enhanced Targeting Section:</strong> Added more specific examples and expanded on how businesses can use behavioral and geographic targeting to optimize their campaigns.</li>
                    <li><strong>Stronger Call-to-Action:</strong> The CTA now includes a sense of urgency with an exclusive discount and free consultation offer to encourage immediate action.</li>
                </ul>

                <!-- <h3>Contact Us</h3>
                <p>If you’re ready to get started or have questions about advertising on VenueGPS, reach out to our support team. We’re here to help you create a successful advertising campaign.</p>
                <p><strong>Email:</strong> <a href="mailto:info@venuegps.com">info@venuegps.com</a></p>
                <p><strong>Phone:</strong> (123) 456-7890</p>
                <p>Follow us on social media to stay updated on our latest offerings and tips for effective advertising:</p>
                <ul>
                    <li><a href="https://facebook.com/venuegps">Facebook</a></li>
                    <li><a href="https://twitter.com/venuegps">Twitter</a></li>
                    <li><a href="https://instagram.com/venuegps">Instagram</a></li>
                </ul> -->
            </div>
        </div>

               
    </div>
</section>

        <!-- Dashboard End -->

    </main>
    <!-- Main End -->
    <footer_section></footer_section>
    </div>
</template>

<script>

  import { bus } from './app'
  import HeaderSection from './../views/partials/header' 
  import FooterSection from './../views/partials/footer' 
  import helpers from '../mixin/helpers';

  export default {
    data() {
      return {
          loadingContent: false,
      }
    },
    mixins: [helpers],
    components:{
      'header_section': HeaderSection,
      'footer_section': FooterSection,
    }
  }
</script>

<style scoped>
    table {
        border-collapse: separate;
        border-spacing: 10px; /* Adjust the spacing as needed */
    }
    td {
        padding: 10px; /* Adjust the padding as needed */
    }
</style>