<template>

  <div class="event-detail-card d-none">
    <div
      class="col-lg-4 col-md-6 col-sm-6 col-xs-12"
      v-for="(event, key) in events"
      :key="key"
    >
      <div class="content" :class="event.activation_status_display">
        <div
          class="pic"
          :style="'background: url(' + event.thumbnail_urls.thumb_296 + ');'"
        >


        <div style="float: right;top:52px; position: relative;right: 4px">
                    <event_follow
                      v-bind:events="events"
                      v-bind:event="event"
                      v-bind:index="key"
                    ></event_follow>
                  </div>
                 
          <span
            title="Private Location"
            v-if="event.type && event.type == 'PrivateLocation'"
            class="private-label privatLabel"
          >
            Private</span
          >
          <!-- <a class="flyer-venue-name" :href="'event-detail/'+event.id | url"></a> -->
          <router-link
            v-if="isAuth()"
            class="flyer-venue-name"
            :to="{ name: 'event', params: { id: event.id } }"
          ></router-link>

          <span class="time">{{ event.category }}</span>
          <span class="eye" style="margin-left: 206px;">
                        <img :src="'seen.svg' | assetPath" :alt="event.title" /><br>
                        {{ event.total_views }}
                      </span>

          <like_event
            v-bind:events="events"
            v-bind:event="event"
            v-bind:index="key"
          ></like_event>
        </div>
        <!--<p class="category">{{ event.category }}</p>-->

        <div
          v-if="event.activation_status_display"
          class="event-status-flag activation-status-flag"
        >
          <a
            href="#"
            :class="[
              event.activation_status_id
                ? 'status-bg-' + event.activation_status_id
                : '',
            ]"
          >
            {{ event.activation_status_display }}
            <img :src="'info.svg' | assetPath" />
          </a>
        </div>
        <!--like Event Component -->

        <a
          v-if="isAdministratorAccount()"
          :href="adminEditOptionURL('event', event.id)"
          target="_blank"
          class="admin-edit-option"
        >
          <i class="fa fa-edit"></i>
        </a>

        <div class="bottom-decription">
          <h6>
            <!-- <a :href="'event-detail/'+event.id | url">{{ limitStr(event.title,55) }}</a> -->
            <router-link
              v-if="isAuth()"
              class="flyer-venue-name"
              :to="{ name: 'event', params: { id: event.id } }"
              >{{ limitStr(event.title, 55) }}</router-link
            >
          </h6>

          <p title="Start & End Dates" class="dates">
            {{ event.formatted_date_time.start_date }} -
            {{ event.formatted_date_time.end_date }}
          </p>
          <p
            v-if="event.formatted_date_time.start_time"
            class="event-time"
            title="Start Time"
          >
            Start Time: {{ event.formatted_date_time.start_time }}
          </p>
          <p>
            <router-link
              v-if="isAuth()"
              class="flyer-venue-name"
              :to="{ name: 'venue', params: { id: event.business_details.id } }"
              >{{ limitStr(event.business_details.title, 26) }}</router-link
            >
          </p>

          <div class="vgps-event-hashtags-wrapper">
            <ul>
              <li
                class="hash-item-search"
                :data-category="event.category"
                data-type="category"
              >
                <!-- <a
                  :title="event.category"
                  @click="searchHashTag('#'+event.category)"
                  href="javascript:void();"
                >{{ event.category | checkNullHash}}</a> -->

                
              </li>

            </ul>
          </div>

          <!-- <div class="dropup">
            <button
              class="btn btn-default vue-social-share"
              id="social-network-share"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-share-alt"></i> Share
            </button>
            <div
              class="dropdown-menu vue-social-share-items"
              aria-labelledby="dropdownMenu2"
            >
              <li class="dropdown-item">
                <ShareNetwork
                  network="facebook"
                  :url="socialShareLink('event-detail/' + event.id)"
                  :title="event.title"
                >
                  <i class="fab fah fa-lg fa-facebook"></i>
                  <span>Share on Facebook</span>
                </ShareNetwork>
              </li>
              <li class="dropdown-item">
                <ShareNetwork
                  network="twitter"
                  :url="socialShareLink('event-detail/' + event.id)"
                  :title="event.title"
                >
                  <i class="fab fah fa-lg fa-twitter"></i>
                  <span>Share on Twitter</span>
                </ShareNetwork>
              </li>
            </div>
          </div> -->



          <!--Social Share  plugin -->
          <div
            v-if="
              event.type &&
              event.type == 'PrivateLocation' &&
              isPrivateContacts(event.contacts)
            "
            class="dropup private-info"
          >
            <button
              class="btn btn-default notfollow"
              id="private-contact-info"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
            >
              <!-- <i class="fas fa-mobile-alt"></i> -->
              <img width="20" :src="'icons/call.svg' | assetPath" alt="Call" />
              Contact
            </button>
            <ul class="dropdown-menu" aria-labelledby="private-contact-info">
              <li>
                <a href="#"><strong>Private Event Information</strong></a>
              </li>
              <li role="separator" class="divider"></li>
              <template v-for="contact in event.contacts">
                <li v-if="contact.provider == 'private-number'">
                  <a :href="'tel:' + contact.value" class="dropdown-item">
                    <img
                      width="20"
                      :src="'icons/call.svg' | assetPath"
                      alt="Call"
                    />
                    {{ contact.value }}
                    <!-- <i class="fas fa-envelope"> {{ contact.value }}</i> -->
                  </a>
                </li>
                <li v-if="contact.provider == 'private-email'">
                  <a :href="'mailto:' + contact.value" class="dropdown-item">
                    <!-- <i class="fas fa-envelope"> {{ contact.value }}</i> -->
                    <img
                      width="20"
                      :src="'icons/email.svg' | assetPath"
                      alt="Email"
                    />
                    {{ contact.value }}
                  </a>
                </li>
                <li v-if="contact.provider == 'private-link'">
                  <a :href="contact.value" class="dropdown-item">
                    <img
                      width="20"
                      :src="'icons/link.svg' | assetPath"
                      alt="URL"
                    />
                    URL
                    <!-- <i class="fas fa-globe"> Website</i> -->
                  </a>
                </li>
              </template>
            </ul>
          </div>

          <!-- Event Ticket -->
          <div v-if="event && event.object_type !== 'ads' && event.tickets && event.tickets.length > 0">
                      
                    <div class="parallelogram-container">           
                    <div v-for="(ticket, index) in event.tickets.slice().sort((a, b) => {
                          const priority = ['Tickets','OrderOnline','FoodMenu', 'Reservation','VIP', 'Suites','Parking','FreeRSVP','Tailgate','Metaverse','Watch','PlaceBet'];
                          return priority.indexOf(a.provider) - priority.indexOf(b.provider);
                        })"
                        :key="index"
                      >
                          <div v-if="ticket.provider_left != null">

                            <div class="parallelogram" :style="{ backgroundColor:  getProviderColor(ticket.provider) }">
                              <p class="ticket_status_text">
                                <a :href="ticket.url" target="_blank">{{ ticket.provider }}</a>
                              </p>
                            </div>
                          </div>

                          <div v-if="event.tickets.length != 1 && ticket.provider_left != null">

                          <div v-if="ticket.provider_right != null">

                            <div class="parallelogram2" :style="{ backgroundColor:  getProviderColor(ticket.provider) }">
                              <p class="ticket_status_text2">
                                <a :href="ticket.url" target="_blank">{{ ticket.provider }}</a>
                              </p>
                            </div>
                          </div>
                          </div>

                          <div v-else-if="event.tickets.length === 1 && ticket.provider_left == null">  

                            <div v-if="ticket.provider_right != null">
                              <div class="parallelogram3" :style="{ backgroundColor:  getProviderColor(ticket.provider) }">
                                <p class="ticket_status_text2">
                                  <a :href="ticket.url" target="_blank">{{ ticket.provider }}</a>
                                </p>
                              </div>
                            </div>

                          </div>
                          <div v-else-if="event.tickets.length === 2 && event.tickets.some(t => ['EventBrite', 'LiveNation', 'CompanyWebsite', 'Stubhub', 'Ticketmaster'].includes(t.provider))  && ticket.provider_left == null">

                          <div v-if="ticket.provider_right != null">
                            <div class="parallelogram3" :style="{ backgroundColor:  getProviderColor(ticket.provider) }">
                              <p class="ticket_status_text2">   
                                <a :href="ticket.url" target="_blank">{{ ticket.provider }}</a>
                              </p>
                            </div>        
                          </div>
                          
                          </div>
                          <div v-else>

                          <div  v-if="ticket.provider_right != null">

                        <div class="parallelogram2" :style="{ backgroundColor:  getProviderColor(ticket.provider) }">
                          <p class="ticket_status_text2">
                            <a :href="ticket.url" target="_blank">{{ ticket.provider }}</a>
                          </p>
                        </div>
                        </div>

                          </div>
                          <!-- <button @click="logTickets(ticket.provider)">Log Tickets</button> -->

                        </div>
                        </div>
                        </div>
                      <div v-if="event && event.object_type !== 'ads' && event.tickets && event.tickets.length === 1">
                        <div v-for="(ticket, index) in event.tickets.slice().sort((a, b) => {
                          const priority = ['Tickets','OrderOnline','FoodMenu', 'Reservation','VIP', 'Suites','Parking','FreeRSVP','Tailgate','Metaverse','Watch','PlaceBet'];
                          return priority.indexOf(a.provider) - priority.indexOf(b.provider);
                        })"
                        :key="index"
                      >
                          <div v-if="ticket.provider_right == null && ticket.provider_left ==null">
                            <div class="centered-container2">
                            <router-link   :to="{ name: 'event', params: { id: event.id } }">More Info</router-link>
                          </div>
                          </div>
                        
                        </div>
                      </div>

                        <div v-if="event && event.object_type !== 'ads' && event.tickets && event.tickets.length === 0">

                            <div class="centered-container" style="margin-top: 15px;">
                            <router-link   :to="{ name: 'event', params: { id: event.id } }">More Info</router-link>
                          </div>
                        
                        </div>


                        <!-- <div v-if="event && event.object_type !== 'ads' && event.tickets && event.tickets.length > 0">
                      
                      <div class="parallelogram-container">           
                      <div v-for="(ticket, index) in event.tickets.slice().sort((a, b) => {
                            const priority = ['Tickets','OrderOnline','FoodMenu', 'Reservation','VIP', 'LuxurySuites','Parking','FreeRSVP','Tailgate','Metaverse','Watch','PlaceBet'];
                            return priority.indexOf(a.provider) - priority.indexOf(b.provider);
                          })"
                          :key="index"
                        >
                        <div v-if="ticket.provider_left != null">

                          <div class="parallelogram" :style="{ backgroundColor: providerColors[ticket.provider] ? providerColors[ticket.provider] : 'black' }">
                            <p class="ticket_status_text">
                              <a :href="ticket.url" target="_blank">{{ ticket.provider }}</a>
                            </p>
                          </div>
                          </div>

                        <button @click="logTickets(ticket)">Log Tickets</button>

                        </div>
                        </div>
                        </div> -->
                        

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helpers from "../../mixin/helpers";
import { bus } from "../../app";
import LikeEvent from "../global/like2.vue";
import FollowVenue from "../global/follow-button.vue";

export default {
  props: ["venue", "events", "user"],
  mixins: [helpers],
  watch: {},
  data() {
  return {
    viewType: "eventType",
    likeViewType: "recurringVenue",
    heartImage: "heart.svg",
    like: false,
    processing: false,
    providerColors: {
      Tickets: 'black',
      Reservation: '#33aaec',
      Parking: 'black',
      OrderOnline: '#ef1718',
      FoodMenu: '#ef1718',
      VIP: 'purple',
      Suites: 'green',
      Metaverse: '#28a9ed',
      FreeRSVP: '#28a9ed',
      Tailgate: '#28a9ed',
      Watch: 'black',
      PlaceBet: 'black',
    },
    adStatusOptions: {
      '': 'Select One',
      '1': 'Learn Now', 
      '2': 'Download Now',
      '3': 'Registered Today',
      '4': 'Buy Now',
      '5': 'On Sale Now',
      '6': 'Holiday Sales',
      '7': 'Vote Now',
      '8': 'Ending Soon',
      '9': 'Tickets',
      '10': 'Ad Wanted',
      '11': 'Visit Site',
      '12': 'Movie Release',
      '13': 'Official Trailer',
    },
    // Other data properties...
  };
},

  components: {
    like_event: LikeEvent,
    follow_venue: FollowVenue,
  },
  computed: {},
  mounted() {
    setTimeout(() => {
      shareButtonEnable(this.events, "event-detail");
    }, 100);
  },
  methods: {
    logTickets(e) {
      console.log('Tickets:', e);
       },
       getProviderColor(provider) {
        if (this.providerColors[provider] !== undefined) {
      return this.providerColors[provider];
    }
    return '#3498db'; // Default color
    },
 
    favoriteEvent: function (id) {
      this.events = this.events.map((el) => {
        el.id === id ? (el.like = true) : "";
        return el;
      });
      var vm = this;
      //this.heartImage = 'red-heart.svg';
      var data = new FormData();
      data.append("content_id", id);
      data.append("content_type", "event");
      data.append("like", true);

      if (vm.processing === true) {
        return false;
      }

      vm.processing = true;
      axios
        .post("data/markLike", data)
        .then(function (response) {
          vm.processing = false;
        })
        .catch(function (errors) {
          vm.processing = false;
        });
    },
    getBaseUrl() {
      var path = window.axios.defaults.baseURL;
      return path;
    },
    showNewsletter() {
      $("#newsletterModal").modal("show");
    },
    getHeartImageUrl(isLike) {
      var path = window.axios.defaults.baseURL + "/images/";
      return isLike === true ? path + "red-heart.svg" : path + "heart.svg";
    },
    getFollowClass(isFollow) {
      return isFollow === true ? "follow mobile-hide" : "notfollow mobile-hide";
    },
    getFollowClassMobile(isFollow) {
      return isFollow === true
        ? "follow desktop-hide inline"
        : "notfollow desktop-hide inline";
    },
    followVenue: function (id, venue_id) {
      this.events = this.events.map((el) => {
        el.event_id === id ? (el.follow = true) : "";
        return el;
      });
      var vm = this;
      //this.heartImage = 'red-heart.svg';
      var data = new FormData();
      data.append("content_id", venue_id);
      data.append("content_type", "venue");
      data.append("favourite", true);

      if (vm.processing === true) {
        return false;
      }

      vm.processing = true;
      axios
        .post("data/markFavourite", data)
        .then(function (response) {
          vm.processing = false;
        })
        .catch(function (errors) {
          vm.processing = false;
        });
    },
  },
  // data: function () {
  //   return {
  //     like: false,
  //     processing: false,
  //   };
  // },
  filters: {
    checkNullHash: function (hash) {
      var hashTag = "";
      if (hash != "" && hash != null && hash != "null") {
        hashTag = "#" + hash;
      }
      return hashTag;
    },
    assetPath: function (img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
    checkObj: function (obj) {
      return obj == "" ? false : true;
    },
    url: function (path) {
      var path = window.axios.defaults.baseURL + "/" + path;
      return path;
    },
  },
};
</script>
<style scoped>
.event-detail-card .bottom-decription a{
  color: unset !important;
}

.parallelogram-container {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between;  */
  margin-top: 8px;
}
/* Ticket  Right*/
.parallelogram {
  width: 120px;
  height: 30px;
  background-color: #3498db;
  color: white;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
}
.parallelogram2 {
  width: 120px;
  height: 30px;
  color: white;
  background-color: black;
  /* clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%);  */
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
}
.parallelogram3 {
  width: 120px;
  height: 30px;
  color: white;
  background-color: black;
  /* clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%); */
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
  margin-left: 130px;
}
/* Ad Ticket */
.parallelogramadleft {
  width: 80px;
  height: 38px;
  background-color:#57BB5B;
  color: white;
  clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
}
.parallelogramright{
  width: 200px;
  height: 38px;
  color: white;
  background-color:  #3498db;
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
}
.container23 {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Adjust based on your layout needs */
  margin-top: 10px;
}
.ticket_status_text{
  font-size: 14px;
  color: white;
  margin-left: 15px;
  margin-top: 8px;
}
.ticket_status_text2{
  font-size: 14px;
  color: white;
  margin-left: 22px;
  margin-top: 8px;
}
/* More Info Left */
.centered-container {
  font-size: medium;
  background-color: silver;
  width: 275px;
  height: 30px;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center;    /* Centers vertically */
  color: black;
}
/* More Info Right */
.centered-container2{
  font-size: medium;
  background-color: silver;
  /* width: 275px; */
  height: 30px;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center;    /* Centers vertically */
  color: black;
  /* margin-top: -34px; */
}

a{
  color: unset;
}

/* Media query for mobile devices */
@media only screen and (min-width: 350px) and (max-width: 1008px){

.parallelogram {
width: 160px;
}
.parallelogram2 {
  width: 190px;
  clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%);

  
}
.parallelogramadleft {
width: 90px !important;

}
.parallelogramright{
width: 280px !important;
clip-path: polygon(8% 0, 100% 0, 100% 100%, 0 100%);
}

.ticket_status_text{
  margin-left: 15px;
  /* margin-top: 15px !important; */
}
.ticket_status_text2{

  margin-left: 50px;
  /* margin-top: 15px!important */
}

.parallelogram3 {
  /* width: 140px;
  height: 50px;
  color: white;
  background-color: black;
  clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%); */

  margin-left: 206px;

}

.centered-container {
  width: 380px;

}
.centered-container2{
  width: 380px;

}

.container23 {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;  */
  padding: 20px;

}

}
</style>