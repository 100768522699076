<template>
  <div class="v-map-view-component map-view-page">
    <header_section></header_section>
    <div class="map-vue-content">
        <map_content></map_content>
    </div>
  </div>
</template>

<script>

  import { bus } from './app'
  import MapView from './../components/MapView' 
  import HeaderSection from './../views/partials/header'
  import helpers from './../mixin/helpers';

  export default {
    data() {
      return {
          loadingContent: false,
      }
    },
    beforeCreate() {
      this.loadingContent = true;
    },
    created(){
      this.loadingContent = false;
    },
    mixins: [helpers],
    components:{
      map_content: MapView,
      'header_section': HeaderSection,
    }
  }
</script>