<template>
  <div>
    <!-- Main Start -->
    <header_section></header_section>
    <main class="top-inner-pages">
      <section class="headerSectionWrapper">
        <div class="headerBGImage">
          <img :src="'banners/vgps-min.png' | assetPath" alt="header Image" />
        </div>
      </section>
      <!-- Dashboard Start -->
      <section class="dashboard vgps_contact_support_wrapper innerPageContentSec">
        <div class="container">
          <div class="row">
            <div class="col-md-12 vgps_page_header_wrapper">
              <h2>Terms of Use & Privacy Policy</h2>
            </div>
            <div class="col-md-12 vgps_privacy_content_wrapper privacyPolicyPage">
              <p>Welcome to VenueGPS! This document explains our policies on using our website and services as well as how we collect, use, and protect your personal information. By using venuegps.com or any services provided by VenueGPS LLC, you agree to these terms and conditions.</p>
                <p>We value transparency and are committed to protecting your data while ensuring compliance with relevant laws, including the General Data Protection Regulation (GDPR), Data Protection Act 1998, and Privacy and Electronic Communications (EC Directive) Regulations 2003.</p>

                <h3>Quick Summary</h3>
                <ul>
                    <li><strong>What We Collect:</strong> Personal information like your name, email, location, and payment details when you interact with us.</li>
                    <li><strong>How We Use It:</strong> To provide services, personalize your experience, and for marketing (if you consent).</li>
                    <li><strong>Your Rights:</strong> Access, correct, delete, or object to how we use your data.</li>
                    <li><strong>Third-Party Sharing:</strong> We don’t sell your information. However, we may share it with trusted partners for business purposes.</li>
                    <li><strong>Security:</strong> We protect your data using encryption and other security measures.</li>
                </ul>

                <h3>Table of Contents</h3>
                <ul>
                    <li>1. Introduction</li>
                    <li>2. What Personal Data We Collect</li>
                    <li>3. Legal Basis for Processing Data</li>
                    <li>4. How We Use Your Personal Information</li>
                    <li>5. Geo-Location Services & Tracking</li>
                    <li>6. Data Sharing & Third-Party Partners</li>
                    <li>7. Data Security & Retention</li>
                    <li>8. Links to Other Websites</li>
                    <li>9. Children’s Privacy</li>
                    <li>10. Your Rights</li>
                    <li>11. Changes to this Policy</li>
                    <li>12. Contact Information</li>
                    
                </ul>

                <h4>1. Introduction</h4>
                <p>This document explains how VenueGPS LLC (referred to as “VenueGPS”, “we”, “us”, or “our”) collects, processes, and protects your personal information. We are committed to protecting your privacy and ensuring your data is handled responsibly. We are registered with the Information Commissioner’s Office (ICO) and comply with relevant privacy laws.</p>
                <p>By using venuegps.com, you consent to the collection and processing of your data in accordance with this policy.</p>

                <h4>2. What Personal Data We Collect</h4>
                <p>In order to provide our services, VenueGPS collects the following types of personal information:</p>
                <ul>
                    <li><strong>Personal Identification Information:</strong> First and last name, email address, phone number, and mailing address.</li>
                    <li><strong>Transaction and Billing Information:</strong> Payment details (e.g., credit card info, billing address) and transaction history when you purchase services.</li>
                    <li><strong>Location Data:</strong> Geo-location from your device to provide personalized content and location-based services.</li>
                    <li><strong>Demographic Information:</strong> Age, gender, and race (when provided or collected anonymously).</li>
                    <li><strong>Usage Data:</strong> Technical information such as your IP address, browser type, operating system, and how you interact with our site.</li>
                </ul>
                <p>This data is collected when you:</p>
                <ul>
                    <li>Register an account with us</li>
                    <li>Purchase services or make transactions</li>
                    <li>Participate in promotions or competitions</li>
                    <li>Interact with customer service or send inquiries</li>
                </ul>

                <h4>3. Legal Basis for Processing Data</h4>
                <p>We process your personal data based on the following legal grounds:</p>
                <ul>
                    <li><strong>Contractual Necessity:</strong> To provide you with services and fulfill our contractual obligations.</li>
                    <li><strong>Consent:</strong> For marketing purposes if you’ve given explicit consent. You can withdraw this consent at any time.</li>
                    <li><strong>Legitimate Interest:</strong> For our legitimate business interests, such as improving services or ensuring site security.</li>
                    <li><strong>Legal Compliance:</strong> To comply with legal obligations, such as responding to regulatory inquiries or fraud prevention.</li>
                </ul>

                <h4>4. How We Use Your Personal Information</h4>
                <p>VenueGPS uses your personal information for the following purposes:</p>
                <ul>
                    <li><strong>To Provide Services:</strong> To deliver requested services, manage your account, and process transactions.</li>
                    <li><strong>Personalization:</strong> Tailor the content you see, including event recommendations, based on your preferences and location.</li>
                    <li><strong>Communications:</strong> Service-related updates, responses to inquiries, and marketing messages (if you’ve consented).</li>
                    <li><strong>Marketing:</strong> Send promotions, special offers, or event notifications if you opt in.</li>
                    <li><strong>Compliance & Security:</strong> To comply with legal requirements and maintain platform security.</li>
                </ul>
                <p>Note: We do not publicly display your full name unless required during transactions.</p>

                <h4>5. Geo-Location Services & Tracking</h4>
                <p>To improve your experience, VenueGPS utilizes geo-location services, which allow us to:</p>
                <ul>
                    <li>Display Nearby Events</li>
                    <li>Navigation</li>
                    <li>Personalization</li>
                </ul>
                <p>You may disable location tracking by adjusting your device settings; however, this may limit access to certain features.</p>

                <h4>6. Data Sharing & Third-Party Partners</h4>
                <p>VenueGPS will never sell, rent, or lease your personal information. However, we may share your data with:</p>
                <ul>
                    <li>Service Providers</li>
                    <li>Legal Obligations</li>
                    <li>Business Transfers</li>
                </ul>

                <h4>7. Data Security & Retention</h4>
                <p>We take data security seriously and implement various measures to protect your information:</p>
                <ul>
                    <li><strong>SSL Encryption:</strong> All sensitive information is encrypted.</li>
                    <li><strong>Access Controls:</strong> Only authorized personnel have access to your data.</li>
                    <li><strong>Data Retention:</strong> Retained only as long as necessary to fulfill purposes or legal obligations.</li>
                </ul>

                <h4>8. Links to Other Websites</h4>
                <p>Our website may contain links to third-party websites. VenueGPS is not responsible for the privacy practices of these external sites.</p>

                <h4>9. Children’s Privacy</h4>
                <p>VenueGPS does not knowingly collect personal information from children under the age of 13.</p>

                <h4>10. Your Rights</h4>
                <ul>
                    <li><strong>Access:</strong> Request a copy of the personal data we hold about you.</li>
                    <li><strong>Correction:</strong> Request that we correct inaccuracies.</li>
                    <li><strong>Deletion:</strong> Request deletion of your data, subject to legal obligations.</li>
                    <li><strong>Objection:</strong> You can object to certain data processing.</li>
                    <li><strong>Data Portability:</strong> Request your data in a commonly used format.</li>
                </ul>

                <h4>11. Changes to this Policy</h4>
                <p>VenueGPS reserves the right to update or modify this Terms of Use & Privacy Policy.</p>

                <h4>12. Contact Information</h4>
                <p>For any questions regarding this document or your personal data, please contact us:</p>
                <address>
                    VenueGPS LLC<br>
                    10408 Courthouse Rd., PO Box 1059<br>
                    Spotsylvania, Virginia 22553-1712<br>
                    Email: <a href="mailto:info@venuegps.com">info@venuegps.com</a><br>
                    <!-- Phone: <strong>(757) 656-5799</strong><br> -->
                </address>
                <p><strong>Effective Date:</strong> November 1, 2024</p>
            </div>
          </div>
        </div>
      </section>
      <!-- Dashboard End -->
    </main>
    <!-- Main End -->
    <footer_section></footer_section>
  </div>
</template>

<script>
import HeaderSection from "./../views/partials/header";
import FooterSection from "./../views/partials/footer";
import helpers from "./../mixin/helpers";

export default {
  data() {
    return {
      loadingContent: false,
    };
  },
  mixins: [helpers],
  components: {
    header_section: HeaderSection,
    footer_section: FooterSection,
  },
};
</script>