<template>
  <div class="row">
    <div class="col-md-12 vgps-browse-listing-wrapper">
      <div v-if="loadingContent" class="content-loader">
        <content-loader
          width="1490"
          height="475"
          :speed="1"
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="0" y="20" rx="8" ry="8" width="350" height="350" />
          <rect x="0" y="390" rx="0" ry="0" width="200" height="18" />
          <rect x="0" y="420" rx="0" ry="0" width="120" height="20" />

          <rect x="380" y="20" rx="8" ry="8" width="350" height="350" />
          <rect x="380" y="390" rx="0" ry="0" width="200" height="18" />
          <rect x="380" y="420" rx="0" ry="0" width="120" height="20" />

          <rect x="760" y="20" rx="8" ry="8" width="350" height="350" />
          <rect x="760" y="390" rx="0" ry="0" width="200" height="18" />
          <rect x="760" y="420" rx="0" ry="0" width="120" height="20" />

          <rect x="1140" y="20" rx="8" ry="8" width="350" height="350" />
          <rect x="1140" y="390" rx="0" ry="0" width="200" height="18" />
          <rect x="1140" y="420" rx="0" ry="0" width="120" height="20" />
        </content-loader>
      </div>
      <div v-else class="loaded-content" v-animate-css="animate.fadeIn">
        <div v-if="venues" class="vgps-browse-listing-grid-wrapper">
          <div
            v-for="(venue, key) in venues"
            :key="key"
            class="vgps-browse-listing-grid ceo"
          >
          
            <div class="content">
              <a
                v-if="isAdministratorAccount()"
                :href="adminEditOptionURL('business', venue.id)"
                target="_blank"
                class="admin-edit-option"
              >
                <i class="fa fa-edit"></i>
              </a>
              <router-link
                v-if="
                  filters.category != 'People' &&
                  filters.category != 'AnnualEvent' &&
                  filters.category != 'Community' &&
                  isAuth()
                "
                class="directory-image"
                :class="getBusinessStatus(venue)"
                :to="{ name: 'venue', params: { id: venue.id } }"
              >
                <div
                  class="pic event-thumb-img" 
                  v-lazy:background-image="venue.thumbnail_urls.thumb_296"
                >
                  
                  <div
                    v-if="venue.venue_closing_reason && venue.venue_closing_reason.title != 'Normal'"
                    class="reason-info"
                  >
                    <span class="info-imgae" :title="getVenueAidTitle(venue)">
                      {{ venue.venue_closing_reason.severity.level | checkNullSeverity }}
                      <img :src="'info.svg' | assetPath" />
                    </span>
                  </div>
                </div>
                <div
                  v-if="
                    venue.claim_color == 'red' || venue.claim_color == 'blue'
                  "
                  class="claim-business-flag"
                >
                  <a
                    href="javascript:void(null);"
                    data-toggle="modal"
                    @click="
                      assignVenueID(venue.id, venue.title, 'directory', key)
                    "
                    data-target="#claimBusinessModal"
                    >Claim Business Now</a>
                </div>
                <div
                  v-if="venue.claim_color == 'white'"
                  class="claim-business-flag requested"
                >
                  <a href="javascript:void(null);">Requested for Claim</a>
                </div>
              </router-link>
              <a
                class="directory-image"
                :class="getBusinessStatus(venue)"
                v-else
                @click="loginModal(venue.id, '#/venue/')"
                href="javascript:void(null);"
                @contextmenu="blockRightClick($event)"
              >
                <div
                  class="pic event-thumb-img"
                  v-lazy:background-image="venue.thumbnail_urls.thumb_296"
                >
                  <div
                    v-if="
                      getBusinessStatus(venue) != '' &&
                      getBusinessStatus(venue) != 'White'
                    "
                    class="reason-info"
                    :title="getVenueAidTitle(venue)"
                  >
                    <span class="info-imgae">
                      {{ venue.venue_closing_reason.severity.level | checkNullSeverity }}
                      <img :src="'info.svg' | assetPath" />
                    </span>
                  </div>
                </div>
              </a>
              <div
                class="claim-business-flag"
                v-if="venue.claim_color == 'red' || venue.claim_color == 'blue'"
              >
                <a
                  href="javascript:void(null);"
                  data-toggle="modal"
                  @click="
                    assignVenueID(venue.id, venue.title, 'directory', key)
                  "
                  data-target="#claimBusinessModal"
                  >Claim Business Now</a
                >
              </div>

              <div class="bottom-decription">
                <h6>
                  <router-link
                    v-if="isAuth()"
                    :to="{ name: 'venue', params: { id: venue.id } }"
                    >{{ venue.title? limitStr(venue.title, 30):'' }}</router-link
                  >
                  <a
                    v-else
                    @click="loginModal(venue.id, '#/venue/')"
                    href="javascript:void(null);"
                    @contextmenu="blockRightClick($event)"
                    >{{ venue.title? limitStr(venue.title, 30):'' }}</a
                  >
                </h6>
                <p>
                  {{
                    limitStr(
                      venue.address_details.street &&
                        venue.address_details.street != "NULL"
                        ? venue.address_details.street
                        : "",
                      30
                    )
                  }}
                </p>
                <p>{{ venue.address_details.full_address }}</p>
                <div class="hashtag-wrapper" v-if="venue.type">
                  <router-link
                    class="event-hashtags"
                    :title="venue.type"
                    :to="{
                      name: 'search',
                      query: {
                        search_term: '#' + venue.type,
                        search_category: 'all',
                      },
                    }"
                    >#{{ venue.type }}</router-link
                  >
                </div>

                <follow_venue
                  v-bind:venues="venues"
                  v-bind:event="venue"
                  v-bind:venueid="venue.id"
                  v-bind:viewType="viewType"
                  v-bind:index="key"
                ></follow_venue>
                <div class="dropup directoryShareWrapper">
                  <button
                    class="btn btn-default vue-social-share"
                    id="social-network-share"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-share-alt"></i>
                    <span>Share</span>
                  </button>
                  <div
                    class="dropdown-menu vue-social-share-items"
                    aria-labelledby="dropdownMenu2"
                  >
                    <li class="dropdown-item">
                      <ShareNetwork
                        network="facebook"
                        :url="socialShareLink('venue-detail/' + venue.id)"
                        :title="venue.title ? venue.title : '--'"
                      >
                        <i class="fab fah fa-lg fa-facebook"></i>
                        <span>Share on Facebook</span>
                      </ShareNetwork>
                    </li>
                    <li class="dropdown-item">
                      <ShareNetwork
                        network="twitter"
                        :url="socialShareLink('venue-detail/' + venue.id)"
                        :title="venue.title ? venue.title : '--'"
                      >
                        <i class="fab fah fa-lg fa-twitter"></i>
                        <span>Share on Twitter</span>
                      </ShareNetwork>
                    </li>
                  </div>
                </div>
              </div>
            </div>


            
            
          </div>

          <div v-if="loadingMoreContent" class="content-loader">
            <img :src="'icons/vgps-loader.gif' | assetPath" />
          </div>

         

          <!-- <div v-if="showLoadMore" class="clear-row" style=" padding-top:1px;">
            <a @click="loadMore()" class="see-more" href="javascript:void(0)">Load More</a>
          </div> -->
        </div>

         <!--Show more event --->
          <div v-if="showLoadMore && !loadingMoreContent"  class="row">
            <div class="col-md-12">
              <infinite-loading spinner="waveDots" @infinite="infiniteHandler"></infinite-loading>
            </div>
          </div>
        <div v-if="venues.length == 0" class="text-center result-not-found">
          <a><i class="fa fa-search"></i></a>
          <p>Sorry, we do not have any result for this search for now.<br /></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../../app";
import FollowVenue from "../global/follow-button.vue";
import ResultNotFound from "./../../views/user/partials/result-not-found";
import helpers from "../../mixin/helpers";
import InfiniteLoading from "vue-infinite-loading";

import {
  ContentLoader,
  FacebookLoader,
  CodeLoader,
  BulletListLoader,
  InstagramLoader,
  ListLoader,
} from "vue-content-loader";

export default {
  data() {
    return {
      viewType: "venueType",
      loadingContent: false,
      loadingMoreContent: false,
      showLoadMore: true,
      venues: [],
      selectedCategory: "",
      search: "",
      hasTagsSearch: "",
      statusIndex: "",
    };
  },
  mixins: [helpers],
  created() {
    var vm = this;
    bus.$on("advanceFilterEvent", (searchValue) => {
      // debugger
      vm.getVenues("advanceFilter", searchValue);
    });
    bus.$on("filterSelection", () => {
      // this.getVenues('filterSelection')
    });

    bus.$on("getvenueIndex", (index) => {
      vm.statusIndex = index;
    });
    bus.$on("claimBusinessRequested", (data) => {
      vm.venues[vm.statusIndex].claim_color = data.data.claim_color;
    });

    bus.$on("updateLocation", (city) => {
      vm.location = city;
      vm.filters.offset = 0;
      vm.getVenues("updateLocation");
    });
    bus.$on("search_event_title", (keyword) => {
      vm.filters.search = keyword;
      vm.getVenues("");
    });
    bus.$on("emptyState", () => {
      // alert('emptyState');
      vm.filters.city = "";
      // vm.getVenues();
    });
    bus.$on("clearAdvanceFilter", () => {
      vm.getVenues();
    });

    // vm.getVenues();
  },
  beforeMount() {
    this.loadingContent = true;
  },
  components: {
    ContentLoader,
    FacebookLoader,
    follow_venue: FollowVenue,
    result_not_found: ResultNotFound,
    InfiniteLoading,
  },
  props: ["filters", "location", "userData"],
  computed: {
    
  },
  methods: {
    getVenueAidTitle: function(venue){
        var title = '';
        if( venue.venue_closing_reason && venue.venue_closing_reason.severity.description && venue.venue_closing_reason.title != 'Normal'){
            title = venue.venue_closing_reason.severity.description
            title += venue.venue_closing_reason.duration.time_duration?' | Duration:'+venue.venue_closing_reason.duration.time_duration:'';
        }else {
            title = venue.venue_closing_reason.title;
        }

        return title;
    },
    infiniteHandler($state) {
      var vm = this;
      vm.showLoadMore = true;
      setTimeout(() => {
        vm.filters.offset = vm.filters.offset + 12;
        vm.getVenues("loadmore");
        $state.complete();
        vm.showLoadMore = false;
      }, 500);
    },
    loadMore: function () {
      this.filters.offset = this.filters.offset + this.filters.limit;
      this.getVenues("loadmore");
    },
    getVenueAidTitle: function (venue) {
      var title = "";
      if (
        venue.venue_closing_reason && venue.venue_closing_reason.severity.description &&
        venue.venue_closing_reason.title != "Normal"
      ) {
        title = venue.venue_closing_reason.severity.description;
        title += venue.venue_closing_reason.duration.time_duration
          ? " | Duration:" + venue.venue_closing_reason.duration.time_duration
          : "";
      } else {
        title = venue.venue_closing_reason.title;
      }

      return title;
    },
    directoryfullAddress: function (venue) {
      var address = "";
      address +=
        venue.address_details.street && venue.address_details.street != "NULL"
          ? venue.address_details.street + "<br>"
          : "";
      address +=
        venue.address_details.full_address &&
        venue.address_details.full_address != "NULL"
          ? venue.address_details.full_address
          : "";
      return address;
    },
    getImageUrl(isLike) {
      var path = window.axios.defaults.baseURL + "/images/";
      return isLike === true ? path + "fav-w.svg" : path + "fav.svg";
    },
    getFollowClass(isFollow) {
      return isFollow === true ? "follow mobile-hide" : "notfollow mobile-hide";
    },
    getFollowClassMobile(isFollow) {
      return isFollow === true
        ? "follow desktop-hide inline"
        : "notfollow desktop-hide inline";
    },
    hashTagsSearchAPI(venueType) {
      this.hashTagsSearch = venueType;
      this.getVenues();
    },
    getVenues: function (action, searchingValue) {
      // console.log(action, searchingValue, 'my')
      var vm = this;
      if (action == "loadmore") {
        vm.loadingMoreContent = true;
      } else if (action == "advanceFilter") {
        vm.selectedCategory = searchingValue;
        vm.loadingContent = true;
      } else {
        vm.loadingContent = true;
      }
      axios
        .get("data/venue/", {
          params: {
            offset: this.filters.offset,
            limit: this.filters.limit,
            amenities: this.filters.amenities.length > 0 ? JSON.stringify(this.filters.amenities):"",
            // cuisines: this.filters.amenities.length > 0 ? JSON.stringify(this.filters.amenities):"",
            keyword: this.filters.search !=''?this.filters.search:'',
            starts_with: this.filters.start_with,
            state_id: this.filters.state_id!=''?this.filters.state_id:'',
            genre: this.filters.event_genre!=''?this.filters.event_genre:'',
            is_global: this.filters.is_global!=''?this.filters.is_global:'',
            city: this.filters.city,
            business_type: this.filters.business_type!=''?this.filters.business_type:'',
            type: this.filters.venue_type!=''?this.filters.venue_type:'',
            // category: this.filters.category,
            hashtag: this.hashTagsSearch,
          },
        })
        .then((response) => {
          if (action == "loadmore") {
            $.each(response.data.data.content, function (index, obj) {
              vm.venues.push(obj);
            });
            vm.showLoadMore =
              response.data.data.content !== undefined &&
              response.data.data.content.length < vm.filters.limit
                ? false
                : true;
            vm.loadingMoreContent = false;
          } else {
            vm.venues = response.data.data.content;
            vm.showLoadMore =
              response.data.data.content !== undefined > 0 &&
              response.data.data.content.length < vm.filters.limit
                ? false
                : true;
            vm.loadingContent = false;
          }

          if (
            this.filters.category == "People" ||
            this.filters.category == "AnnualEvent" ||
            this.filters.category == "Community"
          ) {
            setTimeout(function () {
              $(".loaded-content").find(".pleaseShare").hide();
            }, 200);
          } else {
            shareButtonEnable(response.data.data.content, "venue-detail");
          }

          if (vm.filters.type == "") {
            $(".select2-dropdown-type")
              .find("select")
              .prepend('<option value="">Venue Type</option>');
            if (
              $(".select2-hidden-accessible option:contains('Venue Type')")
                .length > 1
            ) {
              $(".select2-hidden-accessible option:contains('Venue Type')")
                .eq(0)
                .remove();
            }
          }
        })
        .catch(function (errors) {
          vm.loadingContent = false;
          vm.loadingMoreContent = false;
        });
    },
    getBusinessStatus: function (venue) {
      var str = "";
      if (venue.venue_closing_reason && typeof venue.venue_closing_reason != "undefined") {
        str = venue.venue_closing_reason.color;
      }
      return str;
    },
    
    followVenue: function (id) {
      this.venues = this.venues.map((el) => {
        el.id === id ? (el.follow = true) : "";
        return el;
      });
      var vm = this;
      //this.heartImage = 'red-heart.svg';
      var data = new FormData();
      data.append("content_id", id);
      data.append("content_type", "venue");
      data.append("favourite", true);

      if (vm.processing === true) {
        return false;
      }

      vm.processing = true;
      axios
        .post("data/markFavourite", data)
        .then(function (response) {
          vm.processing = false;
        })
        .catch(function (errors) {
          vm.processing = false;
        });
    },
    blockRightClick: function (e) {
      e.preventDefault();
    },
    blockRightClick: function (e) {
      e.preventDefault();
    },
  },
  filters: {
    checkNullSeverity: function(severity) {
      var str = "V-AID";
      if (severity != "" && severity != null) {
        str += ": "+severity;
      }
      return str;
    },
    assetPath: function (img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
    url: function (path) {
      var path = window.axios.defaults.baseURL + "/" + path;
      return path;
    },
  },
};
</script>
