<template>
    <main id="search-banner-section">
        <section class="top-banner">
            <!-- <autocomplete></autocomplete> -->
            <h1 class="">Search Event, Venues, People and community.</h1>
        </section>
    </main>
</template>

<script>

    import {bus} from '../../app'
    import helpers from './../../mixin/helpers';
    import Autocomplete from "./../../components/global/all-search.vue";

    import { ContentLoader } from 'vue-content-loader'

    import carousel from 'vue-owl-carousel2'
    import Slick from 'vue-slick';
    import 'slick-carousel/slick/slick.css';
    import 'slick-carousel/slick/slick-theme.css'
    export default {
        data() {
            return {
                loadingContent: true,
                banners: [],
                leaderSpeed:1,
                suggestions: [],
                value: "",
                selection: "",
                slickOptions: {
                    autoplay:true,
                    slidesToShow: 1,
                    arrows: true,
                    dots: false,
                    slide:'div',
                    // centerPadding:50,
                    lazyLoad:true,
                    // Any other options that can be got from plugin documentation
                    responsive: [{

                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 1,
                            infinite: true
                        }

                    }, {

                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            dots: false,
                            arrows: true
                        }

                    }, {

                        breakpoint: 300,
                        settings: "unslick" // destroys slick

                    }]
                },
            }
        },
        mixins: [helpers],
        components: {
            autocomplete: Autocomplete,
            Slick,
            carousel,
            ContentLoader
        },
        beforeCreate() {
            // this.loadingContent = true;
        },
        created() {
            this.getBanners();
        },
        updated() {
            this.reInit();
            // this.loadingContent = false;
        },
        mounted() {
            // var vm = this;
            // setTimeout(function(){
            //     vm.loadingContent = false;
            // },2000)
        },
        methods: {
            getBanners:function (){
                var vm = this;
                axios.get("data/banners",{ params: {
                  'slot': 'home'
                }}).then(response => {
                    vm.banners = response.data.data;
                    vm.loadingContent = false;
                }).catch(function (errors) {
                    vm.loadingContent = false;
                });

            },
            next() {
                this.$refs.slick.next();
            },

            prev() {
                this.$refs.slick.prev();
            },
            reInit() {
                // Helpful if you have to deal with v-for to update dynamic lists
                this.$nextTick(() => {
                    this.$refs.slick.reSlick();
                });
            },
        },
        filters: {
            checkNullLink: function (link) {
                var checklink = link? link:'javascript:void(0);';
                return checklink;
            },
        }
    }
</script>


<style>
    .owl-nav disabled {
        margin-bottom: 369px;
    }
</style>