<template>
  <div>
    <!-- Main Start -->
    <header_section></header_section>
    <main class="top-inner-pages pressKitWrapper">
      <!-- <section class="headerSectionWrapper">
        <div class="headerBGImage">
          <img :src="'banners/vgps-min.png' | assetPath" alt="header Image" />
        </div>
      </section> -->
      <!-- Dashboard Start -->
      <section class="dashboard vgps_contact_support_wrapper">
        <div class="container">
          <div class="col-md-12 vgps_page_header_wrapper">
            <h2>Our Logo</h2>
          </div>
          <!-- Portfolio -->
          <div id="portfolio" class="text-left pt">
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-12">
               

                <div class="bg-white-container logo-row">
                  <h3>Add our logo to your business and flyers and enhance your reach.</h3>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="portfolio-image">
                        <img
                          src="https://d2cv1d7fub3ij0.cloudfront.net/catalog/vgps-logo-colored.png"
                        />
                      </div>
                      <div class="download-pdf">
                        <a
                          :href="'downloaded-files/vgps-logo-colored.png' | assetPath"
                          target="_blank"
                          download
                        >Download Now</a>
                      </div>
                    </div>
                    <div class="col-md-4 dark-bg">
                      <div class="portfolio-image">
                        <img
                          src="https://d2cv1d7fub3ij0.cloudfront.net/catalog/vgps-logo-light.png"
                        />
                      </div>
                      <div class="download-pdf">
                        <a
                          :href="'downloaded-files/vgps-logo-light.png' | assetPath"
                          target="_blank"
                          download
                        >Download Now</a>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="portfolio-image">
                        <img
                          src="https://d2cv1d7fub3ij0.cloudfront.net/catalog/vgps-logo-black.png"
                        />
                      </div>
                      <div class="download-pdf last-item">
                        <a
                          :href="'downloaded-files/vgps-logo-black.png' | assetPath"
                          target="_blank"
                          download
                        >Download Now</a>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col-md-4">
                      <div class="portfolio-image">
                        <img src="https://d2cv1d7fub3ij0.cloudfront.net/catalog/venueGPS_1.png" />
                      </div>
                      <div class="download-pdf">
                        <a
                          :href="'downloaded-files/venueGPS_1.png' | assetPath"
                          target="_blank"
                          download
                        >Download Now</a>
                      </div>
                    </div>
                    <div class="col-md-4 dark-bg">
                      <div class="portfolio-image">
                        <img src="https://d2cv1d7fub3ij0.cloudfront.net/catalog/venueGPS_2.png" />
                      </div>
                      <div class="download-pdf">
                        <a
                          :href="'downloaded-files/venueGPS_2.png' | assetPath"
                          target="_blank"
                          download
                        >Download Now</a>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="portfolio-image">
                        <img src="https://d2cv1d7fub3ij0.cloudfront.net/catalog/venueGPS_3.png" />
                      </div>
                      <div class="download-pdf last-item">
                        <a
                          :href="'downloaded-files/venueGPS_3.png' | assetPath"
                          target="_blank"
                          download
                        >Download Now</a>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col-md-4"></div>
                    <div class="col-md-4 last-item">
                      <div class="portfolio-image">
                        <img src="https://d2cv1d7fub3ij0.cloudfront.net/catalog/venueGPS_4.png" />
                      </div>
                      <div class="download-pdf">
                        <a
                          :href="'downloaded-files/venueGPS_4.png' | assetPath"
                          target="_blank"
                          download
                        >Download Now</a>
                      </div>
                    </div>
                    <div class="col-md-4"></div>
                  </div>
                </div>
              </div>
              <div class="col-md-1"></div>
            </div>
          </div>
          <!-- end Portfolio -->
        </div>
      </section>
      <!-- Dashboard End -->
    </main>
    <!-- Main End -->
    <footer_section></footer_section>
  </div>
</template>

<script>
import { bus } from "./app";
import FooterSection from "./../views/partials/footer";
import HeaderSection from "./../views/partials/header";
import helpers from "./../mixin/helpers";

export default {
  data() {
    return {
      loadingContent: false,
    };
  },
  mixins: [helpers],
  components: {
    footer_section: FooterSection,
    header_section: HeaderSection,
  },
};
</script>