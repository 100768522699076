<template >
    <div class=" menu-box">
        <ul>
            
            <li><router-link :to="{ name: 'my_events' }"><i class="fa fa-star"></i> My Events</router-link></li>
            <li><router-link :to="{ name: 'my_venues' }"><i class="fa fa-star"></i> My Accounts</router-link></li>
            <li><router-link :to="{ name: 'all_cards' }"><i class="fa fa-list"></i> Subscriptions</router-link></li>
            <li><router-link :to="{ name: 'user_settings' }"><i class="fa fa-cog"></i> Settings</router-link></li>
            <li class="divider"></li>
            <ul class="setting-menu-sidebar">
                <li><router-link :to="{ name: 'user_profile' }"><i class="fa fa-user"></i> My Profile</router-link></li>
                <li><router-link :to="{ name: 'user_prefrences' }"><i class="fa fa-bookmark"></i> Prefrences</router-link></li>
                <li><router-link :to="{ name: 'contact-us' }"><i class="fa fa-comment"></i> Submit Feedback</router-link></li>
                <li><router-link :to="{ name: 'user_notification_settings' }"><i class="fa fa-bell"></i> Notifications Global</router-link></li>
            </ul>


            <!-- <li>
                <a class="{{ (request()->is('user/profile')) ? 'active' : '' }}" href="{{ route('auth.user.profile') }}">My Profile</a>
            </li>
            <li>
                <a class="{{ (request()->is('user/my-event')) ? 'active' : '' }}" href="{{ route('auth.user.likes') }}">Following Events</a>
            </li>
            <li>
                <a class="{{ (request()->is('user/my-venue')) ? 'active' : '' }}" href="{{ route('auth.user.followings')  }}">Following Venues</a>
            </li>
            <li>
                <a class="{{ (request()->is('user/notifications')) ? 'active' : '' }}"
                href="{{ route('auth.user.notifications')  }}">Notifications</a>
            </li>
            <li>
                <a href="{{ route('logout') }}">Logout</a>
            </li> -->
        </ul>
    </div>


</template>

<script>

  import { bus } from './../../app'
  import helpers from './../../../mixin/helpers';

  export default {
    data() {
      return {}
    },
    mixins: [helpers],
  }
</script>