<template>
  <section class="vgps-venue-detail-wrapper vgps-venue-detail-header-wrapper">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-3 col-sm-4 col-md-3 col-xs-12 vgps-venue-logo-wrapper"
        >
          <div
            class="venue-logo"
            :class="[ venue.venue_closing_reason ? venue.venue_closing_reason.color : '']">
            <silent-box
              v-if="venue.image_url"
              :image="{
                thumbnail: venue.thumbnail_urls.thumb_296,
                src: venue.image_url,
              }"
            ></silent-box>
            <div
              class="view-large-image"
              title="Click over the flyer image for full screen"
            >
              <i class="fas fa-expand" aria-hidden="true"></i>
            </div>
            <div
              v-if="
                venue.venue_closing_reason &&
                venue.venue_closing_reason.color != 'White'
              "
              class="reason-info"
            >
              <span class="info-imgae" :title="getVenueAidTitle">
                {{ venue.venue_closing_reason | checkNullSeverity }}
                <img :src="'info.svg' | assetPath" />
              </span>
            </div>
            <div
              v-if="venue.claim_color == 'red' || venue.claim_color == 'blue'"
              class="claim-business-flag"
            >
              <a
                href="javascript:void(null);"
                data-toggle="modal"
                v-on:click="
                  assignVenueID(venue.id, venue.title, 'venue_detail')
                "
                data-target="#claimBusinessModal"
                >Claim Business Now</a
              >
            </div>
            <div
              v-if="venue.claim_color == 'white'"
              class="claim-business-flag requested"
            >
              <a href="javascript:void(null);">Requested for Claim</a>
            </div>
          </div>
          <div
            v-if="social_apps.length > 0"
            class="col-md-12 venue-social-links vDJoinusIconWrp"
          >
            <div class="vgps-venue-detail-grid vgps-venue-social-icons-wrapper">
              <h3 class="vgps-venue-detail-heading-white">Join Us</h3>
              <div class="vgps-venue-social-icons venueDetailSSIcons">
                <ul>
                  <template v-for="social in social_apps">
                    <li v-if="social.value" :key="social.id">
                      <a
                        :href="social.value"
                        target="_blank"
                        :class="social.provider"
                        ><img :src="social.icon_url" :alt="social.provider"
                      /></a>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-6 col-sm-4 col-xs-12 vgps-vanue-header-content-wrapper"
        >
          <div class="col-md-12 no-padding-l-r vgps-venue-detail-header">
            <h2>{{ venue.title }}</h2>
            <a
              v-if="isAdministratorAccount()"
              :href="adminEditOptionURL('business', venue.id)"
              target="_blank"
              class="admin-edit-option"
            >
              <i class="fa fa-edit"></i>
            </a>
            <venue_follow_button
              v-bind:id="venue.id"
              v-bind:title="venue.title"
              v-bind:follow="venue.follow"
            ></venue_follow_button>
            <div class="venuHeaderSahreButton">
              <button
                class="btn btn-default vue-social-share"
                id="social-network-share"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-share-alt"></i> Share
              </button>
              <div
                class="dropdown-menu vue-social-share-items"
                aria-labelledby="dropdownMenu2"
              >
                <li class="dropdown-item">
                  <ShareNetwork
                    network="facebook"
                    :url="socialShareLink('venue-detail/' + venue.id)"
                    :title="venue.title"
                  >
                    <i class="fab fah fa-lg fa-facebook"></i>
                    <span>Share on Facebook</span>
                  </ShareNetwork>
                </li>
                <li class="dropdown-item">
                  <ShareNetwork
                    network="twitter"
                    :url="socialShareLink('venue-detail/' + venue.id)"
                    :title="venue.title"
                  >
                    <i class="fab fah fa-lg fa-twitter"></i>
                    <span>Share on Twitter</span>
                  </ShareNetwork>
                </li>
              </div>
            </div>
            <!-- end of share button wrapper -->
            <span style="margin-left: 12px;" class="btns follow-btn-component notfollow">Followers: {{ venue.favourites_count }}</span>
          </div>
          <div class="col-md-10 no-padding-l-r">
            <div
              v-if="
                isTemporaryClosed(venue.venue_closing_reason.closing_reason) &&
                venue.working_days.length > 0 &&
                !isNonVenue(venue.business_type_code)
              "
              class="vgps-venue-detail-grid vgps-venue-schedule-wrapper"
            >
              <h3 class="vgps-venue-detail-heading-white">
                Full week schedule
              </h3>
              <div class="vgps-venue-schedule" style="width: 70%;">
                <ul>
                  <li v-for="(time, index) in venue.working_days" :key="index">
                    <div class="vgps-schedule-day">{{ time.day }}</div>
                    <div class="vgps-schedule-time">
                      <template v-if="time.theme == 'Open'">
                        {{ time.start_time + " - " + time.end_time }}
                      </template>
                      <template v-else>{{ time.theme }}</template>
                    </div>
                    <div class="evenTodayStatus" v-if="time.status.length > 0 && time.theme !='Closed'">
                      <span > {{ time.status }} </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="isNotNormal(venue.venue_closing_reason.title)"
              class="vgps-venue-schedule-wrapper reason-list vHVenueAidWrp"
            >
              <h3 class="vgps-venue-detail-heading-white">Venue AID</h3>
              <div class="vgps-venue-schedule">
                <div class="row venue-aid-text">
                  <div class="col-md-3">Status:</div>
                  <div class="col-md-9">
                    {{ venue.venue_closing_reason.title }}
                  </div>
                </div>
                <div
                  v-if="venue.venue_closing_reason.severity.description"
                  class="row venue-aid-text"
                >
                  <div class="col-md-3">Severity:</div>
                  <div class="col-md-9">
                    {{ venue.venue_closing_reason.severity.description }}
                  </div>
                </div>
                <div
                  v-if="venue.venue_closing_reason.duration.time_duration"
                  class="row venue-aid-text"
                >
                  <div class="col-md-3">Duration:</div>
                  <div class="col-md-9">
                    {{ venue.venue_closing_reason.duration.time_duration }}
                  </div>
                </div>
              </div>
            </div> 
          </div>

          <div  v-if="venue.genre.length > 0" class="vgps-venue-schedule-wrapper venue-genre-wrapper reason-list vHVenueAidWrp">
            <div class="row">
                <div class="col-md-12">
                  <div class=""><h3 class="vgps-venue-detail-heading-white">Music Genre</h3></div>
                  <ul class="venue-genre-list">
                      <li v-for="(item,index) in venue.genre" :key="item.id">{{ item.name }}</li>
                  </ul>
                </div>
            </div>
          </div>
          
        </div>
        
        <div class="vHeaderIconWrap col-lg-3 col-sm-4 col-md-3 col-xs-12">
          
          <div class="VHBtnWrap" v-if="delivery_apps.length > 0">
            <div class="dropdown">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                <span class="btnText"> Online Order </span>
                <!-- <span class="caret"></span> -->
              </button>
              <ul class="dropdown-menu">
                <template v-for="dItem in delivery_apps">
                  <li v-if="dItem.value" :key="dItem.id">
                    <a :href="dItem.value" target="_blank"
                      ><img style="width: 20px;"  :src="dItem.icon_url" :alt="dItem.provider" />
                      <span> {{ dItem.provider }} </span>
                    </a>
                  </li>
                </template>
              </ul>
            </div>
          </div>

          <div class="VHBtnWrap" v-if="music_apps.length > 0">
            <div class="dropdown">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                <span class="btnText"> Media Apps </span>
                <!-- <span class="caret"></span> -->
              </button>
              <ul class="dropdown-menu">
                <template v-for="music in music_apps">
                  <li v-if="music.value" :key="music.id">
                    <a :href="music.value" target="_blank"
                      ><img style="width: 20px;" :src="music.icon_url" :alt="music.provider" />
                      <span> {{ music.provider }} </span>
                    </a>
                  </li>
                </template>
              </ul>
            </div>
          </div>

          <div class="VHBtnWrap" v-if="onlineTaxiApps.length > 0">
            <div class="dropdown">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                <span class="btnText"> Online Taxi </span>
                <!-- <span class="caret"></span> -->
              </button>
              <ul class="dropdown-menu">
                <template v-for="taxi in onlineTaxiApps">
                  <li v-if="taxi.value" :key="taxi.id">
                    <a :href="taxi.value" target="_blank"
                      ><img style="width: 20px;" :src="taxi.icon_url" :alt="taxi.provider" />
                      <span> {{ taxi.provider }} </span>
                    </a>
                  </li>
                </template>
              </ul>
            </div>
          </div>

          <div class="VHBtnWrap" v-if="foodMenuApps.length > 0">
            <div class="dropdown">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                <span class="btnText"> Food Menu </span>
                <!-- <span class="caret"></span> -->
              </button>
              <ul class="dropdown-menu">
                <template v-for="Food in foodMenuApps">
                  <li v-if="Food.value" :key="Food.id">
                    <a :href="Food.value" target="_blank"
                      ><img style="width: 20px;" :src="Food.icon_url" :alt="Food.provider" />
                      <span> {{ Food.provider }} </span>
                    </a>
                  </li>
                </template>
              </ul>
            </div>
          </div>

          <div class="VHBtnWrap" v-if="tableReservationApps.length > 0">
            <div class="dropdown">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                <span class="btnText"> Table Reservation </span>
                <!-- <span class="caret"></span> -->
              </button>
              <ul class="dropdown-menu">
                <template v-for="Reservation in tableReservationApps">
                  <li v-if="Reservation.value" :key="Reservation.id">
                    <a :href="Reservation.value" target="_blank"
                      ><img style="width: 20px;" :src="Reservation.icon_url" :alt="Reservation.provider" />
                      <span> {{ Reservation.provider }} </span>
                    </a>
                  </li>
                </template>
              </ul>
            </div>
          </div>

          <div class="VHBtnWrap" v-if="luxry_servicess_apps.length > 0">
            <div class="dropdown">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                <span class="btnText"> Luxry Services </span>
                <!-- <span class="caret"></span> -->
              </button>
              <ul class="dropdown-menu">
                <template v-for="luxry in luxry_servicess_apps">
                  <li v-if="luxry.value" :key="luxry.id">
                    <a :href="luxry.value" target="_blank"
                      ><img style="width: 20px;" :src="luxry.icon_url" :alt="luxry.provider" />
                      <span> {{ luxry.provider }} </span>
                    </a>
                  </li>
                </template>
              </ul>
            </div>
          </div>

          <div class="VHBtnWrap" v-if="other_social_apps.length > 0">
            <div class="dropdown">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                <span class="btnText"> Social Media Apps</span>
               
              </button>
              <ul class="dropdown-menu">
                <template v-for="app in other_social_apps">
                  <li v-if="app.value" :key="app.id">
                    <a :href="app.value" target="_blank"
                      ><img width="23px" :src="app.icon_url" :alt="app.provider" />
                      <span> {{ app.provider }} </span>
                    </a>
                  </li>
                </template>
              </ul>
            </div>
          </div>

          
        </div>

        <!-- end of vHeaher icon wrapper -->
      </div>
    </div>
  </section>
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";

import VueSilentbox from "vue-silentbox"; //https://vuejsexamples.com/a-lightbox-inspired-vue-js-component/
import VenueDetailFollowButton from "../../components/global/detail-follow-button.vue";

import {
  ContentLoader,
  FacebookLoader,
  CodeLoader,
  BulletListLoader,
  InstagramLoader,
  ListLoader,
} from "vue-content-loader";

export default {
  data() {
    return {
      loadingContent: false,
      directionURL: "",
    };
  },
  computed: {
    iframeUrl: function () {
      return `https://maps.google.com/maps?q=${this.venue.address_details.latitude},${this.venue.address_details.longitude}&hl=es;z=14&output=embed`;
    },
    getVenueAidTitle: function () {
      var title = "";
      if (
        this.venue.venue_closing_reason.severity.description &&
        this.venue.venue_closing_reason.title != "Normal"
      ) {
        title = this.venue.venue_closing_reason.severity.description;
        title += this.venue.venue_closing_reason.duration.time_duration
          ? " | Duration:" +
            this.venue.venue_closing_reason.duration.time_duration
          : "";
      } else {
        title = this.venue.venue_closing_reason.title;
      }
      return title;
    },
    social_apps: function () {
      return this.getSocialApps(this.venue.social_contacts);
    },
    other_social_apps: function () {
      return this.getOtherSocialApps(this.venue.social_contacts);
    },
    music_apps: function () {
      return this.getMusicApps(this.venue.social_contacts);
    },
    delivery_apps: function () {
      return this.getDeliveryApps(this.venue.social_contacts);
    },
    luxry_servicess_apps: function () {
      return this.getLuxryServicessApps(this.venue.social_contacts);
    },
    foodMenuApps: function () {
      return this.getFoodMenuApps(this.venue.social_contacts);
    },
    onlineTaxiApps: function () {
      return this.getOnlineTaxiApps(this.venue.social_contacts);
    },
    tableReservationApps: function () {
      return this.getTableReservationApps(this.venue.social_contacts);
    },
    getDirectionUrl: function () {
      var vm = this;
      // debugger
      var venueLat = this.venue.address_details.latitude;
      var venueLong = this.venue.address_details.longitude;

      //Get GeoLocation location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            // debugger

            var currentLat = position.coords.latitude;
            var currentLong = position.coords.longitude;
            vm.directionURL = `https://www.google.com/maps/dir/?api=1&origin=${currentLat},${currentLong}&destination=${venueLat},${venueLong}&travelmode=`;
            console.log("Event map", vm.directionURL);
            // return directionURL;
          },
          function (error) {
            // debugger
            console.log(error.message);
            // return directionURL
          },
          {
            enableHighAccuracy: true,
            timeout: 10000,
          }
        );
      } else {
        alert("Geolocation is not supported by this browser.");
      }

      return vm.directionURL;
    },
  },
  props: ["venue"],
  mixins: [helpers],
  created() {
    var timezone = Vue.moment().zone();
    console.log("my timezone", timezone);

    let times = [
      {
        day: "Sally's",
        start_time: "08:00",
        end_time: "23:00",
      },
      {
        day: "Fred's",
        start_time: "20:00",
        end_time: "06:00",
      },
    ];

    //it is simulated hour after midnight, but you can try with any time
    let now = new Date();
    // now.setHours(2,0,0,0); // 02:00 AM

    // times.forEach((time,any) => {
    this.venue.working_days.forEach((time, index) => {
      let openTime = Vue.moment(time.start_time, "HH:mm");
      let closeTime = Vue.moment(time.end_time, "HH:mm");
      let todayDay = Vue.moment(now).format("dddd");
      if (openTime.isBefore(closeTime)) {
        //normal case
        let isOpen = Vue.moment(now).isBetween(openTime, closeTime, null, "[]");

        if (isOpen && todayDay == time.day) {
          this.venue.working_days[index].status = " Open Now";
          // this.venue.working_days[index].isOpen = true;
        } else if (!isOpen && todayDay == time.day) {
          this.venue.working_days[index].status = " Closed Now";

        } else {
          this.venue.working_days[index].status = "";

        }

      } else {
        /**
         * First: Note how open and close times switched places
         * Second: the ! in the boolean. If the time is between close and open time, it means the opposite, which is out of range.
         */
        let isOpen = !Vue.moment(now).isBetween(
          closeTime,
          openTime,
          null,
          "[]"
        );

        if (isOpen && todayDay == time.day) {
          this.venue.working_days[index].status = "Open Now";
        } else if (!isOpen && todayDay == time.day) {
          this.venue.working_days[index].status = "Closed Now";
        } else {
          this.venue.working_days[index].status = "";
        }

      }
    });
  },
  components: {
    ContentLoader,
    FacebookLoader,
    VueSilentbox,
    venue_follow_button: VenueDetailFollowButton,
  },
  methods: {
    directoryfullAddress: function (venue) {
      var address = "";
      address +=
        venue.address_details.street && venue.address_details.street != "NULL"
          ? venue.address_details.street + ",<br>"
          : "";
      address +=
        venue.address_details.full_address &&
        venue.address_details.full_address != "NULL"
          ? venue.address_details.full_address
          : "";
      return address;
    },
    getImageUrl(isLike) {
      var path = window.axios.defaults.baseURL + "/images/";
      return isLike === true ? path + "fav-w.svg" : path + "fav.svg";
    },
  },
  filters: {
    checkNullSeverity: function (severity) {
      var str = "V-AID";
      if (severity.level != "" && severity.level != null) {
        str += ": " + severity;
      }
      return str;
    },
    assetPath: function (img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
    url: function (path) {
      var path = window.axios.defaults.baseURL + "/" + path;
      return path;
    },
  },
};
</script>
