<template>
  <div class="sidebar-filters-wrapper sPLeftSideWrap">
    <div class="wrapper center-block">
      <vue-sticky-sidebar
        class="sidebar"
        containerSelector="#search-content-area"
        :topSpacing="topSpacing1"
        :bottomSpacing="bottomSpacing"
        @affixed-unbottom="closeBottom('affixed-unbottom')"
        @affixed-bottom="closeBottom('affixed-bottom')"
        @affixed-container-bottom="closeBottom('affixed-container-bottom')"
        stickyClass="is-fixed-sidebar"
        innerWrapperSelector=".sidebar__inner"
      >
        <div class="form-group">
          <ul class="search-nav-list">
            <li class="item">
                <router-link
                  class="btn btn-default btn-grey"
                  :to="{ name: 'home'}"
                >Home</router-link
                >
            </li>
            <li class="item">
                <a href="javascript:void(0)" @click="openCustomDateMenu()" class="btn btn-default btn-grey">Date</a>
            </li>
            <li class="item">
                <a
                  class="btn more-filters btn-default btn-grey router-link-active"
                  data-toggle="collapse"
                  data-target="#accordion"
                  href="javascript:void(0)"
                  aria-expanded="false">Filters</a>
            </li>
          </ul>
        </div>
        <div class="search-input form-group sPSearchField">
          <div class="input-group">
            <input
              type="text"
              @keyup="searchTimeOut()"
              class="form-control inputSearchField width101"
              v-model="search_term"
              placeholder="Search"
            />
            <div class="input-group-btn">
              <button
                style="background: #673ab7 !important;"
                class="btn btn-default hidden-lg sPMobileFiltIcon"
                data-toggle="collapse"
                data-target="#accordion"
                type="submit"
                aria-expanded="false">
                <img :src="'icons/filter-icon.svg' | assetPath" alt="More Filter" />
                <span v-if="getMainFilterCount && getMainFilterCount > 0" class="main-filter-count badge badge-default">{{getMainFilterCount}}</span>

              </button>
            </div>
          </div>
        </div>

        <div
          class="panel-group collapse visible-lg filterTreeView"
          id="accordion"
          role="tablist"
          aria-multiselectable="true"
          aria-expanded="false"
        >
          

          <!---Event Sub Categories ---->
          <div
            v-if="getFilteredCategories.length > 0"
            class="panel panel-default"
          >
            <div
              class="panel-heading sPCategoryWrap"
              role="tab"
              id="headingOne"
              :class="[search_main_category ? 'selected-item' : '']"
            >
              <a
                role="button"
                data-toggle="collapse"
                href="#collapseOne"
                class="togalFilter sPCategoryHead"
              >
                <span class="filterCtegory">
                  Category
                  <span class="sPFilterCount">
                    ({{ mainCategoriesTotalCount }})
                  </span>
                </span>
                <span class="pull-right light-grey sPFilterArrow"
                  ><i class="fas fa-angle-down"></i
                ></span>
              </a>
              <h5 class="panel-title" v-if="search_main_category">
                <template>
                  <span class="sPselectedItem">
                    {{ search_main_category === 'NightLife' ? 'Dine & Drink' : (search_main_category === 'Media' ? 'Attractions' : search_main_category) }}
                    
                    <span
                      @click="clearFillter('search_main_category')"
                      class="pull-right"
                    >
                      <span v-if="search_main_category != 'All'">
                        <i class="fa fa-times"></i>
                      </span>
                    </span>
                  </span>
                </template>
              </h5>
            </div>
            <div id="collapseOne" class="panel-collapse collapse in">
              <div class="panel-body">
                <ul class="list-group list-group-flush">
                  <li
                    v-for="(item, index) in getFilteredCategories"
                    :key="index"
                    :class="[ highlightAllCategory(item)? 'active-category': '', search_main_category == item.type_n?'selected-main-caegory':'' ]"
                    class="list-group-item"
                  >
                    <div class="form-group vgps-custom-checkbox-wrapper">
                      <label class="fancyRadioWrapper" :for="item.name">
                        {{ item.name | replaceCategoryName }}
                        <input
                          type="radio"
                          v-model="search_main_category"
                          @change="advanceFilter('category_search')"
                          :value="item.type_n"
                          name="search_main_category"
                          :id="item.name"
                        />
                        <span class="checkmark"></span>

                        <span v-if="notCity(item)" class="item-count badge">{{
                          item.count
                        }}</span>
                        <span v-else class="item-count badge">{{
                          item.city_count
                        }}</span>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            v-if="
              typeof search_user_filters.business_category != 'undefined' &&
              search_user_filters.business_category.length > 0
            "
            class="panel panel-default"
          >
            <div
              class="panel-heading sPCategoryWrap"
              role="tab"
              id="top-hash"
              :class="[search_business_sub_type ? 'selected-item' : '']"
            >
              <a
                role="button"
                data-toggle="collapse"
                href="#business-category"
                aria-expanded="true"
                aria-controls="collapseOne"
                class="togalFilter collapsed sPCategoryHead"
              >
                <span class="filterCtegory">
                  Venues
                  <span class="sPFilterCount">
                    ({{ businessTypesTotalCount }})
                  </span>
                </span>
                <span class="pull-right light-grey sPFilterArrow"
                  ><i class="fas fa-angle-down"></i
                ></span>
              </a>
              <h5 class="panel-title" v-if="search_business_sub_type">
                <template>
                  <span class="sPselectedItem">
                    {{ search_business_sub_type }}
                    <span
                      @click="clearFillter('search_business_sub_type')"
                      class="pull-right"
                    >
                      <i class="fa fa-times"></i
                    ></span>
                  </span>
                </template>
              </h5>
           
            </div>
            <div id="business-category" class="panel-collapse collapse">
              <div class="panel-body">
                <business_categories
                  v-bind:filters="filters"
                ></business_categories>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div
              class="panel-heading sPCategoryWrap"
              role="tab"
              id="headingThree"
              :class="[
                search_date_range.length > 0 && search_date_range != 'custom'
                  ? 'selected-item'
                  : '',
              ]"
            >
              <a
                class="collapsed togalFilter sPCategoryHead date-filter-tab"
                role="button"
                data-toggle="collapse"
                href="#dates"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                <span class="filterCtegory"> Date </span>
                <span class="pull-right light-grey sPFilterArrow"
                  ><i class="fas fa-angle-down"></i
                ></span>
              </a>
              <div
                class=""
                v-if="
                  search_date_range.length > 0 && search_date_range != 'custom'
                "
              >
                <template>
                  <ul class="selected-amenities-list sPFilterList">
                    <template
                      v-if="
                        isArray(search_date_range) &&
                        search_date_range != 'custom'
                      "
                    >
                      <li
                        v-for="(item, index) in search_date_range"
                        :key="index"
                        class="selected-amenities panel-title"
                      >
                        <span class="sPselectedItem">
                          {{ item | moment("MM-DD-YYYY") }}
                          <span
                            @click="clearFillter('search_date_range')"
                            class="pull-right pLeft5"
                          >
                            <i class="fa fa-times"></i
                          ></span>
                        </span>
                      </li>
                    </template>
                    <template
                      v-if="
                        !isArray(search_date_range) &&
                        search_date_range != 'custom'
                      "
                    >
                      <li class="panel-title">
                        <span class="sPselectedItem">
                          {{ search_date_range }}
                        </span>
                        <span
                          @click="clearFillter('search_date_range')"
                          class="pull-right pLeft5 pRight5 sPDateFilterCross"
                        >
                          <i class="fa fa-times"></i
                        ></span>
                      </li>
                    </template>
                  </ul>
                </template>
              </div>
            </div>
            <div id="dates" class="panel-collapse collapse">
              <div class="panel-body">
                <ul class="list-group list-group-flush">
                  <li
                    v-for="(item, index) in event_dates"
                    :key="index"
                    class="list-group-item"
                  >
                    <div class="form-group vgps-custom-checkbox-wrapper">
                      <label class="fancyRadioWrapper" :for="item.id">
                        {{ item.name }}
                        <input
                          type="radio"
                          v-model="search_date_range"
                          @change="advanceFilter('date_type')"
                          :value="item.id"
                          name="dateranges"
                          :id="item.id"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </li>
                </ul>
                <div v-if="search_date_range == 'custom' || isArray(search_date_range)" class="form-group">
                  <date-picker
                    v-model="search_date_range"
                    value-type="YYYY-MM-DD"
                    format="MMM DD"
                    input-class="form-control"
                    :disabled-date="notBeforeToday"
                    range
                    @change="advanceFilter('date_search')"
                    placeholder="Select Date"
                    :input-attr="{
                      name: 'daterange',
                      id: 'event-search-dateranges',
                    }"
                  ></date-picker>
                </div>
              </div>
            </div>
          </div>
          <div v-if="getFilteredThemes.length > 0" class="panel panel-default">
            <div
              class="panel-heading sPCategoryWrap"
              role="tab"
              id="headingTwo"
              :class="[search_event_theme.length > 0 ? 'selected-item' : '']"
            >
              <a
                class="collapsed togalFilter sPCategoryHead"
                role="button"
                data-toggle="collapse"
                href="#event-theme"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <span class="filterCtegory">
                  Hashtags
                  <span class="sPFilterCount">
                    ({{ hashThemesTotalCount }})
                  </span>
                </span>
                <span class="pull-right light-grey sPFilterArrow"
                  ><i class="fas fa-angle-down"></i
                ></span>
              </a>
              <!-- if some items is selected and less than two -->
              <div
                v-if="
                  search_event_theme.length > 0 && search_event_theme.length < 2
                "
              >
                <template v-for="(item, index) in search_event_theme">
                  <ul class="selected-amenities-list sPFilterList">
                    <li :key="index" class="selected-amenities panel-title">
                      <span
                        class="sPselectedItem"
                        :class="{ HashTagLength: item.length > 20 }"
                      >
                        {{ item }}
                        <span
                          @click="clearThemeFillter(index)"
                          class="pull-right pLeft5"
                        >
                          <i class="fa fa-times"></i
                        ></span>
                      </span>
                    </li>
                  </ul>
                </template>
              </div>

              <!-- check if selected items are more than two than show only count -->
              <div v-else-if="search_event_theme.length >= 2">
                <template v-for="(item, index) in search_event_theme">
                  <span style="display: none"> {{ (numIndex = index) }} </span>
                </template>
                <span class="sPFitlerCount">
                  <i class="fas fa-filter"></i>
                  <span class="countIndex">
                    {{ numIndex + 1 }}
                  </span>
                </span>
              </div>
            </div>
            <div id="event-theme" class="panel-collapse collapse">
              <div class="panel-body">
                <event_theme v-bind:filters="filters"></event_theme>
              </div>
            </div>
          </div>
          
          <div
            v-if="getFilteredAmenities.length > 0"
            class="panel panel-default sPAmentitiesWrap"
          >
            <div
              class="panel-heading sPCategoryWrap"
              role="tab"
              id="headingThree"
              :class="[
                search_event_amenities.length > 0 ? 'selected-item' : '',
              ]"
            >
              <a
                class="collapsed togalFilter sPCategoryHead"
                role="button"
                data-toggle="collapse"
                href="#event-amenities"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                <span class="filterCtegory">
                  Amenities
                  <span class="sPFilterCount">
                    ({{ amenitiesTotalCount }})
                  </span>
                </span>
                <span class="pull-right light-grey sPFilterArrow"
                  ><i class="fas fa-angle-down"></i>
                </span>
              </a>
              <div
                class=""
                v-if="
                  search_event_amenities.length > 0 &&
                  search_event_amenities.length < 2
                "
              >
                <template>
                  <ul class="selected-amenities-list sPFilterList">
                    <li
                      v-for="(item, index) in search_event_amenities"
                      :key="index"
                      class="selected-amenities panel-title"
                    >
                      <span
                        class="sPselectedItem"
                        :class="{ HashTagLength: item.length > 20 }"
                      >
                        {{ item }}
                        <span
                          @click="clearAmentiesFillter(index)"
                          class="pull-right pLeft5"
                        >
                          <i class="fa fa-times"></i
                        ></span>
                      </span>
                    </li>
                  </ul>
                </template>

               
              </div>
              <!-- check if selected items are more than two than show only count -->
              <div v-else-if="search_event_amenities.length >= 2">
                <template v-for="(item, index) in search_event_amenities">
                  <span style="display: none"> {{ (numIndex = index) }} </span>
                </template>
                <span class="sPFitlerCount">
                  <i class="fas fa-filter"></i>
                  <span class="countIndex">
                    {{ numIndex + 1 }}
                  </span>
                </span>
              </div>
            </div>
            <div id="event-amenities" class="panel-collapse collapse">
              <div class="panel-body">
                <event_amenities v-bind:filters="filters"></event_amenities>
              </div>
            </div>
          </div>
          <div
            v-if="getFilteredCuisines.length > 0"
            class="panel panel-default cuisinesCategoryFilter"
          >
            <div
              class="panel-heading sPCategoryWrap"
              role="tab"
              id="headingThree"
              :class="[search_event_cuisines.length > 0 ? 'selected-item' : '']"
            >
              <a
                class="collapsed togalFilter sPCategoryHead"
                role="button"
                data-toggle="collapse"
                href="#event-cuisines"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                <span class="filterCtegory">
                  Food Cuisines
                  <span class="sPFilterCount">
                    ({{ cuisinesTotalCount }})
                  </span>
                </span>
                <span class="pull-right light-grey sPFilterArrow"
                  ><i class="fas fa-angle-down"></i
                ></span>
              </a>
              <div
                class=""
                v-if="
                  search_event_cuisines.length > 0 &&
                  search_event_cuisines.length < 2
                "
              >
                <template>
                  
                  <ul class="selected-amenities-list sPFilterList">
                    <li
                      v-for="(item, index) in search_event_cuisines"
                      :key="index" class="selected-amenities panel-title">
                      <span
                        class="sPselectedItem"
                        :class="{ HashTagLength: item.length > 2 }"
                      >
                        {{ item }}

                        <span
                          @click="clearCuisinesFillter(index)"
                          class="pull-right pLeft5"
                        >
                          <i class="fa fa-times"></i
                        ></span>
                      </span>
                    </li>
                  </ul>
                </template>

                <!-- Amenities <span class="pull-right light-grey"><i class="fas fa-angle-down"></i></span> -->
              </div>
              <!-- check if selected items are more than two than show only count -->
              <div v-else-if="search_event_cuisines.length >= 2">
                <template v-for="(item, index) in search_event_cuisines">
                  <span style="display: none"> {{ (numIndex = index) }} </span>
                </template>
                <span class="sPFitlerCount">
                  <i class="fas fa-filter"></i>
                  <span class="countIndex">
                    {{ numIndex + 1 }}
                  </span>
                </span>
              </div>
              <!-- <template v-else>
               
              </template> -->
            </div>
            <div id="event-cuisines" class="panel-collapse collapse">
              <div class="panel-body">
                <event_cuisines v-bind:filters="filters"></event_cuisines>
              </div>
            </div>
          </div>
          <div class="closeSearchFilter">
            <i class="glyphicon glyphicon-menu-up"></i> Apply
          </div>
        </div>
      </vue-sticky-sidebar>
    </div>
  </div>
</template>

<script>
import { bus } from "../../app";
import helpers from "./../../mixin/helpers";
import { ContentLoader } from "vue-content-loader";
import Autocomplete from "./../../components/global/all-search.vue";
import BusinessCategories from "./business-category";
import EventTheme from "./theme";
import EventGenre from "./event-genre";
import EventCuisines from "./event-cuisines";
import EventAmenities from "./event-amenities";

import carousel from "vue-owl-carousel2";
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { mapFields } from "vuex-map-fields";
import vueStickySidebar from "vue-sticky-sidebar"; 
//https://blixhavn.github.io/sticky-sidebar-v2/ | https://renatodeleao.github.io/vue-sticky-directive/

export default {
  data() {
    return {
      loadingContent: true,
      loaderSpeed: 1,
      topSpacing1: 75,
      bottomSpacing: 75,
      banners: [],
      dateRange: "",
      isMobileView: false,
      categories: [
        { id: "ceo", name: "Top Trending", count: 23 },
        { id: "all", name: "All Categories", count: 23 },
        { id: "NightLife", name: "Night Life", count: 23 },
        { id: "Entertainment", name: "Entertainment", count: 23 },
        { id: "National", name: "National", count: 23 },
        { id: "Festival", name: "Festival", count: 34 },
        { id: "Tour", name: "Tour", count: 100 },
        { id: "MS/SC/CC", name: "MS/SC/CC", count: 34 },
        { id: "Sports", name: "Sports", count: 589 },
      ],
      top_hash: [
        { id: "#AfterParty", name: "#AfterParty", count: 23 },
        { id: "#BlackBikeWeek", name: "#BlackBikeWeek", count: 23 },
        { id: "#BlackFriday", name: "#BlackFriday", count: 23 },
        { id: "#Boxing", name: "#Boxing", count: 23 },
        { id: "#Brunch", name: "#Brunch", count: 23 },
      ],
      event_dates: [
        { id: "today", name: "Today Themes", count: 23 },
        { id: "week", name: "This Week", count: 23 },
        { id: "two_weeks", name: "In Two Weeks", count: 23 },
        { id: "month", name: "Within a Month", count: 237 },
        { id: "custom", name: "Custom Dates", count: 23 },
      ],
      filters: {
        location: "",
        state_code: "",
        business_type: "",
        search: "",
        category: "",
        dates: {
          start: "",
          end: "",
        },
      },
      leaderSpeed: 1,
      toggleClass: false,
      activeItem: "category",
    };
  },
  mixins: [helpers],
  components: {
    Slick,
    carousel,
    ContentLoader,
    autocomplete: Autocomplete,
    business_categories: BusinessCategories,
    event_theme: EventTheme,
    event_genre: EventGenre,
    event_amenities: EventAmenities,
    event_cuisines: EventCuisines,
    "vue-sticky-sidebar": vueStickySidebar,
  },
  watch: {
    search_term: function (newVal, oldVal) {
      let queries = this.$route.query;
      queries.search_term = newVal;
      let queryStr = { name: "search", query: queries };
      this.$router.replace(queryStr).catch(() => {});
    },
  },
  computed: {
    getFilteredThemes() {
      return this.$store.getters.getFilteredThemes;
    },
    businessTypesTotalCount() {
      return this.$store.getters.businessTypesTotalCount;
    },
    hashThemesTotalCount() {
      return this.$store.getters.hashThemesTotalCount;
    },
    amenitiesTotalCount() {
      return this.$store.getters.amenitiesTotalCount;
    },
    cuisinesTotalCount() {
      return this.$store.getters.cuisinesTotalCount;
    },
    mainCategoriesTotalCount() {
      return this.$store.getters.mainCategoriesTotalCount;
    },
    getFilteredCategories() {
      return this.$store.getters.getFilteredCategories;
    },
    getFilteredAmenities() {
      return this.$store.getters.getFilteredAmenities;
    },
    getFilteredCuisines() {
      return this.$store.getters.getFilteredCuisines;
    },
    getMainFilterCount() {
      let vm = this;
      let count = 0;
      count = vm.search_sub_category? count+1: count + 0 
      count = vm.search_top_hash.length > 0? count + vm.search_top_hash.length : count + 0
      count = vm.search_business_sub_type ? count + 1 : count + 0
      count = vm.search_date_range ? count + 1 : count + 0
      count = vm.search_event_theme.length ? count + vm.search_event_theme.length : count + 0
      count = vm.search_event_genre.length ? count + vm.search_event_genre.length : count + 0
      count = vm.search_event_amenities.length ? count + vm.search_event_amenities.length : count + 0
      count = vm.search_event_cuisines.length ? count + vm.search_event_cuisines.length : count + 0
      return count
    },
    ...mapFields([
      "search_term",
      "search_sub_category",
      "search_main_category",
      "search_top_hash",
      "search_date_range",
      "search_hash_listing",
      "search_user_filters",
      "search_business_sub_type",
      "search_event_theme",
      "search_event_genre",
      "search_event_amenities",
      "search_event_cuisines",
      "isSearching",
    ]),
    getFilteredCount() {
      var filteredItemsCount = [];
      var fieldsToGetValues = ["search_event_genre", "search_main_category"];
      
      var searEvent = this.search_event_genre;
      var mainCagtegory = this.search_main_category;

      let x = this.fieldsToGetValues;

      return filteredItemsCount;
    },
  },
  mounted() {
    this.loadingContent = true;
     // Check if it's mobile view (max-width: 767px)
     this.isMobileView = window.innerWidth <= 767;
    
    // Automatically show the filter panel if in mobile view
    if (this.isMobileView) {
      this.showFilterPanel();
    }
  },
  methods: {
    showFilterPanel() {
      const filterElement = document.querySelector('#accordion');
      if (filterElement && !filterElement.classList.contains('in')) {
        // Trigger Bootstrap collapse 'show' class
        $('#accordion').collapse('show');
      }
    },
    closeBottom: function () {
      console.log("close bottom");
    },
    openCustomDateMenu: function () {
      this.search_date_range = 'custom'
      $('.date-filter-tab').trigger('click')
    },
    
    clearThemeFillter: function (index) {
      this.search_event_theme.splice(index, 1);
      bus.$emit("clear_search_page_filter_emit");
    },
    clearAmentiesFillter: function (index) {
      this.search_event_amenities.splice(index, 1);
      bus.$emit("clear_search_page_filter_emit");
    },
    clearCuisinesFillter: function (index) {
      this.search_event_cuisines.splice(index, 1);
      bus.$emit("clear_search_page_filter_emit");
    },
    clearFillter: function (key) {
      var vm = this;
      switch (key) {
        case "search_term":
          vm.search_term = "";
          break;
        case "search_main_category":
          vm.search_main_category = "All";
          break;
        case "search_sub_category":
          vm.search_sub_category = "";
          break;
        case "search_top_hash":
          vm.search_top_hash = "";
          break;
        case "search_date_range":
          vm.search_date_range = "";
          break;
        case "search_business_type":
          vm.search_business_type = "";
          break;
        case "search_business_sub_type":
          vm.search_business_sub_type = "";
          break;
        case "search_event_theme":
          vm.search_event_theme = [];
          break;
        case "search_event_genre":
          vm.search_event_genre = "";
          break;
        case "search_event_amenities":
          vm.search_event_amenities = [];
          break;
        case "search_event_cuisines":
          vm.search_event_cuisines = [];
          break;
        case "reset":
          vm.search_term = "";
          vm.search_sub_category = "";
          vm.search_top_hash = "";
          vm.search_date_range = "";
          vm.search_business_type = "";
          vm.search_business_sub_type = "";
          vm.search_event_theme = [];
          vm.search_event_genre = "";
          vm.search_event_amenities = [];
          vm.search_event_cuisines = [];
          break;
        default:
          vm.search_term = "";
          vm.search_main_category = "Trending";
          vm.search_sub_category = "";
          vm.search_top_hash = "";
          vm.search_date_range = "";
          vm.search_business_type = "";
          vm.search_business_sub_type = "";
          vm.search_event_theme = [];
          vm.search_event_genre = "";
          vm.search_event_amenities = [];
          vm.search_event_cuisines = [];
          break;
      }
      bus.$emit("clear_search_page_filter_emit");
    },
    advanceFilter: function (type) {
      if (type && type == "date_type" && this.search_date_range == "custom") {
        return false;
      }

      if (type == "category_search") {
        // this.search_business_sub_type = "";

        if (this.search_main_category == "Trending") {
          // this.clearFillter();
        }
        this.clearFillter('reset');

      } else if (type == "sub_category_search") {
       
        
      } else {
      }

      bus.$emit("search_filter_emit");
    },
    setActive: function (menuItem) {
      this.activeItem = menuItem; // no need for Vue.set()
      this.toggleClass = !this.toggleClass;
    },
    isActive: function (menuItem) {
      return this.activeItem === menuItem;
    },
    highlightAllCategory: function (object) {
      var arrData = ['All','Entertainment','MC/SC/CC','Media','NightLife','Sports'];
      return this.search_main_category == 'All' && arrData.includes(object.type_n)?true:false;
    },
    filtetCountTotal: function () {},
  },
  filters: {
    assetPath: function (img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
    replaceCategoryName: function (cat) {
      switch (cat) {
        case "Festival":
          return "Festival (All States)";
          break;
        case "National":
          return "National (All States)";
          break;
        case "Trending":
          return "Trending (Global)";
          break;
        default:
          return cat;
          break;
      }
    },
  },
};
</script>