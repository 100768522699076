<template>
  <div>
    <Select2
      name="duration"
      id="duration"
      v-model="duration"
      @change="showDates"
      required
      placeholder="Select One"
      :options="bannerType"
      :settings="{placeholder:'Select One',selectOnClose: false,allowClear: true,closeOnSelect: true}"
    />
  </div>
</template>
<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";
import Select2 from "v-select2-component";

export default {
  props: ["duration"],
  mixins: [helpers],
  watch: {},
  created() {
    // this.duration = this.duration ? this.duration : "";
    this.showDates();
  },
  computed: {
    bannerType() {
      return this.$store.getters.bannerType;
    }
  },
  components: {
    Select2
  },
  data: function() {
    return {
      city_id: ""
    };
  },
  methods: {
    showDates:function(data){

      this.$store.state.banner_display_type  = this.duration == 'duration'? true:false;
        // bus.$emit("hashtag", category, hashTag, type);
    },
    // selectDataRange: function(event) {
    //     var vm = this;
    // },
  }
};
</script>
