<template>
  <div>
    <header_section></header_section>
    <main class="top-inner-pages">
      <!-- <section class="headerSectionWrapper">
        <div class="headerBGImage">
          <img :src="'banners/vgps-min.png' | assetPath" alt="header Image" />
        </div>
      </section> -->
      <section class="dashboard vgps_contact_support_wrapper">
        <div class="container">
          <div class="row">
            <div class="col-md-12 vgps_page_header_wrapper">
              <h2>Contact Us</h2>
              <p>How could we improve VenueGPS? We'd love to hear your ideas: contact@venuegps.com</p>
            </div>
            <div class="col-md-3">
                <information_sidebar></information_sidebar>
            </div>
            <div class="col-md-9">
              <div class="right-box">
                <div class="vgps_contact_form_header">
                  <h5>Contact Support</h5>
                  <p>Need assistance? Our friendly staff is here to help.</p>
                </div>
                <form id="contact-form" class="form" action="#" method="POST">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <div class="floating-label-wrap">
                          <input
                            type="text"
                            v-model="title"
                            name="title"
                            required
                            maxlength="65"
                            class="floating-label-field floating-label-field--s3 form-control"
                            id="title"
                            placeholder="Name"
                          />
                          <label for="title" class="floating-label">Name</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <div class="floating-label-wrap">
                          <input
                            type="email"
                            name="email"
                            v-model="email"
                            required
                            class="floating-label-field floating-label-field--s3 form-control"
                            id="email"
                            placeholder="Email"
                          />
                          <label for="email" class="floating-label">Email</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <div class="floating-label-wrap">
                          <input
                            type="tel"
                            v-model="contact"
                            name="contact"
                            required
                            maxlength="20"
                            class="floating-label-field floating-label-field--s3 form-control"
                            id="contact"
                            placeholder="Phone Number"
                          />
                          <label for="contact" class="floating-label">Phone Number</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <div class="floating-label-wrap">
                          <input
                            type="text"
                            name="company"
                            required
                            maxlength="100"
                            class="floating-label-field floating-label-field--s3 form-control"
                            id="company"
                            v-model="company"
                            placeholder="Company"
                          />
                          <label for="company" class="floating-label">Company</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <div class="floating-label-wrap">
                          <input
                            type="text"
                            v-model="location"
                            name="location"
                            maxlength="150"
                            class="floating-label-field floating-label-field--s3 form-control"
                            id="location"
                            placeholder="Location"
                          />
                          <label for="location" class="floating-label">Location</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <div class="floating-label-wrap">
                          <select
                            name="subject"
                            v-model="subject"
                            required
                            class="floating-label-field form-control floating-label-field--s3 form-control"
                          >
                            <option value selected>Select Subject</option>
                            <option value="853">Feedback</option>
                            <option value="854">Complaint</option>
                          </select>
                          <label for="message" class="floating-label">Subject</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="floating-label-wrap">
                          <textarea
                            class="floating-input floating-textarea form-control md-textarea"
                            name="message"
                            v-model="message"
                            required
                            type="textarea"
                            rows="2"
                            maxlength="500"
                            placeholder="Message"
                          ></textarea>
                          <span class="highlight"></span>
                          <label for="Message" class="floating-label">Message</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group">
                      <div class="col-md-12">
                        <!-- <button type="submit" class="btn business-btn">Submit</button> -->
                        <a
                          @click="save"
                          href="javascript:void(0)"
                          value="save"
                          class="btn save-btn"
                        >
                          <span class="login-show-spinner" :class="{active : processing == true }">
                            <i class="fa fa-spinner fa-spin"></i>
                          </span> Submit
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer_section></footer_section>
  </div>
</template>
<script>
import { bus } from "./app";
import HeaderSection from "./../views/partials/header";
import Information_Sidebar from './../views/partials/information-sidebar'
import FooterSection from "./../views/partials/footer";
import helpers from "./../mixin/helpers";

export default {
  data() {
    return {
      loadingContent: false,
      processing: false,
      title: "",
      contact: "",
      company: "",
      email: "",
      location: "",
      subject: "",
      message: "",
    };
  },
  mixins: [helpers],
  components: {
    header_section: HeaderSection,
    footer_section: FooterSection,
    information_sidebar: Information_Sidebar
  },
  methods: {
    save: function () {
      var vm = this;
      var data = new FormData();

      data.append("title", this.title);
      data.append("email", this.email);
      data.append("contact", this.contact);
      data.append("company", this.company);
      data.append("location", this.location);
      data.append("subject", this.subject);
      data.append("message", this.message);

      var validate = $("#contact-form").validate();
      validate.form();
      if (validate.errorList.length > 0) {
        sweetalert2.fire("", "please fill the required fields.", "fail");
        return false;
      }

      if (vm.processing === true) {
        return false;
      }
      vm.processing = true;

      axios
        .post("contact-support", data)
        .then(function (response) {
          debugger
          if (response.data.status_code == 201) {
            
            // sweetalert2.fire("", response.data.description);
            sweetalert2.fire("", response.data.description, "success");

            vm.title = "";
            vm.email = "";
            vm.contact = "";
            vm.company = "";
            vm.location = "";
            vm.subject = "";
            vm.message = "";
          } else {
            var messages = "";
            if (typeof response.data.exceptions.errors != "undefined") {
              messages += '<ul class="error-list">';
              $.each(response.data.exceptions.errors, function (index, obj) {
                messages += "<li>" + obj.message + "</li>";
              });
              messages += "</ul>";
            } else {
              messages = response.data.description;
            }

            sweetalert2.fire({
              icon: "error",
              title: "Something went wrong",
              html: messages,
            });
          }

          vm.processing = false;
        })
        .catch(function (errors) {
          debugger
          console.log("Contact exception", errors);
          sweetalert2.fire("", "Something went wrong!", "fail");
          vm.processing = false;
        });
    },
  },
};
</script>