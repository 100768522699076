<template>
  <a
    :class="[event.follow ? 'follow' : 'notfollow']"
    class="follow-btn-component"
    href="javascript:void(0)"
    @click="followEvent(event, venueid)"
    @contextmenu="blockRightClick($event)"
  >
    <i v-if="processing" class="fa fa-circle-o-notch fa-spin"></i>
    <img width="20px" v-if="!processing" :src="getImageUrl(event.follow)" />
    <!-- <span class="xs-follow-text-hide">Follow</span> -->
  </a>
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";
import {
  ContentLoader,
  FacebookLoader,
  CodeLoader,
  BulletListLoader,
  InstagramLoader,
  ListLoader,
} from "vue-content-loader";

export default {
  props: ["events", "event", "index"],
  watch: {},
  mixins: [helpers],
  components: {},
  computed: {},
  data: function () {
    return {
      like: false,
      processing: false,
    };
  },
  methods: {
    followEvent: function (event, venueid) {
      var vm = this;
      if (typeof vm.$session.get("access_token") == "undefined") {
        $("#loginModal").modal("show");
        return false;
      }

      if(!vm.hasSubscribedPlan()){
        sweetalert2.fire({
            icon: 'info',
            title: 'Upgrade Subscription Plan',
            text: 'Please upgrade your subscription plan to like and follow event.'
        })
        return false;
      }

      var follow = event.follow == true ? false : true;
      var contentType = "event";

      var id = typeof event.id != "undefined" ? event.id : event.event_id;

      var data = new FormData();
      data.append("content_id", id);
      data.append("content_type", contentType);
      data.append("favourite", follow);

      if (vm.processing === true) {
        return false;
      }

      vm.processing = true;
      axios
        .post("data/markFavourite", data)
        .then(function (response) {
          vm.processing = false;
          vm.events[vm.index].follow = follow;
        })
        .catch(function (errors) {
          vm.processing = false;
        });
    },
    getImageUrl(isLike) {
      var path = window.axios.defaults.baseURL + "/images/";
      return isLike === true ? path + "fav-w.svg" : path + "fav.svg";
    },
    blockRightClick: function (e) {
      e.preventDefault();
    },
  },

  filters: {
    assetPath: function (img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
  },
};
</script>
