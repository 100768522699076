<template>
    <div>
    <div class="filter-content tabs-gap">
            <div v-if="loadingContent" class="content-loader">
                <!-- <facebook-loader>
                    <rect x="0" y="0" rx="3" ry="3" width="25%" height="10" />
                    <rect x="20" y="20" rx="3" ry="3" width="30%" height="10" />
                    <rect x="20" y="40" rx="3" ry="3" width="50%" height="10" />
                    <rect x="0" y="60" rx="3" ry="3" width="60%" height="10" />
                    <rect x="20" y="80" rx="3" ry="3" width="70%" height="10" />
                    <rect x="20" y="100" rx="3" ry="3" width="100%" height="10" />
                </facebook-loader> -->
                <img :src="'icons/vgps-loader.gif' | assetPath">
            </div>
            <div v-if="!loadingContent" v-for="event in events" class="col-md-12 col-sm-12 col-xs-12 column ceo no-padding-l-r show">
                <div class="map-content">
                    <div class="map-left">
                        <a href="javascript:void(null);" @click="eventDetail(event.id)">
                            <img class="min-height-thumb" :src="event.image" alt="Image" />
                        </a>
                    </div>  
                    <div class="map-center">
                        <h6>{{ event.title }}</h6>
                        <p>{{ event.start_date }}</p>
                        <p>{{ event.venue.city+','+event.venue.state }}</p>
                        <span>#chrisbrown  #xtour</span>
                        <span class="time">{{ event.start_time }}</span>
                        <span class="eye"><img :src="'seen.svg' | assetPath" /> {{ event.total_views }}</span>
                    </div>
                    <div class="map-right">
                        <span v-if="!userData" data-toggle="modal" data-target="#loginModal"  class="heart">
                            <img :src="'heart.svg' | assetPath" /></span>
                        <span v-else v-on:click="favoriteEvent(event.event_id)" class="heart"><img :src="getHeartImageUrl(event.like)" /></span>
                         <a :class="getFollowClass(event.follow)" v-if="!userData" data-toggle="modal" data-target="#loginModal" href="javascript:void(null)"><img :src="'fav.svg' | assetPath"/></a>
                         <a :class="getFollowClass(event.follow)" v-else href="javascript:void(null)" v-on:click="followVenue(event.event_id, event.venue_id)"><img :src="getImageUrl(event.follow)"/></a>
                        <a class="share" href="#"><img :src="'share.svg' | assetPath" /></a>
                    </div>  
                </div>
            </div>
            <div v-if="showLoadMore && !loadingContent" class="col-md-12">
                <a @click="loadMore" class="see-more" href="javascript:void(0)">See more events</a>
            </div>   
        <div v-if="events.length == 0 && !loadingContent" class="no-venue-listing alert alert-warning">Result Not Found!</div>
    </div>
    </div>
</template>

<script>

    import { bus } from '../../app'
    import LikeEvent from '../global/like .vue';
    import {
            ContentLoader,
            FacebookLoader,
            CodeLoader,
            BulletListLoader,
            InstagramLoader,
            ListLoader
    } from 'vue-content-loader'

    export default {
        data() {
            return {
                loadingContent:false,
                loadingMoreContent:false,
                Searching:false,
                showLoadMore:true,
                events: [],
                heartImage: "heart.svg"
            }
        },
        created (){

            bus.$on('advanceFilter',() => {
                this.getEvents('advanceFilter')
            });

            bus.$on('search_event_title',(keyword) => {
                this.filters.search = keyword;
                this.scrollToDiv('filter-id');
                this.getEvents('')
            });

            bus.$on('filterSelection',() => {
                this.getEvents('filterSelection')
            });

            bus.$on('updateDateValues',() => {
                this.getEvents('updateDateValues')
            });
            bus.$on('updateLocation',() => {
                this.getEvents('updateLocation')
            });

        },
        beforeUpdate (){
        },
        components: {
            ContentLoader,
            FacebookLoader,
            'like_event': LikeEvent
        },
        props:['filters','location','userData', 'lookupData'],
        mounted() {
            this.getEvents();
        },
        methods: {

            loadMore: function() {
                this.filters.offset = this.filters.offset + this.filters.limit
                this.getEvents('loadmore');
            },
            getHeartImageUrl(isLike) {
                var path = window.axios.defaults.baseURL+'/images/';
                return isLike === true ? path + 'red-heart.svg' : path+'heart.svg';
            },
            getImageUrl(isLike) {
                var path = window.axios.defaults.baseURL+'/images/';
                return isLike === true ? path + 'red-heart.svg' : path+'heart.svg';
            },
            getEvents: function(action) {
                var vm = this;
                if(action == 'loadmore'){
                    vm.loadingMoreContent = true
                }else{
                    vm.loadingContent = true;
                }
                axios.get("data/search/map",{
                    params: {
                        q: this.filters.search,
                        start_date: this.filters.dates.start,
                        end_date: this.filters.dates.end,
                        category: this.filters.category,
                        venue_type: this.filters.venue_type,
                        event_theme: this.filters.event_theme,
                        genre: this.filters.event_genere,
                        offset: this.filters.offset,
                        limit: this.filters.limit,
                    }
                })
                .then((response) => {
                    let eventMapsList = [];
                    var isLoadMore = false;
                    if(action == 'loadmore'){
                        isLoadMore = true;
                        $.each(response.data.data.events, function (index,obj) {
                            vm.events.push(obj)
                        });
                        vm.showLoadMore = ( response.data.data.events.length < vm.filters.limit)?false:true;
                        vm.loadingMoreContent = false
                    }else{
                        if(action == 'updateLocation' || action == 'search_event_title') {
                            document.getElementById('filter-id').scrollIntoView({ block: 'end',  behavior: 'smooth' });
                        }
                        vm.events = response.data.data.events
                        vm.showLoadMore = (response.data.data.events.length < vm.filters.limit)?false:true;
                        vm.loadingContent = false;
                    }
                    eventMapsList['isLoadMore'] = isLoadMore;
                    eventMapsList['response'] = response.data.data.events;
                    this.$emit('clicked', eventMapsList);

                })
                .catch(function (errors) {

                    vm.loadingContent = false;
                    vm.loadingMoreContent = false

                });
            },
            getImageUrl(isLike) {
                var path = window.axios.defaults.baseURL+'/images/';
                return isLike === true ? path + 'fav-w.svg' : path+'fav.svg';
            },
            getFollowClass(isFollow) {
                return isFollow === true ? 'follow-checked' : 'follow';
            },
            followVenue: function(id, venue_id) {
                this.events = this.events.map((el) => {
                    el.event_id === id ? el.follow = true : '';
                    return el;
                });
                var vm = this;
                //this.heartImage = 'red-heart.svg';
                var data = new FormData();
                data.append("content_id", venue_id);
                data.append("content_type", 'venue');
                data.append("favourite", true);

                if (vm.processing === true) {
                    return false;
                }

                vm.processing = true;
                axios.post("data/markFavourite", data)
                    .then(function (response) {
                        vm.processing = false;
                    })
                    .catch(function (errors) {
                        vm.processing = false;
                    });


            },
            scrollToDiv:function(divID){

                $('html, body').animate({
                    scrollTop: $("#"+divID).offset().top
                }, 1000);

            },
            favoriteEvent: function(id) {
                this.events = this.events.map((el) => {
                    el.event_id === id ? el.like = true : '';
                    return el;
                });
                var vm = this;
                //this.heartImage = 'red-heart.svg';
                var data = new FormData();
                data.append("content_id", id);
                data.append("content_type", 'event');
                data.append("favourite", true);

                if (vm.processing === true) {
                    return false;
                }

                vm.processing = true;
                axios.post("data/markFavourite", data)
                    .then(function (response) {
                        vm.processing = false;
                    })
                    .catch(function (errors) {
                        vm.processing = false;
                    });
            },
            eventDetail: function(id) {
                this.$emit('clickOnEvents', id);
            }
        },
        filters: {

            assetPath: function (img) {
                var path = window.axios.defaults.baseURL+'/images/'+img;
                return path;
            },
            checkObj: function (obj) {
                return (obj == '')?false:true
            },
            url: function (path) {
                var path = window.axios.defaults.baseURL+'/'+path;
                return path;
            }
        }


    }
</script>
