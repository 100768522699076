<template>
  <div class="business-filter">
    <ul class="list-group list-group-flush">
      <li
        v-for="(item, index) in search_user_filters.genre.slice(0, 5)"
        :key="index"
        class="list-group-item genreListItem"
      >
        <div class="form-group vgps-custom-checkbox-wrapper">
          <label class="fancyRadioWrapper" :for="item.name">
            {{ item.name }}
            <input
              type="radio"
              v-model="search_event_genre"
              @change="advanceFilter"
              :value="item.type_n"
              name="genre"
              :id="item.name"
            />
            <span class="checkmark"></span>
          </label>
          <span class="item-count badge">{{ item.count }}</span>
        </div>
      </li>
      <li v-if="search_user_filters.genre.length > 5" class="list-group-item">
        <a
          href="javascript:void(0)"
          data-toggle="modal"
          data-target="#genre-category-more-option-model"
        >
          See All
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";

// import the plugin
import { mapFields } from "vuex-map-fields";

export default {
  components: {
    //
  },
  data() {
    return {};
  },
  computed: {
    eventGenre() {
      return this.$store.getters.eventGenre;
    },
    ...mapFields([
      "search_event_genre",
      "search_user_filters",
      "search_main_category",
    ]),
  },
  watch: {},
  created: function () {
    var vm = this;
  },
  mixins: [helpers],
  methods: {
    advanceFilter: function () {
      var vm = this;
      // vm.search_main_category =
        // vm.search_main_category == "Trending" ? "All" : vm.search_main_category;
      bus.$emit("search_filter_emit");
    },
  },
  filters: {
    assetPath: function (img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
  },
};
</script>
