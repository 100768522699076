<template>
  <div>
    <!-- Main Start -->
    <header_section></header_section>
    <main class="top-inner-pages">
      <!-- Dashboard Start -->
      <!-- <section class="headerSectionWrapper">
        <div class="headerBGImage">
          <img :src="'banners/vgps-min.png' | assetPath" alt="header Image" />
        </div>
      </section> -->

      <section class="dashboard vgps_contact_support_wrapper">
        <div class="container paddingLR20">
          <div class="row">
            <div class="col-md-12 vgps_page_header_wrapper mb0">
              <h2>About Us</h2>
            </div>
            <div class="col-md-12 vgps_privacy_content_wrapper paddingt15">
              <p>We are a subsidiary of Retired Digital, a multimedia company created to improve a private business’
              graphic design, SE Optimization, and content articulation resulting in an enhanced electronic footprint to
              increase the revenue of online sales. VenueGPS was created to bridge the gap of the 180k annual
              parties and events, national holidays and celebrations, and celebrity livestreams and live performances
              to the earth’s 6.9 billion residents. This idea was provoked due to VenueGPS’ CEO “Conn’s”, military
              deployment experiences which he travelled extensively yet lacked “where to go” information. Eighty
              percent of VenueGPS’s executive team is retired veterans which truly gives VenueGPS unique cross-
              cultural real-life experiences to deliver progressive choices to a progressive world.</p>
              <h3>Eventbrite</h3>
              <p>Eventbrite is a global self-service ticketing platform for live experiences that allows anyone to create,
              share, find and attend events that fuel their passions and enrich their lives. From music festivals,
              marathons, conferences, community rallies, and fundraisers, to gaming competitions and air guitar
              contests. Our mission is to bring the world together through live experiences.</p>
              
              <h3>Ticketmaster</h3>
              <p>
                <b>TicketNetwork/Ticketmaster</b>
                <br />We&#39;re your connection to live entertainment. TicketNetwork.com connects you to more than 100,000
                concerts, sports &amp; theater events worldwide! All tickets are listed by professional ticket-selling
                companies &amp; trusted ticket sellers, all backed by our 100% Money-Back Guarantee.
              </p>
              <h3>YouTube</h3>
              <p>Our mission is to give everyone a voice and show them the world.
                We believe that everyone deserves to have a voice, and that the world is a better place when we listen,
                share and build community through our stories <br />
                <b>Freedom of Expression</b><br />
                We believe people should be able to speak freely, share opinions, foster open dialogue, and that
                creative freedom leads to new voices, formats and possibilities.<br />
                <b>Freedom of Information</b><br />
                We believe everyone should have easy, open access to information and that video is a powerful force
                for education, building understanding, and documenting world events, big and small.</p>
            </div>
          </div>
        </div>
      </section>
      <!-- Dashboard End -->
    </main>
    <!-- Main End -->
    <footer_section></footer_section>
  </div>
</template>

<script>
import { bus } from "./app";
import FooterSection from "./../views/partials/footer";
import HeaderSection from "./../views/partials/header";
import helpers from "./../mixin/helpers";

export default {
  data() {
    return {
      loadingContent: false,
    };
  },
  mixins: [helpers],
  components: {
    footer_section: FooterSection,
    header_section: HeaderSection,
  },
};
</script>