<template>
    <div>
<!-- Main Start -->
    <header_section></header_section>
    <main class="top-inner-pages vision-page">
        <section class="headerSectionWrapper">
            <div class="headerBGImage">
          <img :src="'banners/vgps-min.png' | assetPath" alt="header Image" />
            </div>
        </section>
        <!-- Dashboard Start -->
        <section class="dashboard vgps_contact_support_wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 vgps_page_header_wrapper">
                        <h2>Coming Soon</h2>
                    </div>
                    <div class="col-md-12 vgps_privacy_content_wrapper">
                        <p>This content will be available soon, If you have any query please <router-link class="textColorChange" :to="{ name: 'contact-us' }">contact us.</router-link></p>
                    </div>
                </div>
            </div>
        </section>
        <!-- Dashboard End -->

    </main>
    <!-- Main End -->
    <footer_section></footer_section>
    </div>
</template>

<script>

  import { bus } from './app'
  import HeaderSection from './../views/partials/header' 
  import FooterSection from './../views/partials/footer' 
  import helpers from './../mixin/helpers';

  export default {
    data() {
      return {
          loadingContent: false,
      }
    },
    mixins: [helpers],
    components:{
      'header_section': HeaderSection,
      'footer_section': FooterSection,
    }
  }
</script>