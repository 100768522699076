<template>
    <!-- Card -->
    <div class="card">
        <div class="card-header bg-white py-3">
            <h5 class="mb-0">Trial</h5>
        </div>
        <div class="card-body">
            <ul class="packages-type">
                <!-- <li class="item-type " :class="{'selected-plan': selected_plan === 'stripe_plan_2023'}" >
                  <div class="form-group vgps-custom-checkbox-wrapper">
                      <label class="fancyRadioWrapper">
                      <input type="radio" v-model="selected_plan" checked="checked" value="stripe_plan_2023" id="stripe_plan_2023" />
                      <span class="checkmark"></span>
                      </label>
                  </div>
                  <h3>USD</h3>
                  <h2>$ 0.0</h2>
                  <p>14 Days</p>
                </li> -->
                <li style="margin-left: 20px;" class="item-type " :class="{'selected-plan': selected_plan === 'free_vip_trial'}" >
                  <div class="form-group vgps-custom-checkbox-wrapper">
                      <label class="fancyRadioWrapper">
                      <input type="radio" v-model="selected_plan" checked="checked" value="free_vip_trial" id="free_vip_trial" />
                      <span class="checkmark"></span>
                      </label>
                  </div>
                  <h3>USD</h3>
                  <h2>$ 0.0</h2>
                  <p>7 Days</p>
                </li>
            </ul>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <strong>Contacts</strong>
                <p>Phonebook</p>
                <span class="subscript-icons"><img class="img-responsive" :src="'icons/tick-icon.svg' | assetPath" alt=""/></span>
              </li>
              <li class="list-group-item">
                <strong>Hashtags</strong>
                <p>Customize Events</p>
                <span class="subscript-icons"><img class="img-responsive" :src="'icons/tick-icon.svg' | assetPath" alt=""/></span>
              </li>
              <li class="list-group-item">
                <strong>MAP</strong>
                <p>Directories</p>
                <span class="subscript-icons"><img class="img-responsive" :src="'icons/tick-icon.svg' | assetPath" alt=""/></span>
              </li>
              <li class="list-group-item">
                <strong>Accounts</strong>
                <p>{{ checkPlanLimit() }}</p>
                <span class="subscript-icons"><img class="img-responsive" :src="'icons/tick-icon.svg' | assetPath" alt=""/></span>
              </li>
              <li class="list-group-item">
                <strong>Message</strong>
                <p>Notifications</p>
                <template v-if="checkPromoteAndMessage()">
                  <span class="subscript-icons"><img class="img-responsive" :src="'icons/tick-icon.svg' | assetPath" alt=""/></span>
                </template>
                <template v-else>
                  <span class="subscript-icons"><img class="img-responsive" :src="'icons/close-icon.svg' | assetPath" alt=""/></span>
                </template>
              </li>
              <li class="list-group-item">
                <strong>Auto Promote</strong>
                <p>Prefrences</p>
                <template v-if="checkPromoteAndMessage()">
                  <span class="subscript-icons"><img class="img-responsive" :src="'icons/tick-icon.svg' | assetPath" alt=""/></span>
                </template>
                <template v-else>
                  <span class="subscript-icons"><img class="img-responsive" :src="'icons/close-icon.svg' | assetPath" alt=""/></span>
                </template>
              </li>
            </ul>
        </div>
        <div class="card-footer bg-white py-3">
            <button type="button" @click="submit()" class="btn btn-success btn-lg subscription-btn">
                <span class="login-show-spinner" :class="{ active: processing == true }">
                    <i class="fa fa-spinner fa-spin"></i>
                </span>
                Upgrade Subscription
            </button>
        </div>
    </div>
    <!-- Card -->
  </template>
  
  <script>
  
  import { StripeCheckout } from '@vue-stripe/vue-stripe';
  import helpers from "../../../mixin/helpers";
  
  export default {
    mixins: [helpers],
    data() {
      return {
        loadingContent: false,
        loading:false,
        processing:false,
        selected_plan:"free_vip_trial",
        customerEmail:'',
        customerStripeID:'',
        customerId:'',
        // selected_plan:"price_1LwuhaDcPFLTCaUzuVzkdvOX",
        publishableKey:"pk_live_XG0wBmooO0BieFGwmTuLLXIg006Y5yh4g8",
        // publishableKey:"pk_test_DdH7PjiE2RWO3zuKEmXODUGq00Dp2zdIQg",
        successURL: window.axios.defaults.baseURL+"/#/subscription/done",
        cancelURL:window.axios.defaults.baseURL+"/#/subscription",
        lineItems: [
          {
            price: "price_1LwuhaDcPFLTCaUzuVzkdvOX", // The id of the recurring price you created in your Stripe dashboard
            quantity: 1,
          }
        ],
        subscriptionsList: [],
        elementsOptions: {
          appearance: {}, // appearance options
          clientSecret:""
        },
        confirmParams: {
          return_url: window.axios.defaults.baseURL+'/#/subscription/done', // success url
        },
      };
    },
    watch: {
      selected_plan: function (newVal, oldVal) {
          console.log(' selected_plan ',newVal)
          this.$set(this.lineItems[0], 'price', newVal);
      }
    },
    created: function(){
      let vm = this;
      const user = this.$session.get("user_data");
      vm.customerId = user.profile.stripe_customer_id
      vm.customerEmail = user.profile.email


    },
    components: {
      StripeCheckout
    },
    methods: {
      submit : function () {

        var vm = this

        if(this.selected_plan ==''){
              sweetalert2.fire(
              "Plan Option is Required!",
              'Please select the plan Month/Year',
              "info"
            );
            return false;
        }

        const data = new FormData();
        data.append("stripe_user_subscription_id", "is_trial");
        data.append("stripe_plan", this.selected_plan);
        data.append("stripe_customer_id", vm.customerId);

        vm.processing = true;

        axios.post('data/free-subscription', data)
          .then(function (response) {
            
            const {data} = response
            if (data.data.success == true) {
              setTimeout(function () {  
                window.location =  window.axios.defaults.baseURL+'/#/subscription/done';
              }, 500);
            } else {

              sweetalert2.fire({
                icon: "info",
                title: "Something went wrong",
                html: data.data.description,
              });
            }
            vm.processing = false;
          })
          .catch(function (errors) {
            console.log("Subscription create exception", errors);
            sweetalert2.fire("", "Something went wrong!", "error");
            vm.processing = false;
          });
      },
      checkPlanLimit: function () {
        const includePlan = [
          'Trial'
        ];
        return (this.selected_plan == 'stripe_plan_2023')?'50':"Unlimited"
      },
      checkPromoteAndMessage: function () {
        const includePlanSelection = [
          'free_vip_trial',
          "price_1MeDL7DcPFLTCaUzozDb2GGu",
          "price_1MeDKYDcPFLTCaUz7HFDDKGc"
        ];
        return (includePlanSelection.includes(this.selected_plan))?true:false
      }
    },
    filters: {
      formatData: function (str) {
        return str.replace("Yearly",'Save/Yearly')
      }
  }
  };
  </script>

<style scoped>
/* Subscription */
.pricinng-plan .tab-pane .plan-card .packages-type li {
  position: relative;
  background: #FAFAFB;
  border: 2px solid #EDEDEE;
  border-radius: 20px;
  width: 50%;
  padding: 36px 10px 10px 10px;
  text-align: center;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.pricinng-plan .tab-pane .plan-card .packages-type li.selected-plan {
  background: #FAFAFB; /* Inner background */
  border: none; /* Remove default border */
  position: relative;
}

.pricinng-plan .tab-pane .plan-card .packages-type li.selected-plan::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px; /* Same border-radius as the main element */
  padding: 2px; /* The thickness of the border */
  background: linear-gradient(80.46deg, #00AEF0 4%, #6740B4 90.99%);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}
</style>