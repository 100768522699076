<template>
  <div class="floating-label-wrap event-search-component">
    <vue-google-autocomplete
      :ref="address"
      id="claim_business_address"
      v-model="address"
      name="city"
      :country="['us']"
      class="form-control floating-label-field floating-label-field--s3"
      placeholder="Location"
      v-on:placechanged="getAddressData"
      v-on:no-results-found="noResultFound"
    ></vue-google-autocomplete>
    <label for="claim_business_address" class="floating-label">Finding Address</label>
    <img :src="'search.svg' | assetPath" />
  </div>
</template>
<script>
// Ref Types: address, cities,
import { bus } from "../../app";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import helpers from "../../mixin/helpers";
export default {
  components: { VueGoogleAutocomplete },
  mixins: [helpers],
  props: [],
  data: function() {
    return {
      address: "",
      location_city: "",
      latitude: "",
      longitude: "",
      isLocationEnable: false,
      showLocation: false,
      mapPreviewUrl: ""
    };
  },
  created() {
    // bus.$on("businessAddressMap", businessData => {
    //   console.log("Update business address  data", businessData);
    //   this.address = businessData.address_details.street
    //     ? businessData.address_details.street + ", "
    //     : "";
    //   this.address += businessData.address_details.full_address;
    //   this.address += " " + businessData.address_details.zip_code;
    //   this.latitude = businessData.address_details.latitude;
    //   this.longitude = businessData.address_details.longitude;
    //   this.initMap("initMap");
    // });
  },
  updated() {},
  mounted() {
    // if (typeof this.event != "undefined" && this.event != "") {
    //   this.address = this.event.address_details.street
    //     ? this.event.address_details.street + ", "
    //     : "";
    //   this.address += this.event.address_details.full_address;
    //   this.latitude = this.event.address_details.latitude;
    //   this.longitude = this.event.address_details.longitude;
    // } else {
    // this.address = this.$session.get("default_location");
    // this.latitude = this.$session.get("default_location_lat");
    // this.longitude = this.$session.get("default_location_long");
    // }
    this.initMap("initMap");
  },
  methods: {
    updateVal: function() {},
    initMap: function(type, eventData) {
      this.mapPreviewUrl =
        "https://maps.google.com/maps?q=" +
        this.latitude +
        "," +
        this.longitude +
        "&hl=es;z=14&amp;output=embed";
      $("#event-map-preview .map-ifram").html(
        '<iframe src="' +
          this.mapPreviewUrl +
          '" height="220" width="100%" frameborder="0" allowfullscreen=""></iframe>'
      );
      $("#event-map-preview").show();
    },
    /**
     * When the location not found */
    noResultFound: function(addressData, placeResultData, id) {
      console.log(addressData);
      $("#event-map-preview").hide();
    },
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    getAddressData: function(addressData, placeResultData, id) {
      console.log("Get event location", addressData, placeResultData);
      var vm = this;
      this.noLocation = false;
      this.mapPreviewUrl =
        "https://maps.google.com/maps?q=" +
        addressData.latitude +
        "," +
        addressData.longitude +
        "&hl=es;z=14&amp;output=embed";
      $("#event-map-preview .map-ifram").html(
        '<iframe src="' +
          this.mapPreviewUrl +
          '" height="220" width="100%" frameborder="0" allowfullscreen=""></iframe>'
      );
      $("#event-map-preview").show();

      // debugger
      var formatedData = $("#claim_business_address").val(); //placeResultData.formatted_address;
      formatedData = formatedData.split(",").reverse();

      // $('#zipcode').val((typeof addressData.postal_code != 'undefined')?addressData.postal_code: '');
      vm.latitude = addressData.latitude;
      vm.longitude = addressData.longitude;
      bus.$emit("updateClaimLocationLatLong", addressData, placeResultData, formatedData);
      return false;
    }
  }
};
</script>