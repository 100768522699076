<template>
    <div class="event-vue-wrapper">
        <header_section></header_section>
        <div class="top-inner-pages row">
            <div v-if="loadingContent" class="content-loader container">
                <ContentLoader
                    viewBox="0 0 1300 500"
                    height="500"
                    width="1200"
                    :speed="speed">
                    <rect x="20" y="15" rx="20" ry="20" width="300" height="320" />
                    <rect x="92" y="347" rx="5" ry="5" width="45" height="45" />
                    <rect x="148" y="347" rx="5" ry="5" width="45" height="45" />
                    <rect x="205" y="347" rx="5" ry="5" width="45" height="45" />
                    <rect x="361" y="17" rx="10" ry="10" width="420" height="33" />
                    <rect x="361" y="71" rx="10" ry="10" width="315" height="33" />
                    <rect x="361" y="125" rx="10" ry="10" width="233" height="20" />
                    <rect x="361" y="216" rx="5" ry="5" width="195" height="13" />
                    <rect x="361" y="251" rx="5" ry="5" width="195" height="13" />
                    <rect x="367" y="311" rx="8" ry="8" width="130" height="38" />
                    <rect x="515" y="311" rx="8" ry="8" width="130" height="38" />
                </ContentLoader>
            </div>
            <div v-else class="loaded-content" v-animate-css="animate.fadeIn">
                <banner_section v-bind:event="event"></banner_section>
                <page_nav_section 
                    v-bind:event="event"
                    v-bind:amenities="isAmenities"
                    v-bind:organizers="isOrganizerLength"
                >
                </page_nav_section>
                <!-- Event Detail Sections Wrapper Start -->
                <div class="vgps-event-detail-main-wrapper">
                    <status_message_section v-if="excludeEventStatus(event.activation_status)" v-bind:event="event"></status_message_section>
                    <info_contacts_section v-bind:event="event"></info_contacts_section>
                    <tickets_section v-if="event.tickets.length > 0" v-bind:event="event"></tickets_section>
                    <performers_section  v-bind:event="event"></performers_section>
                    <organizer_section 
                        v-if="event.eventpeoplerelations.promoter.length > 0"
                        v-bind:title="'Promoter'" 
                        v-bind:organizers="event.eventpeoplerelations.promoter">
                    </organizer_section>
                    <organizer_section 
                        v-if="event.eventpeoplerelations['hosted-by'].length > 0"
                        v-bind:title="'Hosted By'" 
                        v-bind:organizers="event.eventpeoplerelations['hosted-by']">
                    </organizer_section>
                    <organizer_section 
                        v-if="event.eventpeoplerelations['music-by'].length > 0"
                        v-bind:title="'Music By'" 
                        v-bind:organizers="event.eventpeoplerelations['music-by']">
                    </organizer_section>
                    <organizer_section 
                        v-if="event.eventpeoplerelations['powered-by'].length > 0"
                        v-bind:title="'Powered By'" 
                        v-bind:organizers="event.eventpeoplerelations['powered-by']">
                    </organizer_section>
                    <organizer_section 
                        v-if="event.eventpeoplerelations['media-part'].length > 0"
                        v-bind:title="'Media By'" 
                        v-bind:organizers="event.eventpeoplerelations['media-part']">
                    </organizer_section>
                    <!-- <google_ads_section></google_ads_section> -->
                    <content_slider_ads_section></content_slider_ads_section>
                    <amenties_section v-if="isAmenities.length > 0" v-bind:event="event"></amenties_section>
                    <about_section v-if="event.about && event.about != 'null'" v-bind:event="event"></about_section>
                    <gallery_section v-if="event.venue[0].panaromic_images.length > 0" v-bind:event="event"></gallery_section>
                    <promo_videos_section v-if="event.promo_videos.length > 0" v-bind:event="event"></promo_videos_section>
                    <location_section v-bind:event="event"></location_section>
                    <faqs_section v-if="event.faqs.length > 0" v-bind:event="event"></faqs_section>
                </div>
            </div>
        </div>
        <footer_section></footer_section>
    </div>
</template>

<script>

// Imports
import { bus } from '../app'
import helpers from '../mixin/helpers';

import BannerSection from './../views/event/header'
import PageNavSection from './../views/event/page-nav'
import AboutSection from './../views/event/about'
import Info_Contacts_Section from './../views/event/info-contacts'
import Performers_Section from './../views/event/performers'
import Organizer_Section from './../views/event/organizers'
import Amenities_Section from './../views/event/amentities'
import FAQSSection from './../views/event/faqs'
import Sataus_Message_Section from './../views/event/status-message'
import GallerySection from './../views/event/gallery'
import Promo_Videos_Section from './../views/event/promo-videos'
import GoogleAdsSection from './../views/event/google-ads'
import AdsSliderSection from './../views/event/banner-slider'
import TicketSection from './../views/event/tickets'
import LocationSection from './../views/event/location'
import FooterSection from './../views/partials/footer'  
import HeaderSection from './../views/partials/header'   

import {
  ContentLoader,
  FacebookLoader
} from "vue-content-loader";

export default {
    name:'EventDetailComponent',
    components: {
      ContentLoader,
      FacebookLoader,
      banner_section : BannerSection,
      amenties_section : Amenities_Section,
      about_section : AboutSection,
      info_contacts_section : Info_Contacts_Section,
      organizer_section : Organizer_Section,
      performers_section : Performers_Section,
      faqs_section : FAQSSection,
      gallery_section : GallerySection,
      promo_videos_section : Promo_Videos_Section,
      google_ads_section : GoogleAdsSection,
      content_slider_ads_section : AdsSliderSection,
      tickets_section : TicketSection,
      status_message_section : Sataus_Message_Section,
      location_section : LocationSection,
      page_nav_section : PageNavSection,
      header_section: HeaderSection,
      footer_section: FooterSection,
    },
    created: function(){
        
        var vm = this
        vm.getDetail();

        bus.$on('loadEventDetailData', () => {
            vm.getDetail();
        });
    },
    watch: {
        // $route: "fetchData"
    },
    activated () {
        this.$destroyKey()
    },
    computed:{
       isAmenities: function(){
            var obj = this.event.venue[0].amenities;
            var arr = Object.keys(obj).map(function (key) { return obj[key]; });
            var data = arr.filter(function(obj,val){
                return obj.length > 0 ? true : false;  
            });
            return data;
        },
        isOrganizerLength: function(){
            var obj = this.event.eventpeoplerelations;
            var arr = Object.keys(obj).map(function (key) { return obj[key]; });
            var data = arr.filter(function(obj,val){
                // debugger
                if (obj.length > 0 && obj[0].relation_type !='performer') {
                    return true
                }else {
                    return false;
                }  
            });
            return data;
        }
    },
    props:[],
    mixins: [helpers],
    data () {
        return {
          title: 'Event Detail Component',
          loadingContent:false,
          event: '',
          speed:2
        }
    },
    methods: {
        setDetail: function(event) {
            // debugger
            console.log('Event data set',event);
            this.event = event.data;
        },
        async getDetail(id) {
        
            var vm = this;
            this.loadingContent = true;
            axios.get("get/event/"+vm.$route.params.id)
            .then( (response) => {
                if (response.data.success == false) {
                    setTimeout(function(){
                        if(response.data.status_code == 403){
                            sweetalert2.fire(
                                {
                                    title: "",
                                    icon: "info",
                                    text: "Your sessionn is expired please login or back to home screen.",
                                    confirmButtonText: 'Back to Home!',
                                    showCloseButton: true,
                                    showCancelButton: true,

                                }
                            ).then((result) => {
                                if (result.value) {
                                    vm.$session.set('access_token','');
                                    window.location = window.axios.defaults.baseURL
                                }
                            })
                            return false;
                            
                        }else{
                            vm.$router.back()
                        }
                    });
                } else {
                    this.event = response.data.data 
                }
                this.loadingContent = false;
            })
            .catch(function (errors) {
                sweetalert2.fire("", errors.data, "error");
                this.loadingContent = false;
            });
        }
    }
}
</script>