<template>
  <div>
    <!-- Main Start -->
    <header_section></header_section>
    <main class="top-inner-pages">
      <!-- <section class="headerSectionWrapper">
        <div class="headerBGImage">
          <img :src="'banners/vgps-min.png' | assetPath" alt="header Image" />
        </div>
      </section> -->

      <!-- Dashboard Start -->
      <section class="dashboard vision-page vgps_contact_support_wrapper innerPageContentSec">
        <div class="container paddingLR20">
          <div class="row">
            <div class="col-md-12 vgps_page_header_wrapper mb0">
              <h2>VenueGPS The Vision</h2>
            </div>
            <div class="col-md-12 vgps_privacy_content_wrapper paddingt15">
              <a class="textColorChange" href="#">Our Pledge</a>
              <p>VenueGPS is the world’s first Digital Promotional and Marketing Entertainment website equipped to supersede any entertainment portal ever invented.  Resolute in our passion to “Going Green”, VenueGPS is committed to the reduction of environmental impact by shifting from the print and pace flyer blueprint to a 100 percent digital e-flyer online business model.  VenueGPS is your virtual passport to recreational fun and enjoyment.  VenueGPS is determined to be banner of hope for a diverse group of customers living in a diversified world.</p>
              
              <a class="textColorChange" href="#">What VenueGPS Does For You</a>
              <p>
                VenueGPS will deliver paperless event flyers and electronic alerts to customers via the VenueGPS Website/App and messaging system.  Each event will have customized information such as Event Themes, Genres Type, Food Selections, and an assortment of other amenities.  Our team is committed to providing you with unprecedented contact with the most comprehensive inventory of entertainment on the planet!
              </p>
              <a class="textColorChange" href="#">What You Get</a>
              <p>VenueGPS will link you to Celebrities, Artists, Influencers, Athletes, Hip Hop Music, Rock, Country, Latin, and Jazz Musicians.  VenueGPS facilitates marketing through Video Promotions such as Venmo, YouTube, TikTok, and Instagram!
Information on Bottle Service, Customer Reviews, Operating Hours, Online Taxi Service, UBER/LYFT, in addition to GPS guidance has been conveniently merged with each event promoted on the VenueGPS website.
</p>
<a class="textColorChange" href="#">We Care</a>
<p>VenueGPS’ customer support center is passionate in providing transparent and unmatched support for any questions or concerns for our customers. Our leadership is absolute in making your VenueGPS experience the gold standard of the entire entertainment industry!</p>
              <!-- <p>
                VGPS will link you to Celebrities, Artists, Influencers, Athletes, Hip Hop Music, Rock,
                 Country, Latin, and Jazz Musicians.  
                VenueGPS facilitates marketing through Video Promotions as well as 
                third party ticket sales via Ticketmaster and Eventbrite!
              </p>
              <p>
                Information on Bottle service, Customer Reviews, Operating Hours, Online Taxi Service UBER/LYFT,
                 in addition to GPS guidance conveniently merged with each event is provided.  
                      Yes, all this in one website/app!
              </p>
              <p>
                VenueGPS’ customer support center is passionate in providing transparent and unmatched support  
                for any questions or concerns for our customers.  
                Our leadership is absolute in making your VenueGPS experience the gold
                 standard in entire entertainment industry!
              </p>
              <a class="textColorChange" href="#">VenueGPS, We Go Where You Are!</a> -->
            </div>
          </div>
        </div>
      </section>
      <!-- Dashboard End -->
    </main>
    <!-- Main End -->
    <footer_section></footer_section>
  </div>
</template>

<script>
import { bus } from "./app";
import FooterSection from "./../views/partials/footer";
import HeaderSection from "./../views/partials/header";
import helpers from "./../mixin/helpers";

export default {
  data() {
    return {
      loadingContent: false,
    };
  },
  mixins: [helpers],
  components: {
    footer_section: FooterSection,
    header_section: HeaderSection,
  },
};
</script>