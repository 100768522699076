<template>
    <section class="vgps-event-amenities-wrapper vgps-event-detail-section-wrapper" id="amenities">
            <div class="containers">

                <div class="row">
                    <div class="col-md-12 vgps-event-detail-section-title">
                        <h2>Amenities</h2>
                    </div>
                </div>
                <div class="vgps-ammenities-grid-wrapper">
        
                    <!-- Age Amenity -->
                    <template v-if="venue.amenities.Age.length > 0">
                        <div v-for="(item,index) in venue.amenities.Age" :key="item.amenity" class="vgps-ammenities-grid age">
                            <div class="vgps-ammenities-grid-inner">
                                <div class="vgps-ammenities-grid-title">
                                    <div class="thumb"><img :src="item.icon_url"></div>
                                    <div class="desc">
                                        <h4>Age</h4>
                                    </div>
                                </div>
                                <div class="vgps-ammenities-grid-list">
                                    <ul>
                                        <li>{{ item.amenity }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </template>

                    <!-- Gender Amenity -->
                    <template v-if="venue.amenities.Gender.length > 0">
                        <div v-for="(item,index) in venue.amenities.Gender" :key="item.amenity" class="vgps-ammenities-grid age">
                            <div class="vgps-ammenities-grid-inner">
                                <div class="vgps-ammenities-grid-title">
                                    <div class="thumb"><img :src="item.icon_url"></div>
                                    <div class="desc">
                                        <h4>Gender</h4>
                                    </div>
                                </div>
                                <div class="vgps-ammenities-grid-list">
                                    <ul>
                                        <li>{{ item.amenity }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </template>

                    <!-- Attire Amenity -->
                    <template v-if="venue.amenities.Attire.length > 0">
                        <div v-for="(item,index) in venue.amenities.Attire" :key="item.amenity" class="vgps-ammenities-grid age">
                            <div class="vgps-ammenities-grid-inner">
                                <div class="vgps-ammenities-grid-title">
                                    <div class="thumb"><img :src="item.icon_url"></div>
                                    <div class="desc">
                                        <h4>Attire</h4>
                                    </div>
                                </div>
                                <div class="vgps-ammenities-grid-list">
                                    <ul>
                                        <li>{{ item.amenity }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </template>

                    <!-- Food Amenity -->
                    <template v-if="venue.amenities.Food.length > 0">
                        <div  class="vgps-ammenities-grid age">
                            <div class="vgps-ammenities-grid-inner">
                                <div class="vgps-ammenities-grid-title">
                                    <div class="thumb"><img :src="'food.svg' | assetPath"></div>
                                    <div class="desc">
                                        <h4>Food</h4>
                                    </div>
                                </div>
                                <div class="vgps-ammenities-grid-list">
                                    <ul>
                                        <li v-for="(item,index) in venue.amenities.Food" :key="index">{{ item.amenity }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </template>

                    <!-- Parking Amenity -->
                    <template v-if="venue.parking.length > 0 && !isNonVenue(venue.business_type_code)">
                        <div class="vgps-ammenities-grid">
                            <div class="vgps-ammenities-grid-inner">
                                <div class="vgps-ammenities-grid-title">
                                    <div class="thumb"><img :src="'valet.svg' | assetPath"></div>
                                    <div class="desc">
                                        <h4>Parking</h4>
                                    </div>
                                </div>
                                <div class="vgps-ammenities-grid-list">
                                    <ul class="ui-parking-list">
                                        <li v-for="(parking,index) in venue.parking" :key="parking.name">
                                            {{ parking.name }} : {{ parking | pricePrefix }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </template>

                    <!-- Black Amenity -->
                    <!-- <template v-if="isBlackOwnedBusiness.length > 0">
                        <div v-for="(item,index) in isBlackOwnedBusiness" :key="index" class="vgps-ammenities-grid">
                            <div class="vgps-ammenities-grid-inner">
                                <div class="vgps-ammenities-grid-title">
                                    <div class="thumb"><img :src="item.icon_url"></div>
                                    <div class="desc">
                                        <h4>Business</h4>
                                    </div>
                                </div>
                                <div class="vgps-ammenities-grid-list">
                                    <ul>
                                        <li>{{ item.amenity }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </template>          -->

                    <!-- Others Amenity -->
                    <template v-if="getFrontendFilteredAmenities.length > 0">
                        <template v-for="(item,index) in getFrontendFilteredAmenities">
                            <div class="vgps-ammenities-grid Others" :key="item.amenity">
                                <div class="vgps-ammenities-grid-wrapper vgps-other-amenities-grid-wrapper">
                                    <div class="vgps-ammenities-grid grid-header">
                                        <div class="vgps-ammenities-grid-inner">
                                            <div class="vgps-ammenities-grid-title">
                                                <h4>{{ item.amenity }}</h4>
                                            </div>
                                        </div>
                                    </div>                               
                                    <div v-for="(child,index2) in item.values" :key="index2" class="vgps-ammenities-grid" :class="child.amenity">
                                        <div class="vgps-ammenities-grid-inner">
                                            <div class="vgps-ammenities-grid-title">
                                                <div class="thumb"><img :src="child.icon_url"></div>
                                                <div class="desc">
                                                    <h4>{{ child.amenity }}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>



                </div>
            </div>
        </section>
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";



import {
  ContentLoader,
  FacebookLoader,
  CodeLoader,
  BulletListLoader,
  InstagramLoader,
  ListLoader
} from "vue-content-loader";

export default {
  data() {
    return {
      loadingContent: false,
      directionURL: ''
    };
  },
  props: ["venue"],
  mixins: [helpers],
  created() {
    var vm = this;
    // var amenities = vm.getFrontendFilteredAmenities()
    // console.log('header section data',vm.event,amenities);
  },
  mounted: function() {



  },
  computed: {
    otherAmenities: function () {
        var data = this.venue.amenities.Others.filter(function(obj){
            return obj.amenity_code != 'black-owned-business'? true:false;  
        });
        return data;
    },
    getFrontendFilteredAmenities: function () {
        var data = [];
        let that =  this;
        console.log('filter other amenities',that.venue.amenities.Others);
        if (that.venue.amenities.Others.length) {
            
            
            // Accepts the array and key
            const groupBy = (array, key) => {
                // Return the end result
                return array.reduce((result, currentValue) => {
                // If an array already present for key, push it to the array. Else create an array and push the object
                (result[currentValue[key]] = result[currentValue[key]] || []).push(
                    currentValue
                );
                // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                return result;
                }, {}); // empty object is the initial value for result object
            };

            var grouped = groupBy(this.venue.amenities.Others,'amenity_subgroup');
            let objectArray = Object.keys(grouped);
            let objectArrayValues = Object.values(grouped);
            
            $.each(objectArray,function(index,obj){
                if(obj != 'Food' && obj != 'None'){
                    data.push({amenity: obj,values: objectArrayValues[index]});
                }
            });
            var getPreferencesGroupData = []
            $.each(data,function(index,obj){
                if(obj.amenity == 'Preference'){
                    var sub_grouped = groupBy(obj.values,'type_n');
                    let sub_objectArray = Object.keys(sub_grouped);
                    let sub_objectArrayValues = Object.values(sub_grouped);
                    $.each(sub_objectArray,function(index2,obj2){ 
                        getPreferencesGroupData.push({amenity: obj2,values: sub_objectArrayValues[index2]});
                    });
                    
                    data[index]['values'] = getPreferencesGroupData;
                }
            });
        }
        return data;
    },
    isBlackOwnedBusiness: function () {
        var data = this.venue.amenities.Others.filter(function(obj){
            return obj.amenity_code == 'black-owned-business'? true:false;  
        });
        return data;
    },
  },
  components: {
    ContentLoader,
    FacebookLoader,
  },
  methods: {
    
  },
  filters: {
    assetPath: function(img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
    pricePrefix: function(parking) {
      var price = parking.is_paid? '$'+parking.min_price:'Free';
      return price;
    },
    url: function(path) {
      var path = window.axios.defaults.baseURL + "/" + path;
      return path;
    }
  }
};
</script>
