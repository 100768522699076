<template>
    <main id="home-slider-component">
        <section class="top-banner">

            <div v-if="typeof loadingContent != 'undefined' && loadingContent" class="container slider-default-loader">    
                <content-loader
                    width="1490"
                    height="475"
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb">
                    <rect x="448" y="30" rx="0" ry="0" width="750" height="300" />
                    <rect x="239" y="53" rx="0" ry="0" width="643" height="254" />
                    <rect x="30" y="76" rx="0" ry="0" width="527" height="208" />
                    <rect x="762" y="53" rx="0" ry="0" width="643" height="254" />
                    <rect x="1087" y="76" rx="0" ry="0" width="527" height="208" />
                </content-loader>
            </div>
            <template v-else >
                <slick  ref="slick" :options="slickOptions" v-animate-css="animate.fadeIn">
                    <template v-if="defaultPageData">
                        <div v-for="(banner,index) in defaultPageData.banners" :key="index" class="silk-item">
                            <a v-if="banner.link" :href="banner.link" target="_blank"><img :src="banner.image" :alt="banner.title" /></a>
                            <a v-else href="javascript:vloid(0)l"><img :src="banner.image" :alt="banner.title" /></a>
                        </div>
                    </template>
                    <template v-else>
                        <div class="silk-item"><img :src="'/banners/new-banner-2.jpg'| assetPath" alt=""/></div>
                    </template>  
                </slick>
            </template>
        </section>
    </main>
</template>

<script>

    import helpers from './../../mixin/helpers';
    import { ContentLoader } from 'vue-content-loader'

    import carousel from 'vue-owl-carousel2'
    import Slick from 'vue-slick';
    import { mapFields } from "vuex-map-fields";
    import 'slick-carousel/slick/slick.css';
    import 'slick-carousel/slick/slick-theme.css'
    export default {
        data() {
            return {
                loadingContent: false,
                banners: [],
                leaderSpeed:1,
                width: document.documentElement.clientWidth,
                height: document.documentElement.clientHeight,
                slickOptions: {
                    autoplay:true,
                    slidesToShow: 1,
                    arrows: true,
                    dots: false,
                    slide:'div',
                    // centerPadding:50,
                    lazyLoad:true,
                    // Any other options that can be got from plugin documentation
                    responsive: [{

                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 1,
                            infinite: true
                        }

                    }, {

                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            dots: false,
                            arrows: true
                        }

                    }, {

                        breakpoint: 300,
                        settings: "unslick" // destroys slick

                    }]
                },
            }
        },
        mixins: [helpers],
        components: {
            Slick,
            carousel,
            ContentLoader
        },
        beforeCreate() {
            console.log('===== Slick Before Create=====');
            this.loadingContent = true;
        },
        created() {
            console.log('===== Slick Created =====');
            this.loadingContent = false;
        },
        destroyed() {
            // window.removeEventListener("resize", this.myEventHandler);
            console.log('===== Slick Destroyed =====');
        },
        updated() {
            // this.reInit();
            console.log('===== Slick Updated =====');
        },
        computed:{
            ...mapFields([
                "defaultPageData"
            ]),
        },
        mounted() {
            window.addEventListener('resize', this.myEventHandler);
            console.log('===== Slick Mounted =====');

        },
        unmounted() {
            window.removeEventListener('resize', this.myEventHandler);
            console.log('===== Slick Mounted =====');
        },
        methods: {
            myEventHandler(e) {
                console.log('===== Slick myEventHandler =====',e);
                // setTimeout(() => {
                    this.reInit(this.slickOptions);    
                // }, 500);
            },
            getBanners:function (){
                var vm = this;
                axios.get("data/banners",{ params: {
                  'slot': 'home'
                }}).then(response => {
                    vm.banners = response.data.data;
                    vm.loadingContent = false;
                }).catch(function (errors) {
                    vm.loadingContent = false;
                });

            },
            next() {
                this.$refs.slick.next();
            },

            prev() {
                this.$refs.slick.prev();
            },
            reInit() {
                console.log('Helpful if you have to deal with v-for to update dynamic lists');
                // Helpful if you have to deal with v-for to update dynamic lists
                this.$nextTick(() => {
                    this.$refs.slick.reSlick(this.slickOptions);
                });
            },
        },
        filters: {
            checkNullLink: function (link) {
                var checklink = link? link:'javascript:void(0);';
                return checklink;
            },
        }
    }
</script>


<style>
    /* .owl-nav disabled {
        margin-bottom: 369px;
    } */
</style>