<template>
  <header>
    <nav class="navbar navbar-default navbar-fixed-top" :class="headerClass">
      <div class="container-fluid">
        <div class="navbar-header">
          <router-link :to="{name:'home'}" class="navbar-brand logo-tab">
            <img src="https://d2cv1d7fub3ij0.cloudfront.net/system-images/logo.png" alt="VenueGPS" />
          </router-link>
          <button
            type="button"
            id="menu-toggle visible-xs visible-sm"
            @click="showMobileMenu = !showMobileMenu"
            class="navbar-toggle"
          >
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
        </div>
        <div class="collapse navbar-collapse" id="myNavbar">
          <ul class="nav navbar-nav navbar-right hidden-sm hidden-md hidden-xs user-menu">
            <li>
              <router-link :to="{ name: 'home' }">Home</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'directory' }">Contacts</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'mapview' }">Map View</router-link>
            </li>
            <li v-if="!isAuth()">
              <a href="#" @click="loginModal()" id="login-btn">Sign In</a>
            </li>

            <template v-if="isAuth() && isAdminsAccounts()">
              <!-- <li><a href="{{ route('admin.event.create') }}">Create Event</a></li> -->
              <li class="desktop-hide">
                <a :href="url('admin/dashboard')">Dashboard</a>
              </li>
              <li class="">
                <a
                  href="#"
                  class="dropdown-toggle user"
                  data-toggle="dropdown"
                  aria-expanded="true"
                >
                  <img class="user-image" :src="'/user/admin2.svg' | assetPath" />
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a :href="url('admin/dashboard')">Dashboard</a>
                  </li>
                  <li @click="logoutAccount()">
                    <a href="javascript:void(0);">Logout</a>
                  </li>
                </ul>
              </li>
            </template>

            <template v-if="isAuth() && isClientBusinessAuth()">
              <li>
                <a :href="url('admin/business/client/event/create')">Create Event</a>
              </li>
              <li class="desktop-hide">
                <a :href="url('admin/business/client/account')">My Account</a>
              </li>
              <li class="">
                <a
                  href="#"
                  class="dropdown-toggle user"
                  data-toggle="dropdown"
                  aria-expanded="true"
                >
                  <img class="user-image" :src="'user/admin2.svg' | assetPath" />
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a :href="url('admin/business/client/account')">Business Account</a>
                  </li>
                  <li @click="logoutAccount()">
                    <a href="javascript:void(0);">Logout</a>
                  </li>
                </ul>
              </li>
            </template>
            <template v-if="isAuth() && isPublicUserAuth()">
              <li class="">
                <a
                  href="#"
                  class="dropdown-toggle user"
                  data-toggle="dropdown"
                  aria-expanded="true"
                >
                  <img class="user-image" :src="profileImageUrl()" />
                </a>

                <ul class="dropdown-menu userDBOptionWrapper">
                  <li class="user-info">
                    <div class="user-img">
                      <img :src="profileImageUrl()" />
                    </div>
                    <div class="user-name">
                      <p>Welcome</p>
                      <p>
                        <span>{{ limitStr(userEmail(),20) }}</span>
                      </p>
                    </div>
                  </li>
                  <li class="divider"></li>
                  <li>
                    <router-link :to="{ name: 'my_events' }"><i class="fa fa-star"></i> My Events</router-link>
                  </li>
  
                  <li>
                    <router-link :to="{ name: 'my_venues' }"
                      ><i class="fa fa-star"></i> My Accounts</router-link
                    >
                  </li>
                  <li>
                        <router-link :to="{ name: 'user_notification_settings' }"><i class="fa fa-bell"></i> Notifications</router-link>
                      </li>
                  <!-- <li class="dropdown setting-menu"> -->
                    <!-- <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-cog"></i> Settings <span class="caret"></span>
                    </a> -->
                    <!-- <ul class="dropdown-menu"> -->
                      <li>
                        <router-link :to="{ name: 'user_prefrences' }"><i class="fa fa-bookmark"></i> Prefrences</router-link>
                      </li>
                      
                      <li> <router-link :to="{ name: 'all_cards' }"><i class="fa fa-list"></i> Subscription</router-link></li>

                      <!-- <li> <router-link :to="{ name: 'user_profile' }"><i class="fa fa-user"></i> My Profile</router-link></li> -->


                      <!-- <li><a href="#"><i class="fa fa-comment"></i> Submit Feedback</a></li> -->
                      <li>
                        <router-link :to="{ name: 'user_settings' }"><i class="fa fa-cog"></i> Settings </router-link></li>


                    <!-- </ul> -->
                  <!-- </li> -->
                  <li class="divider"></li>
                  <li>
                    <a href="javascript:void(0);" @click="logoutAccount()"><i class="fa fa-sign-out"></i> Logout</a>
                  </li>
             
                </ul>
              </li>
             
            </template>
          </ul>
        </div>
      </div>
    </nav>

    <user_mobile_menu :showMobileMenu="showMobileMenu"></user_mobile_menu>
  </header>
</template>

<script>
import { bus } from "./../../app";
import helpers from "./../../../mixin/helpers";
import Autocomplete from "./../../../components/global/all-search.vue";
import LocationPicker from "./../../../components/global/location-picker.vue";
import MobileLocationPicker from "./../../../components/global/mobile-location-picker.vue";

// Get User Mobile menues
import User_Mobile_Menu from "./../../partials/user-mobile-menu";

export default {
  created() {
    var vm = this;
    vm.headerClass = vm.$store.state.headerClass
      ? vm.$store.state.headerClass
      : vm.$session.get("headerClass");
  },
  data() {
    return {
      isMobileSearch: false,
      showMobileMenu: false,
      headerClass: "",
      suggestions: [],
      value: "",
      selection: "",
    };
  },
  components: {
    autocomplete: Autocomplete,
    location_picker: LocationPicker,
    mobile_location_picker: MobileLocationPicker,
    user_mobile_menu: User_Mobile_Menu,
  },
  mixins: [helpers],
  methods: {
    openMobileSearch() {},
    updateAutoComplete() {
      this.selection;
    },
  },
};
</script>