<template>
  <!-- tabs Start -->
  <section class="event-top-bar">
    <div class="containers">
      <div class="row">
        <div
          class="col-md-12 vgps-mobile-horizontal-scroll-wrapper vgps-event-section-nav"
        >
          <span class="vgps-scroll-left"></span>
          <span class="vgps-scroll-right"></span>
          <ul
            class="vgps-jump-to-links-wrapper vgps-horizontal-scroll-wrapper page-nav-menu"
          >
            <li
              v-if="
                GeneralInfo.length > 0 ||
                bookingInfo.length > 0 ||
                bookingManagerInfo.length > 0
              "
            >
              <router-link
                to=""
                v-on:click.native="setActive('contact')"
                :class="{ active: isActive('contact') }"
                v-scroll-to="{ el: '#contact-info', offset: -135 }"
                >Contacts</router-link
              >
            </li>
            <li v-if="event.tickets.length > 0">
              <router-link
                to=""
                v-on:click.native="setActive('tickets')"
                :class="{ active: isActive('tickets') }"
                v-scroll-to="{ el: '#tickets', offset: -100 }"
                >Tickets</router-link
              >
            </li>
            <li v-if="event.eventpeoplerelations.performer.length > 0">
              <router-link
                to=""
                v-on:click.native="setActive('performers')"
                :class="{ active: isActive('performers') }"
                v-scroll-to="{ el: '#performers', offset: -100 }"
                >Performers</router-link
              >
            </li>
            <li v-if="organizers.length > 0">
              <router-link
                to=""
                v-on:click.native="setActive('organizer-list')"
                :class="{ active: isActive('organizer-list') }"
                v-scroll-to="{ el: '#organizer-list', offset: -100 }"
                >Organizer's</router-link
              >
            </li>
            <li v-if="amenities.length > 0">
              <router-link
                to=""
                v-on:click.native="setActive('amenities')"
                :class="{ active: isActive('amenities') }"
                v-scroll-to="{ el: '#amenities', offset: -100 }"
                >Amenities</router-link
              >
            </li>
            <li
              v-if="event.about && event.about != '' && event.about != 'null'"
            >
              <router-link
                to=""
                v-on:click.native="setActive('about')"
                :class="{ active: isActive('about') }"
                v-scroll-to="{ el: '#about', offset: -100 }"
                >About</router-link
              >
            </li>
            <li v-if="event.venue[0].panaromic_images.length > 0">
              <router-link
                to=""
                v-on:click.native="setActive('gallery')"
                :class="{ active: isActive('gallery') }"
                v-scroll-to="{ el: '#gallery', offset: -100 }"
                >Gallery</router-link
              >
            </li>
            <li v-if="event.promo_videos.length > 0">
              <router-link
                to=""
                v-on:click.native="setActive('video')"
                :class="{ active: isActive('video') }"
                v-scroll-to="{ el: '#promo-videos', offset: -100 }"
                >Promo Videos</router-link
              >
            </li>
            <li v-if="event.venue[0].address_details">
              <router-link
                to=""
                v-on:click.native="setActive('map')"
                :class="{ active: isActive('map') }"
                v-scroll-to="{ el: '#mapdetail', offset: -100 }"
                >Location</router-link
              >
            </li>
            <li v-if="event.faqs.length > 0">
              <router-link
                to=""
                v-on:click.native="setActive('faq')"
                :class="{ active: isActive('faq') }"
                v-scroll-to="{ el: '#faq', offset: -100 }"
                >FAQ's</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- Tabs End -->
</template>

<script>
import { bus } from "../../app";
import helpers from "../../mixin/helpers";

import VueSilentbox from "vue-silentbox"; //https://vuejsexamples.com/a-lightbox-inspired-vue-js-component/
import EventDetailFollowButton from "../../components/global/event-detail-follow-button.vue";
import EventDetailLikeButton from "../../components/global/detail-page-like.vue";

import {
  ContentLoader,
  FacebookLoader,
  CodeLoader,
  BulletListLoader,
  InstagramLoader,
  ListLoader,
} from "vue-content-loader";

export default {
  data() {
    return {
      loadingContent: false,
      activeItem: "contact",
    };
  },
  computed: {
    bookingInfo: function () {
      var data = this.event.contacts.booking.filter(function (obj) {
        return obj.provider == "booking-email" || obj.provider == "phone"
          ? true
          : false;
      });
      return data;
    },
    bookingManagerInfo: function () {
      var data = this.event.contacts.booking.filter(function (obj) {
        return obj.provider == "manager-email" ||
          obj.provider == "manager-number"
          ? true
          : false;
      });
      return data;
    },
    GeneralInfo: function () {
      var data = this.event.contacts["info-contact"].filter(function (obj) {
        return obj.provider == "website" ||
          obj.provider == "phone-number" ||
          obj.provider == "press-email"
          ? true
          : false;
      });
      return data;
    },
  },
  props: ["event", "amenities", "organizers"],
  mixins: [helpers],
  created() {
    var vm = this;
    console.log("header section data", vm.event);
  },
  components: {
    ContentLoader,
    FacebookLoader,
    VueSilentbox,
    event_detail_follow_button: EventDetailFollowButton,
    event_detail_like_button: EventDetailLikeButton,
    // follow_venue: FollowVenue
  },
  methods: {
    isActive: function (menuItem) {
      return this.activeItem === menuItem;
    },
    setActive: function (menuItem) {
      this.activeItem = menuItem; // no need for Vue.set()
    },
  },
  filters: {},
};
</script>
