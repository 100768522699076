<template>
  <div class="business-filter">
    <ul
      v-if="typeof search_user_filters.business_category !='undefined' && search_user_filters.business_category.length > 0"
      class="business-sub-category list-group list-group-flush"
    >
      <li
        v-for="subCategory in search_user_filters.business_category.slice(0, 5)"
        :key="subCategory.name"
        class="list-group-item"
      >
        <div class="form-group vgps-custom-checkbox-wrapper">
          <label class="fancyRadioWrapper">
            {{ subCategory.name }}
            <input
              type="radio"
              v-model="search_business_sub_type"
              @change="advanceFilter"
              :value="subCategory.type_n"
              name="search_business_sub_type"
            />
            <span class="checkmark"></span>
            <span class="item-count badge">{{ subCategory.count }}</span>
          </label>
        </div>
      </li>
      <li
        v-if="typeof search_user_filters.business_category !='undefined' && search_user_filters.business_category.length > 5"
        class="list-group-item"
      >
        <a
          href="javascript:void(0)"
          class="vgp-primary-link"
          data-toggle="modal"
          data-target="#business-category-more-option-model"
        >
          see all
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import { bus } from "../../app";
import Select2 from "v-select2-component";
import helpers from "../../mixin/helpers";

// import the plugin
import { mapFields } from "vuex-map-fields";

export default {
  components: {
    Select2,
  },
  data() {
    return {
      value: "",
      dateRange: "",
      venue_type: [],
      sub_categories: [],
      isDateFormat: false,
      isFilterSelected: false,
      business_type: "",
      dropDownOptions: [],
      subBusinessTypes: [],
    };
  },
  computed: {
    businessTypes() {
      return this.$store.getters.businessTypes;
    },
    businessSubTypes() {
      return this.$store.getters.businessSubTypes;
    },
    ...mapFields([
      "search_main_category",
      "search_business_type",
      "search_business_sub_type",
      "search_user_filters",
    ]),
  },
  created: function () {
    var vm = this;
  },
  mixins: [helpers],
  props: ["filters"],
  methods: {
    advanceFilter: function () {
      var vm = this;
      // vm.search_main_category = vm.search_main_category == 'Trending' ? 'All': vm.search_main_category;
      bus.$emit("search_filter_emit");
    },
  },
  filters: {
    assetPath: function (img) {
      var path = window.axios.defaults.baseURL + "/images/" + img;
      return path;
    },
  },
};
</script>
