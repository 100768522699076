<template>
    <div>
        <date-picker v-model="featured_date"
            format="MM-DD-Y"
            valueType="YYYY-MM-DD"
            :disabled-date="backendNotBeforeToday"
            input-class="floating-label-field floating-label-field--s3 form-control" 
            placeholder="MM-DD-YYYY"
            :input-attr="{name:'',required:'required', id: 'featured'}">
        </date-picker>
        <input type="hidden" id="featured_end_date" name="featured_end_date" v-model="featured_date">
        
    </div>
</template>

<script>

    import { bus } from '../../app'
    import helpers from '../../mixin/helpers';
    import { mapFields } from 'vuex-map-fields';
    
    export default {
        props:['featured_date','featured'],
        watch: {},
        mixins: [helpers],
        mounted : function() {
            // this.top_featured = this.featured == 1 ? true :false; 

            
        },
        created () {
            this.featured_date = this.featured_date;
        },
        
        data: function () {
            return {

            } 
        },
        computed: {
        ...mapFields([
            'top_featured',
        ]),
        },
        methods: {
            selectDataRange: function(event) {
                var vm = this;

            },
        },
    }

</script>
