<template>
    <div class="event-vue-wrapper">
        <header_section></header_section>
        <div class="top-inner-pages row">
            <div v-if="loadingContent" class="content-loader container">
                <ContentLoader
                    viewBox="0 0 1300 500"
                    height="500"
                    width="1200"
                    :speed="speed">
                    <rect x="20" y="15" rx="20" ry="20" width="300" height="320" />
                    <rect x="92" y="347" rx="5" ry="5" width="45" height="45" />
                    <rect x="148" y="347" rx="5" ry="5" width="45" height="45" />
                    <rect x="205" y="347" rx="5" ry="5" width="45" height="45" />
                    <rect x="361" y="17" rx="10" ry="10" width="420" height="33" />
                    <rect x="361" y="71" rx="10" ry="10" width="315" height="33" />
                    <rect x="361" y="125" rx="10" ry="10" width="233" height="20" />
                    <rect x="361" y="216" rx="5" ry="5" width="195" height="13" />
                    <rect x="361" y="251" rx="5" ry="5" width="195" height="13" />
                    <rect x="367" y="311" rx="8" ry="8" width="130" height="38" />
                    <rect x="515" y="311" rx="8" ry="8" width="130" height="38" />
                </ContentLoader>
            </div>
            <div v-else class="loaded-content" v-animate-css="animate.fadeIn">
                <banner_section v-bind:venue="venue"></banner_section>
                <page_nav_section 
                v-bind:venue="venue"
                v-bind:amenities="isAmenitiesLength"
                ></page_nav_section>
                <!-- Event Detail Sections Wrapper Start -->
                <div class="vgps-event-detail-main-wrapper">
                    <info_contacts_section v-bind:venue="venue"></info_contacts_section>
                    <content_slider_ads_section></content_slider_ads_section>
                    <events_section v-if="venue.events.today_events.length > 0" 
                        v-bind:title="'Today’s Themes'"
                        :id="'today-themes'"
                        v-bind:events="venue.events.today_events"
                        v-bind:venue="venue"
                        >
                    </events_section>
                    <events_section v-if="venue.events.recurring_events.length > 0" 
                        v-bind:title="'Recurring Events'"
                        :id="'recurring-event'"
                        v-bind:events="venue.events.recurring_events"
                        v-bind:venue="venue"
                        >
                    </events_section>
                    <events_section v-if="venue.events.month_events.length > 0" 
                        v-bind:title="'Upcoming Events'"
                        :id="'month-event'"
                        v-bind:events="venue.events.month_events"
                        v-bind:venue="venue"
                        >
                    </events_section><br><br>
                    <div style="margin-top: 20px; clear: both;">
                        <amenties_section v-if="isAmenitiesLength.length > 0" v-bind:venue="venue"></amenties_section>

                    </div>
                    <about_section v-if="venue.venue_bio" v-bind:venue="venue"></about_section>
                    <gallery_section v-if="venue.panaromic_images.length > 0" v-bind:venue="venue"></gallery_section>
                    <!-- <google_ads_section></google_ads_section> -->
                    <promo_videos_section v-if="venue.promo_videos.length > 0" v-bind:venue="venue"></promo_videos_section>
                    <location_section v-bind:venue="venue"></location_section>
                    
                </div>
            </div>
        </div>
        <footer_section></footer_section>
    </div>
</template>

<script>

// Imports
import { bus } from '../app'
import helpers from '../mixin/helpers';

import BannerSection from './../views/venue/header'
import PageNavSection from './../views/venue/page-nav'
import AboutSection from './../views/venue/about'
import Info_Contacts_Section from './../views/venue/info-contacts'
import Amenities_Section from './../views/venue/amentities'
import GallerySection from './../views/venue/gallery'
import AdsSliderSection from './../views/event/banner-slider'
import Event_Theme_Section from './../views/venue/event-themes'
import Promo_Videos_Section from './../views/venue/promo-videos'
import GoogleAdsSection from './../views/venue/google-ads'
import LocationSection from './../views/venue/location'
import FooterSection from './../views/partials/footer'  
import HeaderSection from './../views/partials/header'   

import {
  ContentLoader,
  FacebookLoader
} from "vue-content-loader";

export default {
    name:'VenueDetailComponent',
    components: {
      ContentLoader,
      FacebookLoader,
      banner_section : BannerSection,
      amenties_section : Amenities_Section,
      about_section : AboutSection,
      info_contacts_section : Info_Contacts_Section,
      gallery_section : GallerySection,
      content_slider_ads_section : AdsSliderSection,
      events_section : Event_Theme_Section,
      promo_videos_section : Promo_Videos_Section,
      google_ads_section : GoogleAdsSection,
      page_nav_section : PageNavSection,
      location_section : LocationSection,
      header_section: HeaderSection,
      footer_section: FooterSection,
    },
    created: function(){
        var vm = this
        vm.getDetail();
        bus.$on('loadVenueDetailData', () => {
            vm.getDetail();
        });
    },
    watch: {
        $route: "fetchData"
    },
    activated () {
        this.$destroyKey()
    },
    props:[],
    mixins: [helpers],
    computed: {
        isAmenitiesLength: function(){
            var obj = this.venue.amenities;
            var arr = Object.keys(obj).map(function (key) { return obj[key]; });
            var data = arr.filter(function(obj,val){
                return obj.length > 0 ? true : false;  
            });
            return data;
        },
    },
    data () {
        return {
          title: 'Venue Detail Component',
          loadingContent:false,
          venue: '',
          speed:2
        }
    },
    methods: {
        async getDetail(id) {
            var vm = this ;
            vm.loadingContent = true;
            axios.get("get/venue/"+this.$route.params.id)
            .then( (response) => {
                if (response.data.success == false) {
                    
                    setTimeout(function(){
                        if(response.data.status_code == 403){
                            sweetalert2.fire(
                                {
                                    title: "",
                                    icon: "info",
                                    text: "Your sessionn is expired please login or back to home screen.",
                                    confirmButtonText: 'Back to Home!',
                                    showCloseButton: true,
                                    showCancelButton: true,

                                }
                            ).then((result) => {
                                if (result.value) {
                                    vm.$session.set('access_token','');
                                    window.location = window.axios.defaults.baseURL
                                }
                            })
                            return false;
                            
                        }else{
                            vm.$router.back()
                        }
                    });

                } else {
                    vm.venue = response.data.data 
                }
                vm.loadingContent = false;
            })
            .catch(function (errors) {
                sweetalert2.fire("", errors.data, "error");
                vm.loadingContent = false;
            });
        }
    }
}
</script>