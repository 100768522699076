const API_URL = document.head.querySelector('meta[name="api_url"]').content;


jQuery(document).ready(function ($) {


    $("body").on('click', '.select2-results__group', function () {
        $(this).siblings().slideToggle(500);
    })

    $('.view-large-image').click(function () {
        // debugger
        $('.silentbox-item').trigger('click');
    });
    // $(window).on('load', function () {
    // code here
    $('.select2-business-status').select2({
        tags: false,
        multiple: false,
        placeholder: "Select One",
        selectOnClose: false,
        allowClear: true,
        closeOnSelect: true,
    });

    $('.race-select2').select2({
        tags: false,
        multiple: false,
        placeholder: "Select One",
        selectOnClose: false,
        closeOnSelect: true,
        allowClear: true,
    });

    $('#tier_id').select2({
        tags: false,
        multiple: false,
        placeholder: "Select One",
        selectOnClose: false,
        allowClear: true,
        closeOnSelect: true,
        allowClear: true,
    });

    $('.gender-select2').select2({
        tags: false,
        multiple: false,
        placeholder: "Select One",
        selectOnClose: false,
        allowClear: true,
        closeOnSelect: true,
    });

    $('.age-select2').select2({
        tags: false,
        multiple: false,
        placeholder: "Select One",
        selectOnClose: false,
        allowClear: true,
        closeOnSelect: true,
    });

    $('.select2-event-status').select2({
        tags: false,
        multiple: false,
        placeholder: "Select One",
        selectOnClose: false,
        allowClear: true,
        closeOnSelect: true,
    });


    $('.select2-date-types').select2({
        tags: false,
        multiple: false,
        placeholder: "Select One",
        selectOnClose: false,
        allowClear: true,
        closeOnSelect: true,
    });




    $('.select2-hashtag-trending').select2({
        tags: false,
        multiple: false,
        placeholder: "Select One",
        selectOnClose: false,
        allowClear: true,
        closeOnSelect: true,
    });

    $('.select2-user-status').select2({
        tags: false,
        multiple: false,
        placeholder: "Select One",
        selectOnClose: false,
        closeOnSelect: true,
        allowClear: true,
    });

    $('.select2-business-type').select2({
        tags: false,
        multiple: false,
        placeholder: "Select One",
        selectOnClose: false,
        allowClear: true,
        closeOnSelect: true,
    });
    $('.select2-tiers').select2({
        tags: false,
        multiple: false,
        placeholder: "Select One",
        selectOnClose: false,
        allowClear: true,
        closeOnSelect: true,
    });

    $('.single-city-select2').select2({
        tags: false,
        multiple: false,
        allowClear: true,
        placeholder: "Select One",
        /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
        selectOnClose: false,
        closeOnSelect: true,
        allowClear: true,
    });
    $('.start-with-select2').select2({
        tags: false,
        multiple: false,
        placeholder: "Select One",
        /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
        selectOnClose: false,
        allowClear: true,
        closeOnSelect: true,
    });
    $('#slot').select2({
        tags: false,
        multiple: false,
        placeholder: "Select One",
        /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
        selectOnClose: false,
        allowClear: true,
        closeOnSelect: true,
    });
    $('.business-flag-select2').select2({
        tags: false,
        multiple: false,
        placeholder: "Select One",
        allowClear: true,
        /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
        selectOnClose: false,
        closeOnSelect: true,
        allowClear: true,
    });
    $('#tier_theme').select2({
        tags: false,
        multiple: false,
        placeholder: "Select One",
        allowClear: true,
        /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
        selectOnClose: false,
        closeOnSelect: true,
        allowClear: true,
    });

    $('.category-select2').select2({
        tags: false,
        multiple: false,
        placeholder: "Select One",
        /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
        selectOnClose: false,
        allowClear: true,
        closeOnSelect: true,
    });
    $('.theme-select2').select2({
        tags: false,
        multiple: false,
        placeholder: "Select One",
        allowClear: true,
        /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
        selectOnClose: false,
        allowClear: true,
        closeOnSelect: true,
    });
    $('.genre-select2').select2({
        tags: false,
        multiple: false,
        placeholder: "Select One",
        /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
        selectOnClose: false,
        allowClear: true,
        closeOnSelect: true,
    });

    $('.city-select2').select2({
        tags: false,
        multiple: false,
        placeholder: "Select One",
        /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
        selectOnClose: false,
        closeOnSelect: true,
        allowClear: true,
        minimumInputLength: 2,
        createTag: function (params) {
            var term = $.trim(params.term);
            if (term === '') {
                return null;
            }

            return {
                id: term,
                text: term,
                newTag: false // add additional parameters
            }
        },
        ajax: {
            url: API_URL + 'main/cities/',
            dataType: 'json',
            type: "GET",
            quietMillis: 50,
            data: function (params) {
                var query = {
                    q: params.term,
                    offset: 0,
                    limit: 100
                }
                // Query parameters will be ?search=[term]&type=public
                return query;
            },
            processResults: function (data) {

                console.log(data);
                return {
                    results: $.map(data.payload.content, function (item) {
                        return {
                            text: item.name,
                            slug: item.name,
                            id: item.id
                        }
                    })
                };
            }
        },
        // });

    });



    // $('#tier_theme').select2({
    //     tags: false,
    //     multiple: false,
    //     placeholder: "Select One",
    //     /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
    //     selectOnClose: false,
    //     closeOnSelect: true,
    //     allowClear: true,
    //     minimumInputLength: 0,
    //     createTag: function (params) {
    //         var term = $.trim(params.term);
    //         if (term === '') {
    //             return null;
    //         }

    //         return {
    //             id: term,
    //             text: term,
    //             newTag: false // add additional parameters
    //         }
    //     },
    //     ajax: {
    //         url: API_URL + 'hashtags/hashtag-types/',
    //         dataType: 'json',
    //         type: "GET",
    //         quietMillis: 50,
    //         data: function (params) {
    //             var query = {
    //                 q: params.term,
    //                 offset: 0,
    //                 limit: 100
    //             }
    //             // Query parameters will be ?search=[term]&type=public
    //             return query;
    //         },
    //         processResults: function (data) {

    //             console.log(data);
    //             return {
    //                 results: $.map(data.payload, function (item) {
    //                     return {
    //                         text: item.name,
    //                         slug: item.name,
    //                         id: item.code
    //                     }
    //                 })
    //             };
    //         }
    //     },
    //     // });

    // });


    $('#city-select2').select2({
        tags: false,
        multiple: true,
        placeholder: "Select the advertisement cities",
        /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
        selectOnClose: false,
        closeOnSelect: true,
        allowClear: true,
    }).on("change", function (e, value) {
        // debugger
        // var value = $(this).select2('val');
        // var selectedVal = $("option:selected",this).val();
        // if(selectedVal == 0){
        //     $(this).val([0]);
        //     $(this).trigger('change');
        // }
    });

    $('#sort-by-slot').select2({
        tags: false,
        multiple: false,
        placeholder: "Select One",
        /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
        selectOnClose: false,
        closeOnSelect: true,
        allowClear: true,
    }).on("change", function (e, value) {
        $('#srot-slots').closest('form').trigger('submit')
    });



    $('#date_display_option').select2({
        tags: false,
        multiple: false,
        allowClear: true,
        placeholder: "Select One",
        selectOnClose: false,
        allowClear: true,
        closeOnSelect: true,
    }).on("change", function (e, value) {

        var selectVal = $(this).select2('val');
        // 1 : Full date 
        // 2 : Release date 
        // 3 : No date


        $('#display_start_date').attr('required', true);
        $('#display_end_date').attr('required', true);

        $('.display-dates').removeClass('full-date').removeClass('release-date').removeClass('no-date');
        if (selectVal == 1) {
            $('.display-dates').addClass('full-date')
        } else if (selectVal == 2) {
            $('.display-dates').addClass('release-date')
            $('#display_start_date').removeAttr('required');
        } else {
            $('.display-dates').addClass('no-date')
            $('#display_start_date').removeAttr('required');
            $('#display_end_date').removeAttr('required');
        }


        $("select").on("select2:unselecting", function (e) {
            $(this).select2("val", "");
            e.preventDefault();
        });

        $("#event-vue-create-form").attr('autocomplete', 'off');

    });;

    jQuery.fn.scrollCenter = function (elem, speed) {


        // this = #timepicker
        // elem = .active

        var active = jQuery(this).find(elem); // find the active element
        //var activeWidth = active.width(); // get active width
        var activeWidth = active.width() / 2; // get active width center

        //alert(activeWidth)

        //var pos = jQuery('#timepicker .active').position().left; //get left position of active li
        // var pos = jQuery(elem).position().left; //get left position of active li
        //var pos = jQuery(this).find(elem).position().left; //get left position of active li
        var pos = active.position().left + activeWidth; //get left position of active li + center position
        var elpos = jQuery(this).scrollLeft(); // get current scroll position
        var elW = jQuery(this).width(); //get div width
        //var divwidth = jQuery(elem).width(); //get div width
        pos = pos + elpos - elW / 2; // for center position if you want adjust then change this

        jQuery(this).animate({
            scrollLeft: pos
        }, speed == undefined ? 1000 : speed);
        return this;
    };


    $(function () {
        // $('.select-form-submit').on('change', function(e) {
        //     $(this).closest('form')
        //         .trigger('submit')
        // });

        // $('input[name="daterange"]').daterangepicker({
        //     autoApply: true,
        //     autoUpdateInput: false,
        //     // startDate: '',
        //     // endDate: '',
        //     opens: 'left',
        //     locale: {
        //         format: 'MMM DD'
        //     }
        // }, function (start, end, label) {
        //     // $(this).val(start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
        //     // console.log("A new date ",start, end, label);
        //     // console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
        //     // $('form#event-search-form').submit()

        // }).on('hide.daterangepicker', function (ev, picker) {
        //     // debugger
        //     // $('form#event-search-form').submit()
        //     //do something, like clearing an input
        //     // $('#daterange').val('');

        // }).on('apply.daterangepicker', function (ev, picker) {
        //     $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
        //     // $('form#event-search-form').submit()
        // }).on('cancel.daterangepicker', function (ev, picker) {
        //     $(this).val('');
        // });

        $(document).keyup(function (event) {
            if (event.keyCode == 13) {
                $("form#event-search-form").submit();
            }
        });


        $("form#business-setup-form").bind("keypress", function (e) {
            if (e.keyCode == 13) {
                e.preventDefault();
            }
        });

    });

    var current_location = window.location.pathname.split('/')[1];
    if (current_location == '') {

        // $(".owl-carousel").owlCarousel({
        //     loop: true,
        //     margin: 20,
        //     responsiveClass: true,
        //     navText: ["<img src='" + window.location.href + "images/arrow-b.png'> ", "<img src='" + window.location.href + "images/arrow-c.png'>"],
        //     responsive: {
        //         0: {
        //             items: 1,
        //             nav: true
        //         },
        //         600: {
        //             items: 3,
        //             nav: false
        //         },
        //         1000: {
        //             items: 4,
        //             nav: true
        //         }
        //     }
        // });
    }

    window.addEventListener("scroll", function () {
        var scroll = this.scrollY;
        // var current_location = window.location.pathname.split( '/' )[1]

        if ($(".navbar-default").length === 0) {
            return false;
        }
        if (scroll >= $('.navbar-default').height()) {
            //clearHeader, not clearheader - caps H
            $(".navbar-default").addClass("darkHeader");
        } else {
            $(".navbar-default").removeClass("darkHeader");
        }

    }, false);
    window.addEventListener("scroll", function () {

        var scroll = this.scrollY;

        if ($(".filter-top-bar").length === 0) {
            return false;
        }
        //-$('.navbar-default').height()
        var old_position = $(".filter-top-bar").data("position");

        if (old_position == undefined) {
            $(".filter-top-bar").attr("data-position", $('.filter-top-bar').offset().top);
            old_position = $('.filter-top-bar').position().top;
        }

        if (scroll >= old_position - $('.navbar-default').height()) {
            $(".filter-top-bar").addClass("stick");
        } else {
            $(".filter-top-bar").attr("data-position", $('.filter-top-bar').offset().top);
            $(".filter-top-bar").removeClass("stick");
        }

    }, false);

    window.addEventListener("scroll", function () {

        var scroll = this.scrollY;

        if ($(".event-top-bar").length === 0) {
            return false;
        }

        //-$('.navbar-default').height()
        var old_position = $(".event-top-bar").data("position");

        if (old_position == undefined) {
            $(".event-top-bar").attr("data-position", $('.event-top-bar').offset().top);
            old_position = $('.event-top-bar').position().top;
        }

        if (scroll >= old_position - $('.navbar-default').height()) {
            $(".event-top-bar").addClass("stick");
        } else {
            $(".event-top-bar").attr("data-position", $('.event-top-bar').offset().top);
            $(".event-top-bar").removeClass("stick");
        }


    }, false);
    // tab stick with header
    // if(typeof pos != 'undefined'){
    $('window').scroll(function () {

        //console.log("aaaa");
        // var s = $(".event-top-bar, .filter-top-bar");
        // var pos = s.position();
        // var windowpos = $('body').scrollTop();
        // if (windowpos >= pos.top) {
        //     s.addClass("stick");
        // } else {
        //     s.removeClass("stick");
        // }
    });
    // }

    $(".owl-carousel.ev").owlCarousel({
        stagePadding: 200,
        loop: true,
        margin: 32,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: true,
                stagePadding: 50,
                margin: 15
            },
            600: {
                items: 1,
                nav: true,
                stagePadding: 50,
                margin: 15
            },
            1000: {
                items: 1,
                nav: true
            }
        }
    });

    $(".home-page-slider.owl-carousel").owlCarousel({
        loop: true,
        dots: false,
        autoplay: true,
        animateOut: 'fadeOut',
        autoplayTimeout: 4000,
        items: 1
    });

    $(".owl-carousel.promo-vid-carousel").owlCarousel({
        loop: false,
        responsiveClass: true,
        nav: true,
        responsive: {
            0: {
                items: 1,
                nav: true,
                margin: 10,
                // stagePadding: 30
            },
            600: {
                items: 1,
                nav: false,
                margin: 10,
                // stagePadding: 30
            },
            1000: {
                items: 1,
                nav: true,
                margin: 30,
                stagePadding: 100
            }
        }
    });

    $('#gname').on('keyup', function () {
        //$('#number').slideDown('fast')
    });
    $('.carousel').carousel();


    // $(".close-img").click(function () {
    //     // $("#number").hide();
    // });

    $('.date-range').daterangepicker();

    var header_search = $('.search-dropdown-menu');
    $(".search-mobile-icon").click(function () {
        header_search.addClass('search_show');
        header_search.show();
    });

    $(document).click(function (event) {

        // var $target = $(event.target);
        // if ($($target).hasClass('search-mobile-icon') == false) {
        //     $('.search-dropdown-menu').removeClass('search_show');
        // }

        // Closing Navbar if clicked outside
        // var clickover = $(event.target);
        // var $navbar = $(".navbar-collapse");
        // var _opened = $navbar.hasClass("in");
        // if (_opened === true && !clickover.hasClass("navbar-toggle")) {
        //     $navbar.collapse('hide');
        // }

    });

    $(document).on('select2:close', function (event) {
        // debugger
        $('.form-control.select2-hidden-accessible').select2("close");
        // $('span.select2-container.select2-container--default.select2-container--open span span span').html('');
        // this.value = null 
    })

    $('.event-search').click(function (event) {
        event.stopPropagation();
    });

    $("#user-signup-from").on('hide.bs.modal', function () {
        $('.alerts-div').html('');
        $('input[type="email"]').val('');
        $('input[type="password"]').val('');
        $('label.error').remove();
        $('#role').value('');
        $('#role').value('');
    });
    $("#loginModal").on('hide.bs.modal', function () {
        $('.alerts-div').html('');
        $('input[type="password"]').val('');
        $('input[type="email"]').val('');
        $('label.error').remove();
    });

    $('.vgps-jump-to-links-wrapper li a').click(function (ev) {

        $(this).parent().parent().find('a').removeClass('active');
        $(this).addClass('active');
        // Make sure this.hash has a value before overriding default behavior
        if (this.hash !== "") {
            // Prevent default anchor click behavior
            ev.preventDefault();
            // Store hash
            var hash = this.hash;
            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            $('html, body').animate({
                scrollTop: $(hash).offset().top - 100
            }, 800, function () {
                // Add hash (#) to URL when done scrolling (default click behavior)
                // window.location.hash = hash;
            });
        } // End if

    });


    $('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });


    var windowWidth = $(window).width();


    if (windowWidth > 767 && windowWidth < 992) {

        $('.search-mobile-icon').on('click', function () {
            $(this).toggleClass('hide-view');
            $('.search-wrapper-md').toggleClass('show');

        });

    }

    if (windowWidth < 768) {

        $('#login-btn').on('click', function () {

            // $(this).parent().parent().parent().attr('aria-expanded', 'false');
            $(this).parent().parent().parent().removeClass('in');

        });

    }


    $('.vgps-event-content-toggle-btn').on('click', function (e) {

        e.preventDefault();
        $('.vgps-about-event-content-wrapper').toggleClass('show-content');
        $(this).toggleClass('content-open');

        if ($(this).hasClass('content-open')) {
            $(this).text('View Less');
        } else {
            $(this).text('View More');
        }

    });


    // Get visitor Count ---------------------------
    var visitorCountURL = $('.counterimg').attr('src');
    localStorage.setItem('visitor_count_url', visitorCountURL)
    console.log('visitor_count', localStorage.getItem('visitor_count_url'));

    // Get collapsable  ---------------------------
    $('.panel-collapse').on('show.bs.collapse', function () {
        $(this).siblings('.panel-heading').addClass('active');
    });
    $('.panel-collapse').on('hide.bs.collapse', function () {
        $(this).siblings('.panel-heading').removeClass('active');
    });


    $(document).on("click", ".paginationWrapper a", function () {
        let homeBannerHeight = $('#home-slider-component').height();
        console.log(homeBannerHeight);
        $(window).scrollTop(homeBannerHeight);

    });


    $(document).on('keyup', '.hashthemes-select2 .select2-search__field',
        function (e) {
            var val = this.value;
            $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
            if (typeof val != 'undefined' && val != '') {
                $('.hashthemes-select2').addClass('open-select2-collapse-menu');
                // return data;
            }
        });


    var $eventLog = $("#tags");
    $eventLog.on("select2-close", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:open", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:select", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:selecting", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("change.select2", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:clear", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:closing", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });


    var $eventLog = $("#food_types");
    $eventLog.on("select2-close", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:open", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:select", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:selecting", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("change.select2", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:clear", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:closing", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });


    $(document).on("click", ".sPMobileFiltIcon", function () {

        // $(this).parents('body').toggleClass('bodyScrollingFix');
        if ($('.sPMobileFiltIcon').hasClass("collapsed")) {
            $('.sPMobileFiltIcon').parents('body').removeClass('bodyScrollingFix');

        } else {
            $('.sPMobileFiltIcon').parents('body').addClass('bodyScrollingFix');

        }
    });
    setTimeout(function () {
        // $(this).parents('body').toggleClass('bodyScrollingFix');
        if ($('.sPMobileFiltIcon').hasClass("collapsed")) {
            $('.sPMobileFiltIcon').parents('body').removeClass('bodyScrollingFix');

        } else {
            $('.sPMobileFiltIcon').parents('body').addClass('bodyScrollingFix');

        }
    }, 2000);
    $(document).on("click", ".hPDateAndFilterWrap .more-filters", function () {

        setTimeout(function () {

            $('.search-page').parents('body').addClass('bodyScrollingFix');
            console.log('asdjfjhajsdfhajsdhgf asdfasd');
        }, 2000);

    });

    $(document).on('click', '.closeSearchFilter', function () {
        $('#accordion').slideUp("slow", function () {
            // Animation complete.
            $('.sPMobileFiltIcon').trigger("click");

        }).slideDown(500);

    });
    // $('.sPMobileFiltIcon').parents('body').removeClass('bodyScrollingFix');

    // search page footer and filter block overlapping issue resolved
    $(window).scroll(function () {
        let footerHieght = $('.page-footer').height();
        let filterBlockH = $('.sPLeftSideWrap .inner-wrapper-sticky').height();
        let getHForScroll = $(document).height() - (footerHieght + filterBlockH + 110);

        let calcHeight = $(document).height() - footerHieght;
        let calBaseHeight = $(window).scrollTop() + $(window).height();

        if (calBaseHeight > calcHeight) {
            // console.log(filterBlockH, 'Filter Block h');
            // console.log(footerHieght, 'Footer h');
            // console.log(getHForScroll);
            $('.sPLeftSideWrap .inner-wrapper-sticky').css('top', getHForScroll);
            $('section#search-content-area .sPLeftSideWrap').addClass('scrollItUp');
        } else {
            $('section#search-content-area .sPLeftSideWrap').removeClass('scrollItUp');
        }
    });


    $(document).on('click', '.resetBtn', function () {

        $(this).parent('.amenitiesAgeGroup').find("input[type='radio']").each(function () {

            $(this).prop('checked', false);
        });

    });



    $(document).on('keyup', '.hashthemes-select2 .select2-search__field',
        function (e) {
            var val = this.value;
            $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
            if (typeof val != 'undefined' && val != '') {
                $('.hashthemes-select2').addClass('open-select2-collapse-menu');
                // return data;
            }
        });

    var $eventLog = $("#tags");
    $eventLog.on("select2-close", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:open", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:select", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:selecting", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("change.select2", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:clear", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:closing", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });


    var $eventLog = $("#food_types");
    $eventLog.on("select2-close", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:open", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:select", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:selecting", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("change.select2", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:clear", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:closing", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });


    var $eventLog = $("#food_types");
    $eventLog.on("select2-close", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:open", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:select", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:selecting", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("change.select2", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:clear", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });
    $eventLog.on("select2:closing", function () {
        $('.hashthemes-select2').removeClass('open-select2-collapse-menu');
    });

    // search page popup check boxes selection issue resolved
    function selectTagsFilter(element) {
        var selectedTags = [];
        let checkboxList = $(element).find('.modal-body input');
        $('.selected-filter a').each(function () {
            selectedTags.push($(this).attr('data-item-name'));
        });

        $(checkboxList).each(function () {
            $(this).prop('checked', false);
            let itemValue = $(this).attr('value');
            if (jQuery.inArray(itemValue, selectedTags) != -1) {
                $(this).prop('checked', true);
            }
        });
    }

    function radioTagFilter(element) {
        console.log('Venue close btn');
        var selectedTags = [];
        let checkboxList = $(element).find('.modal-body input');
        $('.selected-filter a').each(function () {
            selectedTags.push($(this).attr('data-item-name'));
        });
        $(checkboxList).each(function () {
            let itemValue = $(this).attr('value');
            if (jQuery.inArray(itemValue, selectedTags) != -1) {
                this.checked = true;
            } else {
                this.checked = false;
            }

        });

    }


    $(document).on('click', '.closeModelBtn', function () {
        let selectedElement = $(this).parents('.moreFilterModelWrp');
        selectTagsFilter(selectedElement);
    });
    $(document).on('click', '.vgp-primary-link', function () {
        selectTagsFilter('#theme-more-option-model');
    });
    $(document).on('click', '.amenitiesLoadMoreBtn', function () {
        selectTagsFilter('#cusinies-more-option-model');
    });
    $(document).on('click', '.cusiniesLoadMoreBtn', function () {
        selectTagsFilter('#cusinies-more-option-model');
    });

    $(document).on('click', '.venueModelClose', function () {
        let selectedElement = $(this).parents('#business-category-more-option-model');
        radioTagFilter(selectedElement);
    });


    // if (!vTodayStatusCheck) {
    //     console.log('Event Live now');
    // } else {
    //     console.log('Event Live now mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm');
    // }


  $('.tranding .adminTagManagment').click(function(){
   
    $('.tranding #hashtag').toggleClass('in');

  });


}); // End of document.ready func

document.addEventListener('DOMContentLoaded', function () {
    setTimeout(function () {
        let vTodayStatusCheck = $('.evenTodayStatus span');
        if (vTodayStatusCheck.text().includes("Open")) {

            $('.evenTodayStatus').parent('li').addClass('todayEventLive');
            // if ($('.vgps-venue-schedule li')children())

        } else {
            $('.evenTodayStatus').parent('li').addClass('toadyEventNotLive');
        }
    }, 6000);
}, false);
