<template>
    <div>
        <date-picker v-model="hash_end_date"
            value-type="YYYY-MM-DD"
            format="MM-DD-YYYY"
            :disabled-date="backendNotBeforeToday"
            input-class="floating-label-field floating-label-field--s3 form-control" 
            placeholder="MM-DD-YYYY"
            @change="updateValues"
            :input-attr="{name:'ens',required:'required', id: 'end'}">
        </date-picker>
        <input type="hidden" id="end_date" name="end_date" v-model="hash_end_date">
        
    </div>
</template>

<script>

    import { bus } from '../../app'
    import helpers from '../../mixin/helpers';
    import { mapFields } from 'vuex-map-fields';
    
    export default {
        props:['end_date'],
        mixins: [helpers],
        watch: {},
        created () {
            var vm = this;
            vm.start_date = vm.start_date;
            vm.hash_end_date = vm.end_date;

            bus.$on('start_date_event', (startDate) => {
                vm.hash_end_date = startDate
            });
        },
        computed: {
           
        },
        data: function () {
            return {
                hash_end_date:''
            } 
        },
        methods: {
            updateValues:function(data){
                this.hash_end_date  = data;
            },
        },
    }

</script>
