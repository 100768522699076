<template>
    <div>
        <date-picker v-model="display_end_date"
            value-type="YYYY-MM-DD"
            format="MM-DD-YYYY"
            input-class="floating-label-field floating-label-field--s3 form-control" 
            placeholder="MM-DD-YYYY"
            :disabled-date="displayBackendNotBeforeToday"
            @change="updateValues"
            :input-attr="{name:'',required:'required', id: 'end'}">
        </date-picker>
        <input type="text" id="display_end_date" required name="display_end_date" v-model="display_end_date">
        
    </div>
</template>

<script>

    import { bus } from '../../app'
    import helpers from '../../mixin/helpers';
    
    export default {
        props:['display_end_date'],
        mixins: [helpers],
        watch: {},
        created () {
            this.display_end_date = this.display_end_date;

            bus.$on('display_start_date_event', (startDate) => {
                this.display_end_date = startDate
            });
        },
        computed: {
            
        },
        data: function () {
            return {
                
            } 
        },
        methods: {
            updateValues:function(data){
                this.display_end_date  = data;
            },
        },
    }

</script>
